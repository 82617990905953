import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Deck, deckParentPages } from '_types/deck';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';
import { Question } from '_types/question';
import DeckModal from 'pages/deck-modal/deck-modal';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Loading } from 'components/loading';
import conversationClient from 'api/conversation-client';
import { useDispatch, useSelector } from 'react-redux';
import {isPremiumSelector, userSelector} from 'store-modules/user';
import { DECK_CONFIG } from './_config/deck-config';
import { returnRoutesForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';
import { ConversationWithCategory, UserConversationTaxonomy } from '_types';
import {
    favouriteConversationsSelector,
    setFavouriteConversations,
} from 'store-modules/favourite-conversations';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import useTypedLocation from 'hooks/use-typed-location';
import useTypedParams from 'hooks/use-typed-params';
import { myConversationsSelector } from 'store-modules/my-conversations';
import { setMyConversations } from 'store-modules/my-conversations';

interface RouteParams {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}

interface LocationState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchString: any;
    deck: Deck;
    randomQuestions: Question[];
    parentPage: string;
    favouriteQuestions: Question[];
    favouriteQuestion: Question;
    favouriteQuestionsIndex: number;
    isFavourites: boolean;
    viewAllSearchConvos?: boolean;
    conversationSearchResult: ConversationWithCategory[];
    questionSearchResult: Question[];
    questions: Question[];
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const DeckPage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const isPremium = useSelector(isPremiumSelector);
    const myConversations = useSelector(myConversationsSelector);
    const language: Language = useSelector(languageSelector);
    const location = useTypedLocation<LocationState>();
    const navigate = useNavigate();
    const parentPage = useMemo<string>(() => location.state?.parentPage, []);
    const routes = returnRoutesForLanguage(language, routeObj);

    const params = useTypedParams<RouteParams>();
    const { taxonomy, deckSlug, questionSlug } = params;

    const isRandomQuestions = taxonomy === 'random';
    const initialRandomQs =
        isRandomQuestions && location.state
            ? location.state?.randomQuestions
            : [];
    const [deck, setDeck] = useState<Deck | undefined>(
        location.state && location.state?.deck
    );
    const [questions, setQuestions] = useState<Question[]>(
        location?.state?.questions ? location.state.questions : initialRandomQs
    );
    const [questionsFetched, setQuestionsFetched] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [conversation, setConversation] =
        useState<ConversationWithCategory | null>(null);
    const favouriteConversations = useSelector(favouriteConversationsSelector);
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [isConversationInFavourites, setIsConversationInFavourites] =
        useState<boolean>(false);
    const [isQuestionInFavourites, setIsQuestionInFavourites] =
        useState<boolean>(false);
    const [isLoadingFavourites, setIsLoadingFavourites] =
        useState<boolean>(false);
    const [isShuffled, setIsShuffled] = useState<boolean>(false);
    const [shuffledQuestions, setShuffledQuestions] = useState<Question[]>([]);

    const searchState = {
        searchString: location.state?.searchString,
        viewAllSearchConvos: location.state?.viewAllSearchConvos,
        conversationSearchResult: location.state?.conversationSearchResult,
        questionSearchResult: location.state?.questionSearchResult,
    };

    const closeDeck = (deck: Deck): void => {
        gtmEvent(GTM_EVENT.CLOSE_DECK, { 'deck.name': deck.name });

        if (parentPage) {
            if (parentPage === deckParentPages.premiumHome) {
                navigate(routes.index);
            }

            if (parentPage === deckParentPages.myLibrary) {
                navigate(routes.myLibrary);
            }
            if (parentPage === deckParentPages.search) {
                navigate(routes.search, {
                    state: searchState,
                });
            }
            if (parentPage.includes(deckParentPages.premiumBrowse)) {
                const parentBrowsePath = `/${language}/${parentPage}`;
                navigate(parentBrowsePath);
            }

            if (parentPage.includes(deckParentPages.likedQuestionList)) {
                navigate(routes.favouriteQuestionsList);
            }
        } else {
            navigate(routes.index);
        }
    };
    const [notFound, setNotFound] = useState(false);
    //const [restart, setRestart] = useState<boolean>(false);

    const shuffleQuestions = async () => {
        const shuffledQuestions = [...questions];
        let currentIndex = shuffledQuestions.length,
            randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [shuffledQuestions[currentIndex], shuffledQuestions[randomIndex]] =
                [
                    shuffledQuestions[randomIndex],
                    shuffledQuestions[currentIndex],
                ];
        }
        setShuffledQuestions(shuffledQuestions);
    };

    const updateConversation = () => {
        conversationClient
            .getMyConversations()
            .then((response) => {
                dispatch(setMyConversations(response.reverse()));
            })
            .then(() => {
                const c = myConversations?.find((c) => c.id === deck?.id);
                setConversation(c ? c : null);
            });
    };

    useEffect(() => {
        if (myConversations === null && user) {

            conversationClient.getMyConversations().then((response) => {
                dispatch(setMyConversations(response.reverse()));
            })


        } else if (
            !conversation &&
            deck?.created_by_id &&
            deck?.created_by_id.toString().length > 0
        ) {
            const c = myConversations?.find((c) => c.id === deck?.id);
            setConversation(c ? c : null);
            setIsLoading(false);
        }
    }, [myConversations, conversation]);

    useEffect(() => {
        if (shuffledQuestions?.length === 0 && questions?.length > 0) {
            shuffleQuestions();
        }

        if (deck && questions && questions.length) {
            return;
        }

        if (location.state?.isFavourites) {
            setQuestions(location.state?.favouriteQuestions);
            // if (!questions || questions.length < 1) {
            //     console.log(' DECK PAGE - Loading favourites ');

            //     console.log('Setting questions - location.state : ' + location);
            //     setQuestions(location.state.favouriteQuestions);
            // }
            return;
        }

        if (
            (!favouriteConversations || !favouriteQuestions) &&
            !isLoadingFavourites &&
            isPremium
        ) {
            setIsLoadingFavourites(true);
            Promise.all([
                conversationClient.getMyFavouriteConversations(),
                conversationClient.getMyFavouriteQuestions(),
            ]).then((response) => {
                dispatch(setFavouriteConversations(response[0].reverse()));
                dispatch(setFavouriteQuestions(response[1].reverse()));
                // setIsLoading(false);
            });
        } else {
            // setIsLoading(false);
        }

        if (favouriteConversations) {
            for (
                let index = 0;
                index < favouriteConversations.length;
                index++
            ) {
                const c = favouriteConversations[index];
                if (c.id === deck?.id) {
                    setIsConversationInFavourites(true);
                }
            }
        }
        if (isRandomQuestions && !questions) {
            // match.params.questionSlug = location.state?.randomQuestions[0].slug; TODO
            setQuestions(location.state?.randomQuestions);
            return;
        }

        if (
            location.state?.isFavourites &&
            (!questions || questions.length < 1)
        ) {
            setQuestions(location.state?.favouriteQuestions);
            return;
        }
        /*if (restart) {
            conversationClient.getRandomQuestions().then((res) => {
                console.log(res);
                match.params.questionSlug = res[0].slug;
                setQuestions(res);
            });
            setRestart(false);
        }*/

        if (
            deck &&
            deck.count > 0 &&
            questions.length === 0 &&
            !questionsFetched
        ) {
            setIsLoading(true);
            //Exception for user created conversations
            const taxonomy =
                deck.taxonomy === UserConversationTaxonomy.en ||
                deck.taxonomy === UserConversationTaxonomy.nb
                    ? language === 'en'
                        ? UserConversationTaxonomy.en
                        : UserConversationTaxonomy.nb
                    : deck.taxonomy;

            conversationClient
                .getQuestions(
                    !(!!deck.created_by_id && deck.created_by_id > 0),
                    {
                        taxonomy: taxonomy,
                        id: deck.id,
                    }
                )
                .then((questions) => {
                    setQuestions(questions);
                    setQuestionsFetched(true);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.log('e', e);
                    navigate(routes.error)
                });

            return;
        }

        if (deck && deck.count === 0 && deck.created_by_id) {
            return;
        }

        conversationClient
            .getConversation(deckSlug, taxonomy)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((deck: any) => {
                if (deck) {
                    setDeck(deck);
                } else {
                    setNotFound(true);
                }
            })
            .catch(() => navigate(routes.error));

        // eslint-disable-next-line
    }, [
        deck,
        questions,
        deckSlug,
        taxonomy,
        isPremium,
        isRandomQuestions,
        location.state,
        questionSlug,
        routes,
        favouriteConversations,
        favouriteQuestions,
        isLoadingFavourites,
        favouriteConversations,
        isShuffled,
    ]);

    if (deck?.taxonomy === 'random' && !isLoadingFavourites && isLoading) {
        setIsLoading(false);
    }

    if (!!deck && !!deck.created_by_id) {
        if (
            !!favouriteConversations &&
            !!favouriteQuestions &&
            !!myConversations &&
            !!conversation &&
            isLoading
        ) {
            setIsLoading(false);
        }
    } else {
        if (!!favouriteConversations && !!favouriteQuestions && isLoading) {
            setIsLoading(false);
        }
    }

    const toggleShuffle = () => {
        setIsShuffled(!isShuffled);
    };

    const onGetMoreRandomQuestions = () => {
        //setRestart(true);
    };

    if (notFound) {
        // FIXME: redirect to 404 page?
        return <Navigate to={routes.index} replace />;
    }

    const questionSlice = (isShuffled ? shuffledQuestions : questions).slice(
        0,
        isRandomQuestions ||
            location.state?.isFavourites ||
            (deck && deck.created_by_id)
            ? DECK_CONFIG.questionLimitRandom
            : DECK_CONFIG.questionLimit
    );

    return !deck ||
        !questions ||
        isLoading ||
        (questions.length === 0 && deck.created_by_id && deck.count > 0) ? (
        <Loading />
    ) : (
        <DeckModal
            params={params}
            deck={deck}
            conversation={conversation}
            questions={questionSlice}
            isPremium={isPremium}
            onExit={() => closeDeck(deck)}
            isRandomQuestions={isRandomQuestions}
            onGetMoreRandomQuestions={onGetMoreRandomQuestions}
            parentPage={parentPage}
            isConversationInFavourites={isConversationInFavourites}
            isQuestionInFavourites={isQuestionInFavourites}
            favouriteQuestions={favouriteQuestions}
            favouriteConversations={favouriteConversations}
            setIsConversationInFavourites={setIsConversationInFavourites}
            setIsQuestionInFavourites={setIsQuestionInFavourites}
            isShuffled={isShuffled}
            toggleShuffle={toggleShuffle}
            searchState={searchState}
            updateConversation={() => updateConversation()}
            setDeck={setDeck}
        />
    );
};

export default DeckPage;
