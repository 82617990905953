import React from 'react';
import { Question } from '_types/question';
import { Container } from 'components/container';
import styles from './question-slide.module.scss';
import { SwiperSlide } from '../swiper-slide';
import { Slide } from '_types';

interface Props {
    slide: Slide<Question>;
    isActive: boolean;
    isRandomQuestions: boolean;
}

const QuestionSlide: React.FC<Props> = ({
    slide,
    isRandomQuestions,
}: Props) => {
    const question = slide.data!;

    return (
        <SwiperSlide isRandomQuestions={isRandomQuestions}>
            <Container className={styles.questionContainer} size='small'>
                <h1 className={styles.question}>{question.text}</h1>
            </Container>
        </SwiperSlide>
    );
};

export default QuestionSlide;
