import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, CircularProgress, Typography } from 'components/base';
import useSession from '../../use-session';
import {
    getAnswersCollectionRef,
    AnswerBase,
    Answer,
} from 'utils/collaboration-utils';
import { Question } from '_types';
import { FreeMode, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '../../join-collaboration-page.module.scss';
import SpeechBubble from './speech-bubble';
import { useCollection } from 'hooks/firebase/use-collection';
import { useIsVisible } from 'hooks/firebase/use-is-visible';
import AnswerForm from './answer-form';
import EmptyState from './empty-state';

type QuestionViewProps = { activeContent: Question };

const QuestionView: FC<QuestionViewProps> = ({
    activeContent,
    activeContent: { id, text },
}) => {
    const { collaborationId, userCredential } = useSession();
    const visible = useIsVisible();

    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState<string>();

    const [focusedOnIOS, setFocusedOnIOS] = useState(false);

    const ownAnswers = useCollection<AnswerBase>(
        getAnswersCollectionRef(collaborationId, id).where(
            'user.id',
            '==',
            userCredential?.user.uid
        ),
        !visible
    );

    const sortedAnswers = useMemo<Answer[] | undefined>(
        () =>
            ownAnswers?.docs
                .map((doc) => ({ id: doc.id, ...doc.data() } as Answer))
                .sort((a, b) =>
                    a.createdAt < b.createdAt
                        ? 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 0
                ),
        [ownAnswers]
    );

    useEffect(() => {
        setLoading(true);
        setQuestion(undefined);
    }, [id]);

    useEffect(() => {
        if (ownAnswers?.docs) {
            setLoading(false);
            setQuestion(text);
        }
    }, [ownAnswers]);

    const hasAnswered = sortedAnswers && sortedAnswers.length > 0;

    return (
        <Box
            display='flex'
            flex={1}
            flexDirection='column'
            height={1}
            justifyContent='center'
            p={focusedOnIOS ? 1 : { xs: 2, md: 3 }}
            pt={{ xs: 1, sm: 2, md: 3 }}
            width={1}
        >
            {loading ? (
                <Box
                    alignItems='center'
                    display='flex'
                    flex={1}
                    justifyContent='center'
                    p={2}
                >
                    <CircularProgress color='inherit' />
                </Box>
            ) : (
                <Box
                    alignItems='center'
                    display='flex'
                    flex={1}
                    flexDirection='column'
                    justifyContent='center'
                    width={1}
                >
                    <Typography
                        {...(focusedOnIOS
                            ? {
                                  alignItems: 'flex-end',
                                  mb: !!sortedAnswers?.length ? undefined : 4,
                              }
                            : {
                                  alignItems: 'center',
                              })}
                        align='center'
                        display='flex'
                        flex={hasAnswered ? 1 : undefined}
                        gutterBottom
                        fontSize={18}
                        fontWeight={600}
                        lineHeight={1.3}
                        maxWidth={{ xs: 540, md: 580 }}
                        px={2}
                    >
                        {question}
                    </Typography>

                    {hasAnswered ? (
                        <Box width={1} m='auto'>
                            <Box mx={{ xs: -2, md: -3 }}>
                                <Box display='flex' overflow='auto' width={1}>
                                    <Swiper
                                        className={styles.swiper}
                                        freeMode
                                        cssMode
                                        modules={[Mousewheel, FreeMode]}
                                        mousewheel={{
                                            forceToAxis: true,
                                        }}
                                        slidesPerView='auto'
                                        spaceBetween={8}
                                    >
                                        {sortedAnswers.map((item, index) => (
                                            <SwiperSlide
                                                className={styles.swiperSlide}
                                                key={item.id}
                                            >
                                                <Box
                                                    height={1}
                                                    display='flex'
                                                    alignItems='flex-end'
                                                >
                                                    <SpeechBubble
                                                        answer={item}
                                                        indicatorDirection={
                                                            (sortedAnswers.length -
                                                                index) %
                                                                2 ===
                                                            0
                                                                ? 'left'
                                                                : 'right'
                                                        }
                                                    />
                                                </Box>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <EmptyState questionId={id} />
                    )}
                </Box>
            )}

            <Box display='flex' justifyContent='center' alignSelf='stretch'>
                <Box maxWidth={400} width={1}>
                    <AnswerForm
                        activeContent={activeContent}
                        loading={loading}
                        setFocusedOnIOS={setFocusedOnIOS}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default QuestionView;
