import {
    DEEP_LINK_ANDROID,
    STORE_LINK_ANDROID,
    DEEP_LINK_IOS,
    STORE_LINK_IOS,
} from '../constants';

export const getUAInfo = (
    navigator: Navigator
): {
    isMobile: boolean;
    isAndroid: boolean;
    isiOS: boolean;
    isiOSWeb: boolean;
    isSafari: boolean | null;
    isWindows: boolean;
} => {
    const UA = navigator.userAgent.toLowerCase();
    const mobileTest = 'mobi';
    const androidTest = 'android';
    const iosTest = 'ios';
    const isMobile = UA.indexOf(mobileTest) !== -1;
    const isAndroid = UA.indexOf(androidTest) !== -1;
    const isiOS = UA.indexOf(iosTest) !== -1;
    const isSafari =
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i) &&
        !navigator.userAgent.match(/Opera|OPT\//);

    const isWindows = UA.indexOf('win') > -1;

    const isiOSWeb =
        [
            'ipad simulator',
            'iphone simulator',
            'ipod simulator',
            'ipad',
            'iphone',
            'ipod',
        ].includes(navigator.platform.toLocaleLowerCase()) ||
        navigator.platform.toLocaleLowerCase().indexOf('mac') >= 0;

    return { isMobile, isAndroid, isiOS, isiOSWeb, isSafari, isWindows };
};

export const openAndroid = (window: Window): void => {
    window.location.href = DEEP_LINK_ANDROID;
    setTimeout(() => (window.location.href = STORE_LINK_ANDROID), 1000);
};

export const openiOS = (window: Window): void => {
    window.location.href = DEEP_LINK_IOS;
    setTimeout(() => (window.location.href = STORE_LINK_IOS), 1000);
};
