import {
    Conversation,
    ConversationResponse,
    ConversationWithCategory,
    FeaturedConversation,
    FeaturedConversationResponse,
    filterFeaturedConversations,
    mapConversations,
    mapConversationsWithCategories,
    mapFeaturedConversations,
    mapQuestion,
    mapQuestions,
    QuestionResponse,
    mapMyConversationsWithCategories,
    mapMyConversationWithCategories,
    mapConversation,
    UserConversationTaxonomy,
} from '_types';
import { Question } from '../_types/question';
import restClient from './rest-client';

export interface FavouriteConversation {
    conversation_id: number;
    taxonomy: string;
}

export interface FavouriteQuestion {
    question_id: number;
}

export interface FavouriteConversationResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export interface FavouriteQuestionResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export interface MyConversation {
    conversation_id?: number;
    name: string;
    description: string;
    color_option: number;
    public: boolean;
    pro_conversation: boolean;
    questions: { question_id: number }[];
}

export interface QuestionTokenResponse {
    token: string;
    question_id: number;
}

const mapFavouriteConversationResponse = ({
    token,
}: FavouriteConversationResponse): string => token;

const mapFavouriteQuestionResponse = ({
    token,
}: FavouriteQuestionResponse): string => token;
/*
const CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};
*/
export default {
    questionCanBeShared: (question_id: number): Promise<boolean> => {
        return restClient.post('/get-question-sharable',
            {
                question_id: question_id,
            },
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            [],
            true
        );
    },
    getQuestion: (id: string): Promise<Question> => {
        return restClient
            .get<QuestionResponse>(
                '/get-question',
                false,
                {question_id: id},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapQuestion);
    },
    getQuestions: (
        cacheEnabled: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params?: any
    ): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>(
                '/get-cards-by-taxonomy',
                false,
                params,
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapQuestions);
    },
    getRandomQuestions: (): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>('/get-random-cards',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapQuestions)
            .catch(() => []);
    },
    getNewlyAddedConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/get-newly-added-conversations', false,

                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversationsWithCategories);
    },
    getFeaturedConversations: (): Promise<FeaturedConversation[]> => {
        return restClient
            .get<FeaturedConversationResponse[]>(
                '/get-featured-conversations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapFeaturedConversations)
            .then(filterFeaturedConversations);
    },
    getThemes: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/get-topics',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then(mapConversations);
    },
    getConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>(
                '/get-conversations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then(mapConversations);
    },

    getConversation: (
        slug: string,
        taxonomy: string
    ): Promise<Conversation> => {
        const isUserConversation =
            taxonomy === UserConversationTaxonomy.en ||
            taxonomy === UserConversationTaxonomy.nb;
        return restClient
            .get<ConversationResponse>(
                '/get-conversation',
                !isUserConversation,
                {
                    slug,
                    taxonomy,
                },
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversation);
    },

    //FAVOURITE CONVERSATIONS
    getMyFavouriteConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>(
                '/get-my-favorite-conversations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversationsWithCategories);
    },
    addFavouriteConversation: (
        favouriteConversation: FavouriteConversation
    ): Promise<string> => {
        return restClient
            .post<FavouriteConversation, FavouriteConversationResponse>(
                '/add-favorite-conversation',
                favouriteConversation,
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                [],
                true
            )
            .then(mapFavouriteConversationResponse);
    },
    removeFavouriteConversation: (
        favouriteConversation: FavouriteConversation
    ) => {
        return restClient.post<
            FavouriteConversation,
            FavouriteConversationResponse>(
            '/remove-favorite-conversation',
            favouriteConversation,
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            [],
            true);
    },

    //FAVOURITE QUESTIONS
    getMyFavouriteQuestions: (): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>('/get-my-favorite-questions',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapQuestions);
    },
    addFavouriteQuestion: (
        favouriteQuestion: FavouriteQuestion
    ): Promise<string> => {
        return restClient
            .post<FavouriteQuestion, FavouriteQuestionResponse>(
                '/add-favorite-question',
                favouriteQuestion,
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                [],
                true
            )
            .then(mapFavouriteQuestionResponse);
    },
    removeFavouriteQuestion: (favouriteQuestion: FavouriteQuestion) => {
        return restClient.post<FavouriteQuestion, FavouriteQuestionResponse>(
            '/remove-favorite-question',
            favouriteQuestion,
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            [],
            true);

    },

    //LIBRARY
    getMyConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/get-my-conversations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then(mapMyConversationsWithCategories);
    },

    createConversation: (
        conversation: MyConversation
    ): Promise<ConversationWithCategory> => {
        return restClient
            .post<MyConversation, ConversationResponse>(
                '/add-my-conversation',
                conversation,
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                [],
                true
            )
            .then(mapMyConversationWithCategories);
    },

    updateMyConversation: (
        conversation: MyConversation
    ): Promise<ConversationWithCategory> => {
        return restClient
            .put<MyConversation, ConversationResponse>(
                '/update-my-conversation',
                conversation,
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then();
    },

    removeMyConversation: (conversation_id: string): Promise<unknown> => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        return restClient.post<{}, ConversationResponse[]>(
            '/remove-my-conversation',
            {
                conversation_id: conversation_id
            },
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            [],
            true
        );
    },

    addQuestionsToMyConversation: (
        conversation_id: number,
        question_ids: number[]
    ): Promise<string> => {
        return (
            restClient
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .post<any, any>(
                    '/add-questions-to-my-conversation',
                    {
                        conversation_id,
                        question_ids,
                    },
                    {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                    [],
                    true
                )
                .then(mapFavouriteQuestionResponse)
        );
    },
};
