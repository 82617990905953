import React, { FC, Fragment, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './conversation-card.module.scss';
import { Conversation, ConversationCategory } from '_types';
import Decorator from './decorator';
import { ReactComponent as AddIcon } from '../../images/icons/addIcon.svg';
import { ReactComponent as PublicIcon } from '../../images/icons/icon-public.svg';
import { Typography } from 'components/base';

type ConversationCardProps = {
    category: ConversationCategory;
    children?: ReactNode;
    className?: string;
    conversation?: Conversation;
    decorator?: Decorator;
    onClick: () => void;
};

const ConversationCard: FC<ConversationCardProps> = ({
    category,
    children,
    className,
    conversation,
    decorator,
    onClick,
}) => {
    const decorationClass = decorator?.nextDecoration();

    let userConversationClass = '';
    if (className?.toLocaleLowerCase().includes('usercreatedconversation1')) {
        userConversationClass = styles.userConversation1;
    } else if (
        className?.toLocaleLowerCase().includes('usercreatedconversation2')
    ) {
        userConversationClass = styles.userConversation2;
    } else if (
        className?.toLocaleLowerCase().includes('usercreatedconversation3')
    ) {
        userConversationClass = styles.userConversation3;
    }

    const hasLongWords = (text: string | undefined): boolean => {
        if (text === undefined) {
            return false;
        }
        const listOfWords = text.split(' ');

        for (let i = 0; i < listOfWords.length; i++) {
            const word = listOfWords[i];
            if (word.length > 14) {
                return true;
            }
        }
        return false;
    };

    //NEW CONVERSATION CARD
    if (conversation?.slug === 'new-conversation') {
        return (
            <button
                className={classnames(
                    className,
                    styles.card,
                    styles.newConversationCard
                )}
                onClick={onClick}
            >
                <div className={styles.category}>{category.name}</div>
                <div className={styles.AddIconContainer}>
                    <AddIcon />
                </div>

                <div>{conversation.name}</div>
            </button>
        );
    }

    //NORMAL CONVERSATIONCARD
    return (
        <button
            className={classnames(
                { [styles.BreakWords]: hasLongWords(conversation?.name) },
                className,
                decorationClass,
                styles.card,
                styles[category?.slug],
                userConversationClass
            )}
            onClick={onClick}
        >
            {children}

            {!children && conversation && (
                <Fragment>
                    <div className={styles.category}>
                        {category.name ? (
                            <div className={styles.CategoryName}>
                                {category.name}
                            </div>
                        ) : (
                            <div />
                        )}

                        {conversation.public && (
                            <div className={styles.PublicIconContainer}>
                                <PublicIcon />
                            </div>
                        )}
                    </div>

                    <Typography
                        component='span'
                        variant='h4'
                        color='text.primary'
                        lineHeight={1.25}
                    >
                        {conversation.name}
                    </Typography>
                </Fragment>
            )}
        </button>
    );
};

export default ConversationCard;
