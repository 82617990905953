import React from 'react';
import { AccountSubpage } from 'pages/account-page/subpage';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { MenuItem } from '../menuItem';

interface Props {}

interface ViewCopy {
    pageTitle: string;
    title: string;
    languageLabel: string;
    notificationsLabel: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Innstillinger',
    title: 'Innstillinger',
    languageLabel: 'Språk',
    notificationsLabel: 'Varslinger',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    title: 'Settings',
    languageLabel: 'Language',
    notificationsLabel: 'Notifications',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AccountSettingsPage: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const { pageTitle, title, languageLabel, notificationsLabel } =
        returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    return (
        <AccountSubpage pageTitle={pageTitle} title={title}>
            <div>
                <MenuItem
                    title={notificationsLabel}
                    titleLink={`../${routes.account.notifications}`}
                />
                <MenuItem
                    title={languageLabel}
                    titleLink={`../${routes.account.language}`}
                />
            </div>
        </AccountSubpage>
    );
};

export default AccountSettingsPage;
