import React from 'react';
import classnames from 'classnames';
import styles from './menuItem.module.scss';
import { AccountSectionProps } from './types';
import { Link } from 'components/link';

type Props = AccountSectionProps;

const MenuItem: React.FC<Props> = ({
    title,
    titleLink,
    className,
    children,
    notification,
}: Props) => {
    const titleElement = title ? (
        <h1 className={styles.title}>{title}</h1>
    ) : null;

    let header = titleElement;

    if (titleLink && title) {
        header = (
            <Link to={titleLink} className={styles.link} underline={false}>
                {titleElement}
            </Link>
        );
    }
    return (
        <article className={classnames(styles.container, className)}>
            {header}
            <div className={styles.notification}>{notification}</div>
            <div className={styles.details}>{children}</div>
        </article>
    );
};

export default MenuItem;
