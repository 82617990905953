import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchQuery } from 'hooks/use-search-query';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Loading } from 'components/loading';
import userClient from 'api/user-client';
import { returnRoutesForLanguage } from 'services/language-service';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { authenticatedSelector } from 'store-modules/auth';
import { setUserState, userSelector } from 'store-modules/user';
import useHistoryRouter from 'hooks/use-history-router';
import { logUserInByToken } from 'common-logic/login';
import { setProAccount } from 'store-modules/professional';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AccountConfirmationPage = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const isAuthenticated = useSelector(authenticatedSelector);
    const user = useSelector(userSelector);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const { setRedirectToPayment, redirectOnLogin } = useHistoryRouter();
    const dispatch = useDispatch();

    const { act: confirmationId, used_pro_code: proValidationKey } =
        useSearchQuery();

    useEffect(() => {
        if (confirmationId === undefined) {
            return;
        }
        Promise.all([
            userClient.confirmUser(
                confirmationId as string,
                proValidationKey ? (proValidationKey as string) : null
            ),
        ])
            .then(async ([hasAlreadyConfirmed]) => {
                setIsConfirmed(true);
                isAuthenticated &&
                    user &&
                    dispatch(setUserState({ ...user, isConfirmed: true }));

                if (user?.isCompanyAdmin) {
                    const token = localStorage.getItem('token');
                    if (user && token) {
                        await logUserInByToken(token, (organization) => {
                            setProAccount(organization);
                        }).then(() => navigate(routes.proindex));
                    } else {
                        navigate(routes.login, {
                            replace: true,
                            state: { redirectedFromConfirmation: true },
                        });
                    }
                }

                if (proValidationKey && proValidationKey?.length > 1) {
                    if (hasAlreadyConfirmed) {
                        navigate(routes.error);
                    } else {
                        navigate(routes.userConfirmPro);
                    }
                }
            })
            .catch(() => navigate(routes.error));
    }, [
        confirmationId,
        navigate,
        proValidationKey,
        routes.error,
        routes.userConfirmPro,
    ]);

    useEffect(() => {
        if (isConfirmed && !proValidationKey) {
            if (isAuthenticated && user) {
                setRedirectToPayment(true);
                redirectOnLogin(user);
            } else {
                navigate(routes.login, {
                    replace: true,
                    state: { redirectedFromConfirmation: true },
                });
            }
        }
    }, [isConfirmed, proValidationKey, isAuthenticated, user]);

    return <Loading />;
};

export default AccountConfirmationPage;
