import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CircularProgress,
    IconButton,
    RFF_TextField,
    Stack,
    Typography,
} from 'components/base';
import { Form } from 'react-final-form';
import useSession from '../../use-session';
import Layout from '../layout';
import useHistoryRouter from 'hooks/use-history-router';
import { useNavigate } from 'react-router-dom';
import styles from './collaboration-components.module.scss';
type FormValues = { sessionDisplayName: string };

const Auth: FC = () => {
    const { t } = useTranslation();
    const { participants, setDisplayName, userCredential } = useSession();
    const navigate = useNavigate();
    const [mounted, setMounted] = useState(false);
    const { routes } = useHistoryRouter();

    useEffect(() => {
        if (userCredential) setMounted(true);
    }, [participants, userCredential]);

    const displayNameFieldId = 'join-session-username-field';

    const submit = ({ sessionDisplayName }: FormValues) =>
        setDisplayName(sessionDisplayName);

    return (
        <Layout>
            <Stack spacing={2}>
                {mounted && userCredential ? (
                    <Fragment>
                        <IconButton
                            aria-label={t('close')}
                            className={styles.closeButton}
                            icon='close'
                            onClick={() => navigate(routes.index)}
                        />
                        <Typography
                            align='center'
                            component='label'
                            htmlFor={displayNameFieldId}
                        >
                            <strong>
                                {t('joinCollaboration.register.username')}
                            </strong>
                        </Typography>

                        <Form
                            initialValues={{
                                sessionDisplayName:
                                    userCredential.user.displayName || '',
                            }}
                            onSubmit={submit}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={3} alignItems='center'>
                                        <RFF_TextField
                                            inputProps={{
                                                id: displayNameFieldId,
                                                required: true,
                                            }}
                                            name='sessionDisplayName'
                                            rounded
                                        />

                                        <Button type='submit'>
                                            {t(
                                                'joinCollaboration.register.submit'
                                            )}
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        />
                    </Fragment>
                ) : (
                    <CircularProgress color='inherit' />
                )}
            </Stack>
        </Layout>
    );
};

export default Auth;
