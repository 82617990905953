import React from 'react';
import styles from './form-error.module.scss';

interface Props {
    message?: string;
}

const FormError: React.FC<Props> = ({ message = ''}: Props) => (
    <p className={styles.formError}>
        {message}
    </p>
);

export default FormError;
