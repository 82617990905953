import { useLayoutEffect, useState, useEffect } from 'react';

import { getQuestionDocumentRef, Wordcloud } from 'utils/collaboration-utils';
import { useDocumentData } from 'hooks/firebase/use-document-data';

import useCollaboration from '../../../../use-collaboration';
import useSessionQuestion from '../../use-session-question';

export const useUpdateWordcloud = (
    questionId: number,
    wordcloudRef: React.MutableRefObject<HTMLDivElement | null>,
    answerCount?: number
) => {
    const { collaborationId } = useCollaboration();
    const { isActive } = useSessionQuestion();

    const questionRef = getQuestionDocumentRef(collaborationId, questionId);
    const questionData = useDocumentData(questionRef, !isActive);

    const { wordcloud: { svg = '' } = {} } = questionData || {};

    const getWordcloud = (): Wordcloud | undefined => {
        if (!answerCount) return undefined;

        if (wordcloudRef.current) {
            const svgElement = wordcloudRef.current.querySelector('svg');
            const gElement = svgElement?.querySelector('g');

            if (svgElement && gElement) {
                const { height, width, x, y } = gElement.getBBox();

                if (!height || !width) return undefined;

                const str = new XMLSerializer()
                    .serializeToString(svgElement)
                    // Set `viewBox` property (react-word-cloud does not do this, and it is needed for PDF rendering)
                    .replace('<svg', `<svg viewBox="0 0 ${width} ${height}"`)
                    // Change width of SVG to width of actual content to remove whitespace
                    .replace(
                        new RegExp(/(width=\"([^"]*)\")/, 'g'),
                        `height="${width}"`
                    )
                    // Change height of SVG to height of actual content to remove whitespace
                    .replace(
                        new RegExp(/(height=\"([^"]*)\")/, 'g'),
                        `height="${height}"`
                    )
                    // Translate content to fit into new SVG size
                    .replace(
                        new RegExp(
                            /(<g transform=\"translate\(([^"]*)\")/,
                            'g'
                        ),
                        `<g transform="translate(${x * -1} ${y * -1})"`
                    );

                return { height, svg: str, width, answerCount };
            }
        }
        return undefined;
    };

    const [timeoutInstance, setTimeoutInstance] = useState<NodeJS.Timeout>();

    const updateSvg = () => {
        let wordcloud = getWordcloud();
        let prevWordcloud: Wordcloud | undefined = undefined;

        /**
         * Makeshift callback function for checking if `react-wordcloud` has finished transitioning. Tick function
         * repeats every 250ms until the SVG string is identical as the previous one, meaning that the wordcloud has
         * finished animating. The Timeout instance is stored as a state variable, and is cleared when triggering
         * new checks as well as on dismount.
         */
        const tick = () =>
            setTimeoutInstance(
                setTimeout(() => {
                    prevWordcloud = wordcloud;
                    wordcloud = getWordcloud();

                    if (
                        answerCount &&
                        prevWordcloud &&
                        prevWordcloud.svg &&
                        wordcloud &&
                        wordcloud.svg &&
                        wordcloud.svg === prevWordcloud.svg
                    )
                        if (wordcloud.svg !== svg)
                            questionRef.update({
                                wordcloud,
                            });
                        else tick();
                }, 250)
            );

        tick();
    };

    // Update wordcloud SVG each time new answers are added
    useLayoutEffect(() => {
        timeoutInstance && clearTimeout(timeoutInstance);
        const timer = setTimeout(updateSvg, 1000);
        return () => clearTimeout(timer);
    }, [answerCount]);

    // Update wordcloud SVG each time the screen is resized, and the wordcloud is rerendered
    useLayoutEffect(() => {
        let timer: NodeJS.Timeout;

        const resizeListener = () => {
            timeoutInstance && clearTimeout(timeoutInstance);
            clearTimeout(timer);
            timer = setTimeout(updateSvg, 1000);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    useEffect(() => {
        return () => timeoutInstance && clearTimeout(timeoutInstance);
    }, []);

    return updateSvg;
};
