import React from 'react';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';

import { AboutPage } from 'pages/account-page/about-page';
import { AccountConfirmationPage } from 'pages/account-confirmation-page';
import { AccountConfirmationProPage } from 'professional/pages/pro-account-confirmation-page';
import { AccountPageNative } from 'pages/account-page-native';
import { BrowseConversationsPage } from 'pages/browse-conversations-page';
import { CreateConversationPage } from 'pages/create-conversation-page';
import { DeckPage } from 'pages/deck-page';
import { determineRedirectUrl } from 'services/redirects.service';
import { ErrorBoundary } from 'components/error-boundary';
import { ErrorPage } from 'pages/error-page';
import { FavouriteConversationsPage } from 'pages/favourite-conversations-page';
import { FavouriteQuestionsListPage } from 'pages/favourite-questions-list-page';
import { HomePage } from 'pages/home-page';
import { IS_MOBILE_APP } from 'constants/mobile-app-constants';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { MyLibraryPage } from 'pages/my-library-page';
import { PermissionActionTypes, useHasPermission } from 'permission';
import AccountRoutes from './account-routes';
import ContactPage from 'pages/contact-page/contact-page';
import { ProHome } from 'professional/pages/pro-home';
import { ProConversations } from 'professional/pages/pro-conversations';
import { subscriptionSelector, userSelector } from 'store-modules/user';
import { PRO_PREFIX } from 'constants/general';
import { ProBrowseConversations } from 'professional/pages/pro-browse-conversations';
import { returnRoutesForLanguage } from 'services/language-service';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { SearchPage } from 'pages/search-page';
import { useSelector, useDispatch } from 'react-redux';
import EditConversationColorAndTexts from 'pages/edit-conversation-color-and-text/edit-conversation-color-and-text';
import EditConversationQuestions from 'pages/edit-conversation-questions/edit-conversation-questions';
import MyConversationInfoPage from 'pages/my-conversation-info-page/my-conversation-info-page';
import QuestionInfoPage from 'pages/question-info-page/question-info-page';
import useCommonRoutes from './use-common-routes';
import { DeleteAccountConfirmationPage } from 'pages/delete-account-confirmation-page';
// import { getEnv } from 'api/rest-client';
import { EndSubscription } from 'pages/account-page/end-subscription';
import {
    InteractiveCollaborationPage,
    StaticCollaborationPage,
} from 'pages/collaboration-page';
import CollaborationOverviewPage from 'pages/collaboration-overview-page/collaboration-overview-page';
import ProCodeSubPage from 'pages/account-page/pro-code-subpage/pro-code-subpage';
import { ProProductInfoPage } from 'pages/pro-product-info-page';
import { CompanySignupPage } from 'pages/company-signup-page';
import CompanySignupConfirmationPage from 'pages/company-signup-confirmation-page/company-signup-confirmation-page';
import { EmailVerificationHOC } from 'hocs/EmailVerificationHoc';
import { ProSwitchConfirmationPage } from 'pages/pro-switch-confirmation-page';

const removePublicRoutes = (
    routesIn: Partial<RouteList>
): Partial<RouteList> => {
    const routesOut: Partial<RouteList> = { ...routesIn };
    delete routesOut.login;
    delete routesOut.createPremium;
    return routesOut;
};

interface Props {}
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const renderErrorPage = (): JSX.Element => <ErrorPage />;
const ActiveSubscriptionRoutes: React.FC<Props> = () => {
    const language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const sub = useSelector(subscriptionSelector);
    const enableProRoutes = !!user?.organization;
    const proRouteIndex = enableProRoutes ? routes.proindex : routes.index;
    const proRouteConversation = enableProRoutes
        ? routes.proconversation
        : routes.index;

    const commonRoutes = useCommonRoutes();

    const noProRoutesObj = (routesObj: RouteList) => {
        const copy: Partial<RouteList> = { ...routesObj };
        delete copy.proconversation;
        delete copy.proindex;
        return copy;
    };

    const hasPermission = useHasPermission();

    const authorizedRoutes = Object.values(
        removePublicRoutes(enableProRoutes ? routes : noProRoutesObj(routes))
    ) as string[];

    if (useMatch(routes.iOSSubscriptions))
        window.open('https://apps.apple.com/account/subscriptions', '_blank');

    if (useMatch(routes.googleSubscriptions))
        window.open(
            'https://play.google.com/store/account/subscriptions',
            '_blank'
        );

    return (
        <Routes>
            {commonRoutes}
            <Route path={routes.proCodeSubPage} element={<ProCodeSubPage />} />
            {sub && (
                <Route
                    path={routes.endSubscription}
                    element={<EndSubscription />}
                />
            )}
            <Route
                path={routes.proProductInfoPage}
                element={<ProProductInfoPage />}
            />
            <Route
                path={routes.companySignupPage}
                element={<CompanySignupPage />}
            />
            <Route
                path={routes.confirmReservedEmail}
                element={<EmailVerificationHOC language={language} />}
            />
            <Route
                path={routes.companySignupConfirmationPage}
                element={<CompanySignupConfirmationPage />}
            />
             <Route
                    path={routes.ProSwitchConfirmationPage}
                    element={<ProSwitchConfirmationPage />}
                />
            <Route path='/account-native' element={<AccountPageNative />} />
            <Route path={routes.search} element={<SearchPage />} />
            <Route path={routes.about} element={<AboutPage />} />

            <Route
                path={`${routes.account.index}/*`}
                element={<AccountRoutes />}
            />

            <Route
                path={routes.iOSSubscriptions}
                element={
                    <Navigate
                        to={`${routes.account.index}`} ///${routes.endSubscription}`}
                    />
                }
            />
            <Route
                path={routes.googleSubscriptions}
                element={
                    <Navigate
                        to={`${routes.account.index}`} ///${routes.endSubscription}`}
                    />
                }
            />

            <Route
                path={routes.userConfirm}
                element={<AccountConfirmationPage />}
            />

            <Route
                path={routes.userConfirmPro}
                element={<AccountConfirmationProPage />}
            />
            <Route
                path={routes.userConfirmDeleted}
                element={<DeleteAccountConfirmationPage />}
            />

            <Route
                path={routes.createConversation}
                element={<CreateConversationPage />}
            />

            <Route
                path={routes.favouriteConversations}
                element={<FavouriteConversationsPage />}
            />

            {hasPermission(PermissionActionTypes.COLLABORATION) && (
                <Route path={routes.collaborationOverview}>
                    <Route index element={<CollaborationOverviewPage />} />

                    <Route
                        path=':collaborationSlug/*'
                        element={<InteractiveCollaborationPage />}
                    />

                    <Route
                        path={`${routes.collaborationReview}/:collaborationSlug/*`}
                        element={<StaticCollaborationPage />}
                    />
                </Route>
            )}

            <Route path={routes.myLibrary} element={<MyLibraryPage />} />

            <Route
                path={routes.favouriteQuestionsList}
                element={<FavouriteQuestionsListPage language={language} />}
            />

            <Route
                path={routes.editConversationColorAndTexts}
                element={<EditConversationColorAndTexts />}
            />

            <Route
                path={routes.editConversationQuestions}
                element={<EditConversationQuestions />}
            />

            <Route
                path={routes.questionInfoPage}
                element={<QuestionInfoPage language={language} />}
            />

            <Route
                path={routes.myConversationInfoPage}
                element={<MyConversationInfoPage />}
            />

            <Route
                path={routes.contact}
                element={<ContactPage language={language} />}
            />

            <Route
                path={`/${language}/${PRO_PREFIX}/browse/:topLevelSlug`}
                element={
                    enableProRoutes ? <ProBrowseConversations /> : <HomePage />
                }
            />

            <Route
                path={`/${language}/${PRO_PREFIX}/browse/:topLevelSlug/:categorySlug`}
                element={
                    enableProRoutes ? <ProBrowseConversations /> : <HomePage />
                }
            />

            <Route
                path={`/${language}/${PRO_PREFIX}/:taxonomy/:deckSlug/*`}
                element={enableProRoutes ? <ProConversations /> : <HomePage />}
            />

            <Route
                path={`/${language}/${PRO_PREFIX}/:taxonomy/:deckSlug/:questionSlug`}
                element={enableProRoutes ? <ProConversations /> : <HomePage />}
            />

            <Route
                path={`/${language}/favourite-question/:questionSlug`}
                element={<DeckPage />}
            />

            <Route
                path={`/${language}/browse/:categorySlug`}
                element={<BrowseConversationsPage />}
            />

            <Route
                path={`/${language}/:taxonomy/:deckSlug`}
                element={<DeckPage />}
            />

            <Route
                path={`/${language}/:taxonomy/:deckSlug/:questionSlug`}
                element={<DeckPage />}
            />

            <Route
                path={routes.index}
                element={
                    <ErrorBoundary renderError={renderErrorPage}>
                        <HomePage />
                    </ErrorBoundary>
                }
            />

            <Route path={proRouteIndex} element={<ProHome />} />

            <Route path={proRouteConversation} element={<ProConversations />} />

            <Route
                path='*'
                element={
                    <Navigate
                        to={determineRedirectUrl(
                            routesNO,
                            routesEN,
                            dispatch,
                            authorizedRoutes,
                            enableProRoutes,
                            user,
                            IS_MOBILE_APP
                        )}
                    />
                }
            />
        </Routes>
    );
};
export default ActiveSubscriptionRoutes;
