import React, { useEffect, useState } from 'react';
import CancelledSub from './cancelled-sub';
import ActiveSub from './active-sub';
import NoSub from './no-sub';
import { useSelector, useDispatch } from 'react-redux';
import { subscriptionSelector, setUserSub } from 'store-modules/user';
import subscriptionClient from 'api/subscription-client';
import { useMountedState } from 'common-logic/use-mounted-state';

interface Props {}

const SubscriptionDetails: React.FC<Props> = () => {
    const isMounted = useMountedState();
    const [hasSubInfo, sethasSubInfo] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!hasSubInfo) {
            subscriptionClient.getActiveSubscription().then((sub) => {
                if (!isMounted()) return;

                sethasSubInfo(true);
                dispatch(setUserSub(sub!));
            });
        }
    }, [dispatch, hasSubInfo, isMounted]);
    const sub = useSelector(subscriptionSelector);

    const isActive = sub && sub.data.status === 'active';
    const isCancelled = sub && sub.data.status === 'pending-cancel';

    return (
        <React.Fragment>
            {!sub && <NoSub />}
            {hasSubInfo && isActive && <ActiveSub sub={sub!} />}
            {hasSubInfo && isCancelled && <CancelledSub sub={sub!} />}
        </React.Fragment>
    );
};

export default SubscriptionDetails;
