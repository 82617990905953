import * as actionTypes from './_actionTypes';
import { SetProAccountAction, ClearProAccountAction } from './_types';
import { Organization } from '_types';

export const setProAccount = (
    organization: Organization
): SetProAccountAction => ({
    type: actionTypes.SET_PRO_ACCOUNT,
    payload: organization,
});

export const clearProAccount = (): ClearProAccountAction => ({
    type: actionTypes.CLEAR_PRO_ACCOUNT,
});
