import React, { FC, useLayoutEffect, useState, useRef } from 'react';
import { Box, Typography, useTheme } from 'components/base';
import styles from '../../join-collaboration-page.module.scss';
import { Answer } from 'utils/collaboration-utils';

type SpeechBubbleProps = {
    answer: Answer;
    indicatorDirection?: 'left' | 'right';
};

const SpeechBubble: FC<SpeechBubbleProps> = ({
    answer: { id, text },
    indicatorDirection = 'left',
}) => {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement | null>(null);

    const [el, setEl] = useState<HTMLDivElement | null>(null);

    useLayoutEffect(() => setEl(ref.current), []);

    const { scrollHeight, scrollWidth } = el || {};

    const canvasId = `canvas-${id}`;
    const indicatorCutoutId = `indicator-cutout-${id}`;
    const indicatorId = `indicator-${id}`;
    const rectangleCutoutId = `rectangle-cutout-${id}`;
    const rectangleId = `rectangle-${id}`;
    const strokeWidth = 1.5;

    return (
        <Box position='relative' marginBottom={2}>
            {scrollHeight && scrollWidth && (
                <Box position='absolute'>
                    <svg width='100%' height={scrollHeight + 20}>
                        <defs>
                            <rect
                                fill='white'
                                height='100%'
                                id={canvasId}
                                width='100%'
                            />

                            <rect
                                data-name={rectangleId}
                                height={scrollHeight}
                                id={rectangleId}
                                rx={24}
                                transform={`translate(${strokeWidth} ${strokeWidth})`}
                                width={`calc(100% - ${strokeWidth}px)`}
                                x={-(strokeWidth / 2)}
                                y={-(strokeWidth / 2)}
                            />

                            {indicatorDirection === 'left' ? (
                                <path
                                    d='M10.774,13.98C10.965,13.951,18.733.352,18.733.352S8.689-3.535,1.539,7.159,10.774,13.98,10.774,13.98Z'
                                    data-name={indicatorId}
                                    id={indicatorId}
                                    transform={`translate(${Math.max(
                                        42.5,
                                        scrollWidth * 0.25
                                    )} ${scrollHeight}) rotate(150)`}
                                />
                            ) : (
                                <path
                                    data-name={indicatorId}
                                    id={indicatorId}
                                    transform={`translate(${Math.min(
                                        scrollWidth - 35.5,
                                        scrollWidth * 0.75
                                    )} ${scrollHeight - 13}) rotate(30)`}
                                    d='M11.278.546c.2.026,7.961,13.626,7.961,13.626S8.922,18.218,1.75,7.537,11.278.546,11.278.546Z'
                                />
                            )}
                            <mask id={rectangleCutoutId}>
                                <use href={`#${canvasId}`} />
                                <use href={`#${rectangleId}`} />
                            </mask>

                            <mask id={indicatorCutoutId}>
                                <use href={`#${canvasId}`} />
                                <use href={`#${indicatorId}`} />
                            </mask>
                        </defs>

                        <use
                            fill='none'
                            href={`#${rectangleId}`}
                            mask={`url(#${indicatorCutoutId})`}
                            stroke={theme.palette.custom.blue[80]}
                            strokeWidth={strokeWidth}
                        />

                        <use
                            fill='none'
                            href={`#${indicatorId}`}
                            mask={`url(#${rectangleCutoutId})`}
                            stroke={theme.palette.custom.blue[80]}
                            strokeWidth={strokeWidth}
                            transform={`translate(0, -${strokeWidth / 2})`}
                        />
                    </svg>
                </Box>
            )}

            <Box
                display='flex'
                minHeight={24}
                minWidth={24}
                px={2}
                py={1.5}
                ref={ref}
            >
                <Typography
                    className={styles.answerChip}
                    color='snow'
                    height={1}
                    m='auto'
                    maxWidth={180}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};
export default SpeechBubble;
