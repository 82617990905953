import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import WebFont from 'webfontloader';
import 'whatwg-fetch';

import App from 'app';
import * as serviceWorker from './serviceWorker';
import { initGtm } from 'utils/tracking-utils';
import { Store } from './store';
import { lightTheme } from 'style/theme';
import { ThemeProvider } from 'components/base';
import HistoryRouterProvider from 'hooks/use-history-router/provider';

WebFont.load({
    google: {
        families: [
            'Source Sans Pro:ital,wght@0,300;0,400;0,600;0,700;1,400;0,900',
            'sans-serif',
        ],
    },
    timeout: 2000,
});

initGtm();

const app = (
    <StoreProvider store={Store}>
        <ThemeProvider theme={lightTheme}>
            <HistoryRouterProvider>
                <App />
            </HistoryRouterProvider>
        </ThemeProvider>
    </StoreProvider>
);

const container = document.getElementById('root');
container && createRoot(container).render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
