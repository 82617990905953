import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Breakpoint, Chip, Grid, Typography } from 'components/base';
import {
    getParticipantsCollectionRefForSession,
    Participant,
} from 'utils/collaboration-utils';
import { useIsVisible } from 'hooks/firebase/use-is-visible';
import { useIntervalCollectionData } from 'hooks/firebase/use-interval-collection-data';

import useCollaboration from '../../use-collaboration';
import { useScreenSize } from 'hooks/use-screen-size';

const chipWidth = 180;

const Participants: FC = () => {
    const { breakpoint } = useScreenSize();

    const {
        collaborationId,
        collaboration: { collaborationCode },
    } = useCollaboration();

    const { t } = useTranslation();
    const visible = useIsVisible();

    const participants = useIntervalCollectionData<Participant>(
        collaborationCode
            ? getParticipantsCollectionRefForSession(
                  collaborationId,
                  collaborationCode
              )
            : (undefined as never),
        !visible,
        1000
    );

    let maxParticipants = 0;

    switch (breakpoint) {
        case 'xs':
            maxParticipants = 6;
            break;
        case 'sm':
            maxParticipants = 6;
            break;
        case 'md':
            maxParticipants = 28;
            break;
        case 'lg':
            maxParticipants = 40;
            break;
        case 'xl':
            maxParticipants = 100;
            break;
        default:
            break;
    }

    const participantsSubset = participants
        ? participants?.length <= maxParticipants
            ? participants
            : participants.slice(0, maxParticipants - 1)
        : [];

    const extraParticipantCount = participants
        ? participants?.length - participantsSubset.length
        : 0;

    const columns: Record<Breakpoint, number> = {
        xs: 2,
        sm: 3,
        md: 4,
        lg: 5,
        xl: participantsSubset.length > maxParticipants / 2 ? 10 : 5,
    };

    const columnSpans: Record<Breakpoint, number> = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 12 / 5,
        xl: participantsSubset.length > maxParticipants / 2 ? 12 / 10 : 12 / 5,
    };

    const getGridValues = (
        valueFn: (num: number) => number | string | undefined
    ) =>
        Object.entries(columns).reduce(
            (acc, [breakpoint, columnCount]) => ({
                ...acc,
                [breakpoint]: valueFn(columnCount),
            }),
            {}
        );

    return (
        <Fragment>
            <Box
                display='flex'
                flexDirection='column'
                height={1}
                overflow='auto'
                zIndex={1}
            >
                {participantsSubset.length > 0 ? (
                    <Fragment>
                        <Box flexBasis={`${(1 / 3) * 100}%`} />

                        <Box
                            display='flex'
                            flex='1 1 auto'
                            justifyContent='center'
                            mt={-2}
                            py={{ xs: 0, md: 4 }}
                            px={{ xs: 1, md: 4 }}
                        >
                            <Grid
                                container
                                margin='auto'
                                spacing={2}
                                maxWidth={1}
                                justifyContent={getGridValues((columnCount) =>
                                    participantsSubset.length <= columnCount
                                        ? 'center'
                                        : undefined
                                )}
                                width={getGridValues(
                                    (columnCount) => chipWidth * columnCount
                                )}
                            >
                                {participantsSubset.map(
                                    ({ displayName, id: uuid }) => (
                                        <Grid
                                            height='min-content'
                                            item
                                            key={uuid}
                                            {...(participantsSubset.length <=
                                            columns[breakpoint]
                                                ? { maxWidth: chipWidth }
                                                : {
                                                      ...columnSpans,
                                                      width: chipWidth,
                                                  })}
                                        >
                                            <Chip
                                                color='primary'
                                                label={displayName}
                                            />
                                        </Grid>
                                    )
                                )}

                                {!!extraParticipantCount && (
                                    <Grid item {...columnSpans}>
                                        <Chip
                                            label={`+${extraParticipantCount}`}
                                            variant='outlined'
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>

                        <Box flexBasis={`${(2 / 3) * 100}%`} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <Box flexBasis='40%' />

                        <Box display='flex' m='auto' height='min-content'>
                            <Typography
                                color='textSecondary'
                                fontSize={{ xs: 18, md: 24 }}
                                fontStyle='italic'
                            >
                                {t('collaboration.waiting')}
                            </Typography>
                        </Box>

                        <Box flexBasis='60%' />
                    </Fragment>
                )}
            </Box>
        </Fragment>
    );
};

export default Participants;
