import styles from './conversation-card.module.scss';

const decorations = [
    styles.decoration1,
    styles.decoration2,
    styles.decoration3,
    styles.decoration4,
    styles.decoration5,
];

export const decorationSpacing = [2, 4, 3, 4, 3];

class Decorator {
    private position: number;
    private currentDecoration: number;

    constructor() {
        this.position = 0;
        this.currentDecoration = 0;
    }

    nextDecoration(): string {
        this.advanceOne();
        const currentIsDecoration = this.position === 0;
        return currentIsDecoration ? decorations[this.currentDecoration] : '';
    }

    private advanceOne(): void {
        this.position++;

        if (this.position > decorationSpacing[this.currentDecoration]) {
            this.position = 0;
            this.currentDecoration++;
        }

        if (this.currentDecoration === decorationSpacing.length)
            this.currentDecoration = 0;
    }
}

export default Decorator;
