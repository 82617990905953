import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    ConversationWithCategory,
    Deck,
    deckParentPages,
    Question,
} from '_types';
import { useState, useEffect, useMemo } from 'react';
import { Loading } from 'components/loading';
import { DECK_CONFIG } from 'pages/deck-page/_config/deck-config';
import {
    isPremiumSelector,
    setUserState,
    userSelector,
} from 'store-modules/user';
import { useDispatch, useSelector } from 'react-redux';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { returnRoutesForLanguage } from 'services/language-service';
import { RouteList, routesNO } from 'api/routes';
import conversationClient from 'api/conversation-client';
import { getConversationsService } from 'pages/deck-page/_services';
import { DeckModal } from 'professional/components';
import {
    favouriteConversationsSelector,
    setFavouriteConversations,
} from 'store-modules/favourite-conversations';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import proClient from 'api/pro-client';
import userClient from 'api/user-client';
import useTypedLocation from 'hooks/use-typed-location';
import useTypedParams from 'hooks/use-typed-params';
import { proTaxonomies } from 'utils/pro-taxonomy-utils';

interface RouteParams {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}
interface LocationState {
    deck: Deck;
    randomQuestions: Question[];
    parentPage: string;
    searchString: string;
    viewAllSearchConvos?: boolean;
    conversationSearchResult: ConversationWithCategory[];
    questionSearchResult: Question[];
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesNO,
    },
];

const ProConversations: React.FC = () => {
    const dispatch = useDispatch();
    const isPremium = useSelector(isPremiumSelector);
    const language: Language = useSelector(languageSelector);
    const location = useTypedLocation<LocationState>();
    const navigate = useNavigate();
    const parentPage = useMemo<string>(() => location.state?.parentPage, []);
    const routes = returnRoutesForLanguage(language, routeObj);
    const user = useSelector(userSelector);

    const [questions, setQuestions] = useState<Question[]>([]);
    const [deck, setDeck] = useState<Deck | undefined>(
        location.state && location.state.deck
    );
    const [notFound, setNotFound] = useState(false);
    const params = useTypedParams<RouteParams>();
    const { taxonomy, deckSlug, questionSlug } = params;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const favouriteConversations = useSelector(favouriteConversationsSelector);
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [isConversationInFavourites, setIsConversationInFavourites] =
        useState<boolean>(false);
    const [isQuestionInFavourites, setIsQuestionInFavourites] =
        useState<boolean>(false);
    const [isLoadingFavourites, setIsLoadingFavourites] =
        useState<boolean>(false);

    const [isShuffled, setIsShuffled] = useState<boolean>(false);

    const [shuffledQuestions, setShuffledQuestions] = useState<Question[]>([]);

    const searchState = {
        searchString: location.state?.searchString,
        viewAllSearchConvos: location.state?.viewAllSearchConvos,
        conversationSearchResult: location.state?.conversationSearchResult,
        questionSearchResult: location.state?.questionSearchResult,
    };

    const closeDeck = (deck: Deck): void => {
        gtmEvent(GTM_EVENT.CLOSE_DECK, { 'deck.name': deck.name });

        if (!!parentPage) {
            if (parentPage === deckParentPages.proHome) {
                navigate(routes.proindex);
            }
            if (parentPage.includes(deckParentPages.proBrowse)) {
                const parentBrowsePath = `/${language}/pro/${parentPage}`;
                navigate(parentBrowsePath);
            }
        } else {
            navigate(routes.proindex);
        }
    };

    const shuffleQuestions = async () => {
        const shuffledQuestions = [...questions];
        let currentIndex = shuffledQuestions.length,
            randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [shuffledQuestions[currentIndex], shuffledQuestions[randomIndex]] =
                [
                    shuffledQuestions[randomIndex],
                    shuffledQuestions[currentIndex],
                ];
        }

        setShuffledQuestions(shuffledQuestions);
    };

    async function getUser(): Promise<void> {
        const user = await userClient.getCurrentUser();
        dispatch(setUserState(user!));
    }

    useEffect(() => {
        if (shuffledQuestions?.length === 0) {
            shuffleQuestions();
        }

        if (
            (!favouriteConversations || !favouriteQuestions) &&
            !isLoadingFavourites
        ) {
            setIsLoadingFavourites(true);
            Promise.all([
                conversationClient.getMyFavouriteConversations(),
                conversationClient.getMyFavouriteQuestions(),
            ]).then((response) => {
                dispatch(setFavouriteConversations(response[0].reverse()));
                dispatch(setFavouriteQuestions(response[1].reverse()));
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        if (favouriteConversations) {
            for (
                let index = 0;
                index < favouriteConversations.length;
                index++
            ) {
                const c = favouriteConversations[index];
                if (c.id === deck?.id) {
                    setIsConversationInFavourites(true);
                }
            }
        }

        if (deck && questions && !!questions.length) {
            return;
        }

        if (deck) {
            conversationClient
                .getQuestions(true, { taxonomy: deck.taxonomy, id: deck.id })
                .then(setQuestions)
                .catch(() => navigate(routes.error));

            return;
        }

        if (user) {
            if (proTaxonomies.includes(taxonomy)) {
                if (taxonomy === 'procustomer' || taxonomy === 'proffkunde') {
                    proClient
                        .getOrgCategories()
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .then((decks: any[]) => {
                            const deck = decks.find((d) => d.slug === deckSlug);

                            if (deck) {
                                setDeck(deck);
                            } else {
                                setNotFound(true);
                            }
                        })
                        .catch(() => navigate(routes.error));
                }
                if (taxonomy === 'procontent' || taxonomy === 'proffinnhold') {
                    proClient
                        .getProCategories()
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .then((decks: any[]) => {
                            const deck = decks.find((d) => d.slug === deckSlug);

                            if (deck) {
                                setDeck(deck);
                            } else {
                                setNotFound(true);
                            }
                        })
                        .catch(() => navigate(routes.error));
                }
            } else {
                const service = getConversationsService(isPremium, taxonomy);

                if (!service) {
                    setNotFound(true);
                    return;
                }

                service()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .then((decks: any[]) => {
                        const deck = decks.find((d) => d.slug === deckSlug);

                        if (deck) {
                            setDeck(deck);
                        } else {
                            setNotFound(true);
                        }
                    })
                    .catch(() => navigate(routes.error));
            }
        } else {
            getUser();
        }
        // eslint-disable-next-line
    }, [
        deck,
        questions,
        deckSlug,
        taxonomy,
        isPremium,
        location.state,
        questionSlug,
        routes,
        favouriteConversations,
        favouriteQuestions,
        isLoadingFavourites,
    ]);

    const toggleShuffle = () => {
        setIsShuffled(!isShuffled);
    };

    if (notFound) {
        return <Navigate to={routes.proindex} replace />;
    }

    if (!deck || !questions || isLoading) {
        return <Loading />;
    }

    const questionSlice = (isShuffled ? shuffledQuestions : questions).slice(
        0,

        deck && deck.created_by_id
            ? DECK_CONFIG.questionLimitRandom
            : DECK_CONFIG.questionLimit
    );

    return !deck || !questions || isLoading ? (
        <Loading />
    ) : (
        <DeckModal
            params={params}
            deck={deck}
            questions={questionSlice}
            onExit={() => closeDeck(deck)}
            parentPage={parentPage}
            isConversationInFavourites={isConversationInFavourites}
            isQuestionInFavourites={isQuestionInFavourites}
            favouriteQuestions={favouriteQuestions}
            favouriteConversations={favouriteConversations}
            setIsConversationInFavourites={setIsConversationInFavourites}
            setIsQuestionInFavourites={setIsQuestionInFavourites}
            isShuffled={isShuffled}
            toggleShuffle={toggleShuffle}
            searchState={searchState}
        />
    );
};

export default ProConversations;
