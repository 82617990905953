import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './theme-card.module.scss';
import { Conversation } from '_types';

type ThemeCard = {
    children?: React.ReactNode;
    className?: string;
    conversation?: Conversation;
    onClick: () => void;
};

const ThemeCard: FC<ThemeCard> = ({
    children,
    className,
    conversation,
    onClick,
}) => (
    <button
        className={classnames(styles.themeCard, className)}
        onClick={onClick}
    >
        {children}
        {!children && conversation && <span>{conversation.name}</span>}
    </button>
);

export default ThemeCard;
