import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const prodEnv = window.location.href.indexOf('app') !== -1;
const composer = prodEnv
    ? // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('redux-devtools-extension/developmentOnly').composeWithDevTools
    : // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('redux-devtools-extension').composeWithDevTools;

const composeEnhancers = composer({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export type RootReducer = ReturnType<typeof rootReducer>;

export default store;
