import styles from './favourite-questions-list-page.module.scss';
import { Language } from '_types/language';
import conversationClient from 'api/conversation-client';
import { FavouriteQuestionBox } from 'components/favourite-question-box';
import { Loading } from 'components/loading';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { SEO } from 'components/seo';
import { deckParentPages, Question } from '_types';
import classnames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import { useState, useEffect } from 'react';
import React from 'react';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import { useDispatch, useSelector } from 'react-redux';
import {
    scrollPosSelectorFavouriteQuestionsPage,
    SetScrollPosActionFavouriteQuestionsPage,
    setScrollPosFavouriteQuestionsPage,
} from 'store-modules/app-settings';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import { BackButton } from 'components/back-button';
import { ToggleConfirmation } from 'components/toggle-confirmation';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { IS_IOS_APP } from './../../constants';
import { setToastMessage, toastMessageSelector } from 'store-modules/user';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import { primaryKeyPressed } from 'utils/keyboard-utils';

interface Props {
    language: Language;
}

interface ViewCopy {
    seoTitle: string;
    buttonText: string;
    cancelText: string;
    title: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Likte spørsmål',
    buttonText: 'Fjern Spørsmål',
    cancelText: 'Avbryt',
    title: 'Fjern fra likte',
};

const enCopy: ViewCopy = {
    seoTitle: 'Liked questions',
    buttonText: 'Remove Question',
    cancelText: 'Cancel',
    title: 'Remove from liked',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const FavouriteQuestionsListPage: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { seoTitle, title, buttonText, cancelText } = returnCopyForLanguage(
        props.language,
        copies
    );
    const hash = useLocation().hash.substring(1);
    const routes = returnRoutesForLanguage(props.language, routeObj);
    const [isLoading, setIsLoading] = useState(true);
    const questions = useSelector(favouriteQuestionsSelector);
    const scrollPosition = useSelector(scrollPosSelectorFavouriteQuestionsPage);
    const toastMessage = useSelector(toastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);

    const [
        showToggleFavouriteConfirmation,
        setShowToggleFavouriteConfirmation,
    ] = useState<boolean>(false);
    const [questionToRemove, setQuestionToRemove] =
        useState<Question | null>(null);

    const dispatch = useDispatch();

    const { isiOS } = getUAInfo(window.navigator);

    const clearScrollPosFavouriteQuestionsPage =
        (): SetScrollPosActionFavouriteQuestionsPage =>
            dispatch(setScrollPosFavouriteQuestionsPage({ x: 0, y: 0 }));

    const onExit = () => {
        dispatch(clearScrollPosFavouriteQuestionsPage);
        navigate(routes.myLibrary);
    };

    const hideToast = () => {
        dispatch(setToastMessage(''));
        setToastToShow('');
    };

    useEffect(() => {
        return () => {
            if (toastMessage != '') {
                setTimeout(() => {
                    hideToast();
                }, 4000);
            }
        };
    });

    useEffect(() => {
        async function fetchFavourites(): Promise<void> {
            if (hash && hash.length) {
                const anchorElement = document.getElementById(hash);
                if (anchorElement) anchorElement.scrollIntoView();
            }

            Promise.all([conversationClient.getMyFavouriteQuestions()]).then(
                (response) => {
                    dispatch(setFavouriteQuestions(response[0].reverse()));
                    if (response.length > 0) {
                        setIsLoading(false);
                    }
                }
            );
        }
        if (!questions) {
            fetchFavourites();
        } else {
            setIsLoading(false);
        }
        if (scrollPosition) {
            //timeout hack to grab correct element
            setTimeout(function () {
                window.scrollTo({
                    top: scrollPosition.y,
                    left: scrollPosition.x,
                });
            }, 2);
        }
    }, [hash, dispatch, questions, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    const initiateRemoveQuestion = (question: Question) => {
        setShowToggleFavouriteConfirmation(true);
        setQuestionToRemove(question);
    };

    const removeFavouriteQuestion = async (question: Question | null) => {
        setShowToggleFavouriteConfirmation(false);
        const questionsCopy = questions?.slice();
        if (question) {
            if (questionsCopy) {
                questionsCopy.splice(questionsCopy.indexOf(question), 1);
                dispatch(setFavouriteQuestions(questionsCopy));
            }

            try {
                await conversationClient.removeFavouriteQuestion({
                    question_id: question.id,
                });
            } catch (err) {
                console.error('Could not remove question from favourites', err);
            }
        }
    };
    const onSelectQuestion = () => {
        dispatch(
            setScrollPosFavouriteQuestionsPage(getWindowScrollPosition(window))
        );
    };

    const favouriteQuestionsList = questions?.map((q) => {
        return (
            <FavouriteQuestionBox
                onRemoveQuestion={initiateRemoveQuestion}
                onQuestionSelect={onSelectQuestion}
                key={q.id}
                question={q}
                color={styles.bright}
                className={styles.QuestionBoxes}
                allQuestions={questions}
                index={questions.indexOf(q)}
                parentPage={deckParentPages.likedQuestionList}
                containerClassName={styles.FavouriteHeartContainers}
            >
                {q.text}
            </FavouriteQuestionBox>
        );
    });

    const toastMessageItem = toastToShow.length > 0 && (
        <div
            tabIndex={0}
            className={styles.ToastContainer}
            onClick={() => hideToast()}
            role='button'
            onKeyDown={(e) => {
                if (primaryKeyPressed(e)) {
                    hideToast();
                }
            }}
        >
            <ToastMessage
                className={
                    toastToShow.length > 0
                        ? styles.RemovedQuestionToast
                        : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
            />
        </div>
    );

    const favouriteQuestionsContainer = (
        <section
            className={classnames(
                styles.FavouriteQuestionsContainer,
                IS_IOS_APP && styles.isiOS
            )}
        >
            {/* <div className={styles.headerContainer}> */}
            <section className={styles.BackButtonContainer}>
                <BackButton
                    language={props.language}
                    className={classNames(
                        styles.backButton,
                        isiOS && styles.backButtoniOS
                    )}
                    clickHandler={onExit}
                />
            </section>
            <div className={styles.headerContainer}>
                <h2 className={styles.title}>{seoTitle}</h2>
            </div>
            <section className={styles.FavouriteQuestionsInnerContainer}>
                {favouriteQuestionsList}
            </section>
        </section>
    );

    return (
        <div className={styles.FavouriteQuestionListPageContainer}>
            <div className={styles.bg} />
            {toastMessageItem}
            <section className={styles.FavouriteQuestionsListPage}>
                <SEO title={seoTitle} />

                {favouriteQuestionsContainer}
                {showToggleFavouriteConfirmation && (
                    <ToggleConfirmation
                        title={title}
                        description={questionToRemove!.text}
                        cancelLabel={cancelText}
                        buttonLabel={buttonText}
                        onSubmit={() =>
                            removeFavouriteQuestion(questionToRemove)
                        }
                        onCancel={() =>
                            setShowToggleFavouriteConfirmation(false)
                        }
                    ></ToggleConfirmation>
                )}
            </section>
        </div>
    );
};

export default FavouriteQuestionsListPage;
