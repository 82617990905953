import React, { FC, ForwardedRef, forwardRef, useState } from 'react';
import classNames from 'classnames';
import MuiCheckbox, {
    CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import FormControlLabel, {
    FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { BORDER_WIDTH } from 'style/theme';

export type CheckboxProps = Omit<
    MuiCheckboxProps,
    'icon' | 'checkedIcon' | 'classes' | 'indeterminateIcon' | 'size'
> &
    Pick<FormControlLabelProps, 'label'>;

const StyledFormControlLabel = styled(FormControlLabel)`
    padding-right: ${({ theme }) => theme.spacing()};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;

    &.focusVisible {
        outline: ${BORDER_WIDTH} dotted;
    }
`;

const StyledCheckbox = styled(MuiCheckbox)`
    color: ${({ theme }) =>
        theme.palette.mode === 'dark'
            ? theme.palette.text.primary
            : theme.palette.primary.main};

    &.Mui-checked:not(.Mui-disabled) {
        color: ${({ theme }) =>
            theme.palette.mode === 'dark'
                ? theme.palette.text.primary
                : theme.palette.primary.main};
    }
`;

export const Checkbox: FC<CheckboxProps> = forwardRef<
    HTMLButtonElement,
    CheckboxProps
>(({ onFocusVisible, onBlur, disabled, label, ...rest }, ref) => {
    const [focusVisible, setFocusVisible] = useState(false);

    return (
        <StyledFormControlLabel
            className={classNames({ focusVisible })}
            control={
                <StyledCheckbox
                    inputRef={ref as ForwardedRef<HTMLInputElement>}
                    size='small'
                    onFocusVisible={(e) => {
                        setFocusVisible(true);
                        onFocusVisible && onFocusVisible(e);
                    }}
                    onBlur={(e) => {
                        setFocusVisible(false);
                        onBlur && onBlur(e);
                    }}
                    {...rest}
                />
            }
            disabled={disabled}
            label={label}
        />
    );
});
