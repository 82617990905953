import { Breakpoint } from 'components/base';
import { useScreenSize } from 'hooks/use-screen-size';

const colCounts: Record<Breakpoint, number> = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 5,
};

export const colSpans = Object.entries(colCounts).reduce(
    (acc, [breakpoint, colCount]) =>
        colCount ? { ...acc, [breakpoint]: 12 / colCount } : acc,
    {}
);

export const useWindowGridColumnCount = () => {
    const { breakpoint } = useScreenSize();
    return colCounts[breakpoint];
};
