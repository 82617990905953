import applePayClient from 'api/apple-pay-client';
import { User } from '_types/user';
import restClient from '../../../api/rest-client';
import { Discount } from '../_types';

const BASE_URL = '/wp-json/fuelbox/v1/';
const API_GET_SESSION = 'createOrder';
const API_GET_DISCOUNT_PRICE = 'getPrice';

interface SessionRequest {
    productId: string | number;
    code?: string;
}

export interface SessionResponse {
    token: string;
    url: string;
}

interface CreateOrderResponse {
    token: string;
    url: string;
    discountPrice: number;
    discountStatus: string;
}

interface GetDiscountedPriceRequest {
    discountCode: string;
    productId: string;
}
interface GetDiscountedPriceResponse {
    discountPrice: number;
    discountStatus: string;
}

function prepareParameters(
    productId: string | number,
    discountCode?: string
): SessionRequest {
    const params: SessionRequest = {
        productId,
    };

    if (discountCode) params.code = discountCode;

    return params;
}

async function getResponse(
    parameters: SessionRequest
): Promise<CreateOrderResponse | undefined> {
    try {
        return await restClient.get<CreateOrderResponse>(
            API_GET_SESSION,
            false,
            parameters,
            null,
            {
                baseUrl: BASE_URL,
            }
        );
    } catch (error) {
        return undefined;
    }
}

function createSessionFromResponse(
    response: CreateOrderResponse
): SessionResponse {
    return {
        token: response.token,
        url: response.token,
    };
}

function createDiscountFromResponse(
    response: CreateOrderResponse,
    discountCode: string | undefined
): Discount | undefined {
    if (!discountCode) {
        return undefined;
    }

    if (!!response.discountPrice) {
        return {
            discountError: false,
            discountedPrice: response.discountPrice,
        };
    }

    return { discountError: true };
}

export const getDiscountedPrice = async (
    discountCode: string,
    productId: string
): Promise<GetDiscountedPriceResponse> => {
    try {
        const params: GetDiscountedPriceRequest = {
            discountCode,
            productId,
        };

        return await restClient.get<GetDiscountedPriceResponse>(
            API_GET_DISCOUNT_PRICE,
            false,
            params,
            null,
            {
                baseUrl: BASE_URL,
            }
        );
    } catch (error) {
        throw error;
    }
};

export const requestSession = async (
    user: User,
    productId: string | number,
    discountCode: string | undefined
): Promise<[SessionResponse | undefined, Discount | undefined]> => {
    if (!user || !productId) throw new Error();

    const params = prepareParameters(productId, discountCode);

    return getResponse(params).then((res) => {
        if (
            !res ||
            (res.discountStatus && res.discountStatus !== 'Coupon applied')
        ) {
            return [undefined, { discountError: true }];
        }

        const session = createSessionFromResponse(res);
        const discount = createDiscountFromResponse(res, discountCode);

        return [session, discount];
    });
};

export const getApplePayOrderData = async (
    productId: string | number | undefined,
    code?: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
    try {
        const orderData = await applePayClient.createOrder({ productId, code });
        const { client_secret } = await applePayClient.paymentIntent(orderData);
        return { client_secret, ...orderData };
    } catch (error) {
        throw error;
    }
};

export const confirmApplePayOrder = async (
    order_id: string | number
): Promise<boolean> => {
    try {
        return await applePayClient.confirmOrder({ order_id });
    } catch (err) {
        throw err;
    }
};
