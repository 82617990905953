import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { openEndSessionDialog } from './collaboration-events';
import EndSessionDialog from './components/end-session-dialog';
import SessionView from './components/session-view';
import StartView from './components/start-view';
import CollaborationBasePage from './collaboration-base-page';

const InteractiveCollaborationPage: FC = () => (
    <CollaborationBasePage interactiveMode onCloseModal={openEndSessionDialog}>
        <Routes>
            <Route path=':questionSlug' element={<SessionView />} />
            <Route index element={<StartView />} />
        </Routes>

        <EndSessionDialog />
    </CollaborationBasePage>
);

export default InteractiveCollaborationPage;
