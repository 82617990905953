import React, { FC } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import classNames from 'classnames';

import { alpha, Box, styled, Typography } from 'components/base';
import { DisplayAs, getQuestionDocumentRef } from 'utils/collaboration-utils';
import { getUAInfo } from 'services/user-agent.service';

import SessionGrid from './session-grid';
import SessionWordcloud from './session-wordcloud';
import useSessionQuestion from '../use-session-question';
import useCollaboration from '../../../use-collaboration';

const { isWindows } = getUAInfo(window.navigator);

const ContentWrapper = styled(Box)`
    transition: ${({ theme }) =>
        `${theme.transitions.duration.standard}ms, ${theme.transitions.create(
            'opacity',
            {
                duration: theme.transitions.duration.shortest,
                delay: theme.transitions.duration.standard,
            }
        )}`};

    overflow: auto;

    &.isWindows * {
        ::-webkit-scrollbar {
            width: 20px;
        }

        ::-webkit-scrollbar-thumb,
        ::-webkit-scrollbar-track {
            background-clip: content-box;
            border-radius: 99px;
            border: 5px solid transparent;
        }

        ::-webkit-scrollbar-track {
            background-color: ${({ theme }) =>
                alpha(theme.palette.text.primary, 0.2)};
        }

        ::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) =>
                alpha(theme.palette.text.primary, 0.5)};
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: ${({ theme }) =>
                alpha(theme.palette.text.primary, 0.7)};
        }

        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
`;

const QuestionContent: FC = () => {
    const {
        answers,
        question: { id, text },
    } = useSessionQuestion();

    const { collaborationId } = useCollaboration();

    const questionRef = getQuestionDocumentRef(collaborationId, id);

    const [q] = useDocumentData(questionRef as never);
    const { display } = q || {};

    const hasAnswers = answers && answers.length > 0;

    return (
        <Box
            display='flex'
            flexDirection='column'
            width={1}
            alignItems='center'
        >
            <Box margin='auto' maxWidth={{ xs: 540, lg: 580 }}>
                <Typography
                    align='center'
                    alignItems='center'
                    component='span'
                    display='flex'
                    flex={hasAnswers ? undefined : 1}
                    fontSize={
                        hasAnswers ? { xs: 18, sm: 20, md: 24 } : undefined
                    }
                    justifyContent='center'
                    pb={hasAnswers ? { xs: 1, md: 3 } : 0}
                    px={hasAnswers ? 5 : 0}
                    sx={{ transition: '500ms' }}
                    variant={hasAnswers ? undefined : 'question'}
                >
                    {text}
                </Typography>
            </Box>

            <ContentWrapper
                className={classNames({ isWindows })}
                flex={hasAnswers ? 1 : undefined}
                height={hasAnswers ? undefined : 0}
                maxWidth={1600}
                width={1}
                sx={{
                    opacity: hasAnswers ? 1 : 0,
                }}
                mb={
                    hasAnswers && display === DisplayAs.FREE_TEXT
                        ? {
                              xs: 2 * -2,
                              md: 3 * -2,
                          }
                        : undefined
                }
                pb={
                    hasAnswers && display === DisplayAs.FREE_TEXT
                        ? {
                              xs: 2,
                              md: 3,
                          }
                        : undefined
                }
            >
                {hasAnswers &&
                    (display === DisplayAs.WORDCLOUD ? (
                        <SessionWordcloud />
                    ) : (
                        <SessionGrid questionId={id} />
                    ))}
            </ContentWrapper>
        </Box>
    );
};
export default QuestionContent;
