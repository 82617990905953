import * as React from 'react';
import styles from './steps.module.scss';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';

type Props = {
    current: number;
    total: number;
    language: Language;
};

interface ViewCopy {
    stepText1: string;
    stepText2: string;
}

const nbCopy: ViewCopy = {
    stepText1: 'Trinn',
    stepText2: 'av',
};

const enCopy: ViewCopy = {
    stepText1: 'Step',
    stepText2: 'of',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const Steps: React.FC<Props> = ({ current, total, language }: Props) => {
    const { stepText1, stepText2 } = returnCopyForLanguage(language, copies);
    return (
        <div data-testid='component-steps' className={styles.Steps}>
            {stepText1} {current} {stepText2} {total}
        </div>
    );
};

export default React.memo(Steps);
