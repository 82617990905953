import { createContext } from 'react';
import SwiperInstance from 'swiper';

export type SwiperContextValues = {
    activeIndex?: number;
    nextRef: React.MutableRefObject<null>;
    /**
     * One entry per slide. Indices of slides that should count in the pagination should be `true`, whereas those that should not should be `false`.
     */
    paginationDefs: boolean[];
    paginationRef: React.MutableRefObject<null>;
    prevRef: React.MutableRefObject<null>;
    setSwiper: (s: SwiperInstance) => void;
    swiper?: SwiperInstance;
};

const SwiperContext = createContext<SwiperContextValues>({
    prevRef: {} as React.MutableRefObject<null>,
    nextRef: {} as React.MutableRefObject<null>,
    paginationDefs: [],
    paginationRef: {} as React.MutableRefObject<null>,
    setSwiper: () => undefined,
    swiper: {} as SwiperInstance,
});

export default SwiperContext;
