import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { CustomColors } from '_types/mui';

export const Typography = styled(MuiTypography)`
    ${({ color, theme }) =>
        color &&
        typeof color === 'string' &&
        theme.palette.custom.hasOwnProperty(color) &&
        `color: ${theme.palette.custom[color as keyof CustomColors]};`}
` as typeof MuiTypography;
