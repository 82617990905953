import { useTranslation } from 'react-i18next';

import { Collaboration, CollaborationStatus } from 'api/collaboration-client';
import useHistoryRouter from 'hooks/use-history-router';
import {
    AnsweredQuestion,
    isFinished,
    isUnfinished,
} from 'utils/collaboration-utils';

const useCollaborationFunctions = ({
    slug,
    collaborationStatus,
}: Collaboration) => {
    const { t } = useTranslation();
    const { routes } = useHistoryRouter();

    const routeBase = routes.collaborationOverview;

    /**
     * @returns Path for static display of collaboration data
     */
    const generateReviewPath = () =>
        [routeBase, routes.collaborationReview, slug].join('/');

    /**
     * @returns Path for existing, active session
     */
    const generatePathForExistingSession = (questionSlug?: string) =>
        questionSlug
            ? [routeBase, slug, questionSlug].join('/')
            : [routeBase, slug].join('/');

    /**
     * @returns Path to existing session if active, otherwise to static review mode
     */
    const generatePath = (questionSlug?: string) =>
        collaborationStatus === CollaborationStatus.ACTIVE
            ? generatePathForExistingSession(questionSlug)
            : generateReviewPath();

    const getProgressLabel = (
        questionCount: number,
        answeredQuestions?: AnsweredQuestion[],
        displayFinishedLabel?: boolean
    ) => {
        if (collaborationStatus === CollaborationStatus.ACTIVE) {
            return t('collaboration.status', { context: 'active' });
        }

        if (isUnfinished(questionCount, answeredQuestions))
            return t('collaboration.questionsAnsweredCount', {
                answered: answeredQuestions?.length ?? 0,
                total: questionCount,
            });

        if (
            displayFinishedLabel &&
            isFinished(questionCount, answeredQuestions)
        )
            return t('finished');

        return undefined;
    };

    return {
        generatePath,
        generatePathForExistingSession,
        generateReviewPath,
        getProgressLabel,
    };
};

export default useCollaborationFunctions;
