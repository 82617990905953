import React, { useRef, FC, useMemo } from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';

import { Box, Breakpoint, useTheme } from 'components/base';
import { useScreenSize } from 'hooks/use-screen-size';

import useSessionQuestion from '../../use-session-question';
import { useUpdateWordcloud } from './use-update-wordcloud';

type WordMap = Record<string, Word>;

const SessionWordcloud: FC = () => {
    const {
        answers,
        question: { id },
    } = useSessionQuestion();

    const theme = useTheme();

    const wordcloudRef = useRef<HTMLDivElement | null>(null);

    // Transform answer list into WordMap structure to use with ReactWordcloud
    const wordMap = useMemo<WordMap>(
        () =>
            answers?.reduce((acc: WordMap, { text }) => {
                // Filter away answers of 25 characters and up
                if (!text || text.length > 25) return acc;

                const lower = text.toLowerCase();

                return acc.hasOwnProperty(lower)
                    ? {
                          ...acc,
                          [lower]: {
                              ...acc[lower],
                              value: acc[lower].value + 1,
                          },
                      }
                    : {
                          ...acc,
                          [lower]: { text: lower, value: 1 },
                      };
            }, {}) ?? {},
        [answers]
    );

    const words = Object.values(wordMap);
    const maxCount = Math.max(...words.map(({ value }) => value));

    useUpdateWordcloud(id, wordcloudRef, answers?.length ?? 0);

    const { breakpoint } = useScreenSize();

    const wordcloudResponsiveOptionMap: Record<
        Breakpoint,
        Record<string, unknown>
    > = {
        xs: {
            padding: words.length < 20 ? 4 : 2,
            fontSizes: [
                Math.max(10, 18 - (maxCount ? Math.log(maxCount) * 2 : 0)),
                Math.min(30, 22 + (maxCount ? Math.log(maxCount) * 2 : 0)),
            ],
        },
        sm: {
            padding: words.length < 20 ? 6 : 2,
            fontSizes: [
                Math.max(12, 24 - (maxCount ? Math.log(maxCount) * 4 : 0)),
                Math.min(38, 26 + (maxCount ? Math.log(maxCount) * 2 : 0)),
            ],
        },
        md: {
            padding: words.length < 20 ? 8 : 4,
            fontSizes: [
                Math.max(16, 28 - (maxCount ? Math.log(maxCount) * 6 : 0)),
                Math.min(48, 32 + (maxCount ? Math.log(maxCount) * 4 : 0)),
            ],
        },
        lg: {
            padding: words.length < 20 ? 10 : 6,
            fontSizes: [
                Math.max(20, 32 - (maxCount ? Math.log(maxCount) * 6 : 0)),
                Math.min(60, 40 + (maxCount ? Math.log(maxCount) * 6 : 0)),
            ],
        },
        xl: {
            padding: words.length < 20 ? 16 : 6,
            fontSizes: [
                Math.max(22, 40 - (maxCount ? Math.log(maxCount) * 8 : 0)),
                Math.min(72, 48 + (maxCount ? Math.log(maxCount) * 6 : 0)),
            ],
        },
    };

    return (
        <Box
            height={1}
            width={1}
            ref={wordcloudRef}
            minWidth={100}
            minHeight={100}
        >
            <ReactWordcloud
                options={{
                    colors: [
                        theme.palette.common.white,
                        theme.palette.custom.conversation.green,
                        theme.palette.custom.red[80],
                        theme.palette.custom.conversation.yellow,
                    ],
                    deterministic: true,
                    enableOptimizations: true,
                    enableTooltip: false,
                    fontFamily: theme.typography.fontFamily,
                    scale: 'log',
                    spiral: 'archimedean',
                    transitionDuration: 500,
                    rotationAngles: [0, -90],
                    rotations: words.length > 6 ? 2 : 1,
                    ...wordcloudResponsiveOptionMap[breakpoint],
                }}
                words={Object.values(wordMap)}
            />
        </Box>
    );
};

export default SessionWordcloud;
