import { Button, Icon } from 'components/base';
import { InputRow } from 'components/inputs';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import authService from 'services/auth-service';
import styles from './company-signup-form-subscription.module.scss';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { CompanySignupFormValues } from 'store-modules/user';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { useWindowSize } from 'hooks';
import { StorageKeys, removeLocalStorageData } from 'models/storage';

const requiredValidator = (value: string | undefined | null): boolean =>
    !!value && value.length > 0;
const emailValidator = (value: string): boolean =>
    authService.validateEmailFormat(value);

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    priceList: any;
    nextHandler: (input: CompanySignupFormValues) => void;
    activeContainer: number;
    formValues: CompanySignupFormValues | undefined;
    settingImage: () => void;
    numberOfUsers: number;
    verifiedUser?: boolean;
};
interface ViewCopy {
    title: string;
    labelEmail: string;
    labelSubmit: string;
    errorRequired: string;
    errorEmail: string;
    paymentLabel: string;
    paymentDescriptionLabel: string;
    poNumberLabel: string;
    optionalLabel: string;
    accessLabel: string;
    accessDescription1: string;
    accessDescription2: string;
    microsoftLabel: string;
    accessInputLabel: string;
    microsoftDescription: string;
    yesLabel: string;
    noLabel: string;
    tenantIDLabel: string;
    tenantIDBubbleText1: string;
    tenantIDBubbleText2: string;
    tenantIDBubbleText3: string;
    tenantIDBubbleText4: string;
    tenantIDPlaceHolder: string;
    logoHeaderLabel: string;
    logoDescription: string;
    logoHelpText: string;
    uploadLogoButtonLabel: string;
    changeLogoButtonLabel: string;
    removeLogoButtonLabel: string;
    fileTypeError: string;
    fileSizeError: string;
    yourPriceLabel: string;
    numberOfUsersLabel: string;
    discountLabel: string;
    currencyLabel: string;
    yearLabel: string;
    priceDescription: string;
}

const nbCopy: ViewCopy = {
    title: 'Abonnement',
    labelEmail: 'Epostadresse',
    labelSubmit: 'Opprett abonnement',
    errorRequired: 'Obligatorisk felt',
    errorEmail: 'Dette ser ikke ut som en fullstendig epostadresse',
    paymentLabel: 'Betaling',
    paymentDescriptionLabel:
        'Abonnementet betales årlig, faktura sendes som pdf på epost.',
    poNumberLabel: 'PO nummer / Referansenummer',
    optionalLabel: 'Valgfritt',
    accessLabel: 'Tilgangsstyring',
    accessDescription1:
        'Begrens tilgangen til abonnementet ved å kun tillate personer med epostadresser knyttet til din bedrift. Bruk komma for å legge til flere.',
    accessDescription2: 'Format: bedrift.no, bedrift.se',
    accessInputLabel: 'F.eks. bedrift.no',
    microsoftLabel: 'Microsoft 365 innlogging',
    microsoftDescription:
        'Har bedriften din epostadresser knyttet til Microsoft 365 og ønsker å bruke disse til innlogging?',
    yesLabel: 'Ja',
    noLabel: 'Nei',
    tenantIDLabel: 'Tenant ID',
    tenantIDBubbleText1: 'Tenant ID kan du finne i ',
    tenantIDBubbleText2: 'Azure AD > Overview > Tenant Information',
    tenantIDBubbleText3: 'Format: 8555b51d-6f6d-42cd-843c-daa1c25fd5ee',
    tenantIDBubbleText4: 'Kontakt din Microsoft Azure administrator for hjelp',
    tenantIDPlaceHolder: '8555b51d-6f6d-42cd-843c-daa1c25fd5ee',
    logoHeaderLabel: 'Logo',
    logoDescription:
        'Last opp logoen din om du ønsker et mer personlig uttrykk i appen.',
    logoHelpText: 'Maks størrelse: 2 MB',
    uploadLogoButtonLabel: 'Last opp bilde',
    changeLogoButtonLabel: 'Endre',
    removeLogoButtonLabel: 'Slett',
    fileTypeError: 'Du kan kun laste opp jpg eller png filer',
    fileSizeError: 'Bildet kan ikke være større enn 2MB',
    yourPriceLabel: 'Pris',
    numberOfUsersLabel: 'Antall brukere',
    discountLabel: 'Du får &{1}% rabatt!',
    currencyLabel: 'NOK',
    yearLabel: 'år',
    priceDescription:
        'Prisen settes etter antall brukere. Jo flere brukere du har jo høyere rabatt.',
};

const enCopy: ViewCopy = {
    title: 'Subscription',
    labelEmail: 'Email address',
    labelSubmit: 'Create subscription',
    errorRequired: 'Required field',
    errorEmail: "This doesn't look like a complete email address",
    paymentLabel: 'Payment',
    paymentDescriptionLabel:
        'The subscription is billed annually, the invoice is sent as a pdf by email.',
    poNumberLabel: 'PO number / Reference number',
    optionalLabel: 'Optional',
    accessLabel: 'Restricted access',
    accessDescription1:
        'Limit who has access to the subscription by only allowing people with email addresses connected to your company. Use commas to add multiple domains.',
    accessDescription2: 'Format: company.com, company.no',
    accessInputLabel: 'E.g. company.com',
    microsoftLabel: 'Microsoft 365',
    microsoftDescription:
        'Does your company have email accounts connected to Microsoft 365 and want to use these to log in?',
    yesLabel: 'Yes',
    noLabel: 'No',
    tenantIDLabel: 'Tenant ID',
    tenantIDBubbleText1: 'Tenant ID can be found in ',
    tenantIDBubbleText2: 'Azure AD > Overview > Tenant Information',
    tenantIDBubbleText3: 'Format: 8555b51d-6f6d-42cd-843c-daa1c25fd5ee',
    tenantIDBubbleText4: 'Contact your Microsoft Azure administrator for help',
    tenantIDPlaceHolder: '8555b51d-6f6d-42cd-843c-daa1c25fd5ee',
    logoHeaderLabel: 'Logo',
    logoDescription:
        'Upload your logo if you want a more personalised look to the app.',
    logoHelpText: 'Max size 2 MB',
    uploadLogoButtonLabel: 'Upload image',
    changeLogoButtonLabel: 'Change',
    removeLogoButtonLabel: 'Remove',
    fileTypeError: 'You can only upload jpg or png files',
    fileSizeError: 'The file size can not exceed 2MB',
    yourPriceLabel: 'Your price',
    numberOfUsersLabel: 'Number of users',
    discountLabel: 'Du får &{1}% rabatt!',
    currencyLabel: 'NOK',
    yearLabel: 'year',
    priceDescription:
        'Prisen settes etter antall brukere. Jo flere brukere du har jo høyere rabatt.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CompanySignupFormUser: React.FC<Props> = ({
    priceList,
    activeContainer,
    nextHandler,
    formValues,
    settingImage,
    numberOfUsers,
    verifiedUser
}) => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        labelEmail,
        labelSubmit,
        accessLabel,
        errorRequired,
        errorEmail,
        paymentLabel,
        changeLogoButtonLabel,
        uploadLogoButtonLabel,
        accessDescription2,
        optionalLabel,
        yesLabel,
        noLabel,
        microsoftDescription,
        tenantIDLabel,
        accessDescription1,
        accessInputLabel,
        poNumberLabel,
        removeLogoButtonLabel,
        paymentDescriptionLabel,
        microsoftLabel,
        tenantIDBubbleText1,
        tenantIDBubbleText2,
        tenantIDBubbleText3,
        tenantIDBubbleText4,
        logoHeaderLabel,
        tenantIDPlaceHolder,
        logoDescription,
        logoHelpText,
        fileTypeError,
        fileSizeError,
        yourPriceLabel,
        numberOfUsersLabel,
        currencyLabel,
        yearLabel,
        priceDescription,
    } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const navigate = useNavigate();
    const routes = returnRoutesForLanguage(language, routeObj);
    const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(numberOfUsers);
    const [numberOfUsersError, setNumberOfUsersError] =
        useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(
        activeContainer !== 3
    );
    const [images, setImages] = useState<ImageListType>([]);
    const [showTenantIDInfoText, setShowTenantIDInfoText] =
        useState<boolean>(false);

    useEffect(() => {
        setIsCollapsed(activeContainer !== 3);
    }, [activeContainer]);

    const onChange = (imageList: ImageListType) => {
        settingImage();
        setImages(imageList as never[]);
    };

    const onSubmitForm = async (values: CompanySignupFormValues) => {
        if (totalNumberOfUsers <= 0) {
            setNumberOfUsersError(true);
            return;
        }
        const billingEmailError =
            (!requiredValidator(values.billingEmail) && errorRequired) ||
            (values.billingEmail &&
                !emailValidator(values.billingEmail) &&
                errorEmail);
        const azureLoginError =
            !requiredValidator(values.azureLogin) && errorRequired;
        const tenantIDError =
            values.azureLogin === 'yes' &&
            !requiredValidator(values.tenantID) &&
            errorRequired;

        if (billingEmailError || azureLoginError || tenantIDError) {
            return {
                billingEmail: billingEmailError,
                azureLogin: azureLoginError,
                tenantID: tenantIDError,
            };
        }

        try {
            const completeValues =
                images.length > 0
                    ? { ...values, image: images[0].dataURL }
                    : values;

            await nextHandler(completeValues);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            navigate(routes.error);
        }
    };

    const onNumberOfUsersInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setTotalNumberOfUsers(Number(event.target.value));
        },
        []
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPriceObject = (): any => {
        for (let i = 0; i < priceList.length; i++) {
            const priceObject = priceList[i];
            if (!priceObject.max_users) {
                return priceObject;
            }
            if (
                totalNumberOfUsers >= priceObject.min_users &&
                totalNumberOfUsers <= priceObject.max_users
            ) {
                return priceObject;
            }
        }
        //Should never reach here if BE endpoint is correct.
        return 0;
    };

    const calculateFullPrice = (): number => {
        return totalNumberOfUsers * priceList[0].price_per_user;
    };

    const numberOfUsersToDisplay = (): number | string => {
        return totalNumberOfUsers < 1
            ? ''
            : parseInt(totalNumberOfUsers.toString());
    };

    const MyForm = (
        <Form
            onSubmit={onSubmitForm}
            render={({ values }) => (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    noValidate
                    className={styles.SubscriptionForm}
                >
                    <p className={styles.PaymentHeader}>{paymentLabel}</p>
                    <p className={styles.PaymentDescription}>
                        {paymentDescriptionLabel}
                    </p>
                    <div className={styles.InputContainer}>
                        <Field
                            name='billingEmail'
                            label={labelEmail}
                            type='email'
                            component={InputRow}
                            disabled={verifiedUser === true}
                            defaultValue={formValues?.billingEmail}
                        />
                    </div>

                    <div className={styles.InputContainer}>
                        <Field
                            name='referenceNumber'
                            label={poNumberLabel}
                            type='text'
                            component={InputRow}
                            defaultValue={formValues?.referenceNumber}
                        />
                    </div>
                    <p className={styles.InputHelpText}>{optionalLabel}</p>

                    <p className={styles.AccessHeader}>{accessLabel}</p>
                    <p className={styles.AccessDescription}>
                        {accessDescription1}
                    </p>
                    <p className={classNames(styles.AccessDescriptionSecond)}>
                        {accessDescription2}
                    </p>
                    <div className={styles.InputContainer}>
                        <Field
                            name='domains'
                            label={''}
                            type='text'
                            component={InputRow}
                            placeholder={accessInputLabel}
                            defaultValue={formValues?.domains}
                        />
                    </div>
                    <p className={styles.InputHelpText}>{optionalLabel}</p>

                    <p className={styles.MicrosoftHeader}>{microsoftLabel}</p>
                    <p>{microsoftDescription}</p>

                    <div className={styles.YesNoButtonContainer}>
                        <label className={styles.YesButton}>
                            <Field
                                name='azureLogin'
                                component='input'
                                type='radio'
                                value={'yes'}
                            />{' '}
                            {yesLabel}
                        </label>
                        <label className={styles.NoButton}>
                            <Field
                                name='azureLogin'
                                component='input'
                                type='radio'
                                value={'no'}
                                defaultValue={'no'}
                            />{' '}
                            {noLabel}
                        </label>
                    </div>

                    {values.azureLogin === 'yes' && (
                        <div className={styles.TenantIDContainer}>
                            <p className={styles.TenantIDHeader}>
                                {tenantIDLabel} &nbsp;
                                {
                                    <Button
                                        className={styles.HelpIcon}
                                        onMouseOver={() =>
                                            setShowTenantIDInfoText(true)
                                        }
                                        onMouseLeave={() => {
                                            setShowTenantIDInfoText(false);
                                        }}
                                        onClick={() =>
                                            setShowTenantIDInfoText(
                                                !showTenantIDInfoText
                                            )
                                        }
                                    >
                                        <Icon name={'help'} />
                                    </Button>
                                }
                            </p>
                            {showTenantIDInfoText && (
                                <div className={styles.Bubble}>
                                    <div className={styles.BubbleText}>
                                        <p>{tenantIDBubbleText1}</p>
                                        <p>{tenantIDBubbleText2}</p>
                                        <br />
                                        <p>{tenantIDBubbleText3}</p>
                                        <br />
                                        <p>{tenantIDBubbleText4}</p>
                                    </div>
                                </div>
                            )}

                            <div className={styles.TenantIDInputContainer}>
                                <Field
                                    name='tenantID'
                                    label={''}
                                    type='text'
                                    component={InputRow}
                                    placeholder={tenantIDPlaceHolder}
                                />
                            </div>
                            {/* <p className={styles.TenantIDHelpText}>
                                Format: 8555b51d-6f6d-42cd-843c-daa1c25fd5ee
                            </p> */}
                        </div>
                    )}

                    <p className={styles.LogoHeader}>{logoHeaderLabel}</p>
                    <p>{logoDescription}</p>
                    <p className={styles.LogoHelpText}>{logoHelpText}</p>

                    <div className={styles.ImageUploadContainer}>
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            maxNumber={1}
                            maxFileSize={2000000} //2MB
                            acceptType={['jpg', 'png']}
                        >
                            {({
                                imageList,
                                onImageRemoveAll,
                                onImageUpdate,
                                errors,
                            }) => (
                                <div className='upload__image-wrapper'>
                                    <div className={styles.LogoButtonContainer}>
                                        {!imageList ||
                                        imageList.length === 0 ? (
                                            <Button
                                                className={
                                                    styles.UploadImageButton
                                                }
                                                aria-label={'login-with-email'}
                                                leftIcon={'add'}
                                                onClick={
                                                    // errors
                                                    //     ? onImageUpdate(0)
                                                    () => {
                                                        onImageUpdate(0);
                                                    }
                                                }
                                            >
                                                {uploadLogoButtonLabel}
                                            </Button>
                                        ) : (
                                            <Button
                                                className={
                                                    styles.UpdateImageButton
                                                }
                                                aria-label={'login-with-email'}
                                                leftIcon={'edit'}
                                                onClick={() => onImageUpdate(0)}
                                            >
                                                {changeLogoButtonLabel}
                                            </Button>
                                        )}

                                        {imageList && imageList.length > 0 && (
                                            <Button
                                                className={
                                                    styles.RemoveImageButton
                                                }
                                                aria-label={'login-with-email'}
                                                leftIcon={'delete'}
                                                onClick={onImageRemoveAll}
                                            >
                                                {removeLogoButtonLabel}
                                            </Button>
                                        )}
                                    </div>
                                    {imageList.map((image, index) => (
                                        <div
                                            key={index}
                                            className={styles.ImageContainer}
                                        >
                                            <img
                                                src={image.dataURL}
                                                alt=''
                                                width='100'
                                                className={styles.Image}
                                            />
                                        </div>
                                    ))}
                                    {errors && (
                                        <div>
                                            {errors.acceptType && (
                                                <span
                                                    className={
                                                        styles.ImageErrorText
                                                    }
                                                >
                                                    {fileTypeError}
                                                </span>
                                            )}
                                            {errors.maxFileSize && (
                                                <span
                                                    className={
                                                        styles.ImageErrorText
                                                    }
                                                >
                                                    {fileSizeError}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    <Button
                        className={styles.createAccountButton}
                        appearance={'cta'}
                        type='submit'
                        onClick={() => {
                            removeLocalStorageData(StorageKeys.userRegistration)
                            onSubmitForm(values)
                        }}
                    >
                        {labelSubmit}
                    </Button>
                </form>
            )}
        />
    );

    const collapsedContainer = (
        <div className={styles.CollapsedContainer}>
            <div className={styles.LeftContainer}>
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{3}</div>
                    {!isDesktop && <PageTitle title={title} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={title} />}
                </div>
            </div>
        </div>
    );

    const expandedContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{3}</div>
                    {!isDesktop && <PageTitle title={title} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={title} />}
                    {!isDesktop && (
                        <div className={styles.PriceOuterContainer}>
                            <h3 className={styles.YourPriceHeader}>
                                {yourPriceLabel}
                            </h3>
                            <p>{priceDescription}</p>
                            <div className={styles.InputContainer}>
                                <div className={styles.NumberOfUsersInputLabel}>
                                    {numberOfUsersLabel}
                                </div>
                                <input
                                    className={
                                        numberOfUsersError
                                            ? styles.NumberOfUsersError
                                            : ''
                                    }
                                    type='number'
                                    value={numberOfUsersToDisplay()}
                                    onChange={onNumberOfUsersInputChanged}
                                    pattern='[0-9]*'
                                    min={1}
                                    maxLength={7}
                                    max={7}
                                />
                            </div>
                            <div className={styles.DiscountText}>
                                <Trans
                                    components={[<strong key={0} />]}
                                    i18nKey='companySelfSignupPage.discountText'
                                    values={{
                                        discount: getPriceObject().discount,
                                    }}
                                />
                            </div>
                            <div className={styles.PriceBorder}>{''}</div>
                            <div className={styles.PriceContainer}>
                                <div className={styles.PriceCurrencyContainer}>
                                    <div className={styles.Price}>
                                        {'= '}
                                        {getPriceObject().price_per_user *
                                            totalNumberOfUsers}
                                    </div>
                                    <div className={styles.PriceCurrency}>
                                        {' '}
                                        {currencyLabel}/{yearLabel}
                                    </div>
                                </div>
                                <div className={styles.DiscountPriceText}>
                                    <div className={styles.FullPrice}>
                                        {calculateFullPrice()}
                                    </div>
                                    <div className={styles.FullPriceCurrency}>
                                        {currencyLabel} / {yearLabel}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {MyForm}
                </div>
            </div>
        </div>
    );

    return (
        <article
            data-testid='component-create-account-form'
            className={styles.CompanySignupForm}
        >
            {isCollapsed ? collapsedContainer : expandedContainer}
        </article>
    );
};

export default React.memo(CompanySignupFormUser);
