import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';
import styles from './input-row.module.scss';

interface OwnProps {
    label: string;
    type: string;
    hint: string;
    errormsg: string;
    showToggle?: boolean;
    className?: string;
    placeholder?: string;
    startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    disabled?: boolean;
    generalError?: boolean;
}

type Props = OwnProps & FieldRenderProps<string>;

const InputRow = ({
    label,
    type,
    input,
    meta: { submitError },
    className,
    placeholder,
    startIcon: StartIcon,
    disabled = false,
}: Props): JSX.Element => {
    return (
        <div className={classnames(styles.InputRow, className)}>
            <label className={styles.InputRow__Label} htmlFor={label}>
                {label}
            </label>
            <div className={styles.InputRow__InputContainer}>
                {StartIcon && (
                    <span className={styles.InputRow__StartIcon}>
                        <StartIcon />
                    </span>
                )}
                <input
                    type={type}
                    id={label}
                    className={classnames(
                        styles.InputRow__Input,
                        StartIcon && styles.withStartIcon,
                        submitError && styles.invalid
                    )}
                    placeholder={placeholder}
                    {...input}
                    disabled={disabled}
                />
            </div>
            <span
                className={
                    submitError
                        ? styles.InputRow__Error__Show
                        : styles.InputRow__Error__Hidden
                }
            >
                {submitError}
            </span>
        </div>
    );
};

export default InputRow;
