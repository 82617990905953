import produce, { Draft } from 'immer';
import { AuthState } from './_types';
import { initialState } from './_initialState';
import { LOGIN, LOGOUT } from './_actionTypes';

// * Implements the producer from ImmerJS.
// Notice that it is not needed to handle the default case, a producer that doesn't do anything will simply return the original state.
const authReducer = produce(
    (draft: Draft<AuthState> = initialState, action): AuthState => {
        switch (action.type) {
            case LOGIN:
                draft.authenticated = true;
                draft.token = action.payload;
                break;
            case LOGOUT:
                draft.authenticated = false;
                draft.token = '';
                break;
        }
        return draft;
    }
);

export default authReducer;
