import * as React from 'react';
import { copyNO, copyEN } from './copy';
import styles from './faq.module.scss';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';

type Props = { language: Language };

interface ViewCopy {
    title: string;
    items: {
        title: string;
        copy: JSX.Element;
        anchor?: string;
    }[];
}
const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: copyNO,
    },
    {
        name: 'en',
        copy: copyEN,
    },
];

const Faq: React.FC<Props> = ({ language }: Props) => {
    const { title, items } = returnCopyForLanguage(language, copies);
    const contentBody = items.map(({ title, copy, anchor }, index) => {
        const titleId = `title-q-${index}`;

        const titleElement = !!anchor ? (
            <h3 id={anchor}>{title}</h3>
        ) : (
            <h3 id={titleId}>{title}</h3>
        );

        return (
            <React.Fragment key={index}>
                {titleElement}
                <section aria-labelledby={titleId}>{copy}</section>
            </React.Fragment>
        );
    });
    return (
        <section
            data-testid='component-faq'
            className={styles.container}
            aria-labelledby='faq-title'
        >
            <PageTitle id='faq-title' title={title} />

            {contentBody}
        </section>
    );
};

export default Faq;
