import React from 'react';
import { Button } from 'components/base';
import styles from './recover-account-confirmation-page.module.scss';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import { PageTitle } from 'components/page-title';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { languageSelector } from 'store-modules/app-settings';
import { returnRoutesForLanguage } from 'services/language-service';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preventDefault = (e: React.MouseEvent<any>): void => e.preventDefault();

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const RecoverAccountConfirmationPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.recoverAccountConfirmationPage}
            headerProps={{
                withAppbar: false,
                withAccount: false,
                showLogoLink: true,
            }}
        >
            <Container className={styles.container}>
                <PageTitle title={t('recoverAccountConfirmationPage.title')} />
                <p>{t('recoverAccountConfirmationPage.text')}</p>
                <Button
                    className={styles.button}
                    onClick={() => {
                        navigate(routes.iOSSubscriptions);
                    }}
                    onMouseDown={preventDefault}
                >
                    {t('recoverAccountConfirmationPage.button')}
                </Button>
            </Container>
        </Layout>
    );
};

export default RecoverAccountConfirmationPage;
