import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, History, Mousewheel, Keyboard } from 'swiper';

import { Box } from 'components/base';
import { SlideType } from 'utils/collaboration-utils';
import useSwiperContext from 'hooks/use-swiper-context';

import EndSlide from './end-slide';
import QuestionContent from './question-content';
import SessionQuestionProvider from './use-session-question/provider';
import styles from './session-view.module.scss';
import Toolbar from './toolbar';
import useSession from '../../use-session';
import useCollaboration from '../../use-collaboration';
import TopInfoCard from './session-info-display/top-info-card';

const SessionSwiper: FC = () => {
    const {
        collaboration: { slug },
    } = useCollaboration();
    const { setSwiper } = useSwiperContext();
    const { displayInfoInDrawer, slideDefs, toolbarRef } = useSession();
    const { t } = useTranslation();

    /**
     * Estimate of closed drawer height
     */
    const defaultBottomMargin = 100;

    return (
        <Fragment>
            {!displayInfoInDrawer && <TopInfoCard />}

            <Box
                display='flex'
                flex={1}
                flexDirection='column'
                height={1}
                justifyContent='flex-end'
            >
                <Box
                    flex={1}
                    width={1}
                    display='flex'
                    height={1}
                    sx={{
                        '.swiper-wrapper': {
                            height: `calc(100% - ${
                                toolbarRef.current?.scrollHeight ??
                                defaultBottomMargin
                            }px)`,
                            maxHeight: `calc(100% - ${
                                toolbarRef.current?.scrollHeight ??
                                defaultBottomMargin
                            }px)`,
                        },
                    }}
                >
                    {slideDefs && slideDefs.length > 0 && (
                        <Swiper
                            className={styles.swiper}
                            history={{
                                key: slug,
                                replaceState: true,
                            }}
                            keyboard
                            modules={[
                                History,
                                Keyboard,
                                Mousewheel,
                                Navigation,
                                Pagination,
                            ]}
                            mousewheel={{ forceToAxis: true }}
                            onBeforeInit={setSwiper}
                            onRealIndexChange={setSwiper}
                            slidesPerView={1}
                        >
                            {slideDefs.map((slideDef, index) => {
                                switch (slideDef.type) {
                                    case SlideType.QUESTION:
                                        const { question } = slideDef;

                                        return (
                                            <SwiperSlide
                                                className={styles.slide}
                                                data-history={question.slug}
                                                key={question.slug}
                                            >
                                                {({
                                                    isActive,
                                                    isNext,
                                                    isPrev,
                                                }) =>
                                                    isActive ||
                                                    isNext ||
                                                    isPrev ? (
                                                        <SessionQuestionProvider
                                                            isActive={isActive}
                                                            question={question}
                                                            index={index}
                                                        >
                                                            <Box
                                                                boxSizing='border-box'
                                                                display='flex'
                                                                flex={1}
                                                                p={{
                                                                    xs: 2,
                                                                    md: 3,
                                                                }}
                                                                width={1}
                                                            >
                                                                <QuestionContent />
                                                            </Box>
                                                        </SessionQuestionProvider>
                                                    ) : null
                                                }
                                            </SwiperSlide>
                                        );
                                    case SlideType.END:
                                        return (
                                            <SwiperSlide
                                                className={styles.slide}
                                                data-history={t(
                                                    'collaboration.endSlide.slug'
                                                )}
                                                key='end'
                                            >
                                                {({ isActive }) => (
                                                    <EndSlide
                                                        isActive={isActive}
                                                    />
                                                )}
                                            </SwiperSlide>
                                        );
                                    default:
                                        return null;
                                }
                            })}

                            <Toolbar />
                        </Swiper>
                    )}
                </Box>
            </Box>
        </Fragment>
    );
};

export default SessionSwiper;
