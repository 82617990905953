import { RouteList, routesEN, routesNO } from 'api/routes';
import { Button } from 'components/base';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { returnCopyForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';
import SettingsSubpage from '../settings-subpage/settings-subpage';
import styles from './notifications-page.module.scss';
import {
    UserNewsletterCheckbox,
    SaveHandle,
} from 'components/user-newsletter-checkbox';
import { setToastMessage } from 'store-modules/user';
import { useNavigate } from 'react-router-dom';
import { Stack } from 'components/base';

interface ViewCopy {
    pageTitle: string;
    title: string;
    buttonLabel: string;
    savedText: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Varslinger',
    title: 'Varslinger',
    buttonLabel: 'Lagre endringer',
    savedText: 'Dine valg er nå lagret',
};
const enCopy: ViewCopy = {
    pageTitle: 'Notifications',
    title: 'Notifications',
    buttonLabel: 'Save changes',
    savedText: 'Your settings have been saved',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const LanguagePage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { pageTitle, title, buttonLabel } = returnCopyForLanguage(
        language,
        copies
    );

    const dispatch = useDispatch();
    const ref = useRef<SaveHandle>(null);

    const navigateBack = (): void => {
        const selectedLanguageRouteObj = routeObj.find(
            (r) => r.name === language
        );
        if (!selectedLanguageRouteObj) return;
        const selectedLanguageRoute =
            selectedLanguageRouteObj.routes.account.index;
        navigate(selectedLanguageRoute);
    };

    const showToast = (): void => {
        dispatch(
            setToastMessage(returnCopyForLanguage(language, copies).savedText)
        );
    };

    const saveHandler = (): void => {
        ref.current!.save().then(() => {
            navigateBack();
            showToast();
        });
    };

    return (
        <SettingsSubpage pageTitle={pageTitle} title={title}>
            <Stack spacing={5} direction='column'>
                <UserNewsletterCheckbox ref={ref} />

                <Button
                    appearance='cta'
                    className={styles.cta}
                    onClick={saveHandler}
                >
                    {buttonLabel}
                </Button>
            </Stack>
        </SettingsSubpage>
    );
};

export default LanguagePage;
