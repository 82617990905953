import React from 'react';
import classnames from 'classnames';
import styles from './page-title.module.scss';

interface Props {
    id?: string;
    title: string;
    className?: string;
}

const PageTitle: React.FC<Props> = ({ id, title, className }: Props) => {

    return (
        <h2 id={id} className={classnames(styles.title, className)}>
            {title}
        </h2>
    );
};

export default React.memo(PageTitle);
