import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { ErrorPage } from 'pages/error-page';
import { FaqPage } from 'pages/faq-page';
import { JoinCollaborationPage } from 'pages/join-collaboration-page';
import { PrivacyPage } from 'pages/privacy-page';
import { TncPage } from 'pages/tnc-page';
import { useHasPermission, PermissionActionTypes } from 'permission';
import useHistoryRouter from 'hooks/use-history-router';

/**
 * Collection of routes that should be available regardless of authentication or subscription
 */
const useCommonRoutes = () => {
    const { language, routes } = useHistoryRouter();
    const hasPermission = useHasPermission();

    return (
        <Fragment>
            <Route
                path={routes.privacyTerms}
                element={<PrivacyPage language={language} />}
            />

            <Route
                path={routes.faq}
                element={<FaqPage language={language} />}
            />

            <Route
                path={routes.tnc}
                element={<TncPage language={language} />}
            />

            <Route path={routes.error} element={<ErrorPage />} />

            {/* TODO: Move to isolated app? */}
            {hasPermission(PermissionActionTypes.JOIN_COLLABORATION) && (
                <Route
                    path={`${routes.joinCollaboration}/*`}
                    element={<JoinCollaborationPage />}
                />
            )}
        </Fragment>
    );
};

export default useCommonRoutes;
