import { SetQuestionImageRequest, SetQuestionImageResponse } from '_types';
import restClient from './rest-client';

export default {
    setQuestionImage: (
        request: SetQuestionImageRequest
    ): Promise<SetQuestionImageResponse> => {
        return restClient.post<
            SetQuestionImageRequest,
            SetQuestionImageResponse
        >('/setQuestionImage', request, { baseUrl: '/wp-json/fuelbox/v1' });
    },
};
