import { Language } from '_types/language';
import { RouteList, routesNO, routesEN } from 'api/routes';
import {
    updateLanguage,
    ChangeLanguageAction,
} from 'store-modules/app-settings';
import { Dispatch } from 'redux';
import { getLocalStorageLanguage } from './language-service';
import { User } from '_types';

// Splits the route into an array of segment strings
const splitRoute = (location: string): string[] => location.split('/');

// Valid domains, covers test.app, staging.app, develop.app
const validUrls = (locationSplit: string): boolean => {
    return (
        locationSplit === 'localhost:3000' ||
        locationSplit === 'app.fuelbox.no' ||
        locationSplit === 'develop.app.fuelbox.no' ||
        locationSplit === 'test.app.fuelbox.no' ||
        locationSplit === 'staging.app.fuelbox.no' ||
        locationSplit === 'app.fuelboxworld.com' ||
        locationSplit === 'develop.app.fuelboxworld.com' ||
        locationSplit === 'staging.app.fuelboxworld.com' ||
        locationSplit === 'test.app.fuelboxworld.com'
    );
};

// Attempts to determine the intended route segments
const intendedRoute = (location: string): string[] => {
    const split: string[] = splitRoute(location);
    const indexToInsertAfter: number = split.findIndex(validUrls) + 1;
    const lastSegment = split.slice(indexToInsertAfter);
    const segments =
        lastSegment.length === 1 ? [`/${lastSegment[0]}`] : lastSegment;
    return segments;
};

// Rebuild the intended route from segments and language
const reconstructedRoute = (language: Language, segment: string[]): string => {
    const reconstructedUrlArray = [language.toString()].concat(segment);
    const reconstructedUrl = `/${reconstructedUrlArray.join('')}`;
    return reconstructedUrl;
};

// Looks for a matching path in the routeObjs
const routeObjContains = (
    routeObj: RouteList,
    routeToMatch: string[],
    language: Language
): boolean => {
    const routes = Object.values(routeObj);
    const routeMatch = `/${language}${routeToMatch.join('/')}`;
    const result = routes.indexOf(routeMatch) !== -1;

    return result;
};

// Reconstructs the url to be redirected to from segments
const redirectReconstructedRoute = (
    language: Language,
    route: string[],
    dispatch: Dispatch<ChangeLanguageAction>
): string => {
    dispatch(updateLanguage(language));
    return reconstructedRoute(language, route);
};

const redirectToAddProCode = (
    url: string,
    defaultLanguage: Language
): string => {
    return defaultLanguage === 'en'
        ? routesEN.proCodeSubPage
        : routesNO.proCodeSubPage;
};

const redirectToCreateUserProPage = (
    url: string,
    defaultLanguage: Language
): string => {
    return defaultLanguage === 'en'
        ? routesEN.createPremiumPro
        : routesNO.createPremiumPro;
};

const redirectToPublicQuestionInfoPage = (
    url: string,
    defaultLanguage: Language
): string => {
    const questionIdParam = '?' + url.split('?')[1];

    return defaultLanguage === 'en'
        ? routesEN.questionInfoPage + questionIdParam
        : routesNO.questionInfoPage + questionIdParam;
};

const redirectToConfirmation = (
    url: string,
    defaultLanguage: Language
): string => {
    const hasNO = url.indexOf('/nb/') !== -1;
    const hasEN = url.indexOf('/en/') !== -1;
    const parameters = '?' + url.split('?')[1];
    const language: Language = hasNO ? 'nb' : hasEN ? 'en' : defaultLanguage;
    return language === 'en'
        ? routesEN.userConfirm + parameters
        : routesNO.userConfirm + parameters;
};

// redirect to set new password by language
const redirectSetNewPassword = (url: string): string => {
    const isEn = url.indexOf('/en/') !== -1;
    const resetRoute = isEn ? routesEN.setNewPass : routesNO.setNewPass;
    const path = resetRoute + (window.location.search ?? '');
    return path;
};

// Looks for the matching route object to return for uncaught matches
const findRouteFromObj = (
    url: string,
    routeObj: RouteList
): string | undefined => {
    const split: string[] = splitRoute(url);
    const indexToInsertAfter: number = split.findIndex(validUrls) + 1;
    const segments = split.slice(indexToInsertAfter);
    const routeToMatch = `/${segments.join('/')}`;
    // *Note: Uncomment if issues with account routes.
    // const isAccountRoute =
    //     segments[1] === 'account' || segments[1] === 'min-konto';
    // if (isAccountRoute) {
    //     const accountRoutes: string[] = Object.values(routeObj.account);
    //     const foundIndex = accountRoutes.indexOf(routeToMatch);
    //     const match = accountRoutes[foundIndex];
    //     return match;
    // }
    const routes = Object.values(routeObj);
    const foundMatch = routes.find((r) => r === routeToMatch);
    return foundMatch;
};

// Main redirect logic to handle urls and direct links
/**
 * Conditions for entering this logic
 * 1. Check if index is the intended route
 * 2. Direct link to Confirmation page from subscription process
 * 3. Direct link to Set new pass
 * 4. Direct link missing language
 * 5. Direct link that contains language, missed by the router
 */
export const determineRedirectUrl = (
    routesNO: RouteList,
    routesEN: RouteList,
    dispatch: Dispatch<ChangeLanguageAction>,
    authorizedRoutes?: string[],
    isProUser?: boolean,
    user?: User | null,
    isNativeApp?: boolean | false
): string => {
    // Set variables from url(location)
    const defaultLanguage: Language =
        getLocalStorageLanguage() === 'en' ? 'en' : 'nb';
    const defaultRoute =
        defaultLanguage === 'en'
            ? isProUser
                ? routesEN.proindex
                : routesEN.index
            : isProUser
            ? routesNO.proindex
            : routesNO.index;
    const url = decodeURI(window.location.href);
    const confirmation = url.indexOf('confirm') !== -1;
    const setNewPass = url.indexOf('resetPwd') !== -1;
    const publicQuestionPage = url.indexOf('/question?') !== -1;
    const createUserProCode =
        url.indexOf('/opprett-bruker-proff') !== -1 ||
        url.indexOf('/create-user-pro') !== -1;
    const addProCode =
        url.indexOf('/proff-kode') !== -1 || url.indexOf('/pro-code') !== -1;

    if (addProCode) {
        return redirectToAddProCode(window.location.href, defaultLanguage);
    }

    if (createUserProCode) {
        if (user) {
            if (isProUser) {
                return defaultLanguage === 'en'
                    ? routesEN.proindex
                    : routesNO.proindex;
            } else {
                return redirectToAddProCode(
                    window.location.href,
                    defaultLanguage
                );
            }
        } else {
            return redirectToCreateUserProPage(
                window.location.href,
                defaultLanguage
            );
        }
    }

    //check if navigating to public question info page
    if (publicQuestionPage)
        return redirectToPublicQuestionInfoPage(
            window.location.href,
            defaultLanguage
        );

    // check if subscription process
    if (confirmation) return redirectToConfirmation(url, defaultLanguage);

    // password reset check
    if (setNewPass) return redirectSetNewPassword(url);

    // find the language of the route by looking in each routeObj for a match
    const targetRoute: string[] = intendedRoute(url);
    const isNO = routeObjContains(routesNO, targetRoute, 'nb');
    const isEN = routeObjContains(routesEN, targetRoute, 'en');
    const language: Language | undefined = isNO
        ? 'nb'
        : isEN
        ? 'en'
        : undefined;
    if (!!language)
        return redirectReconstructedRoute(language, targetRoute, dispatch);

    if (!!targetRoute[1] && targetRoute[1] === 'freemium' && !!targetRoute[3]) {
        return targetRoute[3];
    } else if (
        !!targetRoute[1] &&
        targetRoute[1] === 'freemium' &&
        !!targetRoute[2]
    ) {
        return targetRoute[2];
    }
    // check if valid route and wasn't caught initially
    const hasNO = url.indexOf('/nb/') !== -1;
    const hasEN = url.indexOf('/en/') !== -1;
    if (hasNO || hasEN) {
        const routeObj = hasNO ? routesNO : routesEN;
        const routeLanguage = hasNO ? 'nb' : 'en';
        const routeFromObj = findRouteFromObj(url, routeObj);

        if (!user?.language && !isNativeApp) {
            dispatch(updateLanguage(routeLanguage));
        }

        if (
            !!authorizedRoutes &&
            !!routeFromObj &&
            authorizedRoutes.includes(routeFromObj)
        )
            return routeFromObj;
    }

    // Return default route if when all
    return defaultRoute;
};

// Redirects on user language select
export const redirectOnLanguageSelect = (
    language: Language,
    currentRoute: string
): string => {
    // get current path from route
    const currentPath = intendedRoute(currentRoute);
    // check for valid path otherwise early return
    // const currentRoutesObj = language === 'en' ? routesNO : routesEN;
    const nextRoutesObj = language === 'en' ? routesEN : routesNO;
    const found = routeObjContains(nextRoutesObj, currentPath, language);

    const currentPageInOtherLanguage = `/${language}${currentPath.join('/')}`;

    if (!found) return nextRoutesObj.index;

    return currentPageInOtherLanguage;
};
