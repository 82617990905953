import produce, { Draft } from 'immer';
import {
    SET_USER,
    SET_SUBSCRIPTION,
    CLEAR_USER,
    SELECTED_PRODUCT,
    PAYMENT_SESSION,
    SET_TOAST_MESSAGE,
    SET_BOLD_TOAST_MESSAGE,
} from './_actionTypes';
import { UserState } from './_types';
import { initialState } from './_initialState';

const userReducer = produce(
    (draft: Draft<UserState> = initialState, action): UserState => {
        switch (action.type) {
            case SET_USER:
                draft.user = action.payload;
                break;
            case CLEAR_USER:
                draft.user = initialState.user;
                draft.subscription = initialState.subscription;
                break;
            case SET_SUBSCRIPTION:
                draft.subscription = action.payload;
                break;
            case SELECTED_PRODUCT:
                draft.selectedProduct = action.payload;
                break;
            case PAYMENT_SESSION:
                draft.session = action.payload;
                break;
            case SET_TOAST_MESSAGE:
                draft.toastMessage = action.payload;
                break;
            case SET_BOLD_TOAST_MESSAGE:
                draft.boldToastMessage = action.payload;
                break;
        }
        return draft;
    }
);

export default userReducer;
