import React from 'react';
import {
    SignInWithApple,
    SignInWithAppleResponse,
    SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { logInByApple } from 'common-logic/login';
import { RouteList } from 'api/routes';
import { RootReducer } from 'store/store';
import {
    selectedProductSelector,
    setUserState,
    setUserSub,
} from 'store-modules/user';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as AppleIcon } from '../../images/icons/apple.svg';
import { Button } from 'components/base';
import styles from './apple-login-button.module.scss';
import { getUAInfo } from 'services/user-agent.service';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

const { isiOS, isiOSWeb } = getUAInfo(window.navigator);

type PropsOwn = {
    text: string;
    routes: RouteList;
    setLoading: (arg: boolean) => void;
    createUser: boolean;
};

const mapStateToProps = (state: RootReducer) => ({
    selectedProduct: selectedProductSelector(state),
});

type PropsFromState = ReturnType<typeof mapStateToProps>;
type Props = PropsOwn & PropsFromState;

const AppleLoginButton: React.FC<Props> = ({
    text,
    routes,
    selectedProduct,
    setLoading,
    createUser,
}: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    if (!SignInWithApple) {
        return null;
    }

    const loginToSystem = async (): Promise<void> => {
        setLoading(true);

        const options: SignInWithAppleOptions = {
            clientId: isiOS ? 'no.fuelit.fuelbox' : 'no.fuelit.fuelbox.web',
            redirectURI: `${window.location.origin}${routes.account.index}`,
            scopes: 'email name',
            nonce: 'nonce',
        };

        try {
            const { response }: SignInWithAppleResponse =
                await SignInWithApple.authorize(options);
            const loginResult = await logInByApple(
                response,
                selectedProduct?.id
            );
            loginResult.subscription &&
                dispatch(setUserSub(loginResult.subscription));
            loginResult.user && dispatch(setUserState(loginResult.user));
            navigate(routes.account.index);
        } catch (err) {
            console.error(err);
            navigate(routes.error);
        }
        setLoading(false);
    };

    return !isiOS || isiOSWeb ? (
        <Button
            className={
                createUser
                    ? classnames(styles.appleButton, styles.createUser)
                    : styles.appleButton
            }
            fullWidth
            onClick={loginToSystem}
        >
            <AppleIcon />
            <span>{text}</span>
        </Button>
    ) : null;
};

export default connect(mapStateToProps)(AppleLoginButton);
