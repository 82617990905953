import userClient from 'api/user-client';
import { Checkbox } from 'components/checkbox';
import { Link } from 'components/link';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { returnCopyForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';

interface Props {}
export interface SaveHandle {
    save(): Promise<void>;
}

interface ViewCopy {
    label: string;
    linkTarget: string;
    linkText: string;
}

const nbCopy: ViewCopy = {
    label:
        'Hold meg oppdatert om nytt innhold, funksjonalitet og nyheter fra FuelBox. Vi ivaretar dine opplysninger iht. vår ',
    linkTarget: 'https://app.fuelbox.no/nb/personvernerkl%C3%A6ring',
    linkText: 'Personvernerklæring.',
};

const enCopy: ViewCopy = {
    label:
        'Keep me updated with new content, functionality and news from FuelBox. We will secure your personal information according to ',
    linkTarget: 'https://app.fuelbox.no/en/privacy-policy',
    linkText: 'FuelBox Privacy policy.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const UserNewsletterCheckbox = React.forwardRef<SaveHandle, Props>(
    (props, ref) => {
        const language: Language = useSelector(languageSelector);
        const { label, linkTarget, linkText } = returnCopyForLanguage(
            language,
            copies
        );

        const [isChecked, setIsChecked] = useState(false);
        const [isAllowed, setIsAllowed] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const save = (): Promise<void> => {
            if (isChecked === isAllowed) return Promise.resolve();

            setIsLoading(true);
            return userClient
                .setUserNewsletterConsent(isChecked)
                .then(() => {
                    setIsAllowed(isChecked);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsChecked(isAllowed);
                    setIsLoading(false);
                    throw error;
                });
        };
        useImperativeHandle(ref, () => ({
            save: (): Promise<void> => save(),
        }));

        const handleChange = (isChecked: boolean): void =>
            setIsChecked(isChecked);

        useEffect(() => {
            setIsLoading(true);
            userClient
                .getUserNewsletterConsent()
                .then((isAllowed) => {
                    setIsAllowed(isAllowed);
                    setIsChecked(isAllowed);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    throw error;
                });
        }, []);

        return (
            <Checkbox
                isChecked={isChecked}
                isDisabled={isLoading}
                onStateChanged={handleChange}
            >
                <span>
                    {label}
                    <Link to={linkTarget} external={true}>
                        {linkText}
                    </Link>
                </span>
            </Checkbox>
        );
    }
);

export default UserNewsletterCheckbox;
