import conversationClient from 'api/conversation-client';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { SEO } from 'components/seo';
import React, { ReactElement, useEffect, useState } from 'react';
import SwiperInstance from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { THEMES_CATEGORY } from 'services/conversation-service';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    scrollPosSelectorHomePage,
    setScrollPosHomePage,
    languageSelector,
} from 'store-modules/app-settings';
import { buildFlatTreeFromArray, FlatTree } from 'utils/tree-utils';
import {
    Conversation,
    ConversationCategory,
    ConversationWithCategory,
    deckParentPages,
    FeaturedConversation,
    Question,
    randomCoversation,
} from '_types';
import ConversationCard from '../../components/conversation-card/conversation-card';
import ThemeCard from '../../components/conversation-card/theme-card';
import { ConversationsRow } from './conversations-row';
import { FeaturedDecksSwiper } from './featured-decks';
import styles from './home-page.module.scss';
import {
    getMainCategories,
    groupConversationsByCategory,
} from './_services/home-page.service';
import { RandomCardRow } from './random-card-row';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useWindowSize } from 'hooks';
import Decorator from 'components/conversation-card/decorator';
import { useNavigate } from 'react-router-dom';
import useConversationSelector from 'hooks/use-conversation-selector';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';

interface ViewCopy {
    pageTitle: string;
    randomCardTitle: string;
    themesTitle: string;
    conversationsLabel: string;
    randomSlug: string;
    newConversationsTitle: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Hjem',
    randomCardTitle: 'Ubesluttsom?',
    themesTitle: 'Aktuelle tema',
    conversationsLabel: 'Relasjon samtale',
    randomSlug: 'kort',
    newConversationsTitle: 'Nyeste samtaler',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    randomCardTitle: 'Indecisive?',
    themesTitle: 'Timely topics',
    conversationsLabel: 'Relasjon samtale',
    randomSlug: 'card',
    newConversationsTitle: 'New conversations',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

type Content = {
    featuredConversations: FeaturedConversation[];
    themes: ConversationWithCategory[];
    conversations: ConversationWithCategory[];
    randomQuestions: Question[];
    myConversations: ConversationWithCategory[];
    newlyAddedConversations: ConversationWithCategory[];
};

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        randomCardTitle,
        themesTitle,
        conversationsLabel,
        randomSlug,
        newConversationsTitle,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const scrollPosition = useSelector(scrollPosSelectorHomePage);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [swiper, setSwiper] = useState<SwiperInstance>();
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const { isAndroid } = getUAInfo(window.navigator);

    const [content, setContent] = useState<Content>({
        conversations: [],
        featuredConversations: [],
        randomQuestions: [],
        themes: [],
        myConversations: [],
        newlyAddedConversations: [],
    });

    const {
        conversations,
        featuredConversations,
        randomQuestions,
        themes,
        newlyAddedConversations,
    } = content;

    const { selectConversation, selectRandomQuestions } =
        useConversationSelector();

    const headerProps = {
        showLogoLink: true,
    };

    useEffect(() => {
        Promise.all([
            conversationClient.getFeaturedConversations(),
            conversationClient.getThemes(),
            conversationClient.getConversations(),
            conversationClient.getRandomQuestions(),
            conversationClient.getMyFavouriteConversations(),
            conversationClient.getNewlyAddedConversations(),
        ])
            .then((response) =>
                setContent({
                    featuredConversations: response[0],
                    themes: response[1],
                    conversations: response[2],
                    randomQuestions: response[3],
                    myConversations: response[4],
                    newlyAddedConversations: response[5],
                })
            )
            .catch((err) => {
                console.error(err);
                navigate(routes.error);
            })
            .finally(() => setIsLoading(false));
    }, [dispatch, routes, isMobile]);

    useEffect(() => {
        if (scrollPosition)
            window.scrollTo({
                top: scrollPosition.y,
                left: scrollPosition.x,
            });
    }, [content, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    const selectFeaturedDeck = (): void => {
        if (swiper) {
            dispatch(setScrollPosHomePage(getWindowScrollPosition(window)));
            selectConversation(
                featuredConversations[swiper.activeIndex],
                language,
                deckParentPages.premiumHome
            );
        }
    };

    const selectRandomQuestion = (): void => {
        dispatch(setScrollPosHomePage(getWindowScrollPosition(window)));
        selectRandomQuestions(
            randomCoversation,
            randomQuestions,
            language,
            randomSlug
        );
    };

    const onConversationSelect = (conversation: Conversation) => {
        dispatch(setScrollPosHomePage(getWindowScrollPosition(window)));
        selectConversation(conversation, language, deckParentPages.premiumHome);
    };

    const onClickShowAll = (category: ConversationCategory) => {
        dispatch(setScrollPosHomePage(getWindowScrollPosition(window)));
        navigate(`browse/${category.slug}`);
    };

    const themesTree = buildFlatTreeFromArray(themes);
    const filteredThemes = themes.filter(
        ({ id }) => themesTree.nodesById[id].childrenIds.length === 0
    );

    const conversationTree: FlatTree<ConversationWithCategory> =
        buildFlatTreeFromArray(conversations);
    const mainCategories = getMainCategories(conversationTree);
    const byCategoryId = groupConversationsByCategory(conversationTree);

    const randomCardsSection = randomQuestions.length ? (
        <section aria-label={randomCardTitle}>
            <RandomCardRow
                title={randomCardTitle}
                selectRandomQuestion={selectRandomQuestion}
            />
        </section>
    ) : null;

    const createThemeCard = (
        category: ConversationCategory,
        conversation: Conversation
    ): ReactElement => {
        return (
            <ThemeCard
                conversation={conversation}
                onClick={() => onConversationSelect(conversation)}
            />
        );
    };

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = newlyAddedConversations?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    const decorator = new Decorator();
    const createConversationCard = (
        category: ConversationCategory,
        conversation: ConversationWithCategory
    ): ReactElement => {
        return isThemeCard(conversation.taxonomy) ||
            isOrganizationCard(conversation.taxonomy) ? (
            <ThemeCard
                key={conversation.id}
                conversation={conversation}
                className={conversationCardStyle(conversation)}
                onClick={(): void => onConversationSelect(conversation)}
            />
        ) : (
            <ConversationCard
                conversation={conversation}
                onClick={() => onConversationSelect(conversation)}
                category={category}
                decorator={decorator}
                className={conversationCardStyle(conversation)}
            />
        );
    };

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.homePage}
            headerProps={headerProps}
        >
            <SEO title={pageTitle} />

            <section
                className={styles.featuredConverstations}
                aria-label='featured conversations'
            >
                {featuredConversations.length > 0 && (
                    <FeaturedDecksSwiper
                        featuredConversations={featuredConversations}
                        selectFeaturedConversation={selectFeaturedDeck}
                        swiperOptions={{ onInit: setSwiper }}
                    />
                )}
            </section>

            {randomCardsSection}
            {newlyAddedConversations && newlyAddedConversations.length > 5 && (
                <section aria-label={newConversationsTitle}>
                    <ConversationsRow
                        key={-9090}
                        slidesId={-9090}
                        className={styles.conversationRow}
                        category={null}
                        title={newConversationsTitle}
                        conversations={newlyAddedConversations}
                        createCard={createConversationCard}
                        onClickShowAll={() =>
                            onClickShowAll({
                                slug:
                                    language === 'nb'
                                        ? 'nyeste_samtaler'
                                        : 'new_conversations',
                                name:
                                    language === 'nb'
                                        ? 'Nyeste samtaler'
                                        : 'New conversations ',
                            })
                        }
                        showAllComponent={ConversationCard}
                    />
                </section>
            )}

            <section aria-label={themesTitle}>
                {themes.length > 0 && (
                    <ConversationsRow
                        slidesId={-99999}
                        className={styles.timelyTopics}
                        category={THEMES_CATEGORY}
                        title={themesTitle}
                        conversations={filteredThemes}
                        createCard={createThemeCard}
                        onClickShowAll={() => onClickShowAll(THEMES_CATEGORY)}
                        showAllComponent={ThemeCard}
                    />
                )}
            </section>
            <section aria-label={conversationsLabel}>
                {mainCategories.map((category) => {
                    const { id, name, title, slug } = category;
                    const conversations = byCategoryId[id];

                    if (conversations.length === 0) {
                        return null;
                    }

                    return (
                        <ConversationsRow
                            key={id}
                            slidesId={id}
                            className={styles.conversationRow}
                            category={{ slug, name }}
                            title={title || name}
                            conversations={conversations}
                            createCard={createConversationCard}
                            onClickShowAll={() =>
                                onClickShowAll({ slug, name })
                            }
                            showAllComponent={ConversationCard}
                        />
                    );
                })}
            </section>
        </Layout>
    );
};

export default HomePage;
