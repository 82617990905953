import { format as formatDateFns } from 'date-fns';
import { useTranslation } from 'react-i18next';

/**
 * NOTE: This hooks is flexible and accepts a lot of different formats, including unix timestamp, string
 * and Date objects. It also uses localized formats to allow for different date formats in different languages.
 * For these reasons, this hooks should replace the existing `formatDate` function.
 */

type DateInput = Date | number | string;

type BaseDateFormatter = (
    date?: DateInput,
    outputFormat?: string
) => string | undefined;

type DateFormatter = (date?: DateInput) => string | undefined;

type DateUtils = {
    format: BaseDateFormatter;
    formatDate: DateFormatter;
};

const isEpochTimestamp = (date?: DateInput) =>
    date && typeof date === 'number' && String(date).length === 10;

const epochToUnix = (timestamp: number) => timestamp * 1000;

/**
 * Hook for consistent localized date formatting
 */
const useDateUtils = (): DateUtils => {
    const { t } = useTranslation();

    const dateFormat = t('format.date');

    const format: BaseDateFormatter = (date, outputFormat = dateFormat) =>
        date
            ? formatDateFns(
                  new Date(
                      isEpochTimestamp(date)
                          ? epochToUnix(date as number)
                          : date
                  ),
                  outputFormat
              )
            : undefined;

    const formatDate: DateFormatter = (date) => format(date);

    return {
        format,
        formatDate,
    };
};

export default useDateUtils;
