import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DarkThemeProvider } from 'style/theme';
import JoinCode from './components/join-code';
import MainContent from './components/main-content';
import styles from './join-collaboration-page.module.scss';

const JoinCollaborationPage: FC = () => (
    <DarkThemeProvider className={styles.container}>
        <Routes>
            <Route path=':collaborationCode' element={<MainContent />} />
            <Route index element={<JoinCode />} />
            <Route path='*' element={<Navigate to='..' />} />
        </Routes>
    </DarkThemeProvider>
);

export default JoinCollaborationPage;
