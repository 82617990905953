import classnames from 'classnames';
import { Button } from 'components/button';
// import { Props } from 'components/page-header/page-header';
import React from 'react';
import { Question } from '_types';
import styles from './select-question-box.module.scss';
import { ReactComponent as AddIcon } from 'images/icons/addIcon.svg';
import { ReactComponent as CheckIcon } from 'images/icons/check-blue.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/icon-delete.svg';
import classNames from 'classnames';

interface Props {
    question: Question;
    className?: string;
    selectedQuestions?: Question[];
    onClick: () => void;
    isEditing?: boolean;
}

const SelectQuestionBox: React.FC<Props> = ({
    question,
    className,
    selectedQuestions,
    onClick,
    isEditing,
}: Props) => {
    const selected = selectedQuestions?.map((q) => q.id)?.includes(question.id);

    return (
        <Button
            className={classnames(
                className,
                styles.SelectQuestionBox,
                selected && !isEditing ? styles.Selected : null,
                selected && isEditing && styles.EditingSelected,
                !selected && isEditing && styles.EditingNotSelected
            )}
            onClick={onClick}
        >
            <div
                // tabIndex={0}
                // role='button'
                // onKeyDown={onClick}
                className={styles.QuestionsTextContainer}
                // onClick={onClick}
            >
                {question.text}{' '}
            </div>
            <div className={styles.QuestionBoxIcon}>
                {selected ? (
                    isEditing ? (
                        <DeleteIcon className={styles.DeleteIcon} />
                    ) : (
                        <CheckIcon className={styles.CheckIcon} />
                    )
                ) : (
                    <AddIcon
                        className={classNames(styles.Icon, styles.AddIcon)}
                    />
                )}
            </div>
        </Button>
    );
};
export default SelectQuestionBox;
