import React, { FC, Fragment } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import classNames from 'classnames';

import {
    Box,
    Chip,
    Icon,
    Paper,
    Slide,
    styled,
    SwipeableDrawer,
    SwiperNav,
} from 'components/base';
import { LightThemeProvider } from 'style/theme';
import useSwiperContext from 'hooks/use-swiper-context';
import {
    getParticipantsCollectionRefForSession,
    Participant,
} from 'utils/collaboration-utils';

import { DrawerInfoContent } from './session-info-display/styled';
import BgNightDecorator from '../background/bg-night-decorator';
import DisplayButtons from './display-buttons';
import SessionInfoContent from './session-info-display/session-info-content';
import useCollaboration from '../../use-collaboration';
import useSession from '../../use-session';

const DashedChip = styled(Chip)`
    border-color: currentColor;
    border-style: dashed;
`;

const Toolbar: FC = () => {
    const { activeIndex } = useSwiperContext();

    const {
        collaboration: { collaborationCode },
        collaborationId,
        interactiveMode,
    } = useCollaboration();

    const { toolbarRef, displayInfoInDrawer, displayToolbarAsDrawer } =
        useSession();

    const participantsRef =
        interactiveMode && collaborationCode
            ? getParticipantsCollectionRefForSession(
                  collaborationId,
                  collaborationCode
              )
            : undefined;

    const [participants] = useCollectionData<Participant>(
        participantsRef as never
    );

    return displayToolbarAsDrawer ? (
        <Fragment>
            <Box position='absolute' bottom={{ xs: 70, sm: 90 }}>
                <BgNightDecorator />
            </Box>

            <SwipeableDrawer
                bleedContent={
                    <Box width={1}>
                        <SwiperNav />
                    </Box>
                }
                dense={displayInfoInDrawer}
            >
                <Box
                    width={1}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    {activeIndex != null && (
                        <SwipeableDrawer.InnerContent
                            className={classNames('px', {
                                pb: displayInfoInDrawer,
                            })}
                        >
                            {/* Hard-coding min-height because this renders nothing on end slides, which disrupts the drawer */}
                            <Box minHeight={104}>
                                <DisplayButtons
                                    StackProps={{ direction: 'column' }}
                                />
                            </Box>
                        </SwipeableDrawer.InnerContent>
                    )}

                    {displayInfoInDrawer && (
                        <LightThemeProvider width={1}>
                            <DrawerInfoContent
                                className='px'
                                component={Paper}
                                minHeight={56}
                            >
                                <SessionInfoContent />

                                {interactiveMode && (
                                    <DashedChip
                                        label={participants?.length ?? 0}
                                        variant='outlined'
                                        size='small'
                                        icon={
                                            <Icon
                                                fontSize='small'
                                                name='person_outlined'
                                            />
                                        }
                                    />
                                )}
                            </DrawerInfoContent>
                        </LightThemeProvider>
                    )}
                </Box>
            </SwipeableDrawer>
        </Fragment>
    ) : (
        <Slide direction='up' in>
            <Box position='absolute' bottom={0} left={0} right={0}>
                <BgNightDecorator />

                <Box
                    alignItems='center'
                    display='flex'
                    px={2}
                    py={1.25}
                    ref={toolbarRef}
                    sx={(theme) => ({
                        background: theme.palette.background.default,
                    })}
                >
                    <Box flex={1.25}>
                        {activeIndex != null && <DisplayButtons />}
                    </Box>

                    <Box flex={1} py={0.75}>
                        <SwiperNav />
                    </Box>

                    <Box flex={1.25} display='flex' justifyContent='flex-end'>
                        {interactiveMode && (
                            <Chip
                                label={participants?.length ?? 0}
                                icon={
                                    <Icon
                                        fontSize='small'
                                        name='person_outlined'
                                    />
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Slide>
    );
};

export default Toolbar;
