import * as React from 'react';
import styles from './account-page-native.module.scss';
import { Layout } from 'components/layout';
import { Container } from 'components/container';
import { Button } from 'components/base';
import authService from 'services/auth-service';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesNO, routesEN } from 'api/routes';

interface ViewCopy {
    title: string;
    buttonLogoutLabel: string;
    message: string;
}

const nbCopy: ViewCopy = {
    title: 'Min konto',
    buttonLogoutLabel: 'Logg ut',
    message: 'Logg inn på FuelBox i nettleseren for å administrere kontoen din',
};

const enCopy: ViewCopy = {
    title: 'My account',
    buttonLogoutLabel: 'Log out',
    message: 'Log into FuelBox in your browser to manage your account',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AccountPageNative: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const { title, buttonLogoutLabel, message } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    return (
        <Layout routes={routes} language={language}>
            <Container
                className={styles.container}
                data-testid='component-native-account'
            >
                <header className={styles.header}>
                    <h1 className={styles.title}>{title}</h1>
                    <Button
                        className={styles.button}
                        onClick={authService.logout}
                        size='small'
                    >
                        {buttonLogoutLabel}
                    </Button>
                </header>
                <section
                    className={styles.content}
                    aria-labelledby='account instructions'
                >
                    {message}
                </section>
            </Container>
        </Layout>
    );
};

export default AccountPageNative;
