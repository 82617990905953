import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { useSelector, useDispatch } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
// import { deviceSelector, languageSelector } from 'store-modules/app-settings';
// import { AccountPageNative } from 'pages/account-page-native';
import { SEO } from 'components/seo';
import { Language } from '_types/language';
import { returnRoutesForLanguage } from 'services/language-service';
import { AccountHome } from 'pages/account-page/account-home';
import { SubscriptionPage } from 'pages/account-page/subscription-page';
import AccountSettingsPage from 'pages/account-page/account-home/account-settings-page/account-settings-page';
import LanguagePage from 'pages/account-page/account-home/language-page/language-page';
import { determineRedirectUrl } from 'services/redirects.service';
import ManageNotifications from 'pages/account-page/account-home/notifications-page/notifications-page';
import ProCodeSubPage from 'pages/account-page/pro-code-subpage/pro-code-subpage';
import { DeleteAccount } from 'pages/account-page/delete-account';
import { ChangePersonalDetails } from 'pages/account-page/change-personal-details';
import { RecoverAccount } from 'pages/account-page/recover-account';
import RecoverAccountConfirmationPage from 'pages/account-page/recove-account-confirmation-page/recover-account-confirmation-page';
import { CompanyAdminPage } from 'pages/account-page/company-admin-page';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AccountRoutes: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);

    // const device = useSelector(deviceSelector);
    const dispatch = useDispatch();
    const authorizedRoutes = [
        routes.account.index,
        routes.account.changePersonal,
        routes.account.deleteAccount,
        routes.account.subscription,
        routes.account.settings,
        routes.account.language,
        routes.account.recoverAccount,
        routes.endSubscription,
        routes.proCodeSubPage,
        routes.about,
    ];

    return (
        <React.Fragment>
            <SEO noIndex={true} />
            <Routes>
                <Route
                    path={routes.proCodeSubPage}
                    element={<ProCodeSubPage />}
                />                
                <Route
                    path={routes.account.changePersonal}
                    element={<ChangePersonalDetails />}
                />
                <Route
                    path={routes.account.companyAdminPage}
                    element={<CompanyAdminPage />}
                />
                <Route
                    path={routes.account.notifications}
                    element={<ManageNotifications />}
                />
                <Route
                    path={routes.account.subscription}
                    element={<SubscriptionPage />}
                />
                <Route
                    path={routes.account.deleteAccount}
                    element={<DeleteAccount />}
                />
                <Route
                    path={routes.account.recoverAccount}
                    element={<RecoverAccount />}
                />
                <Route
                    path={routes.account.recoverAccountConfirmationPage}
                    element={<RecoverAccountConfirmationPage />}
                />

                <Route
                    path={routes.account.settings}
                    element={<AccountSettingsPage />}
                />
                <Route
                    path={routes.account.language}
                    element={<LanguagePage />}
                />

                <Route index element={<AccountHome />} />

                <Route
                    path='*'
                    element={
                        <Navigate
                            to={determineRedirectUrl(
                                routesNO,
                                routesEN,
                                dispatch,
                                authorizedRoutes
                            )}
                            replace
                        />
                    }
                />
            </Routes>
        </React.Fragment>
    );
};

export default AccountRoutes;
