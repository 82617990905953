import React, { ElementType, FC, MouseEvent } from 'react';

import { IconButton, IconButtonProps } from '../../buttons/icon-button';

import useMenu from './use-menu';

export type MenuIconButtonProps = IconButtonProps & {
    component?: ElementType<IconButtonProps>;
};

export const MenuIconButton: FC<MenuIconButtonProps> = ({
    children,
    component: Component = IconButton,
    onClick,
    ...rest
}) => {
    const { onOpen } = useMenu();

    return (
        <Component
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                onClick && onClick(e);
                onOpen(e);
            }}
            {...rest}
        >
            {children}
        </Component>
    );
};
