import * as React from 'react';
import styles from './create-account-confirmation.module.scss';
import { Button } from 'components/base';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { SEO } from 'components/seo';
import { useNavigate } from 'react-router-dom';

interface ViewCopy {
    title: string;
    instructions: string;
    labelGoHome: string;
}

const nbCopy: ViewCopy = {
    title: 'Fullfør din bruker',
    instructions:
        'Klikk på lenken i eposten vi har sendt deg for å fortsette. Lenken er gyldig i 24 timer.',
    labelGoHome: 'Gå til forsiden',
};

const enCopy: ViewCopy = {
    title: 'Complete your account',
    instructions:
        'To continue, follow the link in the email we have sent you. The link is valid for 24 hours.',
    labelGoHome: 'Go to front page',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CreateAccountConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { title, instructions, labelGoHome } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    const navigateToHome = () => navigate(routes.index);

    return (
        <>
            <SEO noIndex={true} />
            <article
                data-testid='component-confirmation-success'
                className={styles.CreateAccountConfirmation}
            >
                <section className={styles.Message}>
                    <h1>{title}</h1>
                    <p>{instructions}</p>
                </section>
                <section className={styles.Actions}>
                    <Button onClick={navigateToHome}>{labelGoHome}</Button>
                </section>
            </article>
        </>
    );
};

export default CreateAccountConfirmation;
