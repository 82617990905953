import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    FullscreenLoader,
    Grid,
    styled,
    SwipeableDrawer,
    Typography,
    useMediaQuery,
    useTheme,
} from 'components/base';
import { Deck, Question } from '_types';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useCreateCollaboration } from 'hooks/use-create-collaboration';
import styles from './start-conversation.module.scss';

const Container = styled(Box)`
    background: ${({ theme }) => theme.palette.custom.brown[95]};
    border-radius: ${({ theme }) => Number(theme.shape.borderRadius) * 2}px;
    display: flex;
    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, 3)};
`;

const MinimalViewStartButton = styled(Button)`
    height: 64px;
    background: ${({ theme }) => theme.palette.common.white};
    border-color: ${({ theme }) => theme.palette.custom.brown[85]};
    border-radius: ${({ theme }) => Number(theme.shape.borderRadius) * 2}px;
    border-width: thin;

    &:hover {
        border-color: ${({ theme }) => theme.palette.custom.brown[85]};
        border-width: thin;
    }
`;

type StartCollaborationBoxProps = {
    deck: Deck;
    questions: Question[];
};

const StartCollaborationBox: FC<StartCollaborationBoxProps> = ({
    deck,
    questions,
}) => {
    const { t } = useTranslation();
    const auth = getAuth();

    const theme = useTheme();
    const displayDrawer = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        signInAnonymously(auth);
    }, []);

    const { createCollaboration } = useCreateCollaboration();

    const handleCreateCollaboration = () => {
        setLoading(true);

        createCollaboration({
            callbackFn: () => setLoading(false),
            deck,
            questions,
        });
    };

    if (!deck || !questions) return null;

    if (loading) return <FullscreenLoader />;

    if (displayDrawer)
        return (
            <SwipeableDrawer
                bleedContent={
                    <Typography align='left' ml={2}>
                        <Trans
                            components={[<strong key={0} />]}
                            i18nKey='conversationView.collaboration.description'
                            context='minimal'
                        />
                    </Typography>
                }
            >
                <MinimalViewStartButton
                    data-testid='button-start-collaboration'
                    leftIcon='desktop'
                    onClick={handleCreateCollaboration}
                >
                    {t('conversationView.collaboration.start')}
                </MinimalViewStartButton>
            </SwipeableDrawer>
        );

    return (
        <Container>
            <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='center'
            >
                <Grid item xs>
                    <Typography align='left' ml={2}>
                        <Trans
                            components={[<strong key={0} />]}
                            i18nKey='conversationView.collaboration.description'
                        />
                    </Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Button
                        appearance='text'
                        data-testid='button-start-collaboration'
                        leftIcon='desktop'
                        onClick={handleCreateCollaboration}
                        className={styles.StartPollingButton}
                    >
                        {t('conversationView.collaboration.start')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default StartCollaborationBox;
