import React from 'react';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import styles from './pro-owner.module.scss';
// import { Link as CustomLink, Link } from 'components/link';
import { Link } from 'components/base';
import { routesNO, RouteList, routesEN } from 'api/routes';
// import { Link } from 'react-router-dom';
// import { subscriptionSelector } from 'store-modules/user';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';

interface Props {}
interface ViewCopy {
    productName: string;
    infoText1: string;
    infoText2: string;
    adminLinkLabel: string;
}

const nbCopy: ViewCopy = {
    productName: 'FuelBox Bedrift',
    infoText1: 'Du er administrator for et ',
    infoText2: 'bedriftsabonnement',
    adminLinkLabel: 'Administrer abonnement',
};

const enCopy: ViewCopy = {
    productName: 'FuelBox Company',
    infoText1: 'You are the administrator of a ',
    infoText2: 'company subscription',
    adminLinkLabel: 'Administer subscription',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ProOwner: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const navigate = useNavigate();
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const routes = returnRoutesForLanguage(language, routeObj);
    const { productName, infoText1, infoText2, adminLinkLabel } =
        returnCopyForLanguage(language, copies);

    return (
        <section className={styles.ProOwnerContainer}>
            <h2 className={styles.Header}>{productName}</h2>
            <p>
                {infoText1}
                {isMobile && <br />}
                {infoText2}
                <br /> <br />
                <Link
                    onClick={() => {
                        navigate(`../${routes.account.companyAdminPage}`);
                    }}
                    className={styles.FaqLink}
                >
                    {adminLinkLabel}
                </Link>
            </p>
        </section>
    );
};

export default ProOwner;
