import React, { FC, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import MuiIconButton, {
    IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDefaultColor } from 'style/theme';

import { Icon, IconOption } from '../../data-display/icon';

export type IconButtonProps = Omit<
    MuiIconButtonProps,
    | 'action'
    | 'centerRipple'
    | 'classes'
    | 'disableElevation'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'focusRipple'
    | 'focusVisibleClassName'
    | 'LinkComponent'
    | 'onFocusVisible'
    | 'TouchRippleProps'
    | 'touchRippleRef'
> &
    Partial<Pick<LinkProps, 'to'>> & {
        'aria-label': string;
        icon: IconOption;
    };

export const IconButton: FC<IconButtonProps> = forwardRef(
    ({ children, color, size, icon, ...rest }, ref) => {
        const theme = useTheme();
        const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
        const { colorName } = useDefaultColor();

        return (
            <MuiIconButton
                color={color ?? colorName}
                ref={ref}
                size={size ?? (mdScreen ? undefined : 'large')}
                {...(rest.to ? { component: Link } : {})}
                {...rest}
            >
                <Icon fontSize='small' name={icon} />
                {children}
            </MuiIconButton>
        );
    }
);
