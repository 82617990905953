import React from 'react';
import { Button } from 'components/base';
import styles from './confirmation-page.module.scss';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import { PageTitle } from 'components/page-title';
import { RouteList } from 'api/routes';
import { Language } from '_types/language';
import { CancelledProSub } from 'professional/components/cancelled-pro-sub';
import { userSelector } from 'store-modules/user';
import { useSelector } from 'react-redux';
import { getUAInfo } from 'services/user-agent.service';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preventDefault = (e: React.MouseEvent<any>): void => e.preventDefault();

interface Props {
    title: string;
    description: string;
    onClickButton: () => void;
    buttonLabel: string;
    iapLinkButtonLabel?: string;
    endDate?: string;
    routes: RouteList;
    language: Language;
    proSub?: boolean;
    inAppPurchaseText1?: string;
    inAppPurchaseText2?: string;
    inAppPurchaseMobileConfirmationText?: string;
    IAPSubscription?: boolean | false;
}

const ConfirmationPage: React.FC<Props> = ({
    title,
    description,
    buttonLabel,
    iapLinkButtonLabel,
    onClickButton,
    endDate,
    routes,
    language,
    proSub,
    inAppPurchaseText1,
    inAppPurchaseText2,
    inAppPurchaseMobileConfirmationText,
    IAPSubscription,
}) => {
    const navigate = useNavigate();
    const showIAPWarning = IAPSubscription || false;
    const user = useSelector(userSelector);
    const { isiOS } = getUAInfo(window.navigator);

    const goToAppStore = () => {
        navigate(routes.iOSSubscriptions);
    };

    const IAPWarningDesktop = (
        <div>
            <p className={styles.inAppPurchaseText1}>{inAppPurchaseText1}</p>
            <p className={styles.inAppPurchaseText2}>{inAppPurchaseText2}</p>
        </div>
    );

    const IAPWarningMobile = (
        <div>
            <p className={styles.inAppPurchaseMobileConfirmationText}>
                {inAppPurchaseMobileConfirmationText}
            </p>
        </div>
    );

    const premiumContent = endDate ? (
        <div>
            <div className={styles.text}>
                {`${description}`}
                <b>
                    <p className={styles.text}>{endDate}</p>
                </b>
            </div>
        </div>
    ) : (
        <p className={styles.text}>{endDate}</p>
    );

    const proContent = !!proSub && <CancelledProSub />;
    const showIAPsubsBUtton = (
        <Button
            className={styles.button}
            onClick={goToAppStore}
            onMouseDown={preventDefault}
        >
            {iapLinkButtonLabel}
        </Button>
    );

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.confirmationPage}
            headerProps={{
                withAppbar: false,
                withAccount: false,
                showLogoLink: true,
            }}
        >
            <Container className={styles.container}>
                <PageTitle title={title} />
                {!user?.organization && premiumContent}
                {showIAPWarning && !isiOS && IAPWarningDesktop}
                {showIAPWarning && isiOS && IAPWarningMobile}
                {proContent}
                {isiOS && showIAPWarning && showIAPsubsBUtton}
                {isiOS && showIAPWarning && (
                    <a href={routes.index} className={styles.ExploreLink}>
                        {buttonLabel}
                    </a>
                )}
                {(!showIAPWarning || !isiOS) && (
                    <Button
                        className={styles.button}
                        onClick={onClickButton}
                        onMouseDown={preventDefault}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </Container>
        </Layout>
    );
};

export default ConfirmationPage;
