import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import classNames from 'classnames';

import { ReactComponent as LogoSvg } from './logo.svg';
import styles from './logo.module.scss';

type LogoProps = SvgIconProps & {
    title?: string;
};

const Logo: FC<LogoProps> = ({
    className,
    color = 'action',
    fontSize = 'medium',
    title = 'Logo',
    ...rest
}) => (
    <SvgIcon
        component={LogoSvg}
        className={classNames(
            styles.logo,
            className,
            styles[`size--${fontSize}`]
        )}
        title={title}
        inheritViewBox
        color={color}
        fontSize={fontSize}
        {...rest}
    />
);

export default Logo;
