import React, { FC, MouseEvent, ReactNode, useState } from 'react';

import MenuContext, { MenuContextValues } from './context';

export type MenuProviderProps = { children: ReactNode };

const MenuProvider: FC<MenuProviderProps> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

    const onClose = () => {
        anchorEl?.focus();
        setAnchorEl(null);
    };

    const values: MenuContextValues = {
        anchorEl,
        onClose,
        onOpen,
    };

    return (
        <MenuContext.Provider value={values}>{children}</MenuContext.Provider>
    );
};

export default MenuProvider;
