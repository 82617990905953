import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import styles from './collaboration-components.module.scss';
import { IconButton, Typography } from 'components/base';

import useSession from '../../use-session';
import Layout from '../layout';

import {
    getSessionDocumentRef,
    SessionData,
    SlideType,
} from 'utils/collaboration-utils';
import QuestionView from './question-view';
import EndView from './end-view';
import { useNavigate } from 'react-router-dom';
import { returnRoutesForLanguage } from 'services/language-service';
import { useSelector } from 'react-redux';
import { Language } from '_types/language';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { languageSelector } from 'store-modules/app-settings';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const SessionView: FC = () => {
    const { collaborationId, displayName } = useSession();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);

    const sessionRef = getSessionDocumentRef(collaborationId);
    const [sessionData] = useDocumentData<SessionData>(sessionRef as never);

    if (!sessionData) return null;

    const { activeContent } = sessionData || {};

    return (
        <Layout
            hideLogo
            BoxProps={{
                ...(activeContent?.type === SlideType.QUESTION
                    ? { mb: 0 }
                    : {}),
                minHeight: 1,
                flex: 1,
            }}
        >
            <IconButton
                aria-label={t('close')}
                className={styles.closeButton}
                icon='close'
                onClick={() => navigate(routes.index)}
            />
            {activeContent ? (
                activeContent.type === SlideType.END ? (
                    <EndView />
                ) : activeContent.type === SlideType.START ? (
                    <Fragment>
                        <Typography
                            align='center'
                            component='p'
                            gutterBottom
                            variant='h6'
                        >
                            {t('joinCollaboration.register.joined', {
                                username: displayName,
                            })}
                        </Typography>

                        <Typography
                            align='center'
                            gutterBottom
                            color='textSecondary'
                        >
                            {t('joinCollaboration.register.waiting')}
                        </Typography>
                    </Fragment>
                ) : activeContent.type === SlideType.QUESTION ? (
                    <QuestionView activeContent={activeContent.question} />
                ) : null
            ) : null}
        </Layout>
    );
};

export default SessionView;
