import React from 'react';
import styles from './pro-switch-confirmation-page.module.scss';
import { useSelector } from 'react-redux';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Layout } from 'components/layout';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { ReactComponent as SuccessIcon } from '../../images/icons/success-icon.svg';
import { Container } from 'components/container';
import { Button } from 'components/base';
import { Subscription } from '_types';
import { formatDate } from 'utils/date-utils';
import { useNavigate } from 'react-router-dom';

interface ViewCopy {
    header1: string;
    header2: string;
    infoText1: string;
    infoText2: string;
    infoText3: string;
    infoText4: string;
    buttonLabel: string;
    footerText: string;
}

interface Props {
    sub?: Subscription;
}

const nbCopy: ViewCopy = {
    header1: 'Du har nå et ',
    header2: 'Proff-abonnement!',
    infoText1: 'Din siste betalingsdato var',
    infoText2: 'Fra nå av er det din organisasjon som tar regningen.',
    infoText3: 'Utforsk din organisasjons innhold og start ',
    infoText4: 'en samtale med kollegene dine.',
    buttonLabel: 'Utforsk samtaler',
    footerText: 'Kontakt oss for spørsmål om refusjon',
};
const enCopy: ViewCopy = {
    header1: 'You now have a ',
    header2: 'Pro subscription!',
    infoText1: 'Your last payment date was',
    infoText2: 'From now on, your organisation will pay for your subscription.',
    infoText3: 'Explore your organizations content and start ',
    infoText4: 'a conversation with your colleagues.',
    buttonLabel: 'Explore conversations',
    footerText: 'Contact us if you have questions about refunds',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

export const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ProSwitchConfirmationPage: React.FC<Props> = ({ sub }) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        header1,
        header2,
        infoText1,
        infoText2,
        infoText3,
        infoText4,
        buttonLabel,
        footerText,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const lastPaymentDate = sub
        ? formatDate(sub?.data?.completed_payments[0])
        : undefined;
    const headerProps = { withAppbar: false, showLogoLink: true };
    const navigateToProHome = () => navigate(routes.proindex);

    return (
        <Layout headerProps={headerProps} routes={routes} language={language}>
            <Container className={styles.container}>
                <SuccessIcon className={styles.SuccessIcon} />
                <h1 className={styles.confirmationHeader1}>{header1}</h1>
                <h1 className={styles.confirmationHeader2}>{header2}</h1>
                {lastPaymentDate ? (
                    <section className={styles.confirmationInfoText1}>
                        {infoText1} <b>{lastPaymentDate}</b> {'. '} {infoText2}
                    </section>
                ) : (
                    <section className={styles.confirmationInfoText2}>
                        {infoText3}
                        {infoText4}
                        
                                            </section>
                )}
                <br />
                <Button
                    className={styles.exploreButton}
                    onClick={navigateToProHome}
                >
                    {buttonLabel}
                </Button>
                {lastPaymentDate && (
                    <footer className={styles.infoFooter}>{footerText}</footer>
                )}
            </Container>
        </Layout>
    );
};

export default ProSwitchConfirmationPage;
