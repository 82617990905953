import React, { useState } from 'react';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import styles from './set-new-password-page.module.scss';
import { useSearchQuery } from 'hooks/use-search-query';
import { Container } from 'components/container';
import { PageTitle } from 'components/page-title';
import { Form, Field } from 'react-final-form';
import { InputPasswordRow } from 'components/inputs';
import { Button } from 'components/base';
import userClient from 'api/user-client';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { ConfirmationPage } from 'pages/confirmation-page';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';

interface Props {}

interface NewPasswordFormValues {
    password: string;
    password_repeat: string;
}

interface ViewCopy {
    seoTitle: string;
    title: string;
    labelPass: string;
    labelPassRepeat: string;
    buttonSubmit: string;
    errorMismatch: string;
    confirmationTitle: string;
    confirmationDescription: string;
    confirmationButtonLabel: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Endre passord',
    title: 'Endre passord',
    labelPass: 'Nytt passord',
    labelPassRepeat: 'Bekreft nytt passord',
    buttonSubmit: 'Lagre endringer',
    errorMismatch: 'Passordene må være like',
    confirmationTitle: 'Passord endret',
    confirmationDescription: 'Ditt nye passord er lagret.',
    confirmationButtonLabel: 'Logg inn',
};

const enCopy: ViewCopy = {
    seoTitle: '',
    title: 'Change password',
    labelPass: 'New password',
    labelPassRepeat: 'Repeat new password',
    buttonSubmit: 'Save changes',
    errorMismatch: 'The passwords must be identical',
    confirmationTitle: 'Password changed',
    confirmationDescription: 'Your new password has been saved.',
    confirmationButtonLabel: 'Log in',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const SetNewPasswordPage: React.FC<Props> = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        seoTitle,
        title,
        labelPass,
        labelPassRepeat,
        buttonSubmit,
        errorMismatch,
        confirmationTitle,
        confirmationDescription,
        confirmationButtonLabel,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { code, email } = useSearchQuery();

    const formSubmit = async (values: NewPasswordFormValues) => {
        if (values.password !== values.password_repeat) {
            return {
                password_repeat: errorMismatch,
            };
        }

        try {
            await userClient.setNewPassword({
                email: email as string,
                code: code as string,
                newpass: values.password,
            });

            setShowConfirmation(true);
        } catch (e) {
            navigate(routes.error);
        }
    };

    if (showConfirmation) {
        const confirmation = {
            title: confirmationTitle,
            description: confirmationDescription,
            buttonLabel: confirmationButtonLabel,
            onClickButton: (): void => navigate(routes.login),
            routes: routes,
            language: language,
        };

        return <ConfirmationPage {...confirmation} />;
    }

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.page}
            headerProps={{
                showLogoLink: true,
            }}
        >
            <SEO title={seoTitle} />
            <Container className={styles.container}>
                <PageTitle title={title} />
                <Form
                    onSubmit={formSubmit}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <Field
                                name='password'
                                label={labelPass}
                                language={language}
                                component={InputPasswordRow}
                                className={styles.input}
                            />
                            <Field
                                name='password_repeat'
                                label={labelPassRepeat}
                                language={language}
                                component={InputPasswordRow}
                                className={styles.input}
                                hint={''}
                            />
                            <Button
                                className={styles.cta}
                                type='submit'
                                appearance='cta'
                                fullWidth={true}
                            >
                                {buttonSubmit}
                            </Button>
                        </form>
                    )}
                />
            </Container>
        </Layout>
    );
};

export default SetNewPasswordPage;
