import he from 'he';

interface Illustration {
    src: string;
}

// Illustration from API response
interface IllustrationResponse {
    ID: string;
    post_title: string;
    guid: string;
}
// FIXME: This should be deprecated in favor of Conversation/FeaturedConversation,
// Still using this until 'freemium decks' endpoint is updated
export interface Deck {
    category?: string;
    colorTheme: 'dark' | 'light';
    color_option?: number;
    count: number;
    created_by_id?: number;
    created_by_name?: string;
    description: string;
    featured: boolean;
    hidden: boolean;
    id: number;
    illustrationDesktop?: Illustration;
    illustrationMobile?: Illustration;
    name: string;
    pro_conversation?: boolean;
    public?: boolean;
    random?: boolean;
    slug: string;
    taxonomy: string;
}

// Deck from API response
export interface DeckResponse {
    id: number;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featured?: '0' | '1' | any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hidden?: '0' | '1' | any[];
    parent?: number;
    illustration_mobile?: false | IllustrationResponse;
    illustration_desktop?: false | IllustrationResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    color_theme?: any;
    count: number;
    description: string;
    link: string;
    slug: string;
    taxonomy: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: any[];
    kategori?: [string];
}

// Maps the response to the Deck type for the app
export const mapDeckResponse = (decks: DeckResponse[]): Deck[] =>
    decks.map((deck) => ({
        id: deck.id,
        name: he.decode(deck.name),
        count: deck.count,
        illustrationMobile: deck.illustration_mobile
            ? { src: deck.illustration_mobile.guid }
            : undefined,
        illustrationDesktop: deck.illustration_desktop
            ? { src: deck.illustration_desktop.guid }
            : undefined,
        colorTheme: deck.color_theme === '1' ? 'dark' : 'light',
        featured: !!deck.featured && deck.featured !== '0',
        category: deck.kategori ? deck.kategori[0] : undefined,
        hidden: !!deck.hidden && deck.hidden === '1',
        slug: deck.slug,
        description: !!deck.description ? deck.description : '',
        taxonomy: deck.taxonomy,
    }));

export const deckParentPages = {
    premiumHome: 'homepage',
    premiumBrowse: 'browse',
    proHome: 'pro-homepage',
    proBrowse: 'browse',
    myLibrary: 'my-library',
    likedQuestionList: 'liked-questions',
    search: 'search',
};
