import * as React from 'react';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { CopyContent } from 'components/copy-content';
import { TermsConditions } from 'components/terms-conditions';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'store-modules/auth';

interface Props {
    language: Language;
}

interface ViewCopy {
    seoTitle: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Vilkår for bruk',
};

const enCopy: ViewCopy = {
    seoTitle: 'Terms of use',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const TncPage: React.FC<Props> = (props: Props) => {
    const { seoTitle } = returnCopyForLanguage(props.language, copies);
    const routes = returnRoutesForLanguage(props.language, routeObj);
    const authenticated = useSelector(authenticatedSelector);

    const headerProps = {
        withLogin: true,
        withAccount: true,
        showLogoLink: true,
    };

    const routerProps = props;

    return (
        <Layout
            routes={routes}
            language={props.language}
            headerProps={headerProps}
            withFooter={!authenticated}
        >
            <SEO title={seoTitle} />
            <CopyContent {...routerProps} showBottomButton={false}>
                <TermsConditions language={props.language} />
            </CopyContent>
        </Layout>
    );
};

export default TncPage;
