import React, { useEffect, useState } from 'react';
import styles from './my-conversation-info-page.module.scss';
import { Language } from '_types/language';
import { ToggleConfirmation } from 'components/toggle-confirmation';
import { Loading } from 'components/loading';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { SEO } from 'components/seo';
import { ConversationWithCategory, deckParentPages, Question } from '_types';
import { ReactComponent as IconCheckBlue } from 'images/icons/check-blue.svg';
import { ReactComponent as IconCheckWhite } from 'images/icons/check.svg';
import { ReactComponent as IconPublic } from 'images/icons/icon-public.svg';
import { ReactComponent as IconPrivate } from 'images/icons/icon-lock.svg';
// import { ReactComponent as IconShare } from './../../images/icons/icon-share.svg';
import { ReactComponent as IconDelete } from './../../images/icons/icon-delete.svg';
import { ReactComponent as IconEdit } from './../../images/icons/icon-edit.svg';
import { ReactComponent as IconConversationOutline } from './../../images/icons/icon-conversation-outline.svg';
import classNames from 'classnames';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { favouriteQuestionsSelector } from 'store-modules/favourite-questions';
import SelectQuestionBox from 'components/select-question-box/select-question-box';
import conversationClient, { MyConversation } from 'api/conversation-client';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';
import { Button, Slide, useScrollTrigger } from 'components/base';
import { ConversationCard } from 'components/conversation-card';
import { setMyConversations } from 'store-modules/my-conversations';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconAdd } from './../../images/icons/addIcon.svg';
import { ReactComponent as IconSearch } from './../../images/icons/icon-magnifier-blue.svg';
import useConversationSelector from 'hooks/use-conversation-selector';
import useHistoryRouter from 'hooks/use-history-router';
import useTypedLocation from 'hooks/use-typed-location';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';

interface LocationState {
    deck: ConversationWithCategory;
    deckParentPage: string;
    questions: Question[];
}

interface ViewCopy {
    addQuestionsText: string;
    addQuestions: string;
    deleteConversationButtonLabel: string;
    deleteConversationCancelLabel: string;
    deleteConversationDescription: string;
    deleteConversationTitle: string;
    deleteOptionLabel: string;
    descriptionFieldLabel: string;
    editNameAndDescriptionTitle: string;
    emptyConversationText: string;
    favouriteTitle: string;
    nameAndDescriptionOptionLabel: string;
    nameFieldLabel: string;
    publishConversationCancelLabel: string;
    publishConversationDescription: string;
    publishConversationOption1Label: string;
    publishConversationOption2Label: string;
    publishConversationSubTitle: string;
    publishConversationTitle: string;
    publishOptionLabel: string;
    publishToastText: string;
    questionListOptionLabel: string;
    removeQuestionToastText: string;
    saveChangesLabel: string;
    searchForQuestionsText: string;
    seoTitle: string;
    shareOptionLabel: string;
    unPublishOptionLabel: string;
    unPublishToastText: string;
    byText: string;
    backButtonText: string;
    titleErrorText: string;
}

const nbCopy: ViewCopy = {
    addQuestionsText: 'Legg til spørsmål fra likte',
    addQuestions: 'Legg til spørsmål',
    byText: 'av ',
    deleteConversationButtonLabel: 'Slett samtalen',
    deleteConversationCancelLabel: 'Avbryt',
    deleteConversationDescription:
        'Samtalen og alle spørsmål vil bli fjernet fra ditt biliotek',
    deleteConversationTitle: 'Slett samtalen',
    deleteOptionLabel: 'Slett samtalen',
    descriptionFieldLabel: 'Beskrivelse',
    editNameAndDescriptionTitle: 'Endre navn og beskrivelse',
    emptyConversationText: 'Denne samtalen er tom.',
    favouriteTitle: 'Likte spørsmål',
    nameAndDescriptionOptionLabel: 'Endre navn og beskrivelse',
    nameFieldLabel: 'Navn',
    publishConversationCancelLabel: 'Avbryt',
    publishConversationDescription:
        'Svaret ditt forteller oss hvordan vi bør sortere samtalen',
    publishConversationOption1Label: 'Hjemme',
    publishConversationOption2Label: 'På jobb',
    publishConversationSubTitle: 'La andre brukere finne samtalen din i søk',
    publishConversationTitle: 'Hvor passer samtalen best?',
    publishOptionLabel: 'Gjør samtalen offentlig',
    publishToastText: 'Samtalen er nå offentlig',
    questionListOptionLabel: 'Se og rediger spørsmål',
    removeQuestionToastText: 'Spørsmålet er fjernet fra samtalen',
    saveChangesLabel: 'Lagre endringer',
    searchForQuestionsText: 'Søk etter spørsmål',
    seoTitle: 'Mitt bibliotek',
    shareOptionLabel: 'Del samtalen',
    unPublishOptionLabel: 'Gjør samtalen privat',
    unPublishToastText: 'Samtalen er nå privat',
    backButtonText: 'Tilbake',
    titleErrorText: 'Gi samtalen en tittel',
};

const enCopy: ViewCopy = {
    addQuestionsText: 'Add questions from liked',
    addQuestions: 'Add questions',
    byText: 'by ',
    deleteConversationButtonLabel: 'Delete conversation',
    deleteConversationCancelLabel: 'Cancel',
    deleteConversationDescription:
        'The conversation and questions will be removed from your library',
    deleteConversationTitle: 'Delete conversation',
    deleteOptionLabel: 'Delete conversation',
    descriptionFieldLabel: 'Description',
    editNameAndDescriptionTitle: 'Edit name and description',
    emptyConversationText: 'This conversation is empty',
    favouriteTitle: 'Liked questions',
    nameAndDescriptionOptionLabel: 'Edit name and description',
    nameFieldLabel: 'Name',
    publishConversationCancelLabel: 'Cancel',
    publishConversationDescription:
        'Your answer tells us how we should sort the conversation',
    publishConversationOption1Label: 'Home',
    publishConversationOption2Label: 'At work',
    publishConversationTitle: 'Where would you have the conversation?',
    publishOptionLabel: 'Publish conversation',
    publishToastText: 'Conversation set to public',
    questionListOptionLabel: 'View and edit questions',
    publishConversationSubTitle: 'Let others find your conversation in search',
    removeQuestionToastText: 'Question removed from conversation',
    saveChangesLabel: 'Save changes',
    searchForQuestionsText: 'Search for questions',
    seoTitle: 'My library',
    shareOptionLabel: 'Share conversation',
    unPublishOptionLabel: 'Make conversation private',
    unPublishToastText: 'Conversation set to private',
    backButtonText: 'Back',
    titleErrorText: 'Give the conversation a title',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const maxDescriptionLength = 64;
const maxTitleLength = 42;

const MyConversationInfoPage: React.FC = () => {
    const navigate = useNavigate();
    const { language } = useHistoryRouter();
    const location = useTypedLocation<LocationState>();
    const { selectConversation } = useConversationSelector();

    const {
        // shareOptionLabel,
        addQuestionsText,
        addQuestions,
        byText,
        deleteConversationButtonLabel,
        deleteConversationCancelLabel,
        deleteConversationDescription,
        deleteConversationTitle,
        deleteOptionLabel,
        descriptionFieldLabel,
        editNameAndDescriptionTitle,
        emptyConversationText,
        favouriteTitle,
        nameAndDescriptionOptionLabel,
        nameFieldLabel,
        publishConversationCancelLabel,
        publishConversationDescription,
        publishConversationOption1Label,
        publishConversationOption2Label,
        publishConversationTitle,
        publishOptionLabel,
        publishConversationSubTitle,
        publishToastText,
        questionListOptionLabel,
        removeQuestionToastText,
        saveChangesLabel,
        searchForQuestionsText,
        seoTitle,
        unPublishOptionLabel,
        unPublishToastText,
        backButtonText,
        titleErrorText,
    } = returnCopyForLanguage(language, copies);

    const routes = returnRoutesForLanguage(language, routeObj);
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [selectedQuestions, setSelectedQuestions] = useState<Question[]>(
        location.state.questions
    );

    const [addFromFavouritesState, setAddFromFavouritesState] =
        useState<boolean>(false);

    const [titleInput, setTitleInput] = useState<string>(
        location.state.deck?.name
    );
    const [descriptionInput, setDescriptionInput] = useState<string>(
        location.state.deck?.description
    );
    const [invalidTitleInput, setInvalidTitleInput] = useState<boolean>(false);
    const [invalidDescriptionInput, setInvalidDescriptionInput] =
        useState<boolean>(false);
    const [isEditingNameAndDescription, setIsEditingNameAndDescription] =
        useState<boolean>(false);
    const [isEditingQuestions, setIsEditingQuestions] =
        useState<boolean>(false);

    const [showDeleteConversation, setShowDeleteConversation] =
        useState<boolean>(false);
    const [showPublishConversation, setShowPublishConversation] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [conversationToDisplayAndSave, setConversationToDisplayAndSave] =
        useState<ConversationWithCategory>(location.state.deck);
    const dispatch = useDispatch();
    const [questionsToSelectFrom, setQuestionsToSelectFrom] = useState<
        Question[]
    >(location.state.questions);

    const pageTitle = addFromFavouritesState
        ? favouriteTitle
        : location?.state?.deck?.name;

    useEffect(() => {
        async function fetchFavourites(): Promise<void> {
            Promise.all([conversationClient.getMyFavouriteQuestions()]).then(
                (response) => {
                    dispatch(setFavouriteQuestions(response[0].reverse()));
                }
            );
        }
        if (!favouriteQuestions) {
            fetchFavourites();
        }
    }, [favouriteQuestions, dispatch]);

    function convertToMyConversation(
        conversation: ConversationWithCategory,
        questions: Question[]
    ): MyConversation {
        const myConversation: MyConversation = {
            conversation_id: conversation.id,
            name: conversation.name,
            description: conversation.description,
            color_option: conversation.color_option
                ? conversation.color_option
                : 1,
            public: conversation.public ? conversation.public : false,
            pro_conversation: conversation.pro_conversation
                ? conversation.pro_conversation
                : false,
            questions: questions.map((q) => {
                return { question_id: q.id };
            }),
        };

        return myConversation;
    }

    const setColor = (color: number) => {
        const c = { ...conversationToDisplayAndSave };
        c.color_option = color;
        setConversationToDisplayAndSave(c);

        conversationClient
            .updateMyConversation(convertToMyConversation(c, selectedQuestions))
            .then(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                navigate(routes.error);
            });
    };

    const onExit = () => {
        window.scrollTo(0, 0);
        if (isEditingNameAndDescription) {
            setTitleInput(conversationToDisplayAndSave.name);
            setDescriptionInput(conversationToDisplayAndSave.description);
            setIsEditingNameAndDescription(false);
        } else if (isEditingQuestions) {
            setAddFromFavouritesState(false);
            // if (selectedQuestions.length !== questionsToSelectFrom.length) {
            setQuestionsToSelectFrom(selectedQuestions);
            conversationClient
                .updateMyConversation(
                    convertToMyConversation(
                        conversationToDisplayAndSave,
                        selectedQuestions
                    )
                )
                .then(() => {
                    conversationClient.getMyConversations().then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    });
                })
                .catch((error) => {
                    console.error(error);
                    navigate(-1);
                });
            // }
            setIsEditingQuestions(false);
        } else {
            selectConversation(
                {
                    ...conversationToDisplayAndSave,
                    count: selectedQuestions.length,
                },
                language,
                deckParentPages.myLibrary,
                undefined,
                undefined,
                undefined,
                undefined,
                selectedQuestions
            );
        }
    };

    const toggleQuestion = (question: Question) => {
        const copyOfSelectedQuestions: Question[] = [...selectedQuestions];

        if (selectedQuestions.map((q) => q.id)?.includes(question.id)) {
            setToastMessage(removeQuestionToastText);

            setTimeout(() => {
                setToastMessage('');
            }, 2000);
            const newListOfQuestions = copyOfSelectedQuestions.filter(
                (q) => q.id !== question.id
            );
            setSelectedQuestions(newListOfQuestions);
        } else {
            copyOfSelectedQuestions.push(question);

            setSelectedQuestions(copyOfSelectedQuestions);
        }
    };
    const onSaveConversation = () => {
        setIsLoading(true);
        //Validate input
        if (titleInput.length < 1) {
            setInvalidTitleInput(true);
            setIsLoading(false);
        } else {
            const conversationToSave: MyConversation = {
                conversation_id: conversationToDisplayAndSave.id,
                name: titleInput,
                description: descriptionInput,
                color_option: conversationToDisplayAndSave.color_option
                    ? conversationToDisplayAndSave.color_option
                    : 1,
                public: !!location.state.deck.public,
                pro_conversation: !!location.state.deck.pro_conversation,
                questions: selectedQuestions.map((q) => {
                    return { question_id: q.id };
                }),
            };
            setConversationToDisplayAndSave({
                ...conversationToDisplayAndSave,
                title: titleInput,
                name: titleInput,
                description: descriptionInput,
                public: !!location.state.deck.public,
                pro_conversation: !!location.state.deck.pro_conversation,
            });
            conversationClient
                .updateMyConversation(conversationToSave)
                .then(() => {
                    conversationClient
                        .getMyConversations()
                        .then((response) => {
                            dispatch(setMyConversations(response.reverse()));
                        })
                        .then(() => {
                            setIsEditingNameAndDescription(false);
                            setIsLoading(false);
                        });
                })
                .catch(() => {
                    selectConversation(
                        conversationToDisplayAndSave,
                        language,
                        location?.state?.deckParentPage
                    );
                });
        }
    };

    const setConversationToPrivate = () => {
        setIsLoading(true);
        setShowPublishConversation(false);
        const c = { ...conversationToDisplayAndSave };
        c.public = false;

        setConversationToDisplayAndSave(c);
        conversationClient
            .updateMyConversation(convertToMyConversation(c, selectedQuestions))
            .then(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    })
                    .then(() => {
                        setToastMessage(unPublishToastText);
                        setTimeout(() => {
                            setToastMessage('');
                        }, 3000);
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            });
    };

    const onPublishConversation = (isProConversation: boolean) => {
        setIsLoading(true);
        setShowPublishConversation(false);
        const c = { ...conversationToDisplayAndSave };
        c.public = true;

        if (isProConversation) {
            c.pro_conversation = true;
        } else {
            c.pro_conversation = false;
        }

        setConversationToDisplayAndSave(c);
        conversationClient
            .updateMyConversation(convertToMyConversation(c, selectedQuestions))
            .then(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                        setToastMessage(publishToastText);
                        setTimeout(() => {
                            setToastMessage('');
                        }, 3000);
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            });
    };

    const onDeleteConversation = () => {
        setIsLoading(true);

        conversationClient
            .removeMyConversation(conversationToDisplayAndSave.id.toString())
            .then(() => {
                conversationClient
                    .getMyConversations()
                    .then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                        setIsLoading(false);
                    })
                    .then(() => {
                        navigate(routes.myLibrary);
                    });
            });
    };

    const setFavouritesToSelectFrom = () => {
        setQuestionsToSelectFrom(favouriteQuestions!);
        setIsLoading(false);
    };

    const hasLongWords = (text: string | undefined): boolean => {
        if (text === undefined) {
            return false;
        }
        const listOfWords = text.split(' ');

        for (let i = 0; i < listOfWords.length; i++) {
            const word = listOfWords[i];
            if (word.length > 14) {
                return true;
            }
        }
        return false;
    };

    function BackButtonWithTrigger() {
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window : undefined,
        });

        return (
            <Slide appear={false} direction='down' in={true}>
                <section
                    className={classnames(
                        styles.BackButtonContainer,
                        trigger && styles.BackButtonWhiteContainer,
                        isEditingQuestions && styles.StickyBackButton
                    )}
                >
                    <button
                        className={styles.BackButton}
                        onClick={() => {
                            onExit();
                        }}
                        tabIndex={0}
                    >
                        <ArrowLeft className={styles.LeftArrow} />
                        <span>{backButtonText}</span>
                    </button>
                </section>
            </Slide>
        );
    }

    const emptyQuestionListStateContainer = questionsToSelectFrom.length ===
        0 &&
        !addFromFavouritesState && (
            <div className={styles.EmptyStateContainer}>
                {emptyConversationText}
                <section className={styles.options}>
                    <div
                        role='button'
                        tabIndex={0}
                        className={styles.option}
                        onKeyDown={() => {
                            setAddFromFavouritesState(true);

                            favouriteQuestions &&
                                setQuestionsToSelectFrom(favouriteQuestions);
                        }}
                        onClick={() => {
                            setIsLoading(true);
                            setAddFromFavouritesState(true);

                            favouriteQuestions
                                ? setFavouritesToSelectFrom()
                                : conversationClient
                                      .getMyFavouriteQuestions()
                                      .then((response) => {
                                          dispatch(
                                              setFavouriteQuestions(
                                                  response.reverse()
                                              )
                                          );
                                          setQuestionsToSelectFrom(
                                              [...response].reverse()
                                          );
                                      })
                                      .then(() => {
                                          setIsLoading(false);
                                      });
                        }}
                    >
                        <div className={styles.icon}>
                            <IconAdd />
                        </div>
                        <p>{addQuestionsText}</p>
                    </div>
                    <div
                        role='button'
                        tabIndex={0}
                        className={styles.option}
                        onKeyDown={() => {
                            navigate(routes.search);
                        }}
                        onClick={() => {
                            navigate(routes.search);
                        }}
                    >
                        <div className={styles.icon}>
                            <IconSearch />
                        </div>
                        <p>{searchForQuestionsText}</p>
                    </div>
                </section>
            </div>
        );

    const editNameAndDescriptionSection = (
        <div className={styles.EditNameAndDescriptionContainer}>
            <h2 className={styles.title}>{editNameAndDescriptionTitle}</h2>
            <section className={styles.FieldContainer}>
                <label className={styles.TitleLabel} htmlFor={nameFieldLabel}>
                    {nameFieldLabel}
                </label>
                <input
                    name='title'
                    onChange={({ currentTarget: { value } }) =>
                        setTitleInput(value)
                    }
                    onFocus={() => setInvalidTitleInput(false)}
                    type='text'
                    placeholder=''
                    defaultValue={titleInput}
                    className={classnames(
                        styles.titleField,
                        invalidTitleInput && styles.InvalidInput
                    )}
                    maxLength={maxTitleLength}
                />
                <div
                    className={
                        invalidTitleInput
                            ? styles.TitleErrorAndCounter
                            : styles.OnlyCounter
                    }
                >
                    {invalidTitleInput && (
                        <p className={styles.TitleErrorText}>
                            {titleErrorText}
                        </p>
                    )}
                    <p className={styles.Counter}>
                        {titleInput.length + '/' + maxTitleLength}
                    </p>
                </div>
                <label
                    htmlFor={descriptionFieldLabel}
                    className={styles.DescriptionLabel}
                >
                    {descriptionFieldLabel}
                </label>
                <textarea
                    name='description'
                    onChange={({ currentTarget: { value } }) =>
                        setDescriptionInput(value)
                    }
                    onFocus={() => setInvalidDescriptionInput(false)}
                    placeholder={''}
                    defaultValue={descriptionInput}
                    className={classnames(
                        styles.InputRow__Input,
                        styles.descriptionField,
                        invalidDescriptionInput && styles.InvalidInput
                    )}
                    maxLength={maxDescriptionLength}
                />
                <p className={styles.Counter}>
                    {descriptionInput.length + '/' + maxDescriptionLength}
                </p>
                <div className={classnames(styles.ButtonContainer)}>
                    <Button
                        form='editConversationForm'
                        appearance='cta'
                        fullWidth={false}
                        className={styles.SaveButton}
                        onClick={onSaveConversation}
                    >
                        {saveChangesLabel}
                    </Button>
                </div>
            </section>
        </div>
    );

    if (isLoading) {
        return <Loading />;
    }
    return (
        <div className={styles.MyConversationInfoPageOuterContainer}>
            <div className={styles.MyConversationInfoPageContainer}>
                <SEO title={seoTitle} />

                <div className={styles.bg} />

                <BackButtonWithTrigger />

                {isEditingNameAndDescription && editNameAndDescriptionSection}

                {isEditingQuestions && (
                    <section className={styles.QuestionListSelection}>
                        <p className={styles.QuestionSelectTitleWrapper}>
                            <span
                                className={classnames(
                                    styles.QuestionSelectTitle,
                                    hasLongWords(pageTitle) && styles.BreakWords
                                )}
                            >
                                {pageTitle}
                            </span>
                            {!addFromFavouritesState && (
                                <span
                                    className={
                                        styles.QuestionSelectTitleCounter
                                    }
                                >
                                    &nbsp;
                                    {'(' + questionsToSelectFrom.length + ')'}
                                </span>
                            )}
                        </p>
                        {emptyQuestionListStateContainer}
                        {questionsToSelectFrom?.map((question) => {
                            return (
                                <SelectQuestionBox
                                    className={styles.QuestionBox}
                                    question={question}
                                    key={question.id}
                                    selectedQuestions={selectedQuestions}
                                    isEditing={!addFromFavouritesState}
                                    onClick={() => toggleQuestion(question)}
                                />
                            );
                        })}
                    </section>
                )}

                {!isEditingNameAndDescription && !isEditingQuestions && (
                    <section className={styles.ContentContainer}>
                        <section>
                            <ConversationCard
                                key={conversationToDisplayAndSave.id}
                                conversation={conversationToDisplayAndSave}
                                category={{
                                    ...conversationToDisplayAndSave.category,
                                    name:
                                        byText +
                                        conversationToDisplayAndSave.category
                                            .name,
                                }}
                                onClick={() => undefined}
                                className={classNames(
                                    styles.card,
                                    conversationToDisplayAndSave.category &&
                                        conversationToDisplayAndSave.color_option ===
                                            1 &&
                                        styles.userCreatedConversation1,
                                    conversationToDisplayAndSave.category &&
                                        conversationToDisplayAndSave.color_option ===
                                            2 &&
                                        styles.userCreatedConversation2,
                                    conversationToDisplayAndSave.category &&
                                        conversationToDisplayAndSave.color_option ===
                                            3 &&
                                        styles.userCreatedConversation3
                                )}
                            />
                        </section>
                        <section>
                            <div className={styles.customRadios}>
                                <div>
                                    <input
                                        type='radio'
                                        className={styles.color1}
                                        name='color'
                                        value={1}
                                        checked={
                                            conversationToDisplayAndSave.color_option ===
                                            1
                                        }
                                        onChange={() => {
                                            setColor(1);
                                        }}
                                    />
                                    <label htmlFor='color1'>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setColor(1);
                                            }}
                                        >
                                            <IconCheckBlue
                                                className={styles.IconCheck}
                                            />
                                        </button>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        type='radio'
                                        className={styles.color2}
                                        name='color'
                                        value={2}
                                        checked={
                                            conversationToDisplayAndSave.color_option ===
                                            2
                                        }
                                        onChange={() => {
                                            setColor(2);
                                        }}
                                    />
                                    <label htmlFor='color2'>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setColor(2);
                                            }}
                                        >
                                            <IconCheckBlue
                                                className={styles.IconCheck}
                                            />
                                        </button>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        type='radio'
                                        className={styles.color3}
                                        name='color'
                                        value={3}
                                        checked={
                                            conversationToDisplayAndSave.color_option ===
                                            3
                                        }
                                        onChange={() => {
                                            setColor(3);
                                        }}
                                    />
                                    <label htmlFor='color3'>
                                        <button
                                            type='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setColor(3);
                                            }}
                                        >
                                            <IconCheckBlue
                                                className={styles.IconCheck}
                                            />
                                        </button>
                                    </label>
                                </div>
                            </div>
                        </section>
                        <section className={styles.InnerContentContainer}>
                            <div
                                role='button'
                                tabIndex={0}
                                className={styles.option}
                                onKeyDown={() => {
                                    setIsEditingNameAndDescription(true);
                                }}
                                onClick={() => {
                                    setIsEditingNameAndDescription(true);
                                }}
                            >
                                <div className={styles.EditIcon}>
                                    <IconEdit />
                                </div>
                                <p>{nameAndDescriptionOptionLabel}</p>
                            </div>
                            <div
                                role='button'
                                tabIndex={0}
                                className={styles.option}
                                onKeyDown={() => {
                                    setIsEditingQuestions(true);
                                }}
                                onClick={() => {
                                    setIsEditingQuestions(true);
                                }}
                            >
                                <div className={styles.ConversationIcon}>
                                    <IconConversationOutline />
                                </div>
                                <p>{questionListOptionLabel}</p>
                            </div>
                            <div
                                role='button'
                                tabIndex={0}
                                className={classNames(
                                    styles.option,
                                    styles.PublishOption
                                )}
                                onKeyDown={() => {
                                    conversationToDisplayAndSave.public
                                        ? setConversationToPrivate()
                                        : setShowPublishConversation(true);
                                }}
                                onClick={() => {
                                    conversationToDisplayAndSave.public
                                        ? setConversationToPrivate()
                                        : setShowPublishConversation(true);
                                }}
                            >
                                <div className={styles.PublicIconAndTitle}>
                                    <div
                                        className={classNames(
                                            !conversationToDisplayAndSave.public
                                                ? styles.PublicIcon
                                                : styles.PrivateIcon
                                        )}
                                    >
                                        {!conversationToDisplayAndSave.public ? (
                                            <IconPublic />
                                        ) : (
                                            <IconPrivate />
                                        )}
                                    </div>
                                    <p>
                                        {!conversationToDisplayAndSave.public
                                            ? publishOptionLabel
                                            : unPublishOptionLabel}
                                    </p>
                                </div>
                                {!conversationToDisplayAndSave.public && (
                                    <div className={styles.PublishInfoText}>
                                        {publishConversationSubTitle}
                                    </div>
                                )}
                            </div>
                            {/* <section className={styles.option}>
                            <div className={styles.ShareIcon}>
                                <IconShare />
                            </div>
                            <p>{shareOptionLabel}</p>
                        </section> */}
                            <div
                                role='button'
                                tabIndex={0}
                                className={classNames(styles.option)}
                                onKeyDown={() => {
                                    setShowDeleteConversation(true);
                                }}
                                onClick={() => {
                                    setShowDeleteConversation(true);
                                }}
                            >
                                <div className={styles.DeleteIcon}>
                                    <IconDelete />
                                </div>
                                <p>{deleteOptionLabel}</p>
                            </div>

                            {showDeleteConversation && (
                                <ToggleConfirmation
                                    title={deleteConversationTitle}
                                    description={deleteConversationDescription}
                                    cancelLabel={deleteConversationCancelLabel}
                                    buttonLabel={deleteConversationButtonLabel}
                                    onSubmit={() => {
                                        onDeleteConversation();
                                    }}
                                    onCancel={() =>
                                        setShowDeleteConversation(false)
                                    }
                                ></ToggleConfirmation>
                            )}

                            {showPublishConversation && (
                                <ToggleConfirmation
                                    title={publishConversationTitle}
                                    description={publishConversationDescription}
                                    cancelLabel={publishConversationCancelLabel}
                                    option1Label={
                                        publishConversationOption1Label
                                    }
                                    option2Label={
                                        publishConversationOption2Label
                                    }
                                    onSelectOption1={() => {
                                        onPublishConversation(false);
                                    }}
                                    onSelectOption2={() => {
                                        onPublishConversation(true);
                                    }}
                                    onCancel={() =>
                                        setShowPublishConversation(false)
                                    }
                                ></ToggleConfirmation>
                            )}
                        </section>
                    </section>
                )}
            </div>
            {addFromFavouritesState && (
                <div
                    className={classnames(
                        styles.ButtonContainer,
                        isEditingQuestions && styles.Sticky
                    )}
                >
                    <Button
                        form='createConversationForm'
                        appearance='cta'
                        fullWidth={false}
                        className={styles.ConfirmButton}
                        onClick={onExit}
                    >
                        {addQuestions}
                    </Button>
                </div>
            )}
            <div className={styles.ToastContainer}>
                <ToastMessage
                    className={
                        toastMessage.length > 0 && !addFromFavouritesState
                            ? styles.RemovedQuestionToast
                            : styles.Hidden
                    }
                    icon={<IconCheckWhite />}
                    message={toastMessage}
                />
            </div>
        </div>
    );
};

export default MyConversationInfoPage;
