import { UserRegistrationLinkData } from 'store-modules/user';

enum StorageKeys {
  userRegistration = 'userRegistration',
}

//  get data from local storage
function getUserRegistrationData(key: StorageKeys): UserRegistrationLinkData | undefined {
  const data = localStorage.getItem(key);
  if (!data) return undefined;

  const result = JSON.parse(data) as UserRegistrationLinkData;
  return result;
}

//  set data to local storage
function setLocalStorageData(key: StorageKeys, data: UserRegistrationLinkData): void {
  //  remove data if it exists
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }

  localStorage.setItem(key, JSON.stringify(data));
}

//  remove data from local storage
function removeLocalStorageData(key: StorageKeys): void {
  if (!localStorage.getItem(key)) return;
  localStorage.removeItem(key);
}

export { getUserRegistrationData, setLocalStorageData, StorageKeys, removeLocalStorageData }