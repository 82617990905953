import authClient, { LoginData } from 'api/auth-client';
import userClient, { CreateUserResponse, UserData } from 'api/user-client';
import authService from 'services/auth-service';
import { Language } from '_types/language';
import restClient from '../../../api/rest-client';
import { AccountInfo, Product } from '../_types';

const BASE_URL = '/wp-json/fuelbox/v1/';
const API_PRODUCTS = 'getProducts';

export interface SessionRequest {
    productId: string | number;
    lang: Language;
}

export interface SessionResponse {
    token: string;
    url: string;
}

const mapAccountToUser = (
    values: AccountInfo,
    selectedProductId?: string | number
): UserData => ({
    username: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    password: values.password,
    app_storage: { selectedProductId },
    pro_code: values.proCode,
});

export const createUser = (
    accountInfo: AccountInfo,
    selectedProductId?: number | string
): Promise<number> => {
    if (!accountInfo) throw new Error();
    const newUser: UserData = mapAccountToUser(accountInfo, selectedProductId);

    return userClient
        .createUser(newUser)
        .then((res: CreateUserResponse): number => res.code);
};

export const autoLogin = (loginData: LoginData): Promise<void> => {
    if (!loginData) throw new Error();
    return authClient.getToken(loginData).then(authService.login);
};

export const getProducts = (): Promise<Product[]> =>
    restClient
        .get<Product[]>(API_PRODUCTS, true, undefined, null, {
            baseUrl: BASE_URL,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res);

// For tests
export const mockSessionResponse: SessionResponse = {
    token: 'testToken',
    url: 'testUrl',
};
