import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import SwiperInstance from 'swiper';
import { Question } from '_types/question';
import { Deck } from '_types/deck';
import { SEO } from 'components/seo';
import { Navigate, useNavigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { PRO_PREFIX } from 'constants/general';
import useTypedParams from 'hooks/use-typed-params';
import useTypedLocation from 'hooks/use-typed-location';
import SwiperContextProvider from 'hooks/use-swiper-context/provider';
import { QuestionSwiper } from 'pages/deck-modal/question-swiper';
import { Slide, SlideType } from '_types';

const makeSlides = (
    deck: Deck,
    questions: Question[],
    EndSlideTitle: string
): Slide[] => {
    const proEndSlide = {
        id: 'end',
        slideType: SlideType.PRO_END,
        slug: 'ferdig-snakket',
        title: EndSlideTitle,
    };

    const questionsSlides = questions.map((q) => ({
        id: q.id,
        slideType: SlideType.QUESTION,
        slug: q.slug,
        title: `${deck.name} - ${q.text}`,
        data: q,
    }));
    return [...questionsSlides, proEndSlide].map((slide, index) => ({
        ...(slide as Slide),
        index,
    }));
};

interface Params {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}

interface Props {
    deck: Deck;
    questions: Question[];
    params: Params;
    language: Language;
    parentPage: string;
    setIsLastSlide: (x: boolean) => void;
    setCurrentQuestion: (question: Question) => void;
    onSlide: () => void;
}

type SlideChangeSource = 'swiper' | 'history';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface ViewCopy {
    EndSlideTitle: string;
}
const nbCopy: ViewCopy = {
    EndSlideTitle: 'Ferdig snakket?',
};

const enCopy: ViewCopy = {
    EndSlideTitle: '',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const QuestionSwiperContainer: FC<Props> = ({
    deck,
    language,
    onSlide,
    params,
    parentPage,
    questions,
    setCurrentQuestion,
    setIsLastSlide,
}) => {
    const navigate = useNavigate();
    // Slide can be changed by swiper (swipe, click next/back)
    // or by history (browser's "back" and "forward" buttons)
    const [slideChangeSource, setSlideChangeSource] =
        useState<SlideChangeSource>('swiper');

    const routes = returnRoutesForLanguage(language, routeObj);

    const { EndSlideTitle } = returnCopyForLanguage(language, copies);

    const slides = useMemo<Slide[]>(
        () => makeSlides(deck, questions, EndSlideTitle),
        [deck, questions, EndSlideTitle]
    );
    const { deckSlug, questionSlug, taxonomy } = useTypedParams<Params>();

    const initialSlide = questionSlug
        ? slides.find((s) => s.slug === questionSlug)
        : undefined;

    const [swiper, setSwiper] = useState<SwiperInstance>();
    const [activeSlideIndex, setActiveSlideIndex] = useState(
        initialSlide && initialSlide.index
    );

    const handlePopState = (): void => {
        setSlideChangeSource('history');
    };

    const location = useTypedLocation<object>();

    const syncSlideWithLocation = useCallback(() => {
        const realActiveSlideIndex = activeSlideIndex;

        // location is out of sync
        if (slideChangeSource === 'swiper') {
            const slug = slides[realActiveSlideIndex!].slug;
            const nextPath = `/${language}/${PRO_PREFIX}/${taxonomy}/${deckSlug}/${slug}`;

            navigate(nextPath, {
                state: {
                    ...(location.state ?? {}),
                    parentPage,
                },
            });
        }

        // current slide is out of sync
        if (slideChangeSource === 'history') {
            setSlideChangeSource('swiper');
            const nextSlide = slides.find(
                (s) => s.slug === params.questionSlug
            );

            if (nextSlide !== undefined) {
                swiper?.slideTo(nextSlide.index);
            }
        }
    }, [
        activeSlideIndex,
        deckSlug,
        language,
        location.state,
        navigate,
        params.questionSlug,
        parentPage,
        slideChangeSource,
        slides,
        swiper,
        taxonomy,
    ]);

    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        !!activeSlideIndex &&
            activeSlideIndex > -1 &&
            setCurrentQuestion(questions[activeSlideIndex!]);
        onSlide();

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [activeSlideIndex, onSlide, questions, setCurrentQuestion]);

    useEffect(() => {
        if (activeSlideIndex == null) return;
        setIsLastSlide(slides.length === activeSlideIndex + 1);

        setCurrentQuestion(questions[activeSlideIndex]);
        // location and active slide is in sync
        if (questionSlug === slides[activeSlideIndex].slug) {
            return;
        }

        // On 'popstate' event, location is changed and component receives
        // new match params first, only then handlePopState is executed.
        // Need to delay synchronization so that handlePopState took effect.
        const timeout = setTimeout(syncSlideWithLocation, 0);

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [
        activeSlideIndex,
        questions,
        questionSlug,
        setCurrentQuestion,
        setIsLastSlide,
        slides,
        syncSlideWithLocation,
    ]);

    const handleSetSwiper = (newSwiper: SwiperInstance): void => {
        setSwiper(newSwiper);

        newSwiper?.on('slideChange', () => {
            setActiveSlideIndex(newSwiper.activeIndex);
        });
    };

    if (activeSlideIndex === undefined) {
        // FIXME: redirect to 404 page?
        return <Navigate to={routes.index} />;
    }

    const activeSlide = slides[activeSlideIndex];
    const seoTitle = activeSlide.title;

    return (
        <React.Fragment>
            <SEO title={seoTitle} />
            {slides.length > 0 && (
                <SwiperContextProvider
                    paginationDefs={slides.map(
                        ({ slideType }) => slideType === SlideType.QUESTION
                    )}
                    swiper={swiper}
                    setSwiper={handleSetSwiper}
                >
                    <QuestionSwiper
                        initialSlideIndex={activeSlide.index}
                        slides={slides}
                    />
                </SwiperContextProvider>
            )}
        </React.Fragment>
    );
};

export default QuestionSwiperContainer;
