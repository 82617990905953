import React, { FC, useState } from 'react';
import SwiperInstance from 'swiper';

import SwiperContextProvider from 'hooks/use-swiper-context/provider';

import SessionSwiper from './session-swiper';
import SessionProvider from '../../use-session/provider';
import {
    ActiveContent,
    SessionParams,
    SlideType,
} from 'utils/collaboration-utils';
import useCollaboration from '../../use-collaboration';
import useTypedParams from 'hooks/use-typed-params';
import { Navigate } from 'react-router-dom';

const SessionView: FC = () => {
    const { interactiveMode, questions } = useCollaboration();
    const { questionSlug } = useTypedParams<SessionParams>();
    const [swiper, setSwiper] = useState<SwiperInstance>();

    if (questions.length === 0) return null;

    const slideDefs: ActiveContent[] = [];

    questions.forEach((question) =>
        slideDefs.push({
            question,
            type: SlideType.QUESTION,
        })
    );

    // Only display end slide for interactive mode
    if (interactiveMode) slideDefs.push({ type: SlideType.END });

    if (!questionSlug) return <Navigate to={questions[0].slug} replace />;

    return (
        <SwiperContextProvider
            paginationDefs={slideDefs.map(
                ({ type }) => type === SlideType.QUESTION
            )}
            swiper={swiper}
            setSwiper={setSwiper}
        >
            <SessionProvider slideDefs={slideDefs}>
                <SessionSwiper />
            </SessionProvider>
        </SwiperContextProvider>
    );
};

export default SessionView;
