import { RootReducer } from 'store/store';
import { FavouriteQuestionsState } from './_types';
import { Question } from '_types';

const favouriteQuestionsStateFromRoot = (
    state: RootReducer
): FavouriteQuestionsState => state.favouriteQuestions;

export const favouriteQuestionsSelector = (
    state: RootReducer
): Question[] | null =>
    favouriteQuestionsStateFromRoot(state).favouriteQuestions;
