import React, { useEffect, useState } from 'react';
import styles from './company-admin.module.scss';
import { Button, Icon } from 'components/base';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import {
    setBoldToastMessage,
    setToastMessage,
    toastMessageSelector,
} from 'store-modules/user';
import { Layout } from 'components/layout';
import { BackButton } from 'components/back-button';
import UserTable from './user-table';
import { proAccountSelector } from 'store-modules/professional';
import { useWindowSize } from 'hooks';
import MobileUserTable from './mobile-user-table';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import companyClient, { CompanyData } from 'api/company-client';
import { CompanyRoleTypes, CompanyUser } from '_types/company-user';
import { Loading } from 'components/loading';
import { IS_IOS_APP, IS_ANDROID_APP } from '../../../constants';
import classnames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';

export interface User extends CompanyUser {
    markedForDeletion: boolean;
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface ViewCopy {
    title: string;
    companyCodeLabel: string;
    copyCodeButtonLabel: string;
    linkCopyToast: string;
    usersLabel: string;
}

const nbCopy: ViewCopy = {
    title: 'Administrer abonnement',
    companyCodeLabel: 'Bedriftskode:',
    copyCodeButtonLabel: 'Kopier link med kode',
    linkCopyToast: 'Link kopiert!',
    usersLabel: 'Brukere',
};

const enCopy: ViewCopy = {
    title: 'Administer subscription',
    companyCodeLabel: 'Company code:',
    copyCodeButtonLabel: 'Copy link with code',
    linkCopyToast: 'Link copied!',
    usersLabel: 'Users',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

interface Data {
    id: number;
    name: string;
    email: string;
    memberSince: string;
    role: string;
}

const CompanyAdminPage: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        companyCodeLabel,
        copyCodeButtonLabel,
        linkCopyToast,
        usersLabel,
    } = returnCopyForLanguage(language, copies);
    const navigate = useNavigate();
    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    const organization = useSelector(proAccountSelector);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const toastMessage = useSelector(toastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<User[]>();
    const [company, setCompany] = useState<CompanyData>();
    const [userRows, setUserRows] = useState<Data[]>([]);
    const { isiOS } = getUAInfo(window.navigator);

    useEffect(() => {
        return () => {
            setTimeout(() => setToastToShow(''), 3000);
        };
    });

    useEffect(() => {
        return () => {
            dispatch(setToastMessage(''));
            dispatch(setBoldToastMessage(''));
        };
    }, [dispatch]);

    useEffect(() => {
        companyClient.getCompany().then((c) => {
            setCompany(c);
        });
        companyClient.getCompanyUsers().then((companyUsers) => {
            const mappedUsers = companyUsers.map((us) => {
                const u: User = { ...us, markedForDeletion: false };
                return u;
            });
            setUsers(mappedUsers);
        });
    }, []);

    useEffect(() => {
        if (company && users) {
            setIsLoading(false);
        }
    }, [company, users]);

    function createData(
        id: number,
        name: string,
        email: string,
        memberSince: string,
        role: string
    ): Data {
        return {
            id,
            name,
            email,
            memberSince,
            role,
        };
    }

    const getURL = () => {
        const localhostURL =
            'http://localhost:3000' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const developURL =
            'https://develop.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const testURL =
            'https://test.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const stagingURL =
            'https://test.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const prodURL =
            'https://app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;

        const url = window.location.href;
        let urlToReturn = '';
        if (IS_IOS_APP || IS_ANDROID_APP) {
            urlToReturn += prodURL;
        } else if (url.indexOf('localhost:3000') !== -1) {
            return localhostURL;
        } else if (url.indexOf('develop.app') !== -1) {
            urlToReturn += developURL;
        } else if (url.indexOf('test.app') !== -1) {
            urlToReturn += testURL;
        } else if (url.indexOf('staging.app') !== -1) {
            urlToReturn += stagingURL;
        } else {
            urlToReturn += prodURL;
        }

        return urlToReturn;
    };

    useEffect(() => {
        const tempRows = [];
        if (!!users)
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                const name = user.firstName + ' ' + user.lastName;
                const memberSinceDate = new Date(user.memberSince);
                const memberSinceFormattet =
                    ('0' + memberSinceDate.getDate()).slice(-2) +
                    '.' +
                    ('0' + (memberSinceDate.getMonth() + 1)).slice(-2) +
                    '.' +
                    memberSinceDate.getFullYear();

                const userData = createData(
                    user.id,
                    name,
                    user.email,
                    memberSinceFormattet,
                    user.role === CompanyRoleTypes.admin ? user.role : ''
                );
                tempRows.push(userData);
                setUserRows([]);
                setUserRows(tempRows);
            }
    }, [users]);

    const toastMessageContainer = toastToShow.length > 0 && (
        <div
            className={
                toastToShow.length > 0
                    ? classnames(styles.Toast, isiOS && styles.IOSToast)
                    : styles.Hidden
            }
        >
            <ToastMessage
                className={
                    toastToShow.length > 0 ? styles.Toast : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
            />
        </div>
    );

    const userCounter = (
        <div className={styles.UserCounter}>
            {' '}
            <Icon
                className={styles.PersonIcon}
                fontSize='small'
                name={'person_outlined'}
            />{' '}
            {users?.length} / {company?.number_of_users}
        </div>
    );

    if (isLoading) {
        return <Loading />;
    }

    //TODO EXCEPTION FOR NATIVE  when copying pro code
    return (
        <div>
            {toastMessageContainer}

            <Layout
                routes={routes}
                language={language}
                pageClassName={styles.PageLayout}
                headerProps={{
                    withLogin: true,
                    showLogoLink: !isMobile,
                }}
                withFooter={false}
            >
                <div className={styles.PageContainer}>
                    <BackButton
                        language={language}
                        className={classnames(
                            styles.BackButton,
                            IS_IOS_APP && styles.IosBackButton
                        )}
                        clickHandler={() => {
                            navigate(routes.account.index);
                        }}
                    />
                    <p className={styles.PageTitle}>{title}</p>
                    <div className={styles.CompanyInfoBox}>
                        <picture>
                            {!!organization?.logo?.url ? (
                                <picture>
                                    <img
                                        src={organization.logo.url}
                                        className={styles.OrganizationLogo}
                                        title={organization.name}
                                        alt={organization.name}
                                    />
                                </picture>
                            ) : (
                                organization?.name.toUpperCase()
                            )}
                        </picture>
                        {isMobile && userCounter}
                        <div className={styles.CompanyCodeContainer}>
                            <div className={styles.CompanyCodeLabel}>
                                {companyCodeLabel}
                            </div>
                            <div className={styles.CompanyCode}>
                                {company?.pro_code}
                            </div>
                        </div>
                        <Button
                            className={styles.CompanyCodeCopyButton}
                            leftIcon='attachment'
                            onClick={() => {
                                navigator.clipboard.writeText(getURL());
                                setToastToShow(linkCopyToast);
                            }}
                        >
                            {copyCodeButtonLabel}
                        </Button>
                        {!isMobile && userCounter}
                    </div>
                    <div className={styles.UserTableContainer}>
                        {!isMobile && (
                            <p className={styles.UserTableHeader}>
                                {usersLabel}
                            </p>
                        )}
                        <div className={styles.UserTable}>
                            {isMobile ? (
                                <MobileUserTable
                                    setToast={(toast) => setToastToShow(toast)}
                                    rows={users!}
                                    setUsers={(users) => {
                                        const mappedCompanyUsers = users.map(
                                            (us) => {
                                                const u: User = {
                                                    ...us,
                                                    markedForDeletion: false,
                                                };
                                                return u;
                                            }
                                        );
                                        setUsers(mappedCompanyUsers);
                                    }}
                                    setIsLoading={(loading) =>
                                        setIsLoading(loading)
                                    }
                                ></MobileUserTable>
                            ) : (
                                <UserTable
                                    setToast={(toast) => setToastToShow(toast)}
                                    rows={userRows}
                                    setUsers={(users) => {
                                        const mappedCompanyUsers = users.map(
                                            (us) => {
                                                const u: User = {
                                                    ...us,
                                                    markedForDeletion: false,
                                                };
                                                return u;
                                            }
                                        );
                                        setUsers(mappedCompanyUsers);
                                    }}
                                ></UserTable>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default CompanyAdminPage;
