import { useNavigate } from 'react-router-dom';

import collaborationClient, { Collaboration } from 'api/collaboration-client';
import useHistoryRouter from 'hooks/use-history-router';
import {
    getSessionDocumentRef,
    sanitize,
    getQuestionDocumentRef,
    DisplayAs,
} from 'utils/collaboration-utils';
import { Deck, Question } from '_types';

type CreateCollaborationArgs = {
    deck: Deck;
    questions: Question[];
    callbackFn: () => void;
};

export const useCreateCollaboration = () => {
    const navigate = useNavigate();
    const { language, routes } = useHistoryRouter();

    const routeBase = routes.collaborationOverview;

    /**
     * @returns Path for new collaboration taking the id of the new collaboration as an argument
     */
    const generatePathForNewCollaboration = (slug: string) =>
        [routeBase, slug].join('/');

    /**
     * Common response handler for creating a new collaboration and copying an existing one
     */
    const responseHandler =
        ({ callbackFn, deck, questions }: CreateCollaborationArgs) =>
        ({ collaborationId, slug }: Collaboration) => {

            getSessionDocumentRef(collaborationId)
                .set({
                    answeredQuestions: [],
                    conversation: {
                        deck: sanitize(deck),
                        questions: sanitize(questions),
                    },
                    language,
                    participantCount: 0,
                    started: false,
                })
                .then(() =>
                    Promise.all(
                        questions.map(
                            async ({id: questionId, slug, text}, index) =>
                                getQuestionDocumentRef(
                                    collaborationId,
                                    questionId
                                ).set({
                                    display: DisplayAs.WORDCLOUD,
                                    id: questionId,
                                    index,
                                    slug,
                                    text,
                                })
                        )
                    ).then(() =>
                        navigate(generatePathForNewCollaboration(slug))
                    )
                )
                .finally(callbackFn);
        }

    const createCollaboration = (args: CreateCollaborationArgs) => {
        const {
            callbackFn,
            deck: { id: deckId , taxonomy},
        } = args;

        collaborationClient
            .createCollaboration(deckId, taxonomy)
            .then(

                responseHandler(args)
            )
            .catch((err) => {
                console.error(err);
                callbackFn();
            });
    };

    const copyCollaboration = (
        /**
         * ID of the Collaboration to copy
         */
        originalCollaborationId: string,
        args: CreateCollaborationArgs
    ) => {
        const { callbackFn } = args;

        collaborationClient
            .copyCollaboration(originalCollaborationId)
            .then(responseHandler(args))
            .catch((err) => {
                console.error(err);
                callbackFn();
            });
    };

    return {
        createCollaboration,
        copyCollaboration,
    };
};
