import React, { FC, Fragment } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { Stack } from 'components/base';
import { getSessionDocumentRef, SessionData } from 'utils/collaboration-utils';

import ActiveSessionInfo from './active-session-info';
import FinishedSessionInfo from './finished-session-info';
import UnfinishedSessionInfo from './unfinished-session-info';
import useCollaboration from '../../../use-collaboration';

const SessionInfoContent: FC = () => {
    const { collaborationId, interactiveMode } = useCollaboration();

    const sessionRef = getSessionDocumentRef(collaborationId);
    const [sessionData] = useDocumentData<SessionData>(sessionRef as never);

    const { answeredQuestions = [], conversation: { questions = [] } = {} } =
        sessionData || {};

    const answeredQuestionCount = answeredQuestions?.length ?? 0;

    const questionCount = questions?.length ?? 0;

    const finished =
        answeredQuestionCount != null &&
        questionCount &&
        answeredQuestionCount === questionCount;

    const unfinished =
        answeredQuestionCount != null &&
        questionCount &&
        answeredQuestionCount < questionCount;

    return (
        <Fragment>
            <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction='row'
                alignItems='center'
                borderRadius='inherit'
            >
                {interactiveMode ? (
                    <ActiveSessionInfo />
                ) : finished ? (
                    <FinishedSessionInfo />
                ) : unfinished ? (
                    <UnfinishedSessionInfo />
                ) : null}
            </Stack>
        </Fragment>
    );
};

export default SessionInfoContent;
