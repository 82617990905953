import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getDomainLanguageDefault } from 'services/language-service';

import en from './assets/locales/en.json';
import nb from './assets/locales/nb-NO.json';

export const LanguageMap = {
    nb: 'nb',
    en: 'en',
};

export const resources = {
    [LanguageMap.nb]: { translation: nb },
    [LanguageMap.en]: { translation: en },
};

i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    keySeparator: '.',
    lng: getDomainLanguageDefault(),
    resources,
});

export default i18n;
