import React, { FC, forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import {
    CustomIconMap,
    CustomIconOption,
    IconOption,
    IconOptions,
    MuiIconMap,
    MuiIconOption,
} from './icon-options';

export type IconProps = Omit<
    SvgIconProps,
    'classes' | 'children' | 'shapeRendering' | 'viewBox' | 'inheritViewBox'
> & {
    /**
     * Icon name, specifies which icon will be rendered
     */
    name: IconOption;
};

export const Icon: FC<IconProps> = forwardRef(({ name, ...rest }, ref) => {
    if (Object.keys(MuiIconMap).includes(name)) {
        const { icon: Component, ...props } = MuiIconMap[name as MuiIconOption];
        return <Component {...rest} {...props} ref={ref} />;
    }

    const Component = CustomIconMap[name as CustomIconOption];

    return (
        <SvgIcon
            {...rest}
            {...{ component: Component }}
            ref={ref}
            inheritViewBox
        />
    );
});

Icon.displayName = 'Icon';

export { IconOptions };
export type { IconOption };
