import { Avatar, Box, Button, styled, Typography } from 'components/base';

import TopDockedCard from '../../top-docked-card';

const spacingLg = 4;

export const StyledTopDockedCard = styled(TopDockedCard)`
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('md')} {
        width: auto;
    }
` as typeof TopDockedCard;

export const JoinInfoWrapper = styled(Box)`
    align-items: stretch;
    display: flex;
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.spacing(4.5)};

    ${({ theme }) => theme.breakpoints.up('md')} {
        margin: ${({ theme }) => theme.spacing(0, 8.75)};
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        padding-bottom: ${({ theme }) => theme.spacing(2)};
    }
`;

export const ContentWrapper = styled(Box)`
    max-width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(2, 1, 2)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
        padding: ${({ theme }) => theme.spacing(3, 6, 4)};
        flex-direction: row;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        padding: ${({ theme }) => theme.spacing(2.5, 6, 3, 4)};
    }
`;

export const JoinDescription = styled(Typography)`
    text-align: center;
    max-width: 100%;
    width: min-content;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        text-align: left;
        width: 320px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        min-width: 200px;
        width: 280px;
    }
` as typeof Typography;

export const JoinLink = styled(Typography)`
    color: inherit;
    font-weight: 600;
    font-size: 24px;
    text-decoration: underline;
` as typeof Typography;

export const JoinCode = styled(Typography)`
    font-weight: 900;
    font-size: 4rem;

    ${({ theme }) => theme.breakpoints.up('md')} {
        font-size: 5rem;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        margin-left: ${({ theme }) => theme.spacing(spacingLg)};
    }
` as typeof Typography;

export const StyledAvatar = styled(Avatar)`
    background: ${({ theme }) => theme.palette.common.white};
    width: 36px;
    height: 36px;
    margin: ${({ theme }) => theme.spacing(0, 3, 0, -4)};
`;

export const StartButton = styled(Button)`
    margin-bottom: ${({ theme }) => theme.spacing(-5.75)} !important;
    padding-right: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.up('md')} {
        margin-bottom: ${({ theme }) => theme.spacing(-7.5)} !important;
        margin-top: ${({ theme }) => theme.spacing()} !important;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        margin-left: ${({ theme }) => theme.spacing(spacingLg)} !important;
    }
`;
