import * as actionTypes from './_actionTypes';
import {
    ChangeLanguageAction,
    SetScrollPosActionHomePage,
    SetScrollPosActionPremiumBrowsePage,
    SetScrollPosActionProHomePage,
    SetScrollPosActionProBrowsePage,
    ChangeDeviceAction,
    SetScrollPosActionFavouritesPage,
    SetScrollPosActionFavouriteQuestionsPage,
    SetScrollPosActionCollaborationsPage,
    SetIsCreatingCompany,
} from './_types';
import { Language } from '_types/language';
import { ScrollPosition } from '_types/scoll-position';
import { UserAgent } from '_types/user-agent';

export const detectDevice = (agent: UserAgent): ChangeDeviceAction => ({
    type: actionTypes.SET_DEVICE_TYPE,
    payload: agent,
});

export const updateLanguage = (language: Language): ChangeLanguageAction => ({
    type: actionTypes.CHANGE_LANGUAGE,
    payload: language,
});

export const setScrollPosHomePage = (
    position: ScrollPosition
): SetScrollPosActionHomePage => ({
    type: actionTypes.SET_SCROLL_POS_HOME_PAGE,
    payload: position,
});
export const setScrollPosPremiumBrowsePage = (
    position: ScrollPosition
): SetScrollPosActionPremiumBrowsePage => ({
    type: actionTypes.SET_SCROLL_POS_PREMIUM_BROWSE_PAGE,
    payload: position,
});
export const setScrollPosProHomePage = (
    position: ScrollPosition
): SetScrollPosActionProHomePage => ({
    type: actionTypes.SET_SCROLL_POS_PRO_HOME_PAGE,
    payload: position,
});
export const setScrollPosProBrowsePage = (
    position: ScrollPosition
): SetScrollPosActionProBrowsePage => ({
    type: actionTypes.SET_SCROLL_POS_PRO_BROWSE_PAGE,
    payload: position,
});

export const setScrollPosFavouriteConversationsPage = (
    position: ScrollPosition
): SetScrollPosActionFavouritesPage => ({
    type: actionTypes.SET_SCROLL_POS_FAVOURITES_PAGE,
    payload: position,
});
export const setScrollPosMyLibraryPage = (
    position: ScrollPosition
): SetScrollPosActionFavouritesPage => ({
    type: actionTypes.SET_SCROLL_POS_FAVOURITES_PAGE,
    payload: position,
});
export const setScrollPosFavouriteQuestionsPage = (
    position: ScrollPosition
): SetScrollPosActionFavouriteQuestionsPage => ({
    type: actionTypes.SET_SCROLL_POS_FAVOURITE_QUESTIONS_PAGE,
    payload: position,
});
export const setScrollPosCollaborationsPage = (
    position: ScrollPosition
): SetScrollPosActionCollaborationsPage => ({
    type: actionTypes.SET_SCROLL_POS_MY_COLLABORATIONS_PAGE,
    payload: position,
});
export const setIsCreatingCompany = (
    isCreatingCompany: boolean
): SetIsCreatingCompany => ({
    type: actionTypes.SET_IS_CREATING_COMPANY,
    payload: isCreatingCompany,
});
