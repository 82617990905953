import React, {
    FC,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { IS_MOBILE_APP } from '../../constants';
import { routeObj } from 'pages/pro-switch-confirmation-page/pro-switch-confirmation-page';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { returnRoutesForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';

import HistoryRouterContext, { HistoryRouterContextValues } from './context';
import { createBrowserHistory } from 'history';
import { Language } from '_types/language';
import qs from 'query-string';
import { User } from '_types';
import i18n from 'i18n';

type HistoryRouterProviderProps = {
    children: ReactNode;
};

const history = createBrowserHistory();

const HistoryRouterProvider: FC<HistoryRouterProviderProps> = ({
    children,
}) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    const [redirectToPayment, setRedirectToPayment] = useState(false);

    const language: Language = useSelector(languageSelector);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const routes = useMemo(
        () => returnRoutesForLanguage(language, routeObj),
        [language, routeObj]
    );

    useLayoutEffect(
        () =>
            history.listen((listener) => {
                setState(listener);

                gtmEvent(GTM_EVENT.PAGEVIEW, {
                    location: listener.location,
                    pageTitle: document.title.trim(),
                });

                //native analytics
                if (IS_MOBILE_APP) {
                    const pathName1 = listener.location.pathname
                        .trim()
                        .replace(/\//, '');
                    const pathName2 = pathName1
                        .replace(/\//g, '_')
                        .replace(/-/g, '_')
                        .substring(0, 40);
                    FirebaseAnalytics.logEvent({
                        name: pathName2,
                        params: { pathName2 },
                    });
                }
            }),
        [history]
    );

    const redirectOnLogin = (user: User) => {
        const nextLocation = qs.stringifyUrl({
            url: routes.paymentOptionSelect,
            query: { productId: user.app_storage },
        });

        history.push(nextLocation);
        setRedirectToPayment(false);
    };

    const conditionallyRedirectOnLogin = (user: User) =>
        redirectToPayment && redirectOnLogin(user);

    const redirectOnPayment = (user: User) => {
        const nextLocation = qs.stringifyUrl({
            url: routes.paymentOptionSelect,
            query: { productId: user.app_storage },
        });
        history.push(nextLocation);
    };

    const getJoinCollaborationUrl = (
        hideProtocol?: boolean,
        collaborationCode?: string
    ) => {
        const url =
            window.location.origin === 'capacitor://localhost'
                ? [
                      'https://app.fuelbox.no',
                      routes.joinCollaboration,
                      ...(collaborationCode ? [collaborationCode] : []),
                  ].join('/')
                : [
                      window.location.origin,
                      routes.joinCollaboration,
                      ...(collaborationCode ? [collaborationCode] : []),
                  ].join('/');

        return hideProtocol
            ? url.replace(new RegExp(/https?:\/\//, 'g'), '')
            : url;
    };

    const values: HistoryRouterContextValues = {
        conditionallyRedirectOnLogin,
        getJoinCollaborationUrl,
        language,
        redirectOnLogin,
        redirectOnPayment,
        redirectToPayment,
        routes,
        setRedirectToPayment,
    };

    return (
        <HistoryRouterContext.Provider value={values}>
            <Router
                location={state.location}
                navigationType={state.action}
                navigator={history}
            >
                {children}
            </Router>
        </HistoryRouterContext.Provider>
    );
};

export default HistoryRouterProvider;
