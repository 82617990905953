import React from 'react';
import { Link } from 'components/link';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Subscription } from '_types/subscription';
import { formatDate } from 'utils/date-utils';
import styles from './active-sub-page.module.scss';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { userSelector } from 'store-modules/user';
import { proAccountSelector } from 'store-modules/professional';
import { ProSub } from 'professional/components/pro-sub';
import classNames from 'classnames';
import { ProOwner } from 'professional/components/pro-owner';

interface Props {
    sub: Subscription;
}
interface ViewCopy {
    linkEndSubscription: string;
    textNextPayment: string;
    addProCodeLabel: string;
}

const nbCopy: ViewCopy = {
    linkEndSubscription: 'Avslutt abonnement',
    textNextPayment: 'Neste betaling er',
    addProCodeLabel: 'Legg til bedriftskode',
};

const enCopy: ViewCopy = {
    linkEndSubscription: 'Cancel subscription',
    textNextPayment: 'Next payment is due',
    addProCodeLabel: 'Add a company code',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ActiveSubPage: React.FC<Props> = ({ sub }: Props) => {
    const language: Language = useSelector(languageSelector);
    const user = useSelector(userSelector);
    const organization = useSelector(proAccountSelector);
    const { linkEndSubscription, textNextPayment, addProCodeLabel } =
        returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    const proSubActive =
        !!organization && organization.name === user?.organization;

    const isProOwner = user?.isCompanyAdmin;

    const { productName, next_payment_date } = sub.data.subscription;

    const nextPaymentDate = formatDate(next_payment_date);

    const premiumSubInfo = !user?.organization && !isProOwner && (
        <div>
            <h2 className={styles.productName}>{productName}</h2>
            <p>
                {textNextPayment} {nextPaymentDate}
            </p>
        </div>
    );

    const proContent = !!proSubActive && <ProSub />;
    const proOwnerContent = !!user?.isCompanyAdmin && <ProOwner />;

    const cancelLink = !proSubActive && !isProOwner && (
        <Link
            to={routes.endSubscription}
            className={styles.link}
            block={true}
            highlight={true}
        >
            {linkEndSubscription}
        </Link>
    );

    const addProCodeLink = !user?.organization && !isProOwner && (
        <Link
            to={routes.proCodeSubPage}
            className={classNames(styles.link, styles.addProCodeLink)}
            block={true}
            highlight={true}
        >
            {addProCodeLabel}
        </Link>
    );

    return (
        <section className={styles.container} aria-label='subscription-details'>
            {premiumSubInfo}
            {isProOwner ? proOwnerContent : proContent}
            {cancelLink}
            {addProCodeLink}
        </section>
    );
};

export default ActiveSubPage;
