import classNames from 'classnames';
import React from 'react';
import styles from './option.module.scss';

interface Props {
    onClick: () => void;
    title: string;
    subTitle?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any; // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iconClassName?: any;
}

const Option: React.FC<Props> = ({
    onClick,
    title,
    subTitle,
    icon,
    iconClassName,
}: Props) => (
    <div
        role='button'
        tabIndex={0}
        className={styles.OptionContainer}
        onKeyDown={() => {
            onClick();
        }}
        onClick={() => {
            onClick();
        }}
    >
        <div className={classNames(styles.IconContainer, iconClassName)}>
            {icon}
        </div>
        <div className={styles.TextContainer}>
            <div className={styles.Title}>{title}</div>

            {subTitle && <div className={styles.SubTitle}>{subTitle}</div>}
        </div>
    </div>
);

export default Option;
