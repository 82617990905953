import { RouteList, routesEN, routesNO } from 'api/routes';
import userClient from 'api/user-client';
import { Button } from 'components/base';
import LanguageOption from 'components/language-option/language-option';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    returnCopyForLanguage,
    setLocalStorageLanguage,
} from 'services/language-service';
import { languageSelector, updateLanguage } from 'store-modules/app-settings';
import { Language } from '_types/language';
import SettingsSubpage from '../settings-subpage/settings-subpage';
import { setToastMessage } from 'store-modules/user';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from 'components/base';
import { setFavouriteConversations } from 'store-modules/favourite-conversations';
import conversationClient from 'api/conversation-client';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';

const norwegian: Language = 'nb';
const english: Language = 'en';

interface ViewCopy {
    pageTitle: string;
    title: string;
    norwegianLabel: string;
    englishLabel: string;
    buttonLabel: string;
    savedPasswordText: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Språk',
    title: 'Språk',
    norwegianLabel: 'Norsk',
    englishLabel: 'Engelsk',
    buttonLabel: 'Lagre endringer',
    savedPasswordText: 'Ditt språkvalg er nå lagret',
};
const enCopy: ViewCopy = {
    pageTitle: '',
    title: 'Language',
    norwegianLabel: 'Norwegian',
    englishLabel: 'English',
    buttonLabel: 'Save changes',
    savedPasswordText: 'Your language setting has been saved',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const LanguagePage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { pageTitle, title, norwegianLabel, englishLabel, buttonLabel } =
        returnCopyForLanguage(language, copies);
    const dispatch = useDispatch();

    const [selectedLanguage, setLanguage] = useState<Language>(language);

    const selectLanguage = (language: Language): void => {
        dispatch(updateLanguage(language));
        setLocalStorageLanguage(language);
        userClient.setUserLanguage(language);

        //Update favourites with correct language then navigate account
        Promise.all([
            conversationClient.getMyFavouriteConversations(),
            conversationClient.getMyFavouriteQuestions(),
        ]).then((response) => {
            dispatch(setFavouriteConversations(response[0].reverse()));
            dispatch(setFavouriteQuestions(response[1].reverse()));
            dispatch(
                setToastMessage(
                    returnCopyForLanguage(language, copies).savedPasswordText
                )
            );
            const selectedLanguageRouteObj = routeObj.find(
                (r) => r.name === language
            );
            if (!selectedLanguageRouteObj) return;
            const selectedLanguageRoute =
                selectedLanguageRouteObj.routes.account.index;
            navigate(selectedLanguageRoute);
        });
    };

    return (
        <SettingsSubpage pageTitle={pageTitle} title={title}>
            <Stack spacing={5} direction='column'>
                <Box>
                    <LanguageOption
                        language={norwegian}
                        languageLabel={norwegianLabel}
                        selectedLanguage={selectedLanguage}
                        selectLanguage={setLanguage}
                    />

                    <LanguageOption
                        language={english}
                        languageLabel={englishLabel}
                        selectedLanguage={selectedLanguage}
                        selectLanguage={setLanguage}
                    />
                </Box>

                <Button
                    appearance='cta'
                    onClick={() => selectLanguage(selectedLanguage)}
                >
                    {buttonLabel}
                </Button>
            </Stack>
        </SettingsSubpage>
    );
};

export default LanguagePage;
