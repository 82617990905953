import 'cordova-plugin-purchase';

export class PurchaseService {

    store?: CdvPurchase.Store;

    constructor() {
        this.store = CdvPurchase.store
        /*this.platform.ready().then(() => {

            // MUST WAIT for Cordova to initialize before referencing CdvPurchase namespace
            this.store = CdvPurchase.store
        });
        
         */
    }
}