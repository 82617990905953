import { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import { useCollectionData } from './use-collection-data';

const useUpdatedData = <T>(
    collectionRef:
        | firebase.firestore.CollectionReference<T>
        | firebase.firestore.Query<T>,
    disabled?: boolean
) => {
    const updatedData = useCollectionData(collectionRef, disabled);
    const getUpdatedData = () => updatedData;
    return getUpdatedData;
};

const shouldUpdate = <T>(data?: T[], updatedData?: T[]) =>
    (!data && updatedData) ||
    (data && updatedData && updatedData.length > data.length);

export const useIntervalCollectionData = <T>(
    collectionRef:
        | firebase.firestore.CollectionReference<T>
        | firebase.firestore.Query<T>,
    disabled?: boolean,
    timeout?: number
): T[] | undefined => {
    const [data, setData] = useState<T[]>();
    const callbackRef = useRef<() => void>();
    const getData = useUpdatedData(collectionRef, disabled);

    const callbackFn = () => {
        if (!disabled) {
            const updatedData = getData();
            shouldUpdate(data, updatedData) && setData(updatedData);
        }
    };

    useEffect(() => {
        callbackRef.current = callbackFn;
    });

    useEffect(() => {
        callbackFn();
        const tick = () => callbackRef.current && callbackRef.current();
        const timer = setInterval(tick, timeout ?? 1000);
        return () => clearInterval(timer);
    }, []);

    return data;
};
