import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useCollection as useFirebaseHooksCollection } from 'react-firebase-hooks/firestore';
import { QuerySnapshot } from 'firebase/firestore';

export const useCollection = <T>(
    collectionRef:
        | firebase.firestore.CollectionReference<T>
        | firebase.firestore.Query<T>,
    disabled?: boolean
): QuerySnapshot<T> | undefined => {
    const [data, setData] = useState<QuerySnapshot<T>>();

    const [collectedData] = useFirebaseHooksCollection<T>(
        !disabled ? (collectionRef as never) : undefined
    );

    useEffect(() => {
        if (!disabled) setData(collectedData);
    }, [collectedData]);

    return data;
};
