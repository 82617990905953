import { User } from '_types/user';
import { Subscription } from '_types/subscription';
import { Store } from 'store';
import { userAuthenticated, userUnauthenticated } from 'store-modules/auth';

export interface AuthData {
    token: string | null;
    user: User | null;
    subscription: Subscription | null;
}

export const TOKEN = 'token';

class AuthService {
    private passwordPattern = /^(?=.*?[0-9])(?=.*?[a-zA-z]).{8,}$/;
    private emailPattern = /^[^\s@]+@[^\s@]+$/;

    public login = (token: string): void => {
        localStorage.setItem(TOKEN, token);
        Store.dispatch(userAuthenticated(token));
    };

    public logout = (): void => {
        localStorage.removeItem(TOKEN);
        Store.dispatch(userUnauthenticated());
    };

    public validatePasswordFormat = (password: string | undefined): boolean => {
        if (!password) return false;

        return this.passwordPattern.test(password);
    };

    public validateEmailFormat = (email: string | undefined): boolean =>
        !!email && this.emailPattern.test(email);
}

export default new AuthService();
