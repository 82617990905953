import { Button, Icon } from 'components/base';
import { InputPasswordRow, InputRow } from 'components/inputs';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import authService from 'services/auth-service';
import styles from './company-signup-form-user.module.scss';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { GoogleLoginButton } from 'components/google-login-button';
import { useEffect, useState } from 'react';
import { Link } from 'components/link';
import { FormError } from 'components/forms';
import { User } from '_types';
import {
    CompanySignupFormValues,
    UserRegistrationLinkData,
    userSelector,
} from 'store-modules/user';
import { LoginStatus, logUserIn } from 'common-logic/login';
import { setProAccount } from 'store-modules/professional';
import { useNavigate } from 'react-router-dom';
import userClient from 'api/user-client';
import RotatingSpinner from 'components/rotating-spinner/rotating-spinner';
import { useWindowSize } from 'hooks';
import { getCustomHash } from 'utils/tools';
import { EmailRegistrationLinkError } from 'api/user-client';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';

const requiredValidator = (value: string | undefined | null): boolean =>
    !!value && value.length > 0;
const emailValidator = (value: string): boolean =>
    authService.validateEmailFormat(value);
const passwordValidator = (value: string): boolean =>
    authService.validatePasswordFormat(value);

type Props = {
    nextHandler: (input: CompanySignupFormValues) => void;
    editHandler: () => void;
    activeContainer: number;
    formValues: CompanySignupFormValues | undefined;
    setActiveContainer: (activeContainer: number) => void;
    onRegistrationLinkGenerated: (data: UserRegistrationLinkData) => void;
    emailValidationExpected?: boolean;
    verifiedUser?: boolean;
    toastMessage?: string;
};
interface ViewCopy {
    title: string;
    alreadyHaveAccount: string;
    login: string;
    labelEmail: string;
    labelPassword: string;
    labelFirstName: string;
    labelLastName: string;
    hintPassword: string;
    terms: string;
    linkTerms: string;
    linkPrivacy: string;
    labelSubmit: string;
    errorRequired: string;
    errorEmail: string;
    contactPersonLabel: string;
    usernameTakenError: string;
    and: string;
    googleRegisterlabel: string;
    linkPasswordReset: string;
    missingFieldsError: string;
    emailLoginEmailErrorLabel: string;
    emailLoginPasswordErrorLabel: string;
    changeLabel: string;
    loggedInAsLabel: string;
    logInWithDifferentAccountLabel: string;
    connectToOtherAccountLabel: string;
    createNewUserLabel: string;
    nextLabel: string;
    noUserLabel: string;
    createUserBannerText1: string;
    createUserBannerText2: string;
    googleLoginLabel: string;
    emailLoginLabel: string;
}

const nbCopy: ViewCopy = {
    title: 'Kontaktperson',
    alreadyHaveAccount: 'Har du allerede bruker?',
    login: 'Logg inn',
    labelEmail: 'Epostadresse',
    labelPassword: 'Passord',
    labelFirstName: 'Fornavn',
    labelLastName: 'Etternavn',
    hintPassword: 'Bruk både tall og bokstaver, minimum 8 tegn',
    terms: 'Ved å trykke på knappen og opprette bruker godkjenner du',
    linkTerms: 'vilkår for bruk av tjenesten',
    linkPrivacy: 'personvernerklæring',
    labelSubmit: 'Neste',
    errorRequired: 'Obligatorisk felt',
    contactPersonLabel: 'Kontaktperson',
    errorEmail: 'Dette ser ikke ut som en fullstendig epostadresse',
    usernameTakenError: 'Denne epostadressen finnes allerede',
    and: 'og',
    googleRegisterlabel: 'Registrer deg med Google',
    linkPasswordReset: 'Glemt passordet ditt?',
    missingFieldsError: 'Har du husket å fylle ut begge feltene?',
    emailLoginEmailErrorLabel: 'Vi kjenner ikke igjen denne epostadressen',
    emailLoginPasswordErrorLabel: 'Feil passord',
    loggedInAsLabel: 'Logget inn som:',
    changeLabel: 'Endre',
    logInWithDifferentAccountLabel: 'Logg inn med en annen konto',
    noUserLabel: 'Har du ikke bruker?',
    connectToOtherAccountLabel:
        'Vil du koble abonnementet til en annen epostadresse?',
    createNewUserLabel: 'Opprett ny bruker',
    emailLoginLabel: 'Logg inn med epost',
    createUserBannerText1: 'Hvem skal administrere abonnementet?',
    createUserBannerText2: 'Opprett ny bruker eller logg inn',
    googleLoginLabel: 'Logg inn med Google',
    nextLabel: 'Neste',
};

const enCopy: ViewCopy = {
    title: 'Create account',
    alreadyHaveAccount: 'Already have an account?',
    login: 'Log in',
    labelEmail: 'Email address',
    labelPassword: 'Password',
    labelFirstName: 'First name',
    contactPersonLabel: 'Contact person',
    labelLastName: 'Last name',
    hintPassword: 'Use numbers and letters, minimum 8 characters',
    terms: 'By clicking the button and creating an account you also accept our',
    linkTerms: 'terms of use',
    linkPrivacy: 'privacy policy',
    labelSubmit: 'Next',
    errorRequired: 'Required field',
    errorEmail: "This doesn't look like a complete email address",
    usernameTakenError: 'This email address is already registered.',
    noUserLabel: 'Har du ikke bruker?',
    and: 'and',
    googleRegisterlabel: 'Registrer using Google',
    linkPasswordReset: 'Forgotten your password?',

    missingFieldsError: 'Have you entered email and password?',
    emailLoginEmailErrorLabel: "We don't recognise this email address",
    emailLoginPasswordErrorLabel: 'Wrong password',
    changeLabel: 'Edit',
    logInWithDifferentAccountLabel: 'Log in with a different account',
    loggedInAsLabel: 'Logged in as:',
    googleLoginLabel: 'Log in with Google',
    connectToOtherAccountLabel:
        'Do you want to connect the subscription to a differen email address?',
    createNewUserLabel: 'Create new account',
    emailLoginLabel: 'Log in with email',
    nextLabel: 'Next',
    createUserBannerText1: 'Who will administer the subscription?',
    createUserBannerText2: 'Create new account or log in',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface LoginForm {
    emailLoginUsername: string;
    emailLoginPassword: string;
}

const isEmpty = (value: string | undefined | null): boolean => !value;

const CompanySignupFormUser: React.FC<Props> = ({
    nextHandler,
    editHandler,
    activeContainer,
    formValues,
    setActiveContainer,
    onRegistrationLinkGenerated,
    emailValidationExpected,
    verifiedUser,
    toastMessage,
}) => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        alreadyHaveAccount,
        login,
        labelEmail,
        labelPassword,
        labelFirstName,
        labelLastName,
        hintPassword,
        nextLabel,
        terms,
        connectToOtherAccountLabel,
        linkTerms,
        linkPrivacy,
        googleLoginLabel,
        labelSubmit,
        errorRequired,
        errorEmail,
        usernameTakenError,
        createUserBannerText1,
        createUserBannerText2,
        noUserLabel,
        createNewUserLabel,
        and,
        googleRegisterlabel,
        changeLabel,
        loggedInAsLabel,
        emailLoginLabel,
        linkPasswordReset,
        contactPersonLabel,
        logInWithDifferentAccountLabel,
        emailLoginEmailErrorLabel,
        emailLoginPasswordErrorLabel,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const user = useSelector(userSelector);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(
        activeContainer !== 1
    );
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(true);
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    const [emailLogin, setEmailLogin] = useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = useState<User | null>(user);
    const [isLoggingUserIn, setIsLoggingUserIn] = useState(false);
    const [userHasLoggedIn, setUserHasLoggedIn] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(function checkIfEmailVerificationOnMount() {
        if (verifiedUser === true) {
            setIsLoggingIn(false);
        }
    }, []);

    useEffect(() => {
        setIsCollapsed(activeContainer !== 1);
    }, [activeContainer]);

    const emailError = {
        emailLoginUsername: emailLoginEmailErrorLabel,
    };

    const passwordError = {
        emailLoginPassword: emailLoginPasswordErrorLabel,
    };

    const afterLogin = async () => {
        setIsLoggingUserIn(false);
        setUserHasLoggedIn(true);

        const user = await userClient.getCurrentUser();
        setLoggedInUser(user);
        setIsLoggingIn(false);
        setEmailLogin(false);
    };

    const loginWithEmail = async (values: LoginForm) => {
        setIsLoggingUserIn(true);
        const isEmptyUsername = isEmpty(values.emailLoginUsername);
        const isEmptyPassword = isEmpty(values.emailLoginPassword);

        if (isEmptyUsername) {
            setIsLoggingUserIn(false);
            return {
                emailLoginUsername: errorRequired,
            };
        }
        if (isEmptyPassword) {
            setIsLoggingUserIn(false);
            return {
                emailLoginPassword: errorRequired,
            };
        }

        try {
            const loginResult = await logUserIn(
                values.emailLoginUsername,
                values.emailLoginPassword,
                (organization) => dispatch(setProAccount(organization)),
                true
            );

            switch (loginResult.loginStatus) {
                case LoginStatus.InvalidUsername:
                    setIsLoggingUserIn(false);
                    return emailError;
                case LoginStatus.InvalidPassword:
                    setIsLoggingUserIn(false);
                    return passwordError;
                case LoginStatus.Error:
                    setIsLoggingUserIn(false);
                    navigate(routes.error);
                    return;
            }
            afterLogin();
        } catch {
            setIsLoggingUserIn(false);
            navigate(routes.error);
        }
    };
    const capitalizeFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const afterGoogleLogin = () => {
        afterLogin();
    };

    const sendEmailRegistrationLink = async (
        email: string
    ): Promise<boolean> => {
        setSubmittingForm(true);
        const key = getCustomHash(32);
        try {
            await userClient.sendEmailRegistrationLink(email, key);
        } catch (error) {
            const linkError = error as EmailRegistrationLinkError;
            if (linkError?.data?.status && linkError.data?.status === 404) {
                return false;
            }
        } finally {
            setSubmittingForm(false);
        }
        return true;
    };

    const onSubmitForm = async (values: CompanySignupFormValues) => {
        const emailError =
            (!values.userEmail && errorRequired) ||
            (!requiredValidator(values.userEmail) && errorRequired) ||
            (values.userEmail &&
                !emailValidator(values.userEmail) &&
                errorEmail);
        const firstNameError =
            !requiredValidator(values.userFirstName) && errorRequired;
        const lastNameError =
            !requiredValidator(values.userLastName) && errorRequired;
        const passwordError =
            (verifiedUser && !values.userPassword && hintPassword) ||
            (values.userPassword &&
                !passwordValidator(values.userPassword) &&
                hintPassword);

        if (emailError || firstNameError || lastNameError || passwordError) {
            return {
                userEmail: emailError,
                userFirstName: firstNameError,
                userLastName: lastNameError,
                userPassword: passwordError,
            };
        }

        if (loggedInUser) {
            values.owner = loggedInUser.id;
        } else {
            if (!verifiedUser) {
                const result = await sendEmailRegistrationLink(
                    values.userEmail!
                );
                if (!result) {
                    return {
                        userEmail: usernameTakenError,
                    };
                } else {
                    onRegistrationLinkGenerated({
                        email: values.userEmail!,
                        firstName: values.userFirstName!,
                        lastName: values.userLastName!,
                        createdAt: new Date().toUTCString(),
                    });
                }
                return;
            }
        }

        try {
            nextHandler(values);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            if (err.code === 406) {
                return {
                    email: usernameTakenError,
                };
            } else {
                console.error(err);
                navigate(routes.error);
            }
        }
    };

    const MyForm = (
        <Form
            onSubmit={onSubmitForm}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={styles.CreateUserForm}
                >
                    <p className={styles.CreateNewUserHeader}>
                        {createNewUserLabel}
                    </p>
                    <div className={styles.EmailInputContainer}>
                        <Field
                            name='userEmail'
                            label={labelEmail}
                            type='email'
                            component={InputRow}
                            defaultValue={formValues?.userEmail}
                            disabled={verifiedUser === true}
                        />
                    </div>
                    <div className={styles.NameContainer}>
                        <Field
                            name='userFirstName'
                            label={labelFirstName}
                            type='text'
                            component={InputRow}
                            defaultValue={formValues?.userFirstName}
                        />
                        <Field
                            name='userLastName'
                            label={labelLastName}
                            type='text'
                            component={InputRow}
                            defaultValue={formValues?.userLastName}
                        />
                    </div>
                    {verifiedUser && (
                        <div className={styles.PasswordInputContainer}>
                            <Field
                                name='userPassword'
                                label={labelPassword}
                                language={language}
                                component={InputPasswordRow}
                                className={styles.inputField}
                                defaultValue={formValues?.userPassword}
                            />
                        </div>
                    )}

                    <div className={styles.Form__Info}>
                        <p className={styles.Form__Terms}>
                            {terms}
                            {isDesktop ? <br /> : ' '}
                            <button type='button' className={styles.ButtonLink}>
                                {linkTerms}
                            </button>
                            &nbsp; {and} &nbsp;
                            <button type='button' className={styles.ButtonLink}>
                                {linkPrivacy}
                            </button>
                        </p>
                    </div>

                    {!isDesktop && (
                        <p className={styles.HasAccount}>
                            {alreadyHaveAccount}&nbsp;
                            <button
                                type='button'
                                className={styles.ButtonLink}
                                onClick={() => {
                                    setIsLoggingIn(true);
                                }}
                            >
                                {login}
                            </button>
                        </p>
                    )}
                    <Button
                        className={styles.createAccountButton}
                        appearance={'cta'}
                        type='submit'
                        loading={submittingForm}
                        disabled={submittingForm || emailValidationExpected}
                    >
                        {labelSubmit}
                    </Button>
                    {toastMessage && (
                        <ToastMessage
                            icon={<IconCheck />}
                            message={toastMessage}
                            className={styles.Toast}
                        />
                    )}
                </form>
            )}
        />
    );

    const collapsedContainer = (
        <div className={styles.CollapsedContainer}>
            <div className={styles.LeftContainer}>
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{1}</div>
                    {!isDesktop && <PageTitle title={contactPersonLabel} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={contactPersonLabel} />}
                    <div className={styles.UserInfoContainer}>
                        <p className={styles.Name}>
                            {loggedInUser
                                ? capitalizeFirstLetter(loggedInUser.firstName)
                                : !!formValues && formValues.userFirstName
                                ? capitalizeFirstLetter(
                                      formValues.userFirstName
                                  )
                                : ''}
                            &nbsp;
                            {loggedInUser
                                ? capitalizeFirstLetter(loggedInUser.lastName)
                                : !!formValues && formValues.userLastName
                                ? capitalizeFirstLetter(
                                      formValues?.userLastName
                                  )
                                : ''}
                        </p>
                        <p className={styles.Email}>
                            {loggedInUser
                                ? loggedInUser.email
                                : formValues?.userEmail}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.RightContainer}>
                <Link
                    className={styles.ButtonLink}
                    onClick={() => editHandler()}
                    to={''}
                >
                    {changeLabel}
                </Link>
            </div>
        </div>
    );

    const expandedCreateContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                {' '}
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{1}</div>
                    {!isDesktop && <PageTitle title={title} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={title} />}
                    <div className={styles.LoginContainer}>
                        <div className={styles.GoogleRegisterButton}>
                            <GoogleLoginButton
                                fullWidth
                                text={googleRegisterlabel}
                                routes={routes}
                                setLoading={() => {
                                    console.log('loading');
                                }}
                                createUser={false}
                                tokenOnly={true}
                                afterOnlyLogin={afterGoogleLogin}
                            />
                        </div>
                        {isDesktop && (
                            <p className={styles.HasAccount}>
                                {alreadyHaveAccount}&nbsp;
                                <button
                                    type='button'
                                    className={styles.ButtonLink}
                                    onClick={() => {
                                        setIsLoggingIn(true);
                                    }}
                                >
                                    {login}
                                </button>
                            </p>
                        )}
                    </div>
                    {MyForm}
                </div>
            </div>
        </div>
    );

    const emailLoggedInContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                {' '}
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{1}</div>
                </div>
                <div className={styles.CreateLoginContainer}>
                    {isDesktop && <PageTitle title={title} />}

                    <p className={styles.LoggedInAsTitle}>{loggedInAsLabel}</p>
                    <div className={styles.LoggedInUserInfoContainer}>
                        <div className={styles.LoggedInUserLogo}>
                            {' '}
                            <Icon fontSize='small' name='person_outlined' />
                        </div>
                        <div className={styles.LoggedInUserNameEmail}>
                            <div className={styles.name}>
                                {loggedInUser?.firstName &&
                                    capitalizeFirstLetter(
                                        loggedInUser.firstName
                                    )}
                                &nbsp;
                                {loggedInUser?.lastName &&
                                    capitalizeFirstLetter(
                                        loggedInUser.lastName
                                    )}
                            </div>
                            <div className={styles.email}>
                                {' '}
                                {loggedInUser?.email}
                            </div>
                        </div>
                    </div>
                    <div className={styles.LoggedInUserLowerContainer}>
                        <p className={styles.LogInWithOtherAccountLink}>
                            <button
                                type='button'
                                className={styles.ButtonLink}
                                onClick={() => {
                                    setIsLoggingIn(true);
                                    setLoggedInUser(null);
                                    setUserHasLoggedIn(false);
                                }}
                            >
                                {logInWithDifferentAccountLabel}
                            </button>
                        </p>
                        <p className={styles.ConnectToOtherAccountLink}>
                            {connectToOtherAccountLabel}
                            &nbsp;
                            <button
                                type='button'
                                className={styles.ButtonLink}
                                onClick={() => {
                                    setLoggedInUser(null);
                                    setUserHasLoggedIn(false);
                                    setIsLoggingIn(false);
                                    setEmailLogin(false);
                                }}
                            >
                                {createNewUserLabel}
                            </button>
                        </p>
                        <Button
                            appearance='cta'
                            fullWidth={false}
                            onClick={() => setActiveContainer(2)}
                        >
                            {nextLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    const emailLoginContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                {' '}
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{1}</div>
                    {!isDesktop && <PageTitle title={title} />}
                </div>
                <div className={styles.CreateLoginContainer}>
                    {isDesktop && <PageTitle title={title} />}

                    <div className={styles.EmailLoginContainer}>
                        <Form
                            onSubmit={loginWithEmail}
                            render={({ handleSubmit, submitError }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className={styles.form}
                                >
                                    <Field
                                        name='emailLoginUsername'
                                        label={labelEmail}
                                        type='text'
                                        component={InputRow}
                                    />
                                    <Field
                                        name='emailLoginPassword'
                                        label={labelPassword}
                                        type='password'
                                        component={InputRow}
                                    />

                                    <p className={styles.reset}>
                                        <Link to={routes.passReset}>
                                            {linkPasswordReset}
                                        </Link>
                                    </p>

                                    {!isDesktop && (
                                        <p className={styles.NoUserText}>
                                            {noUserLabel}
                                            &nbsp;
                                            <button
                                                type='button'
                                                className={styles.ButtonLink}
                                                onClick={() => {
                                                    setIsLoggingIn(false);
                                                    setEmailLogin(false);
                                                }}
                                            >
                                                {createNewUserLabel}
                                            </button>
                                        </p>
                                    )}

                                    <FormError message={submitError} />
                                    <div className={styles.BottomContainer}>
                                        <div className={styles.Button}>
                                            <Button
                                                type='submit'
                                                appearance='cta'
                                                fullWidth={true}
                                            >
                                                {isLoggingUserIn ? (
                                                    <RotatingSpinner color='black' />
                                                ) : userHasLoggedIn ? (
                                                    <Icon name={'check'} />
                                                ) : (
                                                    login
                                                )}
                                            </Button>
                                        </div>
                                        {isDesktop && (
                                            <p className={styles.NoUserText}>
                                                {noUserLabel}
                                                &nbsp;
                                                <button
                                                    type='button'
                                                    className={
                                                        styles.ButtonLink
                                                    }
                                                    onClick={() => {
                                                        setIsLoggingIn(false);
                                                        setEmailLogin(false);
                                                    }}
                                                >
                                                    {createNewUserLabel}
                                                </button>
                                            </p>
                                        )}
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const expandedLoginContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                {' '}
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{1}</div>
                </div>
                <div className={styles.CreateLoginContainer}>
                    <PageTitle title={title} />
                    <div className={styles.CreateUserBanner}>
                        <p className={styles.CreatUserBannerText1}>
                            {createUserBannerText1}
                        </p>
                        <p className={styles.CreatUserBannerText2}>
                            {createUserBannerText2}
                        </p>
                        <Button
                            className={styles.CreateUserButton}
                            appearance='cta'
                            onClick={() => setIsLoggingIn(false)}
                        >
                            {' '}
                            {createNewUserLabel}
                        </Button>
                    </div>
                    <div className={styles.LoginContainer}>
                        <div className={styles.GoogleRegisterButton}>
                            <GoogleLoginButton
                                fullWidth
                                text={googleLoginLabel}
                                routes={routes}
                                setLoading={() => {
                                    console.log('loading');
                                }}
                                createUser={true}
                                tokenOnly={true}
                                afterOnlyLogin={afterGoogleLogin}
                            />
                        </div>

                        <Button
                            className={styles.EmailLoginButton}
                            aria-label={'login-with-email'}
                            leftIcon={'mail_outlined'}
                            onClick={() => setEmailLogin(true)}
                        >
                            {emailLoginLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    const expandedLoginContainerMobile = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                {' '}
                <div className={styles.HeaderContainerMobile}>
                    <div className={styles.LeftLeftContainer}>
                        <div className={styles.Circle}>{1}</div>
                    </div>
                    <PageTitle title={title} />
                </div>
                <div className={styles.CreateLoginContainer}>
                    <div className={styles.CreateUserBanner}>
                        <p className={styles.CreatUserBannerText1}>
                            {createUserBannerText1}
                        </p>
                        <p className={styles.CreatUserBannerText2}>
                            {createUserBannerText2}
                        </p>
                        <Button
                            className={styles.CreateUserButton}
                            appearance='cta'
                            onClick={() => setIsLoggingIn(false)}
                        >
                            {' '}
                            {createNewUserLabel}
                        </Button>
                    </div>
                    <div className={styles.LoginContainer}>
                        <div className={styles.GoogleRegisterButton}>
                            <GoogleLoginButton
                                fullWidth
                                text={googleLoginLabel}
                                routes={routes}
                                setLoading={() => {
                                    console.log('loading');
                                }}
                                createUser={true}
                                tokenOnly={true}
                                afterOnlyLogin={afterGoogleLogin}
                            />
                        </div>

                        <Button
                            className={styles.EmailLoginButton}
                            aria-label={'login-with-email'}
                            leftIcon={'mail_outlined'}
                            onClick={() => setEmailLogin(true)}
                        >
                            {emailLoginLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <article
            data-testid='component-create-account-form'
            className={styles.CompanySignupForm}
        >
            {isCollapsed
                ? collapsedContainer
                : isLoggingIn
                ? emailLogin
                    ? emailLoginContainer
                    : !isDesktop
                    ? expandedLoginContainerMobile
                    : expandedLoginContainer
                : loggedInUser
                ? emailLoggedInContainer
                : expandedCreateContainer}
        </article>
    );
};

export default React.memo(CompanySignupFormUser);
