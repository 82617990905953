/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'components/layout';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { Language } from '_types/language';
import styles from './search-page.module.scss';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import {
    Conversation,
    ConversationWithCategory,
    deckParentPages,
    Question,
} from '_types';
import { Container } from 'components/container';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { ConversationCard, ThemeCard } from 'components/conversation-card';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import Decorator from 'components/conversation-card/decorator';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { ReactComponent as SearchIcon } from 'images/icons/magnifier.svg';
import { SEO } from 'components/seo';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { preventDefault } from 'services/visual-style.service';
import { Loading } from 'components/loading';
import SearchQuestionBox from 'components/search-question-box/search-question-box';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import searchClient from 'api/search-client';
import RotatingSpinner from 'components/rotating-spinner/rotating-spinner';
import conversationClient from 'api/conversation-client';
import classnames from 'classnames';
import { useWindowSize } from 'hooks';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import { IS_IOS_APP } from '../../constants';
import useTypedLocation from 'hooks/use-typed-location';
import useConversationSelector from 'hooks/use-conversation-selector';

interface LocationState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchString: any;
    backPath?: string;
    viewAllSearchConvos?: boolean;
    viewAllSearchQuestions?: boolean;
    conversationSearchResult: ConversationWithCategory[];
    questionSearchResult: Question[];
}

interface ViewCopy {
    seoTitle: string;
    infoText: string;
    conversationsLabel: string;
    questionsLabel: string;
    conversationsLink: string;
    questionsLink: string;
    backButtonText: string;
    allConversationsLabel: string;
    allQuestionsLabel: string;
    searchButtonLabel: string;
    minimumCharactersError: string;
    searchInputPlaceholder: string;
    emptyResultText: string;
    emptyConversationResultText: string;
    emptyQUestionResultText: string;
    conversationOf: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Søk',
    infoText:
        'Finn spørsmål og samtaler ved å søke på hvem du vil snakke med eller hva dere vil snakke om. Du kan også utforske samtaler laget av andre brukere!',
    // infoText:
    //     'Utforsk vårt bibliotek av spørsmål og samtaler ved å søke på hvem du vil snakke med eller hva du vil snakke om!',
    conversationsLabel: 'Samtaler',
    questionsLabel: 'Spørsmål',
    conversationsLink: 'Se alle samtaler med',
    questionsLink: 'Se alle spørsmål med',
    backButtonText: 'Tilbake',
    allConversationsLabel: 'Alle samtaler med',
    allQuestionsLabel: 'Alle spørsmål med',
    searchButtonLabel: 'Søk',
    minimumCharactersError: 'Søket ditt må inneholde minst tre tegn',
    searchInputPlaceholder: '',
    emptyResultText: 'Vi fant desverre ingen resultater på søk',
    emptyConversationResultText: 'Vi fant ingen samtaler med',
    emptyQUestionResultText: 'Vi fant ingen spørsmål med',
    conversationOf: 'Av ',
};

const enCopy: ViewCopy = {
    seoTitle: 'Search',
    infoText:
        'Find questions and conversations by searching for who you want to talk to or what you want to talk about. You can also explore conversations made by other users!',
    // infoText:
    //     'Explore our library of questions and conversations by searching for whom you want to talk to or what you want to talk about!',
    conversationsLabel: 'Conversations',
    questionsLabel: 'Questions',
    conversationsLink: 'View all conversations with',
    questionsLink: 'View all questions with',
    backButtonText: 'Back',
    allConversationsLabel: 'All conversations with',
    allQuestionsLabel: 'Alle questions with',
    searchButtonLabel: 'Search',
    minimumCharactersError:
        'Your search must contain at least three characters',
    searchInputPlaceholder: '',
    emptyResultText: 'We could not find any results for',
    emptyConversationResultText: 'We found no conversations for',
    emptyQUestionResultText: 'We found no questions for',
    conversationOf: 'By ',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const SearchPage: React.FC = () => {
    const location = useTypedLocation<LocationState>();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { isAndroid, isiOS } = getUAInfo(window.navigator);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const {
        conversationsLabel,
        seoTitle,
        infoText,
        questionsLabel,
        conversationsLink,
        questionsLink,
        backButtonText,
        allConversationsLabel,
        allQuestionsLabel,
        searchButtonLabel,
        minimumCharactersError,
        searchInputPlaceholder,
        emptyResultText,
        emptyConversationResultText,
        emptyQUestionResultText,
        conversationOf,
    } = returnCopyForLanguage(language, copies);
    const { selectConversation } = useConversationSelector();

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [conversations, setConversations] = useState<
        ConversationWithCategory[]
    >([]);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
    const [readyToSearch, setReadyToSearch] = useState<boolean>(true);
    const [searchedText, setSearchedText] = useState<string>('');
    const [viewAllConversations, setViewAllConversations] =
        useState<boolean>(false);
    const [viewAllQuestions, setViewAllQuestions] = useState<boolean>(false);
    const [canSearch, setCanSearch] = useState<boolean>(true);
    const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
    const hideHeaderAndSearchField =
        isMobile && (viewAllQuestions || viewAllConversations);
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [isFetchingFavouriteQuestions, setIsFetchingFavouriteQuestions] =
        useState<boolean>(false);

    const dispatch = useDispatch();

    const headerProps = {
        withLogin: true,
        withAccount: true,
        withHeaderSpace: false,
        hideHeaderContainer: hideHeaderAndSearchField,
    };

    const search = async (input: string) => {
        window.scrollTo(0, 0);
        setIsLoadingResults(true);
        setViewAllConversations(false);
        setViewAllQuestions(false);
        if (input.trim().length > 2) {
            setSearchedText(input);

            if (input && input.trim()) {
                setReadyToSearch(false);
            }

            Promise.all([
                searchClient.questionSearch({
                    s: input.trim(),
                }),

                searchClient.conversationSearch({
                    s: input.trim(),
                }),
            ]).then((response) => {
                setQuestions(response[0]);
                setConversations(response[1]);
                setIsLoadingResults(false);
                return;
            });
        } else {
            setCanSearch(false);
            setIsLoadingResults(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        //Get Favourite Questions
        if (!favouriteQuestions && !isFetchingFavouriteQuestions) {
            setIsFetchingFavouriteQuestions(true);
            conversationClient.getMyFavouriteQuestions().then((questions) => {
                dispatch(setFavouriteQuestions(questions));
                setIsFetchingFavouriteQuestions(false);
            });
        }

        //Remove duplicates based on text
        if (!!questions) {
            const uniq = questions.filter(
                (thing, index, self) =>
                    index === self.findIndex((t) => t.text === thing.text)
            );
            setFilteredQuestions(uniq);
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [
        routes,
        conversations.length,
        conversations,
        questions,
        isFetchingFavouriteQuestions,
    ]);

    const isQuestionFavourite = (question: Question) => {
        //Check using slug, as there could be duplicates
        return favouriteQuestions?.map((fq) => fq.slug).includes(question.slug)
            ? true
            : false;
    };

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSearchText(event.target.value);
            setReadyToSearch(true);
            setCanSearch(true);
        },
        []
    );
    //   const onInputBlur = useCallback(
    //     (event: React.ChangeEvent<HTMLInputElement>): void => {
    //       setCanSearch(true);
    //     },
    //     []
    //   );

    if (isLoading) {
        return <Loading />;
    }

    //When a user navigates back to search
    if (location.state?.searchString?.length > 0) {
        // search(props.location.state.searchString);
        setSearchText(location.state.searchString);
        setSearchedText(location.state.searchString);
        location.state.searchString = '';
        setViewAllConversations(location.state.viewAllSearchConvos || false);
        setViewAllQuestions(location.state.viewAllSearchQuestions || false);
        setConversations(location.state.conversationSearchResult);
        setQuestions(location.state.questionSearchResult);
    }

    const hasConversations = conversations?.length > 0;

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = conversations.indexOf(convo);
        const isLeftCard = conversationIndex % 2 === 0;

        classNamesToReturn = classNames(
            styles.ConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.ThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.category &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.category &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.category &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    const decorator = new Decorator();

    const onConversationSelect = (deck: Conversation): void => {
        selectConversation(
            deck,
            language,
            deckParentPages.search,
            searchedText,
            viewAllConversations,
            conversations,
            filteredQuestions
        );
    };
    const boldSearchText = (
        <div className={styles.BoldSearchedText}>{searchedText}</div>
    );

    const allEmptyText = (
        <div>
            {emptyResultText} &laquo;{searchedText}&raquo;
        </div>
    );

    const emptyConversationsText = (
        <div
            className={
                isMobile
                    ? styles.EmptyConversationsTextMobile
                    : styles.EmptyConversationsTextDesktop
            }
        >
            {emptyConversationResultText} &laquo;{searchedText}&raquo;
        </div>
    );
    const emptyQuestionsText = (
        <div
            className={
                isMobile
                    ? styles.EmptyQuestionsTextMobile
                    : styles.EmptyQuestionsTextDesktop
            }
        >
            {emptyQUestionResultText} &laquo;{searchedText}&raquo;
        </div>
    );

    const conversationsToUse = conversations.slice(0, isMobile ? 4 : 9);

    const ConversationsContainer = (
        <section className={styles.ConversationsContainer}>
            {(!isMobile || conversations?.length > 0) && (
                <header className={styles.ConversationsHeader}>
                    <h2
                        id='conversations-row-title'
                        className={styles.ConversationsTitle}
                    >
                        {conversationsLabel}
                    </h2>
                    &nbsp;{'(' + conversations.length + ')'}
                </header>
            )}
            {conversations?.length > 0 && (
                <section
                    aria-label={conversationsLabel}
                    className={styles.ConversationCardsContainer}
                >
                    {conversationsToUse.map((convo) => {
                        if (!hasConversations) {
                            return null;
                        }

                        return isThemeCard(convo.taxonomy) ||
                            isOrganizationCard(convo.taxonomy) ? (
                            <ThemeCard
                                key={convo.id}
                                conversation={convo}
                                className={conversationCardStyle(convo)}
                                onClick={(): void =>
                                    onConversationSelect(convo)
                                }
                            />
                        ) : (
                            <ConversationCard
                                key={convo.id}
                                conversation={convo}
                                className={conversationCardStyle(convo)}
                                onClick={(): void =>
                                    onConversationSelect(convo)
                                }
                                category={
                                    convo.created_by_name
                                        ? {
                                              slug:
                                                  conversationOf +
                                                  convo.created_by_name,
                                              name:
                                                  conversationOf +
                                                  convo.created_by_name,
                                          }
                                        : {
                                              slug: convo.category.slug,
                                              name: convo.category.name,
                                          }
                                }
                                decorator={decorator}
                            />
                        );
                    })}
                </section>
            )}
            {!isMobile && conversations?.length === 0 && emptyConversationsText}
            {conversations?.length > conversationsToUse.length && (
                <button
                    className={styles.ShowAllConversationsLink}
                    onClick={() => {
                        window.scrollTo(0, 0);
                        setViewAllConversations(true);
                    }}
                >
                    {conversationsLink} &laquo;{boldSearchText}&raquo;
                </button>
            )}
        </section>
    );

    const backButton = (
        <section className={styles.BackButtonContainer}>
            <button
                className={styles.BackButton}
                onClick={() => {
                    setViewAllConversations(false);
                    setViewAllQuestions(false);
                }}
                tabIndex={0}
            >
                <ArrowLeft className={styles.LeftArrow} />
                <span>{backButtonText}</span>
            </button>
        </section>
    );

    const headSpace = <div className={styles.headSpace} />;

    const allConversationsResult = (
        <Container className={styles.AllConversationsResult}>
            {!IS_IOS_APP && headSpace}
            {backButton}
            <section className={styles.AllConversationsLabelSection}>
                <div className={styles.AllConversationsLabel}>
                    {allConversationsLabel} &laquo;{boldSearchText}&raquo;
                </div>
                <div className={styles.ConversationCountLabel}>
                    {conversations.length}
                    {' ' + conversationsLabel.toLocaleLowerCase()}
                </div>
            </section>
            <section
                aria-label={conversationsLabel}
                className={styles.ConversationCardsContainer}
            >
                {conversations.map((convo) => {
                    if (!hasConversations) {
                        return null;
                    }
                    return isThemeCard(convo.taxonomy) ||
                        isOrganizationCard(convo.taxonomy) ? (
                        <ThemeCard
                            key={convo.id}
                            conversation={convo}
                            className={conversationCardStyle(convo)}
                            onClick={(): void => onConversationSelect(convo)}
                        />
                    ) : (
                        <ConversationCard
                            key={convo.id}
                            conversation={convo}
                            className={conversationCardStyle(convo)}
                            onClick={(): void => onConversationSelect(convo)}
                            category={{
                                slug: convo.category.slug,
                                name: convo.category.name,
                            }}
                            decorator={decorator}
                        />
                    );
                })}
            </section>
        </Container>
    );

    const isEmptyResult =
        conversations?.length === 0 && filteredQuestions?.length === 0;

    const questionsToUse = viewAllQuestions
        ? filteredQuestions
        : filteredQuestions.slice(0, isMobile ? 5 : 7);

    const QuestionsList = questionsToUse.map((q) => {
        const questionIndex = questionsToUse.indexOf(q);
        const isLeft = questionIndex % 2 === 0;
        return (
            <SearchQuestionBox
                key={q.id}
                question={q}
                color={'dark'}
                className={styles.QuestionBoxes}
                allQuestions={questions}
                index={questions.indexOf(q)}
                parentPage={deckParentPages.search}
                searchedText={searchedText}
                isFavourite={isQuestionFavourite(q)}
                containerClassName={classNames(
                    viewAllQuestions
                        ? styles.AllQuestionsCardContainer
                        : styles.SearchQuestionCardContainer,
                    !isLeft && styles.NoRightMargin
                )}
                allConversations={conversations}
                viewAllSearchQuestions={viewAllQuestions}
                favouriteQuestions={favouriteQuestions}
            >
                {q.text}
            </SearchQuestionBox>
        );
    });

    const QuestionsContainer = (
        <section className={styles.QuestionsContainer}>
            {(!isMobile || filteredQuestions?.length > 0) && (
                <header className={styles.QuestionsHeader}>
                    <h2
                        id='conversations-row-title'
                        className={styles.QuestionsTitle}
                    >
                        {questionsLabel}
                    </h2>
                    &nbsp;{'(' + filteredQuestions.length + ')'}
                </header>
            )}
            {filteredQuestions?.length > 0 && (
                <div className={styles.QuestionBoxesContainer}>
                    {QuestionsList}
                </div>
            )}
            {!isMobile && filteredQuestions?.length === 0 && emptyQuestionsText}
            {filteredQuestions?.length > questionsToUse.length && (
                <button
                    className={styles.ShowAllQuestionsLink}
                    onClick={() => {
                        window.scrollTo(0, 0);
                        setViewAllQuestions(true);
                    }}
                >
                    {questionsLink} &laquo;{boldSearchText}&raquo;
                </button>
            )}
        </section>
    );

    const allQuestionsResult = (
        <Container className={styles.AllQuestionsResult}>
            {!IS_IOS_APP && headSpace}
            {backButton}
            <section className={styles.AllQuestionsLabelSection}>
                <div className={styles.AllQuestionsLabel}>
                    {allQuestionsLabel} &laquo;{boldSearchText}&raquo;
                </div>
                <div className={styles.QuestionCountLabel}>
                    {filteredQuestions.length}
                    {' ' + questionsLabel.toLocaleLowerCase()}
                </div>
            </section>
            <div
                className={
                    viewAllQuestions
                        ? styles.AllQuestionBoxesContainer
                        : styles.QuestionBoxesContainer
                }
            >
                {QuestionsList}
            </div>
        </Container>
    );

    const infoTextContainer = (
        <section
            className={classNames(
                styles.InfoText,
                !canSearch && styles.InfoTextReducedTopMargin
            )}
        >
            {infoText}
        </section>
    );

    const searchButton = (
        <button
            className={styles.SearchButton}
            onClick={() => {
                setIsLoadingResults(true);
                search(searchText);
            }}
        >
            {isLoadingResults ? <RotatingSpinner /> : searchButtonLabel}
        </button>
    );

    const clearSearchText = () => {
        setReadyToSearch(true);
        setSearchText('');
    };

    const clearSearchFieldButton = (
        <div
            className={styles.ClearSearchField}
            onClick={() => clearSearchText()}
            role='button'
            onMouseDown={(e) => preventDefault(e)}
            onKeyDown={(e) => {
                if (primaryKeyPressed(e)) {
                    if (e.key === 'Enter') {
                        clearSearchText();
                    }
                }
            }}
            tabIndex={0}
        >
            <CloseIcon />
        </div>
    );

    const SearchPageContainerClass = classNames(
        styles.SearchPageContainer,
        searchedText.length === 0 && styles.StartPosition,
        isEmptyResult && styles.EmptyResults,
        isiOS && styles.SearchPageContainerIOS
    );

    const SearchTitleContainerClass = classNames(
        styles.SearchTitleContainer,
        searchedText.length === 0 && styles.StartPosition,
        isEmptyResult && styles.EmptyResults,
        isiOS && searchedText.length === 0 && styles.SearchTitleContainerIOS
    );

    const searchTitleContainer = (
        <Container className={SearchTitleContainerClass}>
            <h2 className={styles.SearchTitle}>{seoTitle}</h2>
        </Container>
    );

    const UpperSearchContainer = (
        <div className={styles.UpperSearchContainer}>
            <div
                className={
                    !canSearch
                        ? classNames(
                              styles.SearchFieldContainer,
                              styles.ErrorField
                          )
                        : styles.SearchFieldContainer
                }
            >
                <div className={styles.SearchIcon}>
                    <SearchIcon />
                </div>
                <input
                    type={'text'}
                    id={'sok'}
                    placeholder={searchInputPlaceholder}
                    className={styles.SearchField}
                    value={searchText}
                    autoComplete='off'
                    onChange={onInputChanged}
                    onKeyDown={(e) => {
                        if (primaryKeyPressed(e)) {
                            if (e.key === 'Enter') {
                                search(searchText);
                                try {
                                    (
                                        document.activeElement as HTMLElement
                                    ).blur();
                                } catch {
                                    console.error(
                                        'Failed to hide native keyboard.'
                                    );
                                }
                            }
                        }
                    }}
                    onFocus={onInputChanged}
                    //   onBlur={onInputBlur}
                ></input>
                {(readyToSearch || isLoadingResults) && searchButton}
                {!readyToSearch &&
                    !isLoadingResults &&
                    searchText.length > 0 &&
                    clearSearchFieldButton}
            </div>
            {!canSearch && (
                <div className={styles.MinimumCharactersError}>
                    {minimumCharactersError}
                </div>
            )}

            {searchedText.length === 0 &&
                conversations?.length === 0 &&
                questions?.length === 0 &&
                infoTextContainer}
        </div>
    );

    return (
        <Layout
            routes={routes}
            language={language}
            headerProps={headerProps}
            withHeaderSpace={headerProps.withHeaderSpace}
            pageClassName={SearchPageContainerClass}
        >
            <SEO title={seoTitle} />
            {!hideHeaderAndSearchField && searchTitleContainer}
            {!hideHeaderAndSearchField && UpperSearchContainer}

            {viewAllConversations && allConversationsResult}
            {viewAllQuestions && allQuestionsResult}

            {!isLoadingResults && searchedText.length > 0 && !isEmptyResult && (
                <Container
                    className={classnames(
                        styles.DesktopResultContainer,
                        !canSearch && styles.DesktopContainerReducedTopMargin
                    )}
                >
                    {isMobile &&
                        conversations?.length === 0 &&
                        emptyConversationsText}
                    {isMobile &&
                        filteredQuestions?.length === 0 &&
                        emptyQuestionsText}

                    <div
                        className={classnames(
                            styles.MidSearchContainer,
                            isMobile &&
                                !canSearch &&
                                styles.MidSearchContainerReducedTopMargin
                            // IS_ANDROID_APP && styles.Android
                        )}
                    >
                        {searchedText.length > 0 &&
                            !viewAllQuestions &&
                            !viewAllConversations &&
                            ConversationsContainer}
                    </div>
                    <div className={styles.LowerSearchContainer}>
                        {searchedText.length > 0 &&
                            !viewAllConversations &&
                            !viewAllQuestions &&
                            QuestionsContainer}
                    </div>
                </Container>
            )}
            {!isLoadingResults && searchedText.length > 0 && isEmptyResult && (
                <Container className={classnames(styles.NoResultsContainer)}>
                    {allEmptyText}
                </Container>
            )}
        </Layout>
    );
};

export default SearchPage;
