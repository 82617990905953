import React from 'react';
import classnames from 'classnames';
import styles from './organization-card.module.scss';
import RoundCard from '../generic/round-card';
import { Props as CardProps } from '../generic/card';

interface Props extends CardProps {
    name?: string;
}

const OrganizationCard: React.FC<Props> = ({
    name,
    onClick,
    className,
    children,
}: Props) => (
    <RoundCard
        className={classnames(styles.OrganizationCard, className)}
        onClick={onClick}
    >
        {children}
        {!children && name && <div className={styles.title}>{name}</div>}
    </RoundCard>
);

export default OrganizationCard;
