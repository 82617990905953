import React from 'react';
import classnames from 'classnames';
import AccountSubpage from '../subpage/account-subpage';
import { useSelector } from 'react-redux';
import { subscriptionSelector } from 'store-modules/user';
import NoSubPage from './no-sub-page';
import ActiveSubPage from './active-sub-page';
import CancelledSubPage from './cancelled-sub-page';
import styles from './subscription-page.module.scss';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';
import { Language } from '_types/language';

interface Props {}
interface ViewCopy {
    pageTitle: string;
    title: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Abonnement',
    title: 'Abonnement',
};

const enCopy: ViewCopy = {
    pageTitle: 'Subscription',
    title: 'Subscription',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const SubscriptionPage: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const { pageTitle, title } = returnCopyForLanguage(language, copies);
    const sub = useSelector(subscriptionSelector);

    const isActive = sub && sub.data.status === 'active';
    const isCancelled = sub && sub.data.status === 'pending-cancel';

    const bgClassName = classnames(!sub && styles.bgNoSub);

    return (
        <AccountSubpage
            pageTitle={pageTitle}
            title={title}
            bgClassName={bgClassName}
        >
            {!sub && <NoSubPage />}
            {isActive && <ActiveSubPage sub={sub!} />}
            {isCancelled && <CancelledSubPage sub={sub!} />}
        </AccountSubpage>
    );
};

export default SubscriptionPage;
