import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authenticatedSelector } from 'store-modules/auth';
import { isPremiumSelector, userSelector } from 'store-modules/user';

import { AuthRoles, PermissionActionTypes } from './utils';
import permissions from './permissions';

const useHasPermission = () => {
    const isPremium = useSelector(isPremiumSelector);
    const authenticated = useSelector(authenticatedSelector);
    const user = useSelector(userSelector);
    const isProUser = !!user?.organization;

    const authRole = useMemo(() => {
        if (isProUser) return AuthRoles.PRO;
        if (isPremium) return AuthRoles.PREMIUM;
        if (authenticated) return AuthRoles.INACTIVE;
        return AuthRoles.UNAUTHENTICATED;
    }, [isPremium, authenticated, isProUser]);

    const hasPermission = (action: PermissionActionTypes) =>
        permissions[authRole].includes(action);

    return hasPermission;
};

export default useHasPermission;
