import * as React from 'react';
import classnames from 'classnames';
import styles from './back-button.module.scss';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';

type Props = {
    clickHandler: () => void;
    language: Language;
    className?: string;
};

interface ViewCopy {
    text: string;
}

const nbCopy: ViewCopy = {
    text: 'Tilbake',
};

const enCopy: ViewCopy = {
    text: 'Back',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const BackButton: React.FC<Props> = ({
    clickHandler,
    language,
    className,
}: Props) => {
    const { text } = returnCopyForLanguage(language, copies);
    const onGoBack = (): void => clickHandler();

    return (
        <button
            data-testid='component-go-back-btn'
            className={classnames(styles.BackButton, className)}
            onClick={onGoBack}
            tabIndex={0}
        >
            <ArrowLeft />
            <span>{text}</span>
        </button>
    );
};

export default BackButton;
