import * as React from 'react';
import styles from './delete-account-confirmation-page.module.scss';
import { Container } from 'components/container';
import { PageHeader } from 'components/page-header';
import { Button } from 'components/base';
import { SEO } from 'components/seo';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnRoutesForLanguage } from 'services/language-service';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Layout } from 'components/layout';

interface Action {
    label: string;
    onClick: () => void;
}

type Props = {
    title?: string;
    message?: string;
    label?: string;
    onButtonClick?: () => void;
    renderMessage?: () => React.ReactNode;
    actions?: Action[];
};

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const DeleteAccountConfirmationPage: React.FC<Props> = ({
    renderMessage,
}: Props) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const title = t('deleteAccountConfirmation.title');
    const message = t('deleteAccountConfirmation.description');
    const label = t('deleteAccountConfirmation.button');
    const onButtonClick = () => navigate(routes.index);
    const messageUi = renderMessage ? (
        renderMessage()
    ) : (
        <p className={styles.text}>{message}</p>
    );

    const headerProps = {
        withLogin: false,
        withAccount: true,
        showLogoLink: true,
    };

    return (
        <Layout
            routes={routes}
            language={language}
            headerProps={headerProps}
            withFooter={false}
            pageClassName={styles.ErrorPage}
        >
            <SEO title={title} noIndex={true} />
            <PageHeader withAppbar={false} withAccount={false} />
            <Container className={styles.Container}>
                <PageTitle className={styles.Title} title={title!} />
                {messageUi}
                <Button onClick={onButtonClick} className={styles.Button}>
                    {label}
                </Button>
            </Container>
        </Layout>
    );
};

export default DeleteAccountConfirmationPage;
