import React from 'react';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import styles from './pro-sub.module.scss';
import { Link as CustomLink } from 'components/link';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Link } from 'react-router-dom';
import { subscriptionSelector } from 'store-modules/user';

interface Props {}
interface ViewCopy {
    productName: string;
    infoText1: string;
    infoText2: string;
    infoText3: string;
    mailTo: string;
    faqLinkText: string;
}

const nbCopy: ViewCopy = {
    productName: 'FuelBox Proff',
    infoText1: 'Du har et Proff-abonnement.',
    infoText2: 'Har du spørsmål om abonnementet?',
    infoText3: 'Kontakt ansvarlig i din organisasjon eller send en epost til ',
    mailTo: 'post@fuelbox.no',
    faqLinkText: 'Ofte stilte spørsmål om FuelBox Proff',
};

const enCopy: ViewCopy = {
    productName: 'FuelBox Pro',
    infoText1: 'You have a FuelBox Pro subscription',
    infoText2: 'Got a question about your subscription?',
    infoText3: 'Contact your manager or send an email to ',
    mailTo: 'post@fuelboxworld.com',
    faqLinkText: 'Frequently asked questions about FuelBox Pro',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ProSub: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const sub = useSelector(subscriptionSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const {
        productName,
        infoText1,
        infoText2,
        infoText3,
        mailTo,
        faqLinkText,
    } = returnCopyForLanguage(language, copies);

    return (
        <section
            className={
                !!sub!.data.owner
                    ? styles.ProSubContainerOwner
                    : styles.ProSubContainer
            }
        >
            <h2 className={styles.Header}>{productName}</h2>
            <p>
                {infoText1}
                <br /> <br />
                {infoText2}
                <br />
                {infoText3}
                <CustomLink to={`mailto:${mailTo}`} external>
                    {mailTo}
                </CustomLink>
                .<br />
                <br />
                <Link
                    className={styles.FaqLink}
                    to={{
                        pathname: routes.faq,
                        hash: 'faq-pro',
                    }}
                >
                    {faqLinkText}
                </Link>
            </p>
        </section>
    );
};

export default ProSub;
