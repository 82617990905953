import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import SessionContext, { SessionContextValues } from './context';
import useSwiperContext from 'hooks/use-swiper-context';
import {
    ActiveContent,
    getSessionDocumentRef,
} from 'utils/collaboration-utils';
import { useIsVisible } from 'hooks/firebase/use-is-visible';
import useCollaboration from '../use-collaboration';
import { useMediaQuery, useTheme } from 'components/base';

type SessionProviderProps = { children: ReactNode; slideDefs: ActiveContent[] };

const SessionProvider: FC<SessionProviderProps> = ({ children, slideDefs }) => {
    const { activeIndex } = useSwiperContext();
    const { collaborationId, interactiveMode } = useCollaboration();

    const theme = useTheme();
    const displayToolbarAsDrawer = useMediaQuery(theme.breakpoints.down('md'));
    const displayInfoInDrawer = useMediaQuery(theme.breakpoints.down('md'));

    const visible = useIsVisible();
    const toolbarRef = useRef<HTMLDivElement>(null);

    const activeContent = useMemo(
        () => (activeIndex != null ? slideDefs?.[activeIndex] : undefined),
        [activeIndex, slideDefs]
    );

    useEffect(() => {
        if (activeContent && interactiveMode && collaborationId)
            getSessionDocumentRef(collaborationId).set(
                { activeContent },
                { merge: true }
            );
    }, [activeContent, collaborationId]);

    const values: SessionContextValues = {
        activeContent,
        displayInfoInDrawer,
        displayToolbarAsDrawer,
        slideDefs,
        toolbarRef,
        visible,
    };

    return (
        <SessionContext.Provider value={values}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
