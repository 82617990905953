import { RouteList, routesNO, routesEN } from 'api/routes';
import { Button, Icon } from 'components/base';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import React, { useCallback, useState } from 'react';
import styles from './pro-product-info-page.module.scss';
import { useSelector } from 'react-redux';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { HeaderProps } from 'components/page-header';
import { userSelector } from 'store-modules/user';
import companyClient from 'api/company-client';
import { Loading } from 'components/loading';
import { useWindowSize } from 'hooks';
// import { FreemiumNavbar } from 'components/freemium-navbar';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preventDefault = (e: React.MouseEvent<any>): void => e.preventDefault();
interface ViewCopy {
    pageTitle: string;
    title: string;
    description1: string;
    description2: string;
    bulletText1: string;
    bulletText2: string;
    bulletText3: string;
    bulletText4: string;
    bulletText5: string;
    currencyLabel: string;
    yearLabel: string;
    calculatorTitle: string;
    calculatorDescription1: string;
    calculatorDescription2: string;
    numberOfUsersLabel: string;
    purchaseButtonLabel: string;
    featureTitle1: string;
    featureTitle2: string;
    schoolTitle: string;
    schoolText1: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Fuelbox',
    title: 'FuelBox for jobb og skole',
    description1:
        'Med FuelBox appen har du et effektivt relasjonsbyggende samtaleverktøy rett i lomma, som tar deg fra ord til handling på en enkel måte.',
    description2:
        ' Du får tilgang til tusenvis av utfordrende og viktige spørsmål, slik at du kan jobbe konkret med ledelse, teamutvikling, kulturbygging, mentorprogram eller bærekraft – eller fasilitere en ren brainstorming.',
    bulletText1: 'Bruk Google eller Microsoft for å logge inn',
    bulletText2: 'Samtaler til både jobb og privat',
    bulletText3: 'Lagre dine favoritter',
    bulletText4: 'Lag dine egne samtaler og del dem med andre',
    bulletText5: 'Samle dine svar og lag ordskyer med Polling',
    currencyLabel: 'NOK',
    yearLabel: 'år',
    calculatorTitle: 'Hva koster det?',
    calculatorDescription1: '790 NOK per bruker i året.',
    calculatorDescription2: 'Få høyere rabatt jo flere brukere du har!',
    numberOfUsersLabel: 'Antall brukere',
    purchaseButtonLabel: 'Kjøp abonnement',
    featureTitle1: 'Samtaler for team og organisasjoner',
    featureTitle2: 'Skreddersydd innhold?',
    schoolTitle: 'FuelBox i skolen',
    schoolText1:
        ' Vi har en egen skoleportal med spørsmål og samtaler som skaper psykologisk trygghet og gode læringsmiljø. Samtalene er satt sammen av fagmiljøer som jobber med skole og utdanning.',
};

const enCopy: ViewCopy = {
    pageTitle: 'Fuelbox',
    title: 'Fuelbox for work and school',
    description1:
        'With the FuelBox app you have an effective relation building conversation tool in your pocket. It’s an easy way to go from word to action.',
    description2:
        'You will gain access to thousands of challenging and important questions, allowing you to work with leadership, team building, culture building, mentorship programs or sustainability – or facilitate brainstorming sessions.',
    bulletText1: 'Log in with Google or Microsoft',
    bulletText2: 'Conversations for work and private',
    bulletText3: 'Save your favourites',
    bulletText4: 'Create your own conversations and share them with others',
    bulletText5: 'Gather replies and create word clouds with Polling',
    currencyLabel: 'NOK',
    yearLabel: 'year',
    calculatorTitle: 'What does it cost?',
    calculatorDescription1: '790 NOK per user per year.',
    calculatorDescription2: 'Get a higher discount the more users you have!',
    numberOfUsersLabel: 'Number of users',
    purchaseButtonLabel: 'Get subscription',
    featureTitle1: 'Conversations for teams and organisations',
    featureTitle2: 'Custom content?',
    schoolTitle: 'FuelBox in schools',
    schoolText1:
        'We have a dedicated subscription for schools with questions and conversations that enhance the psychological safety and create great learning environments. The conversations have been put together by experts working with schools and education.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        title,
        description1,
        description2,
        bulletText1,
        bulletText2,
        bulletText3,
        bulletText4,
        bulletText5,
        currencyLabel,
        yearLabel,
        calculatorTitle,
        calculatorDescription1,
        calculatorDescription2,
        numberOfUsersLabel,
        purchaseButtonLabel,
        featureTitle1,
        featureTitle2,
        schoolTitle,
        schoolText1,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const localStorageNumberOfUsers = Number(localStorage.getItem('NumberOfBusinessUsers'))
    const [numberOfUsers, setNumberOfUsers] = useState(
        localStorageNumberOfUsers > 0 ? localStorageNumberOfUsers : 10
    );
    const user = useSelector(userSelector);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [priceList, setPriceList] = useState<any>();

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setNumberOfUsers(Number(event.target.value));
            localStorage.setItem('NumberOfBusinessUsers', event.target.value)
        },
        []
    );
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';

    if (!priceList) {
        companyClient.getCompanyPriceIntervals().then(setPriceList);
        return <Loading />;
    }

    const calculatePrice = (): number => {
        for (let i = 0; i < priceList.length; i++) {
            const priceObject = priceList[i];
            if (!priceObject.max_users) {
                return priceObject.price_per_user * numberOfUsers;
            }
            if (
                numberOfUsers >= priceObject.min_users &&
                numberOfUsers <= priceObject.max_users
            ) {
                return priceObject.price_per_user * numberOfUsers;
            }
        }
        //Should never reach here if BE endpoint is correct.
        return 0;
    };

    const calculateFullPrice = (): number => {
        return numberOfUsers * priceList[0].price_per_user;
    };

    const numberOfUsersToDisplay = (): number | string => {
        return numberOfUsers < 1 ? '' : parseInt(numberOfUsers.toString());
    };

    const featureTextContainer2 = (
        <div className={styles.FeatureTextContainer2}>
            <h2 className={styles.FeatureTitle2}>{featureTitle2}</h2>

            <div className={styles.FeatureText2}>
                <Trans
                    components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            className={styles.link}
                            href='post@fuelbox.no'
                            key={0}
                        />,
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            className={styles.link}
                            href={
                                language === 'en'
                                    ? 'https://fuelboxworld.com/tailor-made-fuelbox/'
                                    : 'https://fuelbox.no/jobb/skreddersom/'
                            }
                            key={1}
                        />,
                    ]}
                    i18nKey='proProductInfoPage.FeatureText2'
                />
            </div>
        </div>
    );
    const featureList = (
        <div className={styles.FeatureList}>
            <ul>
                <li>
                    <div className={styles.FeatureItem}>
                        <Icon
                            name={'check'}
                            className={styles.FeatureItemIcon}
                            fontSize='small'
                        ></Icon>
                        <div className={styles.FeatureItemText}>
                            {bulletText1}
                        </div>
                    </div>
                </li>
                <li>
                    <div className={styles.FeatureItem}>
                        <Icon
                            name={'check'}
                            className={styles.FeatureItemIcon}
                            fontSize='small'
                        ></Icon>
                        <div className={styles.FeatureItemText}>
                            {bulletText2}
                        </div>
                    </div>
                </li>
                <li>
                    <div className={styles.FeatureItem}>
                        <Icon
                            name={'check'}
                            className={styles.FeatureItemIcon}
                            fontSize='small'
                        ></Icon>
                        <div className={styles.FeatureItemText}>
                            {bulletText3}
                        </div>
                    </div>
                </li>
                <li>
                    <div className={styles.FeatureItem}>
                        <Icon
                            name={'check'}
                            className={styles.FeatureItemIcon}
                            fontSize='small'
                        ></Icon>
                        <div className={styles.FeatureItemText}>
                            {bulletText4}
                        </div>
                    </div>
                </li>
                <li>
                    <div className={styles.FeatureItem}>
                        <Icon
                            name={'check'}
                            className={styles.FeatureItemIcon}
                            fontSize='small'
                        ></Icon>
                        <div className={styles.FeatureItemText}>
                            {bulletText5}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );

    const headerProps = (): HeaderProps => {
        if (user) {
            return {
                withLogin: true,
                showLogoLink: true,
            };
        }
        return {
            // customNavigation: FreemiumNavbar,
            withLogin: true,
            showLogoLink: true,
        };
    };

    return (
        <div className={styles.OuterPageContainer}>
            <Layout
                routes={routes}
                language={language}
                pageClassName={styles.PageLayout}
                headerProps={headerProps()}
                withFooter={true}
            >
                <SEO title={pageTitle} />
                <div className={styles.bg} />

                <Container className={styles.UpperInfoContainer}>
                    <div className={styles.UpperLeftContainer}>
                        <div className={styles.title}>
                            <h1>{title}</h1>
                        </div>
                        <div className={styles.text}>{description1}</div>
                        <div className={styles.text}>{description2}</div>
                    </div>
                    <div
                        className={
                            language === 'en'
                                ? classNames(
                                      styles.UpperRightContainer,
                                      styles.UpperRightContainerEnglish
                                  )
                                : styles.UpperRightContainer
                        }
                    >
                        {
                            //Placeholder for photo
                        }
                    </div>
                </Container>
                <Container className={styles.CalculatorContainer}>
                    <div className={styles.InnerCalculatorContainer}>
                        <div className={styles.TitleInputAndPriceContainer}>
                            <div
                                className={styles.CalculatorTitleAndDescription}
                            >
                                <h2>{calculatorTitle}</h2>
                                <div className={styles.Description}>
                                    {calculatorDescription1}
                                </div>
                                <div className={styles.Description}>
                                    {calculatorDescription2}
                                </div>
                            </div>

                            <div className={styles.InputContainer}>
                                <div>{numberOfUsersLabel}</div>
                                <input
                                    type='number'
                                    value={numberOfUsersToDisplay()}
                                    onChange={onInputChanged}
                                    pattern='[0-9]*'
                                    min={1}
                                />
                            </div>
                            <div className={styles.PriceContainer}>
                                <div className={styles.PriceCurrencyContainer}>
                                    <div className={styles.Price}>= </div>
                                    <div className={styles.Price}>
                                        {calculatePrice()}
                                    </div>
                                    <div className={styles.PriceCurrency}>
                                        {currencyLabel}
                                        {' / '}
                                        {yearLabel}
                                    </div>
                                </div>
                                <div className={styles.DiscountText}>
                                    <div className={styles.FullPrice}>
                                        {calculateFullPrice()} {currencyLabel}{' '}
                                        {' / '} {yearLabel}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            className={styles.PurchaseButton}
                            appearance='cta'
                            onClick={() => {
                                navigate(routes.companySignupPage, {
                                    state: {
                                        numberOfUsers: numberOfUsersToDisplay(),
                                    },
                                });
                            }}
                            onMouseDown={preventDefault}
                        >
                            {purchaseButtonLabel}
                        </Button>
                    </div>
                </Container>
                <Container className={styles.LowerInfoContainer}>
                    <div className={styles.LowerInnerInfoContainer}>
                        {isDesktop && featureList}
                        <div className={styles.OuterFeatureTextContainer}>
                            <div className={styles.FeatureTextContainer1}>
                                <h2 className={styles.FeatureTitle1}>
                                    {featureTitle1}
                                </h2>
                                <div className={styles.FeatureText1}>
                                    <Trans
                                        components={[<strong key={0} />]}
                                        i18nKey='proProductInfoPage.FeatureText1'
                                    />
                                </div>
                            </div>
                            {isDesktop && featureTextContainer2}
                        </div>
                    </div>
                    {!isDesktop && featureList}
                    <div className={styles.DesktopImageContainer} />
                    {!isDesktop && featureTextContainer2}
                </Container>
                <Container className={styles.SchoolContainer}>
                    <div className={styles.InnerSchoolContainer}>
                        <div className={styles.LeftSchoolContainer}>
                            <p className={styles.SchoolTitle}>{schoolTitle}</p>
                            <p className={styles.SchoolDescription1}>
                                {schoolText1}
                            </p>

                            <div className={styles.SchoolDescription2}>
                                <Trans
                                    components={[
                                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                                        <a
                                            className={styles.Link}
                                            href='post@fuelbox.no'
                                            key={0}
                                        />,
                                    ]}
                                    i18nKey='proProductInfoPage.SchoolText2'
                                />
                            </div>
                        </div>
                        <div className={styles.RightSchoolContainer}></div>
                    </div>
                </Container>
                {!isDesktop && (
                    <div className={styles.LowerPurchaseButtonContainer}>
                        <Button
                            className={styles.LowerPurchaseButton}
                            appearance='cta'
                            onClick={() => {
                                navigate(routes.companySignupPage);
                            }}
                            onMouseDown={preventDefault}
                        >
                            {purchaseButtonLabel}
                        </Button>
                    </div>
                )}
            </Layout>
        </div>
    );
};

export default LandingPage;
