import React, { FC } from 'react';

import { Box } from 'components/base';
import { Button } from 'components/base';
import { DarkThemeProvider } from 'style/theme';
import { FeaturedConversation } from '_types';
import { useTranslation } from 'react-i18next';
import styles from './featured-deck.module.scss';

type Props = {
    deck: FeaturedConversation;
    isActive: boolean;
    onClick: () => void;
};

const splitDeckName = (text: string): string[] => {
    const DIVIDER = '. ';
    let parts = text.split(DIVIDER);

    if (parts[parts.length - 1] === '') {
        parts = parts.map((part) => part + DIVIDER);
        parts.pop();
    } else {
        parts = parts.map((part, index) =>
            index === parts.length - 1 ? part : part + DIVIDER
        );
    }

    return parts;
};

const FeaturedDeck: FC<Props> = ({ deck, isActive, onClick }) => {
    const { t } = useTranslation();

    const Content = () => (
        <div className={styles.overlayText}>
            <h1 className={styles.title}>
                {splitDeckName(deck.name).map((text, index) => (
                    <div key={index}>{text}</div>
                ))}
            </h1>

            <h2 className={styles.description}>{deck.description}</h2>

            <Button component='span' tabIndex={-1}>
                {t('startConversation')}
            </Button>
        </div>
    );

    return (
        <button
            disabled={!isActive}
            onClick={onClick}
            className={styles.featuredDeck}
        >
            <div className={styles.imageContainer}>
                <picture className={styles[deck.colorTheme]}>
                    <source
                        media='(max-width: 767px)'
                        srcSet={deck.illustrationMobile!.src}
                    />
                    <source
                        media='(min-width: 768px)'
                        srcSet={deck.illustrationDesktop!.src}
                    />
                    <img
                        className={styles.image}
                        src={deck.illustrationMobile!.src}
                        alt={deck.name}
                    />
                </picture>

                {deck.colorTheme === 'dark' ? (
                    <DarkThemeProvider>
                        <Content />
                    </DarkThemeProvider>
                ) : (
                    <Box color='primary.main'>
                        <Content />
                    </Box>
                )}
            </div>
        </button>
    );
};

export default FeaturedDeck;
