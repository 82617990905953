import React from 'react';
import { ErrorPage } from 'pages/error-page';
import { Link } from 'components/link';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';

interface Props {
    onButtonClick: () => void;
}
interface ViewCopy {
    message1: string;
    message2: string;
    mailTo: string;
    buttonLabel: string;
}

const nbCopy: ViewCopy = {
    message1: 'Her har visst noe gått galt.',
    message2: 'Prøv på nytt eller ta kontakt med oss på',
    mailTo: 'post@fuelbox.no',
    buttonLabel: 'Prøv på nytt',
};

const enCopy: ViewCopy = {
    message1: 'It looks like something went wrong.',
    message2: 'Try again or send an email to',
    mailTo: 'post@fuelbox.no',
    buttonLabel: 'Try again',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const RegistrationErrorPage: React.FC<Props> = ({ onButtonClick }: Props) => {
    const language: Language = useSelector(languageSelector);
    const { message1, message2, mailTo, buttonLabel } = returnCopyForLanguage(
        language,
        copies
    );
    return (
        <ErrorPage
            label={buttonLabel}
            onButtonClick={onButtonClick}
            renderMessage={() => (
                <p>
                    {message1}
                    <br />
                    {message2}{' '}
                    <Link to={`mailto:${mailTo}`} external>
                        {mailTo}
                    </Link>
                </p>
            )}
        />
    );
};

export default RegistrationErrorPage;
