import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import restClient from './rest-client';
export interface LoginResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export interface AzureLoginResponse extends LoginResponse {
    first_azure_login: boolean;
}

export interface GoogleLoginData {
    id_token: string;
    productId?: number;
}

export interface AppleLoginData {
    id_token: string;
    email: SignInWithAppleResponse['response']['email'];
    given_name: SignInWithAppleResponse['response']['givenName'];
    family_name: SignInWithAppleResponse['response']['familyName'];
    productId?: number;
}

export interface AzureLoginData {
    id_token: string;
    preferred_username: string;
    tenant_id: string;
    given_name: string;
    family_name: string;
}

export interface LoginData {
    username: string;
    password: string;
}

const mapLoginResponse = ({ token }: LoginResponse): string => token;

const CONFIG = {
    baseUrl: '/wp-json/jwt-auth/v1',
};

const GOOGLE_CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

const APPLE_CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

const AZURE_CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

export default {
    getToken: (loginData: LoginData): Promise<string> => {
        return restClient
            .post<LoginData, LoginResponse>('/token', loginData, CONFIG)
            .then(mapLoginResponse);
    },

    logInByGoogleToken: (
        id_token: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        productId?: any
    ): Promise<string> => {
        return restClient
            .post<GoogleLoginData, LoginResponse>(
                '/googleSignIn',
                { id_token, productId },
                GOOGLE_CONFIG
            )
            .then(mapLoginResponse);
    },

    logInByAppleToken: (
        {
            identityToken: id_token,
            email,
            givenName: given_name,
            familyName: family_name,
        }: SignInWithAppleResponse['response'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        productId?: any
    ): Promise<string> => {
        return restClient
            .post<AppleLoginData, LoginResponse>(
                '/appleSignIn',
                { id_token, email, given_name, family_name, productId },
                APPLE_CONFIG
            )
            .then(mapLoginResponse);
    },
    logInByAzureToken: (
        id_token: string,
        preferred_username: string,
        tenant_id: string,
        given_name: string,
        family_name: string
    ): Promise<{
        token: string;
        firstAzureLogin: boolean;
    }> => {
        return restClient
            .post<AzureLoginData, AzureLoginResponse>(
                '/azureSignIn',
                {
                    id_token,
                    preferred_username,
                    tenant_id,
                    given_name,
                    family_name,
                },
                AZURE_CONFIG
            )
            .then((response) => {
                return {
                    token: mapLoginResponse(response),
                    firstAzureLogin: response.first_azure_login,
                };
            });
    },
};
