import restClient from './rest-client';
import { Subscription } from '_types/subscription';

interface SubscriptionResponse {
    completed_payments: string[];
    end_date: string;
    expiry_date: string;
    failed_payments: number;
    interval: string;
    iap: string;
    last_payment_date: string;
    length: number;
    order_id: number;
    owner: boolean;
    period: string;
    pro_content_disabled: boolean;
    product_id: number;
    start_date: string;
    status: string;
    subscription: {
        next_payment_date: string;
        productId: number;
        productName: string;
        subscriptionId: number;
        subtotal: string;
    };
    suspension_count: number;
    trial_expiry_date: number;
    variation_id: number;
    pro_generic_content_disabled: boolean;
}

const mapSubscriptions = (
    subscriptions: SubscriptionResponse[]
): Subscription | null => {
    if (subscriptions.length === 0) {
        return null;
    }

    const sub = subscriptions[0];

    return {
        type:
            sub.status === 'active' || sub.status === 'pending-cancel'
                ? 'premium'
                : 'freemium',
        data: {
            order_id: sub.order_id,
            pro_content_disabled: sub.pro_content_disabled,
            product_id: sub.product_id,
            subscription: sub.subscription,
            status: sub.status,
            expiry_date: sub.expiry_date,
            end_date: sub.end_date,
            iap: sub.iap,
            start_date: sub.start_date,
            owner: sub.owner,
            completed_payments: sub.completed_payments,
            generic_pro_content_disabled: sub.pro_generic_content_disabled,
        },
    };
};

export default {
    getActiveSubscription: (): Promise<Subscription | null> =>
        restClient
            .get<SubscriptionResponse[]>('/getActiveSubscription', false)
            .then(mapSubscriptions),
    endSubscription: (): Promise<void> =>
        restClient.get<void>('/cancelSubscription', false),
};
