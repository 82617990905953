import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, Button } from 'components/base';
import collaborationClient from 'api/collaboration-client';
import useGenerateSessionPdf from 'hooks/use-generate-session-pdf';

import useCollaboration from '../../use-collaboration';

type EndSlideProps = {
    isActive: boolean;
};

const EndSlide: FC<EndSlideProps> = ({ isActive }) => {
    const { closeCollaborationView, collaboration, collaborationId } =
        useCollaboration();
    const { t } = useTranslation();

    const { generateSessionPdf, loading: generateSessionPdfLoading } =
        useGenerateSessionPdf(collaboration!);

    const [endingCollaboration, setEndingCollaboration] = useState(false);

    const handleEndCollaboration = () => {
        setEndingCollaboration(true);

        collaborationClient
            .endCollaboration(collaborationId)
            .then(closeCollaborationView)
            .catch(console.error)
            .finally(() => setEndingCollaboration(false));
    };

    return (
        <Box display='flex' flexDirection='column' width={1} overflow='auto'>
            <Box margin='auto' maxWidth={400} p={5}>
                <Stack alignItems='center' spacing={3}>
                    <Typography
                        align='center'
                        component='h1'
                        fontWeight={600}
                        variant='h5'
                    >
                        {t('collaboration.endSlide.heading')}
                    </Typography>

                    <Stack alignItems='center' spacing={4}>
                        <Typography align='center' fontWeight={300}>
                            {t('collaboration.endSlide.description')}
                        </Typography>

                        <Button
                            appearance='text'
                            disabled={!isActive || endingCollaboration}
                            leftIcon='download'
                            loading={generateSessionPdfLoading}
                            onClick={generateSessionPdf}
                        >
                            {t('collaboration.endSlide.action.download')}
                        </Button>

                        <Button
                            disabled={!isActive || generateSessionPdfLoading}
                            loading={endingCollaboration}
                            onClick={handleEndCollaboration}
                        >
                            {t('collaboration.endSlide.action.finish')}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default EndSlide;
