import React, { ElementType, FC, forwardRef, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { Typography } from '../../data-display/typography';
import { Menu } from '../../navigation/menu';

import {
    IconOption,
    IconProps,
    Icon,
    CardButtonDecorator,
    getRandomCardButtonDecorator,
} from 'components/base';
import { useDefaultColor } from 'style/theme';
import {
    MenuButton,
    Subheading,
    StyledContainer,
    StyledButton,
} from './styled';

export type CardButtonProps = Omit<
    ButtonProps,
    | 'action'
    | 'centerRipple'
    | 'classes'
    | 'color'
    | 'component'
    | 'disableElevation'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'endIcon'
    | 'focusRipple'
    | 'focusVisibleClassName'
    | 'LinkComponent'
    | 'onFocusVisible'
    | 'size'
    | 'startIcon'
    | 'TouchRippleProps'
    | 'touchRippleRef'
    | 'variant'
> &
    Partial<Pick<LinkProps, 'to' | 'state' | 'replace'>> & {
        component?: ElementType;
        /**
         * Italicized text at the bottom of the `CardButton`
         */
        caption?: string;
        /**
         * Background decorator
         */
        decorator?: CardButtonDecorator | 'random';
        /**
         * If `true` the `CardButton` will have slightly less padding than usual
         */
        dense?: boolean;
        /**
         * Main content
         */
        label: string;
        /**
         * Intended for `Menus` with `Menu.IconButton`
         */
        menuContent?: ReactNode;
        /**
         * If `true`, the subheading will be displayed in primary text color and two font sizes up
         */
        prominentSubheading?: boolean;
        /**
         * If `true`, content will be stacked and stacked
         */
        stacked?: boolean;
        /**
         * Intended for `Icons`
         */
        icon?: IconOption;
        IconProps?: Omit<IconProps, 'name'>;
        iconLabel?: string;
        /**
         * If content type is array, it will be joined by middots, otherwise it will be displayed as is.
         */
        subheading: string | CardButtonSubHeading[];
    };

export type CardButtonSubHeading = {
    text: string;
    uppercase?: boolean;
};

export const CardButton: FC<CardButtonProps> = forwardRef(
    (
        {
            caption,
            className,
            decorator: initDecorator,
            dense,
            fullWidth,
            icon,
            iconLabel,
            IconProps,
            label,
            menuContent,
            prominentSubheading,
            stacked,
            subheading,
            state,
            replace,
            ...rest
        },
        ref
    ) => {
        const theme = useTheme();
        const { t } = useTranslation();
        const { colorName } = useDefaultColor();

        const color = theme.palette.mode === 'dark' ? 'inherit' : 'primary';

        const decorator =
            initDecorator === 'random'
                ? getRandomCardButtonDecorator()
                : initDecorator;

        const iconProps = { color: colorName, ...(IconProps || {}) };

        return (
            <StyledContainer
                width={fullWidth ? 1 : 'fit-content'}
                className={className}
            >
                <StyledButton
                    color={color}
                    fullWidth={fullWidth}
                    variant='outlined'
                    className={classNames('card-button', {
                        'end-content': menuContent,
                        'start-content': icon,
                        dense,
                        stacked,
                        decorator,
                        [`decorator${decorator}`]: decorator,
                    })}
                    ref={ref}
                    {...(rest.to ? { component: Link, state, replace } : {})}
                    {...rest}
                >
                    <Stack
                        {...(stacked
                            ? { direction: 'column', spacing: 1 }
                            : { direction: 'row' })}
                        alignItems='center'
                    >
                        {icon && (
                            <Box
                                alignItems='center'
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                px={1}
                                width={stacked ? 1 : 88}
                            >
                                <Icon name={icon} {...iconProps} />

                                {iconLabel && (
                                    <Typography
                                        align='center'
                                        color={iconProps.color}
                                        fontSize={12}
                                        textTransform='uppercase'
                                    >
                                        {iconLabel}
                                    </Typography>
                                )}
                            </Box>
                        )}

                        <Stack flex={1}>
                            <Typography
                                variant='h3'
                                component='span'
                                lineHeight={1.2}
                                align={stacked ? 'center' : 'left'}
                            >
                                {label}
                            </Typography>

                            {subheading && (
                                <Subheading
                                    className={classNames({
                                        prominentSubheading,
                                    })}
                                    align={stacked ? 'center' : 'left'}
                                    mt={stacked ? 0.5 : 0}
                                >
                                    {typeof subheading === 'string'
                                        ? subheading
                                        : subheading.map(
                                              ({ uppercase, text }, index) => (
                                                  <span
                                                      key={index}
                                                      className={classNames({
                                                          uppercase,
                                                      })}
                                                  >
                                                      {text}
                                                  </span>
                                              )
                                          )}
                                </Subheading>
                            )}

                            {caption && (
                                <Typography
                                    fontStyle='italic'
                                    fontSize={14}
                                    color='text.secondary'
                                    mt={2}
                                    mb={-2}
                                >
                                    {caption}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </StyledButton>

                {menuContent && (
                    <Menu>
                        <MenuButton
                            aria-label={t('openMenu')}
                            icon='more_vert'
                            size='medium'
                        />

                        {menuContent}
                    </Menu>
                )}
            </StyledContainer>
        );
    }
);
