import produce, { Draft } from 'immer';
import { initialState } from './_initialState';
import {
    SET_CONVERSATION_SWIPER_POSITION,
    CLEAR_CONVERSATION_SWIPER_POSITIONS,
} from './_actionTypes';
import { ConversationSwiperPositionState } from './_types';
import { ConversationSwiperPosition } from '_types/conversation-swiper-position';

const swiperPositionsReducer = produce(
    (
        draft: Draft<ConversationSwiperPositionState> = initialState,
        action
    ): ConversationSwiperPositionState => {
        switch (action.type) {
            case SET_CONVERSATION_SWIPER_POSITION:
                const { key, page } =
                    action.payload as ConversationSwiperPosition;
                draft.conversationSwiperPositions[key] = page;
                break;
            case CLEAR_CONVERSATION_SWIPER_POSITIONS:
                draft.conversationSwiperPositions = [];
                break;
        }
        return draft;
    }
);

export default swiperPositionsReducer;
