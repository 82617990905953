import React, { ReactElement } from 'react';
import { Conversation } from '_types';
import { Tree } from 'utils/tree-utils';
import ConversationGroup from './conversation-group';

interface Props {
    tree: Tree<Conversation>;
    createCard: (conversation: Conversation) => ReactElement;
}

const renderNode = (
    node: Tree<Conversation>,
    createCard: (conversation: Conversation) => ReactElement,
    depth = 0
): React.ReactNode => {
    if (node.children.length > 0) {
        return (
            <ConversationGroup
                key={node.item.id}
                isSubgroup={depth !== 0}
                title={node.item.title || node.item.name}
            >
                {node.children.map((node) =>
                    renderNode(node, createCard, depth + 1)
                )}
            </ConversationGroup>
        );
    }

    return createCard(node.item);
};

const ConversationTree: React.FC<Props> = ({ tree, createCard }: Props) => {
    const leaves = tree.children.filter((node) => node.children.length === 0);
    const branches = tree.children.filter((node) => node.children.length > 0);

    return (
        <React.Fragment>
            {leaves.length > 0 && (
                <ConversationGroup isSubgroup={false} title=''>
                    {leaves.map((node) => renderNode(node, createCard))}
                </ConversationGroup>
            )}
            {branches.map((node) => renderNode(node, createCard))}
        </React.Fragment>
    );
};

export default ConversationTree;
