import * as React from 'react';

interface Props {
    name: string;
    children?: React.ReactNode;
    renderError?: (error: Error, errorInfo: React.ErrorInfo) => React.ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
    public static defaultProps: Props = {
        name: 'Error boundary',
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(
        error: Error,
        errorInfo: React.ErrorInfo
    ): State {
        return {
            hasError: true,
            error,
            errorInfo,
        };
    }

    public render(): React.ReactNode {
        const { error, errorInfo, hasError } = this.state;
        const { renderError, children } = this.props;

        if (hasError) {
            return renderError ? renderError(error!, errorInfo!) : null;
        }

        return children;
    }
}

export default ErrorBoundary;
