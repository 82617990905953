import React, { ElementType, FC, MouseEvent } from 'react';

import { Button, ButtonProps } from '../../buttons/button';

import useMenu from './use-menu';

export type MenuButtonProps = ButtonProps & {
    component?: ElementType<ButtonProps>;
};

export const MenuButton: FC<MenuButtonProps> = ({
    children,
    component: Component = Button,
    onClick,
    ...rest
}) => {
    const { onOpen } = useMenu();

    return (
        <Component
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                onClick && onClick(e);
                onOpen(e);
            }}
            {...rest}
        >
            {children}
        </Component>
    );
};
