import React from 'react';
import { Link } from 'components/link';
import { ReactComponent as IconFacebook } from './images/Facebook.svg';
import { ReactComponent as IconInstagram } from './images/Instagram.svg';
import { ReactComponent as IconLinkedin } from './images/Linkedin.svg';
import styles from './footer.module.scss';
import { RouteList } from 'api/routes';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import LanguageFooter from 'components/language-footer/language-footer';

interface Props {
    routes: RouteList;
    language: Language;
    parentPage?: string;
}

interface ViewCopy {
    linkFaq: string;
    linkPrivacy: string;
    linkTnc: string;
    copyright: string;
    facebookLink: string;
    instagramLink: string;
    linkedinLink: string;
}

const nbCopy: ViewCopy = {
    linkFaq: 'Ofte stilte spørsmål',
    linkPrivacy: 'Personvern',
    linkTnc: 'Vilkår for bruk',
    copyright: '© 2023 FuelBox AS',
    facebookLink: 'https://www.facebook.com/fuelboxnorge/',
    instagramLink: 'https://www.instagram.com/fuelboxnorge/?hl=en',
    linkedinLink:
        'https://www.linkedin.com/company/fuel-it-as/?originalSubdomain=no',
};

const enCopy: ViewCopy = {
    linkFaq: 'Frequently asked questions',
    linkPrivacy: 'Privacy policy',
    linkTnc: 'Terms of use',
    copyright: '© 2023 FuelBox AS',
    facebookLink: 'https://www.facebook.com/fuelboxnorge/',
    instagramLink: 'https://www.instagram.com/fuelboxnorge/?hl=en',
    linkedinLink:
        'https://www.linkedin.com/company/fuel-it-as/?originalSubdomain=no',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const Footer: React.FC<Props> = ({ routes, language, parentPage }: Props) => {
    const {
        linkFaq,
        linkPrivacy,
        linkTnc,
        copyright,
        facebookLink,
        instagramLink,
        linkedinLink,
    } = returnCopyForLanguage(language, copies);

    return (
        <footer className={styles.footer}>
            <section className={styles.links} aria-label='links'>
                <Link className={styles.link} to={routes.faq}>
                    {linkFaq}
                </Link>
                <Link className={styles.link} to={routes.privacyTerms}>
                    {linkPrivacy}
                </Link>
                <Link className={styles.link} to={routes.tnc}>
                    {linkTnc}
                </Link>
            </section>
            <section className={styles.social} aria-label='social-links'>
                <Link to={facebookLink} external={true}>
                    <IconFacebook title='facebook' />
                </Link>
                <Link to={instagramLink} external={true}>
                    <IconInstagram title='instagram' />
                </Link>
                <Link to={linkedinLink} external={true}>
                    <IconLinkedin title='linkedin' />
                </Link>
            </section>
            <p className={styles.copyright}>{copyright}</p>
            <LanguageFooter language={language} parentPage={parentPage} />
        </footer>
    );
};

export default Footer;
