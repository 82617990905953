import { routesNO, RouteList, routesEN } from 'api/routes';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { SEO } from 'components/seo';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MAIN_CATEGORY,
    ORGANIZATIONS_CATEGORY,
    pathOfBrowseCategory,
} from 'services/conversation-service';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    scrollPosSelectorProHomePage,
    setScrollPosProHomePage,
    languageSelector,
    setIsCreatingCompany,
} from 'store-modules/app-settings';
import {
    buildFlatTreeFromArray,
    FlatTree,
    FlatTreeNode,
} from 'utils/tree-utils';
import {
    Conversation,
    ConversationCategory,
    ConversationWithCategory,
    deckParentPages,
    User,
} from '_types';
import { ConversationsRow } from 'professional/components/conversations-row';
import { ConversationsRow as PremiumConversationsRow } from './../../../pages/home-page/conversations-row';
import styles from './pro-home.module.scss';

import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import proClient from 'api/pro-client';
import { proAccountSelector, setProAccount } from 'store-modules/professional';
import css from './pro-home.module.scss';
import {
    ConversationCard,
    ConversationCategoryCard,
    OrganizationCard,
} from 'professional/components/cards';
import {
    ConversationCard as PremiumConversationCard,
    ThemeCard,
} from './../../../components/conversation-card';
import OrganizationCategoryCard from 'professional/components/cards/organization-card/organization-category-card';
import userClient from 'api/user-client';
import {
    setBoldToastMessage,
    setToastMessage,
    setUserState,
    subscriptionSelector,
    toastMessageSelector,
} from 'store-modules/user';
import { useNavigate } from 'react-router-dom';
import useConversationSelector from 'hooks/use-conversation-selector';
import Decorator from 'components/conversation-card/decorator';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import { Button, Icon, IconButton } from 'components/base';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import companyClient, { CompanyData } from 'api/company-client';
import { IS_IOS_APP, IS_ANDROID_APP } from '../../../constants';
import { useWindowSize } from 'hooks';
import classnames from 'classnames';
import { getMainCategories } from 'pages/home-page/_services/home-page.service';
interface ViewCopy {
    pageTitle: string;
    mainCategoryTitle: string;
    newConversationsTitle: string;
    splashTitle: string;
    splashDescription1: string;
    splashDescription2: string;
    splashCodeLabel: string;
    copyCodeButtonLabel: string;
    linkCopyToast: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Hjem',
    mainCategoryTitle: 'Utløs det kollektive potensialet',
    newConversationsTitle: 'Nyeste samtaler',
    splashTitle: 'Velkommen til FuelBox',
    splashDescription1:
        'Inviter kollegene dine for å starte de gode samtalene!',
    splashDescription2:
        'Du kan administrere abonnementet og invitere brukere fra din konto',
    splashCodeLabel: 'Bedriftskode for deling av abonnement:',
    copyCodeButtonLabel: 'Kopier link med kode',
    linkCopyToast: 'Link kopiert!',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    mainCategoryTitle: 'Release the collective potential',
    newConversationsTitle: 'New conversations',
    splashTitle: 'Welcome to FuelBox',
    splashDescription1:
        'Invite your co-workers to start having great conversations!',
    splashDescription2:
        'You can administer the subscription and invite users from your account',
    splashCodeLabel: 'Company code:',
    copyCodeButtonLabel: 'Copy link with code',
    linkCopyToast: 'Link copied!',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

function childrenOfNode(
    tree: FlatTree<Conversation>,
    node: FlatTreeNode<Conversation>
): FlatTreeNode<Conversation>[] {
    return node.childrenIds.map((id) => tree.nodesById[id]);
}

function topicsOfNode(
    node: FlatTreeNode<Conversation>,
    tree: FlatTree<Conversation>
): Conversation[] {
    if (node.childrenIds.length === 0) return [node.item];

    return childrenOfNode(tree, node).flatMap((child) =>
        topicsOfNode(child, tree)
    );
}
interface Content {
    conversationsAreCategories: boolean;
    conversations: ConversationWithCategory[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    title: string;
    category: ConversationCategory;
}

const toConversationWithCategory = (
    conversation: Conversation,
    category: ConversationCategory
): ConversationWithCategory => ({ ...conversation, category: category });

function categoryTopics(
    categories: FlatTreeNode<Conversation>[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    categoryComponent: any,
    categoriesTitle: string,
    contentCategory: ConversationCategory
): Content | undefined {
    return {
        conversationsAreCategories: true,
        conversations: categories.map((categoryNode) =>
            toConversationWithCategory(categoryNode.item, categoryNode.item)
        ),
        component: categoryComponent,
        title: categoriesTitle,
        category: contentCategory,
    };
}

function conversationTopics(
    categories: FlatTreeNode<Conversation>[],
    tree: FlatTree<Conversation>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    conversationComponent: any,
    conversationCategory: ConversationCategory
): Content | undefined {
    const OrganizationCategoryName = getMainCategories(tree)[0].name;

    const conversations = categories.flatMap((category) =>
        topicsOfNode(category, tree).map((conversation) =>
            toConversationWithCategory(conversation, category.item)
        )
    );

    return {
        conversationsAreCategories: false,
        conversations: conversations,
        component: conversationComponent,
        title: OrganizationCategoryName,
        category: conversationCategory,
    };
}

function findContent(
    tree: FlatTree<Conversation>,
    parentNode: FlatTreeNode<Conversation>,
    categoriesTitle: string,
    contentCategory: ConversationCategory,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    conversationComponent: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    categoryComponent: any
): Content | undefined {
    const children = childrenOfNode(tree, parentNode);

    if (children.length === 0) return undefined;

    const hasCategories =
        children.filter((child) => child.childrenIds.length > 0).length > 1;

    return hasCategories
        ? categoryTopics(
              children,
              categoryComponent,
              categoriesTitle,
              contentCategory
          )
        : conversationTopics(children, tree, conversationComponent, {
              slug: parentNode.item.slug,
              name: parentNode.item.name,
          });
}

function findOrganizationContent(
    conversations: Conversation[]
): Content | undefined {
    const tree = buildFlatTreeFromArray(conversations);
    const organizationNodes = tree.nodesById[0].childrenIds.map(
        (rootNodeId) => tree.nodesById[rootNodeId]
    );
    const OrganizationCategoryName = getMainCategories(tree)[0]?.name;

    if (organizationNodes.length === 0) return undefined;

    return findContent(
        tree,
        organizationNodes[0],
        OrganizationCategoryName,
        ORGANIZATIONS_CATEGORY,
        OrganizationCard,
        OrganizationCategoryCard
    );
}

function findMainContent(
    conversations: Conversation[],
    categoriesTitle: string
): Content | undefined {
    const tree = buildFlatTreeFromArray(conversations);
    const rootNode = tree.nodesById[tree.rootId];

    return findContent(
        tree,
        rootNode,
        categoriesTitle,
        MAIN_CATEGORY,
        ConversationCard,
        ConversationCategoryCard
    );
}

function contentComponent(
    slidesId: number,
    topLevelSlug: string,
    content: Content,
    onCategorySelect: (topLevelSlug: string) => (deck: Conversation) => void,
    onConversationSelect: (deck: Conversation) => void
): ReactElement {
    return (
        <ConversationsRow
            slidesId={slidesId}
            className={styles.OrganizationRow}
            category={content.category}
            topLevelSlug={topLevelSlug}
            title={content.title}
            conversations={content.conversations}
            onClickConversation={
                content.conversationsAreCategories
                    ? onCategorySelect(topLevelSlug)
                    : onConversationSelect
            }
            cardComponent={content.component}
            useSwiper={!content.conversationsAreCategories}
        />
    );
}

type LoadContent = {
    themes: Conversation[];
    conversations: Conversation[];
    newlyAddedProConversations: ConversationWithCategory[];
};

const ProHomePage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        mainCategoryTitle,
        newConversationsTitle,
        splashTitle,
        splashDescription1,
        splashDescription2,
        splashCodeLabel,
        copyCodeButtonLabel,
        linkCopyToast,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const organization = useSelector(proAccountSelector);
    const scrollPosition = useSelector(scrollPosSelectorProHomePage);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const { selectProConversation } = useConversationSelector();
    const { isAndroid, isMobile, isiOS } = getUAInfo(window.navigator);
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const subscription = useSelector(subscriptionSelector);
    const [showNewOwnerSplash, setShowNewOwnerSplash] =
        useState<boolean>(false);
    const toastMessage = useSelector(toastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);
    const [company, setCompany] = useState<CompanyData>();

    useEffect(() => {
        if (user?.isCompanyAdmin) {
            dispatch(setIsCreatingCompany(false));
            companyClient.getCompany().then((company) => {
                setCompany(company);
            });
        }
    }, []);

    useEffect(() => {
        user &&
            company &&
            setShowNewOwnerSplash(
                company.has_seen_owner_splash !== 'yes' && user.isCompanyAdmin
            );
    }, [company, user]);

    useEffect(() => {
        return () => {
            setTimeout(() => setToastToShow(''), 3000);
        };
    });

    useEffect(() => {
        return () => {
            dispatch(setToastMessage(''));
            dispatch(setBoldToastMessage(''));
        };
    }, [dispatch]);

    const [content, setContent] = useState<LoadContent>({
        conversations: [],
        themes: [],
        newlyAddedProConversations: [],
    });

    const { conversations, themes } = content;

    const headerProps = {
        showLogoLink: false,
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async function getUser(): Promise<any> {
            await userClient.getCurrentUser().then((user) => {
                setUser(user);
                dispatch(setUserState(user!));
                return user;
            });
        }
        getUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async function getOrganization(user: User): Promise<any> {
            await proClient
                .getOrganization(user.organization)
                .then((organization) => {
                    dispatch(setProAccount(organization));
                });
        }

        if (
            (organization && user && organization.name !== user.organization) ||
            (!organization && user)
        ) {
            getOrganization(user);
        } else {
            if (organization && user) {
                Promise.all([
                    proClient.getOrgCategories(),
                    proClient.getProCategories(),
                    proClient.getNewlyAddedProConversations(),
                ])
                    .then((response) =>
                        setContent({
                            themes: response[0],
                            conversations: response[1],
                            newlyAddedProConversations: response[2],
                        })
                    )
                    .catch((e) => {
                        console.log('error', e);
                        navigate(routes.error)
                    })
                    .finally(() => setIsLoading(false));
            }
        }
    }, [dispatch, routes, organization, user]);

    useEffect(() => {
        if (scrollPosition)
            window.scrollTo({
                top: scrollPosition.y,
                left: scrollPosition.x,
            });
    }, [content, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    const toastMessageContainer = toastToShow.length > 0 && (
        <div className={styles.ToastContainer}>
            <ToastMessage
                className={
                    toastToShow.length > 0
                        ? classnames(styles.Toast, isiOS && styles.IOSToast)
                        : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
            />
        </div>
    );

    const hideSplash = () => {
        setShowNewOwnerSplash(false);
        companyClient.hideOwnerSplash().catch((error) => {
            console.error(error);
        });
    };

    const onConversationSelect = (deck: Conversation): void => {
        dispatch(setScrollPosProHomePage(getWindowScrollPosition(window)));
        selectProConversation(deck, language, deckParentPages.proHome);
    };

    const onCategorySelect =
        (topLevelSlug: string): ((deck: Conversation) => void) =>
        (deck: Conversation): void => {
            dispatch(setScrollPosProHomePage(getWindowScrollPosition(window)));
            navigate(pathOfBrowseCategory(language, deck.slug, topLevelSlug));
        };

    const header = !!organization?.logo?.url ? (
        <picture>
            <img
                src={organization.logo.url}
                className={css.OrganizationLogo}
                title={organization.name}
                alt={organization.name}
            />
        </picture>
    ) : (
        organization?.name.toUpperCase()
    );

    const organizationContent = findOrganizationContent(themes);

    const mainContent = findMainContent(conversations, mainCategoryTitle);

    const isThemeCard = (taxonomy: string) => {

        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {

        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex =
            content.newlyAddedProConversations?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    const decorator = new Decorator();
    const createConversationCard = (
        category: ConversationCategory,
        conversation: ConversationWithCategory
    ): ReactElement => {
        return isThemeCard(conversation.taxonomy) ||
            isOrganizationCard(conversation.taxonomy) ? (
            <ThemeCard
                key={conversation.id}
                conversation={conversation}
                className={conversationCardStyle(conversation)}
                onClick={(): void => onConversationSelect(conversation)}
            />
        ) : (
            <PremiumConversationCard
                conversation={conversation}
                onClick={() => onConversationSelect(conversation)}
                category={category}
                decorator={decorator}
                className={conversationCardStyle(conversation)}
            />
        );
    };

    const getURL = () => {
        const localhostURL =
            'http://localhost:3000' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const developURL =
            'https://develop.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const testURL =
            'https://test.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const stagingURL =
            'https://staging.app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;
        const prodURL =
            'https://app.fuelbox.no' +
            routes.createPremiumPro +
            '/' +
            company?.pro_code;

        const url = window.location.href;
        let urlToReturn = '';
        if (IS_IOS_APP || IS_ANDROID_APP) {
            urlToReturn += prodURL;
        } else if (url.indexOf('localhost:3000') !== -1) {
            return localhostURL;
        } else if (url.indexOf('develop.app') !== -1) {
            urlToReturn += developURL;
        } else if (url.indexOf('test.app') !== -1) {
            urlToReturn += testURL;
        } else if (url.indexOf('staging.app') !== -1) {
            urlToReturn += stagingURL;
        } else {
            urlToReturn += prodURL;
        }

        return urlToReturn;
    };

    return (
        <div>
            {toastMessageContainer}
            <Layout
                routes={routes}
                language={language}
                pageClassName={styles.ProHome}
                headerProps={headerProps}
            >
                <SEO title={pageTitle} />
                {showNewOwnerSplash && isDesktop && (
                    <div className={styles.NewOwnerContainer}>
                        <div className={styles.NewOwnerSplash}>
                            <div className={styles.LeftContainer}>
                                <div className={styles.NewOwnerImage} />
                            </div>

                            <div className={styles.RightContainer}>
                                <div
                                    className={styles.NewOwnerContentContainer}
                                >
                                    <p className={styles.NewOwnerHeader}>
                                        {splashTitle}
                                    </p>
                                    <p className={styles.NewOwnerDescription}>
                                        {splashDescription1}
                                    </p>
                                    <p className={styles.NewOwnerDescription}>
                                        {splashDescription2}
                                        {' ['}
                                        <Icon
                                            name={'person_outlined'}
                                            className={styles.PersonOutlined}
                                        ></Icon>
                                        {']'}
                                    </p>
                                    <div
                                        className={
                                            styles.NewOwnerLowerContainer
                                        }
                                    >
                                        <div
                                            className={
                                                styles.CompanyCodeContainer
                                            }
                                        >
                                            <p
                                                className={
                                                    styles.CompanyCodeLabel
                                                }
                                            >
                                                {splashCodeLabel}
                                            </p>
                                            <p className={styles.CompanyCode}>
                                                {company?.pro_code}
                                            </p>
                                        </div>
                                        <Button
                                            className={
                                                styles.CompanyCodeCopyButton
                                            }
                                            leftIcon='attachment'
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    getURL()
                                                );
                                                setToastToShow(linkCopyToast);
                                            }}
                                        >
                                            {copyCodeButtonLabel}
                                        </Button>
                                    </div>
                                </div>
                                <IconButton
                                    aria-label={'close modal'}
                                    className={styles.CloseButton}
                                    icon='close'
                                    onClick={() => {
                                        companyClient.hideOwnerSplash();
                                        setShowNewOwnerSplash(false);
                                        hideSplash();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {organization && (
                    <section className={css.LogoSection}>{header}</section>
                )}

                {showNewOwnerSplash && !isDesktop && (
                    <div className={styles.NewOwnerMobileContainer}>
                        <div className={styles.NewOwnerSplash}>
                            <div className={styles.TopContainer}>
                                <div className={styles.LeftContainer}>
                                    <p className={styles.SplashHeader}>
                                        {splashTitle}
                                    </p>
                                    <p className={styles.SplashDescription}>
                                        {splashDescription1}
                                    </p>
                                </div>
                                <div className={styles.MiddleContainer} />
                                <div className={styles.RightContainer}>
                                    <IconButton
                                        aria-label={'close modal'}
                                        className={styles.CloseButton}
                                        icon='close'
                                        onClick={() => hideSplash()}
                                    />
                                </div>
                            </div>
                            <div className={styles.BottomContainer}>
                                <p className={styles.CompanyCodeLabel}>
                                    {splashCodeLabel}
                                </p>
                                <p className={styles.CompanyCode}>
                                    {company?.pro_code}
                                </p>
                                <Button
                                    className={styles.CompanyCodeCopyButton}
                                    leftIcon='attachment'
                                    onClick={() => {
                                        navigator.clipboard.writeText(getURL());
                                        setToastToShow(linkCopyToast);
                                    }}
                                >
                                    {copyCodeButtonLabel}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <section className={styles.OuterContentContainer}>
                    <section className={styles.ContentContainer}>
                        {organizationContent &&
                            contentComponent(
                                -77777,
                                ORGANIZATIONS_CATEGORY.slug,
                                organizationContent,
                                onCategorySelect,
                                onConversationSelect
                            )}
                        {content.newlyAddedProConversations &&
                            !subscription?.data.generic_pro_content_disabled &&
                            content.newlyAddedProConversations.length > 5 && (
                                <PremiumConversationsRow
                                    slidesId={-99999}
                                    className={styles.OrganizationRow}
                                    category={null}
                                    title={newConversationsTitle}
                                    conversations={
                                        content.newlyAddedProConversations
                                    }
                                    createCard={createConversationCard}
                                    showAllComponent={ConversationCard}
                                    onClickShowAll={() => {
                                        dispatch(
                                            setScrollPosProHomePage(
                                                getWindowScrollPosition(window)
                                            )
                                        );
                                        navigate(
                                            pathOfBrowseCategory(
                                                language,
                                                '',
                                                language === 'nb'
                                                    ? 'nyeste_samtaler'
                                                    : 'new_conversations'
                                            )
                                        );
                                    }}
                                />
                            )}

                        {mainContent &&
                            contentComponent(
                                -88888,
                                MAIN_CATEGORY.slug,
                                mainContent,
                                onCategorySelect,
                                onConversationSelect
                            )}
                    </section>
                </section>
            </Layout>
        </div>
    );
};

export default ProHomePage;
