import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button, ButtonProps } from 'components/base';
import { useWindowSize } from 'hooks/use-window-size';
import styles from './question-swiper.module.scss';

const NextRandomButton: React.FC<Partial<ButtonProps>> = forwardRef(
    (_, ref) => {
        const { height } = useWindowSize(window);
        const { t } = useTranslation();

        return (
            <Button
                className={classnames(styles.nextRandomButton)}
                ref={ref}
                sx={{ position: 'fixed', top: height - 100 + 'px' }}
            >
                {t('random.pickNewCard')}
            </Button>
        );
    }
);

export default NextRandomButton;
