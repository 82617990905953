import React, { useState } from 'react';
import { Layout } from 'components/layout';
import { Form, Field } from 'react-final-form';
import { Container } from 'components/container';
import { InputRow } from 'components/inputs';
import { Button } from 'components/base';
import { PageTitle } from 'components/page-title';
import styles from './password-reset-page.module.scss';
import userClient from 'api/user-client';
import { ConfirmationPage } from 'pages/confirmation-page';
import { routesNO, RouteList, routesEN } from 'api/routes';
import authService from 'services/auth-service';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';

interface FormValues {
    email: string;
}

interface Props {}

interface ViewCopy {
    title: string;
    description: string;
    labelEmail: string;
    buttonSubmit: string;
    errorRequired: string;
    errorEmail: string;
    errorNonExistingEmail: string;
    confirmationTitle: string;
    confirmationDescription: string;
    confirmationBttonLabel: string;
}

const nbCopy: ViewCopy = {
    title: 'Glemt passord?',
    description:
        'Vi sender deg en epost med instruksjoner for hvordan du lager et nytt passord',
    labelEmail: 'Epostadresse',
    buttonSubmit: 'Send meg epost',
    errorRequired: 'Obligatorisk felt',
    errorEmail: 'Dette ser ikke ut som en fullstendig epostadresse',
    errorNonExistingEmail: 'Denne epostadressen er ikke registrert',
    confirmationTitle: 'Epost sendt',
    confirmationDescription:
        'Vi har sendt en epost med instruksjoner for hvordan du lager et nytt passord.',
    confirmationBttonLabel: 'Gå tilbake til logg inn',
};

const enCopy: ViewCopy = {
    title: 'Forgotten password?',
    description:
        'We will send you an email with instructions for creating a new password',
    labelEmail: 'Email address',
    buttonSubmit: 'Send me an email',
    errorRequired: 'Required field',
    errorEmail: "This doesn't look like a complete email address",
    errorNonExistingEmail: 'This email address is not registered',
    confirmationTitle: 'Email sent',
    confirmationDescription:
        'We have sent you an email with instructions for creating a new password.',
    confirmationBttonLabel: 'Go back to log in',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const PasswordResetPage: React.FC<Props> = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        title,
        description,
        labelEmail,
        buttonSubmit,
        errorRequired,
        errorEmail,
        errorNonExistingEmail,
        confirmationTitle,
        confirmationDescription,
        confirmationBttonLabel,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const confirmation = {
        title: confirmationTitle,
        description: confirmationDescription,
        buttonLabel: confirmationBttonLabel,
        onClickButton: (): void => navigate(routes.login),
        routes: routes,
        language: language,
    };
    const [showConfirmation, setShowConfirmation] = useState(false);

    const formSubmit = (values: FormValues) => {
        const isValidEmailPattern = authService.validateEmailFormat(
            values.email
        );
        const emailError =
            (!values.email && errorRequired) ||
            (!isValidEmailPattern && errorEmail);

        if (emailError) {
            return {
                email: emailError,
            };
        }

        return userClient
            .resetPassword(values.email)
            .then(() => {
                setShowConfirmation(true);
            })
            .catch((err) => {
                // ignoring backend error for 'user_not_found'
                if (err.code === 401) {
                    setShowConfirmation(true);
                    return;
                }
                if (err.data.status === 404) {
                    return {
                        email: errorNonExistingEmail,
                    };
                }

                throw err;
            })
            .catch(() => navigate(routes.error));
    };

    if (showConfirmation) {
        return <ConfirmationPage {...confirmation} />;
    }

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.passwordResetPage}
            headerProps={{
                showLogoLink: true,
            }}
        >
            <Container className={styles.container}>
                <PageTitle title={title} />
                <p className={styles.description}>{description}</p>
                <Form
                    onSubmit={formSubmit}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <Field
                                name='email'
                                type='text'
                                label={labelEmail}
                                component={InputRow}
                                className={styles.input}
                            />
                            <Button
                                type='submit'
                                appearance='cta'
                                fullWidth={true}
                            >
                                {buttonSubmit}
                            </Button>
                        </form>
                    )}
                />
            </Container>
        </Layout>
    );
};

export default PasswordResetPage;
