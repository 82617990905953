import * as React from 'react';
import { Link } from 'components/link';

const titleNO = 'Ofte stilte spørsmål';

const differenceBoxApp_NO = {
    title: 'Hva er forskjellen på innholdet i boksene og FuelBox-appen?',
    copy: (
        <React.Fragment>
            <p>
                Appen kompletterer FuelBox. I boksene finner du tidløse spørsmål
                fordelt på kategorier. Spørsmålene er nøye utvalgt for å være
                relevante og utviklende for den gjeldende relasjonen. Vi ser
                stor verdi i å bruke boksene, med sine fysiske FuelBox-kort, til
                å skape gode samtaler borte fra skjermene vi så ofte har foran
                oss.
            </p>
            <p>
                Appen er utviklet for å skape enda flere gode samtaler og gjøre
                de mer tilgjengelige – på jobb, på reise eller i friluft. I
                appen er spørsmålene samlet i samtalepakker spesielt tilpasset
                ulike relasjoner, situasjoner og på mange nye temaer. For
                eksempel finnes det samtaler for morsdagen, roadtrip med venner
                og mingling med nye bekjente. Vi tilbyr også abonnementet
                FuelBox Proff, hvor samtalepakkene er spesielt utviklet for å
                styrke relasjoner, kultur og innovasjonskraft i organisasjoner,
                og bidra til team- og lederutvikling. Fordelen med appen er at
                den blir jevnlig oppdatert med helt nye spørsmål og
                samtalepakker.
            </p>
        </React.Fragment>
    ),
};

const differenceFreePrem_NO = {
    title: 'Hva er forskjellen på gratisversjonen og Premium-abonnementet?',
    copy: (
        <p>
            I appen (eller på{' '}
            <Link to='https://app.fuelbox.no' external={true}>
                app.fuelbox.no
            </Link>
            ) finner du alltid et utvalg av samtaler som du kan prøve helt
            gratis, uten at du trenger å opprette bruker. Disse gir deg en smak
            av hva du får når du kjøper et Premium-abonnement med full tilgang.
            Med FuelBox Premium kan du velge blant et stort utvalg av samtaler
            som er skreddersydd til en hver relasjon og situasjon.
        </p>
    ),
};

const whatIsPremium_NO = {
    title: 'Hva er FuelBox Premium?',
    copy: (
        <React.Fragment>
            <p>
                Abonnementet FuelBox Premium gir deg full tilgang til alle
                samtalepakker som er tilpasset personlige relasjoner. Vårt
                spørsmålsbibliotek består av tusenvis av spørsmål, og i appen er
                disse satt sammen til temasamtaler og samtaler for en hver
                relasjon: kjæresten, familien, venner, de aller minste, barn,
                ungdom, kolleger og nye bekjente. Du får også tilgang til
                aktuelle samtalepakker i forbindelse med høytider og merkedager.
                Vi legger stadig til nye samtalepakker slik at du alltid har
                tilgang på nytt og spennende innhold.
            </p>
        </React.Fragment>
    ),
};

const whatIsPro_NO = {
    title: 'Hva er FuelBox Proff?',
    copy: (
        <React.Fragment>
            <p>
                Alle typer organisasjoner kan inngå en avtale om FuelBox
                Proff-abonnement. Gjennom FuelBox Proff får de tilknyttede
                brukerne tilgang til samtalepakker spesielt utviklet for å
                styrke relasjoner, kultur og innovasjonskraft i organisasjoner,
                og bidra til team- og lederutvikling. Dersom organisasjonen har
                skreddersydde spørsmål, kan også disse gjøres tilgjengelige i
                appen gjennom Proff-abonnementet. Sist, men ikke minst, får alle
                brukere med Proff-abonnement også full tilgang til alle
                samtalepakker i abonnementet FuelBox Premium. Disse samtalene er
                tilpasset personlige relasjoner. Et Proff-abonnement blir betalt
                av organisasjonen gjennom en fakturaavtale. For henvendelser
                angående FuelBox Proff, send en epost til{' '}
                <Link to='mailto:post@fuelbox.no' external={true}>
                    post@fuelbox.no
                </Link>
            </p>
        </React.Fragment>
    ),
    anchor: 'faq-pro',
};

const accessProhavePrem_NO = {
    title: 'Jeg har fått tilgang til et Proff-abonnement, men har allerede FuelBox Premium. Hva skjer nå?',
    copy: (
        <React.Fragment>
            <p>
                Hvis du knytter deg til et Proff-abonnement når du allerede har
                et Premium-abonnement, beholder du tilgangen til akkurat det
                samme innholdet som før, i tillegg til at du får tilgang til din
                organisasjons innhold. Da overtar organisasjonen betalingen for
                abonnementet ditt.
            </p>
            <p>
                Dersom du har Premium månedsabonnement, vil du altså ikke
                belastes for en ny måned. Dersom det er mindre enn seks måneder
                siden du kjøpte Premium årsabonnement og nå har fått tilgang til
                et Proff-abonnement, kan du kontakte oss på{' '}
                <Link to='mailto:post@fuelbox.no' external={true}></Link> for
                refusjon.
            </p>
            <p>
                Vi understreker at organisasjonen du er tilknyttet gjennom et
                Proff-abonnement ikke har tilgang til din brukshistorikk. Vi
                samler for øvrig ingen brukshistorikk som kan knyttes til
                individuelle brukere per dags dato.
            </p>
        </React.Fragment>
    ),
};
const whenBoxApp_NO = {
    title: 'Når bør jeg bruke appen og når bør jeg bruke boksen?',
    copy: (
        <React.Fragment>
            <p>
                Hovedformålet med FuelBox er å skape relasjonsbyggende samtaler.
                Boksen skal være et samlingspunkt i nære relasjoner. Derfor
                anbefaler vi at du bruker den fysiske boksen når du har den
                tilgjengelig. Det kan være lurt å la boksen stå synlig fremme
                hjemme eller på kontoret som en påminnelse til bruk.
            </p>

            <p>Appen egner seg godt</p>
            <ul>
                <li>når du ikke har boksen tilgjengelig.</li>
                <li>
                    når du ønsker å snakke om et helt spesifikt tema, som
                    bærekraft, sorg eller graviditet.
                </li>
                <li>
                    for skreddersydde samtaler til aktuelle høytider og
                    merkedager.
                </li>
                <li>
                    for engasjerende samtaler i sosiale sammenkomster som første
                    date eller mingling med nye bekjente.
                </li>
            </ul>
        </React.Fragment>
    ),
};

const lengthSub_NO = {
    title: 'Hvor lenge varer et abonnement?',
    copy: (
        <React.Fragment>
            <p>
                Abonnementet FuelBox Privat varer enten en måned (30 dager)
                eller ett år (365 dager). Det velger du selv. Innholdet i
                abonnementet er helt likt, enten du velger å betale måned for
                måned, eller for ett år om gangen. Abonnementet fornyes
                automatisk til du avslutter det.
            </p>
            <p>Abonnementet FuelBox Proff varer i ett år.</p>
        </React.Fragment>
    ),
};

const renewSub_NO = {
    title: 'Hvordan betaler jeg for abonnementet og hvordan fornyes det?',
    copy: (
        <React.Fragment>
            <p>
                Du kan betale med Visa eller MasterCard. Snart kan du også
                betale via Vipps og PayPal. Når du har kjøpt et abonnement,
                fornyes det automatisk helt til du avslutter abonnementet. Ditt
                registrerte betalingskort vil automatisk trekkes for de neste 30
                dagene ved kjøp av månedsabonnement og for de neste 365 dagene
                ved kjøp av årsabonnement. Hvis du har valgt årsabonnement, vil
                du motta et varsel 14 dager før abonnementet ditt fornyes for et
                nytt år.
            </p>
            <p>
                Vi setter opp egne fakturaavtaler med organisasjoner som ønsker
                å inngå avtale om abonnement på FuelBox Proff. Ved fakturaavtale
                vil abonnementet fornyes årlig og faktureres forskuddsvis for
                ett år av gangen.
            </p>
        </React.Fragment>
    ),
};

const whyDigitalSolution_NO = {
    title: 'Hvorfor har dere laget en digital løsning når dere tidligere har fokusert på behovet for å legge bort telefoner og skjermer?',
    copy: (
        <React.Fragment>
            <p>
                Vi mener fortsatt at det er viktig å ha et aktivt og bevisst
                forhold til teknologi. Vi bør alle sørge for å balansere det
                digitale med det analoge, eller ekte nærvær. Samtidig er vi
                opptatt av å lytte til dere – kundene våre. Dere forteller oss
                om et behov for flere spørsmål og etterlyser en mer mobil
                FuelBox som kan være med på tur og i friluft. Derfor gjør vi nå
                vårt spørsmålsunivers tilgjengelig gjennom en digital løsning.
            </p>
            <p>
                Appen skal ikke erstatte de fysiske boksene. Boksen vil fortsatt
                være vårt hovedprodukt. Vi ønsker fortsatt at boksene skal stå
                fremme og benyttes år etter år for å styrke og utvikle våre
                relasjoner. FuelBox-appen kompletterer boksene. Appen har til
                hensikt å bidra til enda mer nysgjerrighet og gi tilgang til
                enda flere, nye og annerledes samtaler! Den sørger for gode
                spørsmål når du ikke har boksen tilgjengelig – og et jevnt
                påfyll av helt nye spørsmål innen helt nye temaer.
            </p>
        </React.Fragment>
    ),
};

const regret_NO = {
    title: 'Hva skjer hvis jeg kjøper abonnement, men angrer?',
    copy: (
        <p>
            Du har angrerett i 14 dager etter kjøpet. Angrer du, returnerer du
            angrerettskjema som du finner i ordrebekreftelsen din, til{' '}
            <Link to='mailto:post@fuelbox.no' external={true}>
                post@fuelbox.no
            </Link>
            .
        </p>
    ),
};

const cancel_NO = {
    title: 'Hvordan avslutter jeg abonnementet?',
    copy: (
        <>
            <p>
                Du kan avslutte abonnementet på Min konto. Når du har avsluttet
                abonnementet, løper det ut perioden du har betalt for, før det
                avsluttes automatisk.
            </p>
            <p>
                Min konto vises dessverre ikke i appen for iPhone enda. Hvis du
                bruker iPhone-appen, må du derfor logge inn via nettleseren for
                å avslutte abonnementet ditt på Min Konto. Du kan logge inn via
                lenken{' '}
                <Link to='https://app.fuelbox.no/nb/web' external={true}>
                    https://app.fuelbox.no/nb/web
                </Link>
                . Vi jobber med en bedre løsning på dette.
            </p>
        </>
    ),
};

const changePass_NO = {
    title: 'Hvordan endrer jeg passordet mitt?',
    copy: (
        <p>
            For å endre passordet må du først logge ut. Gå så til «Logg inn» og
            velg «glemt passordet ditt» i stedet for å logge inn. Du får da
            tilsendt en lenke til din registrerte epostadresse for å lage nytt
            passord.
        </p>
    ),
};

const whichConversation_NO = {
    title: 'Hvilke samtaler bør jeg velge?',
    copy: (
        <p>
            Du kan velge samtaler basert på temaet du ønsker å snakke om, eller
            personen du ønsker å snakke med.
        </p>
    ),
};

const canShare_NO = {
    title: 'Kan jeg dele abonnementet mitt med andre?',
    copy: (
        <p>
            Et abonnement er til personlig og privat bruk. Ditt brukernavn og
            passord er personlig og skal ikke deles med andre.
        </p>
    ),
};

const whereQsComeFrom_NO = {
    title: 'Hvor kommer spørsmålene i FuelBox fra?',
    copy: (
        <p>
            Spørsmålene i FuelBox er utarbeidet av Fuel It AS. Mange av
            spørsmålene er utviklet i samarbeid med fagfolk og ledere i norsk
            næringsliv, psykologer, lærere og veiledere i skolen, og med
            profesjonelle organisasjoner innen helse og omsorg. Spørsmålene er
            åpne og positivt formulerte med den hensikt å skape gode – og
            viktige – samtaler. I FuelBox-appen vil du også finne samtalepakker
            fra personer som er kjent for sitt engasjement innen ulike temaer.
        </p>
    ),
};

const howToUse_NO = {
    title: 'Hvordan bruker jeg FuelBox?',
    copy: (
        <p>
            Fuelbox gir deg spørsmål for å skape samtale og refleksjon i
            forskjellige relasjoner, situasjoner og innen ulike temaer. Enten du
            har appen eller en fysisk boks, er det helt opp til deg hvordan du
            tar FuelBox i bruk. Enkelt forklart handler det om å trekke et
            spørsmål og stille det til den personen du vil snakke med. Det er
            ikke et mål å komme gjennom flest mulig spørsmål i FuelBox, men å ha
            mest mulig samtale og la alle i samtalen få reflektere og dele med
            hverandre. Derfor anbefaler vi å utforske hverandres svare med
            oppfølgingsspørsmål. Alle våre fysiske FuelBox-produkter kan
            bestilles på{' '}
            <Link to='https://fuelbox.no' external={true}>
                fuelbox.no
            </Link>
            .
        </p>
    ),
};

const help_NO = {
    title: 'Hva gjør jeg dersom jeg har innloggingsproblemer eller opplever feil på min konto?',
    copy: (
        <p>
            Dersom du opplever at abonnementet ditt ikke fungerer, eller du har
            spørsmål om funksjonalitet, kontakt oss på{' '}
            <Link to='mailto:post@fuelbox.no' external={true}>
                post@fuelbox.no
            </Link>
            .
        </p>
    ),
};

const titleEN = 'Frequently asked questions';

const differenceBoxApp_EN = {
    title: 'What is the difference between the physical boxes and the app?',
    copy: (
        <React.Fragment>
            <p>
                The app complements the physical FuelBox products. The boxes
                contain timeless questions divided into categories. All of the
                questions are carefully selected to be relevant for and further
                develop the relation between the participants. We appreciate the
                value of using the boxes, with their physical FuelBox cards, to
                create great conversations away from the screens that we so find
                ourselves in front of.
            </p>
            <p>
                The FuelBox app has been created with the purpose of sparking
                even more great conversations and make these more available – at
                work, during travels or outdoors. The questions in the app are
                grouped into conversations for particular relations, situations
                and topics. There are conversations for Mother’s Day, road trips
                with friends and for small talk with new acquaintances. We also
                offer the subscription FuelBox Pro, in which the conversations
                aim to strengthen relations, culture and innovation capabilities
                within organisations, and can be used as a tool in team building
                and leadership coaching. The advantage of the app is that it’s
                continuously updated with completely new questions and
                conversations.
            </p>
        </React.Fragment>
    ),
};

const differenceFreePrem_EN = {
    title: 'What is the difference between the free version and the Premium subscription?',
    copy: (
        <p>
            In the app (or on{' '}
            <Link to='https://app.fuelboxworld.com' external={true}>
                app.fuelboxworld.com
            </Link>
            ) you’ll find a selection of conversations to try out for free,
            without having to create an account. These conversations are meant
            as a taster of the Premium subscription. When you create an account
            and pay for full access to Premium, you get to choose between a
            large selection of conversations tailored to various relations and a
            range of situations.
        </p>
    ),
};

const whatIsPremium_EN = {
    title: 'What is the difference between the free version and the Premium subscription?',
    copy: (
        <React.Fragment>
            <p>
                The FuelBox Premium subscription gives you access to all of the
                conversations made for your personal relations. Our question
                library consists of thousands of questions. In the app, these
                are grouped into topical conversations and conversations for all
                types of relationships; partners, families, friends, preschool
                kids and older kids, teenagers, colleagues and new
                acquaintances. You also get access to conversations tailored to
                specific holidays and celebrations. We’re continuously adding
                new conversations so that you’ll never run out of things talk
                about.
            </p>
        </React.Fragment>
    ),
};

const whatIsPro_EN = {
    title: 'What is FuelBox Pro?',
    copy: (
        <React.Fragment>
            <p>
                Organisations of all kinds can subscribe to FuelBox Pro. The
                subscription gives the connected users access to conversations
                aimed at strengthening relations, culture and innovation
                capabilities within organisations, and can be used as a tool in
                team building and leadership coaching. If the organisation has
                custom-made FuelBox questions, these can also be made available
                through the Pro subscription. Last but not least, all users with
                a Pro subscription get access to all conversations in the
                subscription FuelBox Premium. These are conversations made for
                personal relations outside of work. The Pro subscription is paid
                for by the organisation through an invoice agreement. For
                inquiries about FuelBox Pro, send an email to{' '}
                <Link to='mailto:post@fuelboxworld.com' external={true}>
                    post@fuelboxworld.com
                </Link>
                .
            </p>
        </React.Fragment>
    ),
    anchor: 'faq-pro',
};

const accessProhavePrem_EN = {
    title: 'I have been given access to a Pro subscription, but I already have FuelBox Premium. What happens now?',
    copy: (
        <React.Fragment>
            <p>
                If you connect to a FuelBox Pro subscription when you are
                already subscribed to FuelBox Premium, you will get access to
                your organisation’s content while keeping your access to the
                content you already enjoy.
            </p>
            <p>
                In this case, the organisation you connect to will pay for your
                subscription going forward. That means that you will not be
                charged for a new month if you currently monthly for your
                FuelBox Premium subscription. If you have an annual Premium
                subscription and it has been less than six months since you
                bought or renewed your subscription, you can email us at{' '}
                <Link to='mailto:post@fuelboxworld.com' external={true}></Link>{' '}
                to claim a refund.
            </p>
            <p>
                Please note that the organisation that you connect to through a
                Pro subscription cannot access your usage history. We currently
                do not collect any individual usage history.
            </p>
        </React.Fragment>
    ),
};
const whenBoxApp_EN = {
    title: 'When should I use the app and when should I use the box?',
    copy: (
        <React.Fragment>
            <p>
                The main purpose of Fuelbox is to create conversations that
                strengthen relationships. We consider the box a meeting point to
                gather around with the people you care about. That’s why we
                recommend to use the physical FuelBox when you have it at hand.
                As a reminder, why not keep the box visible in your home or at
                the office?
            </p>
            <p>Use the FuelBox app when</p>
            <ul>
                <li>your don’t have the box at hand.</li>
                <li>
                    you want to talk about a specific topic, such as
                    sustainability, grief or pregnancy.
                </li>
                <li>
                    you want conversations tailored for specific holidays and
                    celebrations.
                </li>
                <li>
                    you’d like to have engaging conversations at social events,
                    such as a first date or a networking event.
                </li>
            </ul>
        </React.Fragment>
    ),
};

const lengthSub_EN = {
    title: 'How long do subscriptions last?',
    copy: (
        <React.Fragment>
            <p>
                You can choose a monthly (30 days) or annual (365 days)
                subscription to FuelBox Premium and you can cancel anytime. You
                get access to the same content regardless of which subscription
                you choose. Subscriptions are automatically renewed until you
                cancel.
            </p>
            <p>A subscription to FuelBox Pro lasts for one year.</p>
        </React.Fragment>
    ),
};

const renewSub_EN = {
    title: 'How do I pay for the subscription and how is it renewed?',
    copy: (
        <React.Fragment>
            <p>
                You can pay with Visa or MasterCard. Soon you’ll can also choose
                to pay with Vipps and PayPal. Once you have purchased a
                subscription, it will renew automatically until you cancel. Your
                registered payment card will be charged for the next 30 days
                when you purchase a monthly subscription and for the next 365
                day if you’ve chosen annual subscription. Annual subscribers
                receive a notification 14 days before the subscription renews.
            </p>
            <p>
                We set up invoice agreements with organisations that wish to
                subscribe to FuelBox Pro. The agreement will renew annually and
                invoiced in advanced for one year at a time.
            </p>
        </React.Fragment>
    ),
};

const whyDigitalSolution_EN = {
    title: 'Why have you made a digital solution when you have previously focused on putting aside phones and screens?',
    copy: (
        <React.Fragment>
            <p>
                We believe it’s important to have a conscious relationship with
                technology. We should all be careful to balance our virtual and
                physical presence. At the same time we think it’s important that
                we listen to our customers’ needs. You’ve asked us for more
                questions and requested a more mobile solution to take with you
                outside or bring along on travels. That’s why we’ve made the
                FuelBox question library available through a digital solution.
                Download the FuelBox app on your phone and bring it anywhere.
            </p>
            <p>
                The app is not intended to replace the physical boxes. The box
                is still our main product. We hope that you want to keep using
                the box year after year to strengthen your relationships with
                the people you care about. The FuelBox app complements the
                boxes. Our intention is for the app to contribute to more, new
                and different kids of conversations. The app equips you with
                great questions even when you don’t have the box at hand, and it
                provides you with a continuous refill of completely new
                questions and conversation topics.
            </p>
        </React.Fragment>
    ),
};

const regret_EN = {
    title: 'What if I have paid for a subscription, but regret my purchase?',
    copy: (
        <p>
            Your right to cancel the purchase starts the moment you buy a
            subscription and lasts until 14 days have passed. If you want to
            cancel your subscription, simply fill out the cancellation form
            attached to your order confirmation and return it to{' '}
            <Link to='mailto:post@fuelboxworld.com' external={true}>
                post@fuelboxworld.com
            </Link>
            .
        </p>
    ),
};

const cancel_EN = {
    title: 'How do I cancel my subscription?',
    copy: (
        <>
            <p>
                You can cancel your subscription in My Account. When you cancel
                your subscription, you’ll still have full access until the end
                of the subscription period you have paid for.
            </p>
            <p>
                Unfortunately, you cannot cancel your subscription in the iPhone
                app. If you’re using the iPhone app, you need to access My
                Account from your web browser. You can log in here:{' '}
                <Link to='https://app.fuelbox.no/en/web' external={true}>
                    https://app.fuelbox.no/en/web
                </Link>
                . We are working on an improved solution for this.
            </p>
        </>
    ),
};

const changePass_EN = {
    title: 'How do I change my password?',
    copy: (
        <p>
            To change your password, start by logging out. Then, go to “Log In”
            and select “Forgotten your password?”. You’ll receive an email with
            a link to reset your password.
        </p>
    ),
};

const whichConversation_EN = {
    title: 'Which conversations should I choose?',
    copy: (
        <p>
            You can select conversations based on the topic you’d like to talk
            about or the person you wish to talk to.
        </p>
    ),
};

const canShare_EN = {
    title: 'Can I share my subscription with others?',
    copy: (
        <p>
            A FuelBox subscription is for personal and private use only. Your
            username and password are private and should not be shared.
        </p>
    ),
};

const whereQsComeFrom_EN = {
    title: 'How are the FuelBox questions made?',
    copy: (
        <p>
            FuelBox gives you questions to spark conversation and reflection
            within relationships, situations and topics. Whether you’re using
            the app or a physical FuelBox, it’s up to you how you want to use
            it. Put simply, it’s all about picking a card and asking the
            question to the person(s) you want have a conversation with.
            Remember: it’s not about getting through as many questions as
            possible, but about having great conversations and give everyone
            involved the time to reflect and share with each other. That’s why
            we encourage you ask follow-up questions as you go, in order to
            exploring each other’s answers. You can find out more about FuelBox
            and order our products on fuelboxworld.com.
        </p>
    ),
};

const howToUse_EN = {
    title: 'How do I use FuelBox?',
    copy: (
        <p>
            FuelBox gives you questions to spark conversation and reflection
            within relationships, situations and topics. Whether you’re using
            the app or a physical FuelBox, it’s up to you how you want to use
            it. Put simply, it’s all about picking a card and asking the
            question to the person(s) you want have a conversation with.
            Remember: it’s not about getting through as many questions as
            possible, but about having great conversations and give everyone
            involved the time to reflect and share with each other. That’s why
            we encourage you ask follow-up questions as you go, in order to
            exploring each other’s answers. You can find out more about FuelBox
            and order our products on{' '}
            <Link to='https://fuelboxworld.com' external={true}>
                fuelboxworld.com
            </Link>
            .
        </p>
    ),
};

const help_EN = {
    title: 'I have problems with my account. How can I get help?',
    copy: (
        <p>
            If you’re having problems with your account or have a question for
            us, please email us at{' '}
            <Link to='mailto:post@fuelboxworld.com' external={true}>
                post@fuelboxworld.com
            </Link>
            . We’ll do our best to get back to you within 24 hours.
        </p>
    ),
};

export const copyNO = {
    title: titleNO,
    items: [
        differenceBoxApp_NO,
        differenceFreePrem_NO,
        whatIsPremium_NO,
        whatIsPro_NO,
        accessProhavePrem_NO,
        whenBoxApp_NO,
        lengthSub_NO,
        renewSub_NO,
        whyDigitalSolution_NO,
        regret_NO,
        cancel_NO,
        changePass_NO,
        whichConversation_NO,
        canShare_NO,
        whereQsComeFrom_NO,
        howToUse_NO,
        help_NO,
    ],
};

export const copyEN = {
    title: titleEN,
    items: [
        differenceBoxApp_EN,
        differenceFreePrem_EN,
        whatIsPremium_EN,
        whatIsPro_EN,
        accessProhavePrem_EN,
        whenBoxApp_EN,
        lengthSub_EN,
        renewSub_EN,
        whyDigitalSolution_EN,
        regret_EN,
        cancel_EN,
        changePass_EN,
        whichConversation_EN,
        canShare_EN,
        whereQsComeFrom_EN,
        howToUse_EN,
        help_EN,
    ],
};
