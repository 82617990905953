import React, { useEffect, useState } from 'react';
import styles from './account-page.module.scss';
import { InfoLinks } from './info-links';
import { Container } from 'components/container';
import { Button, Link } from 'components/base';
import { useWindowSize } from 'hooks';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { MenuItem } from './menuItem';
import { SubscriptionDetails } from './subscription-details';
import { PersonalDetails } from './personal-details';
import authService from 'services/auth-service';
import { Language } from '_types/language';
import { useSelector, useDispatch } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import AccountSettings from './account-settings/account-settings';
import {
    subscriptionSelector,
    toastMessageSelector,
    setToastMessage,
    userSelector,
    boldToastMessageSelector,
    setBoldToastMessage,
} from 'store-modules/user';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import { ToastMessage } from 'components/toast-message';
import { Layout } from 'components/layout';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ViewCopy {
    title: string;
    buttonLogoutLabel: string;
    titlePersonal: string;
    titleSubscription: string;
    titleFaq: string;
    titleAbout: string;
    titleSettings: string;
    inactiveSubNotification: string;
    confirmEmail: string;
}

const nbCopy: ViewCopy = {
    title: 'Min konto',
    buttonLogoutLabel: 'Logg ut',
    titlePersonal: 'Personopplysninger',
    titleSubscription: 'Abonnement',
    titleFaq: 'Ofte stilte spørsmål',
    titleAbout: 'Om FuelBox',
    titleSettings: 'Innstillinger',
    inactiveSubNotification: 'Ikke Aktivt',
    confirmEmail:
        'For å ta i bruk FuelBox, vennligst bekreft din konto ved å følge lenken vi har sendt til din e-postadresse.',
};

const enCopy: ViewCopy = {
    title: 'My Account',
    buttonLogoutLabel: 'Log out',
    titlePersonal: 'Personal Information',
    titleSubscription: 'Subscription',
    titleFaq: 'Frequently asked questions',
    titleAbout: 'About FuelBox',
    titleSettings: 'Settings',
    inactiveSubNotification: 'Inactive',
    confirmEmail:
        'To start using FuelBox, please confirm your account by clicking the link we sent to your email address.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

export const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AccountHome: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        buttonLogoutLabel,
        titlePersonal,
        titleSubscription,
        titleFaq,
        titleAbout,
        titleSettings,
        inactiveSubNotification,
        confirmEmail,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const user = useSelector(userSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toastMessage = useSelector(toastMessageSelector);
    const boldToastMessage = useSelector(boldToastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    const faqWithBackpath = {
        pathname: routes.faq,
        state: {
            backPath: routes.account.index,
        },
    };

    const toastMessageItem2 = toastToShow.length > 0 && (
        <div className={styles.ToastContainer}>
            <ToastMessage
                className={
                    toastToShow.length > 0
                        ? styles.RemovedQuestionToast
                        : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
                boldMessage={boldToastMessage}
            />
        </div>
    );

    const subscriotionNotification = !useSelector(subscriptionSelector)
        ? inactiveSubNotification
        : '';

    useEffect(() => {
        return () => {
            setTimeout(() => setToastToShow(''), 3000);
        };
    });

    useEffect(() => {
        return () => {
            dispatch(setToastMessage(''));
            dispatch(setBoldToastMessage(''));
        };
    }, [dispatch]);

    const deleteDate = new Date(user?.delete_date ? user.delete_date : '');
    const formattedDeleteDate = deleteDate.toLocaleDateString(
        language === 'en' ? 'en-EN' : 'nb-NO',
        {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }
    );

    return (
        <div>
            {toastMessageItem2}
            <Layout routes={routes} language={language}>
                <Container className={styles.container}>
                    <header className={styles.header}>
                        <h1 className={styles.title}>{title}</h1>
                        <Button
                            className={styles.button}
                            onClick={authService.logout}
                            size='small'
                        >
                            {buttonLogoutLabel}
                        </Button>
                    </header>

                    <section>
                        {!user?.isConfirmed &&
                            user?.organization &&
                            user?.organization.length > 1 && (
                                <section className={styles.ConfirmEmailText}>
                                    {confirmEmail}
                                </section>
                            )}
                        {isMobile && user?.delete_date && (
                            <div
                                className={classnames(
                                    styles.DeleteWarningContainer,
                                    styles.DeleteWarningContainerMobile
                                )}
                            >
                                <p className={styles.WarningText}>
                                    {t('userCancellationWarning1')}
                                </p>
                                <div
                                    className={classnames(
                                        styles.WarningText,
                                        styles.DeleteWarningText2
                                    )}
                                >
                                    {user?.delete_date && (
                                        <Trans
                                            i18nKey='userCancellationWarning2'
                                            values={{
                                                date: formattedDeleteDate,
                                            }}
                                        ></Trans>
                                    )}
                                </div>

                                <Link
                                    onClick={() => {
                                        navigate(
                                            `../${routes.account.recoverAccount}`
                                        );
                                    }}
                                    className={styles.UndoDeleteLink}
                                >
                                    {t('userCancellationRegret')}
                                </Link>
                            </div>
                        )}

                        <MenuItem
                            title={titlePersonal}
                            titleLink={
                                isMobile
                                    ? routes.account.changePersonal
                                    : undefined
                            }
                        >
                            <PersonalDetails />
                            {!user?.delete_date && user?.can_be_deleted && (
                                <Link
                                    onClick={() => {
                                        navigate(
                                            `../${routes.account.deleteAccount}`
                                        );
                                    }}
                                    className={classnames(
                                        styles.link,
                                        styles.DeleteMeLink
                                    )}
                                >
                                    {t('deleteAccount.deleteMyAccount')}
                                </Link>
                            )}
                            {!isMobile && !!user?.delete_date && (
                                <div className={styles.DeleteWarningContainer}>
                                    <div
                                        className={classnames(
                                            styles.WarningText,
                                            styles.DeleteWarningText2
                                        )}
                                    >
                                        {user?.delete_date && (
                                            <Trans
                                                i18nKey='userCancellationWarningDesktop'
                                                values={{
                                                    date: formattedDeleteDate,
                                                }}
                                            ></Trans>
                                        )}
                                    </div>

                                    <Link
                                        onClick={() => {
                                            navigate(
                                                `../${routes.account.recoverAccount}`
                                            );
                                        }}
                                        className={styles.UndoDeleteLink}
                                    >
                                        {t('userCancellationRegret')}
                                    </Link>
                                </div>
                            )}
                        </MenuItem>
                        {/* {isMobile &&
                        <MenuItem
                            title={titleChangePassword}
                            titleLink={routes.account.changePassword}
                        />
                    } */}
                        <MenuItem
                            title={titleSubscription}
                            className={styles.subscriptionDetails}
                            notification={subscriotionNotification}
                            titleLink={
                                isMobile
                                    ? routes.account.subscription
                                    : undefined
                            }
                        >
                            <SubscriptionDetails />
                        </MenuItem>
                        <MenuItem
                            title={titleSettings}
                            titleLink={
                                isMobile ? routes.account.settings : undefined
                            }
                        >
                            <AccountSettings
                                language={language}
                                routes={routes}
                            />
                        </MenuItem>
                        {isMobile && (
                            <MenuItem
                                title={titleFaq}
                                titleLink={faqWithBackpath}
                            />
                        )}
                        {isMobile && (
                            <MenuItem
                                title={titleAbout}
                                titleLink={routes.about}
                            />
                        )}
                        {!isMobile && <InfoLinks />}
                    </section>
                </Container>
            </Layout>
        </div>
    );
};

export default AccountHome;
