import { useContext } from 'react';

import SessionQuestionContext, {
    SessionQuestionContextValues,
} from './context';

const useSessionQuestion = (): SessionQuestionContextValues =>
    useContext(SessionQuestionContext);

export default useSessionQuestion;
