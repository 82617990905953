import React, { FC } from 'react';

import { Typography } from 'components/base';
import useSession from '../../use-session';
import { getQuestionDocumentRef } from 'utils/collaboration-utils';

import { useDocumentData } from 'hooks/firebase/use-document-data';
import { useTranslation } from 'react-i18next';
import styles from './empty-state.module.scss';

type EmptyStateProps = { questionId: number };

const EmptyState: FC<EmptyStateProps> = ({ questionId }) => {
    const { t } = useTranslation();
    const { collaborationId } = useSession();

    const questionRef = getQuestionDocumentRef(collaborationId, questionId);
    const questionDoc = useDocumentData(questionRef);
    const { display } = questionDoc ?? {};

    return display ? (
        <div>
            <Typography mt={2} color='text.secondary'>
                {t('joinCollaboration.emptyState', { context: display })}
            </Typography>
            {display === 'wordcloud' && (
                <div className={styles.wordCloudEmptyState2}>
                    <Typography color='text.secondary'>
                        {t('joinCollaboration.emptyState_wordcloud2')}
                    </Typography>
                </div>
            )}
        </div>
    ) : null;
};

export default EmptyState;
