import { RouteList, routesNO, routesEN } from 'api/routes';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './company-signup-page.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '_types/language';
import {
    isCreatingCompanySelector,
    languageSelector,
    setIsCreatingCompany,
} from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { FreemiumNavbar } from 'components/freemium-navbar';
import CompanySignupFormUser from './company-signup-form-user/company-signup-form-user';
import CompanySignupFormCompany from './company-signup-form-company/company-signup-form-company';
import CompanySignupFormSubscription from './company-signup-form-user-subscription/company-signup-form-subscription';
import {
    CompanySignupFormValues,
    UserRegistrationLinkData,
    setUserState,
    setUserSub,
    userSelector,
} from 'store-modules/user';
import { useNavigate } from 'react-router-dom';
import { createUser } from 'pages/create-premium-page/_services';
import { AccountInfo } from 'pages/create-premium-page/_types';
import { LoginStatus, logUserIn } from 'common-logic/login';
import { setProAccount } from 'store-modules/professional';
import companyClient, { CompanyData } from 'api/company-client';
import userClient from 'api/user-client';
import { Trans } from 'react-i18next';
import useTypedLocation from 'hooks/use-typed-location';
import { useWindowSize } from 'hooks';
import { HeaderProps } from 'components/page-header';
import authService from 'services/auth-service';
import { Loading } from 'components/loading';
import { IS_IOS_APP } from '../../constants';
import classnames from 'classnames';
import { authenticatedSelector } from 'store-modules/auth';
import proClient from 'api/pro-client';
import subscriptionClient from 'api/subscription-client';
import {
    StorageKeys,
    getUserRegistrationData,
    setLocalStorageData,
} from 'models/storage';

interface ViewCopy {
    pageTitle: string;
    buyProButtonLabel: string;
    yourPriceLabel: string;
    numberOfUsersLabel: string;
    currencyLabel: string;
    yearLabel: string;
    registrationLinkSent: string;
    emailVerified: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Fuelbox',
    buyProButtonLabel: 'FuelBox for bedrifter',
    yourPriceLabel: 'Din pris',
    numberOfUsersLabel: 'Antall brukere',
    currencyLabel: 'NOK',
    yearLabel: 'år',
    registrationLinkSent: 'Registreringslenken ble sendt til din e-post.',
    emailVerified: 'Din e-post er verifisert',
};

const enCopy: ViewCopy = {
    pageTitle: 'Fuelbox',
    buyProButtonLabel: 'FuelBox for companies',
    yourPriceLabel: 'Your price',
    numberOfUsersLabel: 'Number of users',
    currencyLabel: 'NOK',
    yearLabel: 'year',
    registrationLinkSent: 'The registration link has been sent to your email.',
    emailVerified: 'Your email is verified',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface LocationState {
    numberOfUsers: number;
}

const CompanySignupPage: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const location = useTypedLocation<LocationState>();
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const authenticated = useSelector(authenticatedSelector);
    const isCreatingCompany = useSelector(isCreatingCompanySelector);
    const navigate = useNavigate();
    // const [user, setUser] = useState<User>();
    const user = useSelector(userSelector);
    const dispatch = useDispatch();
    const {
        pageTitle,
        yourPriceLabel,
        numberOfUsersLabel,
        currencyLabel,
        yearLabel,
        registrationLinkSent,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const localStorageNumberOfUsers = Number(
        localStorage.getItem('NumberOfBusinessUsers')
    );
    const [numberOfUsers, setNumberOfUsers] = useState(
        localStorageNumberOfUsers > 0
            ? Number(localStorage.getItem('NumberOfBusinessUsers'))
            : location?.state?.numberOfUsers
            ? location.state.numberOfUsers
            : 10
    );
    const [activeContainer, setActiveContainer] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(isCreatingCompany);
    const [pageFormValues, setPageFormValues] =
        useState<CompanySignupFormValues>({});
    const defaultPageLayoutHeight = window.screen.height;
    const [outerPageContainerHeight, setOuterPageContainerHeight] = useState(
        defaultPageLayoutHeight * 1.5
    );
    const [numberOfUsersError, setNumberOfUsersError] =
        useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [priceList, setPriceList] = useState<any>();
    const [registrationLinkMessage, setRegistrationLinkMessage] =
        useState<string | undefined>(undefined);
    const [emailValidationExpected, setEmailValidationExpected] =
        useState<boolean>(false);
    const [verifiedUser, setVerifiedUser] =
        useState<boolean | undefined>(undefined);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    };

    useEffect(() => {
        scrollToTop();
        user && setActiveContainer(2);
    }, []);

    useEffect(function checkIfEmailVerificationOnMount() {
        const userRegistrationData = getUserRegistrationData(
            StorageKeys.userRegistration
        );
        if (userRegistrationData && userRegistrationData.verifiedAt) {
            setPageFormValues({
                userEmail: userRegistrationData.email,
                userFirstName: userRegistrationData.firstName,
                userLastName: userRegistrationData.lastName,
                billingEmail: userRegistrationData.email,
            });
            setVerifiedUser(true);
        }
    }, []);

    useEffect(
        function toastDisplayTimer() {
            if (!registrationLinkMessage) return;

            const timer = setTimeout(() => {
                setRegistrationLinkMessage(undefined);
            }, 10 * 1000);

            return () => clearTimeout(timer);
        },
        [registrationLinkMessage]
    );

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setNumberOfUsers(Number(event.target.value));
            localStorage.setItem('NumberOfBusinessUsers', event.target.value);
        },
        []
    );

    const updatePageHeight = () => {
        setOuterPageContainerHeight((defaultPageLayoutHeight + scrollY) * 1.5);
    };

    const userLogin = async (
        userAccountInfo: AccountInfo,
        formValues: CompanySignupFormValues
    ) => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            await authService.login(token);
        } else {
            if (formValues.owner) {
                await logUserIn(
                    userAccountInfo.email,
                    userAccountInfo.password,
                    (organization) => setProAccount(organization),
                    true
                ).catch(async (loginResult) => {
                    if (loginResult.loginStaus !== LoginStatus.Ok) {
                        console.error(loginResult);
                    }
                });
            } else {
                await createUser(userAccountInfo)
                    .catch((error) => {
                        console.error(error);
                    })
                    .then(async () => {
                        await logUserIn(
                            userAccountInfo.email,
                            userAccountInfo.password,
                            (organization) => {
                                setProAccount(organization);
                            },
                            true
                        ).catch(async (loginResult) => {
                            if (loginResult.loginStatus !== LoginStatus.Ok) {
                                console.error(loginResult);
                            }
                        });
                    });
            }
        }
    };

    const processForm = async (formValues: CompanySignupFormValues) => {
        dispatch(setIsCreatingCompany(true));
        setIsLoading(true);

        if (numberOfUsers <= 0) {
            setNumberOfUsersError(true);
            return;
        }

        const userAccountInfo: AccountInfo = {
            email: formValues.userEmail!,
            firstName: formValues.userFirstName!,
            lastName: formValues.userLastName!,
            password: formValues.userPassword!,
        };
        //Login User - IF NOT ALREADY LOGGED IN
        !authenticated && (await userLogin(userAccountInfo, formValues));

        userClient.getCurrentUser().then(async (user) => {
            const companyToCreate: CompanyData = {
                owner: user.id,
                name: formValues.companyName!,
                business_registration_number: formValues.organizationNumber!,
                address: formValues.address!,
                postal_code: formValues.postCode!,
                city: formValues.city!,
                country: formValues.country!,
                billing_email: formValues.billingEmail!,
                purchase_referance_number: formValues.referenceNumber,
                email_domains: formValues.domains,
                azure_login: formValues.azureLogin === 'yes',
                logo: formValues.image,
                number_of_users: numberOfUsers,
                business_language: language,
                is_school: formValues.isSchool!,
                pro_code: '',
                has_seen_owner_splash: 'no',
            };
            companyClient
                .createCompany(companyToCreate)
                .then(async () => {
                    if (user.isConfirmed) {
                        userClient.getCurrentUser().then((user) => {
                            dispatch(setUserState(user!));
                            proClient
                                .getOrganization(user.organization)
                                .then((organization) => {
                                    dispatch(setProAccount(organization));
                                    subscriptionClient
                                        .getActiveSubscription()
                                        .then((sub) => {
                                            dispatch(setUserSub(sub!));
                                            navigate(routes.proindex);
                                        });
                                });
                        });
                    } else {
                        dispatch(setUserState(user!));
                        navigate(routes.login, {
                            replace: true,
                            state: { redirectedFromConfirmation: true },
                        });
                        //  reloads the lagin page force login of the user
                        navigate(0);
                    }
                })
                .catch((e) => {
                    console.error('Could not register company', e);
                    navigate(routes.error);
                });
        });
    };

    const handleRegistrationLink = (values: UserRegistrationLinkData) => {
        setLocalStorageData(StorageKeys.userRegistration, values);
        setRegistrationLinkMessage(registrationLinkSent);
        setEmailValidationExpected(true);
    };

    if (!priceList) {
        companyClient.getCompanyPriceIntervals().then(setPriceList);
        return <Loading />;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPriceObject = (): any => {
        for (let i = 0; i < priceList.length; i++) {
            const priceObject = priceList[i];
            if (!priceObject.max_users) {
                return priceObject;
            }
            if (
                numberOfUsers >= priceObject.min_users &&
                numberOfUsers <= priceObject.max_users
            ) {
                return priceObject;
            }
        }
        //Should never reach here if BE endpoint is correct.
        return 0;
    };

    const calculateFullPrice = (): number => {
        return numberOfUsers * priceList[0].price_per_user;
    };

    const numberOfUsersToDisplay = (): number | string => {
        return numberOfUsers < 1 ? '' : parseInt(numberOfUsers.toString());
    };

    const headerProps = (): HeaderProps => {
        if (user) {
            return {
                withLogin: true,
                showLogoLink: true,
            };
        }
        return {
            customNavigation: FreemiumNavbar,
            withLogin: true,
            showLogoLink: true,
        };
    };

    if (isLoading) return <Loading />;

    return (
        <div
            className={styles.OuterPageContainer}
            style={{ height: outerPageContainerHeight }}
        >
            <Layout
                routes={routes}
                language={language}
                pageClassName={classnames(
                    styles.PageLayout,
                    IS_IOS_APP && styles.IosPageLayout
                )}
                headerProps={headerProps()}
                withFooter={false}
            >
                <SEO title={pageTitle} />
                <div className={styles.bg} />
                <Container className={styles.ContentContainer}>
                    <div className={styles.LeftOuterContainer}>
                        <CompanySignupFormUser
                            formValues={pageFormValues}
                            activeContainer={activeContainer}
                            nextHandler={(input) => {
                                const updatedFormValues = {
                                    ...pageFormValues,
                                    ...input,
                                };
                                setPageFormValues(updatedFormValues);
                                setActiveContainer(2);
                                updatePageHeight();
                            }}
                            editHandler={() => {
                                setActiveContainer(1);
                            }}
                            setActiveContainer={(input) =>
                                setActiveContainer(input)
                            }
                            onRegistrationLinkGenerated={handleRegistrationLink}
                            emailValidationExpected={emailValidationExpected}
                            verifiedUser={verifiedUser}
                            toastMessage={registrationLinkMessage}
                        />
                        <CompanySignupFormCompany
                            formValues={pageFormValues}
                            activeContainer={activeContainer}
                            nextHandler={(input) => {
                                const updatedFormValues = {
                                    ...pageFormValues,
                                    ...input,
                                };
                                setPageFormValues(updatedFormValues);
                                setActiveContainer(3);
                                updatePageHeight();
                            }}
                            editHandler={() => {
                                setActiveContainer(2);
                            }}
                        />
                        <CompanySignupFormSubscription
                            priceList={priceList}
                            formValues={pageFormValues}
                            numberOfUsers={numberOfUsers}
                            activeContainer={activeContainer}
                            nextHandler={(input) => {
                                const updatedFormValues = {
                                    ...pageFormValues,
                                    ...input,
                                };

                                setPageFormValues(updatedFormValues);
                                processForm(updatedFormValues);
                            }}
                            settingImage={() => updatePageHeight()}
                            verifiedUser={verifiedUser}
                        />
                    </div>
                    {isDesktop && (
                        <div className={styles.RightOuterContainer}>
                            <h3 className={styles.YourPriceHeader}>
                                {yourPriceLabel}
                            </h3>
                            <div className={styles.InputContainer}>
                                <div className={styles.NumberOfUsersInputLabel}>
                                    {numberOfUsersLabel}
                                </div>
                                <input
                                    className={
                                        numberOfUsersError
                                            ? styles.NumberOfUsersError
                                            : ''
                                    }
                                    type='number'
                                    value={numberOfUsersToDisplay()}
                                    onChange={onInputChanged}
                                    pattern='[0-9]*'
                                    min={1}
                                    maxLength={7}
                                    max={7}
                                />
                            </div>
                            <div className={styles.DiscountText}>
                                <Trans
                                    components={[<strong key={0} />]}
                                    i18nKey='companySelfSignupPage.discountText'
                                    values={{
                                        discount: getPriceObject().discount,
                                    }}
                                />
                            </div>
                            <div className={styles.PriceBorder}>{''}</div>
                            <div className={styles.PriceContainer}>
                                <div className={styles.PriceCurrencyContainer}>
                                    <div className={styles.Price}>
                                        {getPriceObject().price_per_user *
                                            numberOfUsers}
                                    </div>
                                    <div className={styles.PriceCurrency}>
                                        {' '}
                                        {currencyLabel}/{yearLabel}
                                    </div>
                                </div>
                                <div className={styles.DiscountPriceText}>
                                    <div className={styles.FullPrice}>
                                        {calculateFullPrice()} {currencyLabel} /{' '}
                                        {yearLabel}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Container>
            </Layout>
        </div>
    );
};

export default CompanySignupPage;
