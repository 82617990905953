import { RootReducer } from 'store/store';
import { MyConversationsState } from './_types';
import { ConversationWithCategory, UserConversationTaxonomy } from '_types';

const myConversationsStateFromRoot = (
    state: RootReducer
): MyConversationsState => state.myConversations;

export const myConversationsSelector = (
    state: RootReducer
): ConversationWithCategory[] | null => {
    const myConversations = myConversationsStateFromRoot(state).myConversations;
    return !!myConversations
        ? myConversations.map((q) => {
              return {
                  ...q,
                  taxonomy: q.created_by_id
                      ? state.settings.language === 'en'
                          ? UserConversationTaxonomy.en
                          : UserConversationTaxonomy.nb
                      : q.taxonomy,
              };
          })
        : null;
};
