import React from 'react';
import styles from './end-slide.module.scss';
import { Container } from 'components/container';
import { Button, Stack } from 'components/base';
import { Link } from 'components/link';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { SwiperSlide } from '../swiper-slide';
import { Slide } from '_types';
import { useSelector } from 'react-redux';
import { deviceSelector, languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';

interface Props {
    slide: Slide;
    isActive: boolean;
    isRandomQuestions: boolean;
}
interface ViewCopy {
    title: string;
    description: string;
    buttonLabel: string;
    linkText: string;
}

const nbCopy: ViewCopy = {
    title: 'Der var det tomt!',
    description:
        'Få tilgang til tusenvis av spørsmål og få fart på den gode samtalen når og hvor som helst.',
    buttonLabel: 'Få tilgang til flere samtaler',
    linkText: 'Prøv en annen gratis samtale',
};

const enCopy: ViewCopy = {
    title: "That's it!",
    description:
        'Get access to thousands of questions and have great conversations when and where you like.',
    buttonLabel: 'Get access to more conversations',
    linkText: 'Try another free conversation',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const FreemiumEndSlide: React.FC<Props> = ({ isActive }) => {
    const language: Language = useSelector(languageSelector);
    const { title, description, buttonLabel, linkText } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    const device = useSelector(deviceSelector);

    return (
        <SwiperSlide>
            <Container className={styles.container} size='small'>
                <PageTitle title={title} />

                <Stack spacing={5} alignItems='center'>
                    {device === 'browser' && (
                        <>
                            <p className={styles.description}>{description}</p>

                            <Button
                                disabled={!isActive}
                                to={routes.createPremium}
                            >
                                {buttonLabel}
                            </Button>
                        </>
                    )}

                    <Link
                        to={routes.index}
                        className={styles.link}
                        tabIndex={isActive ? 0 : -1}
                    >
                        {linkText}
                    </Link>
                </Stack>
            </Container>
        </SwiperSlide>
    );
};

export default FreemiumEndSlide;
