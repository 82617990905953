import React, { FC, useState, ReactNode } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperInstance, { FreeMode, Mousewheel, Pagination } from 'swiper';

import styles from './conversation-swiper.module.scss';
import { Container } from 'components/container';
import NavigationButton from './navigation-button';
import {
    conversationSwiperPositionSelector,
    setConversationSwiperPosition,
} from 'store-modules/conversation-swiper-positions';
import { useTheme } from 'components/base';

export type ConversationSlideListItem = {
    id: string | number;
    content: ReactNode;
};

type ConversationSwiperProps = {
    noSwiping?: boolean;
    showNavigation: boolean;
    slideList: ConversationSlideListItem[];
    slidesId: number;
    slidesPerGroup: number;
};

const ConversationSwiper: FC<ConversationSwiperProps> = ({
    slidesId,
    slideList,
    slidesPerGroup,
    showNavigation,
    noSwiping = false,
}) => {
    const dispatch = useDispatch();
    const positions = useSelector(
        conversationSwiperPositionSelector,
        shallowEqual
    );

    const [activeGroup, setActiveGroup] = useState(
        (positions[slidesId] ?? 0) / slidesPerGroup
    );
    const [swiper, setSwiper] = useState<SwiperInstance>();

    const totalSlides = slideList.length;
    const totalGroups =
        slidesPerGroup > 0 ? Math.ceil(totalSlides / slidesPerGroup) : 0;

    const showLeftNavigation = showNavigation && activeGroup > 0;
    const showRightNavigation = showNavigation && activeGroup < totalGroups - 1;

    const onClickNext = () => slidesPerGroup && swiper && swiper.slideNext();
    const onClickPrev = () => slidesPerGroup && swiper && swiper.slidePrev();

    const theme = useTheme();

    const handleIndexChange = (s: SwiperInstance) => {
        dispatch(setConversationSwiperPosition(slidesId, s.realIndex));
        setActiveGroup(Math.ceil((s.realIndex ?? 0) / slidesPerGroup));
        setSwiper(s);
    };

    return (
        <Container className={styles.container}>
            {showLeftNavigation && (
                <NavigationButton
                    activeDot={activeGroup}
                    direction='left'
                    dotCount={totalGroups}
                    onClick={onClickPrev}
                />
            )}

            <Swiper
                breakpoints={{
                    [theme.breakpoints.values.md]: {
                        freeMode: false,
                        speed: 500,
                    },
                }}
                className={styles.swiperContainer}
                freeMode
                initialSlide={positions[slidesId]}
                modules={[FreeMode, Mousewheel, Pagination]}
                mousewheel={{ forceToAxis: true }}
                noSwiping={noSwiping}
                onInit={setSwiper}
                onRealIndexChange={handleIndexChange}
                slidesPerGroup={slidesPerGroup}
                slidesPerView='auto'
            >
                {slideList.map((slide) => (
                    <SwiperSlide className={styles.slide} key={slide.id}>
                        {slide.content}
                    </SwiperSlide>
                ))}
            </Swiper>

            {showRightNavigation && (
                <NavigationButton
                    activeDot={activeGroup}
                    direction='right'
                    dotCount={totalGroups}
                    onClick={onClickNext}
                />
            )}
        </Container>
    );
};

export default ConversationSwiper;
