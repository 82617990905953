import React, { useState } from 'react';
import styles from './edit-conversation-color-and-text.module.scss';
import { Language } from '_types/language';
import { Loading } from 'components/loading';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { SEO } from 'components/seo';
import { ConversationWithCategory, deckParentPages, Question } from '_types';
import { BackButton } from 'components/back-button';
import { ReactComponent as IconCheckBlue } from 'images/icons/check-blue.svg';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import conversationClient, { MyConversation } from 'api/conversation-client';
import { Button } from 'components/base';
import { ConversationCard } from 'components/conversation-card';
import { setMyConversations } from 'store-modules/my-conversations';
import { useNavigate } from 'react-router-dom';
import useHistoryRouter from 'hooks/use-history-router';
import useTypedLocation from 'hooks/use-typed-location';
import useConversationSelector from 'hooks/use-conversation-selector';

interface LocationState {
    deck: ConversationWithCategory;
    questions: Question[];
}

interface ViewCopy {
    editNameAndDescriptionTitle: string;
    nameFieldLabel: string;
    descriptionFieldLabel: string;
    saveChangesLabel: string;
    byText: string;
    titleErrorText: string;
}

const nbCopy: ViewCopy = {
    editNameAndDescriptionTitle: 'Endre farge, navn og beskrivelse',
    nameFieldLabel: 'Navn',
    descriptionFieldLabel: 'Beskrivelse',
    saveChangesLabel: 'Lagre endringer',
    byText: 'av',
    titleErrorText: 'Gi samtalen en tittel',
};

const enCopy: ViewCopy = {
    editNameAndDescriptionTitle: 'Edit colour, name, and description',
    nameFieldLabel: 'Name',
    descriptionFieldLabel: 'Description',
    saveChangesLabel: 'Save changes',
    byText: 'by',
    titleErrorText: 'Give the conversation a title',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const maxDescriptionLength = 64;
const maxTitleLength = 42;

const EditConversationColorAndTexts: React.FC = () => {
    const navigate = useNavigate();
    const { language } = useHistoryRouter();
    const location = useTypedLocation<LocationState>();
    const { selectConversation } = useConversationSelector();

    const {
        editNameAndDescriptionTitle,
        nameFieldLabel,
        descriptionFieldLabel,
        saveChangesLabel,
        byText,
        titleErrorText,
    } = returnCopyForLanguage(language, copies);

    const routes = returnRoutesForLanguage(language, routeObj);
    const [titleInput, setTitleInput] = useState<string>(
        location.state.deck?.name
    );
    const [descriptionInput, setDescriptionInput] = useState<string>(
        location.state.deck?.description
    );
    const [invalidTitleInput, setInvalidTitleInput] = useState<boolean>(false);
    const [invalidDescriptionInput, setInvalidDescriptionInput] =
        useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);
    const [conversationToDisplayAndSave, setConversationToDisplayAndSave] =
        useState<ConversationWithCategory>(location.state.deck);
    const dispatch = useDispatch();

    const onExit = () => {
        selectConversation(
            location.state.deck,
            language,
            deckParentPages.myLibrary
        );
    };

    const onSaveConversation = () => {
        setIsLoading(true);
        //Validate input
        if (titleInput.length < 1) {
            setInvalidTitleInput(true);
            setIsLoading(false);
        } else {
            const conversationToSave: MyConversation = {
                conversation_id: conversationToDisplayAndSave.id,
                name: titleInput,
                description: descriptionInput,
                color_option: conversationToDisplayAndSave.color_option
                    ? conversationToDisplayAndSave.color_option
                    : 1,
                public: !!conversationToDisplayAndSave.public,
                pro_conversation: false,
                questions: location.state.questions.map((q) => {
                    return { question_id: q.id };
                }),
            };
            conversationClient
                .updateMyConversation(conversationToSave)
                .then(() => {
                    conversationClient
                        .getMyConversations()
                        .then((response) => {
                            dispatch(setMyConversations(response.reverse()));
                        })
                        .then(() => {
                            selectConversation(
                                {
                                    ...conversationToDisplayAndSave,
                                    title: titleInput,
                                    name: titleInput,
                                    description: descriptionInput,
                                },
                                language,
                                deckParentPages.myLibrary
                            );
                        });
                })
                .catch(() => {
                    navigate(routes.error);
                });
        }
    };

    if (isLoading) {
        return <Loading />;
    }
    return (
        <div className={styles.EditNameAndDescriptionContainer}>
            <div className={styles.Container}>
                <div className={styles.bg} />
                <section
                    className={styles.BackButtonContainer}
                    id='BackButtonContainer'
                >
                    <BackButton
                        language={language}
                        className={styles.backButton}
                        clickHandler={onExit}
                    />
                </section>
                <SEO title={editNameAndDescriptionTitle} />
                <h2 className={styles.title}>{editNameAndDescriptionTitle}</h2>
                <div className={styles.InnerContainer}>
                    <section className={styles.CreateConversationPage}>
                        <ConversationCard
                            key={conversationToDisplayAndSave.id}
                            conversation={conversationToDisplayAndSave}
                            // name={location.state?.deck?.name}
                            category={{
                                ...conversationToDisplayAndSave.category,
                                name:
                                    byText +
                                    ' ' +
                                    conversationToDisplayAndSave.category.name,
                            }}
                            onClick={() => undefined}
                            className={classNames(
                                styles.card,
                                conversationToDisplayAndSave.category &&
                                    conversationToDisplayAndSave.color_option ===
                                        1 &&
                                    styles.userCreatedConversation1,
                                conversationToDisplayAndSave.category &&
                                    conversationToDisplayAndSave.color_option ===
                                        2 &&
                                    styles.userCreatedConversation2,
                                conversationToDisplayAndSave.category &&
                                    conversationToDisplayAndSave.color_option ===
                                        3 &&
                                    styles.userCreatedConversation3
                            )}
                        />

                        <div className={styles.customRadios}>
                            <div>
                                <input
                                    type='radio'
                                    className={styles.color1}
                                    name='color'
                                    value={1}
                                    checked={
                                        conversationToDisplayAndSave.color_option ===
                                        1
                                    }
                                    onChange={() => {
                                        setConversationToDisplayAndSave({
                                            ...conversationToDisplayAndSave,
                                            color_option: 1,
                                        });
                                    }}
                                />
                                <label htmlFor='color1'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConversationToDisplayAndSave({
                                                ...conversationToDisplayAndSave,
                                                color_option: 1,
                                            });
                                        }}
                                    >
                                        <IconCheckBlue
                                            className={styles.IconCheck}
                                        />
                                    </button>
                                </label>
                            </div>

                            <div>
                                <input
                                    type='radio'
                                    className={styles.color2}
                                    name='color'
                                    value={2}
                                    checked={
                                        conversationToDisplayAndSave.color_option ===
                                        2
                                    }
                                    onChange={() => {
                                        setConversationToDisplayAndSave({
                                            ...conversationToDisplayAndSave,
                                            color_option: 2,
                                        });
                                    }}
                                />
                                <label htmlFor='color2'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConversationToDisplayAndSave({
                                                ...conversationToDisplayAndSave,
                                                color_option: 2,
                                            });
                                        }}
                                    >
                                        <IconCheckBlue
                                            className={styles.IconCheck}
                                        />
                                    </button>
                                </label>
                            </div>

                            <div>
                                <input
                                    type='radio'
                                    className={styles.color3}
                                    name='color'
                                    value={3}
                                    checked={
                                        conversationToDisplayAndSave.color_option ===
                                        3
                                    }
                                    onChange={() => {
                                        setConversationToDisplayAndSave({
                                            ...conversationToDisplayAndSave,
                                            color_option: 3,
                                        });
                                    }}
                                />
                                <label htmlFor='color3'>
                                    <button
                                        type='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConversationToDisplayAndSave({
                                                ...conversationToDisplayAndSave,
                                                color_option: 3,
                                            });
                                        }}
                                    >
                                        <IconCheckBlue
                                            className={styles.IconCheck}
                                        />
                                    </button>
                                </label>
                            </div>
                        </div>
                    </section>

                    <section className={styles.FieldContainer}>
                        <label
                            className={styles.TitleLabel}
                            htmlFor={nameFieldLabel}
                        >
                            {nameFieldLabel}
                        </label>
                        <input
                            name='title'
                            onChange={({ currentTarget: { value } }) =>
                                setTitleInput(value)
                            }
                            onFocus={() => setInvalidTitleInput(false)}
                            type='text'
                            placeholder=''
                            defaultValue={titleInput}
                            className={classNames(
                                styles.titleField,
                                invalidTitleInput && styles.InvalidInput
                            )}
                            maxLength={maxTitleLength}
                        />
                        <div
                            className={
                                invalidTitleInput
                                    ? styles.TitleErrorAndCounter
                                    : styles.OnlyCounter
                            }
                        >
                            {invalidTitleInput && (
                                <p className={styles.TitleErrorText}>
                                    {titleErrorText}
                                </p>
                            )}
                            <p className={styles.Counter}>
                                {titleInput.length + '/' + maxTitleLength}
                            </p>
                        </div>
                        <label
                            htmlFor={descriptionFieldLabel}
                            className={styles.DescriptionLabel}
                        >
                            {descriptionFieldLabel}
                        </label>
                        <textarea
                            name='description'
                            onChange={({ currentTarget: { value } }) =>
                                setDescriptionInput(value)
                            }
                            onFocus={() => setInvalidDescriptionInput(false)}
                            placeholder={''}
                            defaultValue={descriptionInput}
                            className={classNames(
                                styles.InputRow__Input,
                                styles.descriptionField,
                                invalidDescriptionInput && styles.InvalidInput
                            )}
                            maxLength={maxDescriptionLength}
                        />
                        <p className={styles.Counter}>
                            {descriptionInput?.length +
                                '/' +
                                maxDescriptionLength}
                        </p>
                        <div className={classNames(styles.ButtonContainer)}>
                            <Button
                                form='editConversationForm'
                                appearance='cta'
                                fullWidth={false}
                                className={styles.SaveButton}
                                onClick={onSaveConversation}
                            >
                                {saveChangesLabel}
                            </Button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default EditConversationColorAndTexts;
