import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AboutPage } from 'pages/account-page/about-page';
import { AccountConfirmationPage } from 'pages/account-confirmation-page';
import { AccountConfirmationProPage } from 'professional/pages/pro-account-confirmation-page';
import { AccountPageNative } from 'pages/account-page-native';
import { CompleteSubscription } from 'pages/complete-subscription-page';
import { CreatePremiumAccount } from 'pages/create-premium-page';
import { PageContainer } from 'pages/complete-subscription-page/complete-subscription-container';
import AccountRoutes from './account-routes';
import ContactPage from 'pages/contact-page/contact-page';
import useCommonRoutes from './use-common-routes';
import { DeleteAccountConfirmationPage } from 'pages/delete-account-confirmation-page';
import useHistoryRouter from 'hooks/use-history-router';
import ProCodeSubPage from 'pages/account-page/pro-code-subpage/pro-code-subpage';
import { ProProductInfoPage } from 'pages/pro-product-info-page';
import { CompanySignupPage } from 'pages/company-signup-page';
import CompanySignupConfirmationPage from 'pages/company-signup-confirmation-page/company-signup-confirmation-page';
import { EmailVerificationHOC } from 'hocs/EmailVerificationHoc';
import { ProSwitchConfirmationPage } from 'pages/pro-switch-confirmation-page';

const InactiveSubscriptionRoutes: React.FC = () => {
    const { language, routes } = useHistoryRouter();
    const commonRoutes = useCommonRoutes();

    return (
        <Routes>
            {commonRoutes}
            <Route path={routes.proCodeSubPage} element={<ProCodeSubPage />} />
            <Route
                path={routes.userConfirmPro}
                element={<AccountConfirmationProPage />}
            />
             <Route
                    path={routes.ProSwitchConfirmationPage}
                    element={<ProSwitchConfirmationPage />}
                />

            <Route
                path={routes.proProductInfoPage}
                element={<ProProductInfoPage />}
            />
            <Route
                path={routes.companySignupPage}
                element={<CompanySignupPage />}
            />
            <Route
                path={routes.confirmReservedEmail}
                element={<EmailVerificationHOC language={language} />}
            />
            <Route
                path={routes.companySignupConfirmationPage}
                element={<CompanySignupConfirmationPage />}
            />
            <Route path='/account-native' element={<AccountPageNative />} />
            <Route path={routes.about} element={<AboutPage />} />

            <Route
                path={`${routes.account.index}/*`}
                element={<AccountRoutes />}
            />

            <Route
                path={routes.createPremium}
                element={<CreatePremiumAccount />}
            />

            <Route
                path={routes.createPremiumPro}
                element={<CreatePremiumAccount />}
            />

            <Route
                path={routes.paymentOptionSelect}
                element={<CompleteSubscription />}
            />

            <Route
                path={routes.userConfirm}
                element={<AccountConfirmationPage />}
            />

            <Route
                path={routes.userConfirmPro}
                element={<AccountConfirmationProPage />}
            />

            <Route
                path={routes.userConfirmDeleted}
                element={<DeleteAccountConfirmationPage />}
            />
            <Route
                path={routes.createAccountConfirmation}
                element={<PageContainer regStep='Account Confirmation' />}
            />

            <Route
                path={routes.contact}
                element={<ContactPage language={language} />}
            />

            <Route
                path='*'
                element={<Navigate to={routes.account.index} replace />}
            />
        </Routes>
    );
};

export default InactiveSubscriptionRoutes;
