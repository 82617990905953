import React, {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TextField } from 'components/base';
import useSession from '../../use-session';
import { getAnswersCollectionRef, AnswerBase } from 'utils/collaboration-utils';
import { Question } from '_types';
import { serverTimestamp } from 'firebase/firestore';
import { useWindowSize } from 'hooks/use-window-size';
import styles from '../../join-collaboration-page.module.scss';
import { IS_IOS_APP } from 'constants/mobile-app-constants';
import { getUAInfo } from 'services/user-agent.service';

const { isiOS, isiOSWeb } = getUAInfo(window.navigator);
const isIOS = IS_IOS_APP || isiOS || isiOSWeb;

type AnswerFormProps = {
    activeContent: Question;
    loading: boolean;
    setFocusedOnIOS: Dispatch<SetStateAction<boolean>>;
};

const AnswerForm: FC<AnswerFormProps> = ({
    activeContent: { id },
    loading,
    setFocusedOnIOS,
}) => {
    const { displayName, userCredential, collaborationId } = useSession();
    const { t } = useTranslation();
    const { view } = useWindowSize(window);

    const [answer, setAnswer] = useState('');
    const [focused, setFocused] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const ref = useRef<HTMLInputElement>(null);
    const answersRef = getAnswersCollectionRef(collaborationId, id);

    useEffect(() => setAnswer(''), [id]);

    const addAnswer = async () => {
        ref.current?.focus();

        if (answer) {
            setSubmitting(true);

            if (displayName && userCredential?.user.uid) {
                const answerObj: AnswerBase = {
                    createdAt: serverTimestamp(),
                    text: answer,
                    user: {
                        displayName,
                        id: userCredential.user.uid,
                    },
                };

                await answersRef
                    ?.add(answerObj)
                    .then(() => setAnswer(''))
                    .catch(console.error)
                    .finally(() => setSubmitting(false));
            }
        } else setSubmitting(false);
    };

    const handleFocusChange = (focus: boolean) => {
        setFocused(focus);
        if (isIOS) setFocusedOnIOS(focus);
    };

    return (
        <form
            className={styles.answerForm}
            onSubmit={(e) => {
                e.preventDefault();
                addAnswer();
            }}
        >
            <TextField
                autoComplete='off'
                disabled={loading}
                endAdornment={
                    focused || answer ? (
                        <Button
                            appearance='secondary'
                            disabled={loading}
                            loading={submitting}
                            onClick={() => setSubmitting(true)}
                            size='small'
                            type='submit'
                        >
                            {t('send')}
                        </Button>
                    ) : undefined
                }
                fullWidth={view === 'mobile'}
                hideFocusIndication
                inputProps={{ 'aria-label': t('joinCollaboration.yourAnswer') }}
                onBlur={() => handleFocusChange(false)}
                onChange={({ currentTarget: { value } }) =>
                    submitting ? undefined : setAnswer(value)
                }
                onFocus={() => handleFocusChange(true)}
                placeholder={t('joinCollaboration.yourAnswer.placeholder')}
                ref={ref}
                rounded
                value={answer}
            />
        </form>
    );
};

export default AnswerForm;
