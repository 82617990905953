import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';
import InputAdornment from '@mui/material/InputAdornment';

import { LightThemeProvider } from 'style/theme';
import styles from './text-field.module.scss';
import { Icon } from '../../../data-display/icon';
import { TextFieldBase, TextFieldBaseProps } from './text-field-base';

export type TextFieldProps = Omit<
    TextFieldBaseProps,
    'maxRows' | 'minRows' | 'multiline' | 'rows'
>;

export const TextField: FC<TextFieldProps> = forwardRef(
    ({ endAdornment, startIcon, ...rest }, ref) => (
        <TextFieldBase
            InputProps={{
                endAdornment: endAdornment ? (
                    <LightThemeProvider>
                        <InputAdornment
                            className={classNames(
                                styles.inputAdornment,
                                styles.endAdornment
                            )}
                            position='end'
                        >
                            {endAdornment}
                        </InputAdornment>
                    </LightThemeProvider>
                ) : undefined,
                startAdornment: startIcon ? (
                    <LightThemeProvider>
                        <InputAdornment
                            position='start'
                            className={styles.inputAdornment}
                        >
                            <Icon name={startIcon} />
                        </InputAdornment>
                    </LightThemeProvider>
                ) : undefined,
            }}
            ref={ref}
            {...rest}
        />
    )
);
