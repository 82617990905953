import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collaboration } from 'api/collaboration-client';
import { FullscreenLoader, Menu } from 'components/base';

import { useCreateCollaboration } from 'hooks/use-create-collaboration';
import { getSessionDocumentRef } from 'utils/collaboration-utils';

type CopyCollaborationMenuItemProps = {
    collaboration: Collaboration;
};

const CopyCollaborationMenuItem: FC<CopyCollaborationMenuItemProps> = ({
    collaboration: { name, collaborationId },
}) => {
    const { t } = useTranslation();
    const { copyCollaboration } = useCreateCollaboration();

    const [loading, setLoading] = useState(false);

    const handleCopyCollaboration = () => {
        setLoading(true);

        getSessionDocumentRef(collaborationId)
            .get()
            .then((snapshot) => {
                const data = snapshot.data();
                if (!data) throw new Error();

                const {
                    conversation: { deck, questions },
                } = data;

                if (!deck || !questions) throw new Error();

                copyCollaboration(collaborationId, {
                    deck,
                    questions,
                    callbackFn: () => setLoading(false),
                });
            });
    };

    return (
        <Fragment>
            <Menu.Item
                icon='desktop'
                keepMounted
                onClick={handleCopyCollaboration}
            >
                {t('collaboration.menu.option.useInNewCollaboration', {
                    conversationName: name,
                })}
            </Menu.Item>

            {loading && <FullscreenLoader />}
        </Fragment>
    );
};

export default CopyCollaborationMenuItem;
