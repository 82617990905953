import React, { useEffect } from 'react';
import { AppRouter } from 'app-router';
import './app.module.scss';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/mousewheel';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import { SplashScreen } from '@capacitor/splash-screen';
import { IS_ANDROID_APP, IS_IOS_APP, IS_MOBILE_APP } from './constants';
import { useDispatch } from 'react-redux';
import { detectDevice } from 'store-modules/app-settings';
import { initializeLanguage } from 'services/language-service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import firebaseConfig from 'utils/firebase';
import './i18n';
import { PurchaseService } from './common-logic/purchase-service';
import ProductType = CdvPurchase.ProductType;
import Platform = CdvPurchase.Platform;

//native analytics
//not necessary on android at least
if (!IS_ANDROID_APP) {
    FirebaseAnalytics.initializeFirebase(firebaseConfig).then(() => {
        FirebaseAnalytics.setCollectionEnabled({
            enabled: true,
        });
        FirebaseAnalytics.enable();
    });
}

const PRODUCTS: Map<number, { id: string, type: ProductType, platform: CdvPurchase.Platform }> = new Map();

if (IS_MOBILE_APP) {
    const purchaseService = new PurchaseService();
    const IAP2 = purchaseService.store;
    PRODUCTS.set(299, {
        id: IS_ANDROID_APP
            ? 'annual_subscription_android'
            : 'Annual_subscription',
        type: ProductType.PAID_SUBSCRIPTION,
        platform: IS_ANDROID_APP ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE,
    });

    PRODUCTS.set(49, {
        id: IS_ANDROID_APP
            ? 'monthly_subscription_android'
            : 'Monthly_subscription',
        type: ProductType.PAID_SUBSCRIPTION,
        platform: IS_ANDROID_APP ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE
    });

    if (IS_IOS_APP) {
        PRODUCTS.set(209, {
            id: 'Annual_company_subscription',
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
        });
        PRODUCTS.set(179, {
            id: 'Annual_business_subscription',
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
        });
        PRODUCTS.set(149, {
            id: 'Annual_corporate_subscription',
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
        });
        PRODUCTS.set(35, {
            id: 'Monthly_company_subscription',
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
        });
        PRODUCTS.set(25, {
            id: 'Monthly_corporate_subscription',
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
        });
    }

    if (IAP2) {
        IAP2.register(Array.from(PRODUCTS.values()));
        // IAP2.when(i.id).updated(() => {
        //     const p = IAP2.get(i.id);
        //     setProduct(p);
        //   });
        IAP2.when().approved(function (p) {
            // console.log('approved', i);
            p.verify();
        });
        IAP2.when().verified(function (p) {
            // console.log('verified');
            p.finish();
        });
        IAP2.when().finished(() => {
            // console.log('finished');
            IAP2.update();
        });
        IAP2.when().unverified((p) => {
            // console.log('unverified');
            // console.error('Purchase was Cancelled');

            console.error(p);
        });
        IAP2.error((err) => {
            console.error(err);
        });
    }
}
export { PRODUCTS };

// Annual subscription
// Årlig abonnement
// NOK 299
// ID: Annual_Payment

// Annual company subscription
// Årlig bedriftsabonnement
// 30%
// NOK 209
// ID: Annual_company_subscription

// Annual business subscription
// Årlig forretningsabonnement
// 40%
// NOK 179
// ID: Annual_business_subscription

// Annual corporate subscription
// Årlig bedriftsabonnement
// 50%
// NOK 149
// ID: Annual_corporate_subscription

// Monthly subscription
// Månedlig abonnement
// NOK 49
// ID: Monthly_Payment

// Monthly company subscription
// Månedlig firmabonnement
// 30%
// NOK 35
// ID: Monthly_company_subscription

// Monthly corporate subscription
// Månedlig bedriftsabonnement
// 50%
// NOK 25
// ID: Monthly_corporate_subscription

const App: React.FC = () => {
    const dispatch = useDispatch();

    const purchaseService = new PurchaseService();
    const IAP2 = purchaseService.store;

    if (IAP2 && IS_MOBILE_APP) {
        IAP2.initialize();
    }
    useEffect(() => {
        initializeLanguage(dispatch);

        if (IS_MOBILE_APP) {
            dispatch(detectDevice(IS_ANDROID_APP ? 'android' : 'ios'));
            if (IS_IOS_APP) {
                SplashScreen.hide().then();
            }
        }
    }, [dispatch]);

    return <AppRouter/>;
};

export default App;
