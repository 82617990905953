import React from 'react';
import { Container } from 'components/container';
import styles from './swiper-slide.module.scss';

interface Props {
    children: React.ReactNode;
}

const SwiperSlide: React.FC<Props> = ({ children }: Props) => {
    return (
        <Container className={styles.Container}>
            <div className={styles.Content}>{children}</div>
        </Container>
    );
};

export default SwiperSlide;
