import React, { ElementType, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './conversations-row.module.scss';
import { ConversationCategory, ConversationWithCategory } from '_types';
import { Container } from 'components/container';
import { useWindowSize } from 'hooks';
import ConversationSwiper, {
    ConversationSlideListItem,
} from './conversation-swiper';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Icon, Typography } from 'components/base';

interface Props {
    slidesId: number;
    title: string;
    category: ConversationCategory | null;
    conversations: ConversationWithCategory[];
    createCard: (
        category: ConversationCategory,
        conversation: ConversationWithCategory
    ) => ReactElement;
    showAllComponent: ElementType;
    onClickShowAll: () => void;
    className?: string;
}

interface ViewCopy {
    showAll: string;
    byText: string;
}

const nbCopy: ViewCopy = {
    showAll: 'Se alle',
    byText: 'av ',
};

const enCopy: ViewCopy = {
    showAll: 'View all',
    byText: 'by ',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

// TODO: find a better place for these objects
const SLIDE_COUNT = {
    mobile: 6,
    tablet: 16,
    desktop: 24,
};

const SLIDES_PER_GROUP = {
    mobile: 1,
    tablet: 4,
    desktop: 6,
};

const ConversationsRow: React.FC<Props> = ({
    slidesId,
    title,
    category,
    conversations,
    createCard,
    showAllComponent: ShowAllComponent,
    onClickShowAll,
    className,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const { showAll, byText } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    const maxSlideCount = SLIDE_COUNT[view];
    const slidesPerGroup = SLIDES_PER_GROUP[view];
    const allCardsAreVisible = conversations.length <= maxSlideCount;
    const showLink = !isMobile && !allCardsAreVisible;
    const addShowAllCard = isMobile && !allCardsAreVisible;

    const slideList: ConversationSlideListItem[] = conversations
        .slice(0, maxSlideCount)
        .map((conversation) => ({
            id: conversation.id,
            content: createCard(
                category
                    ? category
                    : {
                          ...conversation.category,
                          name: conversation.created_by_id
                              ? byText + conversation.category.name
                              : conversation.category.name,
                      },
                conversation
            ),
        }));

    if (addShowAllCard) {
        slideList.push({
            id: 'show-all',
            content: (
                <ShowAllComponent
                    className={classNames(styles.showAllSlide)}
                    category={category}
                    onClick={onClickShowAll}
                >
                    <Typography color='primary'>{showAll}</Typography>
                    <Icon color='primary' name='arrow_right' />
                </ShowAllComponent>
            ),
        });
    }

    return (
        <article
            className={classnames(styles.row, className)}
            aria-labelledby='conversations-row-title'
        >
            <Container className={styles.container}>
                <header className={styles.header}>
                    <h2 id='conversations-row-title' className={styles.title}>
                        {title}
                    </h2>
                    {showLink && (
                        <button
                            className={styles.showAllButton}
                            onClick={onClickShowAll}
                        >
                            {showAll}
                        </button>
                    )}
                </header>
                <ConversationSwiper
                    key={view}
                    slidesId={slidesId}
                    slidesPerGroup={slidesPerGroup}
                    showNavigation={view !== 'mobile'}
                    slideList={slideList}
                    noSwiping={view !== 'mobile'}
                />
            </Container>
        </article>
    );
};

export default ConversationsRow;
