import React from 'react';
import { Button, Dialog, Link } from 'components/base';

interface Props {
    onCancel: () => void;
    onSubmit?: () => void;
    onSelectOption1?: () => void;
    onSelectOption2?: () => void;
    title: string;
    description: string;
    buttonLabel?: string;
    cancelLabel: string;
    option1Label?: string;
    option2Label?: string;
}

const ToggleConfirmation: React.FC<Props> = ({
    title,
    description,
    buttonLabel,
    cancelLabel,
    onSubmit,
    onCancel,
    option1Label,
    option2Label,
    onSelectOption1,
    onSelectOption2,
}: Props): React.ReactElement => {
    const content = (
        <Dialog open onClose={onCancel} title={title}>
            <Dialog.Content>{description}</Dialog.Content>

            <Dialog.Actions>
                {option1Label && option1Label.length > 0 && (
                    <Button onClick={onSelectOption1}>{option1Label}</Button>
                )}

                {option2Label && option2Label.length > 0 && (
                    <Button onClick={onSelectOption2}>{option2Label}</Button>
                )}

                {buttonLabel && buttonLabel.length > 0 && (
                    <Button appearance='cta' onClick={onSubmit}>
                        {buttonLabel}
                    </Button>
                )}

                <Link onClick={onCancel} component='button'>
                    {cancelLabel}
                </Link>
            </Dialog.Actions>
        </Dialog>
    );

    return content;
};

export default ToggleConfirmation;
