import React from 'react';
import classnames from 'classnames';
import { PageHeader, HeadSpace, HeaderProps } from 'components/page-header';
import styles from './layout.module.scss';
import { Footer } from 'components/footer';
import { RouteList } from 'api/routes';
import { Language } from '_types/language';

interface Props {
    children: React.ReactNode;
    pageClassName?: string;
    headerProps?: HeaderProps;
    withHeaderSpace?: boolean;
    withFooter?: boolean;
    routes: RouteList;
    language: Language;
    parentPage?: string;
}

const Layout: React.FC<Props> = ({
    children,
    pageClassName,
    headerProps,
    withHeaderSpace = true,
    withFooter = false,
    routes,
    language,
    parentPage,
}: Props) => {
    return (
        <div className={styles.layout}>
            <PageHeader {...headerProps} />
            <main>
                <div
                    className={classnames(styles.page, pageClassName)}
                    id={'PageLayout'}
                >
                    {withHeaderSpace && <HeadSpace />}
                    {children}
                </div>
            </main>
            {withFooter && (
                <Footer
                    routes={routes}
                    language={language}
                    parentPage={parentPage}
                />
            )}
        </div>
    );
};

export default Layout;
