import { createContext, MouseEvent } from 'react';

export type MenuContextValues = {
    anchorEl: null | HTMLElement;
    onClose: () => void;
    onOpen: (event: MouseEvent<HTMLElement>) => void;
};

const MenuContext = createContext<MenuContextValues>({
    anchorEl: null,
    onClose: () => true,
    onOpen: () => true,
});

export default MenuContext;
