import React, { useEffect, useRef, useState } from 'react';
import { Steps } from '../../../components/steps';
import { Product } from '../_types';
import Benefits from './benefits/benefits';
import styles from './choose-premium-product.module.scss';
import { ProductSelect } from './product-select';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { deviceSelector, languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { routeObj } from 'pages/account-page/account-home/account-home';
import { Dots } from 'components/loading/dots';
import applePayClient from 'api/apple-pay-client';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Link } from 'components/link';
import { PurchaseService } from '../../../common-logic/purchase-service';

type Props = {
    products: Product[];
    onProductSelected: (product?: Product) => void;
    moveToRegistrationByEmail: (enterProCode: boolean) => void;
};
interface ViewCopy {
    title: string;
    description: string;
    benefits: string[];
    linkPrivacy: string;
    linkTnc: string;
    proCodeLabel: string;
}

const nbCopy: ViewCopy = {
    title: 'Velg din FuelPlan',
    description:
        'Få fart på den gode samtalen når og hvor som helst med din digitale FuelBox.',
    benefits: [
        'Nye spørsmål hver måned',
        'Samtaler for alle sesonger og høytider',
        'Samtaler for par, familien, venner, kolleger, barn og nye bekjente',
    ],
    linkPrivacy: 'Personvern',
    linkTnc: 'Vilkår for bruk',
    proCodeLabel: 'Jeg har en bedriftskode',
};

const enCopy: ViewCopy = {
    title: 'Choose your FuelPlan',
    description:
        'Have great conversations when and where you like with your digital FuelBox.',
    benefits: [
        'New questions every month',
        'Conversations for all seasons and events',
        'Conversations for couples, families, friends, colleagues, kids and new acquaintances',
    ],
    linkPrivacy: 'Privacy policy',
    linkTnc: 'Terms of use',
    proCodeLabel: 'I have a Company code',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const BASE_URL = '/wp-json/fuelbox/v1';
const { REACT_APP_API_URL } = process?.env;

const ChoosePremProd: React.FC<Props> = ({
    products,
    onProductSelected,
    moveToRegistrationByEmail,
}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const language = useSelector(languageSelector);
    const device = useSelector(deviceSelector);

    const ref = useRef<HTMLDivElement>(null);

    const routes = returnRoutesForLanguage(language, routeObj);

    const { title, description, benefits, linkPrivacy, linkTnc, proCodeLabel } =
        returnCopyForLanguage(language, copies);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.HTMLElement.prototype.scrollIntoView = function () {};
    useEffect(() => {
        !!ref &&
            !!ref.current &&
            ref.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const onClick = (): void => {
        const purchaseService = new PurchaseService();
        const IAP2 = purchaseService.store!;
        setLoading(true);

        const productId = products[0].id;

        applePayClient
            .createOrder({ productId })
            .then(({ order_id }) => {
                IAP2.validator = `${REACT_APP_API_URL}${BASE_URL}/AppleVerifyReceipt?order=${order_id}`;

                IAP2.when()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .approved(function (p: any) {
                        p.verify();
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .verified(function (p: any) {
                        p.finish();
                        navigate(routes.account.index);
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .unverified((p: any) => {
                        console.error(p);
                        setLoading(false);
                        setIsError(true);
                    });

                IAP2.error((err) => {
                    setLoading(false);
                    setIsError(true);
                    console.error(err);
                });
                IAP2.restorePurchases();
            })
            .catch((err) => {
                console.error(err);
                throw err;
            });
    };

    return (
        <article
            data-testid='component-choose-sub-plan'
            className={styles.ChoosePremProd}
        >
            {!!products.length && (
                <>
                    <section className={styles.Steps}>
                        <Steps current={1} total={3} language={language} />
                    </section>
                    <PageTitle title={title} />
                    <p className={styles.Description}>{description}</p>
                    <section
                        className={styles.Benefits}
                        aria-labelledby='Benefits'
                    >
                        <Benefits benefits={benefits} />
                    </section>
                    <section className={styles.Plans}>
                        <ProductSelect
                            products={products}
                            productSelectHandler={onProductSelected}
                            navigateToCompanySignup={() =>
                                navigate(routes.companySignupPage)
                            }
                        />
                        {device === 'ios' &&
                            (loading ? (
                                <section className={styles.dots}>
                                    <div>
                                        <Dots />
                                    </div>
                                </section>
                            ) : (
                                <section className={styles.restorePurchases}>
                                    {isError ? (
                                        <p>Error Verifying Purchase</p>
                                    ) : (
                                        <a
                                            href='#restore-purchases'
                                            onClick={onClick}
                                        >
                                            Restore Purchases
                                        </a>
                                    )}
                                </section>
                            ))}
                    </section>

                    <section>
                        <button
                            className={classNames(
                                styles.link,
                                styles.proCodeLabel
                            )}
                            onClick={() => {
                                moveToRegistrationByEmail(true);
                            }}
                        >
                            {proCodeLabel}
                        </button>
                    </section>

                    <section className={styles.links} aria-label='links'>
                        <Link className={styles.link} to={routes.privacyTerms}>
                            {linkPrivacy}
                        </Link>
                        <Link className={styles.link} to={routes.tnc}>
                            {linkTnc}
                        </Link>
                    </section>
                    <div ref={ref} />
                </>
            )}
        </article>
    );
};

export default React.memo(ChoosePremProd);
