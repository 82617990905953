import { publish } from 'services/pubsub';

export class EndSessionDialogOpenedEvent {
    constructor(dialogOpen) {
        this.args = [dialogOpen];
    }
}

export const openEndSessionDialog = () =>
    publish(new EndSessionDialogOpenedEvent(true));

export const closeEndSessionDialog = () =>
    publish(new EndSessionDialogOpenedEvent(false));
