import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import Box from '@mui/material/Box';
import visuallyHidden from '@mui/utils/visuallyHidden';

import { Loading } from 'components/loading';

import { Modal } from './modal';
import { Typography } from '../data-display/typography';

/**
 * Accessible wrapper component for displaying `Loading` component on top of other content, hiding it from view and
 * making it inresponsive.
 */
export const FullscreenLoader: FC = () => {
    const { t } = useTranslation();
    const titleId = useMemo(nanoid, []);

    return (
        <Modal titleId={titleId}>
            <Typography id={titleId} sx={visuallyHidden}>
                {t('loading')}
            </Typography>

            <Box width={1} height={1}>
                <Loading />
            </Box>
        </Modal>
    );
};
