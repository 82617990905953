/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Icon } from 'components/base';
import { InputRow } from 'components/inputs';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import styles from './company-signup-form-company.module.scss';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useEffect, useState } from 'react';
import { Link } from 'components/link';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import nbLocale from 'i18n-iso-countries/langs/nb.json';
import { CompanySignupFormValues } from 'store-modules/user';
import classnames from 'classnames';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { RouteList, routesEN, routesNO } from 'api/routes';
// import { Checkbox } from '@mui/material';
// import { type } from 'os';
// import { components } from '@storybook/components';

const requiredValidator = (value: string | undefined | null): boolean =>
    !!value && value.length > 0;

type Props = {
    nextHandler: (input: CompanySignupFormValues) => void;
    editHandler: () => void;
    activeContainer: number;
    formValues: CompanySignupFormValues;
};
interface ViewCopy {
    billingAddressLabel: string;
    changeLabel: string;
    companyLabel: string;
    countryLabel: string;
    errorEmail: string;
    errorRequired: string;
    googleRegisterlabel: string;
    labelAddress: string;
    labelCity: string;
    labelCompanyName: string;
    labelCountry: string;
    labelOrganizationNumber: string;
    labelPostalCode: string;
    labelProCode: string;
    labelSubmit: string;
    linkPrivacy: string;
    login: string;
    pageTitle: string;
    title: string;
    schoolCheckboxLabel: string;
}

const nbCopy: ViewCopy = {
    billingAddressLabel: 'Fakturaadresse',
    changeLabel: 'Endre',
    companyLabel: 'Bedrift',
    countryLabel: 'Land',
    errorEmail: 'Dette ser ikke ut som en fullstendig epostadresse',
    errorRequired: 'Obligatorisk felt',
    googleRegisterlabel: 'Registrer deg med Google',
    labelAddress: 'Adresse',
    labelCity: 'Poststed',
    labelCompanyName: 'Bedriftsnavn',
    labelCountry: 'Land',
    labelOrganizationNumber: 'Organisasjonsnummer',
    labelPostalCode: 'Postnummer',
    labelProCode: 'Bedriftskode',
    labelSubmit: 'Neste',
    linkPrivacy: 'personvernerklæring',
    login: 'Logg inn',
    pageTitle: 'Din bedrift',
    title: 'Opprett bruker',
    schoolCheckboxLabel: 'Jeg oppretter abonnement for en skole',
};

const enCopy: ViewCopy = {
    billingAddressLabel: 'Billing address',
    changeLabel: 'Edit',
    companyLabel: 'Company',
    countryLabel: 'Country',
    errorEmail: "This doesn't look like a complete email address",
    errorRequired: 'Required field',
    googleRegisterlabel: 'Registrer using Google',
    labelAddress: 'Address',
    labelCity: 'City',
    labelCompanyName: 'Company name',
    labelCountry: 'Country',
    labelOrganizationNumber: 'Organization number',
    labelPostalCode: 'Postal code',
    labelProCode: 'Company code',
    labelSubmit: 'Next',
    linkPrivacy: 'privacy policy',
    login: 'Log in',
    pageTitle: 'Your company',
    title: 'Create account',
    schoolCheckboxLabel: 'This subscription is for a school',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CompanySignupFormUser: React.FC<Props> = ({
    nextHandler,
    editHandler,
    activeContainer,
    formValues,
}) => {
    const language: Language = useSelector(languageSelector);
    const {
        billingAddressLabel,
        changeLabel,
        companyLabel,
        countryLabel,
        errorRequired,
        labelAddress,
        labelCity,
        labelCompanyName,
        labelOrganizationNumber,
        labelPostalCode,
        labelSubmit,
        pageTitle,
        schoolCheckboxLabel,
    } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const [isCollapsed, setIsCollapsed] = useState<boolean>(
        activeContainer !== 2
    );
    const selectedCountry = '';
    const [countryError, setCountryError] = useState<string>('');
    const routes = returnRoutesForLanguage(language, routeObj);
    const navigate = useNavigate();
    const [isSchool, setIsSchool] = useState<boolean>(
        formValues.isSchool === 'yes'
    );
    const address = '';
    const [addressError, setAddressError] = useState<string>('');

    useEffect(() => {
        setIsCollapsed(activeContainer !== 2);
    }, [activeContainer]);

    const onSubmitForm = async (values: CompanySignupFormValues) => {
        const companyNameError =
            !requiredValidator(values?.companyName) && errorRequired;
        const organizationNumberError =
            !requiredValidator(values?.organizationNumber) && errorRequired;
        const addressError =
            !requiredValidator(values?.address) && errorRequired;
        const postCodeError =
            !requiredValidator(values?.postCode) && errorRequired;
        const cityError = !requiredValidator(values?.city) && errorRequired;

        if (
            companyNameError ||
            organizationNumberError ||
            addressError ||
            postCodeError ||
            cityError
        ) {
            if (!requiredValidator(values?.country)) {
                setCountryError(errorRequired);
            } else {
                setCountryError('');
            }
            if (!requiredValidator(values?.address)) {
                setAddressError(errorRequired);
            } else {
                setAddressError('');
            }

            return {
                companyName: companyNameError,
                organizationNumber: organizationNumberError,
                address: addressError,
                postCode: postCodeError,
                city: cityError,
                country: countryError,
            };
        }

        try {
            values.isSchool = isSchool ? 'yes' : 'no';
            // values.address = address;
            nextHandler(values);
        } catch (error) {
            console.error(error);
            navigate(routes.error);
        }
    };

    countries.registerLocale(enLocale);
    countries.registerLocale(nbLocale);

    const countryObj = countries.getNames(language, { select: 'official' });

    const countryArray = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key,
        };
    });

    const CountryOptionList = () => {
        const listOfContryOptions = [];
        for (const i in countryArray) {
            const country = countryArray[i];
            listOfContryOptions.push(
                <option key={country.value} label={country.label}>
                    {country.label}
                </option>
            );
        }
        return listOfContryOptions;
    };

    const MyForm = (
        <Form
            onSubmit={onSubmitForm}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <p className={styles.FormHeader}>{companyLabel}</p>
                    <div className={styles.CompanyNameContainer}>
                        <Field
                            name='companyName'
                            label={labelCompanyName}
                            type='text'
                            component={InputRow}
                            defaultValue={formValues?.companyName}
                        />
                    </div>
                    <div className={styles.OrganizationNumber}>
                        <Field
                            name='organizationNumber'
                            label={labelOrganizationNumber}
                            type='text'
                            component={InputRow}
                            defaultValue={formValues?.organizationNumber}
                        />
                    </div>

                    <Button
                        className={styles.SchoolCheckBoxButton}
                        onClick={() => {
                            setIsSchool(!isSchool);
                        }}
                    >
                        <Icon
                            name={
                                isSchool
                                    ? 'checkbox_checked'
                                    : 'checkbox_outlined'
                            }
                        />
                        <p className={styles.SchoolCheckboxLabel}>
                            {schoolCheckboxLabel}
                        </p>
                    </Button>

                    <div className={styles.AddressContainer}>
                        <p className={styles.FormHeader}>
                            {billingAddressLabel}
                        </p>
                        <div className={styles.StreetContainer}>
                            {/* <Field
                                name='address'
                                label={labelAddress}
                                language={language}
                                component={InputRow}
                                className={styles.inputField}
                                defaultValue={formValues?.address}
                            />
                         */}
                            <p className={styles.AddressLabel}>
                                {labelAddress}
                            </p>

                            <Field
                                name='address'
                                component={'textarea'}
                                className={classnames(
                                    styles.inputField,
                                    addressError && styles.Form__Input__Error
                                )}
                                defaultValue={formValues?.address}
                                value={address}
                            ></Field>
                            <span
                                className={
                                    addressError.length > 0
                                        ? styles.Form__Error__Show
                                        : styles.Form__Error__Hidden
                                }
                            >
                                {addressError}
                            </span>
                        </div>
                        <div className={styles.PostalCodeContainer}>
                            <Field
                                name='postCode'
                                label={labelPostalCode}
                                language={language}
                                component={InputRow}
                                className={styles.inputField}
                                defaultValue={formValues?.postCode}
                            />
                            <Field
                                name='city'
                                label={labelCity}
                                language={language}
                                component={InputRow}
                                className={styles.inputField}
                                defaultValue={formValues?.city}
                            />
                        </div>
                        <div className={styles.CountryContainer}>
                            <label
                                htmlFor='country'
                                className={styles.CountrySelectLabel}
                            >
                                {countryLabel}
                            </label>
                            <Field
                                name='country'
                                component={'select'}
                                className={classnames(
                                    styles.CountrySelect,
                                    countryError && styles.Form__Input__Error
                                )}
                                value={selectedCountry}
                                defaultValue={formValues?.country}
                            >
                                <option />
                                {CountryOptionList()}
                            </Field>
                            <span
                                className={
                                    countryError?.length > 0
                                        ? styles.Form__Error__Show
                                        : styles.Form__Error__Hidden
                                }
                            >
                                {countryError}
                            </span>
                        </div>
                    </div>

                    <Button
                        className={styles.NextButton}
                        appearance={'cta'}
                        type='submit'
                    >
                        {labelSubmit}
                    </Button>
                </form>
            )}
        />
    );

    const collapsedContainer = (
        <div className={styles.CollapsedContainer}>
            <div className={styles.LeftContainer}>
                <div className={styles.LeftLeftContainer}>
                    <div className={styles.Circle}>{2}</div>
                    {!isDesktop && <PageTitle title={pageTitle} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={pageTitle} />}
                    {formValues?.companyName &&
                        formValues?.address &&
                        formValues?.postCode &&
                        formValues?.city && (
                            <div className={styles.CompanyInfoContainer}>
                                <p className={styles.CompanyInfoName}>
                                    {formValues?.companyName}
                                </p>

                                <p className={styles.CompanyInfoAddress}>
                                    {formValues?.address}
                                    {formValues?.address && ','}
                                    &nbsp;
                                    {formValues?.postCode}
                                    &nbsp;
                                    {formValues?.city}
                                </p>
                            </div>
                        )}
                </div>
            </div>
            <div className={styles.RightContainer}>
                {activeContainer === 3 && (
                    <Link
                        className={styles.ButtonLink}
                        onClick={() => editHandler()}
                        to={''}
                    >
                        {changeLabel}
                    </Link>
                )}
            </div>
        </div>
    );

    const expandedContainer = (
        <div className={styles.ExpandedContainer}>
            <div className={styles.LeftContainer}>
                <div className={styles.HeaderContainerMobile}>
                    <div className={styles.LeftLeftContainer}>
                        <div className={styles.Circle}>{2}</div>
                    </div>
                    {!isDesktop && <PageTitle title={pageTitle} />}
                </div>
                <div className={styles.LeftMiddleContainer}>
                    {isDesktop && <PageTitle title={pageTitle} />}
                    {MyForm}
                </div>
            </div>
        </div>
    );

    return (
        <article
            data-testid='component-create-account-form'
            className={styles.CompanySignupForm}
        >
            {isCollapsed ? collapsedContainer : expandedContainer}
        </article>
    );
};

export default React.memo(CompanySignupFormUser);
