import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useCollectionData as useFirebaseHooksCollectionData } from 'react-firebase-hooks/firestore';

export const useCollectionData = <T>(
    collectionRef:
        | firebase.firestore.CollectionReference<T>
        | firebase.firestore.Query<T>,
    disabled?: boolean
): T[] | undefined => {
    const [data, setData] = useState<T[]>();

    const [collectedData] = useFirebaseHooksCollectionData<T>(
        !disabled ? (collectionRef as never) : undefined
    );

    useEffect(() => {
        if (!disabled) setData(collectedData);
    }, [collectedData]);

    return data;
};
