import { CompanyRoleTypes, CompanyUser } from '_types/company-user';
import restClient from './rest-client';

export interface CompanyData {
    owner: number;
    name: string;
    business_registration_number: string;
    address: string;
    postal_code: string;
    city: string;
    country: string;
    billing_email: string;
    purchase_referance_number?: string;
    email_domains?: string;
    azure_login: boolean;
    logo?: string;
    number_of_users: number;
    business_language: string;
    is_school: string;
    pro_code: string;
    has_seen_owner_splash: string;
}

export interface CompanyPrice {
    interval: number;
    min_users: number;
    max_users: number | null;
    price_per_user: number;
    discount: number;
    currency: string;
}

export interface CreateCompanyResponse {
    code: number;
    message: string;
}

export interface CompanyUsersResponse {
    user_id: number;
    first_name: string;
    last_name: string;
    email: string;
    member_since: string;
    role: CompanyRoleTypes;
}
export interface RemoveUsersFromCompanyResponse {
    user_id: number;
    first_name: string;
    last_name: string;
    email: string;
    member_since: string;
    role: CompanyRoleTypes;
}

const CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

export const mapUsers = (users: CompanyUsersResponse[]): CompanyUser[] =>
    users.map((u) => ({
        id: u.user_id,
        firstName: u.first_name,
        lastName: u.last_name,
        email: u.email,
        memberSince: u.member_since,
        role: u.role,
    }));

export const mapRemainingUsers = (
    users: RemoveUsersFromCompanyResponse[]
): CompanyUser[] =>
    users.map((u) => ({
        id: u.user_id,
        firstName: u.first_name,
        lastName: u.last_name,
        email: u.email,
        memberSince: u.member_since,
        role: u.role,
    }));

export default {
    createCompany: (company: CompanyData): Promise<CreateCompanyResponse> => {
        return restClient.post<CompanyData, CreateCompanyResponse>(
            '/registerCompany',
            company,
            CONFIG
        );
    },

    getCompany: (): Promise<CompanyData> => {
        return restClient.get<CompanyData>('/getCompany');
    },

    getCompanyPriceIntervals: (): Promise<CompanyPrice[]> => {
        return restClient.get<CompanyPrice[]>(
            '/getCompanyPriceIntervals',
            true
        );
    },

    getCompanyUsers: (): Promise<CompanyUser[]> => {
        return restClient
            .get<CompanyUsersResponse[]>('/getCompanyUsers', false)
            .then(mapUsers);
    },

    removeUsersFromCompany: (userIds: number[]): Promise<CompanyUser[]> => {
        return restClient
            .post<number[], RemoveUsersFromCompanyResponse[]>(
                '/removeUsersFromCompany',
                userIds,
                CONFIG
            )
            .then(mapRemainingUsers);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hideOwnerSplash: (): Promise<any> => {
        return restClient.post<string, string>('/hideOwnerSplash', '');
    },
};
