import React from 'react';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';

interface Props {
    direction: 'left' | 'right';
    title?: string;
    color?: string;
}

const Arrow: React.FC<Props> = ({
    direction,
    title,
}: Props): React.ReactElement => {
    return direction === 'right' ? (
        // wrong typing - title prop is valid
        // https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
        <ArrowRight title={title} />
    ) : (
        <ArrowLeft title={title} />
    );
};

export default React.memo(Arrow);
