import React, { FC, Fragment, useEffect } from 'react';

import { SlideType, getSessionDocumentRef } from 'utils/collaboration-utils';

import Participants from './participants';
import BgNightDecorator from '../background/bg-night-decorator';
import useCollaboration from '../../use-collaboration';
import JoinSessionInfo from './join-session-info';

const StartView: FC = () => {
    const { collaborationId } = useCollaboration();

    useEffect(() => {
        getSessionDocumentRef(collaborationId).update({
            activeContent: { type: SlideType.START },
        });
    }, [collaborationId]);

    return (
        <Fragment>
            <JoinSessionInfo />
            <Participants />
            <BgNightDecorator />
        </Fragment>
    );
};

export default StartView;
