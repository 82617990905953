import React from 'react';
import classnames from 'classnames';
import { ReactComponent as IconPrev } from 'images/icons/prev.svg';
import { ReactComponent as IconNext } from 'images/icons/next.svg';
import styles from './navigation-button.module.scss';
import { preventDefault } from 'services/visual-style.service';

interface Props {
    direction: 'left' | 'right';
    className?: string;
    onClick: () => void;
    dotCount: number;
    activeDot: number;
}

const NavigationButton: React.FC<Props> = ({
    direction,
    onClick,
    className,
    dotCount,
    activeDot,
}: Props) => {
    const dots =
        dotCount === 0
            ? null
            : Array(dotCount)
                .fill(0)
                .map((item, index) => (
                    <span
                        key={index}
                        className={classnames(
                            styles.dot,
                            activeDot === index && styles.active
                        )}
                    />
                ));

    return (
        <button
            onClick={onClick}
            onMouseDown={preventDefault}
            className={classnames(
                styles.navigationButton,
                styles[direction],
                className
            )}
        >
            {direction === 'left' ? (
                <IconPrev title='previous' />
            ) : (
                <IconNext title='next' />
            )}
            <div className={styles.dots}>{dots}</div>
        </button>
    );
};

export default NavigationButton;
