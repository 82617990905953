import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useSwiperContext from 'hooks/use-swiper-context';

import { Button } from '../buttons/button';

type SwiperNavProps = {
    disable?: boolean;
};

const StyledContainer = styled(Stack)`
    .disabled {
        opacity: 0;
    }
`;

const PaginationContainer = styled(Box)`
    border: 1px dashed;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

export const SwiperNav: FC<SwiperNavProps> = ({ disable }) => {
    const { swiper, activeIndex, paginationDefs } = useSwiperContext();
    const { t } = useTranslation();

    const isFirstSlide = activeIndex === 0;
    const isLastSlide = activeIndex === paginationDefs.length - 1;
    const totalSlideCount = paginationDefs.filter((d) => !!d).length;

    const curPage =
        activeIndex != null && paginationDefs[activeIndex]
            ? paginationDefs.slice(0, activeIndex).length + 1
            : undefined;

    return (
        <StyledContainer display='flex' flexDirection='row' gap={2}>
            <Box
                alignItems='center'
                display='flex'
                flex={1}
                justifyContent='flex-end'
            >
                {swiper?.allowSlidePrev && !isFirstSlide && (
                    <Button
                        appearance='text'
                        disabled={disable}
                        leftIcon='arrow_left'
                        onClick={(e) => {
                            e.stopPropagation();
                            swiper.slidePrev();
                        }}
                        size='small'
                    >
                        {t('direction', { context: 'prev' })}
                    </Button>
                )}
            </Box>

            <PaginationContainer>
                <Typography component='span' align='center' variant='caption'>
                    {`${curPage ?? '-'} / ${totalSlideCount}`}
                </Typography>
            </PaginationContainer>

            <Box flex={1} display='flex' alignItems='center'>
                {swiper?.allowSlideNext && !isLastSlide && (
                    <Button
                        appearance='text'
                        disabled={disable}
                        onClick={(e) => {
                            e.stopPropagation();
                            swiper.slideNext();
                        }}
                        rightIcon='arrow_right'
                        size='small'
                    >
                        {t('direction', { context: 'next' })}
                    </Button>
                )}
            </Box>
        </StyledContainer>
    );
};
