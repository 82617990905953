import { AuthRoles, PermissionActionTypes, PermissionMap } from './utils';

const permissions: PermissionMap = {
    [AuthRoles.UNAUTHENTICATED]: [
        PermissionActionTypes.FREEMIUM,
        PermissionActionTypes.JOIN_COLLABORATION,
    ],
    [AuthRoles.INACTIVE]: [
        PermissionActionTypes.FAVOURITE,
        PermissionActionTypes.JOIN_COLLABORATION,
    ],
    [AuthRoles.PREMIUM]: [
        PermissionActionTypes.FAVOURITE,
        PermissionActionTypes.COLLABORATION,
        PermissionActionTypes.JOIN_COLLABORATION,
    ],
    [AuthRoles.PRO]: [
        PermissionActionTypes.FAVOURITE,
        PermissionActionTypes.COLLABORATION,
        PermissionActionTypes.JOIN_COLLABORATION,
    ],
};

export default permissions;
