import { Language } from '_types/language';

export interface RouteList {
    about: string;
    account: AccountRoutes;
    contact: string;
    conversation: string;
    createAccountConfirmation: string;
    createConversation: string;
    createPremium: string;
    createPremiumPro: string;
    endSubscription: string;
    error: string;
    faq: string;
    myLibrary: string;
    favouriteConversations: string;
    favouriteQuestionsList: string;
    index: string;
    login: string;
    nativeGreeting: string;
    questionInfoPage: string;
    myConversationInfoPage: string;
    passReset: string;
    paymentOptionSelect: string;
    privacyTerms: string;
    proCodeSubPage: string;
    proconversation: string;
    proindex: string;
    search: string;
    setNewPass: string;
    tnc: string;
    userConfirm: string;
    userConfirmPro: string;
    userConfirmDeleted: string;
    iOSSubscriptions: string;
    googleSubscriptions: string;
    editConversationColorAndTexts: string;
    editConversationQuestions: string;
    joinCollaboration: string; // TODO: Temporary solution - this should be deployed as an isolated app
    collaborationOverview: string;
    collaborationReview: string;
    proProductInfoPage: string;
    ProSwitchConfirmationPage: string;
    companySignupPage: string;
    companySignupConfirmationPage: string;
    confirmReservedEmail: string;
}

export interface AccountRoutes {
    changeCard: string;
    changePassword: string;
    changePersonal: string;
    companyAdminPage: string;
    deleteAccount: string;
    index: string;
    language: string;
    notifications: string;
    recoverAccount: string;
    recoverAccountConfirmationPage: string;
    settings: string;
    subscription: string;
}

const NO: Language = 'nb';
const EN: Language = 'en';

export const routesNO: RouteList = {
    about: `/${NO}/om-fuelbox`,
    account: {
        index: `/${NO}/min-konto`,
        changeCard: 'endre-kort',
        changePassword: 'endre-passord',
        changePersonal: 'change-personal',
        companyAdminPage: 'bedrift-admin',
        deleteAccount: 'slett-konto',
        language: 'spraak',
        notifications: 'varslinger',        
        settings: 'instillinger',
        subscription: 'abonnement',
        recoverAccount: 'gjennopprett-konto',
        recoverAccountConfirmationPage: 'gjennopprett-konto-bekreftelse',
    },
    contact: `/${NO}/kontakt-oss`,
    conversation: `/${NO}/samtale`,
    createAccountConfirmation: `/${NO}/bekreft-e-post`,
    createConversation: `/${NO}/opprett-samtale`,
    createPremium: `/${NO}/opprett-bruker`,
    createPremiumPro: `/${NO}/opprett-bruker-proff`,
    endSubscription: `/${NO}/min-konto/abonnement/avslutt-abonnement`,
    error: `/${NO}/feil`,
    faq: `/${NO}/faq`,
    myLibrary: `/${NO}/mitt-bibliotek`,
    favouriteConversations: `/${NO}/likte-samtaler`,
    favouriteQuestionsList: `/${NO}/likte-sporsmal-liste`,
    index: `/${NO}`,
    login: `/${NO}/logg-inn`,
    nativeGreeting: `/${NO}/native`,
    questionInfoPage: `/${NO}/question`,
    myConversationInfoPage: `/${NO}/min-samtale`,
    passReset: `/${NO}/nytt-passord`,
    paymentOptionSelect: '/betaling',
    privacyTerms: `/${NO}/personvernerklaering`,
    proCodeSubPage: `/${NO}/min-konto/abonnement/proff-kode`,
    proconversation: `/${NO}/prosamtale`,
    proindex: `/${NO}/pro`,
    search: `/${NO}/sok`,
    setNewPass: `/${NO}/resetPwd`,
    tnc: `/${NO}/bruk`,
    userConfirm: `/${NO}/confirm`,
    userConfirmPro: `/${NO}/confirm-pro`,
    userConfirmDeleted: `/${NO}/confirm-deleted`,
    iOSSubscriptions: `/${NO}/iossubscriptions`,
    googleSubscriptions: `/${NO}/googlesubscriptions`,
    editConversationColorAndTexts: `/${NO}/samtale-endre-info`,
    editConversationQuestions: `/${NO}/samtale-endre-sporsmal`,
    // NOTE: Join route needs to be identical in all languages to avoid redirect to home page if a an existing user uses the app in another language than what's shown on screen
    joinCollaboration: 'join',
    collaborationOverview: `/${NO}/polling`,
    collaborationReview: 'se-gjennom',
    proProductInfoPage: `/${NO}/proff-produkt-info`,
    ProSwitchConfirmationPage: `/${NO}/proff-bekreftelse`,
    companySignupPage: `/${NO}/bedrift-opprett`,
    companySignupConfirmationPage: `/${NO}/bedrift-opprett-bekreft`,
    confirmReservedEmail: `/${NO}/confirm-reserved-email`,
};

export const routesEN: RouteList = {
    about: `/${EN}/about`,
    account: {
        index: `/${EN}/account`,
        changeCard: 'change-card',
        changePassword: 'change-password',
        changePersonal: 'change-personal',
        companyAdminPage: 'company-admin',
        deleteAccount: 'delete-account',
        language: 'language',
        notifications: 'notifications',        
        settings: 'settings',
        subscription: 'subscription',
        recoverAccount: 'recover-account',
        recoverAccountConfirmationPage: 'recover-account-confirmation-page',
    },
    contact: `/${EN}/contact-us`,
    conversation: `/${EN}/deck`,
    createAccountConfirmation: `/${EN}/confirm-email`,
    createConversation: `/${EN}/create-conversation`,
    createPremium: `/${EN}/create-user`,
    createPremiumPro: `/${EN}/create-user-pro`,
    endSubscription: `/${EN}/account/subscription/end-subscription`,
    proCodeSubPage: `/${EN}/account/subscription/pro-code`,
    error: `/${EN}/error`,
    faq: `/${EN}/faq`,
    myLibrary: `/${EN}/my-library`,
    favouriteConversations: `/${EN}/liked-conversations`,
    favouriteQuestionsList: `/${EN}/liked-questions`,
    index: `/${EN}`,
    login: `/${EN}/login`,
    nativeGreeting: `/${EN}/native`,
    questionInfoPage: `/${EN}/question`,
    myConversationInfoPage: `/${EN}/my-conversation`,
    passReset: `/${EN}/password-reset`,
    paymentOptionSelect: '/payment-option-select',
    privacyTerms: `/${EN}/privacy-policy`,
    proconversation: `/${EN}/prodeck`,
    proindex: `/${EN}/pro`,
    search: `/${EN}/search`,
    setNewPass: `/${EN}/resetPwd`,
    tnc: `/${EN}/terms`,
    userConfirm: `/${EN}/confirm`,
    userConfirmPro: `/${EN}/confirm-pro`,
    userConfirmDeleted: `/${EN}/confirm-deleted`,
    iOSSubscriptions: `/${EN}/iossubscriptions`,
    googleSubscriptions: `/${EN}/googlesubscriptions`,
    editConversationColorAndTexts: `/${EN}/conversation-change-info`,
    editConversationQuestions: `/${EN}/samtale-change-questions`,
    joinCollaboration: 'join',
    collaborationOverview: `/${EN}/polling`,
    collaborationReview: 'review',
    proProductInfoPage: `/${EN}/pro-product-info`,
    ProSwitchConfirmationPage: `/${EN}/pro-confirmation`,
    companySignupPage: `/${EN}/company-signup`,
    companySignupConfirmationPage: `/${EN}/company-signup-confirm`,
    confirmReservedEmail: `/${EN}/confirm-reserved-email`,
};
