import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-code-input';

import { IconButton, Stack, Typography, useTheme } from 'components/base';
import styles from '../join-collaboration-page.module.scss';
import { useNavigate } from 'react-router-dom';
import Layout from './layout';
import useHistoryRouter from 'hooks/use-history-router';

const JoinCode: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { routes } = useHistoryRouter();

    const [code, setCode] = useState('');

    // TODO: Validate active session in backend before navigating
    useEffect(() => {
        if (code.length === 5) navigate(code);
    }, [code]);

    return (
        <Layout>
            <IconButton
                aria-label={t('close')}
                className={styles.closeButton}
                icon='close'
                onClick={() => navigate(routes.index)}
            />
            <Stack spacing={2}>
                <Typography align='center'>
                    <strong>{t('joinCollaboration.register.code')}</strong>
                </Typography>

                <ReactCodeInput
                    onChange={setCode}
                    value={code}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    inputMode='numeric'
                    className={styles.reactCodeInput}
                    type='number'
                    name='join-code'
                    fields={5}
                    inputStyle={{
                        fontFamily: theme.typography.fontFamily,
                        borderRadius: `${
                            Number(theme.shape.borderRadius) / 2
                        }px`,
                        margin: theme.spacing(0.5),
                    }}
                />
            </Stack>
        </Layout>
    );
};

export default JoinCode;
