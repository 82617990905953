import * as actionTypes from './_actionTypes';
import {
    SetFavouriteConversations,
    ClearFavouriteConversations,
} from './_types';
import { ConversationWithCategory } from '_types';

export const setFavouriteConversations = (
    favouriteConversations: ConversationWithCategory[]
): SetFavouriteConversations => ({
    type: actionTypes.SET_FAVOURITE_CONVERSATIONS,
    payload: favouriteConversations,
});

export const clearFavouriteConversations = (): ClearFavouriteConversations => ({
    type: actionTypes.CLEAR_FAVOURITE_CONVERSATIONS,
});
