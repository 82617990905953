import { RootReducer } from 'store/store';
import { UserState } from './_types';
import { User } from '_types';
import { Subscription } from '_types/subscription';
import { Product } from 'pages/create-premium-page/_types';
import { SessionResponse } from 'pages/create-premium-page/_services';

const userStateFromRoot = (state: RootReducer): UserState => state.user;

export const userSelector = (state: RootReducer): User | null =>
    userStateFromRoot(state).user;

export const subscriptionSelector = (state: RootReducer): Subscription | null =>
    userStateFromRoot(state).subscription;

export const isPremiumSelector = (state: RootReducer): boolean => {
    const sub = userStateFromRoot(state).subscription;

    return !!sub && sub.type === 'premium';
};

export const selectedProductSelector = (state: RootReducer): Product | null =>
    userStateFromRoot(state).selectedProduct;

export const paymentSessionSelector = (state: RootReducer): SessionResponse =>
    userStateFromRoot(state).session;

export const toastMessageSelector = (state: RootReducer): string =>
    userStateFromRoot(state).toastMessage;

export const boldToastMessageSelector = (state: RootReducer): string =>
    userStateFromRoot(state).boldToastMessage;
