import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import SwiperInstance from 'swiper';
import SwiperContext, { SwiperContextValues } from './context';

type SwiperContextProviderProps = Pick<
    SwiperContextValues,
    'paginationDefs' | 'setSwiper' | 'swiper'
> & {
    children: ReactNode;
};

const SwiperContextProvider: FC<SwiperContextProviderProps> = ({
    children,
    swiper,
    setSwiper,
    paginationDefs,
}) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const paginationRef = useRef(null);

    useEffect(() => {
        paginationRef?.current &&
            !swiper?.pagination?.el &&
            swiper?.pagination &&
            swiper?.pagination.init();
    }, [paginationRef?.current, swiper]);

    useEffect(() => {
        ((nextRef?.current && !swiper?.navigation?.nextEl) ||
            (prevRef?.current && !swiper?.navigation?.nextEl)) &&
            swiper?.navigation &&
            swiper?.navigation.init();
    }, [nextRef?.current, prevRef?.current, swiper]);

    const [activeIndex, setActiveIndex] = useState(swiper?.activeIndex);

    const handleSetSwiper = (s: SwiperInstance) => {
        setActiveIndex(s.activeIndex);
        setSwiper(s);
    };

    const values: SwiperContextValues = {
        activeIndex,
        nextRef,
        paginationDefs,
        paginationRef,
        prevRef,
        setSwiper: handleSetSwiper,
        swiper,
    };

    return (
        <SwiperContext.Provider value={values}>
            {children}
        </SwiperContext.Provider>
    );
};

export default SwiperContextProvider;
