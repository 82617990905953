import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';

const DeepLink: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            const hosts = ['fuelbox.no', 'fuelboxworld.com'];
            const slugNO = '/nb';
            const slugEN = '/en';
            for (const host of hosts) {
                let slug = event.url.split(host).pop();
                const fuelboxWorld =
                    host === 'fuelboxworld.com' &&
                    event.url.includes('fuelboxworld.com');
                const fuelbox =
                    host === 'fuelbox.no' && event.url.includes('fuelbox.no');

                if (!slug) slug = !!fuelboxWorld ? slugEN : slugNO;

                if (slug && (fuelbox || fuelboxWorld)) {
                    navigate(slug);
                    window.location.reload();
                    break;
                }
            }
        });
    }, [navigate]);

    return null;
};

export default DeepLink;
