import * as React from 'react';
import styles from './mobile-user-table.module.scss';
import { ReactComponent as SearchIcon } from 'images/icons/magnifier.svg';
import { useCallback, useEffect, useState } from 'react';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import { Checkbox, Icon, IconButton } from 'components/base';
import classnames from 'classnames';
import { BackButton } from 'components/back-button';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { User } from './company-admin';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Language } from '_types/language';
import companyClient from 'api/company-client';
import { useNavigate } from 'react-router-dom';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { CompanyUser } from '_types/company-user';

interface Props {
    setToast: (toast: string) => void;
    rows: User[];
    setUsers: (users: CompanyUser[]) => void;
    setIsLoading: (isLoading: boolean) => void;
}

enum SortType {
    NAME_ASCENDING,
    NAME_DESCENDING,
    MEMBER_SINCE_ASCENDING,
    MEMBER_SINCE_DESCENDING,
}
interface ViewCopy {
    infoText1: string;
    infoText2: string;
    header: string;
    removeHeader: string;
    searchPlaceHolder: string;
    toggleRemoveUsersLabel: string;
    toggleSortUsersLabel: string;
    memberSinceLabel: string;
    selectedLabel: string;
    removeButtonLabel: string;
    sortHeader: string;
    nameAscendingLabel: string;
    nameDescendingLabel: string;
    memberSinceAscendingLabel: string;
    memberSinceDescendingLabel: string;
    cancelLabel: string;
    usersDeletedToast: string;
}

const nbCopy: ViewCopy = {
    infoText1: 'Trenger du flere brukere?',
    infoText2: 'Kontakt oss på epost',
    header: 'Brukere',
    removeHeader: 'Fjern brukere',
    searchPlaceHolder: 'Søk etter brukere',
    toggleRemoveUsersLabel: 'Fjern brukere',
    toggleSortUsersLabel: 'Sorter liste',
    memberSinceLabel: 'Medlem siden',
    selectedLabel: 'valgt',
    removeButtonLabel: 'Fjern',
    sortHeader: 'Sorter etter',
    nameAscendingLabel: 'Navn - Fra A',
    nameDescendingLabel: 'Navn - Fra Å',
    memberSinceDescendingLabel: 'Nyeste brukere',
    memberSinceAscendingLabel: 'Eldste brukere',
    cancelLabel: 'Avbryt',
    usersDeletedToast: 'Brukerne er fjernet fra abonnementet.',
};

const enCopy: ViewCopy = {
    infoText1: 'Need to add more users?',
    infoText2: 'Contact us at',
    header: 'Users',
    removeHeader: 'Remove users',
    searchPlaceHolder: 'Search for users',
    toggleRemoveUsersLabel: 'Remove users',
    toggleSortUsersLabel: 'Sort list',
    memberSinceLabel: 'Member since',
    selectedLabel: 'selected',
    removeButtonLabel: 'Remove',
    sortHeader: 'Sort by',
    nameAscendingLabel: 'Name - From A',
    nameDescendingLabel: 'Name - From Z',
    memberSinceDescendingLabel: 'Newest users',
    memberSinceAscendingLabel: 'Oldest users',
    cancelLabel: 'Cancel',
    usersDeletedToast: 'The users have been removed from the subscription.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const MobileUserTable = ({ setToast, rows, setUsers, setIsLoading }: Props) => {
    const language = useSelector(languageSelector);
    const {
        infoText1,
        infoText2,
        header,
        removeHeader,
        searchPlaceHolder,
        toggleRemoveUsersLabel,
        toggleSortUsersLabel,
        memberSinceLabel,
        selectedLabel,
        removeButtonLabel,
        sortHeader,
        nameAscendingLabel,
        nameDescendingLabel,
        memberSinceAscendingLabel,
        memberSinceDescendingLabel,
        cancelLabel,
        usersDeletedToast,
    } = returnCopyForLanguage(language, copies);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [searchText, setSearchText] = useState('');
    const routes = returnRoutesForLanguage(language, routeObj);
    const [isDeletingUsers, setIsDeletingUsers] = useState(false);
    const [showOptionModal, setShowOptionModal] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const navigate = useNavigate();
    const [currentSortOrder, setCurrentSortOrder] = useState<SortType>(
        SortType.NAME_ASCENDING
    );
    const fuelBoxEmail = 'post@fuelbox.no';

    useEffect(() => {
        setFilteredUsers(rows);
    }, [rows]);

    const selectedUsers = () => {
        return filteredUsers.filter((user) => user.markedForDeletion);
    };

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSearchText(event.target.value);

            if (event.target.value.trim().length > 0) {
                const filteredUsers = [];
                for (let i = 0; i < rows.length; i++) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const row: any = rows[i];

                    if (
                        row.email
                            .toLocaleLowerCase()
                            .includes(event.target.value.toLocaleLowerCase()) ||
                        row.firstName
                            .toLocaleLowerCase()
                            .includes(event.target.value.toLocaleLowerCase()) ||
                        row.lastName
                            .toLocaleLowerCase()
                            .includes(event.target.value.toLocaleLowerCase()) ||
                        row.memberSince
                            .toLocaleLowerCase()
                            .includes(event.target.value.toLocaleLowerCase())
                    ) {
                        filteredUsers.push(row);
                    } else {
                        filteredUsers.slice(i, 1);
                    }
                }
                setFilteredUsers(filteredUsers);
            } else {
                setFilteredUsers(rows);
            }
        },
        []
    );

    const sortUsers = (sortType: SortType) => {
        const tempArray = [...filteredUsers];
        switch (sortType) {
            case SortType.NAME_ASCENDING:
                tempArray.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
                break;
            case SortType.NAME_DESCENDING:
                tempArray.sort((a, b) => (a.firstName < b.firstName ? 1 : -1));
                break;
            case SortType.MEMBER_SINCE_ASCENDING:
                tempArray.sort((a, b) =>
                    new Date(a.memberSince) > new Date(b.memberSince) ? 1 : -1
                );
                break;
            case SortType.MEMBER_SINCE_DESCENDING:
                tempArray.sort((a, b) =>
                    new Date(a.memberSince) < new Date(b.memberSince) ? 1 : -1
                );
                break;
        }
        setFilteredUsers(tempArray);
    };

    const deleteUsers = (userIds: number[]) => {
        setIsLoading(true);
        companyClient
            .removeUsersFromCompany(userIds)
            .then((users) => {
                setUsers(users);
                setIsLoading(false);
                setToast(usersDeletedToast);
            })
            .catch((error) => {
                console.error(error);
                navigate(routes.error);
            });
    };

    return (
        <div
            className={
                isDeletingUsers
                    ? classnames(
                          styles.MobileUserTable,
                          styles.MobileUserTableDeleting
                      )
                    : styles.MobileUserTable
            }
        >
            {isDeletingUsers && (
                <BackButton
                    language={language}
                    className={styles.BackButton}
                    clickHandler={() => {
                        setIsDeletingUsers(false);
                        setFilteredUsers(
                            filteredUsers.map((u) => {
                                return u.markedForDeletion === true
                                    ? {
                                          ...u,
                                          markedForDeletion: false,
                                      }
                                    : u;
                            })
                        );
                    }}
                />
            )}
            <p className={styles.Header}>
                {isDeletingUsers ? removeHeader : header}
            </p>
            {!isDeletingUsers && (
                <div>
                    <p className={styles.InfoText1}>{infoText1}</p>
                    <p className={styles.InfoText2}>
                        {infoText2}{' '}
                        <a href='mailto:post@fuelbox.no'> {fuelBoxEmail}</a>
                    </p>
                </div>
            )}
            <div className={styles.TableControllers}>
                <div className={styles.SearchFieldContainer}>
                    <div className={styles.SearchIcon}>
                        <SearchIcon />
                    </div>
                    <input
                        type={'text'}
                        id={'sok'}
                        placeholder={searchPlaceHolder}
                        className={styles.SearchField}
                        value={searchText}
                        autoComplete='off'
                        onChange={onInputChanged}
                        onKeyDown={(e) => {
                            if (primaryKeyPressed(e)) {
                                if (e.key === 'Enter') {
                                    // search(searchText);
                                    try {
                                        (
                                            document.activeElement as HTMLElement
                                        ).blur();
                                    } catch {
                                        console.error(
                                            'Failed to hide native keyboard.'
                                        );
                                    }
                                }
                            }
                        }}
                        onFocus={onInputChanged}
                        //   onBlur={onInputBlur}
                    ></input>
                </div>
                <IconButton
                    aria-label={'options'}
                    className={styles.MoreOptions}
                    icon={isDeletingUsers ? 'import_export' : 'more_vert'}
                    onClick={() =>
                        isDeletingUsers
                            ? setIsSorting(true)
                            : setShowOptionModal(!showOptionModal)
                    }
                />
            </div>
            {showOptionModal && (
                <div className={styles.OptionsModal}>
                    <button
                        className={styles.RemoveUsersToggle}
                        onClick={() => {
                            setIsDeletingUsers(true);
                            setShowOptionModal(false);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                            });
                        }}
                    >
                        <Icon name='delete' />
                        <p className={styles.RemoveUsersToggleText}>
                            {toggleRemoveUsersLabel}
                        </p>
                    </button>
                    <button
                        className={styles.SortUsersToggle}
                        onClick={() => {
                            setIsSorting(true);
                            setShowOptionModal(false);
                        }}
                    >
                        <Icon name='import_export' />
                        <p className={styles.SortUsersToggleText}>
                            {toggleSortUsersLabel}
                        </p>
                    </button>
                </div>
            )}
            <div className={styles.TableCards}>
                {filteredUsers.map((user) => {
                    const memberSinceDate = new Date(user.memberSince);
                    return (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                        <div
                            className={
                                user.markedForDeletion
                                    ? classnames(
                                          styles.Card,
                                          styles.CheckedCard,
                                          isDeletingUsers && styles.DeleteCard
                                      )
                                    : classnames(
                                          styles.Card,
                                          isDeletingUsers && styles.DeleteCard
                                      )
                            }
                            key={user.id}
                        >
                            {isDeletingUsers ? (
                                <label
                                    htmlFor={user.id.toLocaleString()}
                                    className={classnames(
                                        styles.CheckBox,
                                        styles.DeleteCheckbox
                                    )}
                                >
                                    <Checkbox
                                        label={undefined}
                                        id={user.id.toLocaleString()}
                                        value={user.markedForDeletion}
                                        onChange={() => {
                                            setFilteredUsers(
                                                filteredUsers.map((u) => {
                                                    return u.id === user.id
                                                        ? {
                                                              ...u,
                                                              markedForDeletion:
                                                                  !u.markedForDeletion,
                                                          }
                                                        : u;
                                                })
                                            );
                                        }}
                                    />
                                </label>
                            ) : (
                                <div className={styles.UserLogo}>
                                    <Icon
                                        name={'person_outlined'}
                                        fontSize='small'
                                    />
                                </div>
                            )}
                            <label
                                htmlFor={user.id.toLocaleString()}
                                className={classnames(
                                    styles.UserInfoContainer,
                                    isDeletingUsers &&
                                        styles.DeleteUserInfoContainer
                                )}
                            >
                                <p className={styles.Name}>
                                    {user.firstName} {user.lastName}
                                </p>
                                <p className={styles.Email}>{user.email}</p>
                                <p className={styles.MemberSince}>
                                    {memberSinceLabel}{' '}
                                    {('0' + memberSinceDate.getDate()).slice(
                                        -2
                                    ) +
                                        '.' +
                                        (
                                            '0' +
                                            (memberSinceDate.getMonth() + 1)
                                        ).slice(-2) +
                                        '.' +
                                        memberSinceDate.getFullYear()}
                                </p>
                            </label>
                        </div>
                    );
                })}
            </div>
            {isDeletingUsers && (
                <div className={styles.DeleteFooter}>
                    <div className={styles.SelectedForDeleteText}>
                        {' '}
                        {selectedUsers().length} {selectedLabel}
                    </div>
                    <button
                        className={styles.RemoveUsersButton}
                        onClick={() => {
                            setIsDeletingUsers(false);
                            const userIdsToDelete = [];
                            for (let i = 0; i < filteredUsers.length; i++) {
                                const user = filteredUsers[i];
                                !!user.markedForDeletion &&
                                    userIdsToDelete.push(user.id);
                            }
                            //DELETE ids in userIdsToDelete
                            deleteUsers(userIdsToDelete);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                            });
                        }}
                    >
                        <Icon name='delete' />
                        <p className={styles.RemoveUsersButtonText}>
                            {removeButtonLabel}
                        </p>
                    </button>
                </div>
            )}
            {isSorting && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div
                    className={styles.SortModalContainer}
                    onClick={() => setIsSorting(false)}
                >
                    <div className={styles.SortModal}>
                        <p className={styles.SortHeader}>{sortHeader}</p>
                        <div className={styles.SortOptions}>
                            <button
                                className={styles.SortOption}
                                onClick={() => {
                                    sortUsers(SortType.NAME_ASCENDING);
                                    setCurrentSortOrder(
                                        SortType.NAME_ASCENDING
                                    );
                                }}
                            >
                                {currentSortOrder ===
                                SortType.NAME_ASCENDING ? (
                                    <Icon
                                        name={'check'}
                                        fontSize='small'
                                    ></Icon>
                                ) : (
                                    <div className={styles.IconPlaceholder} />
                                )}
                                {nameAscendingLabel}
                            </button>
                            <button
                                className={styles.SortOption}
                                onClick={() => {
                                    sortUsers(SortType.NAME_DESCENDING);
                                    setCurrentSortOrder(
                                        SortType.NAME_DESCENDING
                                    );
                                }}
                            >
                                {currentSortOrder ===
                                SortType.NAME_DESCENDING ? (
                                    <Icon
                                        name={'check'}
                                        fontSize='small'
                                    ></Icon>
                                ) : (
                                    <div className={styles.IconPlaceholder} />
                                )}
                                {nameDescendingLabel}
                            </button>
                            <button
                                className={styles.SortOption}
                                onClick={() => {
                                    sortUsers(SortType.MEMBER_SINCE_DESCENDING);
                                    setCurrentSortOrder(
                                        SortType.MEMBER_SINCE_DESCENDING
                                    );
                                }}
                            >
                                {currentSortOrder ===
                                SortType.MEMBER_SINCE_DESCENDING ? (
                                    <Icon
                                        name={'check'}
                                        fontSize='small'
                                    ></Icon>
                                ) : (
                                    <div className={styles.IconPlaceholder} />
                                )}
                                {memberSinceDescendingLabel}
                            </button>
                            <button
                                className={styles.SortOption}
                                onClick={() => {
                                    sortUsers(SortType.MEMBER_SINCE_ASCENDING);
                                    setCurrentSortOrder(
                                        SortType.MEMBER_SINCE_ASCENDING
                                    );
                                }}
                            >
                                {currentSortOrder ===
                                SortType.MEMBER_SINCE_ASCENDING ? (
                                    <Icon
                                        name={'check'}
                                        fontSize='small'
                                    ></Icon>
                                ) : (
                                    <div className={styles.IconPlaceholder} />
                                )}
                                {memberSinceAscendingLabel}
                            </button>
                        </div>
                        <button
                            type='button'
                            className={styles.ButtonLink}
                            onClick={() => setIsSorting(false)}
                        >
                            {cancelLabel}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileUserTable;
