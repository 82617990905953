import restClient from './rest-client';

interface InitOrderData {
    productId: string | number | undefined;
    code?: string | undefined;
}

interface OrderResponse {
    order_id: number;
    discountPrice: number;
}

export default {
    createOrder: (initData: InitOrderData): Promise<OrderResponse> => {
        return restClient.get<OrderResponse>(
            `/createOrderGooglePay?productId=${initData.productId}&code=${initData.code}`,
            false
        );
    },
};
