import classNames from 'classnames';
import React from 'react';
import styles from './conversation-list.module.scss';

interface Props {
    className?: string;
    children?: React.ReactNode;
}

const ConversationList: React.FC<Props> = ({ className, children }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.list}>{children}</div>
        </div>
    );
};

export default ConversationList;
