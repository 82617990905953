import authService from 'services/auth-service';
import { Language } from '_types/language';
import {
    getLocalStorageLanguage,
    getDomainLanguageDefault,
} from 'services/language-service';
import { IS_IOS_APP } from '../constants/mobile-app-constants';
import { User } from '_types';

const url = window.location.href;
const isLocalhost = url.indexOf('localhost') !== -1;
const isDev = url.indexOf('develop') !== -1;
const isTest = url.indexOf('test') !== -1;
const isStaging = url.indexOf('staging') !== -1;
const isProd = !isDev && !isTest && !isStaging && !isLocalhost;
const isFuelBoxWorld = url.indexOf('fuelboxworld') !== -1;

export const getEnvAPI = (): string | undefined => {
    if (isStaging) return process.env.REACT_APP_API_URL_STAGING;
    if (isTest) return process.env.REACT_APP_API_URL_TEST;
    if (isDev || isProd) return process.env.REACT_APP_API_URL;
    return process.env.REACT_APP_API_URL;
};

export const getEnv = (): string => {
    if (isProd) return 'prod';
    if (isStaging) return 'staging';
    if (isTest) return 'test';
    if (isDev) return 'dev';
    if (IS_IOS_APP) return 'ios';
    return 'localhost';
};

const API_URL = getEnvAPI();
const DEFAULT_BASE_URL = '/wp-json/fuelbox/v1';

interface RestClientConfig {
    baseUrl?: string;
    params?: object;
}
interface CustomHeadersConfig {
    name: string;
    value: string;
}

const esc = encodeURIComponent;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const searchQuery = (params: any): string =>
    Object.keys(params)
        .map((k) => `${esc(k)}=${esc(params[k])}`)
        .join('&');

export const handleFetchError = (response: Response) => {
    if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
            authService.logout();
        }

        return response.json().then((body) => Promise.reject(body));
    }

    return response;
};

const getUrl = (
    baseUrl: string,
    relativeUrl: string,
    params?: object
): string => {
    const url = `${API_URL}${baseUrl}${relativeUrl}`;

    return params ? `${url}?${searchQuery(params)}` : url;
};

const getHeaders = (
    customHeaders?: CustomHeadersConfig[]
): HeadersInit | undefined => {
    const langFromDomain: Language = getDomainLanguageDefault();
    const langFromStorage: Language | null = getLocalStorageLanguage();
    const language = !!langFromStorage ? langFromStorage : langFromDomain;

    const headers: HeadersInit = {
        'Access-Control-Allow-Origin' : 'http://localhost:3000',
        'content-type': 'application/json',
        'Accept-Language': language,
    };

    const token = localStorage.getItem('token');

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    if (customHeaders?.length) {
        customHeaders.forEach((element) => {
            headers[element.name] = element.value;
        });
    }

    return headers;
};

function client<T>(
    url: string,
    customConfig: RequestInit,
    useCacheDatabase?: boolean,
    customHeaders?: CustomHeadersConfig[],
    firebaseParams?: object | null,
    user?: User
): Promise<T> {
    const config: RequestInit = {
        ...customConfig,
        headers: getHeaders(customHeaders),
    };
    //const langFromDomain: Language = getDomainLanguageDefault();
    // const langFromStorage: Language | null = getLocalStorageLanguage();
    //const language = langFromDomain;
 /*   let fireBaseURL = isProd
        ? `https://europe-west1-fuelbox-app.cloudfunctions.net/getData?url=${url}&acceptLanguage=${language}&contentType=application/json`
        : `https://europe-west1-fuelbox-app.cloudfunctions.net/getData?url=${url}&acceptLanguage=${language}&contentType=application/json&env=${getEnv()}`;
*/
    if (user) {
      //  fireBaseURL += `&user=${user?.id}&org=${user?.organization}`;
    }

    //This is just used to get seperate cache in CDN for fuelboxworld, if not we have CORS issue
    if (isFuelBoxWorld) {
    //      fireBaseURL += '&fbw=y';
    }

    if (firebaseParams) {
   //     fireBaseURL += `&${searchQuery(firebaseParams)}`;
    }

    return fetch(url, config)
        .then(handleFetchError)
        .then((response) => response.json().catch(console.error) as Promise<T>);
}

export default {
    get<T>(
        relativeUrl: string,
        useCacheDatabase?: boolean,
        params?: object,
        fireBaseParams?: object | null,
        { baseUrl = DEFAULT_BASE_URL }: RestClientConfig = {},
        useFirebase?: boolean,
    ): Promise<T> {
        const url = useFirebase ? baseUrl + relativeUrl + `?${searchQuery(params)}`: getUrl(baseUrl, relativeUrl, params);

        const config = {
            method: 'GET',
        };

        return client(url, config, useCacheDatabase, undefined, fireBaseParams);
    },
    post<D, T>(
        relativeUrl: string,
        data: D,
        { baseUrl = DEFAULT_BASE_URL }: RestClientConfig = {},
        customHeaders?: CustomHeadersConfig[],
        useFirebase?: boolean,
    ): Promise<T> {
        //const url = getUrl(baseUrl, relativeUrl);
        const url = useFirebase ? baseUrl + relativeUrl : getUrl(baseUrl, relativeUrl);

        const config = {
            method: 'POST',
            body: JSON.stringify(data),
        };

        return client(url, config, false, customHeaders);
    },
    put<D, T>(
        relativeUrl: string,
        data: D,
        { baseUrl = DEFAULT_BASE_URL }: RestClientConfig = {},
        useFirebase?: boolean
    ): Promise<T> {
        //const url = getUrl(baseUrl, relativeUrl);
        const url = useFirebase ? baseUrl + relativeUrl : getUrl(baseUrl, relativeUrl);

        const config = {
            method: 'PUT',
            body: JSON.stringify(data),
        };

        return client(url, config);
    },
};
