import React from 'react';
import classnames from 'classnames';
import { Deck } from '_types/deck';
import styles from './free-decks-list.module.scss';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import { preventDefault } from '../../services/visual-style.service';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';

interface Props {
    decks: Deck[];
    onSelectDeck: (deck: Deck) => void;
}
interface ViewCopy {
    questionsLabel: string;
}

const nbCopy: ViewCopy = {
    questionsLabel: 'spørsmål',
};

const enCopy: ViewCopy = {
    questionsLabel: 'questions',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const FreeDecksList: React.FC<Props> = ({ decks, onSelectDeck }: Props) => {
    const language: Language = useSelector(languageSelector);
    const { questionsLabel } = returnCopyForLanguage(language, copies);
    return (
        <div className={styles.decksList} aria-live='polite'>
            {decks.map((deck, index) => (
                <div key={deck.id} className={styles.item}>
                    <div
                        className={styles.deckCard}
                        role='link'
                        onMouseDown={(e) => preventDefault(e)}
                        onClick={() => onSelectDeck(deck)}
                        onKeyDown={(e) => {
                            if (primaryKeyPressed(e)) {
                                onSelectDeck(deck);
                            }
                        }}
                        tabIndex={0}
                    >
                        <div
                            className={classnames(
                                styles.bg,
                                index === 1 && styles.bg1,
                                index === 5 && styles.bg2
                            )}
                        />
                        <div className={styles.textBlock}>
                            <h3 className={styles.title}>{deck.name}</h3>
                            <div className={styles.description}>
                                <div>
                                    {deck.count} {questionsLabel}
                                </div>

                                {deck.category && (
                                    <React.Fragment>
                                        <div className={styles.separator}>
                                            ·
                                        </div>
                                        <div className={styles.category}>
                                            {deck.category}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FreeDecksList;
