import React from 'react';
import classnames from 'classnames';
import { Layout } from 'components/layout';
import { Container } from 'components/container';
import { BackButton } from 'components/back-button';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { SEO } from 'components/seo';
import styles from './settings-subpage.module.scss';
import { returnRoutesForLanguage } from 'services/language-service';
import { useSelector } from 'react-redux';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
    pageClassName?: string;
    bgClassName?: string;
    title: string;
    pageTitle: string;
    children: React.ReactNode;
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const SettingsSubpage: React.FC<Props> = ({
    pageClassName,
    bgClassName,
    pageTitle,
    title,
    children,
}: Props) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const navigateToSettingsPage = (): void => {
        isMobile ? navigate(-1) : navigate(routes.account.index);
    };

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={classnames(styles.page, pageClassName)}
        >
            <div className={classnames(styles.bg, bgClassName)} />
            <SEO title={pageTitle} />
            <Container className={styles.container}>
                <BackButton
                    language={language}
                    className={styles.backButton}
                    clickHandler={navigateToSettingsPage}
                />
                <h1 className={styles.title}>{title}</h1>
                {children}
            </Container>
        </Layout>
    );
};

export default SettingsSubpage;
