import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

import styles from './link.module.scss';

interface OwnProps {
    className?: string;
    block?: boolean;
    highlight?: boolean;
    underline?: boolean;
    external?: boolean;
}

type Props = OwnProps & LinkProps;

const Link: React.FC<Props> = ({
    className,
    block = false,
    highlight = false,
    underline = true,
    external = false,
    children,
    ...props
}: Props) => {

    const classNames = classnames(
        styles.link,
        className,
        block && styles.block,
        highlight && styles.highlight,
        underline && styles.underline,
    );

    return external
        ? <a href={props.to as string} className={classNames}>{children}</a>
        : <RouterLink {...props} className={classNames}>{children}</RouterLink>
}

export default Link;
