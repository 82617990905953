import restClient from './rest-client';

export enum CollaborationStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type Collaboration = {
    category?: string;
    categoryName: string;
    collaborationCode?: string;
    collaborationId: string;
    collaborationStatus: CollaborationStatus;
    conversationId: string;
    createdByName?: string;
    description?: string;
    lastUpdated?: number;
    name: string;
    order: number;
    slug: string;
    startTime?: number;
    taxonomy: string;
};


type CollaborationResponse = {
    category_name: string;
    category: string;
    collaboration_code: string | null;
    collaboration_id: string;
    collaboration_status: CollaborationStatus;
    conversation_id: string;
    created_by_name: string | null;
    description: string | null;
    last_updated: number | null;
    name: string | null;
    order: number;
    slug: string | null;
    start_time: number;
    taxonomy: string | null;
};

const transformCollaborationResponse = ({
    category_name,
    category,
    collaboration_code,
    collaboration_id,
    collaboration_status,
    conversation_id,
    created_by_name,
    description,
    name,
    order,
    last_updated,
    slug,
    start_time,
    taxonomy,
}: CollaborationResponse): Collaboration => ({
    category,
    categoryName: category_name,
    collaborationCode: collaboration_code ?? undefined,
    collaborationId: collaboration_id,
    collaborationStatus: collaboration_status,
    conversationId: conversation_id,
    createdByName: created_by_name ?? undefined,
    description: description ?? '',
    name: name ?? '',
    order,
    slug: slug ?? '',
    startTime: start_time ? Number(start_time) : undefined,
    lastUpdated: last_updated ? Number(last_updated) : undefined,
    taxonomy: taxonomy ?? '',
});

const transformGetCollaborationListResponse = (
    collaborations: CollaborationResponse[]
) =>
    collaborations
        ?.map(transformCollaborationResponse)
        .sort((a, b) => (a.order < b.order ? 1 : a.order > b.order ? -1 : 0));

type ValidateCollaborationCodeResponse = {
    collaboration_id: string;
    is_collaboration_code_valid: boolean;
};

type ValidateCollaborationCode = {
    collaborationId: string;
    isCollaborationCodeValid: boolean;
};

const transformValidateCollaborationCodeResponse = ({
    collaboration_id,
    is_collaboration_code_valid,
}: ValidateCollaborationCodeResponse) => ({
    collaborationId: collaboration_id,
    isCollaborationCodeValid: is_collaboration_code_valid,
});

type RestartCollaborationResponse = {
    collaboration_id: string;
    collaboration_code: string;
};

const collaborationClient = {
    /**
     * Create collaboration
     */
    createCollaboration: (conversation_id: number, taxonomy: string): Promise<Collaboration> =>
        restClient
            .post<unknown, CollaborationResponse>(
                '/create-collaboration',
                {
                        taxonomy,
                        conversation_id,
                    },
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                [],
                true
                )
            .then(transformCollaborationResponse),

    /**
     * Copy collaboration
     */
    copyCollaboration: (id: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>('/copy-collaboration',
                false,
                {id},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(transformCollaborationResponse),

    /**
     * My collaborations
     */
    myCollaborations: (): Promise<Collaboration[]> =>
        restClient
            .get<CollaborationResponse[]>(
                '/get-my-collaborations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(transformGetCollaborationListResponse),

    /**
     * End collaboration
     */
    endCollaboration: (id: string) =>
        restClient.get('/end-collaboration',
            false,
            {id},
            {},
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            true
            ),

    /**
     * Restart collaboration
     */
    restartCollaboration: (id: string): Promise<RestartCollaborationResponse> =>
        restClient.get('/restart-collaboration',
            false,
            {id},
            {},
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            true

            ),

    /**
     * Ping API to set `last_updated`
     */
    collaborationLastUpdated: (id: string) =>
        restClient.get('/get-collaboration-last-updated',
            false,
            {id},
            {},
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            true
        ),

    /**
     * Get collaboration
     */
    getCollaboration: (id: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>('/getCollaboration?id=' + id, false)
            .then(transformCollaborationResponse),

    /**
     * Get collaboration by slug
     */
    getCollaborationBySlug: (slug: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>(
                '/get-collaboration-by-slug',
                false,
                {slug},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(transformCollaborationResponse),

    /**
     * Get collaboration with code
     */
    getCollaborationWithCode: (code: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>(
                '/getCollaborationWithCode?code=' + code,
                false
            )
            .then(transformCollaborationResponse),

    /**
     * Delete collaboration
     */
    deleteCollaboration: (id: string) =>
        restClient.get('/delete-collaboration',
            false,
            {id},
            {},
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            true
            ),

    /**
     * Validate collaboration code. Intended for participants in collaborations, to verify the code and
     * return required collaboration data.
     */
    validateCollaborationCode: (
        code: string
    ): Promise<ValidateCollaborationCode> =>
        restClient
            .get<ValidateCollaborationCodeResponse>(
                '/validate-collaboration-code',
                false,
                {code},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_OPEN},
                true
            )
            .then(transformValidateCollaborationCodeResponse),
};

export default collaborationClient;
