import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import { Divider, Typography, useMediaQuery, useTheme } from 'components/base';

import useHistoryRouter from 'hooks/use-history-router';
import useCollaboration from '../../../use-collaboration';

const ActiveSessionInfo: FC = () => {
    const {
        collaboration: { collaborationCode },
    } = useCollaboration();
    const { getJoinCollaborationUrl } = useHistoryRouter();

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Fragment>
            <Typography display='inline' variant={smScreen ? 'body1' : 'body2'}>
                <strong>{getJoinCollaborationUrl(true)}</strong>
            </Typography>

            <Divider orientation='vertical' variant='middle' flexItem />

            <Typography display='inline' variant={smScreen ? 'body1' : 'body2'}>
                <Trans
                    components={[<strong key={0} />]}
                    i18nKey='collaboration.code'
                    values={{ code: collaborationCode }}
                />
            </Typography>
        </Fragment>
    );
};

export default ActiveSessionInfo;
