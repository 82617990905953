import React from 'react';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesNO, routesEN } from 'api/routes';
import styles from './contact-page.module.scss';
import { BackButton } from 'components/back-button';
import { Link } from 'components/link';
import { useNavigate } from 'react-router-dom';

interface Props {
    language: Language;
}

interface ViewCopy {
    pageTitle: string;
    pageHeader: string;
    items: {
        title: string;
        content: string;
        isMail?: boolean;
    }[];
}

const nbCopy: ViewCopy = {
    pageTitle: 'Kontakt oss',
    pageHeader: 'Kontakt oss',
    items: [
        { title: 'Kundeservice', content: 'post@fuelbox.no', isMail: true },
        { title: 'Bedriftsnavn', content: 'FuelBox AS' },
        { title: 'Adresse', content: 'Bispevegen 4, 4340 Bryne' },
    ],
};

const enCopy: ViewCopy = {
    pageTitle: 'Contact us',
    pageHeader: 'Contact us',
    items: [
        {
            title: 'Customer service',
            content: 'post@fuelboxworld.com',
            isMail: true,
        },
        { title: 'Company name', content: 'FuelBox AS' },
        { title: 'Address', content: 'Bispevegen 4, 4340 Bryne, Norway' },
    ],
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ContactPage: React.FC<Props> = ({ language }) => {
    const navigate = useNavigate();
    const { pageTitle, pageHeader, items } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    const navigateBack = (): void => navigate(-1);

    const headerProps = {
        withLogin: true,
        withAccount: true,
    };

    const content = items.map(({ title, content, isMail }, index) => {
        return isMail ? (
            <React.Fragment key={index}>
                <div className={styles.pageItem}>
                    <h3>{title}</h3>
                    <Link to={`mailto:${content}`} external={true}>
                        {content}
                    </Link>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment key={index}>
                <div className={styles.pageItem}>
                    <h3>{title}</h3>
                    {content}
                </div>
            </React.Fragment>
        );
    });

    return (
        <div className={styles.pageContainer}>
            <Layout
                routes={routes}
                language={language}
                headerProps={headerProps}
                withFooter={false}
            >
                <SEO title={pageTitle} />
                <article className={styles.container}>
                    <div className={styles.BackButton}>
                        <BackButton
                            language={language}
                            clickHandler={navigateBack}
                        />
                    </div>

                    <h1 className={styles.pageHeader}>{pageHeader}</h1>
                    {content}
                </article>
            </Layout>
        </div>
    );
};

export default ContactPage;
