import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ApplePay } from './apple-pay';
import { ApplePayWithoutDiscount } from './apple-pay-without-discount';

const stripePromise = loadStripe(
    'pk_live_51IvGjBJLOZ0xaJ1gCrC6i0qsVoRLhQTScPdpgHMLKrO6OqQZCBkDgL2BAmp7su61eB4pcT3P1OgfGnDVd3KK4u5j00cnBkUsz9'
);

type Props = {
    productID: string | number;
    price: number;
    currency: string;
    discountCode: string | undefined;
    onSuccess: () => void;
    onCancel: () => void;
};

const StripeProvider: FC<Props> = (props: Props) => {
    return (
        <Elements
            stripe={stripePromise}
            {...{
                // TODO/NOTE: Check/test this and fix it if necessary. `Elements` does not seem to take children. Error occurred upon upgrading TypeScript.
                children: props.discountCode ? (
                    <ApplePay {...props} />
                ) : (
                    <ApplePayWithoutDiscount {...props} />
                ),
            }}
        />
    );
};

export default StripeProvider;
