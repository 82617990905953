import * as React from 'react';
import styles from './benefits.module.scss';
import { ReactComponent as CheckIcon } from './checkmark.svg';

type Props = {
    benefits: string[];
};

const Benefits: React.FC<Props> = ({ benefits }: Props) => {
    const list = benefits.map((b, i) => (
        <li key={i} className={styles.Benefits__Item}>
            <CheckIcon /> <span className={styles.Item__Text}>{b}</span>
        </li>
    ));

    return (
        <ul data-testid='component-benefits' className={styles.Benefits__List}>
            {list}
        </ul>
    );
};

export default React.memo(Benefits);
