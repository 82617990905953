import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Question } from '_types/question';
import { Deck } from '_types/deck';
import { SEO } from 'components/seo';
import { Slide, SlideType } from '_types';
import { QuestionSwiper } from './question-swiper';
import { Navigate, useNavigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import useTypedParams from 'hooks/use-typed-params';
import useTypedLocation from 'hooks/use-typed-location';
import { SHRUG } from 'constants/general';
import SwiperInstance from 'swiper';
import SwiperContextProvider from 'hooks/use-swiper-context/provider';

const makeSlides = (
    isPremium: boolean,
    deck: Deck,
    questions: Question[],
    freemiumEndSlideTitle: string,
    premiumEndSlideTitle: string
): Slide[] => {
    const freemiumEndSlide = {
        id: 'registration',
        slideType: SlideType.FREEMIUM_END,
        slug: 'samtale-slutt',
        title: freemiumEndSlideTitle,
    };

    const premiumEndSlide = {
        id: 'end',
        slideType: SlideType.PREMIUM_END,
        slug: 'ferdig-snakket',
        title: premiumEndSlideTitle,
    };

    const questionsSlides = questions.map((q) => ({
        id: q.id,
        slideType: SlideType.QUESTION,
        slug: q.slug,
        title: `${deck.name} - ${q.text}`,
        data: q,
    }));
    return [
        ...questionsSlides,
        isPremium ? premiumEndSlide : freemiumEndSlide,
    ].map((slide, index) => ({
        ...(slide as Slide),
        index,
    }));
};

interface Params {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}

type Props = {
    deck: Deck;
    questions: Question[];
    isPremium: boolean;
    isRandomQuestions?: boolean;
    onGetMoreRandomQuestions: () => void;
    language: Language;
    parentPage: string;
    setIsLastSlide: (x: boolean) => void;
    setCurrentQuestion: (question: Question) => void;
    onSlide: () => void;
};

type SlideChangeSource = 'swiper' | 'history';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface ViewCopy {
    premiumEndSlideTitle: string;
    freemiumEndSlideTitle: string;
}
const nbCopy: ViewCopy = {
    premiumEndSlideTitle: 'Ferdig snakket?',
    freemiumEndSlideTitle: 'Samtale slutt',
};

const enCopy: ViewCopy = {
    premiumEndSlideTitle: '',
    freemiumEndSlideTitle: '',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const QuestionSwiperContainer: FC<Props> = ({
    deck,
    isPremium,
    isRandomQuestions,
    language,
    onGetMoreRandomQuestions,
    onSlide,
    parentPage,
    questions,
    setCurrentQuestion,
    setIsLastSlide,
}) => {
    const navigate = useNavigate();
    // Slide can be changed by swiper (swipe, click next/back)
    // or by history (browser's "back" and "forward" buttons)
    const [slideChangeSource, setSlideChangeSource] =
        useState<SlideChangeSource>('swiper');

    const routes = returnRoutesForLanguage(language, routeObj);
    const { premiumEndSlideTitle, freemiumEndSlideTitle } =
        returnCopyForLanguage(language, copies);

    const slides = useMemo(
        () =>
            makeSlides(
                isPremium,
                deck,
                questions,
                premiumEndSlideTitle,
                freemiumEndSlideTitle
            ),
        [
            deck,
            freemiumEndSlideTitle,
            isPremium,
            premiumEndSlideTitle,
            questions,
        ]
    );

    const { deckSlug, questionSlug, taxonomy } = useTypedParams<Params>();

    const initialSlide = questionSlug
        ? slides.find((s) => s.slug === questionSlug)
        : undefined;

    const [swiper, setSwiper] = useState<SwiperInstance>();
    const [activeSlideIndex, setActiveSlideIndex] = useState(
        initialSlide && initialSlide.index
    );

    const handlePopState = () => setSlideChangeSource('history');

    useEffect(() => {
        window.addEventListener('popstate', handlePopState);

        !!activeSlideIndex &&
            activeSlideIndex > -1 &&
            setCurrentQuestion(questions[activeSlideIndex!]);
        onSlide();

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [activeSlideIndex, onSlide, questions, setCurrentQuestion]);

    const location = useTypedLocation<object>();

    const syncSlideWithLocation = useCallback(() => {
        onSlide();

        const realActiveSlideIndex = activeSlideIndex;

        // location is out of sync
        if (slideChangeSource === 'swiper') {
            const slug = slides[realActiveSlideIndex!].slug;
            const nextPath = `/${language}/${taxonomy}/${deckSlug}/${slug}`;
            navigate(nextPath, {
                state: {
                    ...(location.state ?? {}),
                    parentPage,
                },
            });
        }

        // current slide is out of sync
        if (slideChangeSource === 'history') {
            setSlideChangeSource('swiper');
            const nextSlide = slides.find((s) => s.slug === questionSlug);

            if (nextSlide !== undefined) {
                swiper?.slideTo(nextSlide.index);
            }
        }
    }, [
        activeSlideIndex,
        deckSlug,
        language,
        location.state,
        navigate,
        onSlide,
        parentPage,
        questionSlug,
        slideChangeSource,
        slides,
        swiper,
        taxonomy,
    ]);

    useEffect(() => {
        if (activeSlideIndex == null) return;

        setIsLastSlide(slides.length === activeSlideIndex + 1);
        setCurrentQuestion(questions[activeSlideIndex]);

        // location and active slide is in sync
        if (questionSlug === slides[activeSlideIndex].slug) {
            return;
        }

        // On 'popstate' event, location is changed and component receives
        // new match params first, only then handlePopState is executed.
        // Need to delay synchronization so that handlePopState took effect.
        const timeout = setTimeout(syncSlideWithLocation, 0);

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [
        activeSlideIndex,
        questions,
        questionSlug,
        setCurrentQuestion,
        setIsLastSlide,
        slides,
        syncSlideWithLocation,
    ]);

    const handleSetSwiper = (newSwiper: SwiperInstance): void => {
        setSwiper(newSwiper);
        setActiveSlideIndex(newSwiper.activeIndex);
    };

    if (activeSlideIndex === undefined) {
        // FIXME: redirect to 404 page?
        return <Navigate to={routes.index} replace />;
    }

    const activeSlide = slides[activeSlideIndex];
    const seoTitle = isRandomQuestions ? SHRUG : activeSlide.title;

    return (
        <React.Fragment>
            <SEO title={seoTitle} />
            {slides.length > 0 && (
                <SwiperContextProvider
                    paginationDefs={slides.map(
                        ({ slideType }) => slideType === SlideType.QUESTION
                    )}
                    setSwiper={handleSetSwiper}
                    swiper={swiper}
                >
                    <QuestionSwiper
                        initialSlideIndex={activeSlide.index}
                        slides={slides}
                        isRandomQuestions={isRandomQuestions}
                        getMoreRandomQuestions={onGetMoreRandomQuestions}
                    />
                </SwiperContextProvider>
            )}
        </React.Fragment>
    );
};

export default QuestionSwiperContainer;
