import React, { FC } from 'react';
import classNames from 'classnames';
import MuiDialogContent, {
    DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';

import { Typography } from '../../data-display/typography';
import { styled } from '@mui/material/styles';

export type ContentProps = Omit<MuiDialogContentProps, 'classes' | 'dividers'>;

const StyledContent = styled(MuiDialogContent)`
    align-items: center;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
`;

/**
 * Implements [DialogContent](https://mui.com/api/dialog-content/) from **Material-UI**.
 */
export const Content: FC<ContentProps> = ({ children, className, ...rest }) => (
    <StyledContent
        className={classNames(
            'dialog-content',
            'pb-4',
            'pt-0',
            'o-visible',
            className
        )}
        {...rest}
    >
        {typeof children === 'string' ? (
            <Typography
                align='center'
                variant='body2'
                sx={{ color: 'text.secondary' }}
            >
                {children}
            </Typography>
        ) : (
            children
        )}
    </StyledContent>
);
