import React, { FC } from 'react';

import SessionInfoContent from './session-info-content';
import { StyledTopDockedCard } from './styled';

const TopInfoCard: FC = () => (
    <StyledTopDockedCard>
        <SessionInfoContent />
    </StyledTopDockedCard>
);

export default TopInfoCard;
