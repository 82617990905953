import React from 'react';
import { Subscription } from '_types/subscription';
import { formatDate } from 'utils/date-utils';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { userSelector } from 'store-modules/user';
import { RouteList, routesEN, routesNO } from 'api/routes';
import classNames from 'classnames';
import { Link } from 'components/link';
import styles from './subscription-details.module.scss';

interface Props {
    sub: Subscription;
}
interface ViewCopy {
    textCancelled: string;
    textExpires: string;
    addProCodeLabel: string;
}

const nbCopy: ViewCopy = {
    textCancelled: 'Ditt abonnement er avsluttet',
    textExpires: 'Du vil ha tilgang til FuelBox frem til',
    addProCodeLabel: 'Legg til bedriftskode',
};

const enCopy: ViewCopy = {
    textCancelled: 'Your subscription is cancelled',
    textExpires: 'You will still have access until',
    addProCodeLabel: 'Add a company code',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CancelledSub: React.FC<Props> = ({ sub }: Props) => {
    const language: Language = useSelector(languageSelector);
    const user = useSelector(userSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { textCancelled, textExpires, addProCodeLabel } =
        returnCopyForLanguage(language, copies);
    const expiryDate = formatDate(sub.data.expiry_date);

    const addProCodeLink = !user?.organization && (
        <Link
            to={routes.proCodeSubPage}
            className={classNames(styles.link, styles.addProCodeLink)}
            block={true}
            highlight={false}
        >
            {addProCodeLabel}
        </Link>
    );

    return (
        <React.Fragment>
            <p>
                <b>{textCancelled}</b>
            </p>
            <p>
                {textExpires} <b>{expiryDate}</b>
            </p>
            {addProCodeLink}
        </React.Fragment>
    );
};

export default CancelledSub;
