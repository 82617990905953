import React from 'react';
import classnames from 'classnames';
import styles from './conversation-card.module.scss';
import { ConversationCategory } from '_types';
import SquareCard from '../generic/square-card';
import { Props as CardProps } from '../generic/card';
import Decorator from '../../../../components/conversation-card/decorator';

interface Props extends CardProps {
    name?: string;
    category: ConversationCategory;
    decorator?: Decorator;
}

const ConversationCard: React.FC<Props> = ({
    name,
    onClick,
    className,
    children,
    category,
    decorator,
}: Props) => {
    const decorationClass = decorator?.nextDecoration();
    return (
        <SquareCard
            className={classnames(
                styles.card,
                decorationClass,
                className,
                styles[category?.slug]
            )}
            onClick={onClick}
        >
            {children}
            {!children && name && (
                <>
                    <div className={styles.category}>{category?.name}</div>
                    <div className={styles.title}>{name}</div>
                </>
            )}
        </SquareCard>
    );
};

export default ConversationCard;
