import React, { FC, Fragment, ReactNode } from 'react';
import { Box, BoxProps, Logo, Toolbar } from 'components/base';

type LayoutProps = {
    children: ReactNode;
    hideLogo?: boolean;
    BoxProps?: BoxProps;
};

const Layout: FC<LayoutProps> = ({
    children,
    hideLogo,
    BoxProps: boxProps,
}) => (
    <Fragment>
        {!hideLogo && (
            <Toolbar>
                <Box display='flex' justifyContent='center' width={1}>
                    <Logo />
                </Box>
            </Toolbar>
        )}

        <Box
            alignItems='center'
            display='flex'
            flexDirection='column'
            height={1}
            justifyContent='center'
            mb='20vh'
            {...boxProps}
        >
            {children}
        </Box>
    </Fragment>
);

export default Layout;
