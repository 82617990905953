import produce, { Draft } from 'immer';
import { ProfessionalState } from './_types';
import { initialState } from './_initialState';
import { SET_PRO_ACCOUNT, CLEAR_PRO_ACCOUNT } from './_actionTypes';

// * Implements the producer from ImmerJS.
// Notice that it is not needed to handle the default case, a producer that doesn't do anything will simply return the original state.
const proReducer = produce(
    (
        draft: Draft<ProfessionalState> = initialState,
        action
    ): ProfessionalState => {
        switch (action.type) {
            case SET_PRO_ACCOUNT:
                draft.organization = action.payload;
                break;
            case CLEAR_PRO_ACCOUNT:
                draft.organization = initialState.organization;
                break;
        }
        return draft;
    }
);

export default proReducer;
