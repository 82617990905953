import React, { FC, forwardRef } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import {
    PasswordField,
    PasswordFieldProps,
} from '../fields/text-input/password-field';
import { RFF_FieldProps } from './utils';

export type PasswordFieldBinderProps = Pick<
    FieldRenderProps<string>,
    'input' | 'meta'
> &
    Omit<PasswordFieldProps, 'onChange' | 'value'>;

/**
 * Helper component for binding PasswordField to React Final Form
 */
export const PasswordFieldBinder: FC<PasswordFieldBinderProps> = forwardRef(
    (
        {
            input: { name, onChange, value, ...restInput },
            meta: { submitError },
            inputProps,
            ...rest
        },
        ref
    ) => (
        <PasswordField
            {...rest}
            error={submitError}
            inputProps={{ ...restInput, ...inputProps }}
            name={name}
            onChange={onChange}
            value={value}
            ref={ref}
        />
    )
);

export type RFF_PasswordFieldProps = RFF_FieldProps<PasswordFieldProps>;

/**
 * Binding component for usage with React Final Form
 */
export const RFF_PasswordField: FC<RFF_PasswordFieldProps> = forwardRef(
    (props, ref) => (
        <Field component={PasswordFieldBinder} {...props} ref={ref} />
    )
);
