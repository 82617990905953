import React, { FC, ReactNode, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import {
    AnswerBase,
    getOrderedAnswersCollectionRef,
    getSessionDocumentRef,
    SessionData,
} from 'utils/collaboration-utils';
import SessionQuestionContext, {
    SessionQuestionContextValues,
} from './context';
import { useIntervalCollectionData } from 'hooks/firebase/use-interval-collection-data';
import { Question } from '_types';

import useSession from '../../../use-session';
import useCollaboration from '../../../use-collaboration';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

type SessionQuestionProviderProps = {
    children: ReactNode;
    index: number;
    isActive: boolean;
    question: Question;
};

const SessionQuestionProvider: FC<SessionQuestionProviderProps> = ({
    children,
    index,
    isActive,
    question,
}) => {
    const { visible } = useSession();
    const { collaborationId } = useCollaboration();

    const sessionRef = getSessionDocumentRef(collaborationId);
    const [sessionData] = useDocumentDataOnce<SessionData>(sessionRef as never);

    const answersRef = getOrderedAnswersCollectionRef(
        collaborationId,
        question.id
    );

    const answers = useIntervalCollectionData<AnswerBase>(
        answersRef,
        !isActive || !visible,
        2000
    );

    const [answeredRegistered, setAnswersRegistered] = useState(false);

    useEffect(() => {
        if (!answeredRegistered && answers && answers.length > 0) {
            if (
                !sessionData?.answeredQuestions?.some(
                    ({ id }) => id === question.id
                )
            )
                sessionRef.update({
                    answeredQuestions: firebase.firestore.FieldValue.arrayUnion(
                        { id: question.id, index }
                    ),
                });

            if (
                !sessionData?.answeredQuestionWithHighestIndex ||
                sessionData.answeredQuestionWithHighestIndex.index < index
            )
                sessionRef.update({
                    answeredQuestionWithHighestIndex: question,
                });

            setAnswersRegistered(true);
        }
    }, [answers, sessionData]);

    const values: SessionQuestionContextValues = {
        answers,
        answersRef,
        isActive: isActive && visible,
        question,
    };

    return (
        <SessionQuestionContext.Provider value={values}>
            {children}
        </SessionQuestionContext.Provider>
    );
};

export default SessionQuestionProvider;
