
// TODO: substitue this for moment
export const formatDate = (date: string): string => {
    if (!date) {
        return date;
    }

    //2021-03-09 14:18:41
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);

    return `${day}.${month}.${year}`;
}