import React, { FC, forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import { ReactComponent as IOS } from 'assets/images/mobile-store-buttons/ios.svg';
import { ReactComponent as Android } from 'assets/images/mobile-store-buttons/android.svg';
import { useDefaultColor } from 'style/theme';

export enum MobileStoreButtonType {
    ANDROID = 'android',
    IOS = 'ios',
}

export type MobileStoreButtonProps = Omit<
    HTMLAttributes<HTMLAnchorElement>,
    'children' | 'href' | 'rel' | 'target'
> & {
    type: MobileStoreButtonType;
};

const MobileStoreLinks: Record<MobileStoreButtonType, string> = {
    [MobileStoreButtonType.ANDROID]:
        'https://play.google.com/store/apps/details?id=no.fuelbox.fuelbox',
    [MobileStoreButtonType.IOS]:
        'https://apps.apple.com/no/app/fuelbox/id1507438424',
};

const StyledLink = styled(Link)`
    display: flex;
    max-width: 100%;
    width: 210px;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
    }

    &:focus-visible {
        outline: none;

        &:after {
            border-radius: 10px;
            border: 2px dotted;
            bottom: -2px;
            content: '';
            left: -2px;
            position: absolute;
            right: -2px;
            top: -2px;
        }
    }
` as typeof Link;

export const MobileStoreButton: FC<MobileStoreButtonProps> = forwardRef<
    HTMLAnchorElement,
    MobileStoreButtonProps
>(({ type, ...rest }, ref) => {
    const { t } = useTranslation();
    const { colorName } = useDefaultColor();

    return (
        <StyledLink
            color={colorName}
            href={MobileStoreLinks[type]}
            ref={ref}
            rel='noopener noreferrer'
            target='_blank'
            title={t('mobileStoreButton.label', { context: type })}
            {...rest}
        >
            {type === MobileStoreButtonType.ANDROID ? <Android /> : <IOS />}
        </StyledLink>
    );
});
