import { AppSettingsState } from './_types';

export const initialState: AppSettingsState = {
    device: 'browser',
    language: 'nb',
    scrollPosProHomePage: null,
    scrollPosProBrowsePage: null,
    scrollPosPremiumBrowsePage: null,
    scrollPosHomePage: null,
    scrollPosFavouritesPage: null,
    scrollPosFavouriteQuestionsPage: null,
    scrollPosCollaborationsPage: null,
    isCreatingCompany: false,
};
