import Decorator from 'components/conversation-card/decorator';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classnames from 'classnames';
import conversationClient from 'api/conversation-client';
import styles from './favourite-conversations-page.module.scss';
import { Container } from 'components/container';
import { ConversationWithCategory, deckParentPages } from '_types';
import { ConversationCard, ThemeCard } from 'components/conversation-card';
import { Language } from '_types/language';
import {
    scrollPosSelectorFavouriteConversationsPage,
    setScrollPosFavouriteConversationsPage,
} from 'store-modules/app-settings';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { SEO } from 'components/seo';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useDispatch, useSelector } from 'react-redux';
import { getUAInfo } from 'services/user-agent.service';
import {
    favouriteConversationsSelector,
    setFavouriteConversations,
} from 'store-modules/favourite-conversations';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';
import useConversationSelector from 'hooks/use-conversation-selector';
import useHistoryRouter from 'hooks/use-history-router';
import useTypedLocation from 'hooks/use-typed-location';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';

interface LocationState {
    backPath?: string;
}

interface ViewCopy {
    title: string;
    byText: string;
    backButtonText: string;
}

const nbCopy: ViewCopy = {
    title: 'Likte samtaler',
    byText: 'av ',
    backButtonText: 'Tilbake',
};

const enCopy: ViewCopy = {
    title: 'Liked conversations',
    byText: 'by ',
    backButtonText: 'Back',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const FavouriteConversationsPage: React.FC = () => {
    const navigate = useNavigate();
    const { language } = useHistoryRouter();
    const location = useTypedLocation<LocationState>();
    const { selectConversation } = useConversationSelector();

    const { title, byText, backButtonText } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    const scrollPosition = useSelector(
        scrollPosSelectorFavouriteConversationsPage
    );
    const hash = location.hash.substring(1);
    const { isMobile, isAndroid } = getUAInfo(window.navigator);
    const headerProps = {
        withLogin: true,
        withAccount: true,
        withHeaderSpace: false,
    };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const conversations = useSelector(favouriteConversationsSelector);
    const conversationsToDisplay = conversations?.map((c) => {
        if (c.created_by_id) {
            return {
                ...c,
                category: { ...c.category, name: byText + c.category.name },
            };
        }
        return c;
    });

    const hasConversations =
        !!conversationsToDisplay && conversationsToDisplay.length > 0;

    const decorator = new Decorator();

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchFavourites(): Promise<void> {
            if (hash && hash.length) {
                const anchorElement = document.getElementById(hash);
                if (anchorElement) anchorElement.scrollIntoView();
            }

            Promise.all([
                conversationClient.getMyFavouriteConversations(),
                conversationClient.getMyFavouriteQuestions(),
            ]).then((response) => {
                dispatch(setFavouriteConversations(response[0].reverse()));
                dispatch(setFavouriteQuestions(response[1].reverse()));

                if (response.length > 0) {
                    setIsLoading(false);
                }
            });
        }

        if (!conversationsToDisplay) {
            fetchFavourites();
        } else {
            setIsLoading(false);
        }
        if (scrollPosition) {
            //timeout hack to grab correct element
            setTimeout(function () {
                window.scrollTo({
                    top: scrollPosition.y,
                    left: scrollPosition.x,
                });
            }, 2);
        }
    }, [hash, dispatch, conversationsToDisplay, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    const onConversationSelect = (deck: ConversationWithCategory): void => {
        dispatch(
            setScrollPosFavouriteConversationsPage(
                getWindowScrollPosition(window)
            )
        );
        selectConversation(deck, language, deckParentPages.myLibrary);
    };

    const pageClassName = classnames(
        styles.FavouriteConversationsPageContainer,
        styles.ColorBg,
        isAndroid && styles.Android
    );

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = conversationsToDisplay?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard
        );

        classNamesToReturn = classNames(
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    const backButton = (
        <section className={styles.BackButtonContainer}>
            <button
                className={styles.BackButton}
                onClick={() => {
                    navigate(-1);
                }}
                tabIndex={0}
            >
                <ArrowLeft className={styles.LeftArrow} />
                <span>{backButtonText}</span>
            </button>
        </section>
    );

    const favouriteConversationsContainer = (
        <Container className={styles.ConversationsContainer}>
            {/* <section
                className={styles.BackButtonContainer}
                id='BackButtonContainer'
            >
                <BackButton
                    language={language}
                    className={styles.backButton}
                    clickHandler={() => navigate(-1)}
                />
            </section>
             */}
            {backButton}
            {/* <header className={styles.header}> */}
            <h2
                id='conversations-row-title'
                className={styles.FavouriteConversationsTitle}
            >
                {title}
            </h2>
            {/* </header> */}
            <section
                aria-label={title}
                className={styles.ConversationCardsContainer}
            >
                {conversationsToDisplay?.map((convo) => {
                    if (!hasConversations) {
                        return null;
                    }
                    return isThemeCard(convo.taxonomy) ||
                        isOrganizationCard(convo.taxonomy) ? (
                        <ThemeCard
                            key={convo.id}
                            conversation={convo}
                            className={conversationCardStyle(convo)}
                            onClick={() => onConversationSelect(convo)}
                        />
                    ) : (
                        <ConversationCard
                            key={convo.id}
                            conversation={convo}
                            className={conversationCardStyle(convo)}
                            onClick={() => onConversationSelect(convo)}
                            category={{
                                slug: convo.category.slug,
                                name: convo.category.name,
                            }}
                            decorator={decorator}
                        />
                    );
                })}
            </section>
        </Container>
    );

    const favouritesContainer = (
        <section className={styles.FavouritesContainer}>
            <section className={styles.ResultContainer}>
                {favouriteConversationsContainer}
            </section>
        </section>
    );

    return (
        <Layout
            routes={routes}
            language={language}
            headerProps={headerProps}
            withHeaderSpace={headerProps.withHeaderSpace}
            pageClassName={pageClassName}
        >
            <SEO title={title} />

            {favouritesContainer}
        </Layout>
    );
};

export default FavouriteConversationsPage;
