import React, { useRef } from 'react';
import { Container } from 'components/container';
import FeaturedDeck from './featured-deck';
import styles from './featured-decks-swiper.module.scss';
import { FeaturedConversation } from '_types';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';

type FeaturedDecksSwiperProps = {
    featuredConversations: FeaturedConversation[];
    selectFeaturedConversation: () => void;
    swiperOptions?: Partial<SwiperProps>;
};

const FeaturedDecksSwiper: React.FC<FeaturedDecksSwiperProps> = ({
    featuredConversations,
    selectFeaturedConversation,
    swiperOptions,
}) => {
    const hasPagination = featuredConversations.length > 1;
    const paginationRef = useRef(null);

    return (
        <Container>
            <div className={styles.swiperContainer} role='grid'>
                <div role='row' aria-live='polite'>
                    <Swiper
                        centeredSlides
                        spaceBetween={16}
                        mousewheel={{ forceToAxis: true }}
                        noSwiping={!hasPagination}
                        modules={[Pagination, Mousewheel]}
                        pagination={
                            hasPagination && {
                                bulletActiveClass: styles.bulletActive,
                                bulletClass: styles.bullet,
                                el: paginationRef.current,
                                clickable: true,
                            }
                        }
                        {...swiperOptions}
                    >
                        {featuredConversations.map((conversation) => (
                            <SwiperSlide key={conversation.id}>
                                {({ isActive }) => (
                                    <FeaturedDeck
                                        deck={conversation}
                                        isActive={isActive}
                                        onClick={selectFeaturedConversation}
                                    />
                                )}
                            </SwiperSlide>
                        ))}

                        {hasPagination && (
                            <div
                                ref={paginationRef}
                                className={styles.pagination}
                            />
                        )}
                    </Swiper>
                </div>
            </div>
        </Container>
    );
};

export default FeaturedDecksSwiper;
