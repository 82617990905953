import * as React from 'react';
import styles from './freemium-navbar.module.scss';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { Button } from 'components/base';
import { Link } from 'components/link';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';

interface ViewCopy {
    tryFreemium: string;
    login: string;
}

const nbCopy: ViewCopy = {
    tryFreemium: 'Prøv gratis',
    login: 'Logg inn',
};

const enCopy: ViewCopy = {
    tryFreemium: 'Try for free',
    login: 'Log in',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

type Props = {
    disableTryFreemium?: boolean;
};

const FreemiumNavbar: React.FC<Props> = ({ disableTryFreemium }: Props) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { tryFreemium, login } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    const onTryFree = (): void => navigate(routes.index);

    return (
        <nav data-testid='component-nav' className={styles.Navbar}>
            {!disableTryFreemium && (
                <Button
                    data-testid='button-nav-free'
                    size='small'
                    onClick={onTryFree}
                >
                    {tryFreemium}
                </Button>
            )}
            <Link to={routes.login} data-testid='button-nav-login'>
                {login}
            </Link>
        </nav>
    );
};

export default FreemiumNavbar;
