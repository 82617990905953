import * as React from 'react';
const titleNO = 'Personvernerklæring';

const q1 = {
    title: '1 Innledning',
    copy: (
        <React.Fragment>
            <p>
                I FuelBox AS (heretter kalt «FuelBox») tar vi ditt personvern på
                alvor. Derfor har vi alltid ditt personvern og vårt ansvar i
                tankene når vi samler inn og behandler personopplysninger.
                Personopplysninger er data som kan knyttes til deg, som vi
                samler inn når du kjøper og bruker våre produkter og tjenester.
                Vi har ansvar for å håndtere all data på en sikker og
                brukervennlig måte, som oppfyller både lovkrav og dine
                forventninger som bruker.
            </p>
            <p>
                Det er FuelBox som er behandlingsansvarlig, det vil si den
                virksomheten som er ansvarlig for behandlingen av
                personopplysningene som er beskrevet i denne erklæringen. I
                denne erklæringen finner du utfyllende informasjon om hvordan vi
                i FuelBox samler, lagrer og bruker personopplysninger.
            </p>
        </React.Fragment>
    ),
};

const q2 = {
    title: '2 Hva bruker FuelBox dine data til?',
    copy: (
        <React.Fragment>
            <p>
                FuelBox samler inn og bruker data for å kunne tilby produkter og
                tjenester av best mulig kvalitet til alle våre brukere. Vi
                bruker data til å:
            </p>
            <ul>
                <li>
                    behandle bestillinger og betalinger i nettbutikken, og
                    kommunisere med kunder om deres bestillinger.
                </li>
                <li>
                    sikre forsendelser, håndtere retur av produkter og
                    administrere tilbakekalling av produkter.
                </li>
                <li>behandle kundehenvendelser.</li>
                <li>
                    markedsføre våre produkter og tjenester, for eksempel
                    gjennom nyhetsbrev på epost.
                </li>
                <li>
                    måle hvordan informasjon om våre produkter og tjenester
                    brukes, slik at vi kan forbedre effektivitet, relevans og
                    innhold på våre nettsider.
                </li>
                <li>
                    behandle kjøp av og administrere digitale
                    FuelBox-abonnement.
                </li>
                <li>
                    kontinuerlig forbedre våre produkter og tjenester. Vi
                    analyserer adferden til grupper av brukere, og ser
                    eksempelvis på hva slags innhold som blir mest lest eller
                    hvilken funksjonalitet som blir mest brukt, som for eksempel
                    nyhetsbrev. På den måten kan vi jobbe målrettet med å
                    videreutvikle de områdene som er viktigst for brukerne våre.
                </li>
                <li>
                    granske og forebygge misbruk av tjenestene våre, slik som
                    deling av et digitalt abonnement og forsøk på å omgå våre
                    sikkerhetsmekanismer.
                </li>
            </ul>
            <p>
                Når vi håndterer data, er det vår fremste oppgave å gjøre det på
                en sikker og god måte i samsvar med de forventningene våre
                brukere har. Vi må også oppfylle en rekke krav fra både norsk og
                europeisk lovverk. Blant annet må all behandling av
                personopplysninger ha et formål og et behandlingsgrunnlag. Dette
                handler om at det er du som eier dine opplysninger og derfor må
                vårt formål med å samle og bruke opplysninger om deg, ha et
                behandlingsgrunnlag. Vi må også sørge for å slette opplysningene
                vi har lagret om deg når formålet med behandlingen er oppnådd.
            </p>
        </React.Fragment>
    ),
};

const q3 = {
    title: '3 Hvilke opplysninger samles inn?',
    copy: (
        <React.Fragment>
            <p>
                FuelBox samler personopplysningene du selv oppgir når du handler
                i nettbutikken vår eller kjøper et abonnement på digitale
                FuelBox. Disse personopplysningene samles inn for å kunne
                håndtere dine bestillinger og kjøp.
            </p>
            <h4>3.1 Ved kjøp i nettbutikk</h4>
            <p>Når du handler på nettsidene våre ber vi deg om:</p>
            <ul>
                <li>fullt navn</li>
                <li>adresse</li>
                <li>firmanavn (valgfritt å oppgi)</li>
                <li>epostadresse</li>
                <li>telefonnummer</li>
                <li>kortdetaljer (dersom du velger å betale med bankkort)</li>
                <li>
                    samtykke til å sende deg markedsføringshenvendelser på
                    e-post (frivillig)
                </li>
            </ul>
            <p>
                Dersom du velger å opprette en konto, lagrer vi opplysningene du
                har oppgitt slik at du sparer tid neste gang du handler i
                nettbutikken vår.
            </p>
            <p>
                Om du benytter deg av fakturabetaling for organisasjoner når du
                handler på nettsidene våre, ber vi deg om:
            </p>
            <ul>
                <li>bestillers fulle navn</li>
                <li>bestillers telefonnummer</li>
                <li>bestillers epostadresse</li>
                <li>organisasjonens navn</li>
                <li>avdeling/referanse (valgfritt å oppgi)</li>
                <li>organisasjonsnummer</li>
                <li>valg av hvordan faktura skal mottas (EHF eller epost)</li>
                <li>leveringsadresse</li>
                <li>eventuell tilleggsinformasjon eller spørsmål</li>
                <li>samtykke til leveringsbetingelser</li>
            </ul>
            <h4>3.2 Ved kjøp av FuelBox-abonnement</h4>
            <p>Når du velger å abonnere på FuelBox Premium ber vi deg om:</p>
            <ul>
                <li>navn</li>
                <li>e-postadresse</li>
                <li>kortdetaljer (dersom du velger å betale med bankkort)</li>
                <li>samtykke til vilkårene for bruk av abonnementet</li>
                <li>
                    samtykke til å motta markedsføringshenvendelser på epost
                    (frivillig)
                </li>
            </ul>
            <p>
                Organisasjoner som inngår avtale om abonnement på FuelBox Proff
                med tilhørende fakturaavtale blir bedt om:
            </p>
            <ul>
                <li>bestillers fulle navn</li>
                <li>bestillers telefonnummer</li>
                <li>bestillers epostadresse</li>
                <li>organisasjonens navn</li>
                <li>avdeling/referanse (valgfritt å oppgi)</li>
                <li>organisasjonsnummer</li>
                <li>valg av hvordan faktura skal mottas (EHF eller epost)</li>
                <li>eventuell tilleggsinformasjon eller spørsmål</li>
                <li>samtykke til vilkårene for bruk av abonnementet</li>
            </ul>
            <p>
                Når du blir FuelBox Proff-bruker ved å knytte deg til en
                organisasjons abonnement, ber vi deg om:
            </p>
            <ul>
                <li>navn</li>
                <li>epostadresse</li>
                <li>samtykke til vilkårene for bruk av abonnementet</li>
                <li>
                    samtykke til å motta markedsføringshenvendelser på epost
                    (frivillig)
                </li>
            </ul>
            <h4>3.3 Ved besøk på våre digitale flater</h4>
            <p>
                Når du besøker nettsidene våre eller bruker digitale Fuelbox
                installeres informasjonskapsler (cookies) på din enhet, som
                samler inn opplysninger om din bruk av våre digitale flater.
            </p>
            <p>
                I del 4 kan du lese mer om hvilke cookies vi bruker og til
                hvilke formål.
            </p>
        </React.Fragment>
    ),
};

const q4 = {
    title: '4 Hvordan bruker FuelBox informasjonskapsler?',
    copy: (
        <React.Fragment>
            <p>
                Når du besøker nettsidene våre eller bruker digitale FuelBox,
                installeres informasjonskapsler (cookies) lokalt på din enhet
                (PC, mobiltelefon eller nettbrett). FuelBox bruker cookies til
                tre formål:
            </p>
            <ul>
                <li>
                    levere og tilpasse tjenestene våre til enheten og
                    nettleseren du bruker.
                </li>
                <li>
                    måle og analysere trafikken på våre digitale flater for å
                    vurdere hvordan disse brukes, kartlegge forbedringspotensial
                    og videreutvikle tjenestene våre.
                </li>
                <li>
                    i fremtiden vil cookies brukes til å gi deg relevant og
                    tilpasset innhold. Per i dag bruker vi ikke cookies til
                    dette formålet.
                </li>
            </ul>
            <p>
                Når du avlegger et besøk på nettsidene våre eller bruker
                digitale FuelBox installerer vi cookies som er nødvendige for at
                du skal kunne benytte deg av våre digitale flater. Dersom du har
                tillatt cookies i din nettleser, kan vi også installere cookies
                som tjener andre formål, som statistikk og analyse eller
                brukervennlighet. Du kan trekke tilbake ditt samtykke til
                cookies når som helst.
            </p>
            <p>
                Det tas forebehold om at det kan forekomme cookies som ikke er
                dokumentert i denne oversikten.
            </p>
            <h4>Statistikkverktøy</h4>
            <p>
                For at vi skal kunne utvikle og forbedre våre digitale flater,
                bruker vi verktøyene Google Analytics (levert av Google, Inc) og
                Hotjar (levert av Hotjar Ltd.) for å måle trafikk og adferd. Vi
                administrerer disse verktøyene ved hjelp av Google Tag Manager
                (levert av Google, Inc). Både Google Analytics og Hotjar bruker
                cookies for å analysere hvordan besøkende bruker våre digitale
                flater. Opplysningene behandles i avidentifisert og aggregert
                form. Det vil si at opplysningene aldri vil kunne spores tilbake
                til enkeltbrukere, og heller ikke behandles individuelt, men
                slått sammen i grupper.
            </p>
            <p>Utfyllende informasjon om hvilke cookies som brukes:</p>
            <ul>
                <li>
                    Google Analytics:
                    <br />
                    <a href='https://support.google.com/analytics/answer/6004245'>
                        https://support.google.com/analytics/answer/6004245
                    </a>
                </li>
                <li>
                    Hotjar:
                    <br />
                    <a href='https://www.hotjar.com/cookies'>
                        https://www.hotjar.com/cookies
                    </a>
                </li>
            </ul>
            <p>
                Hvis du ikke ønsker at FuelBox skal samle inn anonyme data fra
                deg, kan du melde deg av:
            </p>
            <ul>
                <li>
                    Google Analytics:
                    <br />
                    <a href='https://tools.google.com/dlpage/gaoptout'>
                        https://tools.google.com/dlpage/gaoptout
                    </a>
                </li>
                <li>
                    Hotjar:
                    <br />
                    <a href='https://www.hotjar.com/legal/compliance/opt-out'>
                        https://www.hotjar.com/legal/compliance/opt-out
                    </a>
                </li>
            </ul>
            <h4>Publiseringsløsninger</h4>
            <p>
                Publiseringløsningene vi bruker oppretter også cookies når du
                besøker våre digitale flater. Vi bruker publiseringsløsningen
                Wordpress for nettsidene våre og publiseringsløsningen Netlify
                for vår digitale FuelBox-løsning.
            </p>
            <p>Utfyllende informasjon om hvilke cookies som brukes:</p>
            <ul>
                <li>
                    Wordpress:
                    <br />
                    <a href='https://wordpress.org/support/article/cookies/'>
                        https://wordpress.org/support/article/cookies/
                    </a>
                </li>
                <li>
                    Netlify:
                    <br />
                    <a href='https://www.netlify.com/privacy/'>
                        https://www.netlify.com/privacy/
                    </a>
                </li>
            </ul>
            <h4>Administrere cookies</h4>
            <p>
                I innstillingene til nettleseren din finner du en oversikt over
                alle cookies som er lagret. Her kan du når som helst blokkere og
                tillate cookies fra nettsidene du besøker. Mer om administering
                av cookies finner på Nettvett.no:&nbsp;
                <a href='https://nettvett.no/slik-administrer-du-informasjonskapsler/'>
                    https://nettvett.no/slik-administrer-du-informasjonskapsler/.
                </a>
            </p>
        </React.Fragment>
    ),
};

const q5 = {
    title: '5 Bruk av databehandlere',
    copy: (
        <React.Fragment>
            <p>
                Vi benytter oss av tjenesteleverandører som utfører enkelte
                oppgaver for oss. Noen av disse tjenesteleverandørene vil ha
                tilgang til personopplysninger som FuelBox has samlet inn.
                Tjenesteleverandørene er såkalte databehandlere for oss, men det
                er fremdeles FuelBox som har ansvaret for databehandlingen.
                Selskapet som er databehandler kan bare håndtere opplysninger
                for å levere den aktuelle tjenesten til oss. De har ikke lov til
                å behandle opplysningene på annen måte enn slik vi har avtalt.
                Vi har avtaler med alle leverandører som behandler
                personopplysninger vi er ansvarlige for, som sikrer at
                opplysningene behandles på forsvarlig måte.
            </p>
            <p>
                Eksempler på tjenester vi får levert er drift av våre digitale
                flater, håndtering av forsendelser og retur av produkter og
                betalingstjenester.
            </p>
            <p>
                For å administrere betalinger via nettsidene våre og vår
                digitale FuelBox-løsning benytter vi oss av en betalingstjeneste
                fra Bambora. Betalingsinformasjonen du legger inn når du kjøper
                produkter eller tjenester fra FuelBox krypteres av vår
                betalingsleverandør og er aldri tilgjengelig for FuelBox
                ansatte. Leverandøren av betalingstjenesten har egne vilkår og
                egne prosedyrer for håndtering av personopplysninger. Disse får
                du tilgang til når du velger betalingsmåte på nettsiden vår,
                eller ved å følge lenken:{' '}
                <a href='https://www.bambora.com/no/no/personopplysninger/'>
                    https://www.bambora.com/no/no/personopplysninger/
                </a>
            </p>
        </React.Fragment>
    ),
};

const q6 = {
    title: '6 Lagring og sletting av personopplysninger',
    copy: (
        <React.Fragment>
            <p>
                Alle personopplysninger som FuelBox samler inn, lagres og
                behandles på en trygg og sikker måte. Vi har etablert og
                dokumentert rutiner og tiltak som skal sikre opplysningenes
                integritet, tilgjengelighet og konfidensialitet.
            </p>
            <p>
                Det er bare utvalgte ansatte i FuelBox som har tilgang til våre
                kunders personopplysninger. Tilgangen er regulert gjennom
                adgangskontroll med passord.
            </p>
            <p>
                Når du handler i nettbutikken vår eller kjøper et digitalt
                FuelBox-abonnement, er det betalingsleverandøren som lagrer og
                behandler betalingsopplysningene du oppgir. FuelBox henter ut
                rapporter fra betalingsleverandørene for å administrere kjøp og
                i forbindelse med regnskapsrapportering, men rapportene
                inneholder kun transaksjonsdata i form av dato, vare og verdi.
                Rapportene inneholder aldri identifiserende personopplysninger
                eller betalingsinformasjon.
            </p>
            <p>
                Vi skal slette personopplysninger når det ikke lenger er
                nødvendig å behandle dem. Dette varierer avhengig av formålet
                for vår behandling.
            </p>
            <p>
                Når du kjøper produkter eller tjenester fra FuelBox på
                nettsidene våre eller i vår digitale løsning, har FuelBox
                oppbevaringsplikt i henhold til de enhver tid gjeldende kravene
                i&nbsp;
                <a href='https://lovdata.no/dokument/NL/lov/1998-07-17-56'>
                    Regnskapsloven.
                </a>
                &nbsp; Per januar 2020 må FuelBox lagre kundedetaljer og
                transaksjonsdata i fem år. Så snart oppbevaringsplikten
                opphører, sletter vi alle identifiserende opplysninger vi har
                lagret om deg.
            </p>
            <p>
                Hvis du har gitt samtykke til behandling av dine
                personopplysninger til et bestemt formål, for eksempel for å
                motta nyhetsbrev fra FuelBox, kan du når som helst trekke
                samtykket tilbake. Da vil vi stanse behandlingen av de
                opplysningene samtykket gjelder for. Vi har sletterutiner som
                sikrer at dine personopplysninger slettes innen 30 dager etter
                at du har trukket tilbake ditt samtykke. Er du FuelBox-abonnent,
                kan du enkelt administrere dine samtykker fra Min konto. Du kan
                også trekke tilbake ditt samtykke ved å følge avmeldingslenken i
                markedsføringshenvendelsene du mottar på epost.
            </p>
        </React.Fragment>
    ),
};

const q7 = {
    title: '7 Hvilke rettigheter har du?',
    copy: (
        <React.Fragment>
            <p>
                Du har rett til innsyn i, korrigering eller sletting av
                personopplysninger vi behandler om deg. Du har også rett på
                informasjon om den behandlingen FuelBox foretar og som er
                beskrevet over. Du har videre rett til å kreve begrensning i
                behandlingen og kan på visse vilkår protestere mot videre
                behandling av personopplysninger eller kreve dine
                personopplysninger overført til deg selv eller annen
                behandlingsansvarlig (dataportabilitet).
            </p>
            <p>
                I de tilfellene vi behandler personopplysningene dine med støtte
                i ditt samtykke har du rett til å når som helst trekke tilbake
                ditt samtykke til behandlingen. Du har også rett til å motsette
                deg behandling, for eksempel når det gjelder formål som direkte
                markedsføring og utsendelse av nyhetsbrev. Under visse
                omstendigheter har du også rett til å få personopplysningene
                dine slettet eller blokkert. Dette gjelder ikke i de tilfellene
                hvor FuelBox er pålagt av loven å bevare eller håndtere
                opplysningene, eller dersom opplysningene er nødvendige for å
                kunne oppfylle en avtale med deg.
            </p>
            <p>
                Vi har felles interesse i at de opplysningene vi har registrert
                om deg er korrekte og fullstendige. Dersom du ønsker å gjøre
                endringer eller på annen måte benytte deg av rettighetene dine,
                kan du kontakte oss på &nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>
                &nbsp;eller <a href='tel:+47 959 78 064'>+47 959 78 064</a>.
            </p>
        </React.Fragment>
    ),
};

const q8 = {
    title: '8 Hvordan kan du kontakte FuelBox?',
    copy: (
        <React.Fragment>
            <p>
                Dersom du har spørsmål knyttet til FuelBox sin behandling av
                personopplysninger, ønsker innsyn, eller vil be om retting eller
                sletting av opplysninger vi har lagret om deg, kan du kontakte
                oss på epost&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>
                &nbsp;eller telefon{' '}
                <a href='tel:+47 959 78 064'>+47 959 78 064</a>.
            </p>
        </React.Fragment>
    ),
};

const titleEN = 'Privacy Policy';
const e1 = {
    title: '1 Introduction',
    copy: (
        <React.Fragment>
            <p>
                At FuelBox AS (from now on referred to as “FuelBox”) we take
                your privacy seriously. We always have your privacy and our
                responsibilities in mind when we collect and process your
                personal data. Personal data is data that can be linked to you,
                which we collect when you buy and use our products and services.
                We are responsible for handling all such data in a secure and
                user-friendly manner, which meets the legal requirements and
                your expectations as a user.
            </p>
            <p>
                FuelBox is the data controller, meaning the business responsible
                for the processing of the personal data described in this
                policy. In this policy you will find thorough information about
                how we collect, store and use personal data.
            </p>
        </React.Fragment>
    ),
};

const e2 = {
    title: '2 Our purposes for processing personal data',
    copy: (
        <React.Fragment>
            <p>
                FuelBox collects and uses data to provide the best-quality
                products and services to all our users. We use data to:
            </p>
            <ul>
                <li>
                    process orders and payments in the online shop and
                    communicate with customers about their orders.
                </li>
                <li>
                    secure shipments, handle product returns and manage product
                    recalls.
                </li>
                <li>process customer inquiries.</li>
                <li>
                    promote our products and services, for example through email
                    newsletters.
                </li>
                <li>
                    measure how information about our products and services is
                    used in order to improve efficiency, relevance and content
                    on our websites.
                </li>
                <li>
                    manage our digital FuelBox solution and subscription
                    purchases.
                </li>
                <li>
                    continuously improve our products and services. We analyse
                    the behavior of groups of users to identify the most popular
                    content and functionalities. In this way, we can
                    purposefully further develop what is most important to our
                    users.
                </li>
                <li>
                    review and prevent abuse of our services, such as the
                    sharing of a digital subscription and attempts to bypass our
                    security measures.
                </li>
            </ul>
            <p>
                When we process data, our primary task is to do so in a safe and
                sound manner, which aligns with the expectations of our users.
                We must also meet a number of requirements in Norwegian and
                European legislations. Among other things, all processing of
                personal data require a purpose and a basis for processing. We
                must also make sure to delete the data we have stored about you
                when the purpose of the processing is achieved.
            </p>
        </React.Fragment>
    ),
};

const e3 = {
    title: '3 Types of personal data processed by FuelBox',
    copy: (
        <React.Fragment>
            <p>
                FuelBox collects the personal data that you provide when you
                make a purchase in our online shop or buy a digital FuelBox
                subscription. This personal data is collected to handle your
                orders and purchases.
            </p>
            <h4>3.1 When making a purchase from our online shop</h4>
            <p>When you shop on our websites we ask you to provide:</p>
            <ul>
                <li>full name</li>
                <li>address</li>
                <li>company name (optional)</li>
                <li>email address</li>
                <li>phone number</li>
                <li>card details (if you choose to pay by credit card)</li>
                <li>consent to marketing communications by email (optional)</li>
            </ul>
            <p>
                If you choose to create an account, we store the information you
                provide so that you can save time the next time you buy
                something from our online shop.
            </p>
            <p>
                If you choose to pay by invoice (for organisations only) when
                you buy something from our online shop, we ask you to provide:
            </p>
            <ul>
                <li>purchaser’s full name</li>
                <li>purchaser’s phone number</li>
                <li>purchaser’s email address</li>
                <li>name of the organisation</li>
                <li>department/reference (optional)</li>
                <li>organisation number</li>
                <li>
                    how to receive the invoice (electronic invoice or email)
                </li>
                <li>shipping address</li>
                <li>any additional information or questions</li>
                <li>consent to our terms for delivery</li>
            </ul>
            <h4>3.2 When purchasing a FuelBox subscription</h4>
            <p>
                When choosing to subscribe to the digital FuelBox, we ask you to
                provide:
            </p>
            <ul>
                <li>name</li>
                <li>email address</li>
                <li>card details (if you choose to pay by credit card)</li>
                <li>
                    consent to the terms of use of digital FuelBox (optional)
                </li>
                <li>consent to marketing communications by email (optional)</li>
            </ul>
            <p>
                Organisations that enter into a subscription agreement for
                FuelBox Pro with associated invoice agreement are asked to
                provide:
            </p>
            <ul>
                <li>purchaser’s full name</li>
                <li>purchaser’s phone number</li>
                <li>purchaser’s email address</li>
                <li>name of the organisation</li>
                <li>department/reference (optional)</li>
                <li>organisation number</li>
                <li>
                    how to receive the invoice (electronic invoice or email)
                </li>
                <li>any additional information or questions</li>
                <li>consent to our terms for delivery</li>
            </ul>
            <p>
                When you become a FuelBox Pro user through connecting to an
                organisation’s subscription, we ask you to provide:
            </p>
            <ul>
                <li>name</li>
                <li>email address</li>
                <li>card details (if you choose to pay by credit card)</li>
                <li>
                    consent to the terms of use of digital FuelBox (optional)
                </li>
                <li>consent to marketing communications by email (optional)</li>
            </ul>
            <h4>3.3 When visiting our digital platforms</h4>
            <p>
                When you visit our websites or use digital FuelBox, cookies are
                installed on your device. Cookies collect information about your
                use of our digital platforms.
            </p>
            <p>
                When you visit our websites or use digital FuelBox, cookies are
                installed on your device. Cookies collect information about your
                use of our digital platforms.
            </p>
        </React.Fragment>
    ),
};

const e4 = {
    title: '4 How we use cookies',
    copy: (
        <React.Fragment>
            <p>
                When you visit our websites or use digital FuelBox, cookies are
                installed locally on your device (PC, mobile phone or tablet).
                FuelBox uses cookies for three purposes:
            </p>
            <ul>
                <li>
                    to deliver and customise our services to the device and
                    browser you use.
                </li>
                <li>
                    to measure and analyse the traffic on our digital platforms
                    to assess how they are used, identify areas of improvement
                    and further develop our services.
                </li>
                <li>
                    in the future, cookies will also be used to provide you with
                    relevant and customized content. We do not currently use
                    cookies for this purpose.
                </li>
            </ul>
            <p>
                When you visit our websites or use digital FuelBox, we install
                cookies that are necessary for you to use our digital platforms.
                If you have allowed cookies in your browser, we may also install
                cookies that serve other purposes, such as statistics and
                analysis or usability. You can withdraw your consent to cookies
                anytime.
            </p>
            <p>
                Please note that there may be installed cookies that are not
                documented in this policy.
            </p>
            <h4>Statistics tools</h4>
            <p>
                To develop and improve our digital platforms, we use Google
                Analytics (provided by Google, Inc) and Hotjar (provided by
                Hotjar Ltd.) to measure traffic and behavior. We manage these
                tools using Google Tag Manager (provided by Google, Inc). Google
                Analytics and Hotjar use cookies to analyse how visitors to our
                digital services make use of these. The information that is
                being collected is processed in anonymized and aggregated form.
                This means that the information cannot be traced back to
                individual users and will not be processed individually.
            </p>
            <p>Further information about which cookies are used:</p>
            <ul>
                <li>
                    Google Analytics:&nbsp;
                    <a href='https://support.google.com/analytics/answer/6004245'>
                        https://support.google.com/analytics/answer/6004245
                    </a>
                </li>
                <li>
                    Hotjar:&nbsp;
                    <a href='https://www.hotjar.com/cookies'>
                        https://www.hotjar.com/cookies
                    </a>
                </li>
            </ul>
            <p>
                If you do not want FuelBox to collect anonymous data from you,
                you have the option to opt out:
            </p>
            <ul>
                <li>
                    Google Analytics:&nbsp;
                    <a href='https://tools.google.com/dlpage/gaoptout'>
                        https://tools.google.com/dlpage/gaoptout
                    </a>
                </li>
                <li>
                    Hotjar:&nbsp;
                    <a href='https://www.hotjar.com/legal/compliance/opt-out'>
                        https://www.hotjar.com/legal/compliance/opt-out
                    </a>
                </li>
            </ul>
            <h4>Content management systems</h4>
            <p>
                The content management systems we use also install cookies on
                your device when you visit our digital platforms. We use
                Wordpress for our websites and Netlify for our digital FuelBox
                solution.
            </p>
            <p>Further information about which cookies are used:</p>
            <ul>
                <li>
                    Wordpress:&nbsp;
                    <a href='https://wordpress.org/support/article/cookies/'>
                        https://wordpress.org/support/article/cookies/
                    </a>
                </li>
                <li>
                    Netlify:&nbsp;
                    <a href='https://www.netlify.com/privacy/'>
                        https://www.netlify.com/privacy/
                    </a>
                </li>
            </ul>
            <h4>Managing cookies</h4>
            <p>
                In your browser settings you can find an overview of all cookies
                stored on your device. From there, you can block or allow
                cookies from the websites you visit.
            </p>
        </React.Fragment>
    ),
};

const e5 = {
    title: '5 Our use of data processors',
    copy: (
        <React.Fragment>
            <p>
                We use service providers that perform certain tasks for us. Some
                of these service providers will have access to the personal data
                that FuelBox has collected. The service providers are data
                processors for FuelBox, but it is still FuelBox that is
                responsible for the processing. The data processing company can
                only process the personal data for the purpose of providing
                FuelBox with the service in question. They are prohibited from
                using the personal data in any other way than as agreed. We have
                contracts with all suppliers that process personal data for
                which we are responsible, which ensures that the data is
                processed in a justifiable manner.
            </p>
            <p>
                We use service providers for tasks like managing our digital
                platforms, handling shipments and product returns, and to
                provide the payment services.
            </p>
            <p>
                To manage payments made on our digital platforms, we use payment
                services from Bambora. The payment information you provide when
                purchasing products or services from FuelBox is encrypted by the
                payment service providers and is never available to FuelBox
                employees. The payment service provider has its own terms and
                procedures for processing personal data. You can access these
                when you choose the payment method on our websites or by
                following the link:{' '}
                <a href='https://www.bambora.com/privacy-policy/'>
                    https://www.bambora.com/privacy-policy/
                </a>
            </p>
        </React.Fragment>
    ),
};

const e6 = {
    title: '6 Storing and deleting personal data',
    copy: (
        <React.Fragment>
            <p>
                All personal data that FuelBox collects is stored and processed
                in a safe and secure manner. We have established and documented
                procedures and measures to ensure the integrity, accessibility
                and confidentiality of the data.
            </p>
            <p>
                Only selected employees of FuelBox have access to our customers’
                personal data. Access is regulated through access control and
                passwords.
            </p>
            <p>
                When you make a purchase in our online shop or purchase a
                digital FuelBox subscription, the payment service provider
                stores and processes the payment information you provide. Fuel
                It retrieves reports from the payment service providers to
                manage purchases and in connection with accounting reporting.
                However, the reports only contain transactional data in the form
                of date, item and value. The reports never contain identifying
                personal data or payment information.
            </p>

            <p>
                We will delete personal data when it is no longer necessary for
                us to process it. This varies depending on the purpose of the
                data processing.
            </p>
            <p>
                When you purchase products or services from FuelBox on our
                digital platforms, FuelBox has an obligation to retain the data
                provided in accordance with the requirements of the
                Norwegian&nbsp;
                <a href='https://lovdata.no/dokument/NL/lov/1998-07-17-56'>
                    Accounting Act,
                </a>
                &nbsp;which applies at all times. As of January 2020, FuelBox
                must store customer details and transaction data for five years.
                As soon as the retention obligation expires, we will delete any
                identifying data we have stored about you.
            </p>
            <p>
                If you have consented to the processing of your personal data
                for a specific purpose, such as for receiving newsletters from
                FuelBox, you can withdraw your consent anytime. We will then
                stop processing the data that the consent applies to. We have
                deletion procedures that ensure that your personal data is
                deleted within 30 days of withdrawing your consent. If you are a
                FuelBox subscriber, you can easily manage your consent in My
                account. You can also withdraw your consent by following the
                unsubscribe link in the marketing communications you receive by
                email.
            </p>
        </React.Fragment>
    ),
};

const e7 = {
    title: '7 Your rights',
    copy: (
        <React.Fragment>
            <p>
                You have the right to access any personal data that we have
                stored about you, and the right to ask for the data to be
                corrected or deleted. You are also entitled to information about
                the processing FuelBox conducts, which is described above. You
                also have the right to require a limitation to the processing of
                your personal data and may, under certain conditions, object to
                the further processing of your personal data or require your
                personal data to be transferred to yourself or another data
                processor (data portability).
            </p>
            <p>
                In cases in which we process your personal data based on your
                consent, you have the right to withdraw your consent anytime.
                You also have the right to oppose the processing of your data,
                for example for purposes such as direct marketing and
                newsletters. In certain circumstances, you also have the right
                to have your personal data deleted or blocked. This does not
                apply in cases where FuelBox is required by law to preserve or
                process the data, or when the data is necessary for FuelBox to
                fulfil an agreement with you.
            </p>
            <p>
                We have a common interest in ensuring that the data we have
                stored about you is accurate and complete. If you wish to make
                changes or otherwise exercise your rights, you can contact us
                at&nbsp;
                <a href='mailto:post@fuelboxworld.com'>post@fuelboxworld.com</a>
                &nbsp;or <a href='tel:+47 959 78 064'>+47 959 78 064</a>.
            </p>
        </React.Fragment>
    ),
};

const e8 = {
    title: '8 Contact FuelBox',
    copy: (
        <React.Fragment>
            <p>
                If you have any questions related to FuelBox processing of
                personal data, would like access to your data or wish to request
                that we correct or delete data we have stored about you, you can
                contact us by sending an email to&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelboxworld.com</a>
                &nbsp;or phone <a href='tel:+47 959 78 064'>+47 959 78 064</a>.
            </p>
        </React.Fragment>
    ),
};

export const copyNO = {
    title: titleNO,
    items: [q1, q2, q3, q4, q5, q6, q7, q8],
};

export const copyEN = {
    title: titleEN,
    items: [e1, e2, e3, e4, e5, e6, e7, e8],
};
