import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentRequest } from '@stripe/stripe-js';
import {
    PaymentRequestButtonElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { deviceSelector } from '../../../../../store-modules/app-settings';
import { confirmApplePayOrder } from '../../../_services';
import style from './apple-pay.module.scss';
import applePayClient from 'api/apple-pay-client';
import { ApplePayButton } from 'components/apple-pay-button';

type Props = {
    productID: string | number;
    price: number;
    currency: string;
    discountCode: string | undefined;
    onSuccess: () => void;
    onCancel: () => void;
};

const ApplePay: FC<Props> = (props: Props) => {
    const { productID, currency, discountCode, onSuccess, onCancel } = props;

    const device = useSelector(deviceSelector);

    const stripe = useStripe();
    const elements = useElements();

    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
        null
    );

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }

        const productId = productID;
        const code = discountCode;

        if (device !== 'ios') {
            applePayClient
                .createOrder({ productId, code })
                .then(({ discountPrice, order_id }) => {
                    const pr = stripe.paymentRequest({
                        country: 'NO',
                        currency: currency.toLocaleLowerCase(),
                        total: {
                            label: 'FuelBox',
                            amount: discountPrice * 100,
                        },
                        requestPayerName: true,
                        disableWallets: ['googlePay', 'browserCard'],
                        requestPayerEmail: true,
                    });

                    // Check the availability of the Payment Request API.
                    pr.canMakePayment().then((result) => {
                        if (result) {
                            setPaymentRequest(pr);
                        }
                    });

                    pr.on('paymentmethod', async (ev) => {
                        const {
                            client_secret: clientSecret,
                        } = await applePayClient.paymentIntent({
                            order_id,
                            discountPrice,
                        });

                        const {
                            error: confirmError,
                            paymentIntent,
                        } = await stripe.confirmCardPayment(
                            clientSecret,
                            {
                                payment_method: ev.paymentMethod.id,
                            },
                            { handleActions: false }
                        );

                        // console.log(priceWithDiscount)
                        if (confirmError) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            ev.complete('fail');
                            onCancel();
                        } else {
                            // Report to the browser that the confirmation was successful, prompting
                            // it to close the browser payment method collection interface.
                            ev.complete('success');
                            // Check if the PaymentIntent requires any actions and if so let Stripe.js
                            // handle the flow. If using an API version older than "2019-02-11" instead
                            // instead check for: `paymentIntent.status === "requires_source_action"`.
                            if (paymentIntent?.status === 'requires_action') {
                                // Let Stripe.js handle the rest of the payment flow.
                                const {
                                    error,
                                } = await stripe.confirmCardPayment(
                                    clientSecret
                                );
                                if (error) {
                                    onCancel();
                                    // The payment failed -- ask your customer for a new payment method.
                                } else {
                                    confirmApplePayOrder(order_id)
                                        .then((data) => {
                                            if (data) {
                                                onSuccess();
                                            } else {
                                                console.log(
                                                    'Payment has declined !'
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            throw err;
                                        });
                                }
                            } else {
                                confirmApplePayOrder(order_id)
                                    .then((data) => {
                                        if (data) {
                                            onSuccess();
                                        } else {
                                            console.log(
                                                'Payment has declined !'
                                            );
                                        }
                                    })
                                    .catch((err) => {
                                        throw err;
                                    });
                            }
                        }
                    });
                })
                .catch((err) => {
                    throw err;
                });
        }
    }, [
        device,
        stripe,
        elements,
        productID,
        currency,
        discountCode,
        onSuccess,
        onCancel,
    ]);

    return (
        <div>
            {paymentRequest && (
                <PaymentRequestButtonElement
                    className={style.applePay}
                    options={{ paymentRequest }}
                />
            )}
            {device === 'ios' && (
                <ApplePayButton
                    productID={productID}
                    discountCode={discountCode}
                    currency={currency}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

export default ApplePay;
