import { ScrollPosition } from '_types/scoll-position';

export const getElementScrollPosition = ({
    element,
    useWindow,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): ScrollPosition => {
    const isBrowser = typeof window !== 'undefined';

    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top };
};

export const getWindowScrollPosition = (window: Window): ScrollPosition => ({
    x: window.scrollX,
    y: window.scrollY,
});
