import { RootReducer } from 'store/store';
import { AuthState } from './_types';

const authStateFromRoot = (state: RootReducer): AuthState => state.auth;

export const authenticatedSelector = (state: RootReducer): boolean =>
    authStateFromRoot(state).authenticated;

export const tokenSelector = (state: RootReducer): string | null =>
    authStateFromRoot(state).token;
