import React from 'react';
import { SEO } from 'components/seo';
import ActiveSubscriptionRoutes from './active-subscription-routes';
import InactiveSubscriptionRoutes from './inactive-subscription-routes';
import { useSelector } from 'react-redux';
import { subscriptionSelector, userSelector } from 'store-modules/user';

interface Props {}

const PremiumRoutes: React.FC<Props> = () => {
    const activeSubscription = !!useSelector(subscriptionSelector);
    const user = useSelector(userSelector);

    const routesToUse =
        activeSubscription && user?.isConfirmed ? (
            <ActiveSubscriptionRoutes />
        ) : (
            <InactiveSubscriptionRoutes />
        );

    return (
        <React.Fragment>
            <SEO noIndex={true} />
            {routesToUse}
        </React.Fragment>
    );
};

export default PremiumRoutes;
