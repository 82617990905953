import * as actionTypes from './_actionTypes';
import { AuthenticatedAction } from './_types';
import { clearUserState } from 'store-modules/user';
import { ThunkAction } from 'redux-thunk';
import { RootReducer } from 'store/store';
import { clearProAccount } from 'store-modules/professional';
import { clearFavouriteConversations } from 'store-modules/favourite-conversations';
import { clearFavouriteQuestions } from 'store-modules/favourite-questions';
import { clearMyConversations } from 'store-modules/my-conversations';

export const userAuthenticated = (token: string): AuthenticatedAction => ({
    type: actionTypes.LOGIN,
    payload: token,
});

export const userUnauthenticated =
    (): ThunkAction<
        void,
        RootReducer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any
    > =>
    (dispatch) => {
        dispatch(clearFavouriteConversations());
        dispatch(clearFavouriteQuestions());
        dispatch(clearMyConversations());
        dispatch(clearUserState());
        dispatch(clearProAccount());
        dispatch({
            type: actionTypes.LOGOUT,
        });
    };
