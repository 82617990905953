import React from 'react';
import { Helmet } from 'react-helmet';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';

const FUELBOX = 'Fuelbox';

interface Props {
    title?: string;
    description?: string;
    noIndex?: boolean;
}

const SEO: React.FC<Props> = ({
    title = FUELBOX,
    description = 'Enda flere gode samtaler',
    noIndex = false,
}: Props) => {
    const constructedTitle = title === FUELBOX ? title : `${title} | Fuelbox`;
    const language: Language = useSelector(languageSelector);

    return (
        <Helmet title={constructedTitle} htmlAttributes={{ lang: language }}>
            {noIndex && <meta name='robots' content='noindex' />}
            <meta name='format-detection' content='telephone=no' />
            <meta name='description' content={description} />
            {title && <meta property='og:title' content={constructedTitle} />}
            {description && (
                <meta property='og:description' content={description} />
            )}
        </Helmet>
    );
};

export default SEO;
