import { ConversationSwiperPositionState } from './_types';
import { RootReducer } from 'store/store';

const settingsStateFromRoot = (
    state: RootReducer
): ConversationSwiperPositionState => state.conversationSwiperPositions;

export const conversationSwiperPositionSelector = (
    state: RootReducer
): { [key: number]: number } => {
    return settingsStateFromRoot(state).conversationSwiperPositions;
};
