import React from 'react';
import { Button } from 'components/base';
import styles from './random-card-row.module.scss';
import { Container } from 'components/container';
import { useWindowSize } from 'hooks';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';

interface Props {
    title: string;
    selectRandomQuestion: () => void;
}
interface ViewCopy {
    buttonLabel: string;
}

const nbCopy: ViewCopy = {
    buttonLabel: 'Trekk et kort',
};

const enCopy: ViewCopy = {
    buttonLabel: 'Pick a card',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const RandomCardRow: React.FC<Props> = ({
    title,
    selectRandomQuestion,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const { buttonLabel } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    const randomCardRowTitle = () => {
        if (!isMobile) {
            return <h2 id='random-card-row-title'> {title} </h2>;
        }
    };

    return (
        <Container className={styles.container}>
            <div className={styles.randomCardItem}>
                {randomCardRowTitle()}
                <Button
                    onClick={selectRandomQuestion}
                    data-testid='random-card-button'
                >
                    {buttonLabel}
                </Button>
            </div>
        </Container>
    );
};

export default RandomCardRow;
