import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import style from './in-app-purchase.module.scss';
import applePayClient from '../../../../api/apple-pay-client';
import { Dots } from 'components/loading/dots';
import { PRODUCTS } from 'app';
import { IS_ANDROID_APP, IS_IOS_APP } from './../../../../constants';
import googlePayClient from 'api/google-pay-client';
import { ReactComponent as GooglePayIcon } from './../../../../images/icons/dark_gpay.svg';
import { PurchaseService } from '../../../../common-logic/purchase-service';
import TransactionState = CdvPurchase.TransactionState;

type Props = {
    productID: string | number;
    productPrice: number;
    discountCode: string | undefined;
    onSuccess: () => void;
    onCancel: () => void;
};

const BASE_URL = '/wp-json/fuelbox/v1';
const { REACT_APP_API_URL } = process?.env;

export const InAppPurchase: FC<Props> = (props) => {
    const [product, setProduct] = useState<CdvPurchase.Product | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [orderDiscountCode, setOrderDiscountCode] = useState<any | undefined>(
        undefined
    );

    const { productID, productPrice, discountCode, onSuccess, onCancel } =
        props;
    const productId = productID;
    const code = discountCode;

    const purchaseService = new PurchaseService();
    const IAP2 = purchaseService.store!;

    useEffect(
        () => {
            const purchaseService = new PurchaseService();
            const IAP2 = purchaseService.store!;

            function setupStore(discountPrice: number): void {
                const price = code ? discountPrice : productPrice;
                const productMap = PRODUCTS.get(price);

                const id = productMap!.id;


                IAP2.ready(() => {
                    const p = IAP2.get(id);
                    if (p) {
                        setProduct(p);
                    }
                    IAP2.when().finished(() => {
                        IAP2.update();
                    });
                    IAP2.when().approved(() => {
                        onSuccess();
                    })
                    IAP2.when().unverified(() => {
                        console.error(p);
                        setOrderDiscountCode(undefined);
                        setProduct(null);
                        onCancel();
                        setProcessing(false);
                    });
                    /*IAP2.when().cancelled(() => {
                        setProcessing(false);
                    });
                    IAP2.when().owned(() => {
                        onSuccess();
                    });

                     */
                });
            }

            if (IS_ANDROID_APP && (!product || orderDiscountCode !== code)) {
                setOrderDiscountCode(code ? code : null);
                googlePayClient
                    .createOrder({ productId, code })
                    .then(({ discountPrice, order_id }) => {
                        IAP2.validator = `${REACT_APP_API_URL}${BASE_URL}/GooglePayVerifyReceipt?order=${order_id}`;
                        setupStore(discountPrice);
                    })
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });
            }

            if (IS_IOS_APP && (!product || orderDiscountCode !== code)) {
                setOrderDiscountCode(code ? code : null);
                applePayClient
                    .createOrder({ productId, code })
                    .then(({ discountPrice, order_id }) => {
                        IAP2.validator = `${REACT_APP_API_URL}${BASE_URL}/AppleVerifyReceipt?order=${order_id}`;
                        setupStore(discountPrice);
                    })
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });
            }
        },
        // eslint-disable-next-line
        [code, onCancel, onSuccess, productId, productPrice]
    );

    const onClick = (): void => {
        if (product?.canPurchase) {
            const offer = product.getOffer()!;
            IAP2.order(offer);
            setProcessing(true);
        }
    };

    // product is valid if product !== null
    if (!!product) {

        const transactionState = IAP2.findInLocalReceipts(product)?.state;
        const isTransactionApproved = transactionState === TransactionState.APPROVED;

        const isProductOwned = product.owned;

        return (
            <div className={style['apple-pay-button']}>
                {
                    !processing ? (
                        IS_ANDROID_APP ? (
                            <button
                                className={style.GooglePayButton}
                                onClick={onClick}
                            >
                                <GooglePayIcon className={style.GooglePayIcon}/>
                            </button>
                        ) : (
                            <button
                                className={classnames(
                                    style['apple-pay-button'],
                                    style['apple-pay-button-with-text']
                                )}
                                onClick={onClick}
                            >
                                Subscribe
                            </button>
                        )
                    ) : (
                        <section>
                            {isProductOwned && <p>Subscribed</p>}
                            {(isTransactionApproved || processing) && (
                                <p>Processing...</p>
                            )}
                            {!product.canPurchase && (
                                <p>{"Can't Purchase"}</p>
                            )}
                        </section>
                    )}
            </div>
        );
    } else {
        return (
            <div className={style['apple-pay-button']}>
                <section className={style.dots}>
                    <div>
                        <Dots/>
                    </div>
                </section>
            </div>
        );
    }
};
