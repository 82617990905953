import React from 'react';
import styles from './checkbox.module.scss';

type Props = {
    children?: React.ReactNode;
    isChecked: boolean;
    isDisabled: boolean;
    onStateChanged: (isChecked: boolean) => void;
};

const Checkbox: React.FC<Props> = ({
    isChecked,
    isDisabled,
    onStateChanged,
    children,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onStateChanged(event.target.checked);
    };

    return (
        <div className={styles.checkboxStyle}>
            <label className={styles.checkbox}>
                <input
                    type='checkbox'
                    onChange={handleChange}
                    checked={isChecked}
                    disabled={isDisabled}
                />
                <span className={styles.checkmark}></span>
            </label>
            {children}
        </div>
    );
};

export default Checkbox;
