import React from 'react';
import AccountSubpage from '../subpage/account-subpage';

import styles from './about-page.module.scss';
import { Link } from 'components/link';
import { routesNO, RouteList, routesEN } from 'api/routes';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';

interface Props {}
interface ViewCopy {
    pageTitle: string;
    title: string;
    privacy: string;
    termsOfUse: string;
    contact: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Om FuelBox',
    title: 'Om FuelBox',
    privacy: 'Personvernerklæring',
    termsOfUse: 'Vilkår for bruk',
    contact: 'Kontakt oss',
};

const enCopy: ViewCopy = {
    pageTitle: 'About FuelBox',
    title: 'About FuelBox',
    privacy: 'Privacy policy',
    termsOfUse: 'Terms of use',
    contact: 'Contact us',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const AboutPage: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        title,
        privacy,
        termsOfUse,
        contact,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    const contactLocation = {
        pathname: routes.contact,
        state: {
            backPath: routes.account.index,
        },
    };

    const privacyLocation = {
        pathname: routes.privacyTerms,
        state: {
            backPath: routes.account.index,
        },
    };

    const tncLocation = {
        pathname: routes.tnc,
        state: {
            backPath: routes.account.index,
        },
    };

    return (
        <AccountSubpage pageTitle={pageTitle} title={title}>
            <Link
                to={contactLocation}
                underline={false}
                className={styles.link}
            >
                {contact}
            </Link>
            <Link
                to={privacyLocation}
                underline={false}
                className={styles.link}
            >
                {privacy}
            </Link>
            <Link to={tncLocation} underline={false} className={styles.link}>
                {termsOfUse}
            </Link>
        </AccountSubpage>
    );
};

export default AboutPage;
