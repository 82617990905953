import React from 'react';
import styles from './rectangular-card.module.scss';
import Card, { Props } from './card';
import classNames from 'classnames';

const RectangularCard: React.FC<Props> = ({
    onClick,
    className,
    children,
}: Props) => (
    <Card className={classNames(styles.card, className)} onClick={onClick}>
        {children}
    </Card>
);

export default RectangularCard;
