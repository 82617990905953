import he from 'he';
import { Question } from './question';

interface Illustration {
    src: string;
}

export enum UserConversationTaxonomy {
    en = 'userconversation',
    nb = 'brukersamtale',
}

export interface ConversationCategory {
    name: string;
    slug: string;
}

export interface Conversation {
    id: number;
    name: string;
    title?: string;
    count: number;
    parent: number;
    slug: string;
    description: string;
    taxonomy: string;
    random?: boolean;
    created_by_id?: number;
    created_by_name?: string;
    color_option?: number;
    public?: boolean;
    pro_conversation?: boolean;
    order?: number;
}

export interface ConversationWithCategory extends Conversation {
    category: ConversationCategory;
}

export interface FeaturedConversation extends Conversation {
    illustrationMobile?: Illustration;
    illustrationDesktop?: Illustration;
    colorTheme: 'dark' | 'light';
    hidden: boolean;
    featured: boolean;
}

export const randomCoversation: Conversation = {
    id: 0,
    name: 'random',
    count: 0,
    parent: 0,
    slug: 'random',
    description: 'random',
    taxonomy: 'random',
};

export interface QuestionResponse {
    ID: number;
    menu_order: number;
    post_name: string;
    post_title: string;
}

export interface ConversationResponse {
    term_id: number;
    conversation_id: number;
    name: string;
    slug: string;
    term_group: number;
    term_taxonomy_id: number;
    taxonomy: string;
    description: string;
    parent: number;
    count: number;
    filter: string;
    term_order: string;
    title: string;
    category: string;
    category_name: string;
    created_by_id?: number;
    created_by_name?: string;
    color_option?: number;
    public?: boolean;
    pro_conversation?: boolean;
    order?: number;
}

export interface FeaturedConversationResponse extends ConversationResponse {
    featured: boolean;
    hidden: boolean;
    illustration_desktop: [string, number, number, boolean] | false;
    illustration_mobile: [string, number, number, boolean] | false;
    color_theme: boolean;
}

export const mapQuestion = (question: QuestionResponse): Question => {
    return {
        id: question.ID,
        text: question.post_title,
        slug: question.post_name,
    };
};

export const mapQuestions = (questions: QuestionResponse[]): Question[] => {

    return questions.map((q) => ({
        id: q.ID,
        text: q?.post_title && he.decode(q.post_title),
        slug: q.post_name,
    }));
}

export const mapConversation = (
    conversation: ConversationResponse
): Conversation => {
    return {
        id: conversation.term_id,
        name: he.decode(conversation.name),
        count: conversation.count,
        parent: conversation.parent,
        slug: conversation.slug,
        description: conversation.description,
        taxonomy: conversation.taxonomy,
        title: conversation.title,
        created_by_id: conversation.created_by_id,
        created_by_name: conversation.created_by_name,
        public: conversation.public,
        pro_conversation: conversation.pro_conversation,
        color_option: conversation.color_option,
        order: conversation.order,
    };
};

export const mapConversations = (
    conversations: ConversationResponse[]
): ConversationWithCategory[] =>
    conversations?.map((c) => ({
        id: c.term_id,
        name: he.decode(c.name),
        count: c.count,
        parent: c.parent,
        slug: c.slug,
        description: c.description,
        taxonomy: c.taxonomy,
        title: c.title,
        category: c.created_by_name
            ? { slug: c.created_by_name, name: c.created_by_name }
            : { slug: c.category, name: c.category_name },
    }));

export const mapConversationsWithCategories = (
    conversations: ConversationResponse[]
): ConversationWithCategory[] =>
    conversations?.map((c) => {
        return {
        id: c.term_id,
        name:  he.decode(c.name),
        count: c.count,
        parent: c.parent,
        color_option: c.color_option,
        created_by_id: c.created_by_id,
        created_by_name: c.created_by_name,
        public: c.public,
        slug: c.slug,
        description: c.description,
        taxonomy: c.taxonomy,
        title: c.title,
        category: c.created_by_name
            ? { slug: c.created_by_name, name: c.created_by_name }
            : { slug: c.category, name: c.category_name }, //        category: { slug: c.created_by_id, name: c.created_by_id },
    }});


export const mapMyConversationsWithCategories = (
    conversations: ConversationResponse[]
): ConversationWithCategory[] => {
    return conversations.map((c) => ({
        id: c.conversation_id,
        name: he.decode(c.name ? c.name : ''),
        count: c.count,
        color_option: c.color_option,
        created_by_id: c.created_by_id,
        created_by_name: c.category_name,
        parent: c.parent,
        slug: c.slug,
        description: c.description,
        taxonomy: c.taxonomy,
        title: c.title,
        public: c.public,
        pro_conversation: c.pro_conversation,
        category: { slug: c.category, name: c.category_name }, //        category: { slug: c.created_by_id, name: c.created_by_id },
    }));
};

export const mapMyConversationWithCategories = (
    conversation: ConversationResponse
): ConversationWithCategory => {

    const convo: ConversationWithCategory = {
        id: conversation.conversation_id,
        name: he.decode(conversation.name),
        count: conversation.count,
        color_option: conversation.color_option,
        created_by_id: conversation.created_by_id,
        created_by_name: conversation.created_by_name,
        parent: conversation.parent,
        slug: conversation.slug,
        description: conversation.description,
        taxonomy: conversation.taxonomy,
        title: conversation.title,
        category: {
            slug: conversation.category,
            name: conversation.category_name,
        }, //        category: { slug: c.created_by_id, name: c.created_by_id },w
    };
    return convo;
};

export const mapFeaturedConversations = (
    conversations: FeaturedConversationResponse[]
): FeaturedConversation[] =>
    conversations.map((c) => ({
        id: c.term_id,
        name: he.decode(c.name),
        count: c.count,
        parent: c.parent,
        slug: c.slug,
        description: c.description,
        taxonomy: c.taxonomy,
        featured: c.featured,
        hidden: c.hidden,
        illustrationMobile: c.illustration_mobile
            ? { src: c.illustration_mobile[0] }
            : undefined,
        illustrationDesktop: c.illustration_desktop
            ? { src: c.illustration_desktop[0] }
            : undefined,
        colorTheme: c.color_theme ? 'dark' : 'light',
        favcat: c.category,
        favcatName: c.category_name,
    }));

export const filterFeaturedConversations = (
    conversations: FeaturedConversation[]
): FeaturedConversation[] =>
    conversations.filter(
        (c) =>
            c.featured &&
            !c.hidden &&
            c.illustrationMobile &&
            c.illustrationDesktop
    );
