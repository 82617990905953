import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './my-library-page.module.scss';

import { Container } from 'components/container';
import { favouriteConversationsSelector } from 'store-modules/favourite-conversations';
import { favouriteQuestionsSelector } from 'store-modules/favourite-questions';
import { Grid, useMediaQuery, useTheme } from 'components/base';
import { Collaboration } from 'api/collaboration-client';
import useHistoryRouter from 'hooks/use-history-router';

import PersonalContentCategoryButton from './personal-content-category-button';

type PersonalContentCategoryButtonRowProps = {
    collaborations?: Collaboration[];
};

const PersonalContentCategoryButtonRow: FC<PersonalContentCategoryButtonRowProps> =
    ({ collaborations }) => {
        const { routes } = useHistoryRouter();
        const { t } = useTranslation();

        const favouriteConversations = useSelector(
            favouriteConversationsSelector
        );
        const favouriteQuestions = useSelector(favouriteQuestionsSelector);

        const personalContentCategories = {
            favQuestions: !!favouriteQuestions && favouriteQuestions.length > 0,
            favConversations:
                !!favouriteConversations && favouriteConversations.length > 0,
            collaborations: !!collaborations && collaborations.length > 0,
        };

        const personalContentCategoryCount = Object.values(
            personalContentCategories
        ).filter(Boolean).length;

        const theme = useTheme();
        const smScreen = useMediaQuery(theme.breakpoints.down('md'));
        const minimalDisplay =
            personalContentCategoryCount % 2 === 0 && smScreen;

        return (
            <Container
                className={styles.ConversationCardsOuterContainer}
                maxWidth={1}
                mb={9}
                mt={5}
                px={1}
                width={smScreen && !minimalDisplay ? 330 : 1}
            >
                {personalContentCategoryCount > 0 && (
                    <Grid
                        container
                        spacing={{ xs: 2, lg: 4 }}
                        justifyContent={minimalDisplay ? 'center' : undefined}
                    >
                        {personalContentCategories.favQuestions && (
                            <PersonalContentCategoryButton
                                className={classNames({ minimalDisplay })}
                                icon='favorite'
                                IconProps={{ color: 'error' }}
                                label={t('myLibrary.favQuestions.title')}
                                minimalDisplay={minimalDisplay}
                                subheading={t('questionCount', {
                                    count: favouriteQuestions?.length ?? 0,
                                })}
                                to={routes.favouriteQuestionsList}
                            />
                        )}

                        {personalContentCategories.favConversations && (
                            <PersonalContentCategoryButton
                                className={classNames({ minimalDisplay })}
                                icon='speech_bubble'
                                label={t('myLibrary.favConversations.title')}
                                minimalDisplay={minimalDisplay}
                                subheading={t(
                                    'myLibrary.favConversations.conversationCount',
                                    {
                                        count:
                                            favouriteConversations?.length ?? 0,
                                    }
                                )}
                                to={routes.favouriteConversations}
                            />
                        )}

                        {personalContentCategories.collaborations && (
                            <PersonalContentCategoryButton
                                className={classNames({ minimalDisplay })}
                                icon='desktop'
                                label={t('myLibrary.collaborations.title')}
                                minimalDisplay={minimalDisplay}
                                subheading={t(
                                    'myLibrary.collaborations.conversationCount',
                                    { count: collaborations?.length ?? 0 }
                                )}
                                to={routes.collaborationOverview}
                            />
                        )}
                    </Grid>
                )}
            </Container>
        );
    };

export default PersonalContentCategoryButtonRow;
