import React, { useEffect, useState } from 'react';
import { Question } from '_types/question';
import { Deck } from '_types/deck';
import { Modal } from 'components/base';
import styles from './deck-modal.module.scss';
import { SEO } from 'components/seo';
import { StartConversation } from '../../components/start-conversation';
import QuestionSwiperContainer from './question-swiper-container';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Loading } from 'components/loading';
import { SHRUG, IS_IOS_APP } from '../../constants';
import { authenticatedSelector } from 'store-modules/auth';
import conversationClient, {
    FavouriteConversation,
    FavouriteQuestion,
    MyConversation,
} from 'api/conversation-client';
import { Conversation, ConversationWithCategory } from '_types';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';
import { setFavouriteConversations } from 'store-modules/favourite-conversations';
import { ReactComponent as MoreVertIcon } from '../../images/icons/more-vert.svg';
import classnames from 'classnames';
import { RouteList, routesEN, routesNO } from 'api/routes';
import {
    setToastMessage,
    subscriptionSelector,
    toastMessageSelector,
    userSelector,
} from 'store-modules/user';
import { useWindowSize } from 'hooks';
import { OptionModal } from 'components/option-modal';
import { ToggleConfirmation } from 'components/toggle-confirmation';
import { setMyConversations } from 'store-modules/my-conversations';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import { useNavigate } from 'react-router-dom';

interface Params {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}
interface Props {
    deck: Deck;
    conversation: ConversationWithCategory | null;
    questions: Question[];
    isPremium: boolean;
    onExit: () => void;
    params: Params;
    isRandomQuestions: boolean;
    onGetMoreRandomQuestions: () => void;
    parentPage: string;
    favouriteQuestions: Question[] | null;
    favouriteConversations: Conversation[] | null;
    isConversationInFavourites: boolean;
    setIsConversationInFavourites: (arg0: boolean) => void;
    isQuestionInFavourites: boolean;
    setIsQuestionInFavourites: (arg0: boolean) => void;
    toggleShuffle: () => void;
    isShuffled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchState: any;
    updateConversation: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDeck: (argo0: any) => void;
}
interface ViewCopy {
    footerTextMobile: string;
    footerTextDesktop: string;
    publishConversationTitle: string;
    publishConversationDescription: string;
    publishConversationOption1Label: string;
    publishConversationOption2Label: string;
    publishConversationCancelLabel: string;
    deleteConversationTitle: string;
    deleteConversationDescription: string;
    deleteConversationButtonLabel: string;
    deleteConversationCancelLabel: string;
    nameAndDescriptionOptionLabel: string;
    questionListOptionLabel: string;
    publishOptionLabel: string;
    unPublishOptionLabel: string;
    shareOptionLabel: string;
    deleteOptionLabel: string;
    editNameAndDescriptionTitle: string;
    nameFieldLabel: string;
    descriptionFieldLabel: string;
    saveChangesLabel: string;
    publishToastText: string;
    unPublishToastText: string;
}
const nbCopy: ViewCopy = {
    footerTextMobile: 'Du kan også dra for å gå frem og tilbake',
    footerTextDesktop:
        'Du kan også bruke piltastene for å gå til neste eller forrige spørsmål',
    publishConversationTitle: 'Hvor passer samtalen best?',
    publishConversationDescription:
        'Svaret ditt forteller oss hvordan vi bør sortere samtalen',
    publishConversationOption1Label: 'Hjemme',
    publishConversationOption2Label: 'På jobb',
    publishConversationCancelLabel: 'Avbryt',
    deleteConversationTitle: 'Slett samtalen',
    deleteConversationDescription:
        'Samtalen og alle spørsmål vil bli fjernet fra ditt biliotek',
    deleteConversationButtonLabel: 'Slett samtalen',
    deleteConversationCancelLabel: 'Avbryt',
    nameAndDescriptionOptionLabel: 'Endre navn og beskrivelse',
    questionListOptionLabel: 'Se og rediger spørsmål',
    publishOptionLabel: 'Gjør samtalen offentlig',
    unPublishOptionLabel: 'Gjør samtalen privat',
    shareOptionLabel: 'Del samtalen',
    deleteOptionLabel: 'Slett samtalen',
    editNameAndDescriptionTitle: 'Endre farge, navn og beskrivelse',
    nameFieldLabel: 'Navn',
    descriptionFieldLabel: 'Beskrivelse',
    saveChangesLabel: 'Lagre endringer',
    publishToastText: 'Samtalen er nå offentlig',
    unPublishToastText: 'Samtalen er nå privat',
};
const enCopy: ViewCopy = {
    footerTextMobile: 'You can also swipe to change questions',
    footerTextDesktop: 'You can also use the arrow keys to change questions',
    publishConversationTitle: 'Where would you have the conversation?',
    publishConversationDescription:
        'Your answer tells us how we should sort the conversation',
    publishConversationOption1Label: 'Home',
    publishConversationOption2Label: 'At work',
    publishConversationCancelLabel: 'Cancel',
    deleteConversationTitle: 'Delete conversation',
    deleteConversationDescription:
        'The conversation and questions will be removed from your library',
    deleteConversationButtonLabel: 'Delete conversation',
    deleteConversationCancelLabel: 'Cancel',
    nameAndDescriptionOptionLabel: 'Edit name and description',
    questionListOptionLabel: 'View and edit questions',
    publishOptionLabel: 'Publish conversation',
    unPublishOptionLabel: 'Make conversation private',
    shareOptionLabel: 'Share conversation',
    deleteOptionLabel: 'Delete conversation',
    editNameAndDescriptionTitle: 'Edit colour, name, and description',
    nameFieldLabel: 'Name',
    descriptionFieldLabel: 'Description',
    saveChangesLabel: 'Save changes',
    publishToastText: 'Conversation set to public',
    unPublishToastText: 'Conversation set to private',
};
const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];
const DeckModal: React.FC<Props> = ({
    deck,
    conversation,
    questions,
    isPremium,
    onExit,
    params: { questionSlug },
    isRandomQuestions,
    onGetMoreRandomQuestions,
    parentPage,
    favouriteConversations,
    favouriteQuestions,
    isConversationInFavourites,
    setIsConversationInFavourites,
    isQuestionInFavourites,
    setIsQuestionInFavourites,
    toggleShuffle,
    isShuffled,
    searchState,
    setDeck,
}) => {
    const navigate = useNavigate();
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [forcedHeartShow, setForcedHeartShow] = useState(false);
    const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
    const toastMessage = useSelector(toastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);
    const [isLoading, setIsLoading] = useState(false);
    const [showPublishConversation, setShowPublishConversation] =
        useState<boolean>(false);
    const [showDeleteConversation, setShowDeleteConversation] =
        useState<boolean>(false);

    const showIntro = !questionSlug && !isRandomQuestions;
    const isAuthenticated = useSelector(authenticatedSelector);
    const user = useSelector(userSelector);
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const sub = useSelector(subscriptionSelector);

    const isProUser = user?.organization && !sub?.data.pro_content_disabled;

    const {
        footerTextMobile,
        footerTextDesktop,
        publishConversationTitle,
        publishConversationDescription,
        publishConversationOption1Label,
        publishConversationOption2Label,
        publishConversationCancelLabel,
        deleteConversationTitle,
        deleteConversationDescription,
        deleteConversationButtonLabel,
        deleteConversationCancelLabel,
        publishToastText,
        unPublishToastText,
    } = returnCopyForLanguage(language, copies);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (!isLoading && toastMessage != '') {
                setTimeout(() => {
                    dispatch(setToastMessage(''));
                    setToastToShow('');
                }, 4000);
            }
        };
    });

    const [conversationToDisplayAndSave, setConversationToDisplayAndSave] =
        useState<ConversationWithCategory | null>(conversation);

    const showFavouriteHeart =
        isAuthenticated &&
        !isLastSlide &&
        !(showIntro && deck.created_by_id === user?.id);
    function isCurrentQuestionInList(questions: Question[] | null): boolean {
        if (questions) {
            for (let index = 0; index < questions.length; index++) {
                const q = questions[index];
                if (!!currentQuestion && q.id === currentQuestion.id) {
                    return true;
                }
            }
        }
        return false;
    }
    function isDeckInList(conversations: Conversation[] | null): boolean {
        if (conversations) {
            for (let index = 0; index < conversations.length; index++) {
                const c = conversations[index];
                if (c.id === deck?.id) {
                    return true;
                }
            }
        }
        return false;
    }
    const firstQuestion = questions[0];
    const firstQuestionPath = firstQuestion?.slug;

    const navigateToFirstQuestion = () => firstQuestionPath &&
        navigate(firstQuestionPath, { ...searchState, parentPage });
    const updateConversations = () => {
        Promise.all([conversationClient.getMyFavouriteConversations()]).then(
            (response) => {
                dispatch(setFavouriteConversations(response[0].reverse()));
                setForcedHeartShow(false);
            }
        );
    };
    const update = () => {
        if (!forcedHeartShow) {
            setIsQuestionInFavourites(
                isCurrentQuestionInList(favouriteQuestions)
            );
            setIsConversationInFavourites(isDeckInList(favouriteConversations));
        }
    };
    const onSlide = () => {
        setForcedHeartShow(false);
        update();
    };
    const setCurrentQuestionHandler = (question: Question) => {
        setCurrentQuestion(question);
        update();
    };
    const addQuestionToFavourites = async (question: Question) => {
        const questionsCopy = favouriteQuestions?.slice();
        if (questionsCopy) {
            questionsCopy.unshift(question);
            dispatch(setFavouriteQuestions(questionsCopy));
        } else {
            const newQuestionList: Question[] = [question];
            dispatch(setFavouriteQuestions(newQuestionList));
        }
        setForcedHeartShow(false);
        try {
            const questionToAdd: FavouriteQuestion = {
                question_id: question!.id,
            };
            await conversationClient.addFavouriteQuestion(questionToAdd);
        } catch (err) {
            console.error('Could not add question to favourites', err);
        }
    };
    const removeFavouriteQuestion = async (question: Question) => {
        const questionsCopy = favouriteQuestions?.slice();
        if (questionsCopy) {
            questionsCopy.splice(questionsCopy.indexOf(question), 1);
            dispatch(setFavouriteQuestions(questionsCopy));
        }
        setForcedHeartShow(false);
        try {
            await conversationClient.removeFavouriteQuestion({
                question_id: question.id,
            });
        } catch (err) {
            console.error('Could not remove question from favourites', err);
        }
    };
    const toggleFavourite = async () => {
        //Add conversation to favourites
        if (showIntro) {
            const conversationToAdd: FavouriteConversation = {
                conversation_id: deck.id,
                taxonomy: deck.taxonomy
            };
            try {
                if (isConversationInFavourites) {
                    await conversationClient.removeFavouriteConversation(
                        conversationToAdd
                    );
                } else {
                    await conversationClient.addFavouriteConversation(
                        conversationToAdd
                    );
                }
                updateConversations();
            } catch (err) {
                console.error('Could not add Conversation to favourites', err);
            }
        }
        //Add question to favourites
        else if (!showIntro) {
            setIsQuestionInFavourites(!isQuestionInFavourites);
            try {
                if (isQuestionInFavourites) {
                    await removeFavouriteQuestion(currentQuestion!);
                } else {
                    await addQuestionToFavourites(currentQuestion!);
                }
            } catch (err) {
                console.error('Could not add question to favourites', err);
            }
        }
    };

    const convertToMyConversation = (
        conversation: ConversationWithCategory,
        questions: Question[]
    ): MyConversation => {
        const myConversation: MyConversation = {
            conversation_id: conversation.id,
            name: conversation.name,
            description: conversation.description,
            color_option: conversation.color_option
                ? conversation.color_option
                : 1,
            public: conversation.public ? conversation.public : false,
            pro_conversation: conversation.pro_conversation
                ? conversation.pro_conversation
                : false,
            questions: questions.map((q) => {
                return { question_id: q.id };
            }),
        };

        return myConversation;
    };

    const publishConversation = (publicConversation: boolean, pro: boolean) => {
        if (!!deck && deck?.created_by_id === user?.id) {
            setIsLoading(true);
            convertToMyConversation(
                { ...conversationToDisplayAndSave! },
                questions
            );
            conversationClient
                .updateMyConversation(
                    convertToMyConversation(
                        {
                            ...conversationToDisplayAndSave!,
                            public: publicConversation,
                            pro_conversation: pro,
                        },
                        questions
                    )
                )
                .then(() => {
                    setShowPublishConversation(false);
                    setIsLoading(false);
                    conversationClient.getMyConversations().then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                    });
                    setConversationToDisplayAndSave({
                        ...conversationToDisplayAndSave!,
                        public: publicConversation,
                        pro_conversation: pro,
                    });
                    setDeck({
                        ...deck!,
                        public: publicConversation,
                        pro_conversation: pro,
                    });
                });
        }

        publicConversation
            ? setToastToShow(publishToastText)
            : setToastToShow(unPublishToastText);
        setTimeout(() => {
            setToastToShow('');
        }, 3000);
    };

    const onDeleteConversation = () => {
        setIsLoading(true);

        conversationToDisplayAndSave &&
            conversationClient
                .removeMyConversation(
                    conversationToDisplayAndSave.id.toString()!
                )
                .then(() => {
                    conversationClient
                        .getMyConversations()
                        .then((response) => {
                            dispatch(setMyConversations(response.reverse()));
                            setIsLoading(false);
                        })
                        .then(() => {
                            navigate(routes.myLibrary);
                        });
                });
    };

    const showOptions =
        (showIntro && !!deck && deck?.created_by_id === user?.id) ||
        (!showIntro && isAuthenticated && !isLastSlide);

    const randomDeckTitle = SHRUG;
    const deckTitle = !showIntro && !isRandomQuestions ? deck.name : '';
    const seoTitle =
        showIntro || !isRandomQuestions ? deck.name : randomDeckTitle;

    const toastMessageItem = toastToShow.length > 0 && (
        <div className={styles.ToastContainer}>
            <ToastMessage
                className={
                    toastToShow.length > 0
                        ? styles.RemovedQuestionToast
                        : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
            />
        </div>
    );

    return (!!questions.length || deck.created_by_id) && !isLoading ? (
        <div>
            <Modal
                titleId={deck.name}
                title={deckTitle}
                onExit={onExit}
                className={
                    isRandomQuestions
                        ? styles.deckModalRandom
                        : styles.deckModal
                }
            >
                {toastMessageItem}
                {showOptionModal && (
                    <OptionModal
                        toggleModal={() => {
                            setShowOptionModal(!showOptionModal);
                        }}
                        isPublicConversation={
                            !!conversationToDisplayAndSave?.public
                        }
                        onEditTexts={() => {
                            navigate(routes.editConversationColorAndTexts, {
                                state: {
                                    deck: conversationToDisplayAndSave,
                                    questions,
                                },
                            });
                        }}
                        onEditQuestions={() => {
                            navigate(routes.editConversationQuestions, {
                                state: {
                                    deck: conversationToDisplayAndSave,
                                    questions: questions,
                                    emptyState:
                                        questions.length > 0 ? false : true,
                                },
                            });
                        }}
                        onTogglePublic={() => {
                            if (conversationToDisplayAndSave?.public) {
                                publishConversation(false, false);
                                setIsLoading(true);
                                setToastToShow(unPublishToastText);
                                setTimeout(() => {
                                    setToastToShow('');
                                }, 3000);
                            } else {
                                isProUser
                                    ? setShowPublishConversation(true)
                                    : publishConversation(true, false);
                            }
                        }}
                        onDeleteConversation={() => {
                            setShowDeleteConversation(true);
                        }}
                    />
                )}
                <SEO title={seoTitle} />
                <div className={showIntro ? styles.Bg : styles.BgBubbles} />
                {showIntro && (
                    <section className={styles.introSection}>
                        <StartConversation
                            deck={deck}
                            startHandler={navigateToFirstQuestion}
                            isShuffled={isShuffled}
                            toggleShuffle={toggleShuffle}
                            addQuestionsFromFavourites={() => {
                                navigate(routes.editConversationQuestions, {
                                    state: {
                                        deck: conversationToDisplayAndSave,
                                        questions: questions,
                                        addFromFavouritesState: true,
                                    },
                                });
                            }}
                            onSearchClick={() => {
                                navigate(routes.search);
                            }}
                            questions={questions}
                        />
                    </section>
                )}
                {showFavouriteHeart && (
                    <button
                        className={
                            showIntro
                                ? classnames(
                                      styles.favouriteHeartIntro,
                                      styles.favouriteHeart
                                  )
                                : styles.favouriteHeart
                        }
                        onClick={() => {
                            setForcedHeartShow(true);
                            showIntro
                                ? setIsConversationInFavourites(
                                      !isConversationInFavourites
                                  )
                                : setIsQuestionInFavourites(
                                      !isQuestionInFavourites
                                  );
                            toggleFavourite();
                        }}
                    >
                        <div
                            className={
                                (isConversationInFavourites && showIntro) ||
                                (isQuestionInFavourites && !showIntro)
                                    ? styles.redHeartIcon
                                    : styles.redHeartIconOutline
                            }
                        ></div>
                    </button>
                )}
                {showOptions && isAuthenticated && (
                    <button
                        className={styles.moreVert}
                        onClick={() => {
                            showIntro && !!deck?.created_by_id
                                ? isMobile
                                    ? navigate(routes.myConversationInfoPage, {
                                          state: {
                                              deck: conversationToDisplayAndSave,
                                              questions: questions,
                                              deckParentPage: parentPage,
                                          },
                                      })
                                    : setShowOptionModal(true)
                                : navigate(routes.questionInfoPage, {
                                      state: {
                                          conversation: deck,
                                          question: currentQuestion,
                                          parentPage: parentPage,
                                      },
                                  });
                        }}
                    >
                        <MoreVertIcon />
                    </button>
                )}
                {!showIntro && (
                    <section className={styles.swiperSection}>
                        <QuestionSwiperContainer
                            deck={deck}
                            questions={questions}
                            isPremium={isPremium}
                            isRandomQuestions={isRandomQuestions}
                            onGetMoreRandomQuestions={onGetMoreRandomQuestions}
                            language={language}
                            parentPage={parentPage}
                            setIsLastSlide={setIsLastSlide}
                            setCurrentQuestion={setCurrentQuestionHandler}
                            onSlide={onSlide}
                        />
                    </section>
                )}
                {!isRandomQuestions && !showIntro && (
                    <footer
                        className={
                            IS_IOS_APP
                                ? classnames(styles.footer, styles.IOSFooter)
                                : styles.footer
                        }
                    >
                        <span
                            className={styles.text}
                            data-content-mobile={footerTextMobile}
                            data-content-desktop={footerTextDesktop}
                        />
                    </footer>
                )}
            </Modal>
            {showPublishConversation && (
                <ToggleConfirmation
                    title={publishConversationTitle}
                    description={publishConversationDescription}
                    cancelLabel={publishConversationCancelLabel}
                    option1Label={publishConversationOption1Label}
                    option2Label={publishConversationOption2Label}
                    onSelectOption1={() => {
                        publishConversation(true, false);
                    }}
                    onSelectOption2={() => {
                        publishConversation(true, true);
                    }}
                    onCancel={() => {
                        setShowPublishConversation(false);
                    }}
                />
            )}

            {showDeleteConversation && (
                <ToggleConfirmation
                    title={deleteConversationTitle}
                    description={deleteConversationDescription}
                    cancelLabel={deleteConversationCancelLabel}
                    buttonLabel={deleteConversationButtonLabel}
                    onSubmit={() => {
                        onDeleteConversation();
                    }}
                    onCancel={() => setShowDeleteConversation(false)}
                ></ToggleConfirmation>
            )}
        </div>
    ) : (
        <Loading />
    );
};

export default DeckModal;
