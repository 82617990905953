import React, { FC, Fragment, ReactNode, useMemo } from 'react';
import { nanoid } from 'nanoid';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';

import { Actions } from './components/actions';
import { Content } from './components/content';
import { LightThemeProvider } from 'style/theme';

export type DialogProps = Omit<
    MuiDialogProps,
    | 'BackdropComponent'
    | 'BackdropProps'
    | 'children'
    | 'classes'
    | 'closeAfterTransition'
    | 'components'
    | 'componentsProps'
    | 'container'
    | 'disableAutoFocus'
    | 'disableBackdropClick'
    | 'disableEnforceFocus'
    | 'disableEscapeKeyDown'
    | 'disablePortal'
    | 'disableRestoreFocus'
    | 'disableScrollLock'
    | 'elevation'
    | 'fullScreen'
    | 'fullWidth'
    | 'hideBackdrop'
    | 'keepMounted'
    | 'manager'
    | 'maxWidth'
    | 'onBackdropClick'
    | 'onEnter'
    | 'onEntered'
    | 'onEntering'
    | 'onEscapeKeyDown'
    | 'onExit'
    | 'onExited'
    | 'onExiting'
    | 'onRendered'
    | 'PaperComponent'
    | 'PaperProps'
    | 'scroll'
    | 'size'
    | 'TransitionComponent'
    | 'transitionDuration'
    | 'TransitionProps'
> & {
    children: ReactNode;
    title?: string;
};

type DialogSubTypes = {
    /**
     * The action area is intended for one or more `Button` components. The area is padded, the content floats to the right, and the elements are spaced automatically.
     */
    Actions: typeof Actions;
    /**
     * A simple wrapper that provides consistent spacing around the main content of all dialogs. If the `children` prop is of type `string`, it will be wrapped with a `p` tag with no additional spacing, otherwise it will be rendered as-is. Simple textual content consisting of a single paragraph should be passed directly.
     */
    Content: typeof Content;
};

const StyledDialog = styled(MuiDialog)`
    &.fs .paper {
        display: inline-flex;
        flex-direction: column;

        .dialog-content {
            flex: 1;
        }
    }
` as typeof MuiDialog;

/**
 * Implements [Dialog](https://mui.com/components/dialogs/) from **Material-UI**.
 */
export const Dialog: FC<DialogProps> & DialogSubTypes = ({
    children,
    title,
    ...rest
}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const titleId = useMemo(() => nanoid(), []);

    const content = (
        <Fragment>
            {title && <DialogTitle id={titleId}>{title}</DialogTitle>}
            {children}
        </Fragment>
    );

    const Component = mobile ? Drawer : StyledDialog;

    return (
        <Component aria-labelledby={titleId} scroll='body' {...rest}>
            {theme.palette.mode === 'light' ? (
                content
            ) : (
                <LightThemeProvider>{content}</LightThemeProvider>
            )}
        </Component>
    );
};

Dialog.Actions = Actions;
Dialog.Content = Content;
