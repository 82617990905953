import React, { useEffect, useState } from 'react';
import styles from './delete-account.module.scss';
import { Button, Link } from 'components/base';
import AccountSubpage from '../subpage/account-subpage';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnRoutesForLanguage } from 'services/language-service';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import {
    setBoldToastMessage,
    setToastMessage,
    setUserState,
    setUserSub,
    subscriptionSelector,
    userSelector,
} from 'store-modules/user';
import userClient from 'api/user-client';
import { Loading } from 'components/loading';
import authService from 'services/auth-service';
import subscriptionClient from 'api/subscription-client';
import { authenticatedSelector } from 'store-modules/auth';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const DeleteAccount: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const user = useSelector(userSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routes = returnRoutesForLanguage(language, routeObj);
    const subscription = useSelector(subscriptionSelector);
    const authenticated = useSelector(authenticatedSelector);
    const { isiOS } = getUAInfo(window.navigator);
    const dispatch = useDispatch();
    const hasAppleIAPSubscription = subscription?.data.iap === 'apple';
    const hasGoogleIAPSubscription = subscription?.data.iap === 'google';
    const [isLoading, setIsLoading] = useState(true);
    const stillActiveStatuses = ['active', 'pending-cancel'];
    const status = subscription?.data.status;
    const stillActive = status ? stillActiveStatuses.includes(status) : null;
    const isProUser = user?.organization;

    useEffect(() => {
        authenticated &&
            subscriptionClient.getActiveSubscription().then((sub) => {
                dispatch(setUserSub(sub!));
                setIsLoading(false);
            });
    }, []);

    //This checks every 10 seconds if the user has cancelled their IAP subscription on iphone or android.
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         authenticated &&
    //             subscriptionClient.getActiveSubscription().then((sub) => {
    //                 dispatch(setUserSub(sub!));
    //             });
    //     }, 10000);
    //     return () => clearInterval(interval);
    // }, []);

    const expiryDate = new Date(
        subscription?.data.expiry_date
            ? subscription?.data.expiry_date
            : subscription?.data.subscription.next_payment_date
            ? subscription?.data.subscription.next_payment_date
            : ''
    );

    const expiryDateFormatted = expiryDate.toLocaleDateString(
        language === 'en' ? 'en-EN' : 'nb-NO',
        {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }
    );

    const deleteAccount = (): void => {
        try {
            setIsLoading(true);
            //IMMEDIATE DELETION for pro users and other users without subscription
            if (user && (!stillActive || isProUser)) {
                userClient.deleteUser(user.id).then(() => {
                    localStorage.removeItem('token');
                    authService.logout();
                    navigate(routes.userConfirmDeleted);
                });
            } else {
                //INITIATE DELETION for users with active subscription
                user &&
                    stillActive &&
                    !isProUser &&
                    userClient.deleteUser(user.id).then((force_logout) => {
                        force_logout && localStorage.removeItem('token');
                        userClient.getCurrentUser().then((user) => {
                            dispatch(setUserState(user!));
                            dispatch(setToastMessage(t('deleteAccountToast')));
                            dispatch(setBoldToastMessage(expiryDateFormatted));
                            navigate(routes.account.index);
                        });
                    });
            }
        } catch (e) {
            console.error(e);
            navigate(routes.index);
        }
    };

    const routeToiOSSubscriptions = (): void => {
        navigate(routes.iOSSubscriptions);
    };

    const routeToGoogleSubscriptions = (): void => {
        navigate(routes.googleSubscriptions);
    };

    if (isLoading) {
        return <Loading />;
    }
    return (
        <AccountSubpage
            pageTitle={t('deleteAccount.pageTitle')}
            title={t('deleteAccount.title')}
        >
            {(hasAppleIAPSubscription || hasGoogleIAPSubscription) &&
            subscription.data.status === 'active' ? (
                hasAppleIAPSubscription ? (
                    <div className={styles.infoBox}>
                        <p className={styles.text}>
                            {!isiOS
                                ? t('deleteAccount.iOSInAppSubTextNoiPhone')
                                : t('deleteAccount.iOSInAppSubText')}
                        </p>
                        {!isiOS && (
                            <div>
                                {' '}
                                <p className={styles.iOSSteps}>
                                    {t('deleteAccount.IAPiOSSteps')}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={styles.infoBox}>
                        <p className={styles.text}>
                            {!isiOS
                                ? t('deleteAccount.googleInAppSubTextNoAndroid')
                                : t('deleteAccount.googleInAppSubText')}
                        </p>
                        {!isiOS && (
                            <div>
                                {' '}
                                <p className={styles.iOSSteps}>
                                    {t('deleteAccount.IAPGoogleSteps')}
                                </p>
                            </div>
                        )}
                    </div>
                )
            ) : (
                <div className={styles.infoBox}>
                    <p className={styles.text}>{t('deleteAccount.text1')}</p>
                    <div className={styles.text}>
                        <Trans
                            components={[
                                <Link
                                    color='inherit'
                                    key={1}
                                    className={styles.PointerCursor}
                                    onClick={() => {
                                        navigate(routes.privacyTerms);
                                    }}
                                />,
                            ]}
                            i18nKey='deleteAccount.text2'
                        />
                    </div>

                    <div className={styles.text}>
                        {stillActive && !isProUser && (
                            <Trans
                                components={[<strong key={0} />]}
                                i18nKey='deleteAccount.text3'
                                values={{
                                    date: expiryDateFormatted,
                                }}
                            />
                        )}
                    </div>
                    <p className={styles.text}>{t('deleteAccount.text4')}</p>
                </div>
            )}
            {!(hasAppleIAPSubscription && !isiOS) && (
                <Button
                    onClick={
                        (hasAppleIAPSubscription || hasGoogleIAPSubscription) &&
                        subscription.data.status === 'active'
                            ? hasAppleIAPSubscription
                                ? routeToiOSSubscriptions
                                : routeToGoogleSubscriptions
                            : deleteAccount
                    }
                    className={classNames(
                        styles.ConfirmButton,
                        hasAppleIAPSubscription
                            ? styles.iOSSubscriptionsButton
                            : styles.ConfirmDeleteButton
                    )}
                    appearance={hasAppleIAPSubscription ? 'primary' : 'cta'}
                >
                    {(hasAppleIAPSubscription || hasGoogleIAPSubscription) &&
                    subscription.data.status === 'active'
                        ? hasAppleIAPSubscription
                            ? t('deleteAccount.iOSInAppSubButton')
                            : t('mobileStoreButton.label_android')
                        : t('deleteAccount.deleteMyAccount')}
                </Button>
            )}
        </AccountSubpage>
    );
};

export default DeleteAccount;
