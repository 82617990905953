import React from 'react';
import styles from './account-settings.module.scss';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import { RouteList } from 'api/routes';
import { Link } from 'components/link';
// import { Link } from 'components/link';
// import { routesNO } from 'api/routes';

interface Props {
    language: Language;
    routes: RouteList;
}

interface ViewCopy {
    languageLabel: string;
    notificationsLabel: string;
}

const nbCopy: ViewCopy = {
    languageLabel: 'Velg språk',
    notificationsLabel: 'Endre varslinger',
};

const enCopy: ViewCopy = {
    languageLabel: 'Select language',
    notificationsLabel: 'Change notifications',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const AccountSettings: React.FC<Props> = ({ language, routes }: Props) => {
    const { languageLabel, notificationsLabel } = returnCopyForLanguage(
        language,
        copies
    );

    return (
        <React.Fragment>
            <p className={styles.text}>
                <Link
                    to={routes.account.notifications}
                    className={styles.link}
                    block={false}
                    highlight={false}
                >
                    {notificationsLabel}
                </Link>
            </p>
            <p className={styles.text}>
                <Link
                    to={routes.account.language}
                    className={styles.link}
                    block={false}
                    highlight={false}
                >
                    {languageLabel}
                </Link>
            </p>
        </React.Fragment>
    );
};
export default AccountSettings;
