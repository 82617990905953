import { Breakpoint, useMediaQuery, useTheme } from 'components/base';

export enum ScreenSize {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

type ScreenSizeReturnType = {
    /**
     * Current breakpoint (`xs` | `sm` | `md` | `lg` | `xl`)
     */
    breakpoint: Breakpoint;
    /**
     * Current screen size (`mobile` | `tablet` | `desktop`)
     */
    screenSize: ScreenSize;
};

export const useScreenSize = (): ScreenSizeReturnType => {
    const theme = useTheme();

    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const smScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const mdScreen = useMediaQuery(theme.breakpoints.only('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.only('lg'));

    let breakpoint: Breakpoint = 'xl';

    if (xsScreen) breakpoint = 'xs';
    else if (smScreen) breakpoint = 'sm';
    else if (mdScreen) breakpoint = 'md';
    else if (lgScreen) breakpoint = 'lg';

    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const tabletScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

    let screenSize = ScreenSize.DESKTOP;

    if (mobileScreen) screenSize = ScreenSize.MOBILE;
    else if (tabletScreen) screenSize = ScreenSize.TABLET;

    return { breakpoint, screenSize };
};
