import React, { FC } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import BigStar from '../../../../assets/images/backgrounds/night/star-big.svg';
import ShootingStar from '../../../../assets/images/backgrounds/night/star-shooting.svg';
import SmallStar from '../../../../assets/images/backgrounds/night/star-small.svg';

const BgStarParticles: FC = () => (
    <Particles
        options={{
            manualParticles: [
                { position: { x: 25, y: 25 } },
                { position: { x: 25, y: 75 } },
                { position: { x: 30, y: 13 } },
                { position: { x: 43, y: 11 } },
                { position: { x: 5, y: 90 } },
                { position: { x: 55, y: 85 } },
                { position: { x: 67, y: 70 } },
                { position: { x: 7, y: 13 } },
                { position: { x: 75, y: 75 } },
                { position: { x: 8, y: 68 } },
                { position: { x: 80, y: 25 } },
                { position: { x: 90, y: 80 } },
                { position: { x: 93, y: 9 } },
            ],
            particles: {
                number: {
                    density: { enable: true, area: 800 },
                    value: 0,
                },
                opacity: {
                    animation: { enable: true, speed: 3, sync: false },
                    value: { min: 0, max: 1 },
                },
                shape: {
                    images: [
                        { src: BigStar },
                        { src: ShootingStar },
                        { src: SmallStar },
                        { src: SmallStar },
                        { src: SmallStar },
                    ],
                    type: 'images',
                },
                size: { value: { min: 17.5, max: 17.5 } },
            },
            detectRetina: true,
        }}
        init={async (engine) => await loadFull(engine)}
    />
);

export default BgStarParticles;
