import { decorationSpacing } from 'components/conversation-card/decorator';

const decoratorCount = 3;
const CardButtonDecorators = [1, 2, 3] as const;

export type CardButtonDecorator = typeof CardButtonDecorators[number];

const getDecoratorDefs = () => {
    let curDecorator: CardButtonDecorator = 1;
    let defs: (CardButtonDecorator | undefined)[] = [];

    Array.from(Array(decoratorCount).keys())
        .reduce((acc: number[]) => [...acc, ...decorationSpacing], [])
        .forEach((spacing) => {
            defs = [
                ...defs,
                ...Array.from(Array(spacing + 1).keys()).map((i) =>
                    i === spacing ? curDecorator : undefined
                ),
            ];

            curDecorator++;
            if (curDecorator > decoratorCount) curDecorator = 1;
        });

    return defs;
};

const decoratorDefs = getDecoratorDefs();

const positionCount = decoratorDefs.length;

export const getCardButtonDecorator = (
    index: number
): CardButtonDecorator | undefined => {
    let num = index;
    while (num > positionCount) num -= positionCount;
    return decoratorDefs[num];
};

export const getRandomCardButtonDecorator = (): CardButtonDecorator =>
    CardButtonDecorators[
        Math.floor(Math.random() * CardButtonDecorators.length)
    ];
