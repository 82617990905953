import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';
import { ReactComponent as RevealIcon } from 'images/icons/reveal-hidden.svg';
import { ReactComponent as HideIcon } from 'images/icons/hide-shown.svg';
import styles from './input-row.module.scss';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';

type PasswordInput = 'text' | 'password';

interface OwnProps {
    label: string;
    hint: string;
    errormsg: string;
    showToggle?: boolean;
    className?: string;
    placeholder?: string;
    startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    language: Language;
}

type Props = OwnProps & FieldRenderProps<string>;

interface ViewCopy {
    defaultHint: string;
}

const nbCopy: ViewCopy = {
    defaultHint: 'Bruk både tall og bokstaver, minimum 8 tegn',
};

const enCopy: ViewCopy = {
    defaultHint: 'Use numbers and letters, minimum 8 characters',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const InputPasswordRow = ({
    label,
    input,
    meta: { submitError },
    placeholder,
    className,
    hint,
    startIcon: StartIcon,
    language,
}: Props): JSX.Element => {
    const [passwordVisible, setPasswordVisible] = useState<PasswordInput>(
        'password'
    );
    const { defaultHint } = returnCopyForLanguage(language, copies);

    hint = hint ? hint : defaultHint;

    const onTogglePassword = (): void =>
        setPasswordVisible((prev) => (prev === 'text' ? 'password' : 'text'));

    return (
        <div className={classnames(styles.InputRow, className)}>
            <label className={styles.InputRow__Label} htmlFor={label}>
                {label}
            </label>
            <div className={styles.InputRow__InputContainer}>
                {StartIcon && (
                    <span className={styles.InputRow__StartIcon}>
                        <StartIcon />
                    </span>
                )}
                <input
                    type={passwordVisible}
                    id={label}
                    placeholder={placeholder}
                    {...input}
                    className={classnames(
                        styles.InputRow__Input,
                        StartIcon && styles.withStartIcon,
                        submitError && styles.invalid
                    )}
                />
                <button
                    type='button'
                    onClick={onTogglePassword}
                    className={
                        passwordVisible === 'password'
                            ? styles.InputRow__PassToggle__Reveal
                            : styles.InputRow__PassToggle__Hidden
                    }
                >
                    {passwordVisible === 'password' ? (
                        <RevealIcon />
                    ) : (
                        <HideIcon />
                    )}
                </button>
            </div>
            <span
                className={
                    submitError
                        ? styles.InputRow__Error__Show
                        : hint
                            ? styles.InputRow__Hint
                            : styles.InputRow__Error__Hidden
                }
            >
                {submitError || hint}
            </span>
        </div>
    );
};

export default InputPasswordRow;
