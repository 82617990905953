import React from 'react';
import classnames from 'classnames';
import styles from './conversations-row.module.scss';
import {
    Conversation,
    ConversationCategory,
    ConversationWithCategory,
} from '_types';
import { Container } from 'components/container';
import { useWindowSize } from 'hooks';
import ConversationSwiper, {
    ConversationSlideListItem,
} from './conversation-swiper';
import { Link } from 'components/link';
import { Arrow } from 'components/arrow';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import { pathOfBrowseCategory } from 'services/conversation-service';
import { ConversationList } from '../conversation-list';

interface Props {
    slidesId: number;
    title: string;
    category: ConversationCategory;
    topLevelSlug: string;
    conversations: ConversationWithCategory[];
    onClickConversation: (conversation: Conversation) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardComponent: any;
    className?: string;
    useSwiper: boolean;
}

interface ViewCopy {
    showAll: string;
}

const nbCopy: ViewCopy = {
    showAll: 'Se alle',
};

const enCopy: ViewCopy = {
    showAll: 'View all',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const SLIDE_COUNT = {
    mobile: 6,
    tablet: 16,
    desktop: 24,
};

const SLIDES_PER_GROUP = {
    mobile: 1,
    tablet: 4,
    desktop: 6,
};

const ConversationsRow: React.FC<Props> = ({
    slidesId,
    title,
    category,
    topLevelSlug,
    conversations,
    onClickConversation,
    cardComponent: CardComponent,
    className,
    useSwiper,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const { showAll } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    const maxSlideCount = SLIDE_COUNT[view];
    const slidesPerGroup = SLIDES_PER_GROUP[view];
    const showAllPathname = pathOfBrowseCategory(
        language,
        category.slug,
        topLevelSlug
    );
    const allCardsAreVisible = conversations.length <= maxSlideCount;

    const showLink =
        useSwiper &&
        ((!isMobile && !allCardsAreVisible) || category.slug === topLevelSlug);

    const addShowAllCard = isMobile && !allCardsAreVisible;

    const slideList: ConversationSlideListItem[] = (
        useSwiper ? conversations.slice(0, maxSlideCount) : conversations
    ).map((conversation) => ({
        id: conversation.id,
        content: (
            <CardComponent
                name={conversation.name}
                onClick={() => onClickConversation(conversation)}
                category={conversation.category}
            />
        ),
    }));

    if (useSwiper && addShowAllCard) {
        slideList.push({
            id: 'show-all',
            content: (
                <CardComponent
                    className={styles.showAllSlide}
                    category={category}
                >
                    <Link to={showAllPathname} className={styles.showAllLink}>
                        {showAll} <Arrow direction='right' />
                    </Link>
                </CardComponent>
            ),
        });
    }

    return (
        <article
            className={classnames(styles.row, className)}
            aria-labelledby='conversations-row-title'
        >
            <Container className={styles.container}>
                <header className={styles.header}>
                    <h2 id='conversations-row-title' className={styles.title}>
                        {title}
                    </h2>
                    {showLink && <Link to={showAllPathname}>{showAll}</Link>}
                </header>

                {useSwiper ? (
                    <ConversationSwiper
                        key={view}
                        slidesId={slidesId}
                        slidesPerGroup={slidesPerGroup}
                        showNavigation={view !== 'mobile'}
                        slideList={slideList}
                        noSwiping={view !== 'mobile'}
                    />
                ) : (
                    <ConversationList className={className}>
                        {slideList.map(({ content, id }) => (
                            <article key={id}>{content}</article>
                        ))}
                    </ConversationList>
                )}
            </Container>
        </article>
    );
};

export default ConversationsRow;
