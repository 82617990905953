import React, { FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CardButton,
    CardButtonSubHeading,
    Dialog,
    Grid,
    Link,
} from 'components/base';
import collaborationClient, {
    CollaborationStatus,
} from 'api/collaboration-client';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import { setScrollPosCollaborationsPage } from 'store-modules/app-settings';
import useDateUtils from 'hooks/use-date-utils';
import { getSessionDocumentRef } from 'utils/collaboration-utils';
import useCollaborationFunctions from 'hooks/use-collaboration-functions';

import CollaborationButtonMenuContent from './collaboration-button-menu-content';
import { CollaborationWithFirebaseData } from '../types';

type CollaborationButtonProps = {
    collaboration: CollaborationWithFirebaseData;
    deleteCollabCallback: () => void;
};

const CollaborationButton: FC<CollaborationButtonProps> = ({
    collaboration,
    collaboration: {
        answeredQuestions,
        answeredQuestionWithHighestIndex: { slug } = {},
        categoryName,
        collaborationId,
        collaborationStatus,
        conversation: { questions = [] } = {},
        createdByName,
        name,
        participantCount,
        started,
        startTime,
    },
    deleteCollabCallback,
}) => {
    const { t } = useTranslation();
    const { formatDate } = useDateUtils();
    const dispatch = useDispatch();

    const [deleting, setDeleting] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const confirmDelete = () => {
        setDeleting(true);

        collaborationClient
            .deleteCollaboration(collaborationId)
            .then(() =>
                /**
                 * NOTE: Mark collaboration document as deleted. Since frontend deletion does not offer recursive
                 * delete, this should at some point be done in backend. Marked documents will then be easy to filter
                 * by.
                 */
                getSessionDocumentRef(collaborationId).update({ deleted: true })
            )
            .then(deleteCollabCallback)
            .catch((err) => {
                console.error(err);
                setDeleting(false);
            });
    };

    const { generatePath, getProgressLabel } =
        useCollaborationFunctions(collaboration);

    const setScrollPos = () =>
        dispatch(
            setScrollPosCollaborationsPage(getWindowScrollPosition(window))
        );

    const subheading: CardButtonSubHeading[] = [
        {
            text: createdByName ?? categoryName,
            uppercase: true,
        },
    ];

    if (participantCount != null)
        subheading.push({
            text: t('collaborationOverview.participantCount', {
                count: participantCount,
            }),
        });

    const formattedStartTime = formatDate(startTime);

    if (formattedStartTime != null)
        subheading.push({ text: formattedStartTime });

    return (
        <Fragment>
            <Grid alignItems='stretch' display='flex' item lg={6} xs={12}>
                <CardButton
                    label={name}
                    fullWidth
                    dense
                    subheading={subheading}
                    iconLabel={getProgressLabel(
                        questions?.length ?? 0,
                        answeredQuestions
                    )}
                    icon='desktop'
                    IconProps={
                        collaborationStatus === CollaborationStatus.ACTIVE
                            ? { color: 'error' }
                            : undefined
                    }
                    menuContent={
                        <CollaborationButtonMenuContent
                            collaboration={collaboration}
                            onDelete={() => setDeleteDialogOpen(true)}
                            questionSlug={slug}
                        />
                    }
                    onClick={(e) => (e.ctrlKey ? undefined : setScrollPos())}
                    to={generatePath(started ? slug : undefined)}
                />
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={
                    deleting ? undefined : () => setDeleteDialogOpen(false)
                }
                title={t('collaboration.delete.title')}
            >
                <Dialog.Content>
                    {t('collaboration.delete.description')}
                </Dialog.Content>

                <Dialog.Actions>
                    <Button
                        appearance='cta'
                        loading={deleting}
                        onClick={confirmDelete}
                    >
                        {t('collaboration.delete.confirm')}
                    </Button>

                    <Link
                        component='button'
                        disabled={deleting}
                        onClick={() => setDeleteDialogOpen(false)}
                    >
                        {t('cancel')}
                    </Link>
                </Dialog.Actions>
            </Dialog>
        </Fragment>
    );
};

export default CollaborationButton;
