import React, { useEffect } from 'react';
import styles from './google-login-button.module.scss';
import { logInByGoogle } from 'common-logic/login';
import { RouteList } from 'api/routes';
import { RootReducer } from 'store/store';
import {
    selectedProductSelector,
    setUserState,
    setUserSub,
} from 'store-modules/user';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as GoogleIcon } from '../../images/icons/google.svg';
import { Button, ButtonProps } from 'components/base';
import subscriptionClient from 'api/subscription-client';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Subscription, User } from '_types';

type PropsOwn = Pick<ButtonProps, 'fullWidth'> & {
    text: string;
    routes: RouteList;
    setLoading: (arg: boolean) => void;
    createUser: boolean;
    tokenOnly?: boolean;
    afterOnlyLogin?: () => void;
};

const mapStateToProps = (state: RootReducer) => ({
    selectedProduct: selectedProductSelector(state),
});

type PropsFromState = ReturnType<typeof mapStateToProps>;
type Props = PropsOwn & PropsFromState;

const GoogleLoginButton: React.FC<Props> = ({
    createUser,
    fullWidth,
    routes,
    selectedProduct,
    setLoading,
    text,
    tokenOnly,
    afterOnlyLogin,
}: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const routeAfterLogin = (sub: Subscription, user: User | null) => {
        const stillActiveStatuses = ['active', 'pending-cancel'];
        const status = sub.data.status;
        const stillActive = stillActiveStatuses.includes(status);
        const isProUser = !!user?.organization;
        const proContentDisabled = !!sub.data.pro_content_disabled;

        const route = stillActive
            ? isProUser && !proContentDisabled
                ? routes.proindex
                : routes.index
            : routes.account.index;

        navigate(route);
    };

    const loginToSystem = async (): Promise<void> => {
        setLoading(true);
        try {
            await GoogleAuth.signIn().then(async (response) => {
                if (
                    response &&
                    response.authentication &&
                    response.authentication.idToken.length > 0
                ) {
                    const loginResult = await logInByGoogle(
                        response.authentication.idToken,
                        selectedProduct?.id,
                        tokenOnly
                    );

                    loginResult.subscription &&
                        dispatch(setUserSub(loginResult.subscription));
                    loginResult.user &&
                        dispatch(setUserState(loginResult.user));

                    if (tokenOnly) {
                        afterOnlyLogin && afterOnlyLogin();
                    } else {
                        loginResult.subscription
                            ? routeAfterLogin(loginResult.subscription, loginResult.user)
                            : subscriptionClient
                                  .getActiveSubscription()
                                  .then((sub) => {
                                      if (!!sub) {
                                          dispatch(setUserSub(sub!));
                                          routeAfterLogin(sub, loginResult.user);
                                      }
                                  })
                                  .catch((err) => {
                                      console.error(err);
                                      navigate(routes.error);
                                  });
                    }
                }
            });
        } catch (err) {
            console.error(err);
            navigate(routes.error);
        }
        setLoading(false);
    };

    useEffect(() => {
        GoogleAuth.initialize();
    }, []);

    return (
        <Button
            fullWidth={fullWidth}
            className={
                createUser
                    ? classnames(styles.googleButton, styles.createUser)
                    : styles.googleButton
            }
            onClick={loginToSystem}
        >
            <GoogleIcon />
            <span>{text}</span>
        </Button>
    );
};

export default connect(mapStateToProps)(GoogleLoginButton);
