import Add from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronUp from '@mui/icons-material/KeyboardArrowUp';
import Close from '@mui/icons-material/Close';
import Cloud from '@mui/icons-material/Cloud';
import Delete from '@mui/icons-material/DeleteOutline';
import Desktop from '@mui/icons-material/DesktopWindows';
import Download from '@mui/icons-material/Download';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import HelpIcon from '@mui/icons-material/Help';
import Home from '@mui/icons-material/Home';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Key from '@mui/icons-material/VpnKey';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import Language from '@mui/icons-material/Language';
import Lock from '@mui/icons-material/Lock';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreVert from '@mui/icons-material/MoreVert';
import Pause from '@mui/icons-material/Pause';
import Person from '@mui/icons-material/Person';
import PersonOutlined from '@mui/icons-material/PersonOutline';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Public from '@mui/icons-material/Public';
import Search from '@mui/icons-material/Search';
import Share from '@mui/icons-material/IosShare';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Work from '@mui/icons-material/Work';
import WorkOutlined from '@mui/icons-material/WorkOutline';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { ReactComponent as Edit } from 'images/icons/new/edit.svg';
import { ReactComponent as Check } from 'images/icons/new/check.svg';
import { ReactComponent as CloseBox } from 'images/icons/new/close-box.svg';
import { ReactComponent as SearchActive } from 'images/icons/new/search-active.svg';
import { ReactComponent as Shuffle } from 'images/icons/new/shuffle.svg';
import { ReactComponent as SpeechBubble } from 'images/icons/new/speech-bubble.svg';
import { ReactComponent as SpeechBubbleOutlined } from 'images/icons/new/speech-bubble-outlined.svg';

export const CustomIconMap = {
    check: Check,
    close_box: CloseBox,
    search_active: SearchActive,
    edit: Edit,
    shuffle: Shuffle,
    speech_bubble_outlined: SpeechBubbleOutlined,
    speech_bubble: SpeechBubble,
} as const;

export const MuiIconMap = {
    add: { icon: Add },
    arrow_left: { icon: KeyboardBackspace },
    arrow_right: {
        icon: KeyboardBackspace,
        sx: { transform: 'rotate(180deg)' },
    },
    attachment: { icon: AttachmentIcon },
    checkbox_outlined: { icon: CheckBoxOutlineBlankIcon },
    checkbox_checked: { icon: CheckBoxIcon },
    chevron_down: { icon: ChevronDown },
    chevron_left: { icon: ChevronLeft },
    chevron_right: { icon: ChevronRight },
    chevron_up: { icon: ChevronUp },
    close: { icon: Close },
    cloud: { icon: Cloud },
    delete: { icon: Delete },
    desktop: { icon: Desktop },
    download: { icon: Download },
    favorite_border: { icon: FavoriteBorder },
    favorite: { icon: Favorite },
    help: { icon: HelpIcon },
    home_outlined: { icon: HomeOutlined },
    home: { icon: Home },
    import_export: { icon: ImportExportIcon },
    key: { icon: Key },
    language: { icon: Language },
    lock: { icon: Lock },
    mail_outlined: { icon: MailOutlineIcon },
    more_vert: { icon: MoreVert },
    pause: { icon: Pause },
    person_outlined: { icon: PersonOutlined },
    person: { icon: Person },
    play: { icon: PlayArrow },
    public: { icon: Public },
    search: { icon: Search },
    share: { icon: Share },
    text: { icon: FormatAlignLeftIcon },
    visibility_off: { icon: VisibilityOff },
    visibility: { icon: Visibility },
    work_outlined: { icon: WorkOutlined },
    work: { icon: Work },
} as const;

export type CustomIconOption = keyof typeof CustomIconMap;
export type MuiIconOption = keyof typeof MuiIconMap;

export type IconOption = MuiIconOption | CustomIconOption;

export const CustomIconOptions = Object.keys(CustomIconMap) as IconOption[];
export const MuiIconOptions = Object.keys(MuiIconMap) as IconOption[];

export const IconOptions = [...MuiIconOptions, ...CustomIconOptions].sort(
    (a, b) => (a < b ? -1 : a > b ? 1 : 0)
);
