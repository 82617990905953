import restClient from './rest-client';
import { Deck, DeckResponse, mapDeckResponse } from '_types';

export default {

    getFreeDecks: (): Promise<Deck[]> => {
        return restClient
            .get<DeckResponse[]>(
                '/get-freemium-conversations',
                false,
                {  },
                {},
                {baseUrl: process.env.REACT_APP_NEXT_OPEN},
                true
            )
            .then(mapDeckResponse);
    },
};
