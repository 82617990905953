import React from 'react';
import classnames from 'classnames';
import { ReactComponent as IconPrev } from 'images/icons/prev.svg';
import { ReactComponent as IconNext } from 'images/icons/next.svg';
import styles from './navigation-button.module.scss';
import { preventDefault } from 'services/visual-style.service';

interface Props {
    activeDot: number;
    className?: string;
    direction: 'left' | 'right';
    dotCount: number;
    onClick: () => void;
}

const NavigationButton: React.FC<Props> = ({
    activeDot,
    className,
    direction,
    dotCount,
    onClick,
}) => (
    <button
        onClick={onClick}
        onMouseDown={preventDefault}
        className={classnames(
            styles.navigationButton,
            styles[direction],
            className
        )}
    >
        {direction === 'left' ? (
            <IconPrev title='previous' />
        ) : (
            <IconNext title='next' />
        )}
        <div className={styles.dots}>
            {dotCount &&
                Array.from(Array(dotCount).keys()).map((i) => (
                    <span
                        key={i}
                        className={classnames(styles.dot, {
                            [styles.active]: activeDot === i,
                        })}
                    />
                ))}
        </div>
    </button>
);

export default NavigationButton;
