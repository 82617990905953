import React from 'react';
import classnames from 'classnames';
import styles from './square-card.module.scss';
import Card, { Props } from './card';

const SquareCard: React.FC<Props> = ({
    onClick,
    className,
    children,
}: Props) => (
    <Card className={classnames(styles.card, className)} onClick={onClick}>
        {children}
    </Card>
);

export default SquareCard;
