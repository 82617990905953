import * as React from 'react';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { Steps } from 'components/steps';
import { GoogleLoginButton } from 'components/google-login-button';
import { AppleLoginButton } from 'components/apple-login-button';

import { routesNO, RouteList, routesEN } from 'api/routes';
import { useWindowSize } from 'hooks';
import { Button } from 'components/base';
import styles from './choose-registration-type.module.scss';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Link } from 'components/link';
import { IS_ANDROID_APP } from './../../../constants';

type Props = {
    moveToRegistrationByEmail: (enterProCode: boolean) => void;
    setLoading: (arg: boolean) => void;
};

interface ViewCopy {
    title: string;
    divider: string;
    googleSignUp: string;
    commonSignUp: string;
    alreadyHaveAccount: string;
    termsText1: string;
    termsText2: string;
    loginText: string;
    appleLoginTitle: string;
}

const nbCopy: ViewCopy = {
    title: 'Opprett bruker',
    divider: 'Eller',
    googleSignUp: 'Fortsett med Google',
    commonSignUp: 'Opprett bruker med epost',
    alreadyHaveAccount: 'Har du allerede bruker?',
    termsText1: 'Ved å opprette bruker her, aksepterer du samtidig våre',
    termsText2: 'Vilkår for bruk',
    loginText: 'Logg inn',
    appleLoginTitle: 'Fortsett med Apple',
};

const enCopy: ViewCopy = {
    title: 'Create User',
    divider: 'Or',
    googleSignUp: 'Continue with Google',
    commonSignUp: 'Create user by email',
    alreadyHaveAccount: 'Do you already use?',
    termsText1: 'By creating a user here, you are accepting our',
    termsText2: 'Terms for use',
    loginText: 'Log in',
    appleLoginTitle: 'Continue with Apple',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ChooseRegistrationType: React.FC<Props> = ({
    moveToRegistrationByEmail,
    setLoading,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        divider,
        googleSignUp,
        commonSignUp,
        alreadyHaveAccount,
        loginText,
        appleLoginTitle,
        termsText1,
        termsText2,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    return (
        <article className={styles.ChooseRegistrationType}>
            <section className={styles.Steps}>
                <Steps current={2} total={3} language={language} />
            </section>
            <PageTitle title={title} />
            {isMobile && (
                <p className={styles.HasAccount}>
                    {alreadyHaveAccount}&nbsp;
                    <Link className={styles.ButtonLink} to={routes.login}>
                        {loginText}
                    </Link>
                </p>
            )}
            <p className={styles.TermsText}>
                {termsText1}&nbsp;
                <Link className={styles.ButtonLink} to={routes.privacyTerms}>
                    {termsText2}
                </Link>
            </p>
            <div className={styles.ButtonsWrapper}>
                <GoogleLoginButton
                    text={googleSignUp}
                    routes={routes}
                    setLoading={setLoading}
                    createUser={true}
                    afterOnlyLogin={() => console.log('')}
                />
                <AppleLoginButton
                    text={appleLoginTitle}
                    routes={routes}
                    setLoading={setLoading}
                    createUser={true}
                />

                {!IS_ANDROID_APP && (
                    <div className={styles.Divider}>
                        <hr className={styles.LeftLine} />
                        <p className={styles.DividerText}> {divider} </p>
                        <hr className={styles.RightLine} />
                    </div>
                )}

                <Button onClick={() => moveToRegistrationByEmail(false)}>
                    <span>{commonSignUp}</span>
                </Button>
            </div>
        </article>
    );
};

export default ChooseRegistrationType;
