import React, { useLayoutEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import { returnRoutesForLanguage } from 'services/language-service';
import userClient from 'api/user-client';
import { Loading } from 'components/loading';
import { StorageKeys, getUserRegistrationData, removeLocalStorageData, setLocalStorageData } from 'models/storage';

const routeObj: { name: Language; routes: RouteList }[] = [
  {
      name: 'nb',
      routes: routesNO,
  },
  {
      name: 'en',
      routes: routesEN,
  },
];

const EmailVerificationHOC: React.FC<{ language: Language }> = ({ language }) => {
    const [progress, setProgress] = useState<'loading' | 'success' | 'failure'>('loading');
    const routes = returnRoutesForLanguage(language, routeObj);
    const location = useLocation();
    const queryString = new URLSearchParams(location.search);
    const act = queryString.get('act');

    useLayoutEffect(function verifyEmailRegistrationOnMount() {
      if (!act) return;
      userClient.verifyRegistrationLink(act)
        .then((reservedEmail: string) => {
          const userRegistrationData = getUserRegistrationData(StorageKeys.userRegistration);
          if (!userRegistrationData) return <Navigate to={routes.companySignupPage} replace />;

          if (reservedEmail === userRegistrationData.email) {
            setLocalStorageData(StorageKeys.userRegistration, 
              {...userRegistrationData, verifiedAt: new Date().toUTCString()});
            setProgress('success');
          } else {
            console.log('-> Emails did not match: ', reservedEmail, userRegistrationData.email);
            removeLocalStorageData(StorageKeys.userRegistration);
            setProgress('failure');
          }
        })
        .catch(() => setProgress('failure'));
    }, []);

    if (progress === 'loading') return <Loading />;
    if (progress === 'failure') return <Navigate to={routes.error} replace />;

    return (
      <Navigate to={`${routes.companySignupPage}`} replace />
    );
}

export { EmailVerificationHOC }