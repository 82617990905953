import { createContext, RefObject } from 'react';

import { ActiveContent } from 'utils/collaboration-utils';

export type SessionContextValues = {
    activeContent?: ActiveContent;
    displayInfoInDrawer: boolean;
    displayToolbarAsDrawer: boolean;
    slideDefs?: ActiveContent[];
    toolbarRef: RefObject<HTMLDivElement>;
    visible: boolean;
};

const SessionContext = createContext<SessionContextValues>({
    displayInfoInDrawer: false,
    displayToolbarAsDrawer: false,
    toolbarRef: {} as RefObject<HTMLDivElement>,
    visible: false,
});

export default SessionContext;
