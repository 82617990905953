import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { ErrorBoundary } from 'components/error-boundary';
import { FreemiumNavbar } from 'components/freemium-navbar';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { ErrorPage } from 'pages/error-page';
import { RegistrationStep } from '_types/registration-steps';
import { User } from '_types/user';
import styles from './complete-subscription-container.module.scss';
import { PaymentCreateConfirmation } from './payment-create-confirmation';
import { PaymentOptionSelect } from './payment-option-select';
import {
    getDiscountedPrice,
    requestSession,
    SessionResponse,
} from './_services';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, setUserSub } from 'store-modules/user';
import subscriptionClient from 'api/subscription-client';
import { ChoosePremProd } from 'pages/create-premium-page/choose-premium-product';
import { Product } from 'pages/create-premium-page/_types';
import { getProducts } from 'pages/create-premium-page/_services';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { getUAInfo, openAndroid, openiOS } from 'services/user-agent.service';
import { returnRoutesForLanguage } from 'services/language-service';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { Discount } from './_types';
import { CreateAccountConfirmation } from 'pages/create-premium-page/create-account-confirmation';
import userClient from 'api/user-client';
import { useMountedState } from 'common-logic/use-mounted-state';

interface OwnProps {
    initialSelectedProductId?: number;
    regStep?: RegistrationStep;
}

type Props = OwnProps;

const NO_SESSION: SessionResponse = {
    url: '',
    token: '',
};

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

export const PageContainer: React.FC<Props> = ({ regStep }) => {
    const navigate = useNavigate();

    const isMounted = useMountedState();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    // this route is unreachable for null user
    const user = useSelector(userSelector) as User;
    const location = useLocation();

    const { productId } = qs.parse(location.search);
    const selectedProductId = productId as string | undefined;

    const [session, setSession] = useState<SessionResponse>(NO_SESSION);
    const [nonDiscountedSession, setNonDiscountedSession] =
        useState<SessionResponse>(NO_SESSION);
    const [productsList, setProductsList] = useState<Product[]>([]);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [selectedProduct, setSelectedProduct] =
        useState<Product | undefined>();

    const [discount, setDiscount] = useState<Discount | undefined>(undefined);
    const [firstStep, setFirstStep] = useState<RegistrationStep>('Products');
    const [step, setStep] = useState<RegistrationStep>(firstStep);
    const [discountCode, setDiscountCode] = useState<string | undefined>();

    useEffect(() => {
        //Skip step 1 when navigating from landing page to use pro code
        if (!!regStep && regStep?.length > 0) {
            setStep(regStep);
        }
    }, [regStep]);

    const setSelectedProductId = useCallback(
        (productId: string | undefined): void => {
            const query = { productId };
            const nextLoc = qs.stringifyUrl({ url: location.pathname, query });

            navigate(nextLoc, { replace: true });
        },
        [location.pathname, navigate]
    );

    const updateDiscount = useCallback(
        (
            newSession: SessionResponse | undefined,
            newDiscount: Discount | undefined
        ): void => {
            // Updating the session reloads the payment window. It should only be reloaded when the discounted price changes, or when a discount is added or removed.
            if (
                !!newSession &&
                (session === NO_SESSION ||
                    newDiscount?.discountedPrice !== discount?.discountedPrice)
            ) {
                setSession(newSession);
                if (!newDiscount) {
                    setNonDiscountedSession(newSession);
                }
            }

            // Setting this will show or remove an error even if no discounted price was or is given.
            setDiscount(newDiscount);
        },
        [discount, session]
    );

    const fetchSession = useCallback(
        (discountCode: string | undefined): void => {
            setDiscountCode(discountCode);

            requestSession(user, selectedProduct!.id, discountCode)
                .then(([session, discount]): void => {
                    updateDiscount(session, discount);
                })
                .catch((err) => {
                    console.error(err);
                    navigate(routes.error);
                });
        },
        [navigate, user, selectedProduct, updateDiscount, routes.error]
    );

    const moveToRegistrationByEmail = (): void => {
        if (!!user) {
            navigate(routes.proCodeSubPage);
        }
        setStep('Create Account');
    };

    const onCodeSelected = useCallback(
        async (text: string): Promise<void> => {
            if (session.token) {
                fetchSession(text);
            } else {
                const discountedPriceResponse = await getDiscountedPrice(
                    text,
                    selectedProductId!
                );

                const validCoupon =
                    discountedPriceResponse.discountStatus === 'Coupon applied';

                const d: Discount = {
                    discountedPrice: validCoupon
                        ? discountedPriceResponse.discountPrice
                        : undefined,
                    discountError: !validCoupon,
                };

                validCoupon
                    ? setDiscountCode(text)
                    : setDiscountCode(undefined);

                setDiscount(d);
            }
        },
        [fetchSession, session.token, selectedProductId]
    );

    const onCodeCleared = useCallback((): void => {
        const nextSession = !!discount?.discountedPrice
            ? nonDiscountedSession
            : session;
        setDiscountCode(void 0);
        updateDiscount(nextSession, undefined);
    }, [updateDiscount, session, discount, nonDiscountedSession]);

    useEffect(() => {
        setStep(firstStep);
    }, [firstStep]);

    useEffect(() => {
        const fetchProducts = (): void => {
            getProducts()
                .then((p: Product[]) => {
                    if (!isMounted()) return;

                    const products = Object.values(p);
                    setProductsList(products);
                    setProductsLoaded(true);
                })
                .catch((err) => {
                    console.error(err);
                    navigate(routes.error);
                });
        };

        userClient
            .getUserIsConfirmed()
            .then((isConfirmed) => {
                if (isMounted() && !isConfirmed)
                    setFirstStep('Account Confirmation');
            })
            .then(() => fetchProducts());
    }, [navigate, routes.error, isMounted]);

    useEffect(() => {
        if (!productsLoaded) {
            return;
        }

        if (!selectedProductId) {
            setSession(NO_SESSION);
            setSelectedProduct(undefined);
            setStep(firstStep);
            return;
        }

        const product = productsList.find(
            ({ id }) => `${id}` === selectedProductId
        );

        if (!product) {
            setSelectedProductId(undefined);
            return;
        }
        setSelectedProduct(product);
        setStep('Payment Options');
    }, [
        selectedProductId,
        productsList,
        productsLoaded,
        setSelectedProductId,
        firstStep,
    ]);

    useEffect(() => {
        if (!selectedProduct) {
            return;
        }

        if (session === NO_SESSION) {
            // fetchSession(undefined);
        }
    }, [selectedProduct, setSelectedProductId, session, fetchSession]);

    const paymentCancel = useCallback(() => {
        setSelectedProductId(undefined);
        setDiscount(undefined);
    }, [setSelectedProductId]);

    const processCancel = useCallback(
        () => navigate(routes.index),
        [navigate, routes.index]
    );

    const paymentComplete = useCallback(() => {
        setStep('Payment Created');
    }, []);

    const onComplete = useCallback(() => {
        // get UA
        const { isMobile, isAndroid, isiOS } = getUAInfo(window.navigator);
        // If on desktop
        if (!isMobile) {
            // dispatch active sub, triggers auto re-direct to home
            return subscriptionClient.getActiveSubscription().then((sub) => {
                dispatch(setUserSub(sub!));
            });
        }

        if (isAndroid) {
            return openAndroid(window);
        }

        if (isiOS) {
            return openiOS(window);
        }

        return subscriptionClient.getActiveSubscription().then((sub) => {
            dispatch(setUserSub(sub!));
        });
    }, [dispatch]);

    const backgroundStyle = (step: RegistrationStep): string =>
        step === 'Payment Created' || step === 'Payment Options'
            ? styles.ColorBg
            : styles.PlainBg;

    const handleProductSelected = (product?: Product): void =>
        product && setSelectedProductId(`${product.id}`);

    const ui = (item: RegistrationStep): JSX.Element | null => {
        switch (item) {
            case 'Account Confirmation':
                return <CreateAccountConfirmation />;
            case 'Products':
                return (
                    <ChoosePremProd
                        products={productsList}
                        onProductSelected={handleProductSelected}
                        moveToRegistrationByEmail={moveToRegistrationByEmail}
                    />
                );
            case 'Payment Options':
                return (
                    <PaymentOptionSelect
                        selectedProduct={selectedProduct}
                        session={session}
                        discountCode={discountCode}
                        discount={discount}
                        onCancel={paymentCancel}
                        onSuccess={paymentComplete}
                        onCancelProcess={processCancel}
                        onCodeSelected={onCodeSelected}
                        onCodeCleared={onCodeCleared}
                        createOrderForBambora={() => fetchSession(discountCode)}
                    />
                );
            case 'Payment Created':
                return (
                    <PaymentCreateConfirmation continueHandler={onComplete} />
                );
            default:
                return <ErrorPage />;
        }
    };

    const SEO_TITLE = 'Velg din FuelPlan';

    const headerProps = user
        ? { withAppbar: false, withAccount: false, showLogoLink: true }
        : { customNavigation: FreemiumNavbar, showLogoLink: true };

    const pageClassName = classnames(
        styles.PageContainer,
        backgroundStyle(step)
    );

    return (
        <ErrorBoundary renderError={ErrorPage}>
            <Layout
                routes={routes}
                language={language}
                headerProps={headerProps}
                pageClassName={pageClassName}
            >
                <SEO title={SEO_TITLE} />
                <section
                    key={step} // Escape hatch to force re-render on step change.
                    data-testid='page-create-premium'
                    aria-labelledby='create premium account step'
                    aria-live='polite'
                    id='container-premium'
                    className={styles.container}
                >
                    {ui(step)}
                </section>
            </Layout>
        </ErrorBoundary>
    );
};

export default PageContainer;
