import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, Link } from 'components/base';
import { subscribe } from 'services/pubsub';
import {
    closeEndSessionDialog,
    EndSessionDialogOpenedEvent,
} from '../collaboration-events';
import { getSessionDocumentRef, SlideType } from 'utils/collaboration-utils';
import collaborationClient from 'api/collaboration-client';

import useCollaboration from '../use-collaboration';

const EndSessionDialog: FC = () => {
    const { closeCollaborationView, collaborationId } = useCollaboration();
    const { t } = useTranslation();

    const sessionRef = getSessionDocumentRef(collaborationId);

    const [endSessionDialogOpen, setEndSessionDialogOpen] = useState(false);

    useEffect(() => {
        const handler = subscribe(
            EndSessionDialogOpenedEvent,
            setEndSessionDialogOpen
        );

        return () => handler.unsubscribe();
    });

    const [loading, setLoading] = useState(false);

    const confirmEndCollaboration = () => {
        setLoading(true);

        collaborationClient
            .endCollaboration(collaborationId)
            .then(() => {
                sessionRef.update({ activeContent: { type: SlideType.END } });
                closeCollaborationView();
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            onClose={loading ? undefined : closeEndSessionDialog}
            open={endSessionDialogOpen}
            title={t('collaboration.endSessionDialog.title')}
        >
            <Dialog.Content>
                {t('collaboration.endSessionDialog.description')}
            </Dialog.Content>

            <Dialog.Actions>
                <Button
                    appearance='cta'
                    loading={loading}
                    onClick={confirmEndCollaboration}
                >
                    {t('collaboration.endSessionDialog.confirm')}
                </Button>

                <Link
                    component='button'
                    disabled={loading}
                    onClick={closeEndSessionDialog}
                >
                    {t('cancel')}
                </Link>
            </Dialog.Actions>
        </Dialog>
    );
};

export default EndSessionDialog;
