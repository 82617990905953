import React, { useState } from 'react';
import AccountSubpage from '../subpage/account-subpage';
import styles from './end-subscription.module.scss';
import { Button } from 'components/button';
import { ConfirmationPage } from 'pages/confirmation-page';
import { routesNO, RouteList, routesEN } from 'api/routes';
import subscriptionClient from 'api/subscription-client';
import { Navigate, useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/date-utils';
import { useSelector, useDispatch } from 'react-redux';
import {
    subscriptionSelector,
    setUserSub,
    userSelector,
} from 'store-modules/user';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import {
    clearProAccount,
    proAccountSelector,
} from 'store-modules/professional';
import { EndProSub } from 'professional/components/end-pro-sub';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';

interface ViewCopy {
    pageTitle: string;
    title: string;
    text1: string;
    text2: string;
    buttonLabel: string;
    confirmationTitle: string;
    confirmationDescription: string;
    confirmationButtonLabelPremium: string;
    confirmationButtonLabelPro: string;
    confirmationLinkToiPhoneSubsLabel: string;
    inAppPurchaseHeader: string;
    inAppPurchaseDesktopText1: string;
    inAppPurchaseDesktopText2: string;
    inAppPurchaseDesktopText3: string;
    inAppPurchaseDesktopText4: string;
    inAppPurchaseMobileText: string;
    inAppPurchaseMobileConfirmationText: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Avslutt abonnement',
    title: 'Avslutt abonnement',
    text1: 'Du vil fortsatt ha tilgang til FuelBox frem til',
    text2: 'Etter denne datoen vil du ikke bli belastet for en ny periode og abonnementet ditt avsluttes.',
    buttonLabel: 'Avslutt mitt abonnement',
    confirmationTitle: 'Ditt abonnement er avsluttet',
    confirmationDescription: 'Du vil fortsatt ha tilgang frem til',
    confirmationButtonLabelPremium: 'Utforsk samtaler',
    confirmationButtonLabelPro: 'Min konto',
    confirmationLinkToiPhoneSubsLabel: 'Gå til App Store',
    inAppPurchaseHeader: 'Obs!',
    inAppPurchaseDesktopText1:
        'Når du trykker på knappen vil vi avslutte abonnementet i FuelBox, men betalingen din via App Store vil fortsatt være aktiv.',
    inAppPurchaseDesktopText2:
        'For å stoppe betalingen må du gå inn i App Store på mobilen din og avslutte abonnementet der. Du finner abonnementet ved å følge disse stegene:',
    inAppPurchaseDesktopText3:
        'App Store \u0020 \u2192 \u0020 Apple ID profil \u0020 \u2192 \u0020 Abonnementer \u0020 \u2192 \u0020 FuelBox',
    inAppPurchaseDesktopText4:
        'Husk å avslutte betalingen i App Store på mobilen din!',
    inAppPurchaseMobileText:
        '*For å stoppe betalingen må du avslutte abonnementet ditt i App Store etter å ha trykket på "Avslutt mitt abonnement"',
    inAppPurchaseMobileConfirmationText:
        'Husk å avslutte betalingen i App Store!',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    title: 'Cancel subscription',
    text1: 'You will still have access to FuelBox until',
    text2: 'After this date you will not be charged for a new subscription period and your subscription will be cancelled.',
    buttonLabel: 'Cancel my subscription',
    confirmationTitle: 'Your subscription has been cancelled',
    confirmationDescription: 'You will still have access until',
    confirmationButtonLabelPremium: 'Explore conversations',
    confirmationButtonLabelPro: 'My account',
    confirmationLinkToiPhoneSubsLabel: 'Go to App Store',
    inAppPurchaseHeader: 'Obs!',
    inAppPurchaseDesktopText1:
        'When you click this button we will discontinue the subscription i FuelBox, but your payments in App Store will remain active.',
    inAppPurchaseDesktopText2:
        'In order to stop the payments, you must go to App Store on your phone and cancel your subscription there. You can find the subscription by following these steps:',
    inAppPurchaseDesktopText3:
        'App Store \u0020 \u2192 \u0020 Apple ID profile \u0020 \u2192 \u0020 Subscriptions \u0020 \u2192 \u0020 FuelBox',
    inAppPurchaseDesktopText4:
        'Remember to cancel your subscription in App Store on your phone!',
    inAppPurchaseMobileText:
        '*In order to stop the payments, you need to cancel your subscription in the App Store after clicking "Cancel subscription"',
    inAppPurchaseMobileConfirmationText:
        'Remember to cancel your subscription in the App Store!',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const EndSubscription: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        title,
        text1,
        text2,
        buttonLabel,
        confirmationTitle,
        confirmationDescription,
        confirmationButtonLabelPremium,
        confirmationButtonLabelPro,
        confirmationLinkToiPhoneSubsLabel,
        inAppPurchaseDesktopText1,
        inAppPurchaseDesktopText2,
        inAppPurchaseDesktopText3,
        inAppPurchaseDesktopText4,
        inAppPurchaseHeader,
        inAppPurchaseMobileText,
        inAppPurchaseMobileConfirmationText,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const [showPremiumConfirmation, setShowPremiumConfirmation] =
        useState(false);
    const [showProConfirmation, setShowProConfirmation] = useState(false);
    const dispatch = useDispatch();
    const subscription = useSelector(subscriptionSelector);
    const user = useSelector(userSelector);
    const organization = useSelector(proAccountSelector);
    const activeProSub =
        !!organization && organization.name === user?.organization;
    const sub = subscription!.data;
    const isCancelled = sub.status === 'pending-cancel';
    const { isiOS } = getUAInfo(window.navigator);

    const isAppleIAPSubscription = subscription?.data.iap === 'apple';

    if (showPremiumConfirmation) {
        const expiryDate = formatDate(sub.expiry_date);

        return (
            <ConfirmationPage
                routes={routes}
                language={language}
                onClickButton={(): void =>
                    navigate(routes.index, { replace: true })
                }
                title={confirmationTitle}
                description={confirmationDescription}
                buttonLabel={confirmationButtonLabelPremium}
                endDate={expiryDate}
                proSub={false}
                inAppPurchaseText1={inAppPurchaseDesktopText4}
                inAppPurchaseText2={inAppPurchaseDesktopText3}
                IAPSubscription={isAppleIAPSubscription}
                iapLinkButtonLabel={confirmationLinkToiPhoneSubsLabel}
                inAppPurchaseMobileConfirmationText={
                    inAppPurchaseMobileConfirmationText
                }
            />
        );
    }

    if (showProConfirmation) {
        return (
            <ConfirmationPage
                routes={routes}
                language={language}
                onClickButton={() =>
                    navigate(routes.account.index, { replace: true })
                }
                title={confirmationTitle}
                description={confirmationDescription}
                buttonLabel={confirmationButtonLabelPro}
                proSub={true}
            />
        );
    }

    if (isCancelled) {
        return <Navigate to={routes.account.subscription} replace />;
    }

    const endSubscription = () => {
        subscriptionClient
            .endSubscription()
            .then(subscriptionClient.getActiveSubscription)
            .then((sub) => {
                activeProSub
                    ? setShowProConfirmation(true)
                    : setShowPremiumConfirmation(true);
                activeProSub
                    ? dispatch(clearProAccount())
                    : dispatch(setUserSub(sub!));
            })
            .catch(() => navigate(routes.error));
    };

    const nextPayment = formatDate(sub.subscription.next_payment_date);

    const premiumContent = !user?.organization && (
        <div>
            <p className={styles.text}>
                {text1} <b>{nextPayment}</b>
            </p>
            <p className={styles.text}>{text2}</p>
        </div>
    );
    const inAppPurchaseInfoDesktop = (
        <div>
            <p className={styles.IAPHeader}>{inAppPurchaseHeader}</p>
            <div>
                {' '}
                <p className={styles.IAPText1}>{inAppPurchaseDesktopText1}</p>
                <p className={classNames(styles.text, styles.IAPText2)}>
                    {inAppPurchaseDesktopText2}
                </p>
                <p className={classNames(styles.text, styles.IAPText3)}>
                    {inAppPurchaseDesktopText3}
                </p>
            </div>
        </div>
    );

    const inAppPurchaseInfoMobile = (
        <p className={styles.IAPMobileText}>{inAppPurchaseMobileText}</p>
    );

    const proContent = activeProSub && <EndProSub />;

    return (
        <AccountSubpage
            pageTitle={pageTitle}
            title={title}
            withHeaderSpace={!isAppleIAPSubscription}
        >
            {proContent}
            {premiumContent}
            {isAppleIAPSubscription && !isiOS && inAppPurchaseInfoDesktop}
            <Button
                appearance='cta'
                className={classNames(
                    styles.cta,
                    isAppleIAPSubscription && styles.iapStyling
                )}
                onClick={endSubscription}
            >
                {buttonLabel}
            </Button>
            {isAppleIAPSubscription && isiOS && inAppPurchaseInfoMobile}
        </AccountSubpage>
    );
};

export default EndSubscription;
