import React, { FC } from 'react';

import { CardButton, CardButtonProps, Grid, styled } from 'components/base';

const StyledCardButton = styled(CardButton)`
    &.minimalDisplay {
        width: 150px;
    }
`;

type PersonalContentCategoryButtonProps = CardButtonProps & {
    minimalDisplay?: boolean;
};

const PersonalContentCategoryButton: FC<PersonalContentCategoryButtonProps> = ({
    minimalDisplay,
    ...rest
}) => (
    <Grid
        alignItems='stretch'
        display='flex'
        item
        {...(minimalDisplay ? { xs: 'auto' } : { xs: 12, md: 6, lg: 4 })}
    >
        <StyledCardButton {...rest} dense fullWidth stacked={minimalDisplay} />
    </Grid>
);

export default PersonalContentCategoryButton;
