import React, { HTMLAttributes, FC, KeyboardEvent } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';

import useMenu from './use-menu';
import { LightThemeProvider } from 'style/theme';

export type MenuContentProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
    Pick<PopperProps, 'disablePortal' | 'placement'>;

const StyledPopper = styled(Popper)`
    max-width: ${({ theme }) => `calc(100% - ${theme.spacing(2)})`};
    padding: ${({ theme }) => theme.spacing()};
`;

const StyledPaper = styled(Paper)`
    background: ${({ theme }) => theme.palette.custom.brown[95]};
`;

const StyledMenuList = styled(MenuList)`
    border-radius: inherit;
    overflow: hidden;
`;

export const MenuContent: FC<MenuContentProps> = ({
    children,
    disablePortal,
    placement = 'bottom-end',
    ...rest
}) => {
    const { anchorEl, onClose } = useMenu();
    const theme = useTheme();

    function handleListKeyDown(e: KeyboardEvent) {
        if (e.key === 'Tab' || e.key === 'Escape') {
            e.preventDefault();
            onClose();
        }
    }

    const content = (
        <StyledPopper
            anchorEl={anchorEl}
            disablePortal={disablePortal}
            open={!!anchorEl}
            placement={placement}
        >
            <Grow in={!!anchorEl}>
                <StyledPaper {...rest}>
                    <ClickAwayListener onClickAway={onClose}>
                        <StyledMenuList
                            disablePadding
                            autoFocusItem={!!anchorEl}
                            onKeyDown={handleListKeyDown}
                        >
                            {children}
                        </StyledMenuList>
                    </ClickAwayListener>
                </StyledPaper>
            </Grow>
        </StyledPopper>
    );

    return theme.palette.mode === 'light' ? (
        content
    ) : (
        <LightThemeProvider>{content}</LightThemeProvider>
    );
};
