import React from 'react';
import styles from './language-option.module.scss';
import { Language } from '_types/language';

type Props = {
    language: Language;
    languageLabel: string;
    selectedLanguage: Language;
    selectLanguage: (language: Language) => void;
};

const LanguageOption: React.FC<Props> = ({
    language,
    languageLabel,
    selectedLanguage,
    selectLanguage,
}: Props): React.ReactElement => {
    return (
        <div className={styles.languageOption}>
            <input
                type='radio'
                value={language}
                id={language}
                name='language'
                checked={selectedLanguage === language}
                onChange={() => selectLanguage(language)}
            />
            <label htmlFor={language}>{languageLabel}</label>
        </div>
    );
};

export default LanguageOption;
