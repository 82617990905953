import { createContext } from 'react';
import { UserCredential } from 'firebase/auth';
import { Participant } from 'utils/collaboration-utils';

export type SessionContextValues = {
    collaborationId: string;
    displayName?: string;
    hasJoined?: boolean;
    participants?: Participant[];
    setDisplayName: (displayName: string) => void;
    userCredential?: UserCredential;
};

const SessionContext = createContext<SessionContextValues>({
    collaborationId: '',
    setDisplayName: () => undefined,
});

export default SessionContext;
