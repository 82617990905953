import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Collaboration } from 'api/collaboration-client';
import { Menu } from 'components/base';

import useCollaborationFunctions from '../../../../hooks/use-collaboration-functions';
// import DownloadPdfMenuItem from './download-pdf-menu-item';
import CopyCollaborationMenuItem from './copy-collaboration-menu-item';

type CollaborationButtonMenuContentProps = {
    collaboration: Collaboration;
    onDelete: () => void;
    questionSlug?: string;
};

const CollaborationButtonMenuContent: FC<CollaborationButtonMenuContentProps> =
    ({ collaboration, questionSlug, onDelete }) => {
        const { t } = useTranslation();
        const { generatePath } = useCollaborationFunctions(collaboration);

        return (
            <Fragment>
                <Menu.Content>
                    <Menu.Item
                        icon='speech_bubble_outlined'
                        to={generatePath(questionSlug)}
                    >
                        {t('collaboration.menu.option.viewCollaboration')}
                    </Menu.Item>

                    {/* <DownloadPdfMenuItem collaboration={collaboration} /> */}

                    <Menu.Item icon='delete' onClick={onDelete} divider>
                        {t('collaboration.menu.option.delete')}
                    </Menu.Item>

                    <CopyCollaborationMenuItem collaboration={collaboration} />
                </Menu.Content>
            </Fragment>
        );
    };

export default CollaborationButtonMenuContent;
