import React, { useEffect } from 'react';
import { CopyContent } from 'components/copy-content';
import { Faq } from 'components/faq';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'store-modules/auth';
import { useLocation } from 'react-router-dom';

interface Props {
    language: Language;
}

interface ViewCopy {
    seoTitle: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Ofte stilte spørsmål',
};

const enCopy: ViewCopy = {
    seoTitle: '',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const FaqPage: React.FC<Props> = (props) => {
    const { language } = props;
    const { seoTitle } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const authenticated = useSelector(authenticatedSelector);
    const location = useLocation();
    const hash = location.hash.substring(1);

    useEffect(() => {
        if (hash && hash.length) {
            const anchorElement = document.getElementById(hash);
            if (anchorElement) anchorElement.scrollIntoView();
        }
    }, [hash]);

    const headerProps = {
        withLogin: true,
        withAccount: true,
        showLogoLink: true,
    };

    const routerProps = props;

    return (
        <Layout
            routes={routes}
            language={language}
            headerProps={headerProps}
            withFooter={!authenticated}
        >
            <SEO title={seoTitle} />
            <CopyContent {...routerProps} showBottomButton={false}>
                <Faq language={language} />
            </CopyContent>
        </Layout>
    );
};

export default FaqPage;
