import React, { FC, Fragment, forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MuiTextField, {
    TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { IconOption } from '../../../data-display/icon';

export type TextFieldBaseProps = Omit<
    MuiTextFieldProps,
    | 'classes'
    | 'color'
    | 'endAdornment'
    | 'focused'
    | 'hiddenLabel'
    | 'inputRef'
    | 'margin'
    | 'maxRows'
    | 'minRows'
    | 'select'
    | 'SelectProps'
    | 'size'
    | 'startAdornment'
    | 'variant'
> & {
    /**
     * If `true`, the TextField will be rendered in a rounded state
     */
    rounded?: boolean;
    /**
     * If `true`, there will be no focus indication
     */
    hideFocusIndication?: boolean;
    /**
     * Intended for a `Button` or an `IconButton` that will be rendered within the component, floating to the right
     */
    endAdornment?: ReactNode;
    /**
     * The name of the icon that will be rendered
     */
    startIcon?: IconOption;
};

export const TextFieldBase: FC<TextFieldBaseProps> = forwardRef(
    (
        {
            className,
            helperText,
            hideFocusIndication,
            rounded,
            InputProps,
            ...rest
        },
        ref
    ) => {
        const { t } = useTranslation();

        return (
            <MuiTextField
                className={classNames(className, {
                    rounded,
                    hideFocusIndication,
                })}
                inputRef={ref}
                helperText={
                    helperText != undefined || rest.inputProps?.maxlength ? (
                        <Fragment>
                            {helperText != undefined && (
                                <span>{helperText}</span>
                            )}

                            {rest.inputProps?.maxlength && (
                                <Box
                                    sx={(theme) => ({
                                        color:
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.text.secondary
                                                : theme.palette.custom
                                                      .black[40],
                                    })}
                                    component='span'
                                    ml='auto'
                                >
                                    {t('fraction', {
                                        numerator:
                                            (rest.value as string)?.length ?? 0,
                                        denominator: rest.inputProps.maxlength,
                                    })}
                                </Box>
                            )}
                        </Fragment>
                    ) : undefined
                }
                InputProps={{ disableUnderline: true, ...InputProps }}
                variant='standard'
                {...rest}
            />
        );
    }
);
