import { RootReducer } from 'store/store';
import { FavouriteConversationsState } from './_types';
import { ConversationWithCategory, UserConversationTaxonomy } from '_types';

const favouriteConversationsStateFromRoot = (
    state: RootReducer
): FavouriteConversationsState => state.favouriteConversations;

export const favouriteConversationsSelector = (
    state: RootReducer
): ConversationWithCategory[] | null => {
    const favouriteConversations =
        favouriteConversationsStateFromRoot(state).favouriteConversations;
    return !!favouriteConversations
        ? favouriteConversations.map((q) => {
              return {
                  ...q,
                  taxonomy: q.created_by_id
                      ? state.settings.language === 'en'
                          ? UserConversationTaxonomy.en
                          : UserConversationTaxonomy.nb
                      : q.taxonomy,
              };
          })
        : null;
};
