import * as React from 'react';
import { Callout } from './callout';
import styles from './product-item.module.scss';

type Props = {
    selectHandler: (id: number | string) => void;
    id: number | string;
    name: string;
    price: number;
    short_description?: string;
    currency: string;
    callout?: string;
    highlighted?: boolean;
    navigateToCompanySignup: () => void;
    categories: string[];
};

const ProductItem: React.FC<Props> = ({
    selectHandler,
    id,
    name,
    price,
    short_description = '',
    currency = 'NOK',
    callout = '',
    highlighted = false,
    navigateToCompanySignup,
    categories,
}: Props) => {
    const hasPriceTerms = !!short_description.length;
    const showCallout = !!callout.length;

    const onProductSelect = () => {
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            category.toLocaleLowerCase().includes('proff') &&
                navigateToCompanySignup();
        }
        selectHandler(id);
    };

    const preventDefault = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const containerStyle = highlighted
        ? [styles.Product, styles.Selected, styles.Highlight]
        : [styles.Product];

    return (
        <button
            data-testid='component-product'
            className={containerStyle.join(' ')}
            onClick={onProductSelect}
            onMouseDown={preventDefault}
        >
            <p className={styles.Title} data-testid='product-title'>
                {name}
            </p>
            <p className={styles.Price}>
                {price}&nbsp;{currency === 'NOK' ? 'kr' : ''}
            </p>
            {hasPriceTerms && (
                <p className={styles.Details}>{short_description}</p>
            )}
            <section
                className={styles.Product__Callout}
                data-testid='product-callout'
            >
                {showCallout && <Callout info={callout} />}
            </section>
        </button>
    );
};

export default ProductItem;
