import * as React from 'react';
import styles from './callout.module.scss';

type Props = {
  info: string;
};

const Callout: React.FC<Props> = ({ info }: Props) => {
    return (
        <article data-testid='component-callout' className={styles.Callout}>
            {info}
        </article>
    );
};

export default React.memo(Callout);
