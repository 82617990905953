import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '_types/user';
import { Subscription } from '_types/subscription';
import { Loading } from 'components/loading';
import userClient from 'api/user-client';
import {
    setUserState,
    setUserSub,
    subscriptionSelector,
    userSelector,
} from 'store-modules/user';
import { authenticatedSelector } from 'store-modules/auth';
import subscriptionClient from 'api/subscription-client';
import authService from 'services/auth-service';
import { updateLanguage } from 'store-modules/app-settings';
import { setLocalStorageLanguage } from 'services/language-service';
import proClient from 'api/pro-client';
import { setProAccount } from 'store-modules/professional';
import { Organization } from '_types';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export type AuthContextValue = {
    user: User | null;
    subscription: Subscription | null;
};

interface Props {
    children: React.ReactElement;
}

type Status = 'idle' | 'loading' | 'resolved';

export const AuthContainer: React.FC<Props> = ({ children }: Props) => {
    const authenticated = useSelector(authenticatedSelector);
    const dispatch = useDispatch();
    const [status, setStatus] = useState<Status>('idle');
    const user = useSelector(userSelector);
    const subscription = useSelector(subscriptionSelector);

    //native analytics
    const logOrganization = (organization: Organization) => {
        FirebaseAnalytics.setUserProperty({
            name: 'Organization',
            value: organization.name.trim(),
        });
    };

    const setupAccount = async (user: User) => {
        setStatus('resolved');
        if (!!user && !!user.language) {
            setLocalStorageLanguage(user.language);
            dispatch(updateLanguage(user.language));
        }
        if (!!user?.organization) {
            const organization = await proClient.getOrganization(
                user.organization
            );
            logOrganization(organization);
            dispatch(setProAccount(organization));
        }
    };

    useEffect(() => {
        if (!authenticated) {
            setStatus('resolved');
            return;
        }

        // if user is authenticated, we need to load activeUser and activeSubscription
        setStatus('loading');

        if (!user || !subscription) {
            Promise.all([
                userClient.getCurrentUser(),
                subscriptionClient.getActiveSubscription(),
            ])
                .then(async ([user, sub]) => {
                    setupAccount(user);
                    dispatch(setUserState(user!));
                    dispatch(setUserSub(sub!));
                })
                .catch(() => {
                    authService.logout();
                });
        } else {
            setupAccount(user);
        }
    }, [authenticated, dispatch]);

    if (status === 'idle' || status === 'loading') {
        return <Loading />;
    }

    return children;
};
