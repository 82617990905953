import React from 'react';
import classnames from 'classnames';

import styles from './conversation-group.module.scss';

interface Props {
    isSubgroup: boolean;
    title: string;
    children: React.ReactNode;
}

const ConversationGroup: React.FC<Props> = ({
    children,
    isSubgroup,
    title,
}: Props) => (
    <div className={classnames(isSubgroup ? styles.Subgroup : styles.Group)}>
        <div className={styles.Title}>{title}</div>
        {children}
    </div>
);

export default ConversationGroup;
