const KEY = {
    tab: 'Tab',
    enter: 'Enter',
    space: ' ',
};

export const primaryKeyPressed = (e: React.KeyboardEvent): boolean =>
    e.key === KEY.enter || e.key === KEY.space;

// FIXME: typing
export const ifPrimaryPressed =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (func: any): any =>
        (e: React.KeyboardEvent): void => {
            if (primaryKeyPressed(e)) {
                func();
            }
        };

// function ifPrim(func: any) {
//     return function something(e: React.KeyboardEvent):
// }
