import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AccountConfirmationPage } from 'pages/account-confirmation-page';
import { CompleteSubscription } from 'pages/complete-subscription-page';
import { CreatePremiumAccount } from 'pages/create-premium-page';
import { DeckPage } from 'pages/deck-page';
import { determineRedirectUrl } from 'services/redirects.service';
import { LandingPage } from 'pages/landing-page';
import { LoginPage } from 'pages/login-page';
import { PasswordResetPage } from 'pages/password-reset-page';
import { routesEN, routesNO } from 'api/routes';
import { SetNewPasswordPage } from 'pages/set-new-password-page';
import AccountConfirmationProPage from 'professional/pages/pro-account-confirmation-page/pro-account-confirmation-page';
import PublicQuestionInfoPage from 'pages/public-question-info-page/public-question-info-page';
import useCommonRoutes from './use-common-routes';
import { DeleteAccountConfirmationPage } from 'pages/delete-account-confirmation-page';
import useHistoryRouter from 'hooks/use-history-router';
import { ProProductInfoPage } from 'pages/pro-product-info-page';
import { CompanySignupPage } from 'pages/company-signup-page';
import CompanySignupConfirmationPage from 'pages/company-signup-confirmation-page/company-signup-confirmation-page';
import { EmailVerificationHOC } from 'hocs/EmailVerificationHoc';

const PublicRoutes: FC = () => {
    const { language, routes } = useHistoryRouter();
    const dispatch = useDispatch();

    const authorizedRoutes = [
        routes.index,
        routes.error,
        routes.createPremium,
        routes.paymentOptionSelect,
        routes.userConfirmPro,
        routes.userConfirm,
        routes.login,
        routes.passReset,
        routes.setNewPass,
        routes.conversation,
        routes.privacyTerms,
        routes.tnc,
        routes.faq,
        routes.nativeGreeting,
        routes.questionInfoPage,
    ];

    const commonRoutes = useCommonRoutes();

    const conversationRoutes = (
        <>
            <Route
                path={`/${language}/:taxonomy/:deckSlug/`}
                element={<DeckPage />}
            />
            <Route
                path={`/${language}/:taxonomy/:deckSlug/:questionSlug`}
                element={<DeckPage />}
            />
        </>
    );

    const subscriptionRoutes = (
        <>
            <Route
                path={routes.createPremium}
                element={<CreatePremiumAccount />}
            />
            <Route
                path={routes.createPremiumPro + '/:procode'}
                element={<CreatePremiumAccount />}
            />
            <Route
                path={routes.createPremiumPro}
                element={<CreatePremiumAccount />}
            />
            <Route
                path={routesEN.paymentOptionSelect}
                element={<CompleteSubscription />}
            />
            <Route
                path={routes.userConfirmPro}
                element={<AccountConfirmationProPage />}
            />
            <Route
                path={routes.userConfirm}
                element={<AccountConfirmationPage />}
            />
            <Route
                path={routes.userConfirmDeleted}
                element={<DeleteAccountConfirmationPage />}
            />
        </>
    );

    const publicRoutes = (
        <>
            {commonRoutes}
            {conversationRoutes}
            {subscriptionRoutes}

            <Route path={routes.index} element={<LandingPage />} />
            <Route
                path={routes.proProductInfoPage}
                element={<ProProductInfoPage />}
            />
            <Route
                path={routes.companySignupPage}
                element={<CompanySignupPage />}
            />
            <Route
                path={routes.confirmReservedEmail}
                element={<EmailVerificationHOC language={language} />}
            />
            <Route
                path={routes.companySignupConfirmationPage}
                element={<CompanySignupConfirmationPage />}
            />
            <Route path={routes.login} element={<LoginPage />} />
            <Route path={routes.passReset} element={<PasswordResetPage />} />
            <Route path={routes.setNewPass} element={<SetNewPasswordPage />} />

            <Route
                path={routes.questionInfoPage}
                element={<PublicQuestionInfoPage language={language} />}
            />

            <Route
                path='*'
                element={
                    <Navigate
                        to={determineRedirectUrl(
                            routesNO,
                            routesEN,
                            dispatch,
                            authorizedRoutes
                        )}
                        replace
                    />
                }
            />
        </>
    );

    return <Routes>{publicRoutes}</Routes>;
};

export default PublicRoutes;
