import { FirebaseInitOptions } from '@capacitor-community/firebase-analytics';

const firebaseConfig: FirebaseInitOptions = {
    apiKey: 'AIzaSyDBMNSWA4tlDn7XV64axT-FczPg13HCMCg',
    appId: '1:246438887397:ios:9a8c1f6c0822f0a1bab90c',
    authDomain: 'fuelbox-app.firebaseapp.com',
    databaseURL: 'https://fuelbox-app.firebaseio.com',
    measurementId: '',
    messagingSenderId: '246438887397',
    projectId: 'fuelbox-app',
    storageBucket: 'fuelbox-app.appspot.com',
};

export default firebaseConfig;
