import React, { FC, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { Divider, FullscreenLoader, Typography } from 'components/base';
import { getSessionDocumentRef, SessionData } from 'utils/collaboration-utils';
import collaborationClient from 'api/collaboration-client';

import useCollaborationFunctions from 'hooks/use-collaboration-functions';

import useCollaboration from '../../../use-collaboration';
import { SessionProgressButton } from './styled';

const UnfinishedSessionInfo: FC = () => {
    const { collaborationId, collaboration } = useCollaboration();
    const { t } = useTranslation();
    const { generatePathForExistingSession } =
        useCollaborationFunctions(collaboration);
    const navigate = useNavigate();

    const sessionRef = getSessionDocumentRef(collaborationId);
    const [sessionData] = useDocumentData<SessionData>(sessionRef as never);

    const { answeredQuestions = [], conversation: { questions = [] } = {} } =
        sessionData || {};

    const answeredQuestionCount = answeredQuestions?.length ?? 0;

    const [loading, setLoading] = useState(false);

    const restartCollaboration = () => {
        setLoading(true);

        collaborationClient
            .restartCollaboration(collaborationId)
            .then(() =>
                navigate(generatePathForExistingSession(), { replace: true })
            )
            .finally(() => setLoading(false));
    };

    return loading ? (
        <FullscreenLoader />
    ) : (
        <Fragment>
            <Typography>
                {t('collaboration.questionsAnsweredCount', {
                    answered: answeredQuestionCount,
                    total: questions?.length ?? 0,
                })}
            </Typography>

            <Divider orientation='vertical' variant='middle' flexItem />

            <SessionProgressButton onClick={restartCollaboration}>
                {t('collaboration.sessionInfo.resume')}
            </SessionProgressButton>
        </Fragment>
    );
};

export default UnfinishedSessionInfo;
