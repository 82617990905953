import React from 'react';
import { Form, Field } from 'react-final-form';
import { InputRow } from 'components/inputs';
import styles from './change-personal-details.module.scss';
import AccountSubpage from '../subpage/account-subpage';
import { useWindowSize } from 'hooks';
import { useSelector } from 'react-redux';
import { userSelector } from 'store-modules/user';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { Link } from 'components/base';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
}
interface ViewCopy {
    pageTitle: string;
    titleMobile: string;
    titleDesktop: string;
    labelEmail: string;
    labelFirstName: string;
    labelLastName: string;
    linkDeleteAccount: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Endre personopplysninger',
    titleMobile: 'Personopplysninger',
    titleDesktop: 'Endre personopplysninger',
    labelEmail: 'Epostadresse',
    labelFirstName: 'Fornavn',
    labelLastName: 'Etternavn',
    linkDeleteAccount: 'Slett min bruker',
};
const enCopy: ViewCopy = {
    pageTitle: '',
    titleMobile: 'Personal information',
    titleDesktop: 'Personal information',
    labelEmail: 'Email address',
    labelFirstName: 'First name',
    labelLastName: 'Last name',
    linkDeleteAccount: 'Delete my account',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ChangePersonalDetails: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        titleMobile,
        titleDesktop,
        labelEmail,
        labelFirstName,
        labelLastName,
        linkDeleteAccount,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const user = useSelector(userSelector);
    const { view } = useWindowSize(window);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formSubmit = (values: FormValues) => {
        console.log(values); // TODO: Do something else; empty functions are not allowed
    };
    const pendingDeletion = user?.delete_date;

    const deleteDate = new Date(user?.delete_date ? user.delete_date : '');
    const formattedDate = deleteDate.toLocaleDateString(
        language === 'en' ? 'en-EN' : 'nb-NO',
        {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }
    );

    return (
        <AccountSubpage
            pageTitle={pageTitle}
            title={view === 'mobile' ? titleMobile : titleDesktop}
        >
            <Form
                initialValues={{
                    firstName: user!.firstName,
                    lastName: user!.lastName,
                    email: user!.email,
                }}
                onSubmit={formSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <Field
                            name='email'
                            type='text'
                            label={labelEmail}
                            component={InputRow}
                            disabled={true}
                        />
                        <Field
                            name='firstName'
                            type='text'
                            label={labelFirstName}
                            component={InputRow}
                            disabled={true}
                        />
                        <Field
                            name='lastName'
                            type='text'
                            label={labelLastName}
                            component={InputRow}
                            disabled={true}
                        />

                        {/* <Button
                                type='submit'
                                appearance='cta'
                                fullWidth={true}
                            >
                                {buttonSubmit}
                            </Button> */}

                        <div className={styles.DeleteMeLinkContainer}>
                            {pendingDeletion ? (
                                <div>
                                    <p className={styles.WarningText}>
                                        {t('userCancellationWarning1')}
                                    </p>
                                    <div
                                        className={classnames(
                                            styles.WarningText,
                                            styles.DeleteWarningText2
                                        )}
                                    >
                                        {user?.delete_date && (
                                            <Trans
                                                i18nKey='userCancellationWarning2'
                                                values={{
                                                    date: formattedDate,
                                                }}
                                            ></Trans>
                                        )}
                                    </div>

                                    <Link
                                        onClick={() => {
                                            navigate(
                                                `../${routes.account.recoverAccount}`
                                            );
                                        }}
                                        className={styles.UndoDeleteLink}
                                    >
                                        {t('userCancellationRegret')}
                                    </Link>
                                </div>
                            ) : (
                                <Link
                                    onClick={() => {
                                        navigate(
                                            `../${routes.account.deleteAccount}`
                                        );
                                    }}
                                    className={styles.DeleteMeLink}
                                >
                                    {linkDeleteAccount}
                                </Link>
                            )}
                        </div>
                    </form>
                )}
            />
        </AccountSubpage>
    );
};

export default ChangePersonalDetails;
