import { PRO_PREFIX } from 'constants/general';
import { useNavigate } from 'react-router-dom';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';
import { Conversation, ConversationWithCategory, Question } from '_types';
import { Language } from '_types/language';

const useConversationSelector = () => {
    const navigate = useNavigate();

    const selectConversation = (
        deck: Conversation,
        language: Language,
        parentPage: string,
        searchString?: string,
        viewAllSearchConvos?: boolean,
        conversationSearchResult?: ConversationWithCategory[],
        questionSearchResult?: Question[],
        questions?: Question[]
    ) => {
        gtmEvent(GTM_EVENT.SELECT_DECK, { 'deck.name': deck.name });
        navigate(`/${language}/${deck.taxonomy}/${deck.slug}`, {
            state: {
                deck,
                parentPage,
                searchString,
                viewAllSearchConvos,
                conversationSearchResult,
                questionSearchResult,
                questions,
            },
        });
    };

    const selectMyFavouritesConversation = (
        deck: Conversation,
        favouriteQuestions: Question[],
        favouriteQuestion: Question,
        favouriteQuestionIndex: number,
        language: Language,
        parentPage: string,
        favouritesTaxonomy: string,
        favouritesDeckSlug: string,
        isFavourites: boolean
    ) => {
        gtmEvent(GTM_EVENT.SELECT_DECK, { 'deck.name': deck.name });
        navigate(
            `/${language}/${favouritesTaxonomy}/${favouritesDeckSlug}/${favouriteQuestion.slug}`,
            {
                state: {
                    deck,
                    parentPage,
                    favouriteQuestions,
                    favouriteQuestion,
                    favouriteQuestionIndex,
                    favouritesTaxonomy,
                    favouritesDeckSlug,
                    isFavourites,
                },
            }
        );
    };

    const selectProConversation = (
        deck: Conversation,
        language: Language,
        parentPage: string
    ) => {
        gtmEvent(GTM_EVENT.SELECT_DECK, { 'deck.name': deck.name });
        navigate(`/${language}/${PRO_PREFIX}/${deck.taxonomy}/${deck.slug}`, {
            state: { deck, parentPage },
        });
    };

    const selectRandomQuestions = (
        deck: Conversation,
        randomQuestions: Question[],
        language: Language,
        randomSlug: string
    ) => {
        navigate(
            `/${language}/random/${randomSlug}/${randomQuestions[0].slug}`,
            {
                state: { deck, randomQuestions },
            }
        );
    };

    return {
        selectConversation,
        selectMyFavouritesConversation,
        selectProConversation,
        selectRandomQuestions,
    };
};

export default useConversationSelector;
