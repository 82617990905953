import React, { useEffect, useState } from 'react';
import styles from './edit-conversation-questions.module.scss';
import { Language } from '_types/language';
import { Loading } from 'components/loading';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { SEO } from 'components/seo';
import {
    Conversation,
    ConversationWithCategory,
    deckParentPages,
    Question,
} from '_types';
import { ReactComponent as IconCheckWhite } from 'images/icons/check.svg';
import { useDispatch, useSelector } from 'react-redux';
import SelectQuestionBox from 'components/select-question-box/select-question-box';
import conversationClient, { MyConversation } from 'api/conversation-client';
import { setMyConversations } from 'store-modules/my-conversations';
import { ToastMessage } from 'components/toast-message';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import { ReactComponent as IconAdd } from './../../images/icons/addIcon.svg';
import { ReactComponent as IconSearch } from './../../images/icons/icon-magnifier-blue.svg';
import useHistoryRouter from 'hooks/use-history-router';
import useConversationSelector from 'hooks/use-conversation-selector';
import useTypedLocation from 'hooks/use-typed-location';
import classnames from 'classnames';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import { Button, Slide, useScrollTrigger } from 'components/base';

interface LocationState {
    deck: ConversationWithCategory;
    questions: Question[];
    emptyState: boolean;
    addFromFavouritesState: boolean;
}

interface ViewCopy {
    seoTitle: string;
    removeQuestionToastText: string;
    favouriteTitle: string;
    addQuestionsText: string;
    searchForQuestionsText: string;
    emptyConversationText: string;
    backButtonText: string;
    addQuestions: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Mitt bibliotek',
    removeQuestionToastText: 'Spørsmålet er fjernet fra samtalen',
    favouriteTitle: 'Likte spørsmål',
    addQuestionsText: 'Legg til spørsmål fra likte',
    searchForQuestionsText: 'Søk etter spørsmål',
    emptyConversationText: 'Denne samtalen er tom.',
    backButtonText: 'Tilbake',
    addQuestions: 'Legg til spørsmål',
};

const enCopy: ViewCopy = {
    seoTitle: 'My library',
    removeQuestionToastText: 'Question removed from conversation',
    favouriteTitle: 'Liked questions',
    addQuestionsText: 'Add questions from liked',
    searchForQuestionsText: 'Search for questions',
    emptyConversationText: 'This conversation is empty',
    backButtonText: 'Back',
    addQuestions: 'Add questions',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const EditConversationQuestions: React.FC = () => {
    const navigate = useNavigate();
    const { language } = useHistoryRouter();
    const location = useTypedLocation<LocationState>();
    const { selectConversation } = useConversationSelector();

    const {
        seoTitle,
        removeQuestionToastText,
        favouriteTitle,
        addQuestionsText,
        searchForQuestionsText,
        emptyConversationText,
        backButtonText,
        addQuestions,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const [selectedQuestions, setSelectedQuestions] = useState<Question[]>(
        location?.state?.questions
    );
    const hasQuestions = location?.state?.deck?.count > 0;
    const emptyState = location?.state?.emptyState;
    const [isLoading, setIsLoading] = useState(true);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [addFromFavouritesState, setAddFromFavouritesState] =
        useState<boolean>(location.state.addFromFavouritesState);
    const dispatch = useDispatch();
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [questionsToSelectFrom, setQuestionsToSelectFrom] = useState<
        Question[]
    >(location?.state?.questions);
    const pageTitle = addFromFavouritesState
        ? favouriteTitle
        : location?.state?.deck?.name;

    const setFavouritesToSelectFrom = () => {
        setQuestionsToSelectFrom(favouriteQuestions!);
        setIsLoading(false);
    };

    useEffect(() => {
        if (addFromFavouritesState) {
            if (favouriteQuestions) {
                setFavouritesToSelectFrom();
                setIsLoading(false);
            } else {
                conversationClient
                    .getMyFavouriteQuestions()
                    .then((response) => {
                        dispatch(setFavouriteQuestions(response.reverse()));
                        setQuestionsToSelectFrom([...response].reverse());
                    })
                    .then(() => {
                        setIsLoading(false);
                    });
            }
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    const onSaveConversation = (exitAfter?: boolean) => {
        setIsLoading(true);
        const conversationToSave: MyConversation = {
            conversation_id: location?.state?.deck.id,
            name: location?.state?.deck.title
                ? location?.state?.deck?.title
                : '',
            description: location?.state?.deck.description
                ? location?.state?.deck?.description
                : '',
            color_option: location?.state?.deck.color_option
                ? location?.state?.deck.color_option
                : 1,
            public: !!location?.state?.deck.public,
            pro_conversation: false,
            questions: selectedQuestions.map((q) => {
                return { question_id: q.id };
            }),
        };
        conversationClient
            .updateMyConversation(conversationToSave)
            .then(() => {
                conversationClient.getMyConversations().then((response) => {
                    dispatch(setMyConversations(response.reverse()));
                    if (exitAfter) {
                        const updatedDeck: Conversation = {
                            ...location.state.deck,
                        };
                        selectConversation(
                            updatedDeck,
                            language,
                            deckParentPages.myLibrary,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            selectedQuestions
                        );
                    } else {
                        setIsLoading(false);
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                navigate(-1);
            });
    };

    const onExit = () => {
        setIsLoading(true);
        if (addFromFavouritesState) {
            navigate(-1);
        } else {
            onSaveConversation(true);
        }
    };

    const toggleQuestion = (question: Question) => {
        const copyOfSelectedQuestions: Question[] = [...selectedQuestions];

        if (selectedQuestions.map((q) => q.id)?.includes(question.id)) {
            setToastMessage(removeQuestionToastText);

            setTimeout(() => {
                setToastMessage('');
            }, 2000);
            const newListOfQuestions = copyOfSelectedQuestions.filter(
                (q) => q.id !== question.id
            );
            setSelectedQuestions(newListOfQuestions);
            // onSaveConversation(newListOfQuestions);
        } else {
            copyOfSelectedQuestions.push(question);

            setSelectedQuestions(copyOfSelectedQuestions);
            // onSaveConversation(copyOfSelectedQuestions);
        }
    };

    const switchToAddFromFavourites = () => {
        setIsLoading(true);
        setAddFromFavouritesState(true);

        favouriteQuestions
            ? setFavouritesToSelectFrom()
            : conversationClient
                  .getMyFavouriteQuestions()
                  .then((response) => {
                      dispatch(setFavouriteQuestions(response.reverse()));
                      setQuestionsToSelectFrom([...response].reverse());
                  })
                  .then(() => {
                      setIsLoading(false);
                  });
    };

    const emptyStateContainer = emptyState && !addFromFavouritesState && (
        <div className={styles.EmptyStateContainer}>
            {emptyConversationText}
            <section className={styles.options}>
                <div
                    role='button'
                    tabIndex={0}
                    className={styles.option}
                    onKeyDown={() => {
                        switchToAddFromFavourites();
                    }}
                    onClick={() => {
                        switchToAddFromFavourites();
                    }}
                >
                    <div className={styles.icon}>
                        <IconAdd />
                    </div>
                    <p>{addQuestionsText}</p>
                </div>
                <div
                    role='button'
                    tabIndex={0}
                    className={styles.option}
                    onKeyDown={() => {
                        navigate(routes.search);
                    }}
                    onClick={() => {
                        navigate(routes.search);
                    }}
                >
                    <div className={styles.icon}>
                        <IconSearch />
                    </div>
                    <p>{searchForQuestionsText}</p>
                </div>
            </section>
        </div>
    );

    function BackButtonWithTrigger() {
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window : undefined,
        });

        return (
            <Slide appear={false} direction='down' in={true}>
                <section
                    className={classnames(
                        styles.BackButtonContainer,
                        trigger && styles.BackButtonWhiteContainer
                    )}
                >
                    <button
                        className={styles.BackButton}
                        onClick={() => {
                            onExit();
                        }}
                        tabIndex={0}
                    >
                        <ArrowLeft className={styles.LeftArrow} />
                        <span>{backButtonText}</span>
                    </button>
                </section>
            </Slide>
        );
    }

    if (isLoading) {
        return <Loading />;
    }
    return (
        <div>
            {<BackButtonWithTrigger />}
            <div className={styles.MyConversationInfoPageOuterContainer}>
                <div className={styles.Container}>
                    <div className={styles.MyConversationInfoPageContainer}>
                        <SEO title={seoTitle} />
                        <div className={styles.bg} />
                        <p className={styles.QuestionSelectTitleWrapper}>
                            <span className={styles.QuestionSelectTitle}>
                                {pageTitle}
                            </span>
                            {!addFromFavouritesState && (
                                <span
                                    className={
                                        styles.QuestionSelectTitleCounter
                                    }
                                >
                                    &nbsp;
                                    {'(' + questionsToSelectFrom.length + ')'}
                                </span>
                            )}
                        </p>

                        {emptyStateContainer}
                        <div className={styles.QuestionBoxContainer}>
                            {questionsToSelectFrom?.map((question) => {
                                return (
                                    <SelectQuestionBox
                                        className={styles.QuestionBox}
                                        question={question}
                                        key={question.id}
                                        isEditing={hasQuestions}
                                        selectedQuestions={selectedQuestions}
                                        onClick={() => toggleQuestion(question)}
                                    />
                                );
                            })}{' '}
                        </div>
                    </div>
                </div>
            </div>
            {addFromFavouritesState && (
                <div
                    className={classnames(
                        styles.ButtonContainer,
                        // isSelectingQuestions &&
                        styles.SelectingQuestionsButtonContainer
                    )}
                >
                    <Button
                        form='createConversationForm'
                        appearance='cta'
                        fullWidth={false}
                        className={styles.ConfirmButton}
                        onClick={() => onSaveConversation(true)}
                    >
                        {addQuestions}
                    </Button>
                </div>
            )}

            {toastMessage.length > 0 && !addFromFavouritesState && (
                <div className={styles.ToastContainer}>
                    <ToastMessage
                        className={
                            toastMessage.length > 0 && !addFromFavouritesState
                                ? styles.RemovedQuestionToast
                                : styles.Hidden
                        }
                        icon={<IconCheckWhite />}
                        message={toastMessage}
                    />
                </div>
            )}
        </div>
    );
};
export default EditConversationQuestions;
