import React, { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, IconButton } from 'components/base';
import collaborationClient from 'api/collaboration-client';
import useHistoryRouter from 'hooks/use-history-router';
import useTypedParams from 'hooks/use-typed-params';

import SessionProvider from '../../use-session/provider';
import { JoinSessionParams } from '../../utils';
import styles from './collaboration-components.module.scss';
import { useTranslation } from 'react-i18next';

const MainContent: FC = () => {
    const { collaborationCode } = useTypedParams<JoinSessionParams>();
    const { routes } = useHistoryRouter();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [collaborationId, setCollaborationId] = useState<string>();

    useEffect(() => {
        collaborationClient
            .validateCollaborationCode(collaborationCode)
            .then(({ collaborationId, isCollaborationCodeValid }) => {
                if (collaborationId && isCollaborationCodeValid)
                    setCollaborationId(collaborationId);
                else navigate(routes.joinCollaboration);
            });
    }, []);

    return collaborationId ? (
        <SessionProvider collaborationId={collaborationId} />
    ) : (
        <Fragment>
            <IconButton
                aria-label={t('close')}
                className={styles.closeButton}
                icon='close'
                onClick={() => navigate(routes.index)}
            />
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                height={1}
            >
                <CircularProgress color='inherit' />
            </Box>
        </Fragment>
    );
};

export default MainContent;
