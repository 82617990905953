import React from 'react';
import { returnCopyForLanguage } from 'services/language-service';
import { Language } from '_types/language';
import styles from './discount-code-price.module.scss';

type Props = {
    language: Language;
    discountedPrice: number | undefined;
    currency: string | undefined;
};

interface ViewCopy {
    newPriceLabel: string;
}

const nbCopy: ViewCopy = {
    newPriceLabel: 'Din nye pris',
};

const enCopy: ViewCopy = {
    newPriceLabel: 'Your new price',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const DiscountCodePrice: React.FC<Props> = ({
    language,
    discountedPrice,
    currency,
}: Props) => {
    const { newPriceLabel } = returnCopyForLanguage(language, copies);

    return (
        <div className={styles.price}>
            <div className={styles.priceLine}>
                <span className={styles.priceLabel}>{newPriceLabel}:</span>
                <span className={styles.priceText}>
                    {discountedPrice} {currency}
                </span>
            </div>
            <hr className={styles.dividerLine} />
        </div>
    );
};

export default DiscountCodePrice;
