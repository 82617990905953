import restClient from './rest-client';
import { Organization } from '_types/organization';
import {
    Conversation,
    ConversationResponse,
    ConversationWithCategory,
    mapConversations,
    mapConversationsWithCategories,
} from '_types';
import { VerifyCompanyCodeResponse } from '../_types/company-user';

export const mapCourses = (
    courses: CourseType[]
): CourseType[] =>
    courses.map((c) => {
        return c
    });

// uses DEFAULT_BASE_URL from restClient.
// import these constants where needed
export const PRO_ROUTES = {
    subscriptionSwitch: '/subscription-switch/pro',
    proCode: '/pro/code',
    organization: '/organization',
    categoriesOrg: '/organization/categories',
    categoriesPro: '/pro/categories',
};

export interface CourseInterface {
    title: string,
    link: string,
    illustration: string
}

export type CourseType = {
    title: string,
    link: string,
    illustration: string
};

export interface ProSwitchData {
    pro_code: string;
}

export default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    switchSubscription: (pro_code: string): Promise<any> => {
        /**
         *  403: company email required. The user isn't registered with an email address having one of the allowed domains
         *  404: no professional subscription was found for the given code
         *  500: on any unexpected error or when cancelling any existing premium subscription failed
         */
        const data: ProSwitchData = {
            pro_code: pro_code,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return restClient.post<ProSwitchData, any>(
            PRO_ROUTES.subscriptionSwitch,
            data
        );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validateCode: (code: string, email: string): Promise<any> => {
        /**
         * HTTP status 400: the email address is missing and the code / subscription has a white list of email domains
         * HTTP status 403: the email address isn't allowed to sign up (organization email domain required)
         * HTTP status 204: the pro code is valid and the email address is allowed to sign up
         */
        const route = `${PRO_ROUTES.proCode}/${code}?email=${email}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return restClient.get<any>(route, false);
    },
    getOrganization: (organizationName: string): Promise<Organization> => {
        return restClient.get<Organization>(
            PRO_ROUTES.organization,
            true,
            undefined,
            {
                org: organizationName
                    .trim()
                    .toLocaleLowerCase()
                    .replace(/\s/g, '-'),
            }
        );
    },
    getOrgCategories: (): Promise<Conversation[]> => {
        return restClient
            .get<ConversationResponse[]>(
                '/get-org-categories',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversations);
    },
    getProCategories: (): Promise<Conversation[]> => {
        return restClient
            .get<ConversationResponse[]>(
                    '/get-pro-categories',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversations);
    },
    getNewlyAddedProConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>(
                '/get-newly-added-pro-conversations',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapConversationsWithCategories);
    },
    getCourses: (): Promise<CourseInterface[]> => {
        return restClient
            .get<CourseType[]>(
                '/get-courses',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true
            )
            .then(mapCourses);
    },
    canBeRegisteredWithCode: async (proCode: string): Promise<boolean> => {
        return restClient.get<boolean>('/can_be_registered_with_code', false, {
            code: proCode,
        });
    },
    verifyCompanyCode: async (proCode: string): Promise<VerifyCompanyCodeResponse> => {
        try {
            // verifyCompanyCode endpoint returns an empty body on 200 and throws on 404.
            await restClient.get('/verifyCompanyCode', false, {
                code: proCode.trim(),
            });
            return {code: '', message: '', data: {status: 200}};
        } catch (e) {
            return (e as VerifyCompanyCodeResponse);
        }
    },
};
