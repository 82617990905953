import { useState, useEffect } from 'react';

/**
 * Helper hook to determine whether the current browser tab is visible or hidden
 */
export const useIsVisible = (): boolean => {
    const [visible, setVisible] = useState(
        document.visibilityState === 'visible'
    );

    useEffect(() => {
        const visibilityChangeHandler = () =>
            setVisible(document.visibilityState === 'visible');

        document.addEventListener('visibilitychange', visibilityChangeHandler);

        return () =>
            document.removeEventListener(
                'visibilitychange',
                visibilityChangeHandler
            );
    }, []);

    return visible;
};
