import * as actionTypes from './_actionTypes';
import { SetFavouriteQuestions, ClearFavouriteQuestions } from './_types';
import { Question } from '_types';

export const setFavouriteQuestions = (
    favouriteQuestions: Question[]
): SetFavouriteQuestions => ({
    type: actionTypes.SET_FAVOURITE_QUESTIONS,
    payload: favouriteQuestions,
});

export const clearFavouriteQuestions = (): ClearFavouriteQuestions => ({
    type: actionTypes.CLEAR_FAVOURITE_QUESTIONS,
});
