import React from 'react';
import classnames from 'classnames';
import styles from './round-card.module.scss';
import Card, { Props } from './card';

const RoundCard: React.FC<Props> = ({
    onClick,
    className,
    children,
}: Props) => (
    <Card className={classnames(styles.card, className)} onClick={onClick}>
        {children}
    </Card>
);

export default RoundCard;
