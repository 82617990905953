import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import authClient, { LoginData } from 'api/auth-client';
import proClient from 'api/pro-client';
import userClient from 'api/user-client';
import authService from 'services/auth-service';
import { setProAccount } from 'store-modules/professional';
import { Organization, Subscription, User } from '_types';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import subscriptionClient from 'api/subscription-client';

// The existing code exposed these errors despite the security issues.
// These should be removed when the server no longer exposes these details.
export enum LoginStatus {
    Ok = 'OK',
    InvalidUsername = 'INVALID_USERNAME',
    InvalidPassword = 'INVALID_PASSWORD',
    Error = 'ERROR',
}

export interface LoginResult {
    user: User | null;
    subscription: Subscription | null;
    loginStatus: LoginStatus;
    firstAzureLogin: boolean;
}

//native analytics
const logOrganization = (organization: Organization) => {
    FirebaseAnalytics.setUserProperty({
        name: 'Organization',
        value: organization.name?.trim(),
    });
};

export const logUserInByToken = async (
    token: string,
    setOrganization: (organization: Organization) => void,
    loginForCompanySignupOnly?: boolean
): Promise<LoginResult> => {
    const result: LoginResult = {
        user: null,
        subscription: null,
        loginStatus: LoginStatus.Ok,
        firstAzureLogin: false,
    };
    try {
        // Set token temporary to get current user (user endpoint is protected)
        localStorage.setItem('token', token);
        const user = await userClient.getCurrentUser();
        result.subscription = await subscriptionClient.getActiveSubscription();
        result.user = user;
        if (!!user?.organization) {
            const organization = await proClient.getOrganization(
                user.organization
            );
            setOrganization(organization);
            logOrganization(organization);
        }
        loginForCompanySignupOnly
            ? localStorage.setItem('token', token)
            : authService.login(token);
    } catch (error) {
        localStorage.removeItem('token');
        throw error;
    }

    return result;
};

export const logInByGoogle = async (
    idToken: string,
    productId: number | string | undefined,
    loginForCompanySignupOnly?: boolean
): Promise<LoginResult> => {
    try {
        const tokenInOurSystem = await authClient.logInByGoogleToken(
            idToken,
            productId
        );

        return await logUserInByToken(
            tokenInOurSystem,
            (organization) => {
                logOrganization(organization);
                setProAccount(organization);
            },
            loginForCompanySignupOnly
        );
    } catch (error) {
        throw error;
    }
};

export const logInByApple = async (
    response: SignInWithAppleResponse['response'],
    productId: number | string | undefined
): Promise<LoginResult> => {
    try {
        const tokenInOurSystem = await authClient.logInByAppleToken(
            response,
            productId
        );
        return await logUserInByToken(tokenInOurSystem, (organization) => {
            logOrganization(organization);
            setProAccount(organization);
        });
    } catch (error) {
        throw error;
    }
};

export const logInByAzure = async (
    idToken: string,
    preferredUsername: string,
    tenantId: string,
    givenName: string,
    familyName: string
): Promise<LoginResult> => {
    try {
        const loginResponse = await authClient.logInByAzureToken(
            idToken,
            preferredUsername,
            tenantId,
            givenName,
            familyName
        );

        return await logUserInByToken(loginResponse.token, (organization) => {
            logOrganization(organization);
            setProAccount(organization);
        });
    } catch (error) {
        throw error;
    }
};

export const logUserIn = async (
    username: string,
    password: string,
    setOrganization: (organization: Organization) => void,
    loginForCompanySignupOnly?: boolean
): Promise<LoginResult> => {
    const values: LoginData = {
        username: username,
        password: password,
    };
    const result: LoginResult = {
        user: null,
        subscription: null,
        loginStatus: LoginStatus.Ok,
        firstAzureLogin: false,
    };
    let token;
    try {
        token = await authClient.getToken(values);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        if (err.code === '[jwt_auth] invalid_email') {
            result.loginStatus = LoginStatus.InvalidUsername;
            return result;
        }
        if (err.code === '[jwt_auth] incorrect_password') {
            result.loginStatus = LoginStatus.InvalidPassword;
            return result;
        }
        if (err.code === '[jwt_auth] invalid_username') {
            result.loginStatus = LoginStatus.InvalidUsername;
            return result;
        }

        result.loginStatus = LoginStatus.Error;
        return result;
    }

    try {
        // Set token temporary to get current user (user endpoint is protected)
        localStorage.setItem('token', token);
        const user = await userClient.getCurrentUser();
        result.user = user;
        const subscription = await subscriptionClient.getActiveSubscription();
        result.subscription = subscription;
        if (!!user?.organization) {
            const organization = await proClient.getOrganization(
                user.organization
            );
            logOrganization(organization);
            setOrganization(organization);
        }

        !loginForCompanySignupOnly && authService.login(token);
    } catch (error) {
        localStorage.removeItem('token');
        throw error;
    }

    return result;
};
