import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentRequest } from '@stripe/stripe-js';
import {
    PaymentRequestButtonElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { deviceSelector } from '../../../../../store-modules/app-settings';
import { getApplePayOrderData, confirmApplePayOrder } from '../../../_services';
import style from './apple-pay.module.scss';
import { ApplePayButton } from 'components/apple-pay-button';

type Props = {
    productID: string | number;
    price: number;
    currency: string;
    onSuccess: () => void;
    onCancel: () => void;
};

const ApplePayWithoutDiscount: FC<Props> = (props: Props) => {
    const { productID, price, currency, onSuccess, onCancel } = props;

    const device = useSelector(deviceSelector);

    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
        null
    );

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }

        const completePayment = async (orderId: string): Promise<void> => {
            try {
                const res = await confirmApplePayOrder(orderId);
                if (res) {
                    onSuccess();
                } else console.log('Payment has declined !');
            } catch (err) {
                throw err;
            }
        };

        const pr = stripe.paymentRequest({
            country: 'NO',
            currency: currency.toLocaleLowerCase(),
            total: {
                label: 'Demo total',
                amount: price * 100,
            },
            requestPayerName: true,
            disableWallets: ['googlePay', 'browserCard'],
            requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
            if (result && device !== 'ios') {
                setPaymentRequest(pr);
            }
        });

        pr.on('paymentmethod', async (ev) => {
            const { client_secret: clientSecret, order_id: orderId } =
                await getApplePayOrderData(productID, undefined);

            const { error: confirmError, paymentIntent } =
                await stripe.confirmCardPayment(
                    clientSecret,
                    {
                        payment_method: ev.paymentMethod.id,
                    },
                    { handleActions: false }
                );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                onCancel();
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11" instead
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent?.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(
                        clientSecret
                    );
                    if (error) {
                        onCancel();
                        // The payment failed -- ask your customer for a new payment method.
                    } else {
                        completePayment(orderId);
                    }
                } else {
                    completePayment(orderId);
                }
            }
        });
    }, [
        device,
        stripe,
        elements,
        productID,
        price,
        currency,
        onSuccess,
        onCancel,
    ]);

    return (
        <div>
            {paymentRequest && (
                <PaymentRequestButtonElement
                    className={style.applePay}
                    options={{ paymentRequest }}
                />
            )}
            {device === 'ios' && (
                <ApplePayButton
                    productID={productID}
                    currency={currency}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

export default ApplePayWithoutDiscount;
