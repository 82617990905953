import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    IconOption,
    Stack,
    StackProps,
    ToggleButtonGroup,
} from 'components/base';
import useSwiperContext from 'hooks/use-swiper-context';
import { useDocumentData as useFirebaseDocumentData } from 'react-firebase-hooks/firestore';
import {
    DisplayAs,
    getQuestionDocumentRef,
    getSessionDocumentRef,
} from 'utils/collaboration-utils';

import useCollaboration from '../../use-collaboration';
import { useDocumentData } from 'hooks/firebase/use-document-data';

type DisplayIconMap = {
    value: DisplayAs;
    icon: IconOption;
};

const displayIconMap: DisplayIconMap[] = [
    { value: DisplayAs.WORDCLOUD, icon: 'cloud' },
    { value: DisplayAs.FREE_TEXT, icon: 'text' },
];

type DisplayButtonsProps = {
    StackProps?: Partial<StackProps>;
};

const DisplayButtons: FC<DisplayButtonsProps> = ({
    StackProps: stackProps,
}) => {
    const { activeIndex } = useSwiperContext();
    const { collaborationId, interactiveMode, questions } = useCollaboration();
    const { t } = useTranslation();

    const [selectedIndex, setSelectedIndex] = useState<number>();

    const activeQuestionId =
        activeIndex != null ? questions[activeIndex]?.id : undefined;

    const questionRef = activeQuestionId
        ? getQuestionDocumentRef(collaborationId, activeQuestionId)
        : undefined;

    const [questionDoc, loading, error] = useFirebaseDocumentData(
        questionRef as never
    );
    const { display } = questionDoc ?? {};

    const sessionRef = getSessionDocumentRef(collaborationId);
    const sessionData = useDocumentData(sessionRef);

    const { displayParticipantNames } = sessionData || {};

    useEffect(() => {
        if (interactiveMode && !display && !loading && !error)
            questionRef?.update({ display: DisplayAs.WORDCLOUD });
    }, [questionDoc, loading, error]);

    useEffect(() => {
        if (display) {
            const index = displayIconMap.findIndex(
                (item) => item.value === display
            );
            if (selectedIndex !== index) setSelectedIndex(index);
        }
    }, [display]);

    return activeQuestionId ? (
        <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            my='-1px'
            {...stackProps}
        >
            <ToggleButtonGroup
                buttonProps={displayIconMap.map(({ value, icon }) => ({
                    leftIcon: icon,
                    onClick: () => questionRef?.update({ display: value }),
                    children: t('collaboration.displayAs', {
                        context: value,
                    }),
                }))}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
            />

            <Checkbox
                label={t('collaboration.displayParticipantNames.label')}
                checked={!!displayParticipantNames}
                onChange={({ target: { checked } }) =>
                    sessionRef.update({
                        displayParticipantNames: checked,
                    })
                }
            />
        </Stack>
    ) : null;
};

export default DisplayButtons;
