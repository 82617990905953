import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Typography } from '../../data-display/typography';
import { Menu } from '../../navigation/menu';

import Decorator1 from './assets/decorator-1.svg';
import Decorator2 from './assets/decorator-2.svg';
import Decorator3 from './assets/decorator-3.svg';

export const StyledContainer = styled(Box)`
    border-radius: ${({ theme }) => Number(theme.shape.borderRadius) * 2}px;
    position: relative;
    display: flex;
`;

export const StyledButton = styled(Button)`
    height: 100%;
    width: 100%;
    border-color: ${({ theme }) =>
        theme.palette.mode === 'dark'
            ? theme.palette.text.primary
            : theme.palette.custom.blue[90]};
    border-radius: inherit;
    border-width: 2px;
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: 1.2;
    padding: ${({ theme }) => theme.spacing(4, 6)};

    &:hover {
        border-color: ${({ theme }) =>
            theme.palette.mode === 'dark'
                ? theme.palette.text.primary
                : theme.palette.custom.blue[90]};
    }

    &:not(.stacked) {
        justify-content: flex-start;

        &.start-content {
            padding-left: 0;
        }

        &.end-content {
            padding-right: ${({ theme }) => theme.spacing(9)};
        }
    }

    &.dense {
        padding: ${({ theme }) => theme.spacing(3, 4)};
    }

    &.stacked.start-content {
        padding-top: ${({ theme }) => theme.spacing(4.5)};

        &.dense {
            padding-top: ${({ theme }) => theme.spacing(4)};
        }
    }

    &.decorator {
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: right;
    }

    &.decorator1 {
        background-image: url(${Decorator1});
    }

    &.decorator2 {
        background-image: url(${Decorator2});
    }

    &.decorator3 {
        background-image: url(${Decorator3});
    }
`;

export const Subheading = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.secondary};

    &.prominentSubheading {
        font-size: ${({ theme }) => theme.typography.body1.fontSize};
        color: ${({ theme }) => theme.palette.text.primary};

        .uppercase {
            text-transform: uppercase;
            font-size: 14px;
        }
    }

    span:not(:first-of-type):before {
        content: '·';
        margin: ${({ theme }) => theme.spacing(0, 0.5)};
    }

    .uppercase {
        text-transform: uppercase;
        font-size: 12px;
    }
`;

export const MenuButton = styled(Menu.IconButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacing(2)};
`;
