import React from 'react';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import { returnCopyForLanguage } from 'services/language-service';

interface Props {}
interface ViewCopy {
    infoText: string;
}

const nbCopy: ViewCopy = {
    infoText: 'Du har ikke lenger tilgang til FuelBox Proff.',
};

const enCopy: ViewCopy = {
    infoText: 'You no longer have access to FuelBox Pro',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const CancelledProSub: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const { infoText } = returnCopyForLanguage(language, copies);
    return <p>{infoText}</p>;
};

export default CancelledProSub;
