import React from 'react';

import styles from './option-modal.module.scss';
import Option from './option/option';
import { ReactComponent as IconEdit } from './../../images/icons/icon-edit.svg';
import { ReactComponent as IconConversationOutline } from './../../images/icons/icon-conversation-outline.svg';
import { ReactComponent as IconPublic } from 'images/icons/icon-public.svg';
import { ReactComponent as IconPrivate } from 'images/icons/icon-lock.svg';
// import { ReactComponent as IconShare } from './../../images/icons/icon-share.svg';
import { ReactComponent as IconDelete } from './../../images/icons/icon-delete.svg';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';

interface Props {
    toggleModal: () => void;
    isPublicConversation: boolean;
    onEditTexts: () => void;
    onEditQuestions: () => void;
    onTogglePublic: () => void;
    onDeleteConversation: () => void;
}

interface ViewCopy {
    editTextsLabel: string;
    editQuestionsLabel: string;
    publishLabel: string;
    unPublishLabel: string;
    publishSubLabel: string;
    deleteLabel: string;
}

const nbCopy: ViewCopy = {
    editTextsLabel: 'Endre farge, navn og beskrivelse',
    editQuestionsLabel: 'Se og rediger spørsmål',
    publishLabel: 'Gjør samtalen offentlig',
    unPublishLabel: 'Gjør samtalen privat',
    publishSubLabel: 'La andre brukere finne samtalen din i søk',
    deleteLabel: 'Slett samtalen',
};

const enCopy: ViewCopy = {
    editTextsLabel: 'Edit name and decsription',
    editQuestionsLabel: 'View and edit questions',
    publishLabel: 'Make conversation public',
    unPublishLabel: 'Make conversation private',
    publishSubLabel: 'Let others find your conversation in search',
    deleteLabel: 'Delete conversation',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const OptionModal: React.FC<Props> = ({
    toggleModal,
    isPublicConversation,
    onEditTexts,
    onEditQuestions,
    onTogglePublic,
    onDeleteConversation,
}: Props) => {
    const language: Language = useSelector(languageSelector);

    const {
        editTextsLabel,
        editQuestionsLabel,
        publishLabel,
        unPublishLabel,
        publishSubLabel,
        deleteLabel,
    } = returnCopyForLanguage(language, copies);

    return (
        <div
            role='button'
            tabIndex={0}
            className={styles.OptionModalContainer}
            onKeyDown={() => {
                toggleModal();
            }}
            onClick={() => {
                toggleModal();
            }}
        >
            <div className={styles.OptionModal}>
                <Option
                    title={editTextsLabel}
                    icon={<IconEdit />}
                    onClick={() => onEditTexts()}
                    iconClassName={styles.IconEdit}
                />
                <Option
                    title={editQuestionsLabel}
                    icon={<IconConversationOutline />}
                    onClick={() => onEditQuestions()}
                    iconClassName={styles.IconConversationOutline}
                />
                {isPublicConversation ? (
                    <Option
                        title={unPublishLabel}
                        icon={<IconPrivate />}
                        onClick={() => onTogglePublic()}
                        iconClassName={styles.IconPrivate}
                    />
                ) : (
                    <Option
                        title={publishLabel}
                        icon={<IconPublic />}
                        subTitle={publishSubLabel}
                        onClick={() => onTogglePublic()}
                        iconClassName={styles.IconPublic}
                    />
                )}
                {/* <Option title='Del samtalen' icon={<IconShare />} /> */}
                <Option
                    title={deleteLabel}
                    icon={<IconDelete />}
                    onClick={() => onDeleteConversation()}
                    iconClassName={styles.IconDelete}
                />
            </div>
        </div>
    );
};

export default OptionModal;
