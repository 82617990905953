import { ReactComponent as IconLanguage } from 'images/icons/language.svg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    returnCopyForLanguage,
    setLocalStorageLanguage,
} from 'services/language-service';
import { redirectOnLanguageSelect } from 'services/redirects.service';
import { updateLanguage } from 'store-modules/app-settings';
import { isPremiumSelector } from 'store-modules/user';
import { Language, LanguageName } from '_types/language';
import styles from './language-footer.module.scss';

interface Props {
    language: Language;
    parentPage?: string;
}

interface ViewCopy {
    languageLinkLabel: LanguageName;
    languageLinkCode: Language;
}

const nbCopy: ViewCopy = {
    languageLinkLabel: 'English',
    languageLinkCode: 'en',
};

const enCopy: ViewCopy = {
    languageLinkLabel: 'Norwegian',
    languageLinkCode: 'nb',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const LanguageFooter: React.FC<Props> = ({ language, parentPage }: Props) => {
    const navigate = useNavigate();
    const { languageLinkLabel, languageLinkCode } = returnCopyForLanguage(
        language,
        copies
    );

    const dispatch = useDispatch();

    const selectLanguage = (language: Language): void => {
        setLocalStorageLanguage(language);
        dispatch(updateLanguage(language));

        const route = parentPage
            ? redirectOnLanguageSelect(language, parentPage)
            : redirectOnLanguageSelect(language, window.location.href);

        navigate(route);
    };

    const isPremium = useSelector(isPremiumSelector);

    return isPremium ? null : (
        <section className={styles.languageFooter}>
            <IconLanguage />
            <section className={styles.languageFooterText}>
                FuelBox offered in:
            </section>
            <div
                className={styles.link}
                onClick={(): void => selectLanguage(languageLinkCode)}
                onKeyDown={(): void => selectLanguage(languageLinkCode)}
                role='button'
                tabIndex={0}
            >
                {languageLinkLabel}
            </div>
        </section>
    );
};

export default LanguageFooter;
