import React, { FC } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';

/**
 * Wrapper component for `Dialog` or `Drawer` actions
 */
export const Actions: FC<StackProps> = ({
    alignItems = 'center',
    spacing = 3,
    pt = 2.75,
    ...rest
}) => <Stack alignItems={alignItems} spacing={spacing} pt={pt} {...rest} />;
