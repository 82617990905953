import React from 'react';
import styles from './info-links.module.scss';
import { Link } from 'components/link';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';

interface Props {}
interface ViewCopy {
    termsOfUse: string;
    privacy: string;
    faq: string;
    contact: string;
}

const nbCopy: ViewCopy = {
    termsOfUse: 'Vilkår for bruk',
    privacy: 'Personvern',
    faq: 'Ofte stilte spørsmål',
    contact: 'Kontakt oss',
};

const enCopy: ViewCopy = {
    termsOfUse: 'Terms of use',
    privacy: 'Privacy policy',
    faq: 'Frequently asked questions',
    contact: 'Contact us',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const InfoLinks: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const { termsOfUse, privacy, faq, contact } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);

    const tncLocation = {
        pathname: routes.tnc,
        state: {
            backPath: routes.account.index,
        },
    };

    const privacyLocation = {
        pathname: routes.privacyTerms,
        state: {
            backPath: routes.account.index,
        },
    };

    const faqLocation = {
        pathname: routes.faq,
        state: {
            backPath: routes.account.index,
        },
    };

    const contactLocation = {
        pathname: routes.contact,
        state: {
            backPath: routes.account.index,
        },
    };
    return (
        <article className={styles.container}>
            <Link to={faqLocation} underline={false}>
                {faq}
            </Link>
            <span className={styles.separator}>·</span>
            <Link to={privacyLocation} underline={false}>
                {privacy}
            </Link>
            <span className={styles.separator}>·</span>
            <Link to={tncLocation} underline={false}>
                {termsOfUse}
            </Link>
            <span className={styles.separator}>·</span>
            <Link to={contactLocation} underline={false}>
                {contact}
            </Link>
        </article>
    );
};

export default InfoLinks;
