import React from 'react';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useSelector } from 'react-redux';
import { returnCopyForLanguage } from 'services/language-service';

interface Props {}
interface ViewCopy {
    infoText1: string;
    infoText2: string;
}

const nbCopy: ViewCopy = {
    infoText1:
        'Avslutter du abonnementet vil du bli frakoblet din organisasjon og miste tilgangen til FuelBox Proff.',
    infoText2: 'Du vil fremdeles beholde brukerkontoen din.',
};

const enCopy: ViewCopy = {
    infoText1:
        'If you cancel your subscription, you will be disconnected from your organisation and lose access to FuelBox Pro.',
    infoText2: 'You will still keep your user account.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const EndProSub: React.FC<Props> = () => {
    const language: Language = useSelector(languageSelector);
    const { infoText1, infoText2 } = returnCopyForLanguage(language, copies);
    return (
        <p>
            {infoText1}
            <br /> <br />
            {infoText2}
        </p>
    );
};

export default EndProSub;
