import React from 'react';
import styles from './end-slide.module.scss';
import { Container } from 'components/container';
import { Button } from 'components/base';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { Slide } from '../_types';
import { SwiperSlide } from '../swiper-slide';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';

interface Props {
    slide: Slide;
    isActive: boolean;
}
interface ViewCopy {
    title: string;
    buttonLabel: string;
}

const nbCopy: ViewCopy = {
    title: 'Ferdig snakket?',
    buttonLabel: 'Utforsk andre samtaler',
};

const enCopy: ViewCopy = {
    title: 'Feeling chatty?',
    buttonLabel: 'Explore other conversations',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ProEndSlide: React.FC<Props> = ({ isActive }) => {
    const language: Language = useSelector(languageSelector);
    const { title, buttonLabel } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    return (
        <SwiperSlide>
            <Container className={styles.Container} size='small'>
                <PageTitle title={title} />

                <Button
                    className={styles.Cta}
                    disabled={!isActive}
                    to={routes.proindex}
                >
                    {buttonLabel}
                </Button>
            </Container>
        </SwiperSlide>
    );
};

export default ProEndSlide;
