import React, { useState } from 'react';
import styles from './recover-account.module.scss';
import { Button } from 'components/base';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnRoutesForLanguage } from 'services/language-service';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { AccountSubpage } from 'pages/account-page/subpage';
import { setToastMessage, userSelector } from 'store-modules/user';
import userClient from 'api/user-client';
import { Loading } from 'components/loading';
import { setUserState } from 'store-modules/user';

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const RecoverAccount: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const [isLoading, setIsLoading] = useState(false);

    const recoverAccount = (): void => {
        setIsLoading(true);
        try {
            user &&
                userClient.recoverUser(user.id).then(() => {
                    userClient.getCurrentUser().then((user) => {
                        dispatch(setUserState(user!));
                        dispatch(setToastMessage(t('deleteAccountUndoToast')));
                        navigate(routes.account.index);
                    });
                });
        } catch (e) {
            console.error('account recovery failed..', e);
        }
    };

    const deleteDate = new Date(user?.delete_date ? user.delete_date : '');
    const formattedDate = deleteDate.toLocaleDateString(
        language === 'en' ? 'en-EN' : 'nb-NO',
        {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }
    );

    if (isLoading) {
        return <Loading />;
    }

    return (
        <AccountSubpage
            pageTitle={t('recoverAccount.title')}
            title={t('recoverAccount.title')}
        >
            <div className={styles.text}>
                <Trans
                    components={[<strong key={0} />]}
                    i18nKey='recoverAccount.text1'
                    values={{
                        date: formattedDate,
                    }}
                />
            </div>
            <div className={styles.text}>{t('recoverAccount.text2')}</div>

            <Button
                onClick={recoverAccount}
                className={styles.ConfirmRecoverButton}
                appearance='cta'
            >
                {t('recoverAccount.buttonLabel')}
            </Button>
        </AccountSubpage>
    );
};

export default RecoverAccount;
