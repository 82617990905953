export function subscribe(eventClass, handler) {
    let func = function (event) {
        handler(...event.detail);
    };

    document.addEventListener(eventClass.name, func, { passive: true });

    return {
        unsubscribe: function unsubscribe() {
            document.removeEventListener(eventClass.name, func);
        },
    };
}

export function publish(event) {
    let nativeEvent = new CustomEvent(event.constructor.name, {
        detail: event.args,
    });

    document.dispatchEvent(nativeEvent);
}
