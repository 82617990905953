import { Configuration, LogLevel } from '@azure/msal-browser';
import { getEnv } from 'api/rest-client';
import { IS_MOBILE_APP } from './../../constants';

const environment = getEnv();
const isProd = environment === 'prod' || IS_MOBILE_APP;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AzureActiveDirectoryAppClientId: any = isProd
    ? process.env.REACT_APP_AZURE_CLIENT_ID
    : process.env.REACT_APP_AZURE_CLIENT_ID_TEST;

export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: AzureActiveDirectoryAppClientId,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
