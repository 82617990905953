import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routesNO, RouteList, routesEN } from 'api/routes';
import { Button, Stack } from 'components/base';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';

import styles from './no-sub-page.module.scss';

interface Props {}
interface ViewCopy {
    textNoSub: string;
    textBuySub: string;
}

const nbCopy: ViewCopy = {
    textNoSub: 'Du har ikke et aktivt abonnement',
    textBuySub: 'Velg abonnement',
};

const enCopy: ViewCopy = {
    textNoSub: "You don't have an active subscription",
    textBuySub: 'Select subscription',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const NoSubPage: React.FC<Props> = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { textNoSub, textBuySub } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    return (
        <section className={styles.container} aria-label='subscription-details'>
            <Stack spacing={3}>
                <p className={styles.text}>{textNoSub}</p>

                <Button
                    // className={styles.button}
                    onClick={() => navigate(routes.paymentOptionSelect)}
                    fullWidth={false}
                >
                    {textBuySub}
                </Button>
            </Stack>
        </section>
    );
};

export default NoSubPage;
