import freemiumClient from 'api/freemium-client';
import conversationClient from 'api/conversation-client';

// TODO: Fix typing once freemium/premium conversation types are made similar in BE
export function getConversationsService(
    isPremium: boolean,
    taxonomy: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): null | (() => Promise<any>) {
    if (!isPremium) {
        return taxonomy === 'freemium' ? freemiumClient.getFreeDecks : null;
    }

    switch (taxonomy) {
        case 'freemium':
            return freemiumClient.getFreeDecks;
        case 'temasamtale':
            return conversationClient.getThemes;
        case 'tema':
            return conversationClient.getThemes;
        case 'topics':
            return conversationClient.getThemes;
        case 'sesongsamtale':
            return conversationClient.getFeaturedConversations;
        case 'featured':
            return conversationClient.getFeaturedConversations;
        case 'relasjonelle_samtaler':
            return conversationClient.getConversations;
        case 'tilfeldige_samtaler':
            return conversationClient.getConversations;
        case 'random':
            return conversationClient.getRandomQuestions;
        default:
            return conversationClient.getConversations;
    }
}
