import React from 'react';
import styles from './personal-details.module.scss';
import { useSelector } from 'react-redux';
import { userSelector } from 'store-modules/user';

const PersonalDetails = () => {
    const user = useSelector(userSelector);
    const nameToDisplay = () => {
        let name = '';
        name += user?.firstName + ' ';
        name += user?.lastName;
        return name;
    };
    return (
        <React.Fragment>
            <p className={styles.text}>{nameToDisplay()}</p>
            <p className={styles.text}>{user?.email}</p>
            {/* <Link
                to={routes.account.changePersonal}
                className={styles.link}
                block={true}
            >
                {changePersonalDetails}
            </Link> */}
            {/* <Link
                to={routes.account.changePassword}
                className={styles.link}
                block={true}
            >
                {changePassword}
            </Link> */}
        </React.Fragment>
    );
};

export default PersonalDetails;
