import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    getSessionDocumentRef,
    getParticipantsCollectionRef,
} from 'utils/collaboration-utils';
import { useCollectionData } from 'hooks/firebase/use-collection-data';
import useHistoryRouter from 'hooks/use-history-router';
import { Box, CircularProgress } from 'components/base';

import CollaborationContext, { CollaborationValues } from './context';
import { Deck } from '_types';
import { useDocumentData } from 'hooks/firebase/use-document-data';

type CollaborationProviderProps = Pick<
    CollaborationValues,
    'collaboration' | 'collaborationId' | 'interactiveMode'
> & {
    children: ReactNode;
};

const CollaborationProvider: FC<CollaborationProviderProps> = ({
    children,
    collaboration,
    collaborationId,
    interactiveMode,
}) => {
    const { routes } = useHistoryRouter();
    const location = useLocation();
    const navigate = useNavigate();

    const sessionRef = getSessionDocumentRef(collaborationId);
    const sessionData = useDocumentData(sessionRef);

    const { conversation: { deck = {} as Deck, questions = [] } = {} } =
        sessionData || {};

    const participantRef = getParticipantsCollectionRef(collaborationId);
    const participants = useCollectionData(participantRef, !interactiveMode);

    useEffect(() => {
        interactiveMode &&
            sessionRef.update({ participantCount: participants?.length ?? 0 });
    }, [participants]);

    const closeCollaborationView = () =>
        location.key && location.key !== 'default'
            ? navigate(-1)
            : navigate(routes.collaborationOverview);

    const values: CollaborationValues = {
        closeCollaborationView,
        collaboration,
        collaborationId,
        deck,
        interactiveMode,
        questions,
    };

    if (questions.length === 0 || Object.keys(deck).length === 0)
        return (
            <Box
                height={1}
                width={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <CircularProgress color='inherit' />
            </Box>
        );

    return (
        <CollaborationContext.Provider value={values}>
            {children}
        </CollaborationContext.Provider>
    );
};

export default CollaborationProvider;
