import { useCallback, useEffect, useRef } from 'react';

export const useMountedState = (): (() => boolean) => {
    const mountedRef = useRef(false);
    const isMounted = useCallback(() => mountedRef.current, []);
    useEffect(() => {
        mountedRef.current = true;

        return (): void => {
            mountedRef.current = false;
        };
    }, []);

    return isMounted;
};
