import { Language, LANGUAGE_KEY } from '_types/language';
import { updateLanguage } from 'store-modules/app-settings';
import { Dispatch } from 'redux';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';

// Dispatches an event to GTM for langauge changes
export const dispatchGtmLanguageEvent = (language: Language): void => {
    const gtmData = {
        category: 'settings',
        action: 'language',
        label: language,
    };
    gtmEvent(GTM_EVENT.GA_EVENT, gtmData);
};

//Check language set in browser
const checkBrowserLanguage = (): Language => {
    const isBrowserLangNB = navigator.language.includes('nb');
    const isBrowserLangEN = navigator.language.includes('en');
    if (isBrowserLangNB) return 'nb';
    if (isBrowserLangEN) return 'en';
    return 'nb';
};

// Check language from domain
export const getDomainLanguageDefault = (): Language => {
    if (typeof window === undefined) return 'nb';
    const isNB = window.location.href.indexOf('/nb') !== -1;
    const isEN = window.location.href.indexOf('/en') !== -1;
    const isCOM = window.location.href.indexOf('.com') !== -1;
    const isNO = window.location.href.indexOf('.no') !== -1;
    if (isNB) return 'nb';
    if (isEN) return 'en';
    if (isCOM) return 'en';
    if (isNO) return 'nb';
    return checkBrowserLanguage();
};

// Check langauge from localStorage
export const getLocalStorageLanguage = (): Language | null => {
    if (typeof window === undefined) return null;
    const result = localStorage.getItem(LANGUAGE_KEY);
    return !result ? null : (JSON.parse(result) as Language);
};

// Set langauge to localStorage
export const setLocalStorageLanguage = (
    language: Language,
    dispatchGtm = true
): void => {
    if (typeof window === undefined) return;
    localStorage.setItem(LANGUAGE_KEY, JSON.stringify(language));
    if (dispatchGtm) dispatchGtmLanguageEvent(language);
};

export const determineLanguage = (): Language => {
    const urlLang = getDomainLanguageDefault();
    setLocalStorageLanguage(urlLang, false);
    return urlLang;
};

// Initializes the language from localStorage or domain and dispatches to Store app settings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initializeLanguage = (dispatch: Dispatch<any>): void => {
    const language = determineLanguage();
    // setLocalStorageLanguage(language, false);
    dispatch(updateLanguage(language));
};

// Return correct Copy based on language setting and list of copy objects
export const returnCopyForLanguage = <T>(
    language: Language,
    copies: { name: Language; copy: T }[]
): T => {
    const result = copies.filter((c) => c.name === language);
    return !!result.length ? result[0].copy : copies[0].copy;
};

export const returnRoutesForLanguage = <T>(
    language: Language,
    routeObj: { name: Language; routes: T }[]
): T => {
    const result = routeObj.filter((c) => c.name === language);
    return !!result.length ? result[0].routes : routeObj[0].routes;
};
