import React, { useEffect } from 'react';
import classnames from 'classnames';
import styles from './copy-content.module.scss';
import { Language } from '_types/language';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import { returnCopyForLanguage } from 'services/language-service';
import { IS_IOS_APP } from '../../constants';
import { useNavigate } from 'react-router-dom';

interface Props {
    language: Language;
    goBackHandler?: () => void;
    children: React.ReactNode;
    showTopButton?: boolean;
    showBottomButton?: boolean;
    className?: string;
}

interface ViewCopy {
    backButtonText: string;
}
const nbCopy: ViewCopy = {
    backButtonText: 'Tilbake',
};

const enCopy: ViewCopy = {
    backButtonText: 'Back',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const CopyContent: React.FC<Props> = ({
    language,
    goBackHandler,
    children,
    className,
}) => {
    const navigate = useNavigate();
    const { backButtonText } = returnCopyForLanguage(language, copies);

    const onBackClick = (): void =>
        goBackHandler ? goBackHandler() : navigate(-1);

    useEffect(() => {
        if (window) {
            window.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    const backButton = (
        <section className={styles.BackButtonContainer}>
            <button
                className={styles.BackButton}
                onClick={() => {
                    onBackClick();
                }}
                tabIndex={0}
            >
                <ArrowLeft className={styles.LeftArrow} />
                <span>{backButtonText}</span>
            </button>
        </section>
    );

    return (
        <article
            data-testid='component-copy-content'
            className={
                IS_IOS_APP
                    ? classnames(styles.CopyContentIOSAPP, className)
                    : classnames(styles.CopyContent, className)
            }
        >
            {backButton}
            {children}
        </article>
    );
};

export default CopyContent;
