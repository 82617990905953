import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import firebaseConfig from 'utils/firebase';
import { FieldValue, getDocs, query } from 'firebase/firestore';
import { Deck, Question } from '_types';
import { getEnvAPI } from 'api/rest-client';
import { Language } from '_types/language';

// --------------------------------------
// FIREBASE
// --------------------------------------

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

/**
 * Determines the current API domain name to correctly split Collaboration collections in Firebase in order accompany their server data.
 *
 * @returns `'test'` | `'staging'` | `'prod'`
 */
export const getCollabEnv = () => {
    const domainBase = getEnvAPI();

    if (domainBase) {
        const domainName = domainBase
            .replace(new RegExp(/https?:\/\//, 'g'), '')
            .split('.')?.[0];
        if (domainName) return domainName === 'fuelback' ? 'prod' : domainName;
    }

    return 'test';
};

const env = getCollabEnv();

const FIREBASE_COLLABORATION_KEY = 'collaboration';
const FIREBASE_SESSION_KEY = 'sessions';
const FIREBASE_QUESTION_KEY = 'questions';
const FIREBASE_ANSWERS_KEY = 'answers';
const FIREBASE_PARTICIPANTS_KEY = 'participants';

// TODO: Add environment to outer key
// SESSION
export const getSessionDocumentRef = (
    collaborationId: string
): firebase.firestore.DocumentReference<SessionData> => {

    return firestore
        .collection(FIREBASE_COLLABORATION_KEY)
        .doc(env)
        .collection(FIREBASE_SESSION_KEY)
        .doc(
            collaborationId
        ) as firebase.firestore.DocumentReference<SessionData>;
}
// QUESTIONS
export const getQuestionCollectionRef = (
    collaborationId: string
): firebase.firestore.CollectionReference<SessionQuestion> =>
    getSessionDocumentRef(collaborationId).collection(
        FIREBASE_QUESTION_KEY
    ) as firebase.firestore.CollectionReference<SessionQuestion>;

export const getQuestionDocumentRef = (
    collaborationId: string,
    questionId: number
): firebase.firestore.DocumentReference<SessionQuestion> =>
    getQuestionCollectionRef(collaborationId).doc(
        String(questionId)
    ) as firebase.firestore.DocumentReference<SessionQuestion>;

// ANSWERS
export const getAnswersCollectionRef = (
    collaborationId: string,
    questionId: number
): firebase.firestore.CollectionReference<AnswerBase> =>
    getQuestionDocumentRef(collaborationId, questionId).collection(
        FIREBASE_ANSWERS_KEY
    ) as firebase.firestore.CollectionReference<AnswerBase>;

export const getOrderedAnswersCollectionRef = (
    collaborationId: string,
    questionId: number
): firebase.firestore.CollectionReference<AnswerBase> =>
    getAnswersCollectionRef(collaborationId, questionId).orderBy(
        'createdAt',
        'desc'
    ) as firebase.firestore.CollectionReference<AnswerBase>;
export const DeleteAnswerDocument = async (
    collaborationId: string,
    questionId: number,
    textToDelete: string
) => {
    const answerQuery = await query(
        getAnswersCollectionRef(collaborationId, questionId).where(
            'text',
            '==',
            textToDelete
        )
    );

    const answers = await getDocs(answerQuery);

    answers.forEach(async (doc) => {
        getAnswersCollectionRef(collaborationId, questionId).doc(doc.id).delete;

        await getAnswersCollectionRef(collaborationId, questionId)
            .doc(doc.id)
            .delete()
            .catch((error) => console.error(error));
    });
};

// PARTICIPANTS
export const getParticipantsCollectionRef = (
    collaborationId: string
): firebase.firestore.CollectionReference<Participant> =>
    getSessionDocumentRef(collaborationId).collection(
        FIREBASE_PARTICIPANTS_KEY
    ) as firebase.firestore.CollectionReference<Participant>;

export const getParticipantsCollectionRefForSession = (
    collaborationId: string,
    collaborationCode?: string
): firebase.firestore.Query<Participant> =>
    getParticipantsCollectionRef(collaborationId)
        .where('collaborationCode', '==', collaborationCode)
        .orderBy('joinedAt', 'desc');

// --------------------------------------
// TYPES
// --------------------------------------

// FIXME: Work in progress (types are wrong)

export enum DisplayAs {
    WORDCLOUD = 'wordcloud',
    FREE_TEXT = 'freeText',
}

export type Participant = {
    collaborationCode: string;
    displayName: string;
    id: string;
    joinedAt: firebase.firestore.Timestamp;
};

export type AnswerBase = {
    createdAt: FieldValue;
    text: string;
    user: Omit<Participant, 'collaborationCode' | 'joinedAt'>;
};

export type Answer = AnswerBase & { id: string };

export type Wordcloud = {
    svg: string;
    /**
     * BBox value
     */
    height: number;
    /**
     * BBox value
     */
    width: number;
    /**
     * The number of words included (or attempted included) in the wordcloud
     */
    answerCount: number;
};

export type SessionQuestion = Pick<Question, 'text' | 'id' | 'slug'> & {
    display: DisplayAs;
    /**
     * Serialized SVG
     */
    wordcloud?: Wordcloud;
    /**
     * Question index
     */
    index: number;
};

export type SessionParams = {
    /**
     * Collaboration slug
     */
    collaborationSlug: string;
    questionSlug?: string;
};

export enum SlideType {
    QUESTION = 'question',
    END = 'end',
    START = 'start',
}

export type ActiveContent =
    | {
          question: Question;
          type: SlideType.QUESTION;
      }
    | { type: SlideType.END }
    | { type: SlideType.START };

export type AnsweredQuestion = {
    id: number;
    index: number;
};

export type SessionData = {
    activeContent?: ActiveContent;
    /**
     * Base data about the answered question with the highest index (or the first question in the conversation)
     */
    answeredQuestionWithHighestIndex?: SessionQuestion;
    /**
     * IDs and indices of answered questions
     */
    answeredQuestions: AnsweredQuestion[];
    /**
     * Snapshot of the conversation and questions as they were when the collaboration was created
     */
    conversation: {
        deck: Deck;
        questions: Question[];
    };
    /**
     * If `true`, participant nams will be displayed on screen and in the report
     */
    displayParticipantNames?: boolean;
    /**
     * The collaboration language
     */
    language: Language;
    /**
     * Total number of distinct participants
     */
    participantCount: number;
    /**
     * Initially `false`, but set to `true` when the session is started
     */
    started?: boolean;
};

export const isFinished = (
    questionCount: number,
    answeredQuestions?: AnsweredQuestion[]
) => {
    const answeredQuestionCount = answeredQuestions?.length ?? 0;

    return (
        answeredQuestionCount != null &&
        questionCount &&
        answeredQuestionCount === questionCount
    );
};

export const isUnfinished = (
    questionCount: number,
    answeredQuestions?: AnsweredQuestion[]
) => {
    const answeredQuestionCount = answeredQuestions?.length ?? 0;

    return (
        answeredQuestionCount != null &&
        questionCount &&
        answeredQuestionCount < questionCount
    );
};

// Removes all null values
export const sanitize = <T>(obj: T): T =>
    JSON.parse(
        JSON.stringify(obj, (_, value) => (value == null ? undefined : value))
    );
