import React from 'react';
import classnames from 'classnames';
import styles from './container.module.scss';
import { useWindowSize } from 'hooks';
import { Box, BoxProps } from 'components/base';

type Props = BoxProps & {
    className?: string;
    children?: React.ReactNode;
    size?: 'medium' | 'small' | 'xs';
    layout?: 'fluid' | 'fixed';
    isRandomQuestions?: boolean;
};

const Container: React.FC<Props> = ({
    className,
    children,
    size = 'medium',
    layout = 'fluid',
    isRandomQuestions,
    ...rest
}: Props): React.ReactElement => {
    const { height } = useWindowSize(window);

    return isRandomQuestions ? (
        <Box
            className={classnames(
                styles.container,
                className,
                styles[size],
                styles[layout]
            )}
            style={{
                height: height + 'px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            {...rest}
        >
            {children}
        </Box>
    ) : (
        <Box
            className={classnames(
                styles.container,
                className,
                styles[size],
                styles[layout]
            )}
            {...rest}
        >
            {children}
        </Box>
    );
};
export default Container;
