import TagManager from 'react-gtm-module';

export enum GTM_EVENT {
    PAGEVIEW = 'pageview',
    SELECT_DECK = 'select_deck',
    CLOSE_DECK = 'closed',
    GA_EVENT = 'GA Event',
}

export const initGtm = () => {
    if (!process.env.REACT_APP_GTM_ID) {
        return;
    }

    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_ENV,
        dataLayerName: 'dataLayer',
    });
};

export const gtmEvent = (
    eventName: GTM_EVENT,
    data: Record<string, unknown> = {}
): void => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...data,
        },
    });
};
