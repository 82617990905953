import * as React from 'react';
import styles from './payment-create-confirmation.module.scss';
import { Button } from 'components/base';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';
import {
    SaveHandle,
    UserNewsletterCheckbox,
} from 'components/user-newsletter-checkbox';
import { useRef } from 'react';

type Props = {
    continueHandler: () => void;
};
interface ViewCopy {
    title: string;
    message: string;
    buttonText: string;
}

const nbCopy: ViewCopy = {
    title: 'Velkommen!',
    message:
        'Vi har registrert betalingen din.\nNå kan du ta i bruk din digitale FuelBox.',
    buttonText: 'Kom i gang',
};

const enCopy: ViewCopy = {
    title: 'Welcome!',
    message:
        "We have received your payment.\nYou're ready to start using your digital FuelBox.",
    buttonText: 'Get started',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const PaymentCreateConfirmation: React.FC<Props> = ({
    continueHandler,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const { title, message, buttonText } = returnCopyForLanguage(
        language,
        copies
    );

    const ref = useRef<SaveHandle>(null);
    const onContinue = (): void => {
        ref.current!.save().then(() => {
            continueHandler();
        });
    };

    return (
        <article
            data-testid='component-payment-confirmation'
            className={styles.PaymentCreateConfirmation}
        >
            <section className={styles.Content}>
                <h1>{title}</h1>
                <p>{message}</p>
                <UserNewsletterCheckbox ref={ref} />
                <section className={styles.Actions}>
                    <Button
                        appearance='cta'
                        fullWidth={true}
                        onClick={onContinue}
                    >
                        {buttonText}
                    </Button>
                </section>
            </section>
        </article>
    );
};

export default PaymentCreateConfirmation;
