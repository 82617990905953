export enum AuthRoles {
    UNAUTHENTICATED,
    /**
     * Authenticated, inactive subscription
     */
    INACTIVE,
    /**
     * Active personal subscription
     */
    PREMIUM,
    /**
     * Active organization subscription
     */
    PRO,
}

// TODO: Add actions
export enum PermissionActionTypes {
    COLLABORATION,
    FAVOURITE,
    FREEMIUM,
    JOIN_COLLABORATION,
}

export type PermissionMap = Record<AuthRoles, PermissionActionTypes[]>;
