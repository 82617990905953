import * as React from 'react';
import styles from './create-conversation-page.module.scss';
import { Button } from 'components/button';
import { SEO } from 'components/seo';
import { preventDefault } from 'services/visual-style.service';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';
import { BackButton } from 'components/back-button';
import conversationClient, { MyConversation } from 'api/conversation-client';
import { deckParentPages, Question } from '_types';
import { ReactComponent as IconCheck } from './../../images/icons/check-blue.svg';
import { ReactComponent as AddIcon } from './../../images/icons/addIcon.svg';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import SelectQuestionBox from 'components/select-question-box/select-question-box';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import { setMyConversations } from 'store-modules/my-conversations';
import { Loading } from 'components/loading';
import { useNavigate } from 'react-router-dom';
import useConversationSelector from 'hooks/use-conversation-selector';
import useTypedLocation from 'hooks/use-typed-location';
import { setToastMessage } from 'store-modules/user';
import { IS_IOS_APP } from './../../constants';
import classNames from 'classnames';

interface LocationState {
    questionToAdd: Question;
    toastMessageText: string;
}

interface ViewCopy {
    titleLabel: string;
    descriptionLabel: string;
    descriptionPlaceHolder: string;
    colorLabel: string;
    createLabel: string;
    addQuestions: string;
    addFromLiked: string;
    titleErrorText: string;
}

const nbCopy: ViewCopy = {
    titleLabel: 'Tittel',
    descriptionLabel: 'Beskrivelse',
    descriptionPlaceHolder: 'Legg til en valgfri beskrivelse',
    colorLabel: 'Farge på samtalen',
    createLabel: 'Opprett samtale',
    addQuestions: 'Legg til spørsmål',
    addFromLiked: 'Legg til spørsmål fra likte',
    titleErrorText: 'Gi samtalen en tittel',
};

const enCopy: ViewCopy = {
    titleLabel: 'Title',
    descriptionLabel: 'Description',
    descriptionPlaceHolder: 'Legg til en valgfri beskrivelse',
    colorLabel: 'Conversation colour',
    createLabel: 'Create conversation',
    addQuestions: 'Add questions',
    addFromLiked: 'Add questions from liked',
    titleErrorText: 'Give the conversation a title',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const CreateConversationPage: React.FC = () => {
    const language: Language = useSelector(languageSelector);
    const {
        addFromLiked,
        addQuestions,
        colorLabel,
        createLabel,
        descriptionLabel,
        descriptionPlaceHolder,
        titleErrorText,
        titleLabel,
    } = returnCopyForLanguage(language, copies);
    const dispatch = useDispatch();
    const location = useTypedLocation<LocationState>();
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColor, setSelectedColor] = useState<number>(1);
    const [selectedQuestions, setSelectedQuestions] = useState<Question[]>(
        location?.state?.questionToAdd ? [location.state.questionToAdd] : []
    );
    const { selectConversation } = useConversationSelector();
    const [isSelectingQuestions, setIsSelectingQuestions] =
        useState<boolean>(false);
    const [titleInput, setTitleInput] = useState<string>('');
    const [descriptionInput, setDescriptionInput] = useState<string>('');
    const [invalidTitleInput, setInvalidTitleInput] = useState<boolean>(false);
    const [invalidDescriptionInput, setInvalidDescriptionInput] =
        useState<boolean>(false);
    const navigate = useNavigate();

    const maxDescriptionLength = 64;
    const maxTitleLength = 42;

    //If selected question in state is in favourites - remove from list and add it to the top
    const questionsToSelectFrom = favouriteQuestions
        ? [
              ...favouriteQuestions.filter(
                  (c) => c.id !== location?.state?.questionToAdd?.id
              ),
          ]
        : [];
    location?.state?.questionToAdd &&
        questionsToSelectFrom.unshift(location.state.questionToAdd);

    useEffect(() => {
        window.scrollTo(0, 0);
        async function fetchFavourites(): Promise<void> {
            Promise.all([conversationClient.getMyFavouriteQuestions()]).then(
                (response) => {
                    dispatch(setFavouriteQuestions(response[0].reverse()));
                }
            );
        }
        if (!favouriteQuestions) {
            fetchFavourites();
        }
    }, [favouriteQuestions, dispatch]);

    const onSubmit = () => {
        setIsLoading(true);
        //Validate input
        if (titleInput.length < 1) {
            setIsSelectingQuestions(false);
            setInvalidTitleInput(true);
            setIsLoading(false);
        }
        //Create conversation
        else {
            const selectedQuestionIds = selectedQuestions.map((q) => {
                return { question_id: q.id };
            });

            const conversationToCreate: MyConversation = {
                name: titleInput,
                description: descriptionInput,
                color_option: selectedColor,
                public: false,
                pro_conversation: false,
                questions: selectedQuestionIds,
            };

            conversationClient
                .createConversation(conversationToCreate)
                .then((createdConversation) => {
                    conversationClient.getMyConversations().then((response) => {
                        dispatch(setMyConversations(response.reverse()));
                        if (
                            location.state?.toastMessageText &&
                            location.state?.toastMessageText?.length > 0
                        ) {
                            dispatch(
                                setToastMessage(
                                    location.state.toastMessageText +
                                        '\u00AB' +
                                        createdConversation.name +
                                        '\u00BB'
                                )
                            );
                            navigate(-2);
                        } else {
                            selectConversation(
                                createdConversation,
                                language,
                                deckParentPages.myLibrary
                            );
                        }

                        setIsLoading(false);
                    });
                });
        }
    };

    const navigateBack = () => {
        isSelectingQuestions ? setIsSelectingQuestions(false) : navigate(-1);
    };

    const toggleQuestion = (question: Question) => {
        if (selectedQuestions?.includes(question)) {
            setSelectedQuestions(
                [...selectedQuestions].filter((q) => {
                    if (q.id !== question.id) {
                        return q;
                    }
                })
            );
        } else {
            const copyOfSelectedQuestions = [...selectedQuestions];
            copyOfSelectedQuestions.push(question);
            setSelectedQuestions(copyOfSelectedQuestions);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div
            className={styles.CreateConversationPageContainer}
            id='CreateConversationPageContainer'
        >
            <div className={styles.Container}>
                <section
                    className={classNames(
                        styles.BackButtonContainer,
                        IS_IOS_APP && styles.BackButtonContaineriOS,
                        isSelectingQuestions && styles.StickyBackButton
                    )}
                    id='BackButtonContainer'
                >
                    <BackButton
                        language={language}
                        className={styles.backButton}
                        clickHandler={navigateBack}
                    />
                </section>
                <div className={styles.bg} />
                <h2 className={styles.title}>
                    {isSelectingQuestions ? addQuestions : createLabel}
                </h2>
                <div className={styles.InnerContainer}>
                    <section className={styles.CreateConversationPage}>
                        <SEO title={createLabel} />

                        {isSelectingQuestions ? (
                            <div className={styles.QuestionBoxContainer}>
                                {questionsToSelectFrom?.map((question) => {
                                    return (
                                        <SelectQuestionBox
                                            className={styles.QuestionBox}
                                            question={question}
                                            key={question.id}
                                            selectedQuestions={
                                                selectedQuestions
                                            }
                                            onClick={() =>
                                                toggleQuestion(question)
                                            }
                                        />
                                    );
                                })}{' '}
                            </div>
                        ) : (
                            <section
                                className={styles.CreateConversationContainer}
                            >
                                <label
                                    className={styles.TitleLabel}
                                    htmlFor={titleLabel}
                                >
                                    {titleLabel}
                                </label>
                                <input
                                    name='title'
                                    onChange={({ currentTarget: { value } }) =>
                                        setTitleInput(value)
                                    }
                                    onFocus={() => setInvalidTitleInput(false)}
                                    type='text'
                                    placeholder=''
                                    defaultValue={titleInput}
                                    className={classnames(
                                        styles.titleField,
                                        invalidTitleInput && styles.InvalidInput
                                    )}
                                    maxLength={maxTitleLength}
                                />
                                <div
                                    className={
                                        invalidTitleInput
                                            ? styles.TitleErrorAndCounter
                                            : styles.OnlyCounter
                                    }
                                >
                                    {invalidTitleInput && (
                                        <p className={styles.TitleErrorText}>
                                            {titleErrorText}
                                        </p>
                                    )}
                                    <p className={styles.Counter}>
                                        {titleInput.length +
                                            '/' +
                                            maxTitleLength}
                                    </p>
                                </div>

                                <label
                                    htmlFor={descriptionLabel}
                                    className={styles.DescriptionLabel}
                                >
                                    {descriptionLabel}
                                </label>

                                <textarea
                                    name='description'
                                    onChange={({ currentTarget: { value } }) =>
                                        setDescriptionInput(value)
                                    }
                                    onFocus={() =>
                                        setInvalidDescriptionInput(false)
                                    }
                                    placeholder={descriptionPlaceHolder}
                                    defaultValue={descriptionInput}
                                    className={classnames(
                                        styles.InputRow__Input,
                                        styles.descriptionField,
                                        invalidDescriptionInput &&
                                            styles.InvalidInput
                                    )}
                                    maxLength={maxDescriptionLength}
                                />
                                <p className={styles.Counter}>
                                    {descriptionInput.length +
                                        '/' +
                                        maxDescriptionLength}
                                </p>

                                <label
                                    className={styles.ColorLabel}
                                    htmlFor={colorLabel}
                                >
                                    {colorLabel}
                                </label>
                                <div className={styles.customRadios}>
                                    <div>
                                        <input
                                            type='radio'
                                            className={styles.color1}
                                            name='color'
                                            value={1}
                                            checked={selectedColor === 1}
                                            onChange={() => {
                                                setSelectedColor(1);
                                            }}
                                        />
                                        <label htmlFor='color1'>
                                            <button
                                                onClick={(e) => {
                                                    preventDefault(e);
                                                    setSelectedColor(1);
                                                }}
                                            >
                                                <IconCheck
                                                    className={styles.IconCheck}
                                                />
                                            </button>
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            type='radio'
                                            className={styles.color2}
                                            name='color'
                                            value={2}
                                            checked={selectedColor === 2}
                                            onChange={() => {
                                                setSelectedColor(2);
                                            }}
                                        />
                                        <label htmlFor='color2'>
                                            <button
                                                onClick={(e) => {
                                                    preventDefault(e);
                                                    setSelectedColor(2);
                                                }}
                                            >
                                                <IconCheck
                                                    className={styles.IconCheck}
                                                />
                                            </button>
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            type='radio'
                                            className={styles.color3}
                                            name='color'
                                            value={3}
                                            checked={selectedColor === 3}
                                            onChange={() => {
                                                setSelectedColor(3);
                                            }}
                                        />
                                        <label htmlFor='color3'>
                                            <button
                                                type='button'
                                                onClick={(e) => {
                                                    preventDefault(e);
                                                    setSelectedColor(3);
                                                }}
                                            >
                                                <IconCheck
                                                    className={styles.IconCheck}
                                                />
                                            </button>
                                        </label>
                                    </div>
                                </div>

                                <div
                                    className={styles.AddQuestionLinkContainer}
                                >
                                    <Button
                                        onClick={() =>
                                            setIsSelectingQuestions(true)
                                        }
                                        className={styles.AddQuestionLink}
                                    >
                                        <AddIcon className={styles.AddIcon} />
                                        {addFromLiked}
                                    </Button>
                                </div>
                            </section>
                        )}
                    </section>
                </div>
                <div
                    className={classnames(
                        styles.ButtonContainer,
                        isSelectingQuestions &&
                            styles.SelectingQuestionsButtonContainer
                    )}
                >
                    <Button
                        form='createConversationForm'
                        appearance='cta'
                        fullWidth={false}
                        className={styles.ConfirmButton}
                        onClick={onSubmit}
                    >
                        {createLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateConversationPage;
