import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, FullscreenLoader, Typography } from 'components/base';
import { useCreateCollaboration } from 'hooks/use-create-collaboration';

import { SessionProgressButton } from './styled';
import useCollaboration from '../../../use-collaboration';

const FinishedSessionInfo: FC = () => {
    const { copyCollaboration } = useCreateCollaboration();
    const { deck, questions, collaborationId } = useCollaboration();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const handleCopyCollaboration = () => {
        setLoading(true);

        copyCollaboration(collaborationId, {
            callbackFn: () => setLoading(false),
            deck,
            questions,
        });
    };

    return loading ? (
        <FullscreenLoader />
    ) : (
        <Fragment>
            <Typography>{t('completed')}</Typography>
            <Divider orientation='vertical' variant='middle' flexItem />

            <SessionProgressButton onClick={handleCopyCollaboration}>
                {t('collaboration.sessionInfo.startNew')}
            </SessionProgressButton>
        </Fragment>
    );
};

export default FinishedSessionInfo;
