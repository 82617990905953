import React, { FC } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';

import SessionView from './components/session-view';
import useHistoryRouter from 'hooks/use-history-router';
import CollaborationBasePage from './collaboration-base-page';

const StaticCollaborationPage: FC = () => {
    const { routes } = useHistoryRouter();
    const navigate = useNavigate();

    return (
        <CollaborationBasePage
            onCloseModal={() => navigate(routes.collaborationOverview)}
        >
            <Routes>
                <Route path=':questionSlug' element={<SessionView />} />
                <Route index element={<SessionView />} />
            </Routes>
        </CollaborationBasePage>
    );
};

export default StaticCollaborationPage;
