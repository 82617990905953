import React from 'react';
import classnames from 'classnames';

import { Link } from 'components/link';
import { Logo } from 'components/base';
import styles from './page-header.module.scss';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Container } from 'components/container';
import Appbar from '../appbar/appbar';
import { useWindowSize } from 'hooks';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'store-modules/auth';
import {
    isPremiumSelector,
    subscriptionSelector,
    userSelector,
} from 'store-modules/user';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';

export interface Props {
    className?: string;
    withLogin?: boolean;
    withAccount?: boolean;
    withAppbar?: boolean;
    showLogoLink?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customNavigation?: React.ComponentType<any>;
    hideHeaderContainer?: boolean;
    usingProCode?: boolean;
}
interface ViewLanguageConfig {
    loginLinkText: string;
    routes: RouteList;
}

const nbCopy: ViewLanguageConfig = {
    loginLinkText: 'Logg inn',
    routes: routesNO,
};

const enCopy: ViewLanguageConfig = {
    loginLinkText: 'Log in',
    routes: routesEN,
};

const copies: { name: Language; copy: ViewLanguageConfig }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const PageHeader: React.FC<Props> = (props) => {
    const language: Language = useSelector(languageSelector);
    const { loginLinkText, routes } = returnCopyForLanguage(language, copies);
    const authenticated = useSelector(authenticatedSelector);
    const isPremium = useSelector(isPremiumSelector);
    const { view } = useWindowSize(window);
    const activeSubscription = !!useSelector(subscriptionSelector);
    const user = useSelector(userSelector);

    const {
        withLogin = false,
        withAccount = true,
        withAppbar = true,
        showLogoLink = false,
        customNavigation: CustomNavigation,
        hideHeaderContainer = false,
        usingProCode = false,
    } = props;

    const isMobile = view === 'mobile';
    const showLogin = withLogin && !authenticated;
    const showAccount = withAccount && authenticated && !isPremium;
    const showAppbar = withAppbar && authenticated && isPremium;
    const centered = !showLogin && !showAccount && (!showAppbar || isMobile);
    const linkToHomePage = (
        <Link
            className={styles.link}
            title='Link to homepage'
            to={routes.index}
        >
            <Logo title='Link to homepage' />
        </Link>
    );

    const headerContainer = (
        <Container
            className={classnames(
                styles.container,
                centered && styles.centered
            )}
        >
            {(!isMobile || showLogoLink) && linkToHomePage}
            {CustomNavigation && (
                <CustomNavigation disableTryFreemium={usingProCode} />
            )}
            {!CustomNavigation &&
                ((activeSubscription && authenticated && user?.isConfirmed) ||
                    !authenticated) && (
                    <section aria-label='navigation'>
                        {showLogin && (
                            <Link
                                to={routes.login}
                                title='Link to login'
                                className={styles.Login}
                            >
                                {loginLinkText}
                            </Link>
                        )}
                        {showAccount && (
                            <div className={styles.iconBox}>
                                <Link
                                    to={routes.account.index}
                                    title='Link to account'
                                    className={styles.accountLink}
                                ></Link>
                            </div>
                        )}
                        {showAppbar && <Appbar routes={routes} />}
                    </section>
                )}
        </Container>
    );

    return (
        <header className={styles.header} id='header'>
            {!hideHeaderContainer && headerContainer}
        </header>
    );
};

export default React.memo(PageHeader);
