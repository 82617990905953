import React from 'react';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'store-modules/auth';
import { subscriptionSelector } from 'store-modules/user';
import InactiveSubscriptionRoutes from './inactive-subscription-routes';
import PublicRoutes from './public-routes';

interface Props {}

/**
 * All routes for freemium separated by authenticated.
 * Authenticated is freemium-loggedin
 */
const FreemiumRoutes: React.FC<Props> = () => {
    const authenticated = useSelector(authenticatedSelector);
    const activeSubscription = !!useSelector(subscriptionSelector);
    const isFreemiumLoggedIn = authenticated && !activeSubscription;

    // Detect context; complete subscription, public, freemium loggedin
    const routesToUse = isFreemiumLoggedIn ? (
        <InactiveSubscriptionRoutes />
    ) : (
        <PublicRoutes />
    );

    return <>{routesToUse}</>;
};

export default FreemiumRoutes;
