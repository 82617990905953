export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_SCROLL_POS_HOME_PAGE = 'SET_SCROLL_POS_HOME_PAGE';
export const SET_SCROLL_POS_PREMIUM_BROWSE_PAGE =
    'SET_SCROLL_POS_PREMIUM_HOME_PAGE';
export const SET_SCROLL_POS_PRO_HOME_PAGE = 'SET_SCROLL_POS_PRO_HOME_PAGE';
export const SET_SCROLL_POS_PRO_BROWSE_PAGE = 'SET_SCROLL_POS_PRO_BROWSE_PAGE';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_SCROLL_POS_FAVOURITES_PAGE = 'SET_SCROLL_POS_FAVOURITES_PAGE';
export const SET_SCROLL_POS_FAVOURITE_QUESTIONS_PAGE =
    'SET_SCROLL_POS_FAVOURITE_QUESTIONS_PAGE';
export const SET_SCROLL_POS_MY_COLLABORATIONS_PAGE =
    'SET_SCROLL_POS_MY_COLLABORATIONS_PAGE';
export const SET_IS_CREATING_COMPANY = 'SET_IS_CREATING_COMPANY';
