import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { routesNO, RouteList, routesEN } from 'api/routes';
import styles from './pro-code-subpage.module.scss';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { useWindowSize } from 'hooks';
import { AccountSubpage } from 'pages/account-page/subpage';
import { Button } from 'components/base';
import proClient from 'api/pro-client';
import { setUserState, setUserSub, userSelector } from 'store-modules/user';
import userClient from 'api/user-client';
import subscriptionClient from 'api/subscription-client';
import { Loading } from 'components/loading';
import { ProSwitchConfirmationPage } from 'pages/pro-switch-confirmation-page';
import { Subscription } from '_types';
import { useNavigate } from 'react-router-dom';
import { setProAccount } from 'store-modules/professional';

interface ViewCopy {
    pageTitle: string;
    proCodeLabel: string;
    buttonSubmit: string;
    infoText: string;
    invalidCodeError: string;
    whiteListError: string;
    emptyError: string;
    maxedOutProCodeError: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'FuelBox Proff',
    proCodeLabel: 'Bedriftskode',
    buttonSubmit: 'Legg til',
    infoText:
        'Du er nå klar for å knytte din bruker til et Proff-abonnement. Du vil fremdeles ha tilgang til det samme som i dag, i tillegg til din organisasjons innhold.',
    invalidCodeError: 'Denne koden finnes ikke',
    whiteListError:
        'Denne koden kan ikke kobles til din bruker. Du må opprette en ny brukerkonto med din jobb-epost.',
    emptyError: 'Feltet kan ikke være tomt',
    maxedOutProCodeError: 'Maks antall brukere er nådd for denne koden',
};
const enCopy: ViewCopy = {
    pageTitle: 'FuelBox Pro',
    proCodeLabel: 'Company code',
    buttonSubmit: 'Add code',
    infoText:
        "You are ready to connect your userto a Pro subscription. You will get access to your organization's content and keep the content you already enjoy.",
    invalidCodeError: 'This code is not valid',
    whiteListError:
        'This code cannot be connected to your user account. Create a new account with your work email.',
    emptyError: 'Field cannot be empty',
    maxedOutProCodeError:
        'Maximum amount of users for this code has been reached',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const ProCodeSubPage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const user = useSelector(userSelector);
    const {
        pageTitle,
        proCodeLabel,
        buttonSubmit,
        infoText,
        invalidCodeError,
        whiteListError,
        emptyError,
        maxedOutProCodeError,
    } = returnCopyForLanguage(language, copies);
    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';
    const [proCodeInput, setProCodeInput] = useState<string>('');
    const [showInputError, setShowInputError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [switchPerformed, setSwitchPerformed] = useState<boolean>(false);
    const [sub, setSub] = useState<Subscription | null>(null);    
    const dispatch = useDispatch();

    const submitCode = async () => {
        if (!proCodeInput || proCodeInput?.trim()?.length === 0) {
            setShowInputError(true);
            setErrorText(emptyError);
            return;
        } else {
            const canBeRegisteredWithCode = await proClient.canBeRegisteredWithCode(
                proCodeInput
            );
            if (!canBeRegisteredWithCode) {
                setShowInputError(true);
                setErrorText(maxedOutProCodeError);
                return;
            }
        }

        try {
            setIsLoading(true);
            await proClient
                .validateCode(proCodeInput, user!.email)
                .then((response) => {
                    switch (response?.data?.status) {
                        case 404:
                            setShowInputError(true);
                            setErrorText(invalidCodeError);
                            break;
                        case 403:
                            setShowInputError(true);
                            setErrorText(whiteListError);
                            break;
                        default:
                            setShowInputError(true);
                            setErrorText(invalidCodeError);
                            break;
                    }
                });
            
            proClient.switchSubscription(proCodeInput).then(() => {
                Promise.all([
                    userClient.getCurrentUser(),
                    subscriptionClient.getActiveSubscription(),
                ]).then(async ([user, sub]) => {                                     
                    proClient
                        .getOrganization(user.organization)
                        .then((organization) => {                            
                            dispatch(setProAccount(organization));
                            setSwitchPerformed(true);                                                        
                            dispatch(setUserState(user!));                                        
                            dispatch(setUserSub(sub!));                                        
                            setSub(sub);                    
                            navigate(routes.ProSwitchConfirmationPage);
                        });
                });
            })         
        } catch (error) {
            setShowInputError(true);
            setIsLoading(false);
            setErrorText(invalidCodeError);
        }
    };

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setProCodeInput(event.target.value);
        },
        []
    );

    return isLoading ? (
        <Loading />
    ) : switchPerformed && sub ? (
        <ProSwitchConfirmationPage sub={sub} />
    ) : (
        <AccountSubpage
            pageTitle={pageTitle}
            title={pageTitle}
            customBackRoute={
                isMobile ? routes.account.subscription : routes.account.index
            }
        >
            <div className={styles.formContainer}>
                <div className={styles.inputContainer}>
                    <p className={styles.proCodeInputLabel}>{proCodeLabel}</p>
                    <input
                        name='proCode'
                        type='text'
                        className={classnames(
                            styles.proCodeInput,
                            showInputError && styles.proCodeInputError
                        )}
                        value={proCodeInput}
                        onChange={onInputChanged}
                    ></input>
                    {showInputError && (
                        <p className={styles.errorText}>{errorText}</p>
                    )}
                </div>
                <p className={styles.infoText}>{infoText}</p>
                <Button
                    appearance='cta'
                    className={styles.submitCodeButton}
                    onClick={submitCode}
                >
                    {buttonSubmit}
                </Button>
            </div>
        </AccountSubpage>
    );
};

export default ProCodeSubPage;
