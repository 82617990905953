import freemiumClient from 'api/freemium-client';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { Button } from 'components/base';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { useWindowSize } from 'hooks';
import React, { useEffect, useState } from 'react';
import { gtmEvent, GTM_EVENT } from 'utils/tracking-utils';
import { Deck } from '_types/deck';
import FreeDecksList from './free-decks-list';
import styles from './landing-page.module.scss';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'store-modules/auth';
import { getUAInfo } from 'services/user-agent.service';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preventDefault = (e: React.MouseEvent<any>): void => e.preventDefault();
interface ViewCopy {
    pageTitle: string;
    title: string;
    title2: string;
    description: string;
    buyPremiumButtonLabel: string;
    buyProButtonLabel: string;
    proCodeLabel: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Fuelbox',
    title: 'Enda flere gode samtaler',
    title2: '',
    description:
        'Få tilgang til tusenvis av spørsmål og få fart på den gode samtalen når og hvor som helst.',
    buyPremiumButtonLabel: 'Kjøp abonnement',
    buyProButtonLabel: 'FuelBox for jobb og skole',
    proCodeLabel: 'Jeg har en bedriftskode / proffkode',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    title: 'Even more great',
    title2: 'conversations',
    description:
        'Get access to thousands of questions and have great conversations when and where you like.',
    buyPremiumButtonLabel: 'Get subscription',
    buyProButtonLabel: 'FuelBox for work and schools',
    proCodeLabel: 'I have a Company code / Professional code',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        pageTitle,
        title,
        title2,
        description,
        buyPremiumButtonLabel,
        buyProButtonLabel,
        proCodeLabel,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { view } = useWindowSize(window);
    const [decks, setDecks] = useState<Deck[]>([]);
    const authenticated = useSelector(authenticatedSelector);

    useEffect(() => {
        getUAInfo(window.navigator);
        freemiumClient
            .getFreeDecks()
            .then((decks) => {
                setDecks(decks.filter((deck) => !deck.hidden));
            })
            .catch((err) => {
                console.error(err);
                navigate(routes.error);
            });
    }, [navigate, routes]);

    const selectDeck = (deck: Deck): void => {
        gtmEvent(GTM_EVENT.SELECT_DECK, { 'deck.name': deck.name });
        navigate(`freemium/${deck.slug}`, {
            state: { deck },
        });
    };

    const titleBreak =
        language === 'en' && view === 'mobile' ? <br /> : '\u00A0';

    const navigateToRegistrationPage =
        (authenticated: boolean, routes: RouteList) => (): void =>
            navigate(
                authenticated
                    ? routes.paymentOptionSelect
                    : routes.createPremium
            );

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={styles.landingPage}
            headerProps={{
                withLogin: true,
                showLogoLink: true,
            }}
            withFooter={true}
        >
            <SEO title={pageTitle} />
            <div className={styles.bg} />

            <section className={styles.welcomeSection}>
                <Container
                    className={styles.welcomeSectionContainer}
                    size='small'
                >
                    <div className={styles.title}>
                        <h1>
                            {title}
                            {titleBreak}
                            {title2}
                        </h1>
                    </div>
                    <div className={styles.text}>{description}</div>
                    <div className={styles.buttonGroup}>
                        <Button
                            appearance='cta'
                            fullWidth
                            onClick={navigateToRegistrationPage(
                                authenticated,
                                routes
                            )}
                            onMouseDown={preventDefault}
                        >
                            {buyPremiumButtonLabel}
                        </Button>

                        <Button
                            appearance='primary'
                            fullWidth
                            onClick={() => navigate(routes.proProductInfoPage)}
                            onMouseDown={preventDefault}
                        >
                            {buyProButtonLabel}
                        </Button>
                    </div>

                    <section className={styles.proCodeButtonContainer}>
                        <button
                            className={classNames(
                                styles.link,
                                styles.proCodeButton
                            )}
                            onClick={() => {
                                navigate(routes.createPremiumPro);
                            }}
                        >
                            {proCodeLabel}
                        </button>
                    </section>
                </Container>
            </section>
            <section className={styles.freeDecksSection}>
                <Container>
                    <FreeDecksList decks={decks} onSelectDeck={selectDeck} />
                </Container>
            </section>
        </Layout>
    );
};

export default LandingPage;
