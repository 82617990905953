import React, { FC, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { ErrorBoundary } from 'components/error-boundary';

import {
    IconButton,
    IconButtonProps,
} from '../../buttons/icon-button/icon-button';
import styles from './modal.module.scss';
import classNames from 'classnames';

type ModalProps = {
    children: ReactNode;
    className?: string;
    CloseButtonProps?: Partial<IconButtonProps>;
    hideCloseButton?: boolean;
    isClosable?: boolean;
    onExit?: () => void;
    theme?: Theme;
    title?: string;
    titleId: string;
};

const Modal: FC<ModalProps> = ({
    children,
    className,
    CloseButtonProps,
    hideCloseButton,
    isClosable = true,
    onExit,
    theme,
    title,
    titleId,
}) => {
    const { t } = useTranslation();

    const hasLongWords = (text: string | undefined): boolean => {
        if (text === undefined) {
            return false;
        }
        const listOfWords = text.split(' ');

        for (let i = 0; i < listOfWords.length; i++) {
            const word = listOfWords[i];
            if (word.length > 20) {
                return true;
            }
        }
        return false;
    };

    const content = (
        <Fragment>
            {isClosable && onExit && !hideCloseButton && (
                <IconButton
                    aria-label={t('close')}
                    className={styles.closeButton}
                    icon='close'
                    onClick={onExit}
                    {...CloseButtonProps}
                />
            )}

            {title && (
                <header className={styles.header}>
                    <div
                        className={classNames(styles.title, {
                            [styles.BreakWords]: hasLongWords(title),
                        })}
                    >
                        {title}
                    </div>
                </header>
            )}
            {children}
        </Fragment>
    );

    return (
        <ErrorBoundary>
            <Dialog
                aria-labelledby={titleId}
                classes={{ paperFullScreen: styles.modal }}
                fullScreen
                onClose={onExit}
                open
            >
                {theme ? (
                    <ThemeProvider theme={theme}>
                        <Box
                            height={1}
                            width={1}
                            color='text.primary'
                            className={className}
                        >
                            {content}
                        </Box>
                    </ThemeProvider>
                ) : (
                    <Box height={1} width={1} className={className}>
                        {content}
                    </Box>
                )}
            </Dialog>
        </ErrorBoundary>
    );
};

export default Modal;
