import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    CircularProgress,
    Modal,
    styled,
    Typography,
    visuallyHidden,
} from 'components/base';
import { darkTheme } from 'style/theme';
import collaborationClient, { Collaboration } from 'api/collaboration-client';
import {
    AnsweredQuestion,
    getAnswersCollectionRef,
    getQuestionCollectionRef,
    getSessionDocumentRef,
    SessionParams,
    SessionQuestion,
} from 'utils/collaboration-utils';
import { ScreenSize, useScreenSize } from 'hooks/use-screen-size';
import useTypedParams from 'hooks/use-typed-params';

import styles from './collaboration-page.module.scss';
import BgStarParticles from './components/background/bg-star-particles';
import CollaborationProvider from './use-collaboration/provider';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { SEO } from 'components/seo';

type CollaborationBasePageProps = {
    children: ReactNode;
    interactiveMode?: boolean;
    onCloseModal: () => void;
};

const SpinnerContainer = styled(Box)`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const CollaborationBasePage: FC<CollaborationBasePageProps> = ({
    children,
    interactiveMode,
    onCloseModal,
}) => {
    const { t } = useTranslation();
    const { screenSize } = useScreenSize();
    const params = useTypedParams<SessionParams>();
    const { collaborationSlug } = params;

    const auth = getAuth();

    useEffect(() => {
        signInAnonymously(auth);
    }, []);

    const [collaboration, setCollaboration] = useState<Collaboration>();
    const [loading, setLoading] = useState(true);

    const { collaborationId } = collaboration || {};

    useEffect(() => {
        collaborationClient
            .getCollaborationBySlug(collaborationSlug)
            .then(setCollaboration)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (collaborationId && interactiveMode)
            collaborationClient.collaborationLastUpdated(collaborationId);

        // Ping API to update `last_updated` every hour of an active session
        const interval = setInterval(() => {
            if (collaborationId && interactiveMode)
                collaborationClient.collaborationLastUpdated(collaborationId);
        }, 3600000);

        return () => clearInterval(interval);
    }, [collaborationId]);

    const initializeSessionData = async () => {
        if (collaborationId) {
            const sessionRef = getSessionDocumentRef(collaborationId);

            const questionData = await getQuestionCollectionRef(collaborationId)
                .orderBy('index', 'desc')
                .get()
                .then((snapshots) => {
                    const tmp: SessionQuestion[] = [];
                    snapshots.forEach((doc) => tmp.push(doc.data()));
                    return tmp;
                });

            if (questionData && questionData.length > 0) {
                const answeredQuestions: AnsweredQuestion[] = await Promise.all(
                    questionData.map(
                        ({ id: questionId, index: questionIndex }) =>
                            getAnswersCollectionRef(collaborationId, questionId)
                                .get()
                                .then((snapshot) => ({
                                    id: questionId,
                                    index: questionIndex,
                                    hasAnswers: !snapshot.empty,
                                }))
                    )
                ).then((r) =>
                    r.reduce(
                        (acc: AnsweredQuestion[], { hasAnswers, id, index }) =>
                            hasAnswers ? [...acc, { id, index }] : acc,
                        []
                    )
                );

                const answeredQuestionWithHighestIndex = questionData.find(
                    (q) => q.index === (answeredQuestions[0]?.index ?? 0)
                );

                sessionRef.set(
                    {
                        answeredQuestions,
                        answeredQuestionWithHighestIndex,
                    },
                    { merge: true }
                );
            }
        }
    };

    useEffect(() => {
        initializeSessionData();
    }, [collaborationId]);

    const modalTitleId = 'collaboration-heading-id';

    return (
        <Modal
            className={styles.collaborationModal}
            // Primary colored close button only when displaying start view on mobile
            CloseButtonProps={
                interactiveMode &&
                screenSize === ScreenSize.MOBILE &&
                !loading &&
                !(params as never)['*']
                    ? { color: 'primary' }
                    : undefined
            }
            onExit={onCloseModal}
            theme={darkTheme}
            titleId={modalTitleId}
        >
            <SEO
                title={[
                    collaboration?.name,
                    t('collaborationOverview.pageTitle'),
                ]
                    .filter((el) => !!el)
                    .join(' | ')}
            />

            <Typography sx={visuallyHidden} id={modalTitleId}>
                {t('collaboration.heading')}
            </Typography>

            <BgStarParticles />

            {loading || !collaboration || !collaborationId ? (
                <SpinnerContainer>
                    <CircularProgress color='inherit' />
                </SpinnerContainer>
            ) : (
                <Box
                    color='text.primary'
                    display='flex'
                    flexDirection='column'
                    height={1}
                >
                    <CollaborationProvider
                        collaboration={collaboration}
                        collaborationId={collaborationId}
                        interactiveMode={interactiveMode}
                    >
                        {children}
                    </CollaborationProvider>
                </Box>
            )}
        </Modal>
    );
};

export default CollaborationBasePage;
