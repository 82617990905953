import { createContext } from 'react';
import { Question } from '_types';
import { AnswerBase } from 'utils/collaboration-utils';
import firebase from 'firebase/compat/app';

export type SessionQuestionContextValues = {
    answers?: AnswerBase[];
    answersRef: firebase.firestore.CollectionReference<AnswerBase>;
    isActive: boolean;
    question: Question;
};

const SessionQuestionContext = createContext<SessionQuestionContextValues>({
    answersRef: {} as firebase.firestore.CollectionReference<AnswerBase>,
    isActive: false,
    question: {} as Question,
});

export default SessionQuestionContext;
