import React, { ElementType, FC, forwardRef, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

import { Icon, IconOption, IconProps } from '../../data-display/icon';

export type ButtonProps = Omit<
    MuiButtonProps,
    | 'action'
    | 'centerRipple'
    | 'classes'
    | 'color'
    | 'component'
    | 'disableElevation'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'endIcon'
    | 'focusRipple'
    | 'focusVisibleClassName'
    | 'LinkComponent'
    | 'onFocusVisible'
    | 'startIcon'
    | 'TouchRippleProps'
    | 'touchRippleRef'
    | 'variant'
> &
    Partial<Pick<LinkProps, 'to' | 'replace'>> & {
        appearance?: 'cta' | 'primary' | 'secondary' | 'text';
        children: ReactNode;
        component?: ElementType;
        leftIcon?: IconOption;
        LeftIconProps?: Partial<IconProps>;
        loading?: boolean;
        rightIcon?: IconOption;
        RightIconProps?: Partial<IconProps>;
    };

export const Button: FC<ButtonProps> = forwardRef(
    (
        {
            appearance = 'primary',
            children,
            leftIcon,
            LeftIconProps,
            loading,
            onClick,
            replace,
            rightIcon,
            RightIconProps,
            ...rest
        },
        ref
    ) => {
        const theme = useTheme();

        const color =
            appearance === 'cta'
                ? 'secondary'
                : appearance === 'secondary'
                ? 'info'
                : theme.palette.mode === 'dark'
                ? 'inherit'
                : 'primary';

        const variant =
            appearance === 'text'
                ? 'text'
                : appearance === 'primary'
                ? 'outlined'
                : 'contained';

        const loadingColor =
            (appearance === 'text' ||
                appearance === 'primary' ||
                rest.disabled) &&
            theme.palette.mode === 'dark'
                ? 'inherit'
                : 'primary';

        return (
            <MuiButton
                color={color}
                aria-busy={loading}
                aria-live={loading ? 'polite' : undefined}
                disableElevation
                endIcon={
                    rightIcon && (
                        <Icon
                            sx={{ opacity: loading ? 0 : undefined }}
                            name={rightIcon}
                            {...(RightIconProps || {})}
                        />
                    )
                }
                onClick={loading ? undefined : onClick}
                ref={ref}
                startIcon={
                    leftIcon && (
                        <Icon
                            sx={{ opacity: loading ? 0 : undefined }}
                            name={leftIcon}
                            {...(LeftIconProps || {})}
                        />
                    )
                }
                sx={{
                    position: 'relative',
                    ...(loading ? { pointerEvents: 'none' } : {}),
                }}
                variant={variant}
                {...(rest.to ? { component: Link, replace } : {})}
                {...rest}
            >
                <Box
                    sx={{ opacity: loading ? 0 : undefined }}
                    alignItems='center'
                    justifyContent='center'
                    display='inline-flex'
                    width={1}
                >
                    {children}
                </Box>

                {loading && (
                    <Box
                        alignItems='center'
                        bottom={0}
                        display='flex'
                        justifyContent='center'
                        left={0}
                        position='absolute'
                        right={0}
                        top={0}
                    >
                        <CircularProgress
                            aria-hidden
                            color={loadingColor}
                            size={rest.size === 'small' ? 20 : 26}
                            thickness={rest.size === 'small' ? 6 : 5}
                        />
                    </Box>
                )}
            </MuiButton>
        );
    }
);
