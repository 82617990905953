import { createContext, Dispatch, SetStateAction } from 'react';

import { RouteList } from 'api/routes';
import { User } from '_types';
import { Language } from '_types/language';

export type HistoryRouterContextValues = {
    conditionallyRedirectOnLogin: (user: User) => void;
    getJoinCollaborationUrl: (
        hideProtocol?: boolean,
        collaborationCode?: string
    ) => string;
    language: Language;
    redirectOnLogin: (user: User) => void;
    redirectOnPayment: (user: User) => void;
    redirectToPayment: boolean;
    routes: RouteList;
    setRedirectToPayment: Dispatch<SetStateAction<boolean>>;
};

const HistoryRouterContext = createContext<HistoryRouterContextValues>({
    conditionallyRedirectOnLogin: () => undefined,
    getJoinCollaborationUrl: () => '',
    language: 'nb',
    redirectOnLogin: () => undefined,
    redirectOnPayment: () => undefined,
    redirectToPayment: false,
    routes: {} as RouteList,
    setRedirectToPayment: () => undefined,
});

export default HistoryRouterContext;
