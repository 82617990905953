import React, { FC, useRef, useEffect, Fragment } from 'react';

import {
    Box,
    Card,
    Grid,
    Typography,
    styled,
    IconButton,
} from 'components/base';
import { AnswerBase } from 'utils/collaboration-utils';

import { gridSpacing } from './utils';
import { colSpans } from './use-window-grid-column-count';
import styles from './session-grid-row.module.scss';

const StyledCard = styled(Card)`
    background: ${({ theme }) => theme.palette.custom.blue[80]};
    border-radius: 2px;
    height: 100%;
`;

type SessionWindowGridRowProps = {
    answers: AnswerBase[];
    displayParticipantNames?: boolean;
    windowWidth: number;
    setSize: (index: number, size: number) => void;
    index: number;
    deleteAnswer: (text: string) => void;
};

/**
 * Grid row for virtualized grid view using `react-window`
 */
const SessionGridRow: FC<SessionWindowGridRowProps> = ({
    answers,
    displayParticipantNames,
    setSize,
    index,
    windowWidth,
    deleteAnswer,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        ref.current &&
            setSize(index, ref.current.getBoundingClientRect().height);
    }, [setSize, index, windowWidth]);

    return (
        <Fragment>
            <Grid ref={ref} container spacing={gridSpacing}>
                {answers.map(
                    ({ text, user: { displayName = '' } = {} }, index) =>
                        text ? (
                            <Grid item key={index} {...colSpans}>
                                <StyledCard key={index}>
                                    <Box
                                        p={2}
                                        width={1}
                                        className={styles.AnswerBox}
                                    >
                                        <Typography
                                            variant='h6'
                                            fontWeight={600}
                                            component='p'
                                        >
                                            {text}
                                        </Typography>
                                        <IconButton
                                            aria-label={'delete answer'}
                                            color='inherit'
                                            icon={'delete'}
                                            onClick={() => {
                                                deleteAnswer(text);
                                            }}
                                            size='small'
                                        />
                                        {displayParticipantNames &&
                                            displayName && (
                                                <Typography variant='caption'>
                                                    {displayName}
                                                </Typography>
                                            )}
                                    </Box>
                                </StyledCard>
                            </Grid>
                        ) : null
                )}
            </Grid>
        </Fragment>
    );
};

export default SessionGridRow;
