import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './search-question-box.module.scss';
import { ConversationWithCategory, Question } from '_types';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { RouteList, routesEN, routesNO } from 'api/routes';
import ToggleConfirmation from 'components/toggle-confirmation/toggle-confirmation';
import conversationClient, { FavouriteQuestion } from 'api/conversation-client';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';
import { useNavigate } from 'react-router-dom';

interface Props {
    allQuestions: Question[];
    allConversations: ConversationWithCategory[];
    viewAllSearchQuestions: boolean;
    children: React.ReactNode;
    className?: string;
    color: string;
    index: number;
    parentPage: string;
    question: Question;
    containerClassName: string;
    searchedText: string;
    isFavourite: boolean;
    favouriteQuestions: Question[] | null;
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface ViewCopy {
    buttonText: string;
    cancelText: string;
    title: string;
}

const nbCopy: ViewCopy = {
    buttonText: 'Fjern Spørsmål',
    cancelText: 'Avbryt',
    title: 'Fjern fra likte',
};

const enCopy: ViewCopy = {
    buttonText: 'Remove Question',
    cancelText: 'Cancel',
    title: 'Remove from liked',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const SearchQuestionBox: React.FC<Props> = ({
    allQuestions,
    allConversations,
    viewAllSearchQuestions,
    children,
    className,
    question,
    containerClassName,
    searchedText,
    isFavourite,
    favouriteQuestions,
    parentPage,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const [isQuestionFavourite, setIsQuestionFavourite] =
        useState<boolean>(isFavourite);
    const [
        showToggleFavouriteConfirmation,
        setShowToggleFavouriteConfirmation,
    ] = useState<boolean>(false);

    const { buttonText, cancelText, title } = returnCopyForLanguage(
        language,
        copies
    );

    useEffect(() => {
        setIsQuestionFavourite(isFavourite);
    }, [isFavourite]);

    const clickQuestionBox = (question: Question) => {
        navigate(routes.questionInfoPage, {
            state: {
                searchString: searchedText,
                question: question,
                viewAllSearchQuestions: viewAllSearchQuestions,
                conversationSearchResult: allConversations,
                questionSearchResult: allQuestions,
                parentPage: parentPage,
            },
        });
    };

    const addQuestionToFavourites = async (question: Question) => {
        setIsQuestionFavourite(true);
        if (favouriteQuestions) {
            const questionsCopy = favouriteQuestions.slice();
            questionsCopy.unshift(question);
            dispatch(setFavouriteQuestions(questionsCopy));
        } else {
            const newQuestionList: Question[] = [question];
            dispatch(setFavouriteQuestions(newQuestionList));
        }
        try {
            const questionToAdd: FavouriteQuestion = {
                question_id: question!.id,
            };

            await conversationClient.addFavouriteQuestion(questionToAdd);
        } catch (err) {
            console.error('Could not add question to favourites', err);
        }
    };

    const toggleFavouriteQuestion = (question: Question) => {
        if (isQuestionFavourite) {
            setShowToggleFavouriteConfirmation(true);
        } else {
            addQuestionToFavourites(question);
        }
    };

    const removeFavouriteQuestion = async (question: Question) => {
        setIsQuestionFavourite(false);
        setShowToggleFavouriteConfirmation(false);
        if (favouriteQuestions) {
            const questionsCopy = favouriteQuestions.slice();
            questionsCopy.splice(questionsCopy.indexOf(question), 1);
            dispatch(setFavouriteQuestions(questionsCopy));
        }
        try {
            await conversationClient.removeFavouriteQuestion({
                question_id: question.id,
            });
        } catch (err) {
            console.error('Could not remove question from favourites', err);
        }
    };

    const content = (
        <div
            className={classNames(
                styles.SearchQuestionCardContainer,
                containerClassName
            )}
        >
            <div
                tabIndex={0}
                role='button'
                className={classnames(styles.SearchQuestionCard, className)}
                onClick={() => clickQuestionBox(question)}
                onKeyDown={(e) => {
                    if (primaryKeyPressed(e)) {
                        clickQuestionBox(question);
                    }
                }}
            >
                <div className={styles.SearchQuestionText}>{children}</div>
            </div>
            <div className={styles.SearchHeartContainerContainer}>
                <div
                    tabIndex={0}
                    role='button'
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleFavouriteQuestion(question);
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                        if (primaryKeyPressed(e)) {
                            toggleFavouriteQuestion(question);
                        }
                    }}
                    className={classnames(
                        containerClassName,
                        styles.SearchHeartContainer
                    )}
                >
                    {isQuestionFavourite ? (
                        <div className={styles.redHeartIcon}></div>
                    ) : (
                        <div className={styles.outLineHeartIcon}></div>
                    )}
                </div>
            </div>
            {showToggleFavouriteConfirmation && (
                <ToggleConfirmation
                    title={title}
                    description={question.text}
                    cancelLabel={cancelText}
                    buttonLabel={buttonText}
                    onSubmit={() => removeFavouriteQuestion(question)}
                    onCancel={() => setShowToggleFavouriteConfirmation(false)}
                ></ToggleConfirmation>
            )}
        </div>
    );

    return content;
};

export default SearchQuestionBox;
