export interface Tree<T> {
    id: string;
    item: T;
    children: Tree<T>[];
}

export interface FlatTreeNode<T> {
    id: string;
    parentId: string;
    item: T;
    childrenIds: string[];
}

export interface FlatTree<T> {
    nodesById: { [key: string]: FlatTreeNode<T> };
    nodeIds: string[];
    rootId: string;
}

interface Config {
    idField: string;
    parentIdField: string;
    rootId: string;
}

const defaultConfig = {
    idField: 'id',
    parentIdField: 'parent',
    rootId: '0',
};

export function buildTreeFromArray<T>(
    items: T[],
    {
        idField = 'id',
        parentIdField = 'parent',
        rootId = '0',
    }: Config = defaultConfig
): Tree<T> {
    const rootNode = {
        id: rootId,
        item: {} as T,
        children: [],
    };

    const nodesById: { [key: string]: Tree<T> } = {
        [rootId]: rootNode,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items.forEach((item: any) => {
        nodesById[item[idField]] = {
            id: item[idField],
            item,
            children: [],
        };
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items.forEach((item: any) => {
        const id = item[idField];
        const parentId = item[parentIdField];

        if (parentId !== undefined) {
            const node = nodesById[id];
            const parentNode = nodesById[parentId];

            parentNode.children.push(node);
        }
    });

    return rootNode as Tree<T>;
}

export function buildFlatTreeFromArray<T>(
    items: T[],
    {
        idField = 'id',
        parentIdField = 'parent',
        rootId = '0',
    }: Config = defaultConfig
): FlatTree<T> {
    const rootNode = {
        id: rootId,
        parentId: '',
        item: {} as T,
        childrenIds: [],
    };

    const nodesById: { [key: string]: FlatTreeNode<T> } = {
        [rootId]: rootNode,
    };

    const nodeIds: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items.forEach((item: any) => {
        nodesById[item[idField]] = {
            id: item[idField],
            parentId: `${item[parentIdField]}`,
            item,
            childrenIds: [],
        };
        nodeIds.push(`${item[idField]}`);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items.forEach((item: any) => {
        const id = item[idField];
        const parentId = item[parentIdField];

        if (parentId !== undefined) {
            const parentNode = nodesById[parentId];
            if (!!parentNode) parentNode.childrenIds.push(id);
        }
    });

    return {
        nodesById,
        nodeIds,
        rootId,
    };
}
