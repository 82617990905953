import React from 'react';
import { ifPrimaryPressed } from 'utils/keyboard-utils';

export interface Props {
    onClick?: () => void;
    className?: string;
    children?: React.ReactNode;
}

const preventDefault = (e: React.MouseEvent): void => {
    e.preventDefault();
};

const Card: React.FC<Props> = ({ onClick, className, children }: Props) => (
    <div
        className={className}
        onMouseDown={preventDefault}
        onClick={onClick}
        onKeyDown={onClick && ifPrimaryPressed(onClick)}
        role='link'
        tabIndex={0}
    >
        {children}
    </div>
);

export default Card;
