export enum CompanyRoleTypes {
    admin = 'admin',
    user = 'user',
}

export interface CompanyUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    memberSince: string;
    role: CompanyRoleTypes;
}

export interface VerifyCompanyCodeResponse {
    code: string,
    message: '' | 'no more users for code' | 'code does not exist',
    data: { status: number },
}
