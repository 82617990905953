export const IS_ANDROID_APP = !!window.navigator.userAgent.match(
    /^Fuelbox\/.+Android$/
)?.length;
export const IS_IOS_APP = !!window.navigator.userAgent.match(/^Fuelbox\/.+iOS$/)
    ?.length;
export const IS_MOBILE_APP = IS_ANDROID_APP || IS_IOS_APP;

export const DEEP_LINK_ANDROID = 'no.fuelbox.fuelbox://app.fuelbox.no/';
export const DEEP_LINK_IOS = 'fuelbox://app.fuelbox.no/';
export const STORE_LINK_ANDROID =
    'https://play.google.com/store/apps/details?id=no.fuelbox.fuelbox';
export const STORE_LINK_IOS =
    'https://apps.apple.com/us/app/fuelbox/id1507438424';
