import * as React from 'react';
import styles from './error-page.module.scss';
import { Container } from 'components/container';
import { PageHeader } from 'components/page-header';
import { Button } from 'components/base';
import { SEO } from 'components/seo';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { PageTitle } from 'components/page-title';
import { preventDefault } from 'services/visual-style.service';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useNavigate } from 'react-router-dom';

interface Action {
    label: string;
    onClick: () => void;
}

type Props = {
    title?: string;
    message?: string;
    label?: string;
    onButtonClick?: () => void;
    renderMessage?: () => React.ReactNode;
    actions?: Action[];
};
interface ViewCopy {
    defaultTitle: string;
    defaultMessage: string;
    defaultLabel: string;
}

const nbCopy: ViewCopy = {
    defaultTitle: 'Oisann!',
    defaultMessage: 'Her har visst noe gått galt.',
    defaultLabel: 'Gå til forsiden',
};

const enCopy: ViewCopy = {
    defaultTitle: 'Oh snap!',
    defaultMessage: 'It looks like something went wrong.',
    defaultLabel: 'Go to front page',
};

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const renderButton = (
    { label, onClick }: Action,
    index: number
): React.ReactNode => (
    <Button
        key={index}
        className={styles.button}
        onClick={onClick}
        onMouseDown={preventDefault}
    >
        {label}
    </Button>
);

const ErrorPage: React.FC<Props> = ({
    title,
    message,
    label,
    onButtonClick,
    renderMessage,
    actions,
}: Props) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { defaultTitle, defaultMessage, defaultLabel } =
        returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const routeToIndex = () => navigate(routes.index);

    title = title ? title : defaultTitle;
    message = message ? message : defaultMessage;
    label = label ? label : defaultLabel;
    onButtonClick = onButtonClick ? onButtonClick : routeToIndex;

    const buttons = actions
        ? actions.map(renderButton)
        : renderButton({ label, onClick: onButtonClick }, 0);

    const messageUi = renderMessage ? (
        renderMessage()
    ) : (
        <p className={styles.text}>{message}</p>
    );

    return (
        <article data-testid='page-error' className={styles.errorPage}>
            <SEO title={title} noIndex={true} />
            <PageHeader withAppbar={false} withAccount={false} />
            <Container className={styles.container}>
                <PageTitle title={title} />
                {messageUi}
                {buttons}
            </Container>
        </article>
    );
};

export default ErrorPage;
