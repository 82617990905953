import { alpha, Card, styled } from 'components/base';

const TopDockedCard = styled(Card)`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${({ theme }) =>
        `${Number(theme.shape.borderRadius) * 2}px`};
    border-bottom-right-radius: ${({ theme }) =>
        `${Number(theme.shape.borderRadius) * 2}px`};
    box-shadow: 0 2px 40px
        ${({ theme }) => alpha(theme.palette.primary.main, 0.4)};
    overflow: visible;
    z-index: 2;
` as typeof Card;

export default TopDockedCard;
