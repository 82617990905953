import React, { FC } from 'react';
import styles from './start-conversation.module.scss';
import { Deck } from '_types/deck';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import { returnCopyForLanguage } from 'services/language-service';
import { subscriptionSelector, userSelector } from 'store-modules/user';
import { Question } from '_types';
import { PermissionActionTypes, useHasPermission } from 'permission';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon, Stack, styled } from 'components/base';
import classnames from 'classnames';
import StartCollaborationBox from './start-session-box';

type StartConversationProps = {
    deck: Deck;
    startHandler: () => void;
    isShuffled: boolean;
    toggleShuffle: () => void;
    addQuestionsFromFavourites?: () => void;
    onSearchClick?: () => void;
    questions?: Question[];
};
interface ViewCopy {
    byText: string;
    addQuestionsText: string;
    searchForQuestionsText: string;
    emptyConversationText: string;
    emptyConversationPublicText: string;
    emptyDescriptionText: string;
    publicText: string;
    privateText: string;
}

const nbCopy: ViewCopy = {
    byText: 'av',
    addQuestionsText: 'Legg til spørsmål fra likte',
    searchForQuestionsText: 'Søk etter spørsmål',
    emptyConversationText: 'Denne samtalen er tom',
    emptyConversationPublicText: 'Offentlig samtale laget av ',
    emptyDescriptionText:
        'Tilpass og endre samtalen i menyen øverst i høyre hjørne.',
    publicText: 'Offentlig',
    privateText: 'Privat',
};

const enCopy: ViewCopy = {
    byText: 'by',
    addQuestionsText: 'Add questions from liked',
    searchForQuestionsText: 'Search for questions',
    emptyConversationText: 'This conversation is empty',
    emptyConversationPublicText: 'Public conversation by ',
    emptyDescriptionText: 'Edit the conversation from the top right menu',
    publicText: 'Public',
    privateText: 'Private',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    { name: 'nb', copy: nbCopy },
    { name: 'en', copy: enCopy },
];

const BottomAreaContainer = styled(Box)`
    margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
    min-height: 34px;
    display: flex;
    align-items: flex-end;

    ${({ theme }) => theme.breakpoints.up('md')} {
        margin-bottom: calc(env(safe-area-inset-bottom) + 40px);
    }
`;

const hasLongWords = (text?: string) =>
    text?.split(' ').some((word) => word.length > 14);

const StartConversation: FC<StartConversationProps> = ({
    deck,
    deck: { description, name },
    startHandler,
    isShuffled,
    toggleShuffle,
    addQuestionsFromFavourites,
    onSearchClick,
    questions,
}) => {
    const language: Language = useSelector(languageSelector);
    const {
        byText,
        addQuestionsText,
        searchForQuestionsText,
        emptyConversationText,
        emptyConversationPublicText,
        emptyDescriptionText,
        publicText,
        privateText,
    } = returnCopyForLanguage(language, copies);

    const user = useSelector(userSelector);
    const sub = useSelector(subscriptionSelector);
    const isProUser = user?.organization && !sub?.data.pro_content_disabled;
    const { t } = useTranslation();
    const hasPermission = useHasPermission();

    const hasQuestions = questions && questions.length > 0;
    const hasDescription = deck?.description?.length === 0;
    const createdByAUser = !!deck && !!deck.created_by_id;
    const createdByThisUser = deck?.created_by_id === user?.id;
    const showEmptyDescriptionText = createdByAUser && hasDescription;
    const showEmptyConversationText = !hasQuestions;

    const displayCollaborationBox = hasPermission(
        PermissionActionTypes.COLLABORATION
    );

    return (
        <article
            data-testid='component-start-conversation'
            className={styles.StartConversation}
        >
            <Box
                flexBasis={
                    !hasQuestions || !displayCollaborationBox ? '50%' : '60%'
                }
            />

            <section
                className={classnames(
                    styles.Overview,
                    deck.created_by_id === user?.id && styles.userDeckOverview
                )}
                aria-labelledby='conversation overview'
            >
                <h1
                    className={classnames({
                        [styles.BreakWords]: hasLongWords(name),
                    })}
                >
                    {name}
                </h1>

                {deck.created_by_id ? (
                    <div>
                        {deck.public ? (
                            <div>
                                {isProUser && createdByThisUser ? (
                                    <p>
                                        {byText} {deck.created_by_name} ·{' '}
                                        {t('questionCount', {
                                            count: questions?.length,
                                        })}
                                        {' · '}
                                        <Icon
                                            className={styles.PublicIcon}
                                            fontSize='small'
                                            name={'public'}
                                        />
                                        {' ' + publicText} {' ('}
                                        {''}
                                        <Icon
                                            className={styles.WorkHomeIcon}
                                            fontSize='small'
                                            name={
                                                deck.pro_conversation
                                                    ? 'work_outlined'
                                                    : 'home_outlined'
                                            }
                                        />
                                        {')'}
                                    </p>
                                ) : (
                                    <p>
                                        {byText} {deck.created_by_name} ·{' '}
                                        {t('questionCount', {
                                            count: questions?.length,
                                        })}
                                        {/* {' · '} */}
                                        {/* <Icon
                                            className={styles.PublicIcon}
                                            fontSize='small'
                                            name={'public'}
                                        /> */}
                                        {/* {' ' + publicText} */}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <p>
                                {' '}
                                {byText} {deck.created_by_name} ·{' '}
                                {t('questionCount', {
                                    count: questions?.length,
                                })}
                                {' · '}
                                <Icon
                                    className={styles.PrivateIcon}
                                    fontSize='small'
                                    name={'lock'}
                                />
                                {' ' + privateText}
                            </p>
                        )}
                    </div>
                ) : (
                    <p>{t('questionCount', { count: questions?.length })}</p>
                )}
            </section>

            {addQuestionsFromFavourites && showEmptyConversationText && (
                <div>
                    <section
                        className={styles.description}
                        aria-labelledby='conversation description'
                    >
                        <p>{emptyConversationText}</p>
                    </section>
                    <section className={styles.options}>
                        <Stack spacing={2} alignItems='flex-start'>
                            <Button
                                leftIcon='add'
                                appearance='text'
                                onClick={addQuestionsFromFavourites}
                            >
                                {addQuestionsText}
                            </Button>

                            <Button
                                leftIcon='search'
                                appearance='text'
                                onClick={onSearchClick}
                            >
                                {searchForQuestionsText}
                            </Button>
                        </Stack>
                    </section>
                </div>
            )}

            {!showEmptyConversationText && showEmptyDescriptionText && (
                <section
                    className={styles.description}
                    aria-labelledby='conversation description'
                >
                    <p>
                        {createdByThisUser
                            ? emptyDescriptionText
                            : emptyConversationPublicText +
                              deck.created_by_name}
                    </p>
                </section>
            )}

            {!showEmptyConversationText && !showEmptyDescriptionText && (
                <section
                    className={classnames(
                        styles.description,
                        hasLongWords(description) && styles.BreakWords
                    )}
                    aria-labelledby='conversation description'
                >
                    <p>{description}</p>
                </section>
            )}

            {hasQuestions && (
                <Stack spacing={{ xs: 3, sm: 5 }} alignItems='center'>
                    <Button
                        appearance='cta'
                        data-testid='button-start'
                        onClick={startHandler}
                    >
                        {t('startConversation')}
                    </Button>

                    <button
                        className={classnames(
                            styles.ShuffleIcon,
                            isShuffled ? styles.Active : styles.Inactive
                        )}
                        onClick={toggleShuffle}
                    >
                        {isShuffled && (
                            <div
                                className={classnames(
                                    styles.CheckIconContainer
                                )}
                            >
                                <div className={styles.CheckIcon}></div>
                            </div>
                        )}
                        <div
                            className={
                                isShuffled
                                    ? styles.ActiveShuffleIcon
                                    : styles.InactiveShuffleIcon
                            }
                        ></div>
                    </button>
                </Stack>
            )}

            <Box
                flexBasis={
                    !hasQuestions || !displayCollaborationBox ? '50%' : '40%'
                }
            />

            {hasQuestions && (
                <BottomAreaContainer>
                    {displayCollaborationBox ? (
                        <StartCollaborationBox
                            deck={deck}
                            questions={questions}
                        />
                    ) : (
                        <p className={styles.ShuffleInfoText}>
                            {t(
                                'conversationView.shuffleQuestions.description',
                                {
                                    context: isShuffled
                                        ? 'shuffled'
                                        : undefined,
                                }
                            )}
                        </p>
                    )}
                </BottomAreaContainer>
            )}
        </article>
    );
};

export default StartConversation;
