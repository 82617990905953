import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';

import { Box, Icon, Stack, useTheme } from 'components/base';
import useHistoryRouter from 'hooks/use-history-router';

import {
    ContentWrapper,
    JoinCode,
    JoinDescription,
    JoinInfoWrapper,
    StyledTopDockedCard,
    JoinLink,
    StartButton,
    StyledAvatar,
} from './styled';
import useCollaboration from '../../../use-collaboration';
import { getSessionDocumentRef } from 'utils/collaboration-utils';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from 'hooks/use-screen-size';

const JoinSessionInfo: FC = () => {
    const { getJoinCollaborationUrl } = useHistoryRouter();

    const {
        collaboration: { collaborationCode, collaborationId },
        questions,
    } = useCollaboration();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const sessionRef = getSessionDocumentRef(collaborationId);

    const qrCodeValue = getJoinCollaborationUrl(false, collaborationCode);

    const handleStartSession = () => {
        sessionRef.update({ started: true });
        navigate(questions[0]?.slug, { replace: true });
    };

    const { breakpoint } = useScreenSize();

    return (
        <JoinInfoWrapper>
            <StyledTopDockedCard>
                <ContentWrapper>
                    <Stack
                        alignItems='center'
                        justifyContent='center'
                        spacing={{ sm: 1, md: 6 }}
                        direction={{
                            xs: 'column',
                            lg: 'row',
                        }}
                    >
                        <Stack
                            spacing={{ sm: 4, md: 6 }}
                            justifyContent='center'
                            direction={{
                                xs: 'column',
                                sm: 'row',
                            }}
                            alignItems='center'
                        >
                            <Box p={{ xs: 2, sm: 0 }} display='flex'>
                                <QRCodeSVG
                                    fgColor={theme.palette.custom.black[20]}
                                    includeMargin={false}
                                    size={breakpoint === 'sm' ? 112 : 128}
                                    value={qrCodeValue}
                                />
                            </Box>

                            <Stack
                                flex={1}
                                alignItems={{
                                    xs: 'center',
                                    sm: 'flex-start',
                                    lg: 'center',
                                }}
                                direction={{
                                    xs: 'column',
                                    lg: 'row',
                                }}
                            >
                                <JoinDescription variant='body2'>
                                    <Trans
                                        components={[
                                            <JoinLink
                                                component='span'
                                                key={1}
                                            />,
                                        ]}
                                        i18nKey='collaboration.join'
                                        values={{
                                            url: getJoinCollaborationUrl(true),
                                        }}
                                    />
                                </JoinDescription>

                                <JoinCode component='span'>
                                    {collaborationCode}
                                </JoinCode>
                            </Stack>
                        </Stack>

                        <StartButton
                            appearance='cta'
                            onClick={handleStartSession}
                        >
                            <StyledAvatar>
                                <Icon
                                    name='play'
                                    color='primary'
                                    fontSize='small'
                                />
                            </StyledAvatar>

                            {t('start')}
                        </StartButton>
                    </Stack>
                </ContentWrapper>
            </StyledTopDockedCard>
        </JoinInfoWrapper>
    );
};

export default JoinSessionInfo;
