import { Button } from 'components/base';
import { InputPasswordRow, InputRow } from 'components/inputs';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import authService from 'services/auth-service';
import { Steps } from '../../../components/steps';
import styles from './create-account-form.module.scss';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { AccountInfo } from '../_types';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import proClient from 'api/pro-client';

interface CreateAccountFormValues {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    proCode?: string;
}

const requiredValidator = (value: string | undefined | null): boolean =>
    !!value && value.length > 0;
const emailValidator = (value: string): boolean =>
    authService.validateEmailFormat(value);
const passwordValidator = (value: string): boolean =>
    authService.validatePasswordFormat(value);

type Props = {
    createAccountHandler: (accountInfo: AccountInfo) => Promise<void>;
    viewTnCHandler: () => void;
    viewPrivacyTermsHandler: () => void;
    onFailure?: () => void;
    proCodeVisible?: boolean;
    proCode?: string;
};

interface ViewCopy {
    title: string;
    alreadyHaveAccount: string;
    login: string;
    labelEmail: string;
    labelPassword: string;
    labelFirstName: string;
    labelLastName: string;
    hintPassword: string;
    terms: string;
    linkTerms: string;
    linkPrivacy: string;
    labelSubmit: string;
    errorRequired: string;
    errorEmail: string;
    usernameTakenError: string;
    labelProCode: string;
    wrongProCodeError: string;
    whiteListProEmailError: string;
    maxedOutProCodeError: string;
}

const nbCopy: ViewCopy = {
    title: 'Opprett bruker',
    alreadyHaveAccount: 'Har du allerede bruker?',
    login: 'Logg inn',
    labelEmail: 'Epostadresse',
    labelPassword: 'Passord',
    labelFirstName: 'Fornavn',
    labelLastName: 'Etternavn',
    hintPassword: 'Bruk både tall og bokstaver, minimum 8 tegn',
    terms: 'Ved å trykke på knappen og opprette bruker godkjenner du',
    linkTerms: 'vilkår for bruk av tjenesten',
    linkPrivacy: 'Personvernerklæring',
    labelSubmit: 'Opprett bruker',
    errorRequired: 'Obligatorisk felt',
    errorEmail: 'Dette ser ikke ut som en fullstendig epostadresse',
    usernameTakenError: 'Denne epostadressen finnes allerede, gå til Logg inn',
    labelProCode: 'Bedriftskode/Proffkode',
    wrongProCodeError: 'Denne koden finnes ikke',
    whiteListProEmailError:
        'Du må bruke jobb-eposten din for å opprette Proff-bruker',
    maxedOutProCodeError: 'Maks antall brukere er nådd for denne koden',
};

const enCopy: ViewCopy = {
    title: 'Create account',
    alreadyHaveAccount: 'Already have an account?',
    login: 'Log in',
    labelEmail: 'Email address',
    labelPassword: 'Password',
    labelFirstName: 'First name',
    labelLastName: 'Last name',
    hintPassword: 'Use numbers and letters, minimum 8 characters',
    terms: 'By clicking the button and creating an account you also accept our',
    linkTerms: 'terms of use',
    linkPrivacy: 'Privacy policy',
    labelSubmit: 'Create account',
    errorRequired: 'Required field',
    errorEmail: "This doesn't look like a complete email address",
    usernameTakenError: 'This email address is already registered, go to Login',
    labelProCode: 'Company code/Professional code',
    wrongProCodeError: 'This code is not valid',
    whiteListProEmailError: 'Use your work email to create a Pro account',
    maxedOutProCodeError:
        'Maximum amount of users for this code has been reached',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CreateAccountForm: React.FC<Props> = ({
    createAccountHandler,
    viewTnCHandler,
    viewPrivacyTermsHandler,
    onFailure,
    proCodeVisible,
    proCode,
}) => {
    const language: Language = useSelector(languageSelector);
    const {
        title,
        alreadyHaveAccount,
        login,
        labelEmail,
        labelPassword,
        labelFirstName,
        labelLastName,
        hintPassword,
        terms,
        linkTerms,
        linkPrivacy,
        labelSubmit,
        errorRequired,
        errorEmail,
        usernameTakenError,
        labelProCode,
        wrongProCodeError,
        whiteListProEmailError,
        maxedOutProCodeError,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);

    const onSubmitForm = async (values: CreateAccountFormValues) => {
        let proCodeError = '';

        if (proCodeVisible) {
            const proCodeVerifyResult = await proClient.verifyCompanyCode(values.proCode ?? '');
            switch (proCodeVerifyResult.message) {
                case '':
                    break;
                case 'no more users for code':
                    proCodeError = maxedOutProCodeError;
                    break;
                case 'code does not exist':
                    proCodeError = wrongProCodeError;
                    break;
            }
        }

        const emailError =
            (!requiredValidator(values.email) && errorRequired) ||
            (!emailValidator(values.email) && errorEmail);
        const firstNameError =
            !requiredValidator(values.firstName) && errorRequired;
        const lastNameError =
            !requiredValidator(values.lastName) && errorRequired;
        const passwordError =
            !passwordValidator(values.password) && hintPassword;

        if (
            emailError ||
            firstNameError ||
            lastNameError ||
            passwordError ||
            proCodeError
        ) {
            return {
                email: emailError,
                firstName: firstNameError,
                lastName: lastNameError,
                password: passwordError,
                proCode: proCodeError,
            };
        }

        try {
            await createAccountHandler(values);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            if (
                err?.data?.status === 404 //the pro code is not valid
            ) {
                return {
                    proCode: wrongProCodeError,
                };
            }
            if (err?.data?.status === 403) {
                //the email address isn't allowed to sign up (organization email domain required)
                return {
                    email: whiteListProEmailError,
                };
            }
            if (err.code === 406) {
                return {
                    email: usernameTakenError,
                };
            }

            onFailure && onFailure();
        }
    };

    const onViewTnC = (): void => viewTnCHandler();
    const onViewPrivacy = (): void => viewPrivacyTermsHandler();

    const MyForm = (
        <Form
            onSubmit={onSubmitForm}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {proCodeVisible && (
                        <Field
                            name='proCode'
                            label={labelProCode}
                            type='text'
                            component={InputRow}
                            defaultValue={proCode}
                        />
                    )}
                    <Field
                        name='email'
                        label={labelEmail}
                        type='email'
                        component={InputRow}
                    />
                    <Field
                        name='firstName'
                        label={labelFirstName}
                        type='text'
                        component={InputRow}
                    />
                    <Field
                        name='lastName'
                        label={labelLastName}
                        type='text'
                        component={InputRow}
                    />
                    <Field
                        name='password'
                        label={labelPassword}
                        language={language}
                        component={InputPasswordRow}
                    />

                    <div className={styles.Form__Info}>
                        <p className={styles.Form__Terms}>
                            {terms}&nbsp;
                            <button
                                type='button'
                                className={styles.ButtonLink}
                                onClick={onViewTnC}
                            >
                                {linkTerms}
                            </button>
                        </p>

                        <p className={styles.Form__Privacy}>
                            <button
                                type='button'
                                className={styles.ButtonLink}
                                onClick={onViewPrivacy}
                            >
                                {linkPrivacy}
                            </button>
                        </p>
                    </div>

                    <Button
                        className={styles.createAccountButton}
                        appearance={'cta'}
                        type='submit'
                        loading={submitting}
                        disabled={submitting}
                    >
                        {labelSubmit}
                    </Button>
                </form>
            )}
        />
    );

    return (
        <article
            data-testid='component-create-account-form'
            className={styles.CreateAccountForm}
        >
            <section className={styles.Steps}>
                <Steps current={2} total={3} language={language} />
            </section>
            <PageTitle title={title} />
            <p className={styles.HasAccount}>
                {alreadyHaveAccount}&nbsp;
                <a href={routes.login}>{login}</a>
            </p>
            {MyForm}
        </article>
    );
};

export default React.memo(CreateAccountForm);
