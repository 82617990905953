import React, { FC, Fragment, ReactNode, useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';
import classNames from 'classnames';
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Global } from '@emotion/react';

import {
    A11yLink,
    Bleed,
    Content,
    InnerContent,
    isSmartDevice,
    Puller,
    pullerAreaHeight,
} from './styled';

export type SwipeableDrawerProps = {
    /**
     * Content on top of the drawer that should always be visible and interactive, even when the drawer is closed
     */
    bleedContent?: ReactNode;
    children: ReactNode;
    /**
     * If `true`, the content will not receive padding. Intended for using containers with different background colors that should stretch to the sides or the bottom
     */
    dense?: boolean;
    id?: string;
};

type SwipeableDrawerSubTypes = {
    InnerContent: typeof InnerContent;
};

export const SwipeableDrawer: FC<SwipeableDrawerProps> &
    SwipeableDrawerSubTypes = ({ bleedContent, children, dense, id }) => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const drawerId = useMemo(() => id ?? nanoid(), []);
    const drawerRootClassName = `swipeable-drawer-root-${drawerId}`;

    const [bleedRef, { height: bleedHeight = 0 } = {}] = useMeasure();
    const [contentRef, { height: contentHeight = 0 } = {}] = useMeasure();

    /**
     * Rendering content and bottomContent alone for measuring content height before rendering the drawer to determine translation, and
     * storing it in a variable as to not break the ref object.
     */
    const content = (
        <Content
            aria-hidden={!drawerOpen}
            className={classNames({ dense, 'not-visible': !contentHeight })}
            ref={contentRef}
        >
            {children}
        </Content>
    );

    if (!contentHeight) return content;

    return (
        <Fragment>
            <Global
                styles={{
                    [`.${drawerRootClassName} > .MuiPaper-root`]: {
                        height: contentHeight,
                    },
                }}
            />

            <A11yLink
                component='button'
                onClick={() => setDrawerOpen((prev) => !prev)}
                sx={{
                    bottom: (theme) =>
                        `calc(${bleedHeight}px + ${theme.spacing(0.5)})`,
                }}
            >
                {t('components.swipeableDrawer.open')}
            </A11yLink>

            <MuiSwipeableDrawer
                anchor='bottom'
                classes={{ root: drawerRootClassName }}
                disableSwipeToOpen={false}
                ModalProps={{ keepMounted: true, 'aria-hidden': false }}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
                open={drawerOpen}
                PaperProps={{
                    sx: {
                        a: { pointerEvents: 'all !important' },
                        borderRadius: 0,
                        button: { pointerEvents: 'all !important' },
                        overflow: 'visible',
                        padding: 0,
                    },
                }}
                swipeAreaWidth={bleedHeight}
                /**
                 * Using conditionally persistent drawer on desktop/laptop to allow tab navigation.
                 * Always using temporary drawer on smart devices to support swiping.
                 */
                variant={
                    isSmartDevice || drawerOpen ? 'temporary' : 'persistent'
                }
            >
                <Bleed
                    ref={bleedRef}
                    {...(isSmartDevice
                        ? {}
                        : { onClick: () => setDrawerOpen((prev) => !prev) })}
                    sx={{
                        top: -bleedHeight,
                        ...(bleedContent
                            ? {
                                  padding: (theme) =>
                                      `${pullerAreaHeight}px ${theme.spacing(
                                          2,
                                          3
                                      )}`,
                              }
                            : { paddingTop: `${pullerAreaHeight}px` }),
                    }}
                >
                    <Puller />
                    {bleedContent}
                </Bleed>

                {content}
            </MuiSwipeableDrawer>
        </Fragment>
    );
};

SwipeableDrawer.InnerContent = InnerContent;
