import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';

import PdfFontDefs from './pdf-font-defs';
import {
    AnswerBase,
    DisplayAs,
    getAnswersCollectionRef,
    getQuestionCollectionRef,
    getSessionDocumentRef,
    SessionQuestion,
} from 'utils/collaboration-utils';
import { lightTheme } from 'style/theme';
import { Collaboration } from 'api/collaboration-client';
import useDateUtils from 'hooks/use-date-utils';
import i18n, { resources } from 'i18n';

pdfMake.vfs = { ...PdfFontDefs };

pdfMake.fonts = {
    SourceSansPro: {
        normal: 'SourceSansPro-Regular.ttf',
        bold: 'SourceSansPro-SemiBold.ttf',
    },
};

// PDF layout variables
const defaultPageWidth = 595.28;
const horizontalMargin = 60;
const verticalMargin = 40;

const contentWidth = defaultPageWidth - horizontalMargin * 2;
const contentMarginSm = verticalMargin / 4;
const contentMargin = verticalMargin / 2;

const stripEmojiSkinTones = (str: string) =>
    str.replace(new RegExp(/\ud83c[\udffb-\udfff]/, 'g'), '');

const useGenerateSessionPdf = ({
    collaborationId,
    name,
    startTime,
}: Collaboration) => {
    const { t } = useTranslation();
    const { formatDate } = useDateUtils();

    const [loading, setLoading] = useState(false);

    const handleError = (error: unknown) =>
        console.error('Error getting documents: ', error);

    const generateSessionPdf = async () => {
        setLoading(true);

        try {
            const questionData = await getQuestionCollectionRef(collaborationId)
                .get()
                .then((querySnapshot) => {
                    const tmpQuestionData: SessionQuestion[] = [];

                    querySnapshot.forEach((doc) =>
                        tmpQuestionData.push(doc.data())
                    );

                    return tmpQuestionData.sort((a, b) =>
                        a.index > b.index ? 1 : -1
                    );
                })
                .catch(handleError);

            const sessionData = await getSessionDocumentRef(collaborationId)
                .get()
                .then((querySnapshot) => querySnapshot.data())
                .catch(handleError);

            const { displayParticipantNames = false, language } =
                sessionData || {};

            let tFn: TFunction = t;

            if (language && Object.keys(resources).includes(language))
                tFn = i18n.getFixedT(language);

            let pdfContent: Array<Content> = [];

            const mapColours = (svg: string): string => {
                const svgWithPrintableColours = svg
                    .replaceAll('fill="#D1E4D0"', 'fill="#60A25D"') //green
                    .replaceAll('fill="rgb(209, 228, 208)"', 'fill="#60A25D"') //green
                    .replaceAll('fill="#FCE8B3"', 'fill="#C49108"') //yellow
                    .replaceAll('fill="rgb(252, 232, 179)"', 'fill="#C49108"') //yellow
                    .replaceAll('fill="#fff"', 'fill="#324D67"') //white/blue
                    .replaceAll('fill="rgb(255, 255, 255)"', 'fill="#324D67"') //white/blue
                    .replaceAll('fill="#F7AFA1"', 'fill="#F2735A"') //red
                    .replaceAll('fill="rgb(247, 175, 161)"', 'fill="#F2735A"'); //red

                // console.log('SVG with mapped colors:', svgWithPrintableColours);

                return svgWithPrintableColours;
            };

            if (questionData) {
                for (const {
                    display,
                    id: questionId,
                    index,
                    text: questionText,
                    wordcloud,
                } of questionData) {
                    pdfContent = [
                        ...pdfContent,
                        {
                            alignment: 'center',
                            style: 'fracture',
                            text: tFn('fraction', {
                                denominator: Object.keys(questionData).length,
                                numerator: index + 1,
                            }),
                            pageBreak: index === 0 ? undefined : 'before',
                        },
                        {
                            alignment: 'center',
                            style: 'question',
                            text: questionText,
                        },
                    ];

                    if (
                        display === DisplayAs.WORDCLOUD &&
                        wordcloud &&
                        wordcloud.width &&
                        wordcloud.height
                    )
                        pdfContent.push({
                            fit: [
                                wordcloud.width < wordcloud.height
                                    ? contentWidth
                                    : Math.min(
                                          wordcloud.width * 0.8,
                                          contentWidth
                                      ),
                                Math.min(wordcloud.height, 600),
                            ],
                            style: 'wordcloud',
                            svg: stripEmojiSkinTones(mapColours(wordcloud.svg)),
                        });
                    else {
                        const answerData = await getAnswersCollectionRef(
                            collaborationId,
                            questionId
                        )
                            .orderBy('createdAt')
                            .get()
                            .then((querySnapshot) => {
                                const tmpData: AnswerBase[] = [];

                                querySnapshot.forEach((doc) =>
                                    tmpData.push(doc.data())
                                );

                                return tmpData;
                            })
                            .catch(handleError);

                        if (answerData && answerData.length > 0)
                            answerData.forEach(
                                ({
                                    user: { displayName },
                                    text: answerText,
                                }) => {
                                    pdfContent.push({
                                        text: stripEmojiSkinTones(answerText),
                                        style: displayParticipantNames
                                            ? 'answer'
                                            : 'paddedAnswer',
                                    });

                                    displayParticipantNames &&
                                        pdfContent.push({
                                            text: stripEmojiSkinTones(
                                                displayName
                                            ),
                                            style: 'displayName',
                                        });
                                }
                            );
                        else
                            pdfContent.push({
                                style: 'emptyState',
                                text: tFn('collaboration.pdf.noAnswers'),
                            });
                    }
                }
            }

            const docDefinition: TDocumentDefinitions = {
                content: pdfContent,
                pageSize: 'A4',
                defaultStyle: {
                    font: 'SourceSansPro',
                    color: lightTheme.palette.text.primary,
                },
                styles: {
                    fracture: {
                        fontSize: 10,
                        margin: [0, 0, 0, contentMarginSm],
                    },
                    question: {
                        fontSize: 16,
                        margin: [0, 0, 0, contentMargin],
                    },
                    answer: {
                        fontSize: 14,
                    },
                    paddedAnswer: {
                        fontSize: 14,
                        margin: [0, 0, 0, contentMargin],
                    },
                    displayName: {
                        fontSize: 10,
                        margin: [0, 0, 0, contentMargin],
                    },
                    emptyState: {
                        alignment: 'center',
                        fontSize: 14,
                        opacity: 0.7,
                    },
                    wordcloud: {
                        alignment: 'center',
                        margin: [0, contentMargin, 0, 0],
                    },
                },
                pageMargins: [horizontalMargin, verticalMargin],
            } as TDocumentDefinitions;

            pdfMake.createPdf(docDefinition).download(
                tFn('collaboration.pdf.fileName', {
                    conversationName: name,
                    startDate: formatDate(startTime),
                })
            );

            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    return { generateSessionPdf, loading };
};

export default useGenerateSessionPdf;
