import React from 'react';
import classnames from 'classnames';
import styles from './favourite-question-box.module.scss';
import { ConversationWithCategory, Question } from '_types';
import { Language } from '_types/language';
import { languageSelector } from 'store-modules/app-settings';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import { returnCopyForLanguage } from 'services/language-service';
import { useSelector } from 'react-redux';
import useConversationSelector from 'hooks/use-conversation-selector';

interface Props {
    allQuestions: Question[];
    children: React.ReactNode;
    className?: string;
    color: string;
    index: number;
    onRemoveQuestion: (question: Question) => void;
    parentPage: string;
    question: Question;
    containerClassName: string;
    onQuestionSelect: () => void;
}

interface ViewCopy {
    deckSlug: string;
    favouriteConversationTitle: string;
    taxonomy: string;
}

const nbCopy: ViewCopy = {
    deckSlug: 'spørsmål',
    favouriteConversationTitle: 'Likte spørsmål',
    taxonomy: 'likte-samtaler',
};

const enCopy: ViewCopy = {
    deckSlug: 'question',
    favouriteConversationTitle: 'Liked Questions',
    taxonomy: 'likte-samtaler',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const FavouriteQuestionBox: React.FC<Props> = ({
    allQuestions,
    children,
    className,
    color,
    index,
    onRemoveQuestion,
    parentPage,
    question,
    containerClassName,
    onQuestionSelect,
}: Props): React.ReactElement => {
    const language: Language = useSelector(languageSelector);
    const { favouriteConversationTitle, taxonomy, deckSlug } =
        returnCopyForLanguage(language, copies);
    const isFavourites = true;
    const { selectMyFavouritesConversation } = useConversationSelector();

    const clickQuestionBox = (question: Question) => {
        onQuestionSelect();
        const favouritesConversation: ConversationWithCategory = {
            id: 0,
            name: favouriteConversationTitle,
            count: 0,
            parent: 0,
            slug: 'liked',
            description: 'liked',
            taxonomy: favouriteConversationTitle,
            category: { slug: '', name: '' },
        };

        selectMyFavouritesConversation(
            favouritesConversation,
            allQuestions,
            question,
            index,
            language,
            parentPage,
            taxonomy,
            deckSlug,
            isFavourites
        );
    };

    const content = (
        <div
            className={
                color === 'dark'
                    ? classnames(
                          styles.favouriteQuestionCardContainer,
                          styles.dark
                      )
                    : classnames(
                          styles.favouriteQuestionCardContainer,
                          styles.bright
                      )
            }
        >
            <div
                tabIndex={0}
                role='button'
                className={
                    color === 'dark'
                        ? classnames(
                              styles.favouriteQuestionCard,
                              className,
                              styles.dark
                          )
                        : classnames(
                              styles.favouriteQuestionCard,
                              className,
                              styles.bright
                          )
                }
                onClick={() => clickQuestionBox(question)}
                onKeyDown={(e) => {
                    if (primaryKeyPressed(e)) {
                        clickQuestionBox(question);
                    }
                }}
            >
                <div className={styles.favouriteQuestionText}>{children}</div>
            </div>
            <div
                className={
                    color === 'dark'
                        ? classnames(
                              styles.FavouriteHeartContainerContainer,
                              styles.dark
                          )
                        : classnames(
                              styles.FavouriteHeartContainerContainer,
                              styles.bright
                          )
                }
            >
                <div
                    tabIndex={0}
                    role='button'
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemoveQuestion(question);
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                        if (primaryKeyPressed(e)) {
                            onRemoveQuestion(question);
                        }
                    }}
                    className={classnames(
                        containerClassName,
                        styles.FavouriteHeartContainer
                    )}
                >
                    {' '}
                    <div className={styles.redHeartIcon}></div>
                </div>
            </div>
        </div>
    );

    return content;
};

export default FavouriteQuestionBox;
