import classNames from 'classnames';
import React from 'react';
import styles from './toast-message.module.scss';

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    message: string;
    className?: string;
    boldMessage?: string;
};

const ToastMessage: React.FC<Props> = ({
    icon,
    message,
    className,
    boldMessage,
}) => {
    return (
        <div className={classNames(styles.toastMessage, className)}>
            {icon} {message} {boldMessage}
        </div>
    );
};

export default ToastMessage;
