import React from 'react';
import { Conversation, ConversationCategory } from '_types';
import { Tree } from 'utils/tree-utils';
import styles from './conversation-tree.module.scss';
import ConversationGroup from '../conversation-group/conversation-group';

interface Props {
    tree: Tree<Conversation>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardComponent: any;
    category?: ConversationCategory;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardProps?: any;
}

const renderNode = (
    node: Tree<Conversation>,
    category: ConversationCategory,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CardComponent: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardProps: any,
    depth = 0
): React.ReactNode => {
    if (node.children.length > 0) {
        return (
            <ConversationGroup
                key={node.item.id}
                isSubgroup={depth !== 0}
                title={node.item.title || node.item.name}
            >
                {node.children.map((node) =>
                    renderNode(
                        node,
                        category,
                        CardComponent,
                        cardProps,
                        depth + 1
                    )
                )}
            </ConversationGroup>
        );
    }

    const { onClick, ...props } = cardProps;

    return (
        <CardComponent
            key={node.item.id}
            name={node.item.name}
            className={styles.Card}
            onClick={(): void => onClick(node.item)}
            category={category}
            {...props}
        />
    );
};

const ConversationTree: React.FC<Props> = ({
    tree,
    cardProps = {},
    category,
    cardComponent,
}: Props) => {
    const branches = tree.children.filter((node) => node.children.length > 0);
    const leaves = tree.children.filter((node) => node.children.length === 0);

    return (
        <React.Fragment>
            {leaves.length > 0 && (
                <ConversationGroup isSubgroup={false} title=''>
                    {leaves.map((node) =>
                        renderNode(
                            node,
                            category ?? node.item,
                            cardComponent,
                            cardProps
                        )
                    )}
                </ConversationGroup>
            )}
            {branches.map((node) =>
                renderNode(
                    node,
                    !!category?.slug ? category : node.item,
                    cardComponent,
                    cardProps
                )
            )}
        </React.Fragment>
    );
};

export default ConversationTree;
