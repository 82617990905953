import { RouteList, routesNO, routesEN } from 'api/routes';
import classNames from 'classnames';
import { Container } from 'components/container';
import { Layout } from 'components/layout';
import React, { useEffect, useState } from 'react';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Language } from '_types/language';
import styles from './pro-account-confirmation-page.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setUserState, userSelector } from 'store-modules/user';
import userClient from 'api/user-client';
import { useWindowSize } from 'hooks';
import { Button } from 'components/base';
import { languageSelector } from 'store-modules/app-settings';
import { IS_MOBILE_APP } from '../../../constants';
import { Loading } from 'components/loading';
import { authenticatedSelector } from 'store-modules/auth';
import useTypedLocation from 'hooks/use-typed-location';
import { useNavigate } from 'react-router-dom';

interface LocationState {
    firstAzureLogin: boolean;
}

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface ViewCopy {
    header: string;
    welcomeHeader: string;
    downloadInfo: string;
    nativeWelcome: string;
    explore: string;
    login: string;
    footer1: string;
    footer2: string;
    bannerHeader: string;
    bannerText: string;
}

const nbCopy: ViewCopy = {
    header: 'Takk!',
    welcomeHeader: 'Velkommen!',
    downloadInfo: 'Last ned vår app for å komme i gang',
    nativeWelcome: 'Du kan nå ta i bruk din digitale FuelBox',
    explore: 'Utforsk samtaler',
    login: 'Fortsett til logg inn',
    footer1: 'Har du allerede installert appen?',
    footer2: 'Nå kan du åpne den og logge inn',
    bannerHeader: 'FuelBox finnes også som app!',
    bannerText: 'Last ned fra App Store eller Google Play',
};
const enCopy: ViewCopy = {
    header: 'Thanks!',
    welcomeHeader: 'Welcome!',
    downloadInfo: 'Download our app to get started',
    nativeWelcome: 'You can now use your digital FuelBox',
    explore: 'Explore conversations',
    login: 'Continue to log in',
    footer1: 'Did you already install the app?',
    footer2: 'You can now open the app and log in',
    bannerHeader: 'Get the app!',
    bannerText: 'Download from App Store or Google Play',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const AccountConfirmationProPage: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const {
        header,
        welcomeHeader,
        downloadInfo,
        nativeWelcome,
        login,
        // footer1,
        // footer2,
        explore,
        bannerHeader,
        bannerText,
    } = returnCopyForLanguage(language, copies);

    const location = useTypedLocation<LocationState>();

    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const authenticated = useSelector(authenticatedSelector);
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchUser(): Promise<void> {
            await userClient.getCurrentUser().then((user) => {
                dispatch(setUserState(user!));
                setIsLoading(false);
            });
            //.catch((err) => navigate(routes.error));
        }
        if (authenticated) {
            fetchUser().then(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    const headerProps = {
        withLogin: false,
        withAccount: true,
        showLogoLink: true,
    };

    return isLoading ? (
        <Loading />
    ) : (
        <Layout
            headerProps={headerProps}
            routes={routes}
            language={language}
            pageClassName={styles.ConfirmProPage}
        >
            <Container className={styles.container}>
                <h1
                    className={
                        IS_MOBILE_APP
                            ? classNames(styles.header, styles.MobileHeader)
                            : styles.header
                    }
                >
                    {location.state?.firstAzureLogin ? welcomeHeader : header}
                </h1>
                {IS_MOBILE_APP ? (
                    <section className={styles.InfoText}>
                        {nativeWelcome}
                    </section>
                ) : (
                    <section className={styles.InfoText}>
                        {downloadInfo}
                    </section>
                )}
                {!isDesktop && IS_MOBILE_APP && (
                    <section>
                        <Button
                            className={styles.MobileContinueButton}
                            onClick={() =>
                                user
                                    ? navigate(routes.proindex)
                                    : navigate(routes.login)
                            }
                        >
                            {user ? explore : login}
                        </Button>
                    </section>
                )}
                {!isDesktop && !IS_MOBILE_APP && (
                    <section>
                        <a
                            href='https://apps.apple.com/us/app/fuelbox/id1507438424?itsct=apps_box_link&itscg=30200'
                            target='_blank'
                            rel='noopener noreferrer'
                            tabIndex={0}
                            role='button'
                            className={classNames(
                                styles.AppStoreBadgeContainer
                            )}
                        >
                            <div className={styles.AppStoreBadge}></div>
                        </a>
                        <a
                            href='https://play.google.com/store/apps/details?id=no.fuelbox.fuelbox&hl=no&gl=US'
                            target='_blank'
                            rel='noopener noreferrer'
                            tabIndex={0}
                            role='button'
                            className={classNames(
                                styles.GooglePlayStoreBadgeContainer
                            )}
                        >
                            <div className={styles.GooglePlayStoreBadge}></div>
                        </a>

                        <section className={styles.proCodeButtonContainer}>
                            <button
                                className={classNames(
                                    styles.link,
                                    styles.LoginLink
                                )}
                                onClick={() => {
                                    if (user) {
                                        navigate(routes.proindex);
                                    } else {
                                        navigate(routes.login);
                                    }
                                }}
                            >
                                {user ? explore : login}
                            </button>
                        </section>
                    </section>
                )}
                {isDesktop && (
                    <section className={styles.DesktopContent}>
                        <Button
                            className={styles.DesktopContinueButton}
                            onClick={() => {
                                if (user) {
                                    navigate(routes.proindex);
                                } else {
                                    navigate(routes.login);
                                }
                            }}
                        >
                            {user ? explore : login}
                        </Button>
                        <section className={styles.BannerContainer}>
                            <section className={styles.BannerLeft}></section>
                            <section className={styles.BannerRight}>
                                <p className={styles.BannerHeader}>
                                    {bannerHeader}
                                </p>
                                <p className={styles.BannerText}>
                                    {bannerText}
                                </p>
                                <section
                                    className={
                                        styles.DesktopStoreButtonsContainer
                                    }
                                >
                                    <a
                                        href='https://apps.apple.com/us/app/fuelbox/id1507438424?itsct=apps_box_link&itscg=30200'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        tabIndex={0}
                                        role='button'
                                        className={classNames(
                                            styles.BannerAppStoreBadgeContainer
                                        )}
                                    >
                                        <div
                                            className={
                                                styles.BannerAppStoreBadge
                                            }
                                        ></div>
                                    </a>
                                    <a
                                        href='https://play.google.com/store/apps/details?id=no.fuelbox.fuelbox&hl=no&gl=US'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        tabIndex={0}
                                        role='button'
                                        className={classNames(
                                            styles.BannerGooglePlayStoreBadgeContainer
                                        )}
                                    >
                                        <div
                                            className={
                                                styles.BannerGooglePlayStoreBadge
                                            }
                                        ></div>
                                    </a>
                                </section>
                            </section>
                        </section>
                    </section>
                )}
                {/* <footer className={styles.infoFooter}>
                    <div>{footer1}</div>
                    <div>{footer2}</div>
                </footer> */}
            </Container>
        </Layout>
    );
};

export default AccountConfirmationProPage;
