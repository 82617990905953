import * as React from 'react';
import styles from './loading.module.scss';
import { useTrail, animated as a } from 'react-spring';
import { ReactComponent as Bubble1 } from './_images/bubble-1.svg';
import { ReactComponent as Bubble2 } from './_images/bubble-2.svg';
import { ReactComponent as Bubble3 } from './_images/bubble-3.svg';
import { Dots } from './dots';

const Loading: React.FC = () => {
    const bottomY = 200;
    const step1 = bottomY - 50;
    const step2 = bottomY - 175;
    const step3 = bottomY - 200;
    const thirdBubbleSpacing = 35;
    const ease = 'ease-in';
    const animatedProps = {
        config: {
            ease,
            mass: 1,
            tension: 300,
            friction: 45,
        },
    };

    const bubble1 = (
        <div className={styles.Bubble__Wrapper}>
            <Bubble1 />
            <section className={styles.Dots}>
                <Dots />
            </section>
        </div>
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trail = useTrail<any>(1, {
        ...animatedProps,
        from: {
            bo1: 0,
            by1: bottomY,
            bo2: 0,
            by2: bottomY,
            bo3: 0,
            by3: bottomY + thirdBubbleSpacing,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        to: async (next: any) => {
            while (1) {
                await next({
                    bo1: 1,
                    by1: step1,
                });
                await next({
                    by1: step2,
                    bo2: 1,
                    by2: step1,
                });
                await next({
                    bo3: 1,
                });
                await next({
                    bo1: 0,
                    by1: step3,
                    by2: step2,
                    by3: step2 + thirdBubbleSpacing * 2,
                });
                await next({
                    by1: bottomY,
                    bo2: 0,
                    by2: step3,
                    by3: step3 + thirdBubbleSpacing * 2,
                });
                await next({
                    by2: bottomY,
                    bo3: 0,
                    by3: step3 + thirdBubbleSpacing,
                });
                await next({
                    by3: bottomY,
                });
            }
        },
    });

    const animation = trail.map(
        ({ bo1, by1, bo2, by2, bo3, by3, ...rest }, i) => (
            <a.section
                className={styles.Bubbles}
                key={`bubble${i}`}
                aria-labelledby='bubbles'
            >
                <a.div
                    className={styles.FirstBubble}
                    style={{
                        ...rest,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        opacity: bo1 ? bo1.to((v: any) => `${v}`) : '',
                        transform: by1
                            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              by1.to((v: any) => `translateY(${v}px)`)
                            : '',
                    }}
                >
                    {bubble1}
                </a.div>
                <a.div
                    style={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        opacity: bo2 ? bo2.to((v: any) => `${v}`) : '',
                        transform: by2
                            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              by2.to((v: any) => `translateY(${v}px)`)
                            : '',
                    }}
                >
                    <Bubble2 />
                </a.div>
                <a.div
                    className={styles.ThirdBubble}
                    style={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        opacity: bo3 ? bo3.to((v: any) => `${v}`) : '',
                        transform: by3
                            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              by3.to((v: any) => `translateY(${v}px)`)
                            : '',
                    }}
                >
                    <Bubble3 />
                </a.div>
            </a.section>
        )
    );

    return (
        <article data-testid='component-loading' className={styles.Loading}>
            {animation}
        </article>
    );
};

export default Loading;
