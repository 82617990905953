import React, { FC, ReactNode } from 'react';

import MenuProvider from './use-menu/provider';
import MenuContext from './use-menu/context';
import useMenu from './use-menu/index';
import { MenuButton } from './menu-button';
import { MenuContent, MenuContentProps } from './menu-content';
import { MenuItem, MenuItemProps } from './menu-item';
import { MenuIconButton } from './menu-icon-button';

export type MenuSubTypes = {
    Button: typeof MenuButton;
    Content: typeof MenuContent;
    IconButton: typeof MenuIconButton;
    Item: typeof MenuItem;
};

export type MenuProps = { children: ReactNode };

export const Menu: FC<MenuProps> & MenuSubTypes = ({ children }) => (
    <MenuProvider>{children}</MenuProvider>
);

Menu.Button = MenuButton;
Menu.Content = MenuContent;
Menu.IconButton = MenuIconButton;
Menu.Item = MenuItem;

export { MenuContext, MenuProvider, useMenu };
export type { MenuContentProps, MenuItemProps };
