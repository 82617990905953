import React, { FC, useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
    getAuth,
    UserCredential,
    signInAnonymously,
    updateProfile,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';

import { CircularProgress } from 'components/base';
import {
    getParticipantsCollectionRef,
    Participant,
} from 'utils/collaboration-utils';
import useTypedParams from 'hooks/use-typed-params';

import SessionContext, { SessionContextValues } from './context';
import SessionView from '../components/main-content/session-view';
import Auth from '../components/main-content/auth';
import { JoinSessionParams } from '../utils';

type SessionProviderProps = { collaborationId: string };

const SessionProvider: FC<SessionProviderProps> = ({ collaborationId }) => {
    const { collaborationCode } = useTypedParams<JoinSessionParams>();
    const auth = getAuth();

    const participantsRef = getParticipantsCollectionRef(collaborationId);
    const [participants] = useCollectionData<Participant>(
        participantsRef as never
    );

    const [hasJoined, setHasJoined] = useState<boolean>();
    const [userCredential, setUserCredential] = useState<UserCredential>();

    useEffect(() => {
        signInAnonymously(auth).then(setUserCredential).catch(console.error);
    }, []);

    useEffect(() => {
        if (userCredential && participants)
            setHasJoined(
                participants?.some(
                    (participant) =>
                        participant.id === userCredential.user.uid &&
                        participant.collaborationCode === collaborationCode
                )
            );
    }, [participants, userCredential]);

    const setDisplayName = (value: string) => {
        if (auth.currentUser)
            updateProfile(auth.currentUser, {
                displayName: value,
            }).then(() =>
                getParticipantsCollectionRef(collaborationId)
                    .doc(auth.currentUser!.uid)
                    .set({
                        collaborationCode: collaborationCode,
                        displayName: value,
                        id: auth.currentUser!.uid,
                        joinedAt:
                            firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
                    })
                    .then(() => setHasJoined(true))
            );
    };

    if (!collaborationId) return <CircularProgress color='inherit' />;

    const values: SessionContextValues = {
        collaborationId,
        displayName: userCredential?.user.displayName ?? undefined,
        hasJoined,
        participants,
        setDisplayName,
        userCredential,
    };

    return (
        <SessionContext.Provider value={values}>
            {userCredential?.user.displayName && hasJoined ? (
                <SessionView />
            ) : (
                <Auth />
            )}
        </SessionContext.Provider>
    );
};

export default SessionProvider;
