import produce, { Draft } from 'immer';
import { MyConversationsState } from './_types';
import { initialState } from './_initialState';
import { SET_MY_CONVERSATIONS, CLEAR_MY_CONVERSATIONS } from './_actionTypes';

// * Implements the producer from ImmerJS.
// Notice that it is not needed to handle the default case, a producer that doesn't do anything will simply return the original state.
const myConversationsReducer = produce(
    (
        draft: Draft<MyConversationsState> = initialState,
        action
    ): MyConversationsState => {
        switch (action.type) {
            case SET_MY_CONVERSATIONS:
                draft.myConversations = action.payload;
                break;
            case CLEAR_MY_CONVERSATIONS:
                draft.myConversations = initialState.myConversations;
                break;
        }
        return draft;
    }
);

export default myConversationsReducer;
