import React, { useCallback, useEffect, useState } from 'react';
import styles from './discount-code-input.module.scss';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';
import { ReactComponent as CloseIcon } from './../../../../../../src/images/icons/close.svg';
import RotatingSpinner from 'components/rotating-spinner/rotating-spinner';
import { Discount } from '../../../_types';
import { getUAInfo } from 'services/user-agent.service';

const transitionDuration = 1000;

type Props = {
    language: Language;
    discount: Discount | undefined;
    onCodeSelected: (text: string) => void;
    onCodeCleared: () => void;
};

enum Status {
    Ready = 'READY',
    Validating = 'VALIDATING',
    Valid = 'VALID',
    Invalid = 'INVALID',
    Clearing = 'CLEARING',
}

interface ViewCopy {
    placeholderText: string;
    addButtonLabel: string;
    discountErrorLabel: string;
}

const nbCopy: ViewCopy = {
    placeholderText: 'Rabattkode',
    addButtonLabel: 'Legg til',
    discountErrorLabel: 'Denne koden er ikke gyldig',
};

const enCopy: ViewCopy = {
    placeholderText: 'Discount code',
    addButtonLabel: 'Add',
    discountErrorLabel: "This code isn't valid",
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const getStatus = (discount: Discount | undefined): Status | null => {
    if (discount?.discountedPrice) return Status.Valid;
    if (discount?.discountError) return Status.Invalid;
    return null;
};

const DiscountCodeInput: React.FC<Props> = ({
    language,
    discount,
    onCodeSelected,
    onCodeCleared,
}: Props) => {
    const { placeholderText, addButtonLabel, discountErrorLabel } =
        returnCopyForLanguage(language, copies);
    const { isiOS } = getUAInfo(window.navigator);

    const [status, setStatus] = useState(Status.Ready);
    const [code, setCode] = useState('');

    useEffect(() => {
        const status = getStatus(discount);
        if (!!status) setStatus(status);
    }, [discount]);

    const reset = useCallback(
        (msDelay: number): void => {
            onCodeCleared();
            setCode('');
            setStatus(Status.Clearing);
            setTimeout(() => {
                setStatus(Status.Ready);
            }, msDelay);
        },
        [onCodeCleared]
    );

    const onSubmitHandler = useCallback(
        async (event: React.FormEvent): Promise<void> => {
            event.preventDefault();

            switch (status) {
                case Status.Ready:
                    if (code.length > 0) {
                        setStatus(Status.Validating);
                        //iOS with only 1 discount code hack
                        if (
                            isiOS &&
                            code.toLocaleLowerCase() !== 'yearly40%off' &&
                            code.toLocaleLowerCase() !== 'invalidDiscountCode'
                        ) {
                            onCodeSelected('invalidDiscountCode');
                            break;
                        }
                        onCodeSelected(code);
                    }
                    break;
                case Status.Validating:
                    // We are waiting for the validation result.
                    break;
                case Status.Valid:
                    reset(transitionDuration);
                    break;
                case Status.Invalid:
                    reset(0);
                    break;
            }
        },
        [code, onCodeSelected, reset, status, isiOS]
    );

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setCode(event.target.value);
        },
        []
    );

    const input = (
        <input
            className={
                status === Status.Valid ? styles.inputValidated : styles.input
            }
            placeholder={placeholderText}
            disabled={status !== Status.Ready}
            type='text'
            name='dicount-code'
            value={code}
            onChange={onInputChanged}
        />
    );

    const background = status !== Status.Invalid && (
        <span
            className={
                status === Status.Valid
                    ? styles.backgroundFull
                    : styles.background
            }
            style={{ transition: `all ${transitionDuration}ms` }}
        />
    );

    const addButton = (status === Status.Ready ||
        status === Status.Clearing ||
        status === Status.Validating) && (
        <button
            className={
                status === Status.Ready
                    ? styles.buttonAddWithHover
                    : styles.buttonAdd
            }
        >
            <div>
                {status === Status.Validating ? (
                    <RotatingSpinner />
                ) : (
                    <>{addButtonLabel}</>
                )}
            </div>
        </button>
    );

    const crossButton = (status === Status.Valid ||
        status === Status.Invalid) && (
        <button className={styles.buttonCross}>
            <CloseIcon />
        </button>
    );

    const discountError = !!discount?.discountError && (
        <div className={styles.error}>{discountErrorLabel}</div>
    );

    const form = (
        <form
            className={
                status === Status.Invalid ? styles.formError : styles.form
            }
            onSubmit={onSubmitHandler}
        >
            {input}
            {background}
            {addButton}
            {crossButton}
        </form>
    );

    return (
        <div className={styles.inputForm}>
            {form}
            {discountError}
        </div>
    );
};

export default DiscountCodeInput;
