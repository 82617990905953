import * as React from 'react';
import styles from './dots.module.scss';

const Dots: React.FC = () => {
    return (
        <div className={styles.Dots} data-testid='component-dots'>
            <span className={styles.Dot}>.</span>
            <span className={styles.Dot}>.</span>
            <span className={styles.Dot}>.</span>
        </div>
    );
};

export default Dots;
