import * as React from 'react';
const titleNO = 'Vilkår for bruk';
const q1 = {
    title: '1 Innledning',
    copy: (
        <React.Fragment>
            <p>
                Disse vilkårene regulerer dine rettigheter og plikter ved kjøp
                av våre digitale abonnement FuelBox Premium og FuelBox Proff. I
                tillegg til disse vilkårene, reguleres kjøpet av relevante
                lovbestemmelser for kjøp av varer mellom næringsdrivende og
                forbrukere på internett.
            </p>
            <p>
                Når du velger å abonnere på FuelBox, inngår du en
                abonnementsavtale med FuelBox AS (heretter kalt «FuelBox»), org.
                nr. 927 868 202.
            </p>
            <p>
                Disse vilkårene suppleres både av FuelBox&nbsp;
                <a href='/personvernerklæring'>Personvernerklæring</a> og av
                vilkårene for bruk av den betalingstjenesten du velger når du
                kjøper et digitalt abonnement. FuelBox It tilbyr følgende
                betalingstjenester:
            </p>
            <ul>
                <li>
                    Bambora:&nbsp;
                    <a href='https://www.bambora.com/no/no/vilkar-og-forskrifter/'>
                        https://www.bambora.com/no/no/vilkar-og-forskrifter/
                    </a>
                </li>
                <li>Fakturakjøp (kun for FuelBox Proff)</li>
                <li>Kjøp i app (App Store & Google Play)</li>
                <li>Apple Pay (for Apple enheter i Safari nettleser)</li>
            </ul>
            <p>
                Vi oppfordrer deg til å lese bruksvilkårene i sin helhet, blant
                annet for å forstå hvordan FuelBox behandler personopplysningene
                du oppgir når du oppretter en brukerkonto og kjøper et digitalt
                abonnement.
            </p>
            <p>
                Fra tid til annen vil vi uten nærmere varsel oppdatere disse
                bruksvilkårene. Ved større endringer, som økning i pris, endring
                av abonnementsform eller -lengde, vil du bli varslet i god tid
                før endringene trer i kraft, og bli bedt om å lese gjennom og
                akseptere vilkårene på nytt. Slike endringer kan skyldes
                markedsutvikling, økte avgifter og produktutvikling.
            </p>
            <p>
                Dersom du har en egen fakturaavtale med FuelBox, vil den
                separate avtalen gå foran disse vilkårene der bestemmelsene ikke
                er samsvarende. Dette vil for eksempel gjelde vilkår for og
                endringer av pris, og abonnementsform eller -lengde, som
                reguleres i henhold til den separate avtalen.
            </p>
            <p>Du må være 18 år for å kunne godkjenne disse bruksvilkårene.</p>
        </React.Fragment>
    ),
};

const q2 = {
    title: '2 Bestilling og betaling',
    copy: (
        <React.Fragment>
            <h4>2.1 Bestilling og betaling av FuelBox Premium</h4>
            <p>
                Du må opprette en bruker for å kunne kjøpe abonnement. Når du
                oppretter bruker, må du samtidig validere epostadressen du har
                oppgitt. Dersom du ikke validerer epostadressen din innen 24
                timer må du starte på nytt med å opprette bruker hvis du
                fortsatt ønsker å kjøpe abonnement.
            </p>
            <p>
                Abonnement på tjenesten er til personlig og privat bruk. Ditt
                brukernavn og passord er personlig og skal ikke deles med andre.
            </p>
            <p>
                Du kan inngå avtale om abonnement for ulike abonnementsperioder.
                Gjeldende alternativer og priser er som følger:
            </p>
            <ul>
                <li>Månedsabonnement: betal per måned.</li>
                <li>Årsabonnement: betal per år.</li>
            </ul>
            <p>
                FuelBox gjeldende abonnementsperioder er definert som følger: 1
                måned = 30 dager, 1 år = 365 dager.
            </p>
            <p>
                Du kan velge å betale med Visa, MasterCard, Apple Pay eller
                kjøpe direkte i App Store/Google Play ved å kjøpe tilgang via
                Apple/Google sin in-app purchase løsning.
            </p>
            <p>
                Alle abonnement forskuddsbetales for avtalt abonnementsperiode.
                Ditt registrerte betalingskort vil altså belastes automatisk for
                de neste 30 dagene ved kjøp av månedsabonnement og for de neste
                365 dagene ved kjøp av årsabonnement. Hvis du har valgt
                årsabonnement, vil du motta elektronisk varsel 14 dager før
                fornyelse av abonnementet og belastning av ditt registrerte
                betalingskort for ny abonnementsperiode. Tilgang til den
                digitale tjenesten aktiveres umiddelbart ved betaling. Ved
                manglende betaling vil tilgangen til tjenesten stenges.
            </p>
            <p>
                For in-app purchase i App Store eller Google Play, opprettes en
                kjøpsavtale med Apple/Google og Apple/Google vil trekke
                forskuddsbetalinger via din Apple Id/Google Id inntil du velger
                å avslutte abonnementet.
            </p>
            <p>
                Den oppgitte prisen for abonnementet inkluderer merverdiavgift,
                og alle andre skatter og avgifter, og er den totale prisen du
                skal betale. Eventuelle endringer i pris for eksisterende
                abonnement kunngjøres til alle abonnenter senest 14 dager før
                iverksettelse dersom endringen medfører en prisøkning. Slike
                endringer gjøres først gjeldende fra neste abonnementsperiode.
            </p>
            <p>
                Du kan si opp abonnementet når du selv ønsker. Dersom du ikke
                ønsker å fortsette ditt abonnement med en ny abonnementsperiode,
                må du huske å du si opp abonnementet minst til 24 timer før
                neste abonnementsperiode starter. Du kan lese mer om oppsigelse
                under punkt 3.1.
            </p>
            <p>
                Dersom du ønsker å bytte fra en abonnementsperiode til en annen,
                for eksempel fra månedsabonnement til årsabonnement, må du først
                si opp det abonnementet du har, for så å kjøpe abonnementet du
                vil bytte til.
            </p>
            <h4>2.2 Bestilling og betaling av FuelBox Proff</h4>
            <p>
                Organisasjoner kan inngå avtale om abonnement på FuelBox Proff.
                Abonnementet knyttes til den enkelte bruker i organisasjonen,
                som får tilgang både til samtalene for bedrifter og
                organisasjoner, og til samtalene i FuelBox Premium, som er
                tilpasset personlige relasjoner. Dersom organisasjonen har
                skreddersydde spørsmål, vil også disse bli tilgjengelige i appen
                gjennom Proff-abonnementet.
            </p>
            <p>
                Proff-abonnementet bestilles og betales via fakturaavtale. Ved
                opprettelse av fakturaavtale fastsettes priser basert på antall
                brukere i organisasjonen. Proff-abonnementet fornyes årlig og
                faktureres forskuddsvis for ett år (365 dager) av gangen,
                alternativt som etterskuddsbetaling måned for måned.
            </p>
            <p>
                Brukere tilknyttet en organisasjon med FuelBox Proff kan få
                tilgang til abonnementet på tre måter:
            </p>
            <ol>
                <li>
                    Ansvarlig i organisasjonen formidler brukernes epostadresser
                    til FuelBox, som så oppretter brukerkontoer tilknyttet
                    abonnementet.
                </li>
                <li>
                    Hver bruker registrerer seg som knyttet til den gjeldende
                    organisasjonen på en landingsside på våre nettsider.
                </li>
                <li>
                    Hver bruker oppretter sin egen brukerkonto i appen og
                    knytter denne til sin organisasjons abonnement ved hjelp av
                    en kode.
                </li>
            </ol>
            <p>
                Abonnement på tjenesten er personlig. Brukernavn og passord skal
                derfor ikke deles med andre.
            </p>

            <p>
                Dersom du har fått tilgang på et FuelBox Proff prøveabonnement,
                har du fått en personlig tilgang til FuelBox sine proff portal
                gjennom en personlig kode. Ved registrering for en slik tilgang,
                vil FuelBox følge deg opp med informasjon om bruk og innhold i
                den perioden prøveabonnementet varer. Du kan når som helst
                stoppe denne oppfølgingen.
            </p>
        </React.Fragment>
    ),
};

const q3 = {
    title: '3 Varighet og oppsigelse',
    copy: (
        <React.Fragment>
            <h4>3.1 Varighet og oppsigelse for FuelBox Premium</h4>
            <p>
                Som Premium-abonnent fornyes ditt abonnement automatisk. Du
                fornyes til den abonnementsperioden som du valgte da du kjøpte
                abonnement første gang.
            </p>
            <p>
                Et Premium-abonnement kan når som helst sies opp med virkning
                fra neste abonnementsperiode, men senest innen 24 timer før en
                ny abonnementsperiode starter. Ved oppsigelse kan du fortsette å
                benytte deg av tjenesten helt til neste abonnementsperiode
                starter.
            </p>
            <p>
                Du avslutter enkelt abonnementet ditt direkte i løsningen på Min
                konto. Du vil motta en elektronisk bekreftelse på din
                oppsigelse. Dersom du har kjøpt abonnement med in-app purchase
                via App Store eller Google Play, må du også kansellere
                abonnementet under abonnementer på din Iphone/Android telefon.
                Du kan når som helst reaktivere abonnementet direkte på din
                Iphone eller Android enhet ved å aktivere abonnementet under
                innstillingen <i>abonnementer</i>.
            </p>
            <p>
                Selv om du sier opp ditt abonnement, beholder du fremdeles
                brukerkontoen din. For å slette din brukerkonto, kontakt oss på
                post@fuelbox.no. Vi gjør oppmerksom på at vi har plikt til å
                lagre kundedetaljer og transaksjonsdata i fem år etter ditt
                siste kjøp, selv om du sletter din brukerkonto. Du kan lese mer
                om oppbevaringsplikten i vår{' '}
                <a href='/personvernerklæring'>Personvernerklæring</a>.
            </p>
            <p>Manglende betaling anses som oppsigelse.</p>
            <p>Det er ikke mulig å pause et abonnement.</p>
            <h4>3.2 Varighet og oppsigelse for FuelBox Proff</h4>
            <p>
                Abonnementet FuelBox Proff tegnes for ett år av gangen.
                Abonnementet fornyes automatisk for et nytt år inntil det
                avsluttes av kunde, og det er opp til den ansvarlige i
                organisasjonen å sikre at det fornyes med reelle brukere og
                reelt antall.
            </p>
            <p>
                Hver enkelt bruker kan koble seg fra sin egen tilgang til
                organisasjonens abonnement på Min konto. Selv om abonnementet
                avsluttes, vil brukeren fremdeles beholde sin konto. For å
                slette en brukerkonto, send en epost til{' '}
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>.
            </p>
        </React.Fragment>
    ),
};

const q4 = {
    title: '4 Angrerett',
    copy: (
        <React.Fragment>
            <p>
                Ved kjøp av abonnement har du angrerett i henhold til&nbsp;
                <a href='https://lovdata.no/dokument/NL/lov/2014-06-20-27?q=angrerett'>
                    angrerettlovens bestemmelser.
                </a>
                &nbsp;I ordrebekreftelsen får du en lenke til et nedlastbart
                angrerettsskjema. Du kan gå fra avtalen ved å sende utfylt
                angrerettsskjema til oss på&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>&nbsp;innen
                14 dager etter at du mottok ordrebekreftelsen.
            </p>
            <p>
                For in-app purchases i App Store eller Google Play, gjelder
                Apple/Google sine retningslinjer og eventuell refundering gjøres
                av Apple/Google.
            </p>
            <p>
                Det foreligger ingen angrefrist i avtalen om Proff-abonnement.
            </p>
        </React.Fragment>
    ),
};

const q5 = {
    title: '5 Rettigheter',
    copy: (
        <React.Fragment>
            <p>
                Alle rettigheter til tjenester og innhold i digitale FuelBox
                tilhører FuelBox. Det er ikke tillatt å kopiere, distribuere
                eller på annen måte mangfoldiggjøre innholdet uten særskilt
                avtale med FuelBox.
            </p>
        </React.Fragment>
    ),
};

const q6 = {
    title: '6 Ansvar ved nedetid eller tekniske problemer',
    copy: (
        <React.Fragment>
            {' '}
            <p>
                Som andre digitale tjenester, kan digitale FuelBox fra tid til
                annen være helt eller delvis utilgjengelig. Det kan skyldes
                vedlikehold eller tekniske problemer. I den grad det er
                praktisk, vil FuelBox gi brukerne informasjon om begrensninger i
                tilgjengeligheten. Dersom du opplever vedvarende problemer med
                tilgang til tjenesten, ber vi om at du varsler oss på&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>&nbsp;eller
                gjennom våre sosiale kanaler.
            </p>
            <p>
                Ved utilgjengelighet som varer i over 12 timer, vil FuelBox
                forlenge din abonnementsperiode med tilsvarende antall dager,
                forutsatt at du snarest melder fra om utilgjengelighet på&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>&nbsp;eller
                gjennom våre sosiale kanaler.
            </p>
            <p>
                FuelBox er ikke under noen omstendighet ansvarlig for eventuelle
                konsekvenser eller tap som følge av at tjenesten ikke kan
                benyttes som forutsatt.
            </p>
        </React.Fragment>
    ),
};

const q7 = {
    title: '7 Behandling av personopplysninger',
    copy: (
        <React.Fragment>
            <p>
                FuelBox er behandlingsansvarlig for personopplysninger som er
                relatert til ditt kundeforhold i FuelBox. For mer informasjon om
                hvordan FuelBox behandler personopplysninger oppfordrer vi deg
                til å lese vår&nbsp;
                <a href='/personvernerklæring'>Personvernerklæring</a>.
            </p>
        </React.Fragment>
    ),
};

const q8 = {
    title: '8 Samtykke til markedsføring',
    copy: (
        <React.Fragment>
            <p>
                Dersom du samtykker til markedsføring fra FuelBox, vil vi kunne
                sende deg tilbud og informasjon om produktene og tjenestene som
                FuelBox leverer. Markedsføringen vil være i form av epost.
            </p>
            <p>
                Som kunde i FuelBox Appen vil vi, uavhengig av samtykke og så
                lenge det eksisterer et løpende kundeforhold, kunne følge deg
                opp med tips til bruk og informasjon om ny funksjonalitet og
                innhold i appen, for å forbedre din bruk og hjelpe deg å utnytte
                appen bedre. Kommunikasjonen vil være i form av epost og
                pushvarsler på telefon.
            </p>
            <p>
                Dersom du ikke lenger ønsker å motta markedsføringshenvendelser
                eller annen informasjon fra FuelBox, kan du følge
                avmeldingslenken i markedsføringshenvendelsene du mottar.
            </p>
            <p>
                Når du sletter din brukerkonto vil du bare motta elektronisk
                markedsføring dersom du har gitt særskilt samtykke til det. Selv
                uten brukerkonto kan du når som helst melde deg av markedsføring
                fra FuelBox ved å følge avmeldingslenken i
                markedsføringshenvendelsene du mottar.
            </p>
            <p>
                FuelBox vil kunne benytte push varsler i FuelBox appen for
                mobile enheter. Push varsler kan brukeren selv velge å slå av på
                egen enhet.
            </p>
        </React.Fragment>
    ),
};

const q9 = {
    title: '9 Brudd på abonnementsvilkårene',
    copy: (
        <React.Fragment>
            <p>
                Ved brudd på disse vilkårene forbeholder FuelBox seg retten til
                å avslutte ditt abonnement.
            </p>
        </React.Fragment>
    ),
};

const q10 = {
    title: '10 Kontaktinformasjon',
    copy: (
        <React.Fragment>
            <p>
                Har du spørsmål om våre bruksvilkår, ta gjerne kontakt med oss
                på epost&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>&nbsp;eller
                telefon <a href='tel:+47 959 78 064'>+47 959 78 064</a>. Du
                finner alltid oppdatert kontaktinformasjon på&nbsp;
                <a href='https://fuelbox.no/kontakt-oss/'>
                    fuelbox.no/kontakt-oss/
                </a>
                .
            </p>
            <address>
                FuelBox AS
                <br />
                Bispeveien 4
                <br />
                4344 Bryne
                <br />
            </address>
        </React.Fragment>
    ),
};

export const copyNO = {
    title: titleNO,
    items: [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10],
};

const titleEN = 'Terms of use';
const e1 = {
    title: '1 Introduction',
    copy: (
        <React.Fragment>
            <p>
                These terms of use govern your rights and obligations when you
                purchase one of our digital subscriptions FuelBox Premium or
                FuelBox Pro. In addition to these terms, your purchase is
                regulated by relevant legislation between traders and consumers
                on the Internet.
            </p>
            <p>
                When you choose to subscribe to FuelBox, you enter into a
                subscription agreement with FuelBox AS (from now on referred to
                as “FuelBox”), org. no. 927 868 202.
            </p>
            <p>
                These terms are supplemented by FuelBox&nbsp;
                <a href='/privacy-policy'>Privacy policy</a> and the terms of
                use of the payment service you choose when purchasing a digital
                subscription. FuelBox offers the following payment services:
            </p>
            <ul>
                <li>
                    Bambora:&nbsp;
                    <a href='https://www.bambora.com/terms-and-conditions/'>
                        https://www.bambora.com/terms-and-conditions/
                    </a>
                </li>
                <li>Invoice agreement (for FuelBox Pro only)</li>
                <li>In-app purchase (App Store & Google Play)</li>
                <li>Apple Pay (for Apple units using Safari browser)</li>
            </ul>
            <p>
                We encourage you to read these terms in their entirety in order
                to understand how FuelBox handles the personal data you provide
                when you create a user account and purchase a digital
                subscription.
            </p>
            <p>
                From time to time we will update these terms without further
                notice. In cases of major changes, such as increase in
                subscription prices, changes in types or durations, you will be
                notified well in advance of the changes taking effect, and you
                will be asked to read and accept the new terms. Reasons for such
                changes can be changes in the market, increased fees and further
                development of our products.
            </p>
            <p>
                If you have an invoice agreement with FuelBox, this agreement
                will take precedence over these terms where the provisions do
                not correspond. This will, for example, apply to terms for and
                changes in prices, and subscription types or duration, which are
                regulated in accordance with the invoice agreement.
            </p>
            <p>You must be above 18 years old to accept these terms.</p>
        </React.Fragment>
    ),
};

const e2 = {
    title: '2 Ordering and purchasing',
    copy: (
        <React.Fragment>
            <h4>2.1 Ordering and purchasing of FuelBox Premium</h4>
            <p>
                You must create a user account to purchase a subscription. When
                you create an account, you have to validate the email address
                you used to register. If you do not validate your email address
                within 24 hours, you will need to create a new user account if
                you still wish to purchase a subscription.
            </p>
            <p>
                A subscription is for personal and private use only. Your
                username and password are personal and should not be shared with
                others.
            </p>
            <p>
                You can purchase a monthly or an annual subscription. The
                current options and prices are as follows:
            </p>
            <ul>
                <li>Monthly subscription: pay per month.</li>
                <li>Annual subscription: pay per year.</li>
            </ul>
            <p>
                FuelBox current subscription periods are defined as follows: 1
                month = 30 days, 1 year = 365 days.
            </p>
            <p>
                You can choose to pay with Visa, MasterCard, Apple Pay or
                through Apple/Google in-app purchase.
            </p>
            <p>
                All subscriptions are paid in advance. Your registered payment
                card will be charged for the next 30 days when purchasing a
                monthly subscription and for the next 365 days when purchasing
                an annual subscription. If you have chosen an annual
                subscription, you will receive a renewal notification on email
                14 days in advance of your card being charged for a new
                subscription period. Upon payment, your access to the service is
                activated immediately. In the case of a failed or missing
                payment, access to the service will be terminated.
            </p>
            <p>
                For in-app purchases, a purchase agreement is made between you
                and Apple/Google and Apple/Google will manage subscription
                payments through your Apple Id/Google account until you choose
                to cancel your subscription.
            </p>
            <p>
                The quoted price for the subscription includes value added tax,
                as well as all other taxes and fees, and is the total amount you
                will be charged for. Any changes in the price of an existing
                subscription will be announced to all subscribers no later than
                14 days prior to implementation, if the change results in a
                price increase. Such changes will only be effective from the
                next subscription period.
            </p>
            <p>
                You can cancel your subscription anytime. If you do not wish to
                renew your subscription, you must remember to cancel your
                subscription at least 24 hours before the next subscription
                period starts. You can read more about termination in section
                3.1.
            </p>
            <p>
                If you wish to change from one subscription type to another,
                e.g. from a monthly subscription to an annual subscription, you
                must first cancel your current subscription, then purchase the
                subscription you wish to change to.
            </p>
            <h4>2.1 Ordering and purchasing of FuelBox Pro</h4>
            <p>
                Organisations can enter into a subscription agreement for
                FuelBox Pro. The subscription is connected to the individual
                user in the organisation, and the users get access to
                conversations for companies and organisations, and to the
                conversations in FuelBox Premium, which are made for personal
                relations outside of work. If the organisation has custom-made
                questions, these will also be available in the app through the
                Pro subscription.
            </p>
            <p>
                The Pro subscription is ordered and paid for via an invoice
                agreement. When creating an invoice agreement, prices are
                determined based on the number of users in the organisation. The
                Pro subscription is renewed annually and invoiced in advance for
                one year (365 days) at a time, alternatively as arrears payment
                month by month.
            </p>
            <p>
                Users connected to an organisation with a FuelBox Pro
                subscription can gain access to the subscription in three ways:
            </p>
            <ol>
                <li>
                    The person in charge in the organisation communicates the
                    users’ email addresses to FuelBox, in order for FuelBox to
                    create user accounts connected to the subscription.
                </li>
                <li>
                    Each user registers as connected to the specific
                    organisation on a landing page on our website.
                </li>
                <li>
                    Each user creates their own user account in the app and
                    connects this to their organisation’s subscription using a
                    code.
                </li>
            </ol>
            <p>
                A subscription is for personal and private use only. Usernames
                and passwords are personal and should not be shared with others.
            </p>
            <p>
                If you have been given access to a FuelBox Professional trial
                subscription, you have been given personal access to
                FuelBox&rsquo;s pro pages by a personal code. When registering
                for such access, FuelBox will provide you up with information
                through email about use and content during the period the trial
                subscription lasts. You may cancel the follow up at any time.
            </p>
        </React.Fragment>
    ),
};

const e3 = {
    title: '3 Duration and termination',
    copy: (
        <React.Fragment>
            <h4>3.1 Duration and termination of FuelBox Premium</h4>
            <p>
                As a Premium subscriber, your subscription will renew
                automatically. It will renew for a month or a year, depending on
                the subscription type chosen when you first purchased the
                subscription.
            </p>
            <p>
                A Premium subscription can be cancelled anytime with effect from
                the next subscription period, but no later than 24 hours before
                a new subscription period starts. Upon termination, you may
                continue to use the service until the current subscription
                period ends.
            </p>
            <p>
                You can easily cancel your subscription in My account. You will
                receive an email to confirm the termination. If you have
                purchased a subscription with in-app purchase via the App Store
                or Google Play, you must also cancel the subscription during
                subscriptions on your Iphone / Android phone. A subscription may
                be reactivated at any time directly by activation in
                subscription settings on your Iphone or Android device.
            </p>
            <p>
                Even if you cancel your subscription, you will still keep your
                user account. To delete your user account, send an email to
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>. Note that
                although your user account is deleted, FuelBox is obliged to
                store certain customer details and transaction data for five
                years after your last purchase. You can read more about the duty
                of retention in our <a href='/privacy-terms'>Privacy policy</a>.
            </p>
            <p>A missing payment is treated as a termination.</p>
            <p>It is not possible to pause a subscription.</p>
            <h4>3.2 Duration and termination of FuelBox Pro</h4>
            <p>
                A FuelBox Pro subscription lasts for one year at a time. The
                subscription is automatically renewed anually, and it is up to
                the organisation to ensure that the connected users are real and
                that the number of users connected is correct.
            </p>
            <p>
                Each individual user can disconnect from their organisation’s
                subscription in My account. Although a subscription is
                cancelled, the user will keep their user account. To delete a
                user account, send an email to&nbsp;
                <a href='mailto:post@fuelbox.no'>post@fuelbox.no</a>.
            </p>
        </React.Fragment>
    ),
};

const e4 = {
    title: '4 Right to cancel',
    copy: (
        <React.Fragment>
            <p>
                When purchasing a subscription, you have the right to cancel in
                accordance with the&nbsp;
                <a href='https://lovdata.no/dokument/NLE/lov/2014-06-20-27'>
                    Cancellation Act.
                </a>
                &nbsp;Your order confirmation includes a link to download a
                cancellation form. You can cancel your purchase by sending a
                completed cancellation form to&nbsp;
                <a href='mailto:post@fuelboxworld.com'>post@fuelboxworld.com</a>
                &nbsp;within 14 days of receiving the order confirmation.
            </p>
            <p>
                For in-app purchases in App Store or Google Play, Apple and
                Google terms and conditions for in-app purchases apply and any
                refunds are made by Apple or Google.
            </p>
            <p>
                There is no cancellation period in the agreement for FuelBox
                Pro.
            </p>
        </React.Fragment>
    ),
};

const e5 = {
    title: '5 Rights',
    copy: (
        <React.Fragment>
            <p>
                All rights to services and contents in digital FuelBox belong to
                FuelBox. Copying, distributing or otherwise reproducing the
                contents without a separate agreement with FuelBox is
                prohibited.
            </p>
        </React.Fragment>
    ),
};

const e6 = {
    title: '6 Responsibility for downtime or technical issues',
    copy: (
        <React.Fragment>
            <p>
                Just like other digital services, the digital FuelBox may be
                totally or partially unavailable from time to time. This may be
                caused by technical issues or maintenance work. To the extent
                practicable, FuelBox will provide users with information about
                the limitations to the availability. If you are experiencing
                persistent problems when trying to access the service, we ask
                that you notify us at&nbsp;
                <a href='mailto:post@fuelboxworld.com'>post@fuelboxworld.com</a>
                &nbsp;or through our social media channels.
            </p>
            <p>
                In the case of unavailability for more than 12 hours, FuelBox
                will extend your subscription period with the corresponding
                number of days, providing that you promptly report the
                unavailability to&nbsp;
                <a href='mailto:post@fuelboxworld.com'>post@fuelboxworld.com</a>
                &nbsp;or through our social media channels.
            </p>
            <p>
                FuelBox is under no circumstances liable for any consequences or
                damage resulting from the service not functioning as intended.
            </p>
        </React.Fragment>
    ),
};

const e7 = {
    title: '7 Processing of personal data',
    copy: (
        <React.Fragment>
            <p>
                FuelBox is responsible for processing personal data related to
                your customer relationship with FuelBox. For more information on
                how FuelBox processes personal data, we encourage you to read
                our&nbsp;
                <a href='/privacy-policy'>Privacy policy</a>.
            </p>
        </React.Fragment>
    ),
};

const e8 = {
    title: '8 Marketing consent',
    copy: (
        <React.Fragment>
            <p>
                When you consent to marketing communications from FuelBox, we
                will be able to send you emails with offers and information
                about the products and services we provide.
            </p>
            <p>
                As a customer of the FuelBox App, we will, regardless of consent
                and as long as there is an ongoing customer relationship, be
                able to support you with tips for use and information about new
                functionality and content in the app, to improve your use and
                help you better utilize of the app. The communication will be in
                the form of emails and push notifications on your smartphone.
            </p>
            <p>
                If you no longer wish to receive marketing communications or
                other communication from FuelBox, you can follow the unsubscribe
                link in the marketing emails you receive.
            </p>
            <p>
                When you delete your user account you will only receive digital
                marketing communications upon consent. Even without a user
                account, you can unsubscribe from marketing communications from
                FuelBox anytime by following the unsubscribe link in the
                marketing communications you receive.
            </p>
            <p>
                FuelBox might use push notifications in the FuelBox App for
                mobile devices. Push notifications can be managed by the user
                directly on own device.
            </p>
        </React.Fragment>
    ),
};

const e9 = {
    title: '9 Violation of terms',
    copy: (
        <React.Fragment>
            <p>
                In the event of a breach of these terms of use, FuelBox reserves
                the right to cancel your subscription.
            </p>
        </React.Fragment>
    ),
};

const e10 = {
    title: '10 Contact information',
    copy: (
        <React.Fragment>
            <p>
                If you have any questions or inquiries about our terms of use,
                please contact us at&nbsp;
                <a href='mailto:post@fuelboxworld.com'>post@fuelboxworld.com</a>
                &nbsp;or <a href='tel:+47 959 78 064'>+47 959 78 064</a>. You
                can always find our current contact information at&nbsp;
                <a href='https://fuelboxworld.com/contact/'>
                    fuelboxworld.com/contact/
                </a>
                .
            </p>
            <address>
                FuelBox AS
                <br />
                Bispeveien 4
                <br />
                4344 Bryne
                <br />
                Norway
                <br />
            </address>
        </React.Fragment>
    ),
};

export const copyEN = {
    title: titleEN,
    items: [e1, e2, e3, e4, e5, e6, e7, e8, e9, e10],
};
