import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import styles from './user-table.module.scss';
import { Button } from 'components/base';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from 'images/icons/magnifier.svg';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { returnCopyForLanguage } from 'services/language-service';
import { Trans } from 'react-i18next';
import companyClient from 'api/company-client';
import { CompanyUser } from '_types/company-user';

interface Props {
    setToast: (toast: string) => void;
    rows: Data[];
    setUsers: (users: CompanyUser[]) => void;
}

interface ViewCopy {
    nameLabel: string;
    emailLabel: string;
    memberSinceLabel: string;
    roleLabel: string;
    searchPlaceholder: string;
    removeUserButtonLabel: string;
    rowsPerPageLabel: string;
    ofLabel: string;
    usersDeletedToast: string;
}

const nbCopy: ViewCopy = {
    nameLabel: 'Navn',
    emailLabel: 'Epostadresse',
    memberSinceLabel: 'Medlem siden',
    roleLabel: 'Rolle',
    searchPlaceholder: 'Søk etter brukere',
    removeUserButtonLabel: 'Fjern fra abonnement',
    rowsPerPageLabel: 'Rader per side',
    ofLabel: ' av ',
    usersDeletedToast: 'Brukerne er fjernet fra abonnementet.',
};

const enCopy: ViewCopy = {
    nameLabel: 'Name',
    emailLabel: 'Email address',
    memberSinceLabel: 'Member since',
    roleLabel: 'Role',
    searchPlaceholder: 'Search for users',
    removeUserButtonLabel: 'Remove',
    rowsPerPageLabel: 'Rows per page',
    ofLabel: ' of ',
    usersDeletedToast: 'The users have been removed from the subscription.',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

interface Data {
    id: number;
    name: string;
    email: string;
    memberSince: string;
    role: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface CustomTableProps {
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    rows: Data[];
}

function CustomTableHead(props: CustomTableProps) {
    const language: Language = useSelector(languageSelector);
    const { nameLabel, emailLabel, memberSinceLabel, roleLabel } =
        returnCopyForLanguage(language, copies);
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: nameLabel,
        },
        {
            id: 'email',
            numeric: true,
            disablePadding: false,
            label: emailLabel,
        },
        {
            id: 'memberSince',
            numeric: true,
            disablePadding: false,
            label: memberSinceLabel,
        },
        {
            id: 'role',
            numeric: true,
            disablePadding: false,
            label: roleLabel,
        },
    ];

    return (
        <TableHead className={styles.TableHead}>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        color='primary'
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface TableToolbarProps {
    numSelected: number;
    // onFilterRows: void(Data[]);
    onFilterRows: (data: Data[]) => void;
    onDelete: () => void;
    rows: Data[];
}

function TableToolbar(props: TableToolbarProps) {
    const language: Language = useSelector(languageSelector);
    const { searchPlaceholder, removeUserButtonLabel } = returnCopyForLanguage(
        language,
        copies
    );
    const { numSelected, onFilterRows, onDelete, rows } = props;
    const [searchText, setSearchText] = useState('');

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSearchText(event.target.value);

            if (event.target.value.trim().length > 0) {
                const filteredRows = [];

                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    if (
                        row.email.includes(event.target.value) ||
                        row.name.includes(event.target.value) ||
                        row.memberSince.includes(event.target.value)
                    ) {
                        filteredRows.push(row);
                    } else {
                        filteredRows.slice(i, 1);
                    }
                }
                onFilterRows(filteredRows);
            } else {
                onFilterRows(rows);
            }
        },
        []
    );

    return (
        <div className={styles.Toolbar}>
            <div className={styles.SearchFieldContainer}>
                <div className={styles.SearchIcon}>
                    <SearchIcon />
                </div>
                <input
                    type={'text'}
                    id={'sok'}
                    placeholder={searchPlaceholder}
                    className={styles.SearchField}
                    value={searchText}
                    autoComplete='off'
                    onChange={onInputChanged}
                    onFocus={onInputChanged}
                ></input>
            </div>

            {numSelected > 0 && (
                <Button
                    className={styles.DeleteUsersButton}
                    leftIcon='delete'
                    onClick={onDelete}
                >
                    {removeUserButtonLabel}
                </Button>
            )}
            <div className={styles.TableInfoText}>
                <Trans
                    components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            className={styles.link}
                            href='post@fuelbox.no'
                            key={0}
                        />,
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                    ]}
                    i18nKey='companyAdminPage.needMoreUseresText'
                />
            </div>
        </div>
    );
}

const UserTable = ({ setToast, rows, setUsers }: Props) => {
    const language = useSelector(languageSelector);
    const { rowsPerPageLabel, ofLabel, usersDeletedToast } =
        returnCopyForLanguage(language, copies);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('memberSince');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filteredRows, setFilteredRows] = useState<Data[]>([]);

    useEffect(() => {
        setFilteredRows(rows);
    }, [rows]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = () => {
        const usersToDelete = rows!.filter((r) => isSelected(r.name));
        const idsToDelete = usersToDelete.map((r) => {
            return r.id;
        });
        if (!!idsToDelete && idsToDelete.length > 0) {
            companyClient
                .removeUsersFromCompany(idsToDelete!)
                .then((remainingUsers) => {
                    setUsers(remainingUsers);
                    setToast(usersDeletedToast);
                })
                .catch((error) => console.error(error));
        }
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div className={styles.UserTable}>
            <TableToolbar
                numSelected={selected.length}
                onFilterRows={setFilteredRows}
                onDelete={handleDelete}
                rows={rows}
            />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size={'medium'}
                >
                    <CustomTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        rows={rows}
                    />
                    <TableBody className={styles.TableBody}>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
                        {stableSort(filteredRows, getComparator(order, orderBy))
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.name)
                                        }
                                        role='checkbox'
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                    >
                                        <TableCell
                                            padding='checkbox'
                                            className={styles.CheckboxCell}
                                        >
                                            <Checkbox
                                                color='primary'
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            id={labelId}
                                            scope='row'
                                            padding='none'
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row.email}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row.memberSince}
                                        </TableCell>
                                        <TableCell align='left'>
                                            <div
                                                className={
                                                    row.role.toLowerCase() ===
                                                    'admin'
                                                        ? styles.adminRole
                                                        : ''
                                                }
                                            >
                                                {' '}
                                                {row.role}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={styles.TablePagination}
                labelDisplayedRows={({ from, to, count }) => {
                    return '' + from + '-' + to + ofLabel + count;
                }}
                labelRowsPerPage={rowsPerPageLabel}
            />
        </div>
    );
};
export default UserTable;
