import { combineReducers } from 'redux';
import { AuthReducer } from 'store-modules/auth';
import { UserReducer } from 'store-modules/user';
import { SettingsReducer } from 'store-modules/app-settings';
import { ProfessionalReducer } from 'store-modules/professional';
import { ConversationSwiperPositionsReducer } from 'store-modules/conversation-swiper-positions';
import favouriteConversationsReducer from 'store-modules/favourite-conversations/favourite-conversations.reducer';
import favouriteQuestionsReducer from 'store-modules/favourite-questions/favourite-questions.reducer';
import myConversationsReducer from 'store-modules/my-conversations/my-conversations.reducer';

const rootReducer = combineReducers({
    settings: SettingsReducer,
    auth: AuthReducer,
    user: UserReducer,
    professional: ProfessionalReducer,
    conversationSwiperPositions: ConversationSwiperPositionsReducer,
    favouriteQuestions: favouriteQuestionsReducer,
    favouriteConversations: favouriteConversationsReducer,
    myConversations: myConversationsReducer,
});

export default rootReducer;
