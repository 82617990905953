import { AppSettingsState } from './_types';
import { RootReducer } from 'store/store';
import { ScrollPosition } from '_types/scoll-position';
import { Language } from '_types/language';
import { UserAgent } from '_types/user-agent';

const settingsStateFromRoot = (state: RootReducer): AppSettingsState =>
    state.settings;

export const deviceSelector = (state: RootReducer): UserAgent =>
    settingsStateFromRoot(state).device;

export const languageSelector = (state: RootReducer): Language =>
    settingsStateFromRoot(state).language;

export const scrollPosSelectorHomePage = (
    state: RootReducer
): ScrollPosition | null => settingsStateFromRoot(state).scrollPosHomePage;

export const scrollPosSelectorPremiumBrowsePage = (
    state: RootReducer
): ScrollPosition | null =>
    settingsStateFromRoot(state).scrollPosPremiumBrowsePage;

export const scrollPosSelectorProBrowsePage = (
    state: RootReducer
): ScrollPosition | null => settingsStateFromRoot(state).scrollPosProBrowsePage;

export const scrollPosSelectorProHomePage = (
    state: RootReducer
): ScrollPosition | null => settingsStateFromRoot(state).scrollPosProHomePage;

export const scrollPosSelectorMyLibraryPage = (
    state: RootReducer
): ScrollPosition | null =>
    settingsStateFromRoot(state).scrollPosFavouritesPage;

export const scrollPosSelectorFavouriteConversationsPage = (
    state: RootReducer
): ScrollPosition | null =>
    settingsStateFromRoot(state).scrollPosFavouritesPage;

export const scrollPosSelectorFavouriteQuestionsPage = (
    state: RootReducer
): ScrollPosition | null =>
    settingsStateFromRoot(state).scrollPosFavouriteQuestionsPage;

export const scrollPosSelectorCollaborationsPage = (
    state: RootReducer
): ScrollPosition | null =>
    settingsStateFromRoot(state).scrollPosCollaborationsPage;

export const isCreatingCompanySelector = (state: RootReducer): boolean =>
    settingsStateFromRoot(state).isCreatingCompany;
