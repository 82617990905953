import React, { FC, forwardRef, useState } from 'react';
import { TextField, TextFieldProps } from './text-field';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../buttons/icon-button';

export type PasswordFieldProps = Omit<
    TextFieldProps,
    'autoComplete' | 'endAdornment' | 'startIcon' | 'type'
> & {
    hideStartIcon?: boolean;
};

export const PasswordField: FC<PasswordFieldProps> = forwardRef(
    ({ hideStartIcon, ...rest }, ref) => {
        const { t } = useTranslation();
        const [visible, setVisible] = useState(false);

        return (
            <TextField
                endAdornment={
                    <IconButton
                        aria-label={t('form.password', {
                            context: visible ? 'hide' : 'show',
                        })}
                        color='inherit'
                        icon={visible ? 'visibility_off' : 'visibility'}
                        onClick={() => setVisible(!visible)}
                        size='small'
                    />
                }
                startIcon={hideStartIcon ? undefined : 'key'}
                type={visible ? 'text' : 'password'}
                ref={ref}
                {...rest}
            />
        );
    }
);
