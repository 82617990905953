import {
    ConversationResponse,
    ConversationWithCategory,
    mapConversationsWithCategories,
    mapQuestions,
    QuestionResponse,
} from '_types';
import { Question } from '../_types/question';
import restClient from './rest-client';

export default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    questionSearch: (params: any): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>('/search-questions', false, params,
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then(mapQuestions);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    conversationSearch: (params: any): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/search-conversations', false, params,
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then(mapConversationsWithCategories);
    },
};
