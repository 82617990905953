import { Event, InlineCheckout } from '@bambora/checkout-sdk-web';
import React, { useEffect, useRef } from 'react';
import styles from './bambora-inline-checkout.module.scss';

type Props = {
    sessionToken: string;
    successHandler: () => void;
    cancelHandler: () => void;
};

const BamboraInlineCheckout: React.FC<Props> = ({
    sessionToken,
    successHandler,
    cancelHandler,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const containerRef = useRef<any>(null);
    let checkout = new InlineCheckout(null);
    let form = null;

    useEffect(() => {
        if (checkout && containerRef.current && sessionToken) {
            // eslint-disable-next-line
            checkout = new InlineCheckout(sessionToken);
            checkout.mount(containerRef.current);
            checkout.on(Event.Cancel, cancelHandler);
            checkout.on(Event.Authorize, successHandler);
            // eslint-disable-next-line
            form = checkout.iframe;
        }
        return (): void => checkout.destroy();
    }, [checkout, containerRef, sessionToken, successHandler, cancelHandler]);

    return (
        <section
            data-testid='component-inline-checkout'
            aria-labelledby='checkout form'
            ref={containerRef}
            className={styles.BamboraInline}
        >
            {form}
        </section>
    );
};

export default React.memo(BamboraInlineCheckout);
