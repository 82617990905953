import classnames from 'classnames';
// import { Props } from 'components/page-header/page-header';
import React from 'react';
import styles from './rotating-spinner.module.scss';

export type ColorOption = 'blue' | 'black';

interface Props {
    color?: ColorOption;
}

const RotatingSpinner: React.FC<Props> = ({ color }: Props) => (
    <div
        className={classnames(
            styles.spinner,
            color && color === 'black' && styles.blackSpinner
        )}
    />
);

export default RotatingSpinner;
