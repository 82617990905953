import { ConversationCategory } from '_types';
import { Language } from '_types/language';
import { PRO_PREFIX } from 'constants/general';

export const pathOfBrowseCategory = (
    language: Language,
    slug: string,
    topLevelSlug: string
): string => {
    const folderPath = `/${language}/${PRO_PREFIX}/browse/${topLevelSlug}`;
    return slug !== topLevelSlug ? folderPath + `/${slug}` : folderPath;
};

export const THEMES_CATEGORY: ConversationCategory = {
    slug: 'tema',
    name: 'Tema',
};

export const ORGANIZATIONS_CATEGORY: ConversationCategory = {
    slug: 'organization',
    name: 'organization',
};

export const MAIN_CATEGORY: ConversationCategory = {
    slug: 'main',
    name: 'main',
};
