import React from 'react';
import { Container } from 'components/container';
import styles from './swiper-slide.module.scss';

interface Props {
    children: React.ReactNode;
    isRandomQuestions?: boolean;
}

const SwiperSlide: React.FC<Props> = ({
    children,
    isRandomQuestions,
}: Props) => {
    const containerStyle = isRandomQuestions
        ? styles.containerRandom
        : styles.container;
    return (
        <Container
            className={containerStyle}
            isRandomQuestions={isRandomQuestions}
        >
            <div className={styles.content}>{children}</div>
        </Container>
    );
};

export default SwiperSlide;
