import { FlatTree } from 'utils/tree-utils';
import { Conversation, ConversationWithCategory } from '_types';

interface GroupedConversations {
    [key: string]: ConversationWithCategory[];
}

export const ROOT_NODE_ID = '0';

export const getMainCategories = ({
    nodesById,
    rootId,
}: FlatTree<Conversation>): Conversation[] => {
    const categoryIds = nodesById[rootId].childrenIds;

    return categoryIds.map((id) => nodesById[id].item);
};

export const groupConversationsByCategory = ({
    nodeIds,
    nodesById,
    rootId,
}: FlatTree<ConversationWithCategory>): GroupedConversations =>
    nodeIds.reduce((byId, id) => {
        let node = nodesById[id];
        const { item } = node;

        // we only need bottom-level conversations
        if (node.childrenIds.length > 0) {
            return byId;
        }

        // continue traversing up, until we find top-most ancestor, but not the ROOT
        while (node.parentId && node.parentId !== rootId) {
            node = nodesById[node.parentId];
        }

        if (!byId[node.id]) {
            byId[node.id] = [];
        }

        byId[node.id].push(item);

        return byId;
    }, {} as GroupedConversations);
