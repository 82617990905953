import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Drawer,
    MobileStoreButton,
    MobileStoreButtonType,
    Typography,
} from 'components/base';

import { IS_MOBILE_APP } from '../../../../constants';

const EndView: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box display='flex' height={1} flexDirection='column'>
                <Box flexBasis={IS_MOBILE_APP ? '50%' : '33%'} />

                <Typography
                    align='center'
                    fontSize={18}
                    fontWeight={600}
                    lineHeight={1.3}
                    p={2}
                >
                    {t('joinCollaboration.endSlide.title')}
                </Typography>

                <Box flexBasis={IS_MOBILE_APP ? '50%' : '67%'} />
            </Box>

            {!IS_MOBILE_APP && (
                <Drawer
                    variant='permanent'
                    open
                    title={t('joinCollaboration.endSlide.drawer.title')}
                >
                    <Drawer.Actions>
                        <MobileStoreButton type={MobileStoreButtonType.IOS} />

                        <MobileStoreButton
                            type={MobileStoreButtonType.ANDROID}
                        />
                    </Drawer.Actions>
                </Drawer>
            )}
        </Fragment>
    );
};

export default EndView;
