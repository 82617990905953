import React from 'react';
import classnames from 'classnames';
import styles from './conversation-card.module.scss';
import { Props as CardProps } from '../generic/card';
import { RectangularCard } from '../generic';
import { ConversationCategory } from '_types';

interface Props extends CardProps {
    name?: string;
    category: ConversationCategory;
}

const ConversationCategoryCard: React.FC<Props> = ({
    name,
    onClick,
    className,
    children,
    category,
}: Props) => (
    <RectangularCard
        className={classnames(styles.card, className, styles[category.slug])}
        onClick={onClick}
    >
        {children}
        {!children && name && <div className={styles.title}>{name}</div>}
    </RectangularCard>
);

export default ConversationCategoryCard;
