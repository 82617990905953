import React, { FC, forwardRef, Fragment } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import MuiMenuItem, {
    MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';

import useMenu from './use-menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';

import { Icon, IconOption } from '../../data-display/icon';
import { Typography } from '../../data-display/typography';

export type MenuItemProps = MuiMenuItemProps &
    Partial<Pick<LinkProps, 'to'>> &
    Pick<ListItemTextProps, 'inset'> & {
        icon?: IconOption;
        keepMounted?: boolean;
        subheading?: string;
    };

export const MenuItem: FC<MenuItemProps> = forwardRef(
    (
        {
            children,
            divider,
            icon,
            inset,
            keepMounted,
            onClick,
            subheading,
            ...rest
        },
        ref
    ) => {
        const { onClose } = useMenu();

        return (
            <Fragment>
                <MuiMenuItem
                    {...rest}
                    {...(rest.to ? { component: Link } : {})}
                    ref={ref}
                    onClick={(e) => {
                        onClick && onClick(e);
                        !keepMounted && onClose();
                    }}
                >
                    {icon && (
                        <ListItemIcon>
                            <Icon
                                fontSize='small'
                                color='primary'
                                name={icon}
                            />
                        </ListItemIcon>
                    )}

                    <ListItemText
                        primary={
                            <Typography variant='inherit' noWrap>
                                {children}
                            </Typography>
                        }
                        secondary={subheading}
                        inset={inset}
                    />
                </MuiMenuItem>

                {divider && <Divider />}
            </Fragment>
        );
    }
);

MenuItem.displayName = 'MenuItem';
