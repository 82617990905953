import { useState, useEffect } from 'react';

interface Size {
    width: number;
    height: number;
    view: 'mobile' | 'tablet' | 'desktop';
}

const getSize = ({ innerWidth, innerHeight }: Window): Size => ({
    width: innerWidth,
    height: innerHeight,
    view:
        innerWidth < 768 ? 'mobile' : innerWidth < 1048 ? 'tablet' : 'desktop',
});

// TODO: add debounce/throttle
export const useWindowSize = (window: Window): Size => {
    const [windowSize, setWindowSize] = useState(getSize(window));

    useEffect(() => {
        const handleResize = (): void => {
            setWindowSize(getSize(window));
        };

        window.addEventListener('resize', handleResize);

        return (): void => window.removeEventListener('resize', handleResize);
    }, [window]);

    return windowSize;
};
