import React from 'react';
import { routesNO, RouteList, routesEN } from 'api/routes';
import styles from './subscription-details.module.scss';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Button } from 'components/base';
import { useNavigate } from 'react-router-dom';

interface Props {}
interface ViewCopy {
    textNoSub: string;
    textBuySub: string;
}

const nbCopy: ViewCopy = {
    textNoSub: 'Du har ikke et aktivt abonnement',
    textBuySub: 'Velg abonnement',
};

const enCopy: ViewCopy = {
    textNoSub: "You don't have an active subscription",
    textBuySub: 'Select subscription',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const NoSub: React.FC<Props> = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const { textNoSub, textBuySub } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const navigateToPayment = () => navigate(routes.paymentOptionSelect);

    return (
        <React.Fragment>
            <p>{textNoSub}</p>
            <div className={styles.buttonContainer}>
                <Button
                    className={styles.selectSubButton}
                    onClick={navigateToPayment}
                >
                    {textBuySub}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default NoSub;
