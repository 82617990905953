import restClient from './rest-client';
import { User } from '_types';
import { Language } from '_types/language';

export interface UserData {
    username: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app_storage?: any;
    pro_code?: string;
}

export interface CreateUserResponse {
    code: number;
    message: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResetPasswordResponse = any;

interface LanguagePayload {
    language: Language;
}

interface EmailOtherProductsAndServicesPayload {
    email_other_products_and_services: boolean;
}

enum RegisteredTypes {
    email = 'email',
    google = 'google',
}

interface DeleteUserResponse {
    delete_date: string;
    force_logout: boolean;
}

interface UserResponse {
    ID: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app_storage?: any;
    display_name: string;
    email_new_content: boolean;
    email_other_products_and_services: boolean;
    favourites: string;
    first_name: string;
    last_name: string;
    user_email: string;
    user_login: string;
    is_activated: boolean;
    is_company_admin: boolean;
    language: Language;
    organization: string;
    owner: boolean;
    registered_type: RegisteredTypes;
    delete_date: string;
    can_be_deleted: boolean;
}

const mapUser = ({
    first_name,
    last_name,
    user_email,
    app_storage,
    is_activated,
    is_company_admin,
    language,
    organization,
    owner,
    registered_type,
    ID,
    delete_date,
    can_be_deleted,
}: UserResponse): User => ({
    id: ID,
    firstName: first_name,
    lastName: last_name,
    email: user_email,
    app_storage,
    isConfirmed: is_activated,
    isCompanyAdmin: is_company_admin,
    registeredType: registered_type,
    language,
    organization,
    owner,
    delete_date,
    can_be_deleted,
});

interface NewPasswordData {
    email: string;
    code: string;
    newpass: string;
}

interface ChangeEmailRequest {
    new_email: string;
    send_new_confirmation_email: boolean;
}

interface EmailRegistrationLinkRequest {
    key: string;
    email: string;
}

export type EmailRegistrationLinkError = {
    code: string;
    message: string;
    data: {
        status: number;
    }
}

export default {
    createUser: (user: UserData): Promise<CreateUserResponse> => {
        return restClient.post<UserData, CreateUserResponse>(
            '/users/register',
            user,
            { baseUrl: '/wp-json/wp/v2' }
        );
    },
    resetPassword: (email: string): Promise<void> => {
        return restClient.get<ResetPasswordResponse>('/resetPassword', false, {
            email,
        });
    },
    setNewPassword: (data: NewPasswordData): Promise<void> => {
        return restClient.post<NewPasswordData, ResetPasswordResponse>(
            '/setUserNewPassword',
            data
        );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resendConfirmationEmail: (email: string): Promise<any> => {
        return restClient.get('/sendEmailValidationCode', false, { email });
    },
    confirmUser: (
        confirmationId: string,
        usedProCodeToken?: string | null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> => {
        const params = usedProCodeToken
            ? {
                  act: confirmationId,
                  used_pro_code: usedProCodeToken,
              }
            : {
                  act: confirmationId,
              };
        return restClient.get('/verifyUserEmailCode', false, params);
    },
    getUserIsConfirmed: (): Promise<boolean> => {
        return restClient
            .get<UserResponse>('/get-me',
                false,
                {},
                {},
                {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
                true)
            .then((user) => user.is_activated);
    },
    getCurrentUser: (): Promise<User> => {
        const user = restClient.get<UserResponse>('/get-me',
            false,
            {},
            {},
            {baseUrl: process.env.REACT_APP_NEXT_CLIENT},
            true)
            .then(mapUser);
        return user;
    },
    setUserLanguage: (language: Language): Promise<void> => {
        const data: LanguagePayload = {
            language: language,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return restClient.put<LanguagePayload, any>('/preferences', data);
    },
    getUserNewsletterConsent: (): Promise<boolean> => {
        return restClient.get<UserResponse>('/me', false).then((user) => {
            return user.email_other_products_and_services;
        });
    },
    setUserNewsletterConsent: async (isAllowed: boolean): Promise<void> => {
        const user = await restClient.get<UserResponse>('/me', false);

        const payload: EmailOtherProductsAndServicesPayload = {
            email_other_products_and_services: isAllowed,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await restClient.put<EmailOtherProductsAndServicesPayload, any>(
            `/users/${user.ID}`,
            payload,
            { baseUrl: '/wp-json/wp/v2' }
        );
    },
    deleteUser: async (id: number): Promise<DeleteUserResponse> => {
        return await restClient.get<DeleteUserResponse>(
            '/deleteMe?id=' + id,
            false
        );
    },
    recoverUser: async (id: number): Promise<void> => {
        return await restClient.get<void>('/recoverMe?id=' + id, false);
    },

    changeEmail: (
        newEmail: string,
        sendNewConfirmation: boolean
    ): Promise<string> => {
        return restClient.post<ChangeEmailRequest, string>('/changeEmail', {
            new_email: newEmail,
            send_new_confirmation_email: sendNewConfirmation,
        });
    },

    sendEmailRegistrationLink: (
        email: string,
        key: string
    ): Promise<boolean> => {
        return restClient.post<EmailRegistrationLinkRequest, boolean>('/sendEmailRegistrationLink', {
            email,
            key
        });
    },

    verifyRegistrationLink: (
        act: string
    ): Promise<string> => {
        return restClient.get<string>(`/verifyRegistrationLink?act=${act}`);
    },
};
