import React from 'react';
import classNames from 'classnames';

import { Button, ButtonProps, styled, SwipeableDrawer } from 'components/base';
import { LightThemeProvider } from 'style/theme';

import TopDockedCard from '../../top-docked-card';

const sessionProgressButtonClass = 'session-progress-button';

export const SessionProgressButton = styled(
    ({
        appearance = 'text',
        className,
        size = 'small',
        ...rest
    }: ButtonProps) => (
        <Button
            appearance={appearance}
            size={size}
            className={classNames(className, sessionProgressButtonClass)}
            {...rest}
        />
    )
)({});

export const StyledTopDockedCard = styled((props) => (
    <LightThemeProvider>
        <TopDockedCard {...props} />
    </LightThemeProvider>
))`
    position: absolute;
    padding: ${({ theme }) => theme.spacing(1.25, 1.75)};
    right: ${({ theme }) => theme.spacing(4)};
    p {
        font-size: ${({ theme }) =>
            theme.typography.body2.fontSize}px !important;
    }

    hr {
        margin-bottom: ${({ theme }) => theme.spacing(0.25)} !important;
        margin-top: ${({ theme }) => theme.spacing(0.25)} !important;
    }

    .${sessionProgressButtonClass} {
        font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
        border-radius: 0;
        border-bottom-right-radius: inherit;
        margin: ${({ theme }) => theme.spacing(-1.25, -1.75, -1.25, 0)};
        padding: ${({ theme }) => theme.spacing(0, 1.5)};
        align-self: stretch;
    }
` as typeof TopDockedCard;

export const DrawerInfoContent = styled(SwipeableDrawer.InnerContent)`
    align-items: center;
    background: ${({ theme }) => theme.palette.background.paper};
    border-radius: 0;
    padding-top: ${({ theme }) => theme.spacing()};
    padding-bottom: ${({ theme }) => theme.spacing()};
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: space-between;

    * {
        font-size: ${({ theme }) =>
            theme.typography.body1.fontSize}px !important;
    }

    hr {
        margin-top: ${({ theme }) => theme.spacing()} !important;
        margin-bottom: ${({ theme }) => theme.spacing()} !important;
    }

    .${sessionProgressButtonClass} {
        margin-left: ${({ theme }) => theme.spacing(0.5)};
        padding: ${({ theme }) => theme.spacing(0, 1.5)};
    }
`;
