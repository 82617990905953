import restClient from './rest-client';

interface ValidateResponse {
    success: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentData: any;
}

interface MerchantSession {
    displayName: string;
    domainName: string;
    epochTimestamp: number;
    expiresAt: number;
    merchantIdentifier: string;
    merchantSessionIdentifier: string;
    nonce: string;
    operationalAnalyticsIdentifier: string;
    retries: number;
    signature: string;
}

interface Validate {
    validationURL: string;
}

interface InitOrderData {
    productId: string | number | undefined;
    code?: string | undefined;
}

interface OrderResponse {
    order_id: number;
    discountPrice: number;
}

interface PaymentIntentData {
    order_id: number;
    discountPrice: number;
}

interface PaymentIntentResponse {
    client_secret: string;
}

interface ConfirmOrderData {
    order_id: string | number;
}

const CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

export default {
    createOrder: (initData: InitOrderData): Promise<OrderResponse> => {
        return restClient.get<OrderResponse>(
            `/createOrderApplePay?productId=${initData.productId}&code=${initData.code}`,
            false
        );
    },

    paymentIntent: (
        data: PaymentIntentData
    ): Promise<PaymentIntentResponse> => {
        return restClient.post<PaymentIntentData, PaymentIntentResponse>(
            '/PaymentIntent',
            data,
            CONFIG
        );
    },

    confirmOrder: (order_id: ConfirmOrderData): Promise<boolean> => {
        return restClient.post<ConfirmOrderData, boolean>(
            '/ApplePayAnswer',
            order_id,
            CONFIG
        );
    },

    validateMerchant: (
        validationUrl: string
    ): Promise<MerchantSession | undefined> => {
        return restClient
            .post<Validate, ValidateResponse>('/checkMerchant', {
                validationURL: validationUrl,
            })
            .then((response) => {
                if (response.success) {
                    const session = response.paymentData;
                    return JSON.parse(JSON.parse(session)); // This is double serialized JSON.
                }

                return undefined;
            })
            .catch(() => undefined);
    },

    paymentAuthorized: (
        token: ApplePayJS.ApplePayPaymentToken
    ): Promise<void> => {
        console.log('This token should be passed to the backend here:', token);
        return Promise.reject('Backend payment processing not yet implemented');
    },
};
