import * as actionTypes from './_actionTypes';
import {
    ClearConversationSwiperPositionsAction,
    SetConversationSwiperPositionAction,
} from './_types';

export const setConversationSwiperPosition = (
    key: number,
    page: number
): SetConversationSwiperPositionAction => ({
    type: actionTypes.SET_CONVERSATION_SWIPER_POSITION,
    payload: { key, page },
});
export const clearConversationSwiperPositions =
    (): ClearConversationSwiperPositionsAction => ({
        type: actionTypes.CLEAR_CONVERSATION_SWIPER_POSITIONS,
    });
