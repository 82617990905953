import produce, { Draft } from 'immer';
import { AppSettingsState } from './_types';
import { initialState } from './_initialState';
import {
    CHANGE_LANGUAGE,
    SET_DEVICE_TYPE,
    SET_SCROLL_POS_FAVOURITE_QUESTIONS_PAGE,
    SET_SCROLL_POS_FAVOURITES_PAGE,
    SET_SCROLL_POS_HOME_PAGE,
    SET_SCROLL_POS_MY_COLLABORATIONS_PAGE,
    SET_SCROLL_POS_PREMIUM_BROWSE_PAGE,
    SET_SCROLL_POS_PRO_BROWSE_PAGE,
    SET_SCROLL_POS_PRO_HOME_PAGE,
    SET_IS_CREATING_COMPANY,
} from './_actionTypes';

const appSettingsReducer = produce(
    (
        draft: Draft<AppSettingsState> = initialState,
        action
    ): AppSettingsState => {
        switch (action.type) {
            case SET_DEVICE_TYPE:
                draft.device = action.payload;
                break;
            case CHANGE_LANGUAGE:
                draft.language = action.payload;
                break;
            case SET_SCROLL_POS_HOME_PAGE:
                draft.scrollPosHomePage = action.payload;
                break;
            case SET_SCROLL_POS_PREMIUM_BROWSE_PAGE:
                draft.scrollPosPremiumBrowsePage = action.payload;
                break;
            case SET_SCROLL_POS_PRO_HOME_PAGE:
                draft.scrollPosProHomePage = action.payload;
                break;
            case SET_SCROLL_POS_PRO_BROWSE_PAGE:
                draft.scrollPosProBrowsePage = action.payload;
                break;
            case SET_SCROLL_POS_FAVOURITES_PAGE:
                draft.scrollPosFavouritesPage = action.payload;
                break;
            case SET_SCROLL_POS_FAVOURITE_QUESTIONS_PAGE:
                draft.scrollPosFavouriteQuestionsPage = action.payload;
                break;
            case SET_SCROLL_POS_MY_COLLABORATIONS_PAGE:
                draft.scrollPosCollaborationsPage = action.payload;
                break;
            case SET_IS_CREATING_COMPANY:
                draft.isCreatingCompany = action.payload;
                break;
        }
        return draft;
    }
);

export default appSettingsReducer;
