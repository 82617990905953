import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FreemiumRoutes from 'routes/freemium-routes';
import PremiumRoutes from 'routes/premium-routes';
import { AuthContainer } from 'auth-container';
import { authenticatedSelector } from 'store-modules/auth';
import { DeepLink } from 'components/deep-link';
import { isPremiumSelector, userSelector } from 'store-modules/user';
import useHistoryRouter from 'hooks/use-history-router';

/**
 * This is the entry point for all routing.
 */
export const AppRouter: React.FC = () => {
    const isPremium = useSelector(isPremiumSelector);
    const authenticated = useSelector(authenticatedSelector);
    const user = useSelector(userSelector);
    const isProUser = !!user?.organization;

    const { redirectOnPayment, conditionallyRedirectOnLogin } =
        useHistoryRouter();

    useEffect(() => {
        if (
            !isProUser &&
            !isPremium &&
            (user?.registeredType === 'google' ||
                user?.registeredType === 'apple')
        )
            redirectOnPayment(user);

        // // Redirect when clicking on email link for account confirmation.
        if (authenticated && user) conditionallyRedirectOnLogin(user);
    }, [isProUser, isPremium, user, authenticated]);

    return (
        <Fragment>
            <DeepLink />

            <AuthContainer>
                {
                    // Only when authenticated and active subscription = premium routes
                    // Freemium funnels everything else; freemium logged in, subscription completion
                    authenticated && isPremium ? (
                        <PremiumRoutes />
                    ) : (
                        <FreemiumRoutes />
                    )
                }
            </AuthContainer>
        </Fragment>
    );
};
