import React from 'react';
import classnames from 'classnames';
import styles from './../button/button.module.scss';
import { useWindowSize } from 'hooks';

interface OwnProps {
    appearance?: 'cta' | 'primary' | 'secondary' | 'primary-squashed';
    size?: 'normal' | 'small' | 'large';
    colorTheme?: 'light' | 'dark';
    fullWidth?: boolean;
    className?: string;
    children: React.ReactNode;
    isRandomQuestions?: boolean;
}

type Props = OwnProps &
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >;

const Button: React.FC<Props> = ({
    appearance = 'primary',
    className: customClassName,
    fullWidth = false,
    size = 'normal',
    children,
    colorTheme = 'light',
    isRandomQuestions,
    ...rest
}: Props): React.ReactElement => {
    const className = classnames(
        styles.button,
        styles[appearance],
        styles[size],
        customClassName,
        fullWidth && styles.fullWidth,
        colorTheme && styles[colorTheme]
    );
    const { height } = useWindowSize(window);
    const buttonContent = isRandomQuestions ? (
        <button
            className={className}
            {...rest}
            style={{
                top: height - 100 + 'px',
            }}
        >
            {children}
        </button>
    ) : (
        <button className={className} {...rest}>
            {children}
        </button>
    );

    return buttonContent;
};

export default Button;
