import * as React from 'react';
import { copyNO, copyEN } from './copy';
import styles from './privacy-terms.module.scss';
import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { returnCopyForLanguage } from 'services/language-service';

type Props = { language: Language };
interface ViewCopy {
    title: string;
    items: {
        title: string;
        copy: JSX.Element;
    }[];
}
const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: copyNO,
    },
    {
        name: 'en',
        copy: copyEN,
    },
];
const PrivacyTerms: React.FC<Props> = ({ language }: Props) => {
    const { title, items } = returnCopyForLanguage(language, copies);
    // const confirmText = 'Jeg godkjenner vilkårene';
    const contentBody = items.map(({ title, copy }, index) => {
        const titleId = `title-q-${index}`;

        return (
            <React.Fragment key={index}>
                <h3 id={titleId}>{title}</h3>
                <section aria-labelledby={titleId}>{copy}</section>
            </React.Fragment>
        );
    });

    return (
        <section
            data-testid='component-terms-conditions'
            className={styles.PrivacyTerms}
            aria-labelledby={title}
        >
            <PageTitle id={title} title={title} />

            {contentBody}
        </section>
    );
};

export default PrivacyTerms;
