import React, { FC } from 'react';
import merge from 'deepmerge';

import {
    alpha,
    Box,
    BoxProps,
    createTheme,
    ThemeOptions,
    ThemeProvider,
} from 'components/base';
import { Breakpoint, useTheme } from '@mui/material/styles';

// --------------------------------------
// COLORS
// --------------------------------------

// Black nuances
const black = {
    [20]: '#2F2F37', // Main
    [30]: '#464653',
    [40]: '#5E5E6E',
};

// Blue nuances
const blue = {
    [20]: '#263340',
    [30]: '#324D67', // Main
    [80]: '#BFC8D9',
    [90]: '#DFE5EC',
    [95]: '#EFF1F5',
};

// Red nuances
const red = {
    [40]: '#BD2C0F',
    [50]: '#E64C19',
    [65]: '#F2735A',
    [70]: '#F48771', // Main
    [80]: '#F7AFA1',
    [95]: '#FDEBE7',
};

// Brow nuances
const brown = {
    [75]: '#D2B9AC',
    [85]: '#E4D5CD',
    [95]: '#F6F1EE',
    [98]: '#FBF9F8',
};

export const BRAND_COLOR = blue[30];
const SECONDARY_COLOR = red[70];
const TEXT_COLOR = '#2F2F37';
const TEXT_COLOR_SECONDARY = '#717179';
const ERROR_COLOR = '#c43f17';
const ERROR_COLOR_DARK = red[40];

const CREAM = '#e3d4ce';
const SURF_CREST = '#D1E4D0';

const conversation = {
    theme: blue[30],
    green: SURF_CREST,
    turqoise: '#EEF5F5',
    lightRed: red[95],
    red: red[80],
    yellow: '#FCE8B3',
    orange: '#FBDBBB',
    pink: '#EBDBEB',
};

const proConversation = {
    company: black[20],
    brown: brown[85],
    purple: '#CBCBD3',
    lightGrey: '#EFEFEF',
    pink: '#F0E6EA',
    yellow: '#F7EDE2',
    grey: '#D4D9D8',
};

export const BORDER_WIDTH = '2px';
export const DEFAULT_BORDER_RADIUS = 8;

const breakpoints: Record<Breakpoint, number> = {
    xs: 0, // Default
    sm: 600, // Default
    md: 768, // Custom
    lg: 1048, // Custom
    xl: 1366, // Custom
};

/**
 * Base variables common for light and dark theme
 */
const baseTheme: ThemeOptions = {
    breakpoints: { values: breakpoints },
    palette: {
        action: { hoverOpacity: 0.051, selectedOpacity: 0.11 },
        custom: {
            black,
            blue,
            brown,
            conversation,
            proConversation,
            red,
        },
        divider: blue[90],
        error: { main: ERROR_COLOR, dark: ERROR_COLOR_DARK },
        info: { main: SURF_CREST, dark: '#bad1b9' },
        primary: { main: BRAND_COLOR },
        secondary: { main: SECONDARY_COLOR, dark: red[65] },
    },
    shape: { borderRadius: DEFAULT_BORDER_RADIUS },
    typography: {
        fontFamily:
            "'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        button: {
            textTransform: 'none',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.25,
        },
        body2: {
            fontSize: 18,
            lineHeight: 1.25,
        },
        body1: { lineHeight: 1.25 },
        caption: { fontSize: 11, lineHeight: 1.25 },
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            lineHeight: '3.5rem',
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '2rem',
        },
        h3: {
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: '2rem',
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
        },
        question: {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: '2rem',
            [`@media (min-width: ${breakpoints.lg}px)`]: {
                fontWeight: 'normal',
                fontSize: '2.1rem',
                lineHeight: '2.8rem',
            },
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: { disableRipple: true },
            styleOverrides: {
                root: {
                    '&:focus-visible': {
                        borderColor: 'transparent',
                        outline: `${BORDER_WIDTH} dotted currentColor`,
                        outlineOffset: -2,
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'large',
            },
            styleOverrides: {
                root: {
                    '.MuiButton-startIcon': {
                        marginLeft: -8,
                        '& svg': {
                            fontSize: '24px !important',
                        },
                    },
                    '.MuiButton-endIcon': {
                        marginRight: -8,
                        '& svg': {
                            fontSize: '24px !important',
                        },
                    },
                    fontSize: 16,
                    paddingBottom: 0,
                    paddingTop: 0,
                    transition: 'none',
                },
                sizeSmall: {
                    minHeight: 40,
                    borderRadius: 40 / 2,
                    paddingLeft: 24,
                    paddingRight: 24,
                },
                sizeLarge: {
                    minHeight: 56,
                    borderRadius: 56 / 2,
                    paddingLeft: 40,
                    paddingRight: 40,
                },
                contained: {
                    color: TEXT_COLOR,
                    fontSize: 14,
                    letterSpacing: 0.56,
                    textTransform: 'uppercase',
                },
                containedSecondary: {
                    boxShadow: `0 1px 2px ${alpha(BRAND_COLOR, 0.2)}`,
                },
                outlined: {
                    borderWidth: BORDER_WIDTH,
                    borderColor: 'currentcolor',
                    '&:hover': {
                        borderWidth: BORDER_WIDTH,
                    },
                    '&:disabled': {
                        borderWidth: BORDER_WIDTH,
                    },
                },
            },
        },
        MuiCard: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    borderRadius: 9999,
                    paddingLeft: 4,
                    paddingRight: 4,
                },
                sizeMedium: {
                    height: 40,
                },
                sizeSmall: {
                    height: 32,
                },
                outlined: { borderColor: '#60809f' },
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    elevation: 0,
                },
                transitionDuration: 100,
            },
            styleOverrides: {
                paper: {
                    borderRadius: 32,
                    paddingBottom: 38,
                    paddingLeft: 38,
                    paddingRight: 38,
                    paddingTop: 24,
                    width: 600,
                },
                paperFullScreen: {
                    backgroundColor: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 'env(safe-area-inset-top)',
                    width: '100%',
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                align: 'center',
                component: 'h2',
                variant: 'h4',
            },
        },
        MuiDrawer: {
            defaultProps: {
                anchor: 'bottom',
                elevation: 8,
            },
            styleOverrides: {
                paper: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 24,
                    paddingBottom: 38,
                },
                paperAnchorBottom: {
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 32,
                },
            },
        },
        MuiFab: {
            defaultProps: { size: 'medium' },
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&.Mui-focusVisible': { boxShadow: 'none' },
                    '&:active': { boxShadow: 'none' },
                },
                sizeMedium: { height: 48, width: 48 },
                secondary: {
                    background: CREAM,
                    color: BRAND_COLOR,
                    '&:hover': {
                        background: brown[75],
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    fontSize: 14,
                    gap: 8,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: { '& svg': { fontSize: '22px' } },
                sizeLarge: { height: 56, width: 56 },
                sizeMedium: { height: 48, width: 48 },
            },
        },
        MuiLink: {
            defaultProps: { variant: 'body1' },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: 12,
                    paddingLeft: 20,
                    paddingRight: 24,
                    '&:hover': {
                        background: brown[85],
                    },
                    '&.Mui-focusVisible:not(:hover)': {
                        background: 'none',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: BRAND_COLOR,
                    fontSize: '1rem',
                    fontWeight: 600,
                },
                secondary: {
                    fontSize: 13,
                    fontStyle: 'italic',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    alignSelf: 'flex-start',
                    marginTop: 2,
                    marginBottom: 2,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    '.MuiDivider-root': {
                        marginLeft: 20,
                        marginRight: 20,
                        background: brown[85],
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: { shrink: true, style: { color: 'inherit' } },
            },
            styleOverrides: {
                root: {
                    label: {
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        letterSpacing: 0.34,
                        marginBottom: 4,
                        marginLeft: 4,
                        marginRight: 4,
                        position: 'initial',
                        textAlign: 'left',
                        transform: 'none',
                    },
                    '&:not(.MuiFormControl-fullWidth)': {
                        width: 'min-content',
                    },
                    '.MuiInputBase-root': {
                        color: TEXT_COLOR,
                        marginTop: 0,
                        backgroundColor: 'white',
                        padding: '10.5px 12px',
                        borderRadius: DEFAULT_BORDER_RADIUS,
                        border: `${BORDER_WIDTH} solid ${blue[90]}`,
                        '.MuiInputBase-input': {
                            padding: 0,
                            paddingLeft: 2,
                            boxShadow: '0 0 0 9999px white inset',
                            '&::placeholder': {
                                color: TEXT_COLOR_SECONDARY,
                                opacity: 1,
                            },
                        },
                        '&.Mui-error': {
                            borderColor: SECONDARY_COLOR,
                        },
                    },
                    '.MuiInputBase-multiline': {
                        padding: 0,
                        overflow: 'hidden',
                        '.MuiInputBase-input': {
                            padding: '10.5px 12px',
                        },
                    },
                    '&:not(.hideFocusIndication) .Mui-focused.MuiInputBase-root':
                        {
                            borderStyle: 'dotted',
                            '&:not(.Mui-error)': {
                                borderColor: BRAND_COLOR,
                            },
                        },
                    '&.rounded .MuiInputBase-root': {
                        borderRadius: 9999,
                    },
                    '&:not(.MuiFormControl-fullWidth) .MuiInputBase-root': {
                        width: 311,
                    },
                    '.MuiInputAdornment-root': {
                        color: 'inherit',
                    },
                    '.MuiFormHelperText-root': {
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                    },
                },
            },
        },
    },
    zIndex: {
        fab: 1,
        drawer: 1300,
    },
};

/**
 * Theme variables specific for light theme
 */
const lightThemeVariables: ThemeOptions = {
    palette: {
        text: {
            primary: TEXT_COLOR,
            secondary: alpha(TEXT_COLOR, 0.7),
        },
        action: { active: TEXT_COLOR },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(BRAND_COLOR, 0.4),
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: black[40],
                    '&.Mui-error': {
                        color: ERROR_COLOR_DARK,
                    },
                },
            },
        },
    },
};

/**
 * Light theme (default)
 */
export const lightTheme = createTheme(merge(baseTheme, lightThemeVariables));

/**
 * Theme variables specific for dark theme
 */
const darkThemeVariables: ThemeOptions = {
    palette: {
        background: { default: black[20] },
        error: { main: SECONDARY_COLOR },
        mode: 'dark',
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(black[20], 0.7),
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: lightTheme.palette.text.primary,
                    background: lightTheme.palette.background.paper,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: lightTheme.palette.text.primary,
                    background: lightTheme.palette.background.paper,
                },
            },
        },
    },
};

/**
 * Dark theme
 */
export const darkTheme = createTheme(merge(baseTheme, darkThemeVariables));

// Theme providers
export const LightThemeProvider: FC<Omit<BoxProps, 'sx'>> = ({
    children,
    ...rest
}) => (
    <ThemeProvider theme={lightTheme}>
        <Box sx={(theme) => ({ color: theme.palette.text.primary })} {...rest}>
            {children}
        </Box>
    </ThemeProvider>
);

export const DarkThemeProvider: FC<Omit<BoxProps, 'sx'>> = ({
    children,
    ...rest
}) => (
    <ThemeProvider theme={darkTheme}>
        <Box sx={(theme) => ({ color: theme.palette.text.primary })} {...rest}>
            {children}
        </Box>
    </ThemeProvider>
);

type DefaultColor = {
    colorName: 'inherit' | 'primary';
    color: string;
};

export const useDefaultColor = (): DefaultColor => {
    const theme = useTheme();

    return {
        colorName: theme.palette.mode === 'dark' ? 'inherit' : 'primary',
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.text.primary
                : theme.palette.primary.main,
    };
};
