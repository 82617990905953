import React from 'react';
import styles from './azure-login-button.module.scss';
import { RouteList } from 'api/routes';
import { RootReducer } from 'store/store';
import {
    selectedProductSelector,
    setUserState,
    setUserSub,
} from 'store-modules/user';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as MicrosoftIcon } from '../../images/icons/microsoft.svg';
import { Button } from 'components/base';
import '@codetrix-studio/capacitor-google-auth';
import AzureAuthenticationContext from './azure-authentication-context';
import { AccountInfo } from '@azure/msal-browser';
import { logInByAzure } from 'common-logic/login';
import { Plugins } from '@capacitor/core';
import { IS_MOBILE_APP, IS_IOS_APP, IS_ANDROID_APP } from '../../constants';
import jwt_decode from 'jwt-decode';
import { AzureNativeUser } from '_types';
import { getEnv } from 'api/rest-client';
import { useNavigate } from 'react-router-dom';

const { MsAuthPlugin } = Plugins;

type PropsOwn = {
    text: string;
    routes: RouteList;
    setLoading: (arg: boolean) => void;
};

const mapStateToProps = (state: RootReducer) => ({
    selectedProduct: selectedProductSelector(state),
});

type PropsFromState = ReturnType<typeof mapStateToProps>;
type Props = PropsOwn & PropsFromState;

const AzureLoginButton: React.FC<Props> = ({ text, routes, setLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const msie11 = ua.indexOf('Trident/');
    const isIE = msie > 0 || msie11 > 0;
    // Azure client context
    const authenticationModule: AzureAuthenticationContext =
        new AzureAuthenticationContext();

    //COMMON
    const fuelboxLogin = async (
        userId: string,
        userName: string,
        tenantId: string,
        givenName: string,
        familyName: string
    ) => {
        // //Fuelbox Login
        let isFirstAzureLogin = false;
        const loginResult = await logInByAzure(
            userId,
            userName,
            tenantId,
            givenName,
            familyName
        );

        isFirstAzureLogin = loginResult.firstAzureLogin;
        loginResult.subscription &&
            dispatch(setUserSub(loginResult.subscription));
        loginResult.user && dispatch(setUserState(loginResult.user));

        if (!!loginResult.subscription) {
            const stillActiveStatuses = ['active', 'pending-cancel'];
            const status = loginResult.subscription.data.status;
            const stillActive = stillActiveStatuses.includes(status);
            const isProUser = !!loginResult.user?.organization;
            const proContentDisabled =
                !!loginResult.subscription.data.pro_content_disabled;

            const route = stillActive
                ? isProUser && !proContentDisabled
                    ? routes.proindex
                    : routes.index
                : routes.account.index;

            navigate(isFirstAzureLogin ? routes.userConfirmPro : route, {
                state: isFirstAzureLogin && {
                    firstAzureLogin: isFirstAzureLogin,
                },
            });
        } else {
            navigate(routes.error);
        }
    };

    //WEB
    const returnedAccountInfo = async (user: AccountInfo) => {
        const userId = user.homeAccountId.split('.')[0];
        const givenName = user.name!.toString().split(' ')[0];
        const familyName = user.name!.toString().split(' ')[1];

        await fuelboxLogin(
            userId,
            user.username,
            user.tenantId,
            givenName,
            familyName
        );
    };

    //WEB
    const logIn = () => {
        const typeName = 'loginPopup';
        const logInType = isIE ? 'loginRedirect' : typeName;

        // Azure Login
        authenticationModule.login(logInType, returnedAccountInfo);
    };

    //COMMON
    const loginToSystem = async (): Promise<void> => {
        const environment = getEnv();
        const isProd =
            environment === 'prod' || environment === 'ios' || IS_ANDROID_APP;

        setLoading(true);

        try {
            if (!IS_MOBILE_APP) {
                //WEB
                await logIn();
            } else {
                //ANDROID & IOS
                await MsAuthPlugin.login({
                    clientId: isProd
                        ? process.env.REACT_APP_AZURE_CLIENT_ID
                        : process.env.REACT_APP_AZURE_CLIENT_ID_TEST,
                    tenant: IS_IOS_APP ? null : 'organizations',
                    scopes: IS_IOS_APP ? null : ['profile'],
                    keyHash: IS_IOS_APP
                        ? null
                        : process.env.REACT_APP_AZURE_ANDROID_KEY_HASH,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }).then(async (response: any) => {
                    const decoded: AzureNativeUser = jwt_decode(
                        response.accessToken
                    );

                    if (decoded) {
                        const userId = decoded['oid'];
                        const tenantId = decoded['tid'];
                        const userName = decoded['upn'];
                        const givenName = decoded['given_name'];
                        const familyName = decoded['family_name'];

                        await fuelboxLogin(
                            userId,
                            userName,
                            tenantId,
                            givenName,
                            familyName
                        );
                    }
                });
            }
        } catch (err) {
            console.error(err);
            navigate(routes.error);
        }
        setLoading(false);
    };

    return authenticationModule.isAuthenticationConfigured ? (
        <Button
            className={styles.azureButton}
            fullWidth
            onClick={loginToSystem}
        >
            <MicrosoftIcon />
            <span>{text}</span>
        </Button>
    ) : null;
};

export default connect(mapStateToProps)(AzureLoginButton);
