import { Discount } from '../../_types';
import React from 'react';
import { Language } from '_types/language';
import { DiscountCodeInput } from './input-form';
import { DiscountCodePrice } from './price-line';

type Props = {
    language: Language;
    discount: Discount | undefined;
    onCodeSelected: (text: string) => void;
    onCodeCleared: () => void;
    currency: string | undefined;
};

const DiscountCode: React.FC<Props> = ({
    language,
    discount,
    onCodeSelected,
    onCodeCleared,
    currency,
}: Props) => (
    <div>
        <DiscountCodeInput
            language={language}
            onCodeSelected={onCodeSelected}
            onCodeCleared={onCodeCleared}
            discount={discount}
        />
        {!!discount?.discountedPrice && (
            <DiscountCodePrice
                language={language}
                discountedPrice={discount.discountedPrice}
                currency={currency}
            />
        )}
    </div>
);

export default DiscountCode;
