import { Product } from 'pages/create-premium-page/_types';
import { User } from '_types';
import { Subscription } from '_types/subscription';
import * as actionTypes from './_actionTypes';
import {
    ClearUserAction,
    SetSelectedProductAction,
    SetUserAction,
    SetUserSubAction,
    PaymentSessionAction,
    SetToastMessageAction,
    SetBoldToastMessageAction,
} from './_types';
import { SessionResponse } from 'pages/create-premium-page/_services';

export const setUserState = (user: User): SetUserAction => ({
    type: actionTypes.SET_USER,
    payload: user,
});

export const clearUserState = (): ClearUserAction => ({
    type: actionTypes.CLEAR_USER,
});

export const setUserSub = (sub: Subscription): SetUserSubAction => ({
    type: actionTypes.SET_SUBSCRIPTION,
    payload: sub,
});

export const productSelected = (
    product: Product
): SetSelectedProductAction => ({
    type: actionTypes.SELECTED_PRODUCT,
    payload: product,
});

export const newPaymentSession = (
    session: SessionResponse
): PaymentSessionAction => ({
    type: actionTypes.PAYMENT_SESSION,
    payload: session,
});

export const setToastMessage = (
    toastMessage: string
): SetToastMessageAction => ({
    type: actionTypes.SET_TOAST_MESSAGE,
    payload: toastMessage,
});

export const setBoldToastMessage = (
    boldToastMessage: string
): SetBoldToastMessageAction => ({
    type: actionTypes.SET_BOLD_TOAST_MESSAGE,
    payload: boldToastMessage,
});
