import * as React from 'react';
import styles from './company-signup-confirmation-page.module.scss';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { SEO } from 'components/seo';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'components/layout';
import { FreemiumNavbar } from 'components/freemium-navbar';
import { HeaderProps } from 'components/page-header';
import {
    setBoldToastMessage,
    setToastMessage,
    toastMessageSelector,
    userSelector,
} from 'store-modules/user';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import RotatingSpinner from 'components/rotating-spinner/rotating-spinner';
import { useWindowSize } from 'hooks';
import { ToastMessage } from 'components/toast-message';
import { ReactComponent as IconCheck } from 'images/icons/check.svg';
import userClient from 'api/user-client';
import { getUAInfo } from 'services/user-agent.service';
import classnames from 'classnames';

interface ViewCopy {
    title: string;
    instructions1: string;
    instructions2: string;
    newEmailLabel: string;
    wrongEmailLabel: string;
    sendEmailButtonLabel: string;
    emailPlaceholder: string;
    emailError: string;
    emailSent: string;
}

const nbCopy: ViewCopy = {
    title: 'Snart ferdig!',
    instructions1: 'Klikk på lenken i eposten vi har sendt til ',
    instructions2: 'for å bekrefte epostadressen din.',
    newEmailLabel: 'Send ny e-post',
    wrongEmailLabel: 'Feil epostadresse',
    sendEmailButtonLabel: 'Send epost',
    emailPlaceholder: 'Oppgi ny epostadresse',
    emailError: 'Dette ser ikke ut som en fullstendig epostadresse',
    emailSent: 'Ny epost sendt',
};

const enCopy: ViewCopy = {
    title: 'Almost there!',
    instructions1: 'Click the link in the email we sent to ',
    instructions2: 'to confirm your email address.',
    newEmailLabel: 'Send new email',
    wrongEmailLabel: 'Wrong email address',
    sendEmailButtonLabel: 'Send email',
    emailPlaceholder: 'New email address',
    emailError: "This doesn't look like a complete email address",
    emailSent: 'Email sent',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const CreateAccountConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const user = useSelector(userSelector);
    const {
        title,
        instructions1,
        instructions2,
        newEmailLabel,
        wrongEmailLabel,
        sendEmailButtonLabel,
        emailPlaceholder,
        emailError,
        emailSent,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const dispatch = useDispatch();
    const { view } = useWindowSize(window);
    const isDesktop = view === 'desktop';
    const { isiOS } = getUAInfo(window.navigator);
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
    const [emailInputValue, setEmailInputValue] = useState<string>('');
    const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
    const toastMessage = useSelector(toastMessageSelector);
    // const boldToastMessage = useSelector(boldToastMessageSelector);
    const [toastToShow, setToastToShow] = useState<string>(toastMessage);
    const [emailFormatError, setEmailFormatError] = useState<boolean>(false);
    const isMobile = view === 'mobile';

    useEffect(() => {
        return () => {
            setTimeout(() => setToastToShow(''), 6000);
        };
    });

    useEffect(() => {
        return () => {
            dispatch(setToastMessage(''));
            dispatch(setBoldToastMessage(''));
        };
    }, [dispatch]);

    const toastMessageContainer = toastToShow.length > 0 && (
        <div className={styles.ToastContainer}>
            <ToastMessage
                className={
                    toastToShow.length > 0
                        ? classnames(styles.Toast, isiOS && styles.IOSToast)
                        : styles.Hidden
                }
                icon={<IconCheck />}
                message={toastToShow}
                // boldMessage={boldToastMessage}
            />
        </div>
    );

    const headerProps = (): HeaderProps => {
        if (user) {
            return {
                withLogin: true,
                showLogoLink: true,
            };
        }
        return {
            customNavigation: FreemiumNavbar,
            withLogin: true,
            showLogoLink: true,
        };
    };

    const onInputChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setEmailInputValue(event.target.value);
            setEmailFormatError(false);
        },
        []
    );

    const setNewEmail = () => {
        setIsSendingEmail(true);
        userClient
            .changeEmail(emailInputValue, true)
            .then((response) => {
                if (response === 'invalid_email') {
                    setIsSendingEmail(false);
                    setEmailFormatError(true);
                } else {
                    setTimeout(() => {
                        setIsSendingEmail(false);
                        setToastToShow(emailSent);
                        setShowEmailInput(false);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error(error);
                navigate(routes.error);
            });
    };

    const changeEmailButton = (
        <button
            className={styles.ChangeEmailButton}
            onClick={() => {
                setNewEmail();
            }}
        >
            {isSendingEmail ? <RotatingSpinner /> : sendEmailButtonLabel}
        </button>
    );

    const sendNewEmail = () => {
        userClient.resendConfirmationEmail(user!.email).then(() => {
            setToastToShow(emailSent);
        });
    };

    return (
        <>
            {toastMessageContainer}
            <SEO noIndex={true} />
            <div className={styles.BackgroundContainer}>
                <Layout
                    routes={routes}
                    language={language}
                    pageClassName={classnames(
                        styles.PageLayout,
                        isiOS && styles.PageLayoutIOS
                    )}
                    headerProps={headerProps()}
                    withFooter={false}
                >
                    <SEO title={title} />
                    <h2>{title}</h2>
                    <div className={styles.Instruction1}>
                        {instructions1}{' '}
                        <div className={styles.Email}> {user?.email} </div>
                    </div>
                    <p className={styles.Instruction2}>{instructions2}</p>
                    <p>
                        <button
                            type='button'
                            className={styles.ButtonLink}
                            onClick={() => sendNewEmail()}
                        >
                            {newEmailLabel}
                        </button>
                    </p>
                    <p>
                        <button
                            type='button'
                            className={styles.ButtonLink}
                            onClick={() => setShowEmailInput(true)}
                        >
                            {wrongEmailLabel}
                        </button>
                    </p>

                    {showEmailInput && (
                        <div className={styles.UpperChangeEmailContainer}>
                            <div
                                className={
                                    styles.ChangeEmailFieldOuterContainer
                                }
                            >
                                <div
                                    className={
                                        emailFormatError
                                            ? classNames(
                                                  styles.ChangeEmailFieldContainer,
                                                  styles.ErrorField
                                              )
                                            : styles.ChangeEmailFieldContainer
                                    }
                                >
                                    <input
                                        type={'text'}
                                        id={'change_email'}
                                        placeholder={emailPlaceholder}
                                        className={styles.ChangeEmailField}
                                        value={emailInputValue}
                                        autoComplete='off'
                                        onChange={onInputChanged}
                                        onKeyDown={(e) => {
                                            if (primaryKeyPressed(e)) {
                                                if (e.key === 'Enter') {
                                                    setNewEmail();
                                                    try {
                                                        (
                                                            document.activeElement as HTMLElement
                                                        ).blur();
                                                    } catch {
                                                        console.error(
                                                            'Failed to hide native keyboard.'
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                        onFocus={onInputChanged}
                                    ></input>
                                    {!isMobile && changeEmailButton}
                                </div>
                                {emailFormatError && isMobile && (
                                    <div className={styles.EmailError}>
                                        {emailError}
                                    </div>
                                )}
                                {!isDesktop && changeEmailButton}
                            </div>
                            {emailFormatError && !isMobile && (
                                <div className={styles.EmailError}>
                                    {emailError}
                                </div>
                            )}
                        </div>
                    )}
                </Layout>
            </div>
        </>
    );
};

export default CreateAccountConfirmation;
