export enum SlideType {
    START,
    QUESTION,
    FREEMIUM_END,
    PREMIUM_END,
    PRO_END,
}

export interface Slide<T = Record<string, unknown>> {
    id: string | number;
    index: number;
    slug: string;
    title: string;
    slideType: SlideType;
    data?: T;
}
