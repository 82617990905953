import React, { FC, forwardRef } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { TextField, TextFieldProps } from '../fields/text-input/text-field';
import { RFF_FieldProps } from './utils';

export type TextFieldBinderProps = Pick<
    FieldRenderProps<string>,
    'input' | 'meta'
> &
    Omit<TextFieldProps, 'onChange' | 'value'>;

/**
 * Helper component for binding TextField to React Final Form
 */
export const TextFieldBinder: FC<TextFieldBinderProps> = forwardRef(
    (
        {
            input: { name, onChange, value, ...restInput },
            meta: { submitError },
            inputProps,
            ...rest
        },
        ref
    ) => (
        <TextField
            {...rest}
            error={submitError}
            inputProps={{ ...restInput, ...inputProps }}
            name={name}
            onChange={onChange}
            ref={ref}
            value={value}
        />
    )
);

export type RFF_TextFieldProps = RFF_FieldProps<TextFieldProps>;

/**
 * Binding component for usage with React Final Form
 */
export const RFF_TextField: FC<RFF_TextFieldProps> = forwardRef(
    (props, ref) => <Field component={TextFieldBinder} {...props} ref={ref} />
);
