import { createContext } from 'react';

import { Collaboration } from 'api/collaboration-client';
import { Deck, Question } from '_types';

export type CollaborationValues = {
    closeCollaborationView: () => void;
    collaboration: Collaboration;
    collaborationId: string;
    interactiveMode?: boolean;
    deck: Deck;
    questions: Question[];
};

const CollaborationContext = createContext<CollaborationValues>({
    closeCollaborationView: () => undefined,
    questions: [],
    collaboration: {} as Collaboration,
    deck: {} as Deck,
    collaborationId: '',
});

export default CollaborationContext;
