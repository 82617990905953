import React, {
    FC,
    useEffect,
    useMemo,
    useRef,
    Dispatch,
    SetStateAction,
} from 'react';
import { nanoid } from 'nanoid';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';

import { useDefaultColor } from 'style/theme';
import { Button, ButtonProps } from '../button';

export type ToggleButtonGroupProps = BoxProps & {
    buttonProps: Omit<ButtonProps, 'appearance' | 'size'>[];
    selectedIndex?: number;
    setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
};

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
    buttonProps,
    selectedIndex,
    setSelectedIndex,
    id,
    ...rest
}) => {
    const itemsRef = useRef<HTMLButtonElement[]>([]);
    const bgRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const { color } = useDefaultColor();
    const indicatorColor = alpha(color, theme.palette.action.selectedOpacity);

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, buttonProps.length);
    }, [buttonProps.length]);

    const setIndicatorPlacement = (i: number) => {
        if (bgRef.current && itemsRef.current) {
            const left = itemsRef.current
                .slice(0, i)
                .reduce(
                    (acc, cur) => acc + cur.getBoundingClientRect().width,
                    0
                );

            const current = itemsRef.current[i];

            bgRef.current.style.transform = `translateX(${left}px)`;
            bgRef.current.style.width = `${
                current.getBoundingClientRect().width
            }px`;
            bgRef.current.style.height = `${current.scrollHeight}px`;
            bgRef.current.style.transition = theme.transitions.create('all', {
                duration: theme.transitions.duration.short,
            });
        }
    };

    const validIndex = (i?: number) =>
        i != null && i >= 0 && i <= buttonProps.length - 1;

    const setSelected = (i?: number) => {
        if (validIndex(i) && selectedIndex !== i) {
            setSelectedIndex(i);
            setIndicatorPlacement(i!);
        }
    };

    useEffect(() => {
        if (validIndex(selectedIndex)) setIndicatorPlacement(selectedIndex!);
    }, [selectedIndex]);

    const groupId = useMemo(() => id ?? nanoid(), [id]);

    return (
        <Box
            alignItems='center'
            border={`thin solid ${indicatorColor}`}
            borderRadius={9999}
            component='fieldset'
            display='flex'
            height='fit-content'
            id={groupId}
            p={0.4}
            position='relative'
            role='radiogroup'
            value={selectedIndex}
            width='fit-content'
            {...rest}
        >
            <Box
                borderRadius={9999}
                my={0.4}
                position='absolute'
                ref={bgRef}
                sx={{ background: indicatorColor }}
                width={10}
            />

            {buttonProps.map(({ onClick, ...props }, i) => (
                <Button
                    appearance='text'
                    aria-checked={selectedIndex === i}
                    key={JSON.stringify(props)}
                    name={groupId}
                    onClick={(e) => {
                        setSelected(i);
                        onClick && onClick(e);
                    }}
                    ref={(el) => el && (itemsRef.current[i] = el)}
                    role='radio'
                    size='small'
                    sx={{
                        '&:disabled': { color },
                        '&:hover': { background: 'none' },
                    }}
                    value={i}
                    {...props}
                />
            ))}
        </Box>
    );
};
