import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { ErrorBoundary } from 'components/error-boundary';
import { FreemiumNavbar } from 'components/freemium-navbar';
import { Layout } from 'components/layout';
import { SEO } from 'components/seo';
import { ErrorPage } from 'pages/error-page';
import { RegistrationStep } from '_types/registration-steps';
import { ChoosePremProd } from './choose-premium-product';
import { ChooseRegistrationType } from './choose-registration-type';
import { CreateAccountForm } from './create-account-form';
import styles from './create-premium-container.module.scss';
import { createUser, getProducts } from './_services';
import { AccountInfo, Product } from './_types';
import { RootReducer } from 'store/store';
import {
    productSelected,
    selectedProductSelector,
    userSelector,
} from 'store-modules/user';
import { connect, useSelector } from 'react-redux';
import { CopyContent } from 'components/copy-content';
import { TermsConditions } from 'components/terms-conditions';
import { PrivacyTerms } from 'components/privacy-terms';
import { authenticatedSelector } from 'store-modules/auth';
import { RegistrationErrorPage } from './registration-error-page';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { languageSelector } from 'store-modules/app-settings';
import { Language } from '_types/language';
import { setProAccount } from 'store-modules/professional';
import { LoginStatus, logUserIn } from 'common-logic/login';
import { useMountedState } from 'common-logic/use-mounted-state';
import { Loading } from 'components/loading';
import proClient from 'api/pro-client';
import { useLocation, useNavigate } from 'react-router-dom';
import useTypedParams from 'hooks/use-typed-params';

interface Params {
    procode: string;
}

const mapStateToProps = (state: RootReducer) => ({
    selectedProduct: selectedProductSelector(state),
    language: languageSelector(state),
    authenticated: authenticatedSelector(state),
});

const mapDispatchToProps = {
    productSelected,
    setProAccount,
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
type PropsFromDispatch = typeof mapDispatchToProps;
type Props = PropsFromState & PropsFromDispatch;

interface ViewCopy {
    SEO_TITLE: string;
}

const nbCopy: ViewCopy = {
    SEO_TITLE: 'Velg din FuelPlan',
};

const enCopy: ViewCopy = {
    SEO_TITLE: 'Choose your FuelPlan',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

export const PageContainer: React.FC<Props> = ({
    selectedProduct,
    language,
    authenticated,
    productSelected,
}: Props) => {
    const navigate = useNavigate();
    const isMounted = useMountedState();
    const location = useLocation();
    const { procode } = useTypedParams<Params>();
    const { SEO_TITLE } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const [step, setStep] = useState<RegistrationStep>('Products');
    const [proCodeVisible, setProCodeVisible] = useState<boolean>(false);
    const [productsList, setProductsList] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const user = useSelector(userSelector);

    useEffect(() => {
        const url = decodeURI(window.location.href);
        const isProCodeUrl =
            url.indexOf('/opprett-bruker-proff') !== -1 ||
            url.indexOf('/create-user-pro') !== -1;

        if (isProCodeUrl) {
            setProCodeVisible(true);
            setStep('Create Account');
        }
        async function fetchProducts(): Promise<void> {
            await getProducts()
                .then((p: Product[]) => {
                    if (!isMounted()) return;
                    const products = Object.values(p);
                    setProductsList(products);
                })
                .catch(() => navigate(routes.error))
                .finally(() => {
                    setLoading(false);
                });
        }
        fetchProducts();
    }, [navigate, routes.error, isMounted, location]);

    // * update store with selected prod
    const upateSelectedProduct = (product?: Product): void => {
        if (product) {
            productSelected(product);
            setStep('Choose registration');
        }
    };

    const moveToRegistrationByEmail = (enterProCode: boolean): void => {
        if (!!user) {
            navigate(routes.proCodeSubPage);
        }
        setProCodeVisible(enterProCode || false);
        setStep('Create Account');
    };

    const onCreateAccount = async (accountInfo: AccountInfo): Promise<void> => {
        if (!selectedProduct && !proCodeVisible) return setStep('Products');

        //Validate ProCode
        if (proCodeVisible) {
            await proClient
                .validateCode(accountInfo?.proCode || '', accountInfo.email)
                .then((response) => {
                    if (response?.data?.status !== 204) {
                        return response?.data?.status;
                    }
                });
        }

        await createUser(accountInfo, selectedProduct?.id);
        const loginResult = await logUserIn(
            accountInfo.email,
            accountInfo.password,
            (organization) => setProAccount(organization)
        );
        if (loginResult.loginStatus !== LoginStatus.Ok)
            throw Error('Could not log the user in: ' + loginResult);

        navigate(routes.paymentOptionSelect);
    };

    const onFailure = (): void => {
        setStep('Error');
    };

    const viewTnC = (): void => setStep('Terms & Conditions');
    const viewPrivacyTerms = (): void => setStep('Privacy');
    const goBackFromTnC = (): void => {
        window.scrollTo(0, 0);
        setStep('Create Account');
    };
    const goBackFromPrivacy = (): void => {
        window.scrollTo(0, 0);
        setStep('Create Account');
    };

    const backgroundStyle = (step: RegistrationStep): string =>
        step === 'Create Account' ||
        step === 'Account Confirmation' ||
        step === 'Choose registration'
            ? styles.ColorBg
            : styles.PlainBg;

    const ui = (item: RegistrationStep): JSX.Element | null => {
        switch (item) {
            case 'Products':
                return (
                    <ChoosePremProd
                        products={productsList}
                        onProductSelected={upateSelectedProduct}
                        moveToRegistrationByEmail={moveToRegistrationByEmail}
                    />
                );
            case 'Choose registration':
                return (
                    <ChooseRegistrationType
                        moveToRegistrationByEmail={moveToRegistrationByEmail}
                        setLoading={setLoading}
                    />
                );
            case 'Create Account':
                return (
                    <CreateAccountForm
                        createAccountHandler={onCreateAccount}
                        viewTnCHandler={viewTnC}
                        viewPrivacyTermsHandler={viewPrivacyTerms}
                        onFailure={onFailure}
                        proCodeVisible={proCodeVisible}
                        proCode={procode}
                    />
                );
            case 'Terms & Conditions':
                return (
                    <CopyContent
                        language={language}
                        className={styles.copy}
                        goBackHandler={goBackFromTnC}
                    >
                        <TermsConditions language={language} />
                    </CopyContent>
                );
            case 'Privacy':
                return (
                    <CopyContent
                        language={language}
                        className={styles.copy}
                        goBackHandler={goBackFromPrivacy}
                    >
                        <PrivacyTerms language={language} />
                    </CopyContent>
                );
            case 'Error':
                return (
                    <RegistrationErrorPage
                        onButtonClick={() => setStep('Products')}
                    />
                );
            default:
                return (
                    <RegistrationErrorPage
                        onButtonClick={() => setStep('Products')}
                    />
                );
        }
    };

    const headerProps = authenticated
        ? { withAppbar: false, showLogoLink: true }
        : {
              customNavigation: FreemiumNavbar,
              showLogoLink: true,
              usingProCode: proCodeVisible,
          };

    const pageClassName = classnames(
        styles.PageContainer,
        backgroundStyle(step)
    );

    return (
        <ErrorBoundary renderError={ErrorPage}>
            {loading ? (
                <Loading />
            ) : (
                <Layout
                    routes={routes}
                    language={language}
                    headerProps={headerProps}
                    pageClassName={pageClassName}
                >
                    <SEO title={SEO_TITLE} />
                    <section
                        data-testid='page-create-premium'
                        aria-labelledby='create premium account step'
                        aria-live='polite'
                        id='container-premium'
                        className={styles.container}
                    >
                        {ui(step)}
                    </section>
                </Layout>
            )}
        </ErrorBoundary>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
