import { UserState } from './_types';

export const initialState: UserState = {
    user: null,
    subscription: null,
    selectedProduct: null,
    session: {
        token: '',
        url: '',
    },
    toastMessage: '',
    boldToastMessage: '',
};
