import * as React from 'react';
import { Product } from 'pages/create-premium-page/_types';
import { ProductItem } from './product-item';

type Props = {
    products: Product[];
    productSelectHandler: (product?: Product) => void;
    navigateToCompanySignup: () => void;
};

const ProductSelect: React.FC<Props> = ({
    products,
    productSelectHandler,
    navigateToCompanySignup,
}: Props) => {
    const handleProductSelect = (id: number | string): void =>
        productSelectHandler(products.find((p) => p.id === id));

    const productItems = products.map((p, i) => (
        <li key={p.id}>
            <ProductItem
                {...p}
                selectHandler={handleProductSelect}
                highlighted={i === 0}
                navigateToCompanySignup={navigateToCompanySignup}
            />
        </li>
    ));

    return <ul data-testid='component-Product-select'>{productItems}</ul>;
};

export default ProductSelect;
