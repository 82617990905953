import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAuth, signInAnonymously } from 'firebase/auth';

import { Container } from 'components/container';
import {
    scrollPosSelectorCollaborationsPage,
    setScrollPosCollaborationsPage,
} from 'store-modules/app-settings';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { SEO } from 'components/seo';
import useHistoryRouter from 'hooks/use-history-router';
import collaborationClient, { Collaboration } from 'api/collaboration-client';
import { alpha, Box, Button, Grid, styled, Typography } from 'components/base';
import { getSessionDocumentRef } from 'utils/collaboration-utils';

import CollaborationButton from './collaboration-button';
import styles from './collaboration-overview-page.module.scss';
import { CollaborationWithFirebaseData } from './types';

const BackButtonInnerWrapper = styled(Box)`
    margin-left: ${({ theme }) => theme.spacing(-2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    padding-top: ${({ theme }) =>
        `calc(env(safe-area-inset-bottom) + ${theme.spacing(3)})`};
`;

const BackButtonWrapper = styled(Box)`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${({ theme }) => alpha(theme.palette.background.default, 0.9)};
`;

const CollaborationOverviewPage: FC = () => {
    const { language, routes } = useHistoryRouter();
    const { t } = useTranslation();
    const auth = getAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        signInAnonymously(auth);
    }, []);

    const [loading, setLoading] = useState(true);
    const [collaborations, setCollaborations] =
        useState<CollaborationWithFirebaseData[]>();

    const fetchFirebaseCollaborationData = async (
        collaborationResponse?: Collaboration[]
    ) => {
        if (collaborationResponse) {
            let collaborationsWithFirebaseData: CollaborationWithFirebaseData[] =
                [...collaborationResponse];

            await Promise.all(
                collaborationResponse.map(({ collaborationId }) =>
                    getSessionDocumentRef(collaborationId).get()
                )
            )
                .then((responses) =>
                    responses.map(
                        (response, responseIndex) =>
                            (collaborationsWithFirebaseData =
                                collaborationsWithFirebaseData.map((r, i) =>
                                    i === responseIndex
                                        ? { ...r, ...response.data() }
                                        : r
                                ))
                    )
                )
                .catch(console.error);

            setCollaborations(collaborationsWithFirebaseData);
        }
    };

    useEffect(() => {
        collaborationClient
            .myCollaborations()
            .then(fetchFirebaseCollaborationData)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, []);

    const scrollPosition = useSelector(scrollPosSelectorCollaborationsPage);

    useLayoutEffect(() => {
        if (!loading && (scrollPosition?.x || scrollPosition?.y)) {
            window.scrollTo({
                top: scrollPosition.y,
                left: scrollPosition.x,
            });

            dispatch(setScrollPosCollaborationsPage({ x: 0, y: 0 }));
        }
    }, [loading]);

    const deleteCollabCallback = (id: string) => () =>
        setCollaborations((prev) =>
            prev?.filter((c) => c.collaborationId !== id)
        );

    return loading ? (
        <Loading />
    ) : (
        <Layout
            routes={routes}
            language={language}
            headerProps={{ withLogin: true, withAccount: true }}
            withHeaderSpace={false}
            pageClassName={styles.PageContainer}
        >
            <SEO title={t('collaborationOverview.pageTitle')} />

            <Container p={2} pb={{ xs: 10, md: 5 }} maxWidth={1}>
                <BackButtonWrapper>
                    <BackButtonInnerWrapper>
                        <Button
                            appearance='text'
                            leftIcon='arrow_left'
                            size='small'
                            to={routes.myLibrary}
                        >
                            {t('back')}
                        </Button>
                    </BackButtonInnerWrapper>
                </BackButtonWrapper>

                <Typography variant='h2' component='h1' mb={6}>
                    {t('collaborationOverview.pageTitle')}
                </Typography>

                {collaborations && collaborations.length > 0 ? (
                    <Grid container spacing={2}>
                        {collaborations.map((collaboration) => {
                            const { collaborationId: id } = collaboration;

                            return (
                                <CollaborationButton
                                    key={id}
                                    collaboration={collaboration}
                                    deleteCollabCallback={deleteCollabCallback(
                                        id
                                    )}
                                />
                            );
                        })}
                    </Grid>
                ) : (
                    <Typography>
                        {t('collaborationOverview.emptyState')}
                    </Typography>
                )}
            </Container>
        </Layout>
    );
};

export default CollaborationOverviewPage;
