import React, { ElementType, FC } from 'react';
import FreemiumEndSlide from '../end-slides/freemium-end-slide';
import QuestionSlide from '../question-slide/question-slide';
import styles from './question-swiper.module.scss';
import PremiumEndSlide from '../end-slides/premium-end-slide';
import { Slide, SlideType } from '_types';
import NextRandomButton from './next-random-button';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    EffectFade,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination as SwiperPagination,
} from 'swiper';
import { Box } from 'components/base';
import { SwiperNav } from 'components/base';
import useSwiperContext from 'hooks/use-swiper-context';
import ProEndSlide from 'professional/components/deck-modal/end-slide/end-slide';
import { useDefaultColor } from 'style/theme';

const renderSlide = (
    slide: Slide,
    isRandomQuestions?: boolean
): React.ReactElement => {
    let SlideComponent: ElementType;

    switch (slide.slideType) {
        case SlideType.FREEMIUM_END:
            SlideComponent = FreemiumEndSlide;
            break;
        case SlideType.PREMIUM_END:
            SlideComponent = PremiumEndSlide;
            break;
        case SlideType.QUESTION:
            SlideComponent = QuestionSlide;
            break;
        case SlideType.PRO_END:
            SlideComponent = ProEndSlide;
            break;
        default:
            throw Error('Unknown slide type');
    }

    return (
        <SwiperSlide key={slide.id} className={styles.questionSwiperSlide}>
            {({ isActive }) => (
                <SlideComponent
                    slide={slide}
                    isActive={isActive}
                    isRandomQuestions={!!isRandomQuestions}
                />
            )}
        </SwiperSlide>
    );
};

type QuestionSwiperProps = {
    getMoreRandomQuestions?: () => void;
    initialSlideIndex?: number;
    isRandomQuestions?: boolean;
    slides: Slide[];
};

const QuestionSwiper: FC<QuestionSwiperProps> = ({
    slides,
    initialSlideIndex,
    isRandomQuestions,
    // getMoreRandomQuestions,
}) => {
    const deck = slides.map((slide) => renderSlide(slide, isRandomQuestions));

    // if (isRandomQuestions && slides.length - 1 === activeSlide.index) {
    //     console.log('end of slides', activeSlide.index);
    //     getMoreRandomQuestions();
    // }

    const { setSwiper, nextRef } = useSwiperContext();
    const { colorName } = useDefaultColor();

    return isRandomQuestions ? (
        <Swiper
            autoHeight
            className={styles.swiperContainerRandom}
            direction='vertical'
            effect='fade'
            fadeEffect={{ crossFade: true }}
            initialSlide={initialSlideIndex}
            modules={[Navigation, EffectFade]}
            navigation={{
                disabledClass: styles.swiperNavButtonDisabled,
                nextEl: nextRef?.current,
            }}
            noSwiping
            onBeforeInit={setSwiper}
            onRealIndexChange={setSwiper}
        >
            {deck}
            <NextRandomButton ref={nextRef} />
        </Swiper>
    ) : (
        <Swiper
            className={styles.swiperContainer}
            initialSlide={initialSlideIndex}
            keyboard
            modules={[Keyboard, Mousewheel, SwiperPagination, Navigation]}
            mousewheel={{ forceToAxis: true }}
            onBeforeInit={setSwiper}
            onRealIndexChange={setSwiper}
        >
            {deck}

            <Box width={1} pt={2} sx={{ color: colorName }}>
                <SwiperNav />
            </Box>
        </Swiper>
    );
};

export default QuestionSwiper;
