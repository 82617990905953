import React, { FC } from 'react';

import { Box } from 'components/base';
import { useWindowSize } from 'hooks/use-window-size';

import styles from '../../collaboration-page.module.scss';
import { ReactComponent as DecoratorLarge } from './assets/bg-night-decorator-desktop.svg';
import { ReactComponent as DecoratorSmall } from './assets/bg-night-decorator-mobile.svg';

const BgNightDecorator: FC = () => {
    const { view } = useWindowSize(window);

    const DecoratorComponent =
        view === 'mobile' ? DecoratorSmall : DecoratorLarge;

    return (
        <Box position='relative'>
            <Box display='flex' position='absolute' bottom={0}>
                <DecoratorComponent className={styles.bgDecorator} />
            </Box>
        </Box>
    );
};

export default BgNightDecorator;
