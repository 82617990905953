import React, {
    FC,
    useCallback,
    useRef,
    RefObject,
    Fragment,
    useState,
} from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { Box, Button, Dialog, Link } from 'components/base';
import {
    getSessionDocumentRef,
    SessionData,
    DeleteAnswerDocument,
} from 'utils/collaboration-utils';

import useCollaboration from '../../../../use-collaboration';
import useSessionQuestion from '../../use-session-question';
import { useWindowGridColumnCount } from './use-window-grid-column-count';
import SessionGridRow from './session-grid-row';

import { useWindowResize } from './use-window-resize';
import { Trans, useTranslation } from 'react-i18next';

type SessionGridProps = {
    questionId: number;
};

const SessionGrid: FC<SessionGridProps> = ({ questionId }) => {
    const { answers } = useSessionQuestion();
    const { collaborationId } = useCollaboration();
    const colCount = useWindowGridColumnCount();
    const { t } = useTranslation();

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [textToDelete, setTextoDelete] = useState<string>('');

    const sessionRef = getSessionDocumentRef(collaborationId);
    const [sessionData] = useDocumentData<SessionData>(sessionRef as never);

    const { displayParticipantNames } = sessionData || {};

    const rowCount = answers ? Math.ceil(answers?.length / colCount) : 0;
    const listRef = useRef<VariableSizeList>() as RefObject<VariableSizeList>;
    const sizeMap = useRef<Record<number, number>>({});

    const estimatedHeight = displayParticipantNames ? 100 : 80;

    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        if (listRef.current) listRef.current.resetAfterIndex(index);
    }, []);

    const getSize = (index: number) =>
        sizeMap.current?.[index] || estimatedHeight;

    const [windowWidth] = useWindowResize();

    const deleteAnswer = async (text: string) => {
        if (answers) {
            for (let i = 0; i < answers?.length; i++) {
                if (answers[i].text == text) {
                    answers?.splice(i, 1);
                    i--;
                }
            }
            await DeleteAnswerDocument(collaborationId, questionId, text);
            setIsDeleting(false);
        }
    };

    return answers ? (
        <Fragment>
            <Box
                flex={1}
                mx='auto'
                width={1}
                height={1}
                sx={{ '& > div': { alignContent: 'flex-start', margin: 0 } }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            ref={listRef}
                            height={height ?? 0}
                            itemCount={rowCount}
                            itemSize={getSize}
                            estimatedItemSize={estimatedHeight}
                            width={width ?? 0}
                        >
                            {({ style, index }) => (
                                <Box style={style}>
                                    <SessionGridRow
                                        answers={answers.slice(
                                            index * colCount,
                                            index * colCount + colCount
                                        )}
                                        displayParticipantNames={
                                            displayParticipantNames
                                        }
                                        index={index}
                                        setSize={setSize}
                                        windowWidth={windowWidth}
                                        deleteAnswer={(text) => {
                                            setTextoDelete(text);
                                            setShowDelete(true);
                                        }}
                                    />
                                </Box>
                            )}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Box>
            <Dialog
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                    setTextoDelete('');
                }}
                title={t('delete')}
            >
                <Dialog.Content>
                    <Trans
                        i18nKey='collaboration.answer.delete.description'
                        values={{
                            text: '"' + textToDelete + '"',
                        }}
                    />
                </Dialog.Content>

                <Dialog.Actions>
                    <Button
                        appearance='cta'
                        loading={isDeleting}
                        onClick={() => {
                            setIsDeleting(true);
                            deleteAnswer(textToDelete);
                            setTextoDelete('');
                            setShowDelete(false);
                        }}
                    >
                        {t('Delete')}
                    </Button>

                    <Link
                        component='button'
                        disabled={isDeleting}
                        onClick={() => {
                            setShowDelete(false);
                            setTextoDelete('');
                        }}
                    >
                        {t('Cancel')}
                    </Link>
                </Dialog.Actions>
            </Dialog>
        </Fragment>
    ) : null;
};

export default SessionGrid;
