import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { getUAInfo } from 'services/user-agent.service';

const { isAndroid, isiOS, isiOSWeb, isMobile } = getUAInfo(window.navigator);
export const isSmartDevice = isAndroid || isiOS || isiOSWeb || isMobile;

export const StyledBox = styled(Box)`
    background-color: ${({ theme }) =>
        theme.palette.mode === 'light'
            ? theme.palette.custom.brown[95]
            : theme.palette.background.default};
    color: ${({ theme }) =>
        theme.palette.mode === 'light'
            ? theme.palette.custom.black[40]
            : theme.palette.text.primary};
`;

export const Bleed = styled(StyledBox)`
    align-items: center;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    box-shadow: ${({ theme }) =>
        `0 8px 40px alpha(${theme.palette.common.black}, 0.16)`};
    cursor: ${isSmartDevice ? 'default' : 'pointer'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    width: 100%;
    position: absolute;
    right: 0;
    visibility: visible;
    background-color: ${({ theme }) =>
        theme.palette.mode === 'light'
            ? theme.palette.custom.brown[95]
            : theme.palette.background.default};
    color: ${({ theme }) =>
        theme.palette.mode === 'light'
            ? theme.palette.custom.black[40]
            : theme.palette.text.primary};
` as typeof Box;

const pullerWidth = 40;
const pullerHeight = 4;
const pullerMargin = 16;

export const pullerAreaHeight = pullerMargin * 2 + pullerHeight;

export const Puller = styled(Box)`
    width: ${pullerWidth}px;
    height: ${pullerHeight}px;
    background-color: ${({ theme }) =>
        theme.palette.mode === 'light'
            ? theme.palette.custom.brown[85]
            : theme.palette.custom.black[40]};
    border-radius: 99px;
    position: absolute;
    top: ${pullerMargin}px;
    left: calc(50% - ${pullerWidth / 2}px);
`;

export const A11yLink = styled(Link)`
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    color: ${({ theme }) =>
        theme.palette.getContrastText(theme.palette.primary.main)};
    left: 0;
    padding: ${({ theme }) => theme.spacing(0.5, 1)};
    position: fixed;
    transform: translateX(-100%);
    transition: 150ms;
    z-index: 1;

    &:focus-visible {
        transform: ${({ theme }) => `translateX(${theme.spacing(0.5)})`};
    }
` as typeof Link;

export const Content = styled(StyledBox)`
    padding: ${({ theme }) => theme.spacing(0, 2, 3, 2)};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.not-visible {
        opacity: 0;
        position: fixed;
        bottom: -9999px;
    }

    &.dense {
        padding: 0;
    }
`;

export const InnerContent = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.px {
        padding-left: ${({ theme }) => theme.spacing(2)};
        padding-right: ${({ theme }) => theme.spacing(2)};
    }

    &.pb {
        padding-bottom: ${({ theme }) => theme.spacing(2)};
    }

    &.pt {
        padding-top: ${({ theme }) => theme.spacing(2)};
    }

    &.bottom {
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;

export const BottomContent = styled(Box)`
    bottom: 0;
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;
