import * as actionTypes from './_actionTypes';
import { SetMyConversations, ClearMyConversations } from './_types';
import { ConversationWithCategory } from '_types';

export const setMyConversations = (
    myConversations: ConversationWithCategory[]
): SetMyConversations => ({
    type: actionTypes.SET_MY_CONVERSATIONS,
    payload: myConversations,
});

export const clearMyConversations = (): ClearMyConversations => ({
    type: actionTypes.CLEAR_MY_CONVERSATIONS,
});
