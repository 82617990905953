import React from 'react';
import classNames from 'classnames';
import { RouteList } from 'api/routes';
import styles from './appbar.module.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'store-modules/user';
import { proAccountSelector } from 'store-modules/professional';
import {
    SetScrollPosActionFavouriteQuestionsPage,
    SetScrollPosActionHomePage,
    SetScrollPosActionCollaborationsPage,
    SetScrollPosActionPremiumBrowsePage,
    SetScrollPosActionProBrowsePage,
    SetScrollPosActionProHomePage,
    setScrollPosFavouriteQuestionsPage,
    setScrollPosHomePage,
    setScrollPosCollaborationsPage,
    setScrollPosPremiumBrowsePage,
    setScrollPosProBrowsePage,
    setScrollPosProHomePage,
} from 'store-modules/app-settings';
import { clearConversationSwiperPositions } from 'store-modules/conversation-swiper-positions';

interface Props {
    routes: RouteList;
}

const Appbar: React.FC<Props> = ({ routes }: Props) => {
    const user = useSelector(userSelector);
    const organization = useSelector(proAccountSelector);
    const dispatch = useDispatch();

    const clearScrollPosHomePage = (): SetScrollPosActionHomePage =>
        dispatch(setScrollPosHomePage({ x: 0, y: 0 }));
    const clearScrollPosPremiumBrowsePage =
        (): SetScrollPosActionPremiumBrowsePage =>
            dispatch(setScrollPosPremiumBrowsePage({ x: 0, y: 0 }));
    const clearScrollPosProHomePage = (): SetScrollPosActionProHomePage =>
        dispatch(setScrollPosProHomePage({ x: 0, y: 0 }));
    const clearScrollPosProBrowsePage = (): SetScrollPosActionProBrowsePage =>
        dispatch(setScrollPosProBrowsePage({ x: 0, y: 0 }));
    const clearScrollPosFavouriteQuestionsPage =
        (): SetScrollPosActionFavouriteQuestionsPage =>
            dispatch(setScrollPosFavouriteQuestionsPage({ x: 0, y: 0 }));
    const clearScrollPosCollaborationsPage =
        (): SetScrollPosActionCollaborationsPage =>
            dispatch(setScrollPosCollaborationsPage({ x: 0, y: 0 }));

    const clearScrollPositions = () => {
        clearScrollPosHomePage();
        clearScrollPosPremiumBrowsePage();
        clearScrollPosProHomePage();
        clearScrollPosProBrowsePage();
        clearScrollPosFavouriteQuestionsPage();
        clearScrollPosCollaborationsPage();

        dispatch(clearConversationSwiperPositions());
    };

    return (
        <nav className={styles.appbar}>
            <ul className={styles.list}>
                <li className={styles.item}>
                    <NavLink
                        className={({ isActive }) =>
                            classNames(styles.link, styles.homeIcon, {
                                [styles.active]: isActive,
                            })
                        }
                        end
                        onClick={clearScrollPositions}
                        to={routes.index}
                    >
                        <span className={styles.indicator} />
                    </NavLink>
                </li>

                {!!organization && organization.name == user?.organization && (
                    <li className={styles.item}>
                        <NavLink
                            className={({ isActive }) =>
                                classNames(styles.link, styles.proIcon, {
                                    [styles.active]: isActive,
                                })
                            }
                            onClick={clearScrollPositions}
                            to={routes.proindex}
                        >
                            <span className={styles.indicator} />
                        </NavLink>
                    </li>
                )}

                <li className={styles.item}>
                    <NavLink
                        className={({ isActive }) =>
                            classNames(styles.link, styles.searchIcon, {
                                [styles.active]: isActive,
                            })
                        }
                        to={routes.search}
                    >
                        <span className={styles.indicator} />
                    </NavLink>
                </li>

                <li className={styles.item}>
                    <NavLink
                        to={routes.myLibrary}
                        className={({ isActive }) =>
                            classNames(styles.link, styles.heartIcon, {
                                [styles.active]: isActive,
                            })
                        }
                        end
                        onClick={clearScrollPositions}
                    >
                        <span className={styles.indicator} />
                    </NavLink>
                </li>

                <li className={styles.item}>
                    <NavLink
                        className={({ isActive }) =>
                            classNames(styles.link, styles.userIcon, {
                                [styles.active]: isActive,
                            })
                        }
                        to={routes.account.index}
                    >
                        <span className={styles.indicator} />
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Appbar;
