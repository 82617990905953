import React, { useState } from 'react';
import { Question } from '_types/question';
import { Deck } from '_types/deck';
import { Modal } from 'components/base/feedback/modal';
import styles from './deck-modal.module.scss';
import { SEO } from 'components/seo';
import QuestionSwiperContainer from './question-swiper-container';
import { Language } from '_types/language';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Loading } from 'components/loading';
import { Conversation } from '_types';
import conversationClient, {
    FavouriteConversation,
    FavouriteQuestion,
} from 'api/conversation-client';
import { authenticatedSelector } from 'store-modules/auth';
import { setFavouriteQuestions } from 'store-modules/favourite-questions';
import { setFavouriteConversations } from 'store-modules/favourite-conversations';
import { ReactComponent as MoreVertIcon } from 'images/icons/more-vert.svg';
import classnames from 'classnames';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { IS_IOS_APP } from './../../../constants';
import { useNavigate } from 'react-router-dom';
import { StartConversation } from 'components/start-conversation';

interface Params {
    taxonomy: string;
    deckSlug: string;
    questionSlug: string;
}

interface Props {
    deck: Deck;
    questions: Question[];
    onExit: () => void;
    params: Params;
    parentPage: string;
    favouriteQuestions: Question[] | null;
    favouriteConversations: Conversation[] | null;
    isConversationInFavourites: boolean;
    setIsConversationInFavourites: (arg0: boolean) => void;
    isQuestionInFavourites: boolean;
    setIsQuestionInFavourites: (arg0: boolean) => void;
    toggleShuffle: () => void;
    isShuffled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchState: any;
}
interface ViewCopy {
    footerTextMobile: string;
    footerTextDesktop: string;
}

const nbCopy: ViewCopy = {
    footerTextMobile: 'Du kan også dra for å gå frem og tilbake',
    footerTextDesktop:
        'Du kan også bruke piltastene for å gå til neste eller forrige spørsmål',
};

const enCopy: ViewCopy = {
    footerTextMobile: 'You can also swipe to change questions',
    footerTextDesktop: 'You can also use the arrow keys to change questions',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const DeckModal: React.FC<Props> = ({
    deck,
    questions,
    onExit,
    params,
    params: { questionSlug },
    parentPage,
    favouriteQuestions,
    favouriteConversations,
    isConversationInFavourites,
    setIsConversationInFavourites,
    isQuestionInFavourites,
    setIsQuestionInFavourites,
    toggleShuffle,
    searchState,
    isShuffled,
}: Props) => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { footerTextMobile, footerTextDesktop } = returnCopyForLanguage(
        language,
        copies
    );
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [forcedHeartShow, setForcedHeartShow] = useState(false);
    const dispatch = useDispatch();

    const showIntro = !questionSlug;
    const firstQuestion = questions[0];
    const firstQuestionPath = firstQuestion?.slug;
    const isAuthenticated = useSelector(authenticatedSelector);
    const showFavouriteHeart = isAuthenticated && !isLastSlide;
    const navigateToFirstQuestion = () =>
        firstQuestionPath &&
        navigate(firstQuestionPath, { ...searchState, parentPage });

    function isDeckInList(conversations: Conversation[] | null): boolean {
        if (conversations) {
            for (let index = 0; index < conversations.length; index++) {
                const c = conversations[index];
                if (c.id === deck.id) {
                    return true;
                }
            }
        }
        return false;
    }

    function isCurrentQuestionInList(questions: Question[] | null): boolean {
        if (questions) {
            for (let index = 0; index < questions.length; index++) {
                const q = questions[index];

                if (!!currentQuestion && q.id === currentQuestion.id) {
                    return true;
                }
            }
        }
        return false;
    }
    const updateQuestions = () => {
        Promise.all([conversationClient.getMyFavouriteQuestions()]).then(
            (response) => {
                setFavouriteQuestions(response[0].reverse());
                setForcedHeartShow(false);
            }
        );
    };
    const updateConversations = () => {
        Promise.all([conversationClient.getMyFavouriteConversations()]).then(
            (response) => {
                dispatch(setFavouriteConversations(response[0].reverse()));
                setForcedHeartShow(false);
            }
        );
    };
    const update = () => {
        if (!forcedHeartShow) {
            setIsQuestionInFavourites(
                isCurrentQuestionInList(favouriteQuestions)
            );
            setIsConversationInFavourites(isDeckInList(favouriteConversations));
        }
    };
    const onSlide = () => {
        setForcedHeartShow(false);
        update();
    };
    const setCurrentQuestionHandler = (question: Question) => {
        setCurrentQuestion(question);
        update();
    };

    const addQuestionToFavourites = async (question: Question) => {
        if (favouriteQuestions) {
            const questionsCopy = favouriteQuestions.slice();
            questionsCopy.unshift(question);
            dispatch(setFavouriteQuestions(questionsCopy));
        } else {
            const newQuestionList: Question[] = [question];
            dispatch(setFavouriteQuestions(newQuestionList));
        }
        try {
            const questionToAdd: FavouriteQuestion = {
                question_id: question!.id,
            };
            await conversationClient.addFavouriteQuestion(questionToAdd);
        } catch (err) {
            console.error('Could not add question to favourites', err);
        }
    };

    const removeFavouriteQuestion = async (question: Question) => {
        if (favouriteQuestions) {
            const questionsCopy = favouriteQuestions.slice();
            questionsCopy.splice(questionsCopy.indexOf(question), 1);
            dispatch(setFavouriteQuestions(questionsCopy));
        }
        try {
            await conversationClient.removeFavouriteQuestion({
                question_id: question.id,
            });
        } catch (err) {
            console.error('Could not remove question from favourites', err);
        }
    };

    const toggleFavourite = async () => {
        //Add conversation to favourites
        if (showIntro) {
            const conversationToAdd: FavouriteConversation = {
                conversation_id: deck.id,
                taxonomy: deck.taxonomy
            };
            try {
                if (isConversationInFavourites) {
                    await conversationClient.removeFavouriteConversation(
                        conversationToAdd
                    );
                } else {
                    await conversationClient.addFavouriteConversation(
                        conversationToAdd
                    );
                }
                updateConversations();
            } catch (err) {
                console.error(
                    'Could not add/remove Conversation to favourites',
                    err
                );
            }
        }
        //Add question to favourites
        else if (!showIntro) {
            setIsQuestionInFavourites(!isQuestionInFavourites);
            try {
                if (isQuestionInFavourites) {
                    await removeFavouriteQuestion(currentQuestion!);
                } else {
                    await addQuestionToFavourites(currentQuestion!);
                }
                updateQuestions();
            } catch (err) {
                console.error(
                    'Could not add/remove question to favourites',
                    err
                );
            }
        }
    };
    const deckTitle = !showIntro ? deck.name : '';
    const seoTitle = showIntro ? deck.name : '';
    const view = !!questions.length ? (
        <Modal
            titleId={deck.name}
            title={deckTitle}
            onExit={onExit}
            className={styles.DeckModal}
        >
            <SEO title={seoTitle} />
            <div className={showIntro ? styles.Bg : styles.BgBubbles} />
            {showIntro && (
                <section className={styles.IntroSection}>
                    <StartConversation
                        deck={deck}
                        isShuffled={isShuffled}
                        questions={questions}
                        startHandler={navigateToFirstQuestion}
                        toggleShuffle={toggleShuffle}
                    />
                </section>
            )}
            {showFavouriteHeart && (
                <button
                    className={
                        showIntro
                            ? classnames(
                                  styles.favouriteHeartIntro,
                                  styles.favouriteHeart
                              )
                            : styles.favouriteHeart
                    }
                    onClick={() => {
                        setForcedHeartShow(true);
                        showIntro
                            ? setIsConversationInFavourites(
                                  !isConversationInFavourites
                              )
                            : setIsQuestionInFavourites(
                                  !isQuestionInFavourites
                              );
                        toggleFavourite();
                    }}
                >
                    <div
                        className={
                            (isConversationInFavourites && showIntro) ||
                            (isQuestionInFavourites && !showIntro)
                                ? styles.redHeartIcon
                                : styles.redHeartIconOutline
                        }
                    ></div>
                </button>
            )}
            {!showIntro && (
                <button
                    className={styles.moreVert}
                    onClick={() => {
                        navigate(routes.questionInfoPage, {
                            state: { question: currentQuestion },
                        });
                    }}
                >
                    <MoreVertIcon />
                </button>
            )}
            {!showIntro && (
                <section className={styles.SwiperSection}>
                    <QuestionSwiperContainer
                        deck={deck}
                        questions={questions}
                        params={params}
                        language={language}
                        parentPage={parentPage}
                        setIsLastSlide={setIsLastSlide}
                        setCurrentQuestion={setCurrentQuestionHandler}
                        onSlide={onSlide}
                    />
                </section>
            )}
            {!showIntro && (
                <footer
                    className={
                        IS_IOS_APP
                            ? classnames(styles.Footer, styles.IOSFooter)
                            : styles.Footer
                    }
                >
                    <span
                        className={styles.Text}
                        data-content-mobile={footerTextMobile}
                        data-content-desktop={footerTextDesktop}
                    />
                </footer>
            )}
        </Modal>
    ) : (
        <Loading />
    );

    return view;
};

export default DeckModal;
